import { useFetch } from "../api/api_client";
import { userApis } from "../redux/api";
import { LoadingView } from "../components/loading_view";
import { HeightBox } from "../components/components";

export default function PrivacyPage() {
  const { data, error, loading } = useFetch(userApis.PRIVACY_POLICY);

  if (loading) return <LoadingView />;

  if (error) {
    //console.log(error)
    return <h2>ERROR {"Opps! Something went wrong"}</h2>;
  }

  const privacyArray = data["data"];

  //console.log('data',privacyArray.length);

  if (privacyArray.length == 0) {
    return <div></div>;
  }

  const { content } = data["data"][0];

  //console.log('data'+content);

  return (
    <>
      <div className={"container prevDiv"}>
        <h2>Privacy Policy</h2>
        <HeightBox height={"30px"} />
        <p style={{ fontSize: "14px" }}>{content}</p>
      </div>
    </>
  );
}
