import { crowdsaleActionTypes } from "./Types";

const INITIALSTATE = {
  popularcrowdsale: null,
  recommendcrowdsale: null,
  livecrowdsale: null,
  livecrowdfund: null,
  successfulcrowdsale: null,
  finalizedcrowdsale: [],
  finalizedcrowdfund: [],
  popularcrowdsaleloading: false,
  recommendcrowdsaleloading: false,
  liveprice: null,
  livecrowdsaleloading: false,
  livecrowdfundloading: false,
  successfulcrowdsaleloading: false,
  finalizedcrowdsaleloading: false,
  cartdata: null,
  investloading: false,
};

export const crowdsaleReducer = (state = INITIALSTATE, action) => {
  switch (action.type) {
    case crowdsaleActionTypes.POPULAR_CROWDSALE_START:
      return {
        ...state,
        popularcrowdsaleloading: true,
      };
    case crowdsaleActionTypes.POPULAR_CROWDSALE_SUCCESS:
      return {
        ...state,
        popularcrowdsale: action.payload,
        popularcrowdsaleloading: false,
      };

    case crowdsaleActionTypes.RECOMMEND_CROWDSALE_START:
      return {
        ...state,
        recommendcrowdsaleloading: true,
      };
    case crowdsaleActionTypes.RECOMMEND_CROWDSALE_SUCCESS:
      return {
        ...state,
        recommendcrowdsale: action.payload,
        recommendcrowdsaleloading: false,
      };
    case crowdsaleActionTypes.LIVE_CROWDFUND_START:
      return {
        ...state,
        livecrowdfundloading: true,
      };
    case crowdsaleActionTypes.LIVE_CROWDFUND_SUCCESS:
      return {
        ...state,
        livecrowdfund: action.payload,
        livecrowdfundloading: false,
      };
    case crowdsaleActionTypes.LIVE_CROWDSALE_START:
      return {
        ...state,
        livecrowdsaleloading: true,
      };
    case crowdsaleActionTypes.LIVE_CROWDSALE_SUCCESS:
      return {
        ...state,
        livecrowdsale: action.payload,
        livecrowdsaleloading: false,
      };
    case crowdsaleActionTypes.SUCCESSFUL_CROWDSALE_START:
      return {
        ...state,
        successfulcrowdsaleloading: true,
      };
    case crowdsaleActionTypes.SUCCESSFUL_CROWDSALE_SUCCESS:
      return {
        ...state,
        successfulcrowdsale: action.payload,
        successfulcrowdsaleloading: false,
      };
    case crowdsaleActionTypes.FINALIZED_CROWDSALE_START:
      return {
        ...state,
        finalizedcrowdsaleloading: true,
      };
    case crowdsaleActionTypes.FINALIZED_CROWDSALE_SUCCESS:
      return {
        ...state,
        finalizedcrowdsale: action.payload,
        finalizedcrowdsaleloading: false,
      };
    case crowdsaleActionTypes.FINALIZED_CROWDFUND_SUCCESS:
      return {
        ...state,
        finalizedcrowdfund: action.payload,
      };
    case crowdsaleActionTypes.GET_LIVE_PRICE_SUCCESS:
      return {
        ...state,
        liveprice: action.payload,
      };
    case crowdsaleActionTypes.INVEST_CROWDSALE_START:
      return {
        ...state,
        cartdata: action.payload,
        investloading: true,
      };
    case crowdsaleActionTypes.POPULAR_CROWDSALE_FAILURE:
    case crowdsaleActionTypes.RECOMMEND_CROWDSALE_FAILURE:
    case crowdsaleActionTypes.LIVE_CROWDSALE_FAILURE:
    case crowdsaleActionTypes.LIVE_CROWDFUND_FAILURE:
    case crowdsaleActionTypes.GET_LIVE_PRICE_FAILURE:
    case crowdsaleActionTypes.SUCCESSFUL_CROWDSALE_FAILURE:
    case crowdsaleActionTypes.INVEST_CROWDSALE_SUCCESS:
    case crowdsaleActionTypes.INVEST_CROWDSALE_FAILURE:
    case crowdsaleActionTypes.FINALIZED_CROWDSALE_FAILURE:
      return {
        ...state,
        popularcrowdsaleloading: false,
        recommendcrowdsaleloading: false,
        livecrowdsaleloading: false,
        livecrowdfundloading: false,
        successfulcrowdsaleloading: false,
        finalizedcrowdsaleloading: false,
        investloading: false,
      };

    default:
      return { ...state };
  }
};
