import axios from "axios";
// import { userApis } from "../api";

export default class AppServices {
  constructor() {
    this.ipfsUrl = process.env.REACT_APP_IPFS_URL;
    this.pinataApiKey = process.env.REACT_APP_PINATA_API_KEY;
    this.pinataApiSecret = process.env.REACT_APP_PINATA_API_SECRET;
    this.atomicAssetUrl = process.env.REACT_APP_ATOMICASSET_URL;
    this.atomicMartUrl = process.env.REACT_APP_ATOMICMARKET_URL
    this.ipfsPintaUrl = "https://api.pinata.cloud/pinning/pinFileToIPFS";
    this.ipfsPintaUrl_DNFT = `${process.env.REACT_APP_BASE_URL}api/v1/user/file/upload/pinata`
  }
  filterData(data) {
    return data.filter((data) => data.immutable_data.musicId)
  }
  isLogin = () => {
    return localStorage.getItem("UALInvalidateAt");
  };

  login = async (ual) => {
    return ual.showModal();
  };

  logout = (ual) => {
    if (ual.activeUser) {
      return ual.logout();
    }
  };

  getAccountName = (ual) => {
    let accountName = null;
    if (ual.activeUser != null) {
      accountName = ual.activeUser.accountName ?? null;
    }
    return accountName;
  };

  getIpfsUrl = (ipfsId, thumbnail) => {
    let url = "";
    try {
      url = ipfsId?.includes("http") ? ipfsId : this.ipfsUrl + ipfsId;
      // + "&h=" +
      // (thumbnail === true ? "100" : "370")
      // + "&output=gif&n=-1"
    } catch (err) { }

    return url;
  };

  getIpfsVidepUrl = (ipfsId) => {
    let url = "";

    try {
      url = ipfsId?.includes("http") ? ipfsId : "https://ipfs.io/ipfs/" + ipfsId;
    } catch (err) { }

    return url;
  };

  setUsdPrice = (price) => {
    localStorage.setItem("pusd", price);
  };

  getUsdPrice = () => {
    return localStorage.getItem("pusd");
  };
  uploadFileOnIpfs = async (img) => {
    let res = null;
    const url = this.ipfsPintaUrl_DNFT;
    let data = new FormData();
    data.append("file", img);
    // const pinataOptions = JSON.stringify({
    //   cidVersion: 0,
    //   customPinPolicy: {
    //     regions: [
    //       {
    //         id: "FRA1",
    //         desiredReplicationCount: 1,
    //       },
    //       {
    //         id: "NYC1",
    //         desiredReplicationCount: 2,
    //       },
    //     ],
    //   },
    // });

    // data.append("pinataOptions", pinataOptions);

    // let config = {
    //   maxBodyLength: "Infinity",
    //   headers: {
    //     "Content-Type": `multipart/form-data; boundary=${data._boundary}`,
    //     pinata_api_key: this.pinataApiKey,
    //     pinata_secret_api_key: this.pinataApiSecret,
    //   },
    // };

    res = await axios
      .post(url, data)
      .then(function (response) {
        return response;
      })
      .catch(function (error) {
        return null;
      });

    return res;
  };
  getAuthorCollection = async (userAccount) => {
    const url =
      this.atomicAssetUrl +
      "/collections?limit=20&page=1&order=desc&sort=created&author=" +
      userAccount;
    let res = null;
    await axios
      .get(url)
      .then((response) => {
        res = response.data.data;
      })
      .catch((err) => {
        res = null;
      });
    return res;
  };
  getCollectionSchema = async (collection) => {
    const url = this.atomicAssetUrl + "/schemas";
    const query = "?collection_name=" + collection;
    var res = null;
    await axios
      .get(url + query)
      .then((response) => {
        res = response.data.data;
      })
      .catch((err) => {
        res = null;
      });
    return res;
  };
  getAtomicAssets = async (userName, page, match) => {
    const url = this.atomicMartUrl + "/assets";
    const query =
      "?owner=" +
      userName +
      "&order=desc&sort=asset_id&page=" +
      page +
      "&match=" +
      match;
    var res = null;
    await axios
      .get(url + query)
      .then((response) => {
        res = response.data.data;
      })
      .catch((err) => {
        throw err;
      });
    return res;
  };
}

//https://resizer.atomichub.io/images/v1/preview?ipfs=QmaQTBr1egoQu1k2F7xVcU7DKuz9tHMWCGZt9fJgiscgcR&size=370
//https://images.weserv.nl/?url=https://ipfs.io/ipfs/QmaQTBr1egoQu1k2F7xVcU7DKuz9tHMWCGZt9fJgiscgcR&h=370
