import SettingComponent from "./account_setting_component";
import DatePicker from "react-datepicker";
import "./custom_date_picker.scss";
import "react-datepicker/dist/react-datepicker.css";
import React, { useRef, useState } from "react";
import { Form, Row, Tabs, Col, Badge, Button } from "react-bootstrap";
import KycImageView from "./KycImageView";
import { Tab } from "bootstrap";
import { HeightBox, SubmitButton } from "../../components/components";
import { isNotValid, notifyError, notifySuccess } from "../../utils/utils";
import { format } from "date-fns";
import { kycViewConstants } from "../../constants";
import { Country } from "country-state-city";

import {
  fetchUrl,
  useFetch,
  useSubmit,
  useUploadKyc,
  useUploadKycImage,
} from "../../api/api_client";
import { crowdsaleApis, userApis } from "../../redux/api";
import axios from "axios";
import { useHistory } from "react-router";
import SwalSuccess from "../../components/ToastMessage/SwalSuccess";
import moment from "moment";
import InfoBox from "../../components/InfoMessage/InfoHover";
import SwalError from "../../components/ToastMessage/SwalError";
import { getRequiredProtectedData } from "../../redux/apicalltemplate";
import PublisherAgreement from "./PublisherAgreement";

function InvestorKyc({ details, vatNo, status, kyc }) {

  const [data, setData] = useState({});
  const [issueDate, setIssueDate] = useState(
    details["dateOfIssue"] !== undefined
      ? new Date(details["dateOfIssue"])
      : undefined
  );
  const [expiryDate, setExpiryDate] = useState(
    details["dateOfExp"] !== undefined
      ? new Date(details["dateOfExp"])
      : undefined
  );
  const [loading, setLoading] = useState(false);
  const [frontImage, setFrontImage] = useState();
  const [backImage, setBackImage] = useState();

  const [frontImageUrl, setFrontImageUrl] = useState();
  const [backImageUrl, setBackImageUrl] = useState();

  const [uploadingKycImage, setKycUploadingStatus] = useState("");


  const uploadKycImage = (file, side) => {
    let formData = new FormData();
    formData.append("image", file); //append the values with key, value pair

    setKycUploadingStatus(side);
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useUploadKycImage(userApis.UPLOAD_KYC_IMAGE + side, formData)
      .then((response) => {
        SwalSuccess(response.data.message);
        const { imageUrl } = response["data"];
        if (side === "front") {
          setFrontImageUrl(imageUrl);
        } else {
          setBackImageUrl(imageUrl);
        }
        setKycUploadingStatus("");
      })
      .catch((e) => {
        notifyError(e.message);
        setKycUploadingStatus("");
      });
  };

  const handleInputChange = (e) => {
    data[e.target.name] = e.target.value;
    setData(data);
  };

  const handleSubmit = () => {
    const { idName, idNumber, issuedBy, vatNumber } = data;
    if (isNotValid(idName)) {
      notifyError("Enter a valid ID Name");
      return;
    }
    if (isNotValid(idNumber)) {
      notifyError("Enter a valid ID Number");
      return;
    }
    if (!/^[a-zA-Z0-9]*$/.test(idNumber)) {
      notifyError("Please enter valid ID Number");
      return;
    }
    if (isNotValid(issuedBy)) {
      notifyError("Select a valid Issuer");
      return;
    }

    // if (isNotValid(vatNumber)) {
    //   notifyError("Enter a valid VAT Number");
    //   return;
    // }

    if (isNotValid(issueDate)) {
      notifyError("Enter a valid Issue Date");
      return;
    }
    if (isNotValid(expiryDate)) {
      notifyError("Enter a valid expiry Date");
      return;
    }
    let today = Date.now();
    if (issueDate > today) {
      notifyError("Issue Date should not be greater than Today Date");
      return;
    }
    if (issueDate >= expiryDate) {
      notifyError("Expiry Date should be greater than Issue Date");
      return;
    }
    if (frontImageUrl === undefined) {
      notifyError("Select front side image");
      return;
    }

    if (backImageUrl === undefined) {
      notifyError("Select back side image");
      return;
    }

    setLoading(true);

    data["dateOfIssue"] = issueDate.toISOString();
    data["dateOfExp"] = expiryDate.toISOString();
    data["frontSide"] = frontImageUrl;
    data["backSide"] = backImageUrl;

    // eslint-disable-next-line react-hooks/rules-of-hooks
    useSubmit(userApis.INVESTOR_KYC, data)
      .then((res) => {
        notifySuccess("Successfully Submitted ");
        window.location.reload();
        setLoading(false);
      })
      .catch((e) => {
        notifyError(e.message ?? "Opps! Something went wrong");
        setLoading(false);
      });
  };

  return (
    <div className={"investor_kyc"}>
      <HeightBox height={"4px"} />
      <div className={"kyc-detail-container d-flex"}>
        <label className={"kyc-detail-label mx-2"}>Status</label>
        <div className="status-pill ml-3" pill bg="dark">
          {status}
        </div>
        {/* <span className={"kyc-detail-text"}>{title}</span> */}
      </div>
      {
        kyc?.investor?.rejectReason && kyc?.investor?.status === 'rejected' &&
        <div className="f-14 text-dark d-flex mb-2">
          <label className={"kyc-detail-label mx-2"}>Rejection Reason:</label>
          <div style={{ color: 'rgb(139, 0, 0)' }}>{kyc?.investor?.rejectReason}</div>
        </div>
      }
      <label className="input-label">Select Front Image</label>
      <KycImageView
        loading={uploadingKycImage === "front"}
        imageUrl={details["frontSide"]}
        fileData={frontImage}
        disable={status !== "pending" && status !== "rejected"}
        onChange={(file, data) => {
          uploadKycImage(file, "front");
          setFrontImage(data);
        }}
        hint={"Select Front Side "}
      />
      <HeightBox height={"8px"} />
      <label className="input-label">Select Back Image</label>
      <KycImageView
        loading={uploadingKycImage === "back"}
        imageUrl={details["backSide"]}
        fileData={backImage}
        accept="image/jpeg, image/png"
        disable={status !== "pending" && status !== "rejected"}
        hint={"Select Back Side "}
        onChange={(file, data) => {
          uploadKycImage(file, "back");
          setBackImage(data);
        }}
      />

      <HeightBox height={"8px"} />
      <label className="input-label">ID Name</label>
      <input
        disabled={status !== "pending" && status !== "rejected"}
        defaultValue={details["idName"]}
        type={"text"}
        name={"idName"}
        onChange={handleInputChange}
        className="input-box-sm"
        placeholder="ID Name"
        required
      />

      <HeightBox height={"6px"} />
      <label className="input-label">ID Number</label>

      <input
        disabled={status !== "pending" && status !== "rejected"}
        defaultValue={details["idNumber"]}
        type="text"
        name={"idNumber"}
        onChange={handleInputChange}
        className="input-box-sm"
        placeholder="ID Number"
        maxLength={50}
        required
      />

      <HeightBox height={"6px"} />
      <label className="input-label">ID Issue Date</label>
      <DatePicker
        disabled={status !== "pending" && status !== "rejected"}
        selected={issueDate}
        maxDate={Date.now()}
        onChange={(date) => setIssueDate(date)}
        placeholderText={"ID Issue Date"}
      />

      <HeightBox height={"6px"} />
      <label className="input-label">ID Expiry Date</label>
      <DatePicker
        disabled={status !== "pending" && status !== "rejected"}
        selected={expiryDate}
        minDate={Date.now()}
        onChange={(date) => setExpiryDate(date)}
        placeholderText={"ID Expiry Date"}
      />

      <HeightBox height={"6px"} />
      <label className="input-label">Issued By</label>
      <input
        disabled={status !== "pending" && status !== "rejected"}
        defaultValue={details["issuedBy"]}
        type={"text"}
        name={"issuedBy"}
        onChange={handleInputChange}
        className="input-box-sm"
        placeholder="Issued By"
        required
      />

      <HeightBox height={"6px"} />
      <label className="input-label">Vat Number</label>
      <input
        disabled={status !== "pending" && status !== "rejected"}
        defaultValue={vatNo}
        type={"text"}
        name={"vatNumber"}
        onChange={handleInputChange}
        className="input-box-sm"
        placeholder="Vat Number"
      />
      <div className="f-12 text-dark"> ** Individual users can leave the VAT field empty.</div>
      <HeightBox height={"8px"} />
      <SubmitButton
        hidden={status !== "pending" && status !== "rejected"}
        title={"Submit KYC"}
        loading={loading}
        onClick={handleSubmit}
      />
    </div>
  );
}

function PublisherKyc({ status, ipiNumber, proName, type,kyc }) {
  //console.log('data ', status, ipiNumber, proName, type)
  const handleInputChange = (e) => {
    data[e.target.name] = e.target.value;
    setData(data);
  };

  const [data, setData] = useState({});
  const [loading, setLoading] = useState(false);

  const handleSubmit = () => {
    const { proName, ipiNumber, type } = data;

    if (isNotValid(proName)) {
      notifyError("Enter a valid PRO Name");
      return;
    }
    if (isNotValid(ipiNumber)) {
      notifyError("Enter a valid IPI Number");
      return;
    }

    if (isNotValid(type)) {
      notifyError("Enter a valid type");
      return;
    }

    setLoading(true);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    useSubmit(userApis.PUBLISHER_DETAILS, data)
      .then((res) => {
        //console.log(res)
        notifySuccess("Successfully Submitted ");
        window.location.reload();
        setLoading(false);
      })
      .catch((e) => {
        notifyError(e.message ?? "Opps! Something went wrong");
        setLoading(false);
      });
  };




  return (
    <div className={"publisher_kyc"}>
      <HeightBox height={"4px"} />
      <div className={"kyc-detail-container d-flex"}>
        <label className={"kyc-detail-label mx-2"}>Status</label>
        <div className="status-pill ml-3" pill bg="dark">
          {status}
        </div>
        {/* <span className={"kyc-detail-text"}>{title}</span> */}
     
      </div>
      {
          kyc?.publisher?.rejectReason &&
          <div className="f-14 text-dark d-flex mb-2">
            <label className={"kyc-detail-label mx-2"}>Rejection Reason:</label>
            <div style={{ color: 'rgb(139, 0, 0)' }}>{kyc?.publisher?.rejectReason}</div>
          </div>
        }

      <label className="input-label">
        PRO Name
        <InfoBox className="ml-2" msg={"Your Royalty Collecting Agency"} />
      </label>
      <input
        onChange={handleInputChange}
        disabled={status !== "pending" && status !== "rejected"}
        type={"text"}
   
        name={"proName"}
        className="input-box-sm"
        placeholder="PRO Name"
        required
      />
      <HeightBox height={"6px"} />
      <label className="input-label">
        IPI Number
        <InfoBox
          className="ml-2"
          msg={"You will find this at your Royalty Collecting Agency"}
        />
      </label>
      <input
        onChange={handleInputChange}
        disabled={status !== "pending" && status !== "rejected"}

        type={"number"}
        name={"ipiNumber"}
        className="input-box-sm"
        placeholder="IPI Number"
        required
      />

      <HeightBox height={"6px"} />
      <label className="input-label">Type</label>
      <Form.Control
        onChange={handleInputChange}
        disabled={status !== "pending" && status !== "rejected"}
      
        name={"type"}
        className={"input-box-sm"}
        as="select"
      >
        <option value={""}>Select Type</option>
        <option value={"independent"}>Independent</option>
        <option value={"recordLabel"}>Record Label</option>
      </Form.Control>
      <HeightBox height={"8px"} />

      <SubmitButton
        hidden={status !== "pending" && status !== "rejected"}
        title={"Submit KYC"}
        loading={loading}
        onClick={handleSubmit}
      />
    </div>
  );
}

function AmlKyc({
  status,
  regular_deposit,
  deposit_from_country,
  source_of_funds,
  yearly_deposit,
  yearly_withdraw,
}) {
  const result = Country.getAllCountries();
  const [selectdata, setSelectData] = useState();
  const handleInputChange = (e) => {
    data[e.target.name] = e.target.value;
    if (parseInt(data.yearly_deposit) <= 0) {
      notifyError("Yearly Deposit Can Not Be Zero & Negative");
      data[e.target.name] = "";
      return;
    }
    if (parseInt(data.yearly_withdraw) <= 0) {
      notifyError("Yearly Deposit Can Not Be Zero & Negative");
      data[e.target.name] = "";
      return;
    }
    if (data.source_of_funds === "Other, please specify") {
      data[e.target.name] = "";
      setSelectData(true);
    }
    setData(data);
  };
  const handleChange = (e) => {
    data[e.target.name] = e.target.value === "true";
    setData(data);
  };
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(false);
  const handleSubmit = () => {
    const {
      regular_deposit,
      deposit_from_country,
      source_of_funds,
      yearly_deposit,
      yearly_withdraw,
    } = data;

    if (isNotValid(regular_deposit)) {
      notifyError("Select Yes or No");
      return;
    }
    if (isNotValid(deposit_from_country)) {
      notifyError("Enter Country Name");
      return;
    }
    if (isNotValid(source_of_funds)) {
      notifyError("Select Source Of Funds");
      return;
    }
    if (isNotValid(yearly_deposit)) {
      notifyError("Enter Yearly Deposit");
      return;
    }
    if (isNotValid(yearly_withdraw)) {
      notifyError("Enter Yearly Withdraw");
      return;
    }
    setLoading(true);
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useSubmit(userApis.AML_KYC_DETAILS, data)
      .then((res) => {
        notifySuccess("Successfully Submitted ");
        window.location.reload();
        setLoading(false);
      })
      .catch((e) => {
        notifyError(e.message ?? "Opps! Something went wrong");
        setLoading(false);
      });
  };

  return (
    <div className={"aml_kyc"}>
      <HeightBox height={"8px"} />
      <div className={"kyc-detail-container d-flex"}>
        <label className={"kyc-detail-label mx-2"}>Status</label>
        <div className="status-pill ml-3" pill bg="dark">
          {status}
        </div>
        {/* <span className={"kyc-detail-text"}>{title}</span> */}
      </div>

      <label className="input-label">
        Are you going to make deposits regularly ?
      </label>
      <HeightBox height={"8px"} />
      <div className="font-weight-normal">
        <Form.Check
          disabled={status !== "PENDING" && status !== "rejected"}
          type="radio"
          name="regular_deposit"
          id=""
          label={`Yes`}
          inline
          onChange={handleChange}
          value={true}
        />
        <Form.Check
          disabled={status !== "PENDING" && status !== "rejected"}
          type="radio"
          name="regular_deposit"
          id={`default-radio`}
          label={`No`}
          value={false}
          onChange={handleChange}
          inline
        />
      </div>

      <HeightBox height={"8px"} />
      <label className="input-label">
        Which country are deposits going to come from ?
      </label>
      <HeightBox height={"8px"} />
      <Form.Control
        onChange={handleInputChange}
        disabled={status !== "PENDING" && status !== "rejected"}
        defaultValue={source_of_funds}
        name={"deposit_from_country"}
        className={"input-box-sm"}
        as="select"
      >
        <option value={""}>Select Country</option>
        {result.map((data) => {
          return <option value={data.name}>{data.name}</option>;
        })}
      </Form.Control>
      <HeightBox height={"8px"} />
      <label className="input-label">What is the source of your funds ?</label>
      <Form.Control
        onChange={handleInputChange}
        disabled={status !== "PENDING" && status !== "rejected"}
        defaultValue={source_of_funds}
        name={"source_of_funds"}
        className={"input-box-sm"}
        as="select"
      >
        <option value={""}>choose a source</option>
        {kycViewConstants.map((data) => {
          return <option value={data}>{data}</option>;
        })}
      </Form.Control>
      {selectdata === true && (
        <input
          onChange={handleInputChange}
          disabled={status !== "PENDING" && status !== "rejected"}
          defaultValue={source_of_funds}
          type={"text"}
          name="source_of_funds"
          className="input-box-sm"
          placeholder="Enter Source Of Funds"
          required
        />
      )}
      <HeightBox height={"8px"} />
      <label className="input-label">Expected yearly to zeptagram ?</label>
      <input
        onChange={handleInputChange}
        disabled={status !== "PENDING" && status !== "rejected"}
        defaultValue={yearly_deposit}
        type={"number"}
        min="1"
        name="yearly_deposit"
        className="input-box-sm"
        placeholder=""
        required
      />

      <label className="input-label">
        Expected yearly withdrawl from zeptagram ?
      </label>
      <input
        onChange={handleInputChange}
        disabled={status !== "PENDING" && status !== "rejected"}
        defaultValue={yearly_withdraw}
        type={"number"}
        min="1"
        name="yearly_withdraw"
        className="input-box-sm"
        placeholder=""
        required
      />

      <HeightBox height={"8px"} />
      <SubmitButton
        hidden={status !== "PENDING" && status !== "rejected"}
        title={"Submit KYC"}
        loading={loading}
        onClick={handleSubmit}
      />
    </div>
  );
}
export default function KycView({ aver, userDetails }) {
  const history = useHistory();
  const [showAgreement, setAgreementModal] = useState(false)
  const [key, setKey] = useState("investor");
  const [kycRefresh, setKycRefresh] = useState(false)
  const [averUrlLoading, isFetchingAver] = useState(false);

  const fetchAverUrl = async () => {
    isFetchingAver(true);
    try {
      // eslint-disable-next-line react-hooks/rules-of-hooks
      const data = await fetchUrl(userApis.AVER_URL);
      const checkData = data["data"];
      //console.log(checkData["data"]);

      window.location = data.data["url"];

      // eslint-disable-next-line react-hooks/rules-of-hooks
      //  const redirectData = await useSubmit(userApis.AVER_CREATE,checkData["data"])

      //console.log(data)
    } catch (e) {
      isFetchingAver(false);
    }
  };

  const { error, data, loading } = useFetch(userApis.KYC_DETAILS, [kycRefresh]);
  // console.log("result data", data);
  if (loading || error) return <div />;

  const kyc = data["data"].kyc;
  const kycDetails = data["data"].kycDetails;
  const amlDetails = data["data"].kycDetails?.aml;
  const {
    regular_deposit,
    deposit_from_country,
    source_of_funds,
    yearly_deposit,
    yearly_withdraw,
  } = amlDetails;
  const amlStatus = amlDetails?.status.current_status;
  const investorDetail = kycDetails["id"];
  const vat = kycDetails["vat"];
  const investorStatus = kyc["investor"].status;
  const publisherStatus = kyc["publisher"].status;
  const proName = kycDetails["proName"].name;
  const ipiNumber = kycDetails["ipiNumber"].number;
  const type = kyc["publisher"].type;
  const verifiedBy = kyc["investor"].via;

  return (
    <>
      <Tabs
        id="controlled-tab-example"
        activeKey={key}
        onSelect={(k) => setKey(k)}
        className="mb-2 kyc-tab"
      >
        <Tab eventKey="investor" title="Investor KYC">
          <div>
            {investorStatus == "rejected" || investorStatus == "pending" ?
            
          //   userDetails &&
          //   userDetails.aver &&
          //   userDetails.aver.isVerified == "PENDING" ? (
          //   <div>
          //     <StatusKyc label={"Status Aver"} title={"AVER : Verification in Progess"} />
          //   </div>
          // ):
          
          (
              <>
                    {/* {
                        userDetails &&
                        userDetails.aver &&
                        userDetails.aver.isVerified == "PENDING" ? (
                        <div>
                          <StatusKyc label={"Aver Verification Status"} title={"Verification in Progess"} />
                        </div>
                      ):null
                    }
               */}
                <InvestorKyc
                  details={{}}
                  kyc={kyc}
                  vatNo={vat["number"]}
                  status={investorStatus}
                />
                <div hidden={investorStatus === "verified"}>
                  <div className={"d-flex flex-row align-items-center"}>
                    <span className={"divider-line-short"} />
                    <span className={"or"}>OR</span>{" "}
                    <span className={"divider-line-long"} />
                  </div>

                  <SubmitButton
                    title={"Connect to AVER"}
                    loading={averUrlLoading}
                    onClick={() => {
                      if (aver !== undefined && aver["url"] !== undefined) {
                        window.location = aver["url"];
                        return;
                      }
                      fetchAverUrl();
                    }}
                  />
                </div>
              </>
            ) : userDetails &&
              userDetails.aver &&
              userDetails.aver.isVerified == "ACCEPTED" ? (
              <div>
                <StatusKyc label={"Status"} title={"ACCEPTED"} />
              </div>
            ) : (
              <InverstorDetailsCard
                investorDetails={investorDetail}
                vatNo={vat}
                status={investorStatus}
              />
            )}
          </div>
        </Tab>
        <Tab eventKey="publisher" title="Publisher KYC">
          {publisherStatus == "rejected" || publisherStatus == "pending" ? (
            <>
              <PublisherKyc
                status={publisherStatus}
                proName={proName}
                ipiNumber={ipiNumber}
                type={type}
                kyc={kyc}
              />

            </>
          ) : (
            <PublisherDetailsCard
              status={publisherStatus}
              proName={proName}
              ipiNumber={ipiNumber}
              type={type}
            />
          )}
          {/* <div className="mt-3 d-flex">
            <StatusKyc label={"Is Agreement Signed ?"} title={kycDetails?.agreement?.is_submitted ? 'Yes' : 'No'} />
            {kycDetails?.agreement?.is_submitted ? '' : <button className="button-medium border-shadow  medium-btn more-button" onClick={() => setAgreementModal(true)}>Sign here</button>}
            {showAgreement && <PublisherAgreement show={showAgreement} setShow={setAgreementModal} setKycRefresh={setKycRefresh} kycRefresh={kycRefresh} />}

          </div> */}
        </Tab>
        {verifiedBy === "zeptagram" || verifiedBy === "" || null ? (
          <Tab eventKey="amlkyc" title="AML KYC">
            {amlStatus == "rejected" || amlStatus === "PENDING" ? (
              <AmlKyc
                status={amlStatus}
                regular_deposit={regular_deposit}
                deposit_from_country={deposit_from_country}
                source_of_funds={source_of_funds}
                yearly_deposit={yearly_deposit}
                yearly_withdraw={yearly_withdraw}
              />
            ) : (
              <AmlDetailsCard
                status={amlStatus}
                regular_deposit={regular_deposit}
                deposit_from_country={deposit_from_country}
                source_of_funds={source_of_funds}
                yearly_deposit={yearly_deposit}
                yearly_withdraw={yearly_withdraw}
              />
            )}
          </Tab>
        ) : null}
      </Tabs>
    </>
  );
}

function InverstorDetailsCard({ investorDetails, vatNo, status }) {
  return (
    <div>
      <Row>
        <Col xs={6}>
          {investorDetails["frontSide"] && (
            <img className={"kyc-image"} src={investorDetails["frontSide"]} />
          )}
        </Col>
        <Col xs={6}>
          {investorDetails["backSide"] && (
            <img className={"kyc-image"} src={investorDetails["backSide"]} />
          )}
        </Col>
      </Row>

      <HeightBox height={"8px"} />

      <StatusKyc label={"Status"} title={status} />

      {investorDetails["idName"] && (
        <KycDetailView label={"ID Name"} title={investorDetails["idName"]} />
      )}
      {investorDetails["idNumber"] && (
        <KycDetailView
          label={"ID Number"}
          title={investorDetails["idNumber"]}
        />
      )}
      {investorDetails["dateOfIssue"] && (
        <KycDetailView
          label={"ID Issue Date"}
          title={
            investorDetails["dateOfIssue"] &&
            moment(investorDetails["dateOfIssue"]).format("DD MMM YYYY")
          }
        />
      )}
      {investorDetails["dateOfExp"] && (
        <KycDetailView
          label={"ID Expiry Date"}
          title={
            investorDetails["dateOfExp"] &&
            moment(investorDetails["dateOfExp"]).format("DD MMM YYYY")
          }
        />
      )}
      {investorDetails["issuedBy"] && (
        <KycDetailView
          label={"Issued By"}
          title={investorDetails["issuedBy"]}
        />
      )}
      {vatNo["number"] && (
        <KycDetailView label={"Vat Number"} title={vatNo["number"]} />
      )}
    </div>
  );
}

function PublisherDetailsCard({ proName, ipiNumber, type, status }) {
  return (
    <div>
      <StatusKyc label={"Status"} title={status} />

      <KycDetailView label={"PRO Name"} title={proName} />

      <KycDetailView label={"IPI Number"} title={ipiNumber} />

      <KycDetailView label={"Type"} title={type} />
    </div>
  );
}

function AmlDetailsCard({
  status,
  regular_deposit,
  deposit_from_country,
  source_of_funds,
  yearly_deposit,
  yearly_withdraw,
}) {
  return (
    <div>
      <StatusKyc label={"Status"} title={status} />
      <KycDetailView
        label={"Regular Deposit"}
        title={regular_deposit == true ? "Yes" : "NO"}
      />
      <KycDetailView
        label={"Deposit From Country"}
        title={deposit_from_country}
      />
      <KycDetailView label={"Source Of Funds"} title={source_of_funds} />
      <KycDetailView label={"Yearly Deposit"} title={yearly_deposit} />
      <KycDetailView label={"Yearly Withdraw"} title={yearly_withdraw} />
    </div>
  );
}

function KycDetailView({ label, title }) {
  return (
    <div className={"kyc-detail-container"}>
      <label className={"kyc-detail-label"}>{label}</label>
      <span className={"kyc-detail-text"}>{title}</span>
    </div>
  );
}

export function StatusKyc({ label, title, style }) {
  return (
    <div className={"kyc-detail-container"}>
      <label className={"kyc-detail-label"}>{label}</label>
      <div style={style} className="status-pill" pill bg="dark">
        {title}
      </div>
      {/* <span className={"kyc-detail-text"}>{title}</span> */}
    </div>
  );
}
