import React, { useState, useEffect } from "react";
import CryptoJS from "crypto-js";
import { toast } from "react-toastify";
import { Button, Form, Modal, Spinner } from "react-bootstrap";

import {
  SuccessToast,
  ErrorToast,
} from "../../components/ToastMessage/SuccessToast";
import "./LoadWallet.css";
import { errorMessage, stringConstants, successMessage } from "../../constants";

import StellarSdk from "stellar-sdk";

import WalletIcon from "../../icons/wallet.svg";
import QRCode from "qrcode.react";
var server = new StellarSdk.Server("https://horizon-testnet.stellar.org");

const LoadWallet = (
  { ForEncryptedKey, setZptcBalance, walletConnect },
  showModal
) => {
  const [secretKey, setSecretKey] = useState("");
  const [Private, setPrivateKey] = useState("");
  const [loading, setLoading] = useState(false);
  const [loader, setLoader] = useState(false);

  const [URI, setURI] = useState();

  let sourceKeys;
  let publicKeys;

  const resetDetails = () => {
    setPrivateKey("");
    setSecretKey("");
  };

  const onError = () => {
    console.log("ERROR from load wallert");
    toast.error(<ErrorToast message={errorMessage.VALID_CREDENTIAL_ERROR} />, {
      toastId: 1,
      updateId: 1,
    });
  };
  const onSuccess = () => {
    console.log("Success");
    toast.success(<SuccessToast message={successMessage.WALLETLOADED} />, {
      toastId: 1,
      updateId: 1,
    });
  };

  useEffect(() => {
    if (loading) {
      try {
        walletConnect.login(
          (URI) => {
            setURI(URI);
          },
          async () => {
            console.log("LOGIN complete");

            setLoading(false);
            window.location.reload();
          },
          () => {
            setLoading(false);
          }
        );
      } catch (e) {
        console.log("eer", e);
      }
    }
  }, [loading]);

  return (
    <div>
      <div className="popup-link" onClick={() => setLoading(true)}>
        <div className="loadWallets d-flex cur-pointer ">
          <img width={30} className="mx-1" src={WalletIcon} />
          <div>Wallet Connect</div>
          <div className="f-12 text-dark mx-1">beta</div>
        </div>
      </div>

      {loading && (
        <Modal
          size="md"
          show={showModal}
          onHide={() => {
            setLoading(false);
            resetDetails();
          }}
        >
          <Modal.Header closeButton>
            <Modal.Title className="text-dark launchWalletText">
              Scan QR code with Lobstr Wallet{" "}
              {window.innerWidth <= 500 && "or click on launch wallet"}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div style={{ "text-align": "center" }}>
              {URI && (
                <>
                  {" "}
                  <QRCode value={URI} size={206} renderAs="svg" />
                  {console.log(window.innerWidth)}
                  {window.innerWidth <= 500 && (
                    <Button
                      style={{
                        width: "60%",
                        height: "40px",
                        marginTop: "10px",
                        background: "#d5c79c",
                        color: "white",
                        fontWeight: "600",
                      }}
                      onClick={() => {
                        try {
                          window.location.replace(URI);
                        } catch (e) {
                          console.log(e);
                        }
                      }}
                    >
                      Launch wallet
                    </Button>
                  )}
                </>
              )}
            </div>
          </Modal.Body>
        </Modal>
      )}
    </div>
  );
};

export default LoadWallet;
