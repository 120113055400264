import React, { useState, useEffect, useContext } from "react";
import "bootstrap-icons/font/bootstrap-icons.css";
import "./Swap.css";
import { swapTransaction } from "../../redux/apicalltemplate";
import { stellarApis } from "../../redux/api";
import CryptoJS from "crypto-js";
import { toast } from "react-toastify";
import PinInput from "react-pin-input";

import StellarSdk from "stellar-sdk";
import StellarBase from "stellar-base";

import { Form, Modal } from "react-bootstrap";
import {
  SuccessToast,
  ErrorToast,
} from "../../components/ToastMessage/SuccessToast";
import { errorMessage, stringConstants, successMessage } from "../../constants";
import BlockchainService from "../../services/BlockchainService";
import { useDispatch } from "react-redux";
import { setOverlayLoader } from "../../context";
import InfoBox from "../../components/InfoMessage/InfoHover";

import axios from "axios";
import SwalError from "../../components/ToastMessage/SwalError";

const blockchainService = new BlockchainService();
var server = new StellarSdk.Server("https://horizon-testnet.stellar.org");
let accountForTransaction;

const Swap = ({
  ual,
  showModal,
  hideModel,
  setRefreshTransaction,
  refreshTransaction,
  setZptcBalance,
  zptcBalance,
  walletConnect,
  token,
}) => {
  const [accountName, setAccountName] = useState("");
  const [stellarAmount, setStellarAmount] = useState("");
  const [telosAmount, setTelosAmount] = useState("");
  const [secretKey, setSecretKey] = useState("");
  const [PrivateKey, setPrivateKey] = useState("");
  const [decryptedSecretKey, setDecryptedSecretKey] = useState("");
  const [responseStatus, setResponseStatus] = useState(false);
  const [userAccount, setUserAccount] = useState("");
  const [loadingSwap, setLoadingSwap] = useState(false);

  const [hide, setHide] = useState(false);
  const [showload, setShowLoad] = useState(false);

  const encryptedKey = localStorage.getItem(
    stringConstants.ENCRYPTED_KEY_LOCAL_STORAGE
  );

  var zptcbalance = localStorage.getItem("zptcBalance");
  // console.log("zptcbalance", zptcbalance);

  const getAccountName = async () => {
    setAccountName(ual.activeUser?.accountName);
    accountForTransaction = ual.activeUser?.account;
    const [info, balance] = await Promise.all([
      blockchainService.getAccountInfo(ual.activeUser?.accountName),
      blockchainService.getTokenBalance(ual.activeUser?.accountName),
      blockchainService.getAccountName(ual.activeUser?.accountName),
    ]);
  };

  //generate random number
  var randomNumber = Math.floor(Math.random() * 100000000000000);

  const telosTransaction = async () => {
    function addZeroes(num) {
      return num.toLocaleString("en", {
        useGrouping: false,
        minimumFractionDigits: 4,
      });
    }
    let params = {};
    if (stellarAmount.includes(".")) {
      params = {
        receiverAccount: ual.activeUser?.accountName,
        quantity: `${addZeroes(Number(stellarAmount))} ZPTC`,
        addMemo: randomNumber,
      };
    } else {
      params = {
        receiverAccount: ual.activeUser?.accountName,
        quantity: `${stellarAmount}.0000 ZPTC`,
        addMemo: randomNumber,
      };
    }
    const responseData = await swapTransaction(
      stringConstants.METHOD.POST,
      `${stellarApis.TELOS_ADMIN_TO_USER_TRANSACTION}`,
      {},
      params,
      token
    );

    setRefreshTransaction(refreshTransaction + 1);

    console.log("responseData", responseData);
  };

  const preventMinus = (e) => {
    if (e.code === "Minus") {
      e.preventDefault();
    }
  };

  // const refresh = (e) => {
  //   window.location.reload();
  //   e.preventDefault();
  // };

  // const onError = () => {
  //   toast.error(<ErrorToast message={errorMessage.SECRET_KEY_ERROR} />, {
  //     toastId: 1,
  //     updateId: 1,
  //   });
  // };
  const errorForBalance = () => {
    toast.error(<ErrorToast message={errorMessage.BALANCE_ERROR} />, {
      toastId: 1,
      updateId: 1,
    });
  };
  // const errorForZeroBalance = () => {
  //   toast.error(<ErrorToast message={errorMessage.ZERO_BALANCE_ERROR} />, {
  //     toastId: 1,
  //     updateId: 1,
  //   });
  // };

  // const onSuccess = () => {
  //   toast.success(
  //     <SuccessToast message={successMessage.TRANSACTION_SUCCESSFUL} />,
  //     {
  //       toastId: 1,
  //       updateId: 1,
  //     }
  //   );
  // };

  const validateInput = (value) => {
    const pattern = new RegExp("^([0-9][0-9.]*)$");
    if (
      value === "00" ||
      value === "000" ||
      value === "0000" ||
      value === "0.00"
    ) {
      return value?.slice(0, value.length - 1);
    } else {
      if (pattern.test(value)) {
        if (value.includes(".")) {
          let whole = value?.split(".")[0];
          let decimal = value?.split(".")[1];
          whole = whole.slice(0, 4);
          decimal = decimal.slice(0, 4);
          return whole + "." + decimal;
        } else {
          const res = value.slice(0, 4);
          return res;
        }
      } else {
        return value?.slice(0, value.length - 1);
      }
    }
  };

  const dispatch = useDispatch();

  const validateBalance = async (e) => {
    e.preventDefault();

    console.log("ZPTC", zptcBalance);
    console.log("Stellar", stellarAmount);
    if (
      Number(zptcbalance) < Number(stellarAmount) ||
      Number(stellarAmount) == 0
    ) {
      errorForBalance();
      return;
    }

    setLoadingSwap(true);
    setShowLoad(true);
    if (token) {
      try {
        const isValidToken = await axios.get(
          `${process.env.REACT_APP_BASE_URL}api/v1/user/token/check`,
          {
            headers: {
              "x-auth-token": token,
            },
          }
        );
        if (
          !(
            isValidToken.data &&
            isValidToken.data.success &&
            isValidToken.data.message === "Valid Token."
          )
        ) {
          setLoadingSwap(false);
          setShowLoad(false);
          SwalError("Token expired, please login again");
          localStorage.removeItem(stringConstants.ENCRYPTED_KEY_LOCAL_STORAGE);
          localStorage.removeItem("zptcBalance");
          window.location.reload();
          return;
        }
      } catch (error) {
        setLoadingSwap(false);
        setShowLoad(false);
        localStorage.removeItem(stringConstants.ENCRYPTED_KEY_LOCAL_STORAGE);
        localStorage.removeItem("zptcBalance");
        window.location.reload();
        return;
      }
    }

    await walletConnect.signAndSubmit(
      setLoadingSwap,
      stellarAmount,
      hideModel,
      telosTransaction,
      randomNumber,
      setShowLoad,
      token
    );
  };

  useEffect(() => {
    if (loadingSwap) {
      setShowLoad(true);
    } else {
      setShowLoad(false);
    }
  }, [loadingSwap]);

  // const validateTransaction = (e) => {
  //   try {
  //     if (secretKey) {
  //       e.preventDefault();
  //       const bytes = CryptoJS.AES.decrypt(encryptedKey, secretKey);
  //       setDecryptedSecretKey(JSON.parse(bytes.toString(CryptoJS.enc.Utf8)));
  //       getAccountName();
  //       setLoader(true);
  //     } else if (PrivateKey) {
  //       getAccountName();
  //       setLoader(true);
  //     } else {
  //       onError();
  //     }
  //   } catch (error) {
  //     onError();
  //   }
  // };

  console.log(
    "UAL",
    ual,
    "SHOW MODEL----->",
    showModal,
    "HIDE MODEL----->",
    hideModel,
    "setRefreshTransaction------>>>",
    setRefreshTransaction,
    "RefreshTransaction --->>>",
    refreshTransaction,
    "setZptcBalance",
    setZptcBalance,
    "zptcBalance",
    zptcBalance,
    "walletConnect ---->>>",
    walletConnect,
    "TOKEN",
    token
  );

  return (
    <Modal
      size="md"
      show={showModal}
      centered
      onHide={() => {
        hideModel(false);
      }}
      backdrop="static"
    >
      <div className={`${showload ? "show-load" : "hide-load"}`}>
        <div class="spinner-grow my-2" role="status">
          <span class="sr-only"></span>
        </div>
        <div className="text-dark text-center w-100">
          Please don't refresh and move out of the tab. Please check your lobstr
          wallet to sign this transaction. It will take few seconds.
        </div>
      </div>

      <Modal.Header className={`${!showload ? "" : "hide-load"}`} closeButton>
        <Modal.Title className="text-dark">
          <div className="heading">
            <div className="heading-text">
              <div>
                Swap ZPTC{" "}
                <InfoBox
                  className="ml-2"
                  msg={
                    <p>
                      To see guide, checkout our video tutorial{" "}
                      {
                        <a
                          href="https://www.youtube.com/watch?v=6BG6U68_B28"
                          target="_blank"
                        >
                          click here
                        </a>
                      }
                    </p>
                  }
                />{" "}
              </div>
            </div>
          </div>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className={`${!showload ? "" : "hide-load"}`}>
        <div>
          <div className="trade-text">
            <h6>Swap zptc from stellar to telos</h6>
          </div>
          <div className="col-sm-4 pt-4">
            <div className="form-control swap-stellar-zptc">Stellar ZPTC</div>
          </div>
          <div>
            <input
              type="string"
              name="stellarAmount"
              min="0"
              align="left"
              onKeyPress={preventMinus}
              className={"input-box-sm input"}
              value={stellarAmount}
              placeholder="0.0"
              onInput={(e) => (e.target.value = validateInput(e.target.value))}
              onChange={(event) => setStellarAmount(event.target.value)}
              required
            ></input>
          </div>
          <div className="d-flex justify-content-between align-items-center">
            <button
              type="button"
              className="button-medium border-shadow cur-pointer more-button box-shadow  px-3 "
              onClick={async (e) => {
                setStellarAmount(Number(zptcbalance));
              }}
            >
              Max
            </button>
            <div className="text-dark f-12 text-end">
              **enter stellar zptc amount{" "}
            </div>
          </div>
        </div>

        <div className="logo">
          {" "}
          <button
            style={{ color: "rgb(213, 199, 156" }}
            className="bi bi-arrow-down-circle-fill arrow-button"
          ></button>
        </div>

        <div>
          <div className="col-sm-4 pt-2">
            <div className="form-control swap-telos-zptc">Telos ZPTC</div>
          </div>
          <div>
            <input
              type="number"
              min="0"
              name="telosAmount"
              disabled={true}
              onKeyPress={preventMinus}
              className={"input-box-sm input"}
              value={stellarAmount}
              placeholder="0.0"
              // onChange={(event) =>
              //   setTelosAmount(event.target.value)
              // }
              required
            ></input>
          </div>

          <div className="text-dark f-12 text-end">
            **Equivalent Telos ZPTC{" "}
          </div>
        </div>

        <div>
          <div className="swap">
            <div
              className="submit-transaction"
              disabled={loadingSwap}
              onClick={validateBalance}
            >
              {loadingSwap ? (
                <span
                  className="spinner-border spinner-border-smd"
                  role="status"
                  aria-hidden="true"
                ></span>
              ) : (
                "Swap"
              )}
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};
export default Swap;
