export const kycViewConstants = [
  "Salary",
  "Personal savings",
  "Pension release",
  "Investments sales",
  "Property sales",
  "Gambling winnings",
  "inheritance",
  "Other, please specify"
];
export const httpConstants = {
  CONTENT_TYPE: {
    APPLICATION_JSON: "application/json",
    MULTIPART_FORM_DATA: "multipart/form-data",
    APPLICATION_FORM_URLENCODED: "application/x-www-form-urlencoded",
    IMAGE_PNG: "image/png",
  },
}

export const stringConstants = {
  PUBLIC_KEY_LOCAL_STORAGE: "PublicKey",
  ENCRYPTED_KEY_LOCAL_STORAGE: "StellarPrivateKey",

  METHOD: {
    POST: "POST",
    GET: "GET",
    PUT: "PUT",
    DELETE: "DELETE",
    UPDATE: "UPDATE",
  },
};

export const errorMessage = {
  SECRET_KEY_ERROR: "Please provide valid key",
  BALANCE_ERROR: "Insufficient balance or Enter a valid amount",
  ZERO_BALANCE_ERROR: "Enter valid amount",
  VALID_CREDENTIAL_ERROR: "Please provide valid private key",
  PRIVATEKEY_VALIDATION: "Please provide valid private key",
  ACCOUNT_CREATED_ERROR: "Error while creating account",
};

export const successMessage = {
  TRANSACTION_SUCCESSFUL: "Transaction is successful",
  WALLETLOADED: "Wallet loaded successfully",
  ACCOUNT_CREATED_SUCCESS : "Account Created Successfully",
  COPY_SUCCESS: "Copied",
};
