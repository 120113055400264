import React from "react";
import { Modal, Spinner } from "react-bootstrap";
import moment from "moment";
import ShareTray from "../ShareTray/ShareTray";
import ReactPlayer from "react-player/lazy";
import { useState } from "react";

import NodataFound from "../../icons/cancel.svg";
const CrowdsaleDetails = ({
  show,
  setShow,
  data,
  liveprice,
  loading = false,
  enableStop = false,
}) => {
  const [isPlayerVisible, setIsPlayerVisible] = useState(true);
  const [isSupportVisible, setIsSupportVisible] = useState(false);
  const handleClose = () => setShow(false);
  const renderCoOwnersInAlbum = () => {
    const { coOwnersInAlbum } = data.musicId;

    return coOwnersInAlbum.map((coOwner, i) => {
      return (
        <>
          <li className="d-flex">
            <span className="text-dark">Owner: </span>
            <div className="wrap-word " title={coOwner.coOwnerName}>
              {" "}
              {coOwner.coOwnerName}{" "}
            </div>
          </li>
          <li className="d-flex">
            <span className="text-dark">{`${coOwner.coOwnerName} Ownership :`}</span>{" "}
            {`${coOwner.coOwnerPercentageRights}%`}{" "}
          </li>
        </>
      );
    });
  };

  const renderContributors = () => {
    const { contributors } = data.musicId;

    return contributors.map((contributor, i) => {
      return (
        <li>
          <span className="text-dark">{contributor.contributorType} :</span>{" "}
          {contributor.contributorName}{" "}
        </li>
      );
    });
  };

  const handleStopCrowdsale = () => { };

  const DollarToZPTC = (price, rate) => {
    const priceInCents = price * 100;
    const ZPTCPerCent = 1 / priceInCents;
    const rateInCent = rate * 100;
    return (ZPTCPerCent * rateInCent).toFixed(2);
  };

  if (loading) {
    return (
      <Spinner hidden={false} className="position-center" animation="border" />
    );
  }
  const handleError = (e) => {
    setIsPlayerVisible(false);
    setIsSupportVisible(true);
  };

  const Capitalize = (str) => {
    return str.charAt(0).toUpperCase() + str.slice(1);
  };

  return (
    <Modal show={show} onHide={handleClose} size="lg">
      <Modal.Header closeButton>
        <Modal.Title>
          {data.musicId && data.musicId.musicType === "new"
            ? "Crowdfund"
            : "Crowdsale"}{" "}
          Details
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="p-5">
        <div style={{ fontWeight: "bold" }}>
          <div className="align-center m-2 ">
            <img
              src={data.musicId.bitMapImage}
              className="img-mid box-shadow"
              alt="Song Image"
            />
          </div>
          {isPlayerVisible ? (
            data.musicId &&
            data.musicId.streamingAppLink && (
              <div className="align-center">
                <ReactPlayer
                  url={data.musicId.streamingAppLink}
                  onError={handleError}
                />
              </div>
            )
          ) : isSupportVisible ? (
            <div className="d-flex flex-column align-center m-2">
              <img
                style={{ maxWidth: "250px", height: "250px" }}
                src={NodataFound}
                alt="video"
              />
              <h6 className="mt-3">
                Unsupported Format.{" "}
                <a
                  className="text-dark "
                  href={data.musicId.streamingAppLink}
                  target="_blank"
                >
                  <i
                    className="far fa-play-circle"
                    style={{ cursor: "pointer", fontSize: "20px" }}
                  ></i>
                  <span className="text-dark mx-1"> Listen/Watch </span>
                </a>{" "}
                here.
              </h6>
            </div>
          ) : null}

          <div className="row mt-4 ">
            <div className="col-md-6">
              <ul>
                {data.tokenName ? (
                  <li>
                    <span className="text-dark">Token Name: </span>
                    {data.tokenName}{" "}
                  </li>
                ) : (
                  ""
                )}
                {data.musicId.shareHolderName ? (
                  <li>
                    <span className="text-dark">Shareholder Name: </span>
                    {data.musicId.shareHolderName}{" "}
                  </li>
                ) : (
                  ""
                )}
                {data.musicId.contentType ? (
                  <li>
                    <span className="text-dark">Content Type: </span>
                    {data.musicId.contentType}{" "}
                  </li>
                ) : (
                  ""
                )}

                {data?.musicId?.sortName ? (
                  <li>
                    <span className="text-dark">Short Name: </span>
                    {data.musicId.sortName}{" "}
                  </li>
                ) : (
                  ""
                )}
                {data.musicId.longName ? (
                  <li>
                    <span className="text-dark">Long Name: </span>{" "}
                    <div className="wrap-word " title={data.musicId.longName}>
                      {data.musicId.longName}{" "}
                    </div>
                  </li>
                ) : (
                  ""
                )}
                {data.state ? (
                  <li>
                    <span className="text-dark">State: </span>
                    {data.state === "stopped" ||
                      data.state === "rejected" ||
                      data.state === "failed" ? (
                      <span style={{ color: "red" }}>
                        {Capitalize(data.state)}
                      </span>
                    ) : (
                      <>{Capitalize(data.state)}</>
                    )}{" "}
                  </li>
                ) : (
                  ""
                )}

                {data.verification && data.verification.request ? (
                  <li>
                    <span className="text-dark ">Crowdsale Status: </span>
                    {data.verification.request}{" "}
                  </li>
                ) : (
                  ""
                )}
                {data.rDuration ? (
                  <li>
                    <span className="text-dark">Royalty Duration: </span>
                    {data.rDuration} years{" "}
                  </li>
                ) : (
                  ""
                )}
                {data.percentageRights ? (
                  <li>
                    <span className="text-dark">Percentage Rights: </span>
                    {data.percentageRights} %{" "}
                  </li>
                ) : (
                  ""
                )}

                {data && data.rate && data.deployedIn === "USD" ? (
                  <li>
                    <span className="text-dark">Rights per percente: </span>
                    {data.rate.toFixed(2)} {data.deployedIn} |{" "}
                    {liveprice &&
                      DollarToZPTC(
                        liveprice.data.zeptaPrice,
                        data.rate.toFixed(2)
                      )}{" "}
                    ZPTC
                  </li>
                ) : (
                  <li>
                    <span className="text-dark">Rights per percente: </span>
                    {data.rate.toFixed(2)} {data.deployedIn} | $
                    {liveprice &&
                      (data.rate * liveprice.data.zeptaPrice).toFixed(2)}
                  </li>
                )}

                {data.softCap ? (
                  <li>
                    <span className="text-dark">Soft-cap: </span>${" "}
                    {/* {data.softCap}{" "} */}
                    200
                  </li>
                ) : (
                  ""
                )}

                {data.minContribution ? (
                  <li>
                    <span className="text-dark">Min-contribution: </span>${" "}
                    {data.minContributionByUser}{" "}
                  </li>
                ) : (
                  ""
                )}
                {data.maxContributionByUser ? (
                  <li>
                    <span className="text-dark">Max-contribution: </span>${" "}
                    {data.maxContributionByUser}{" "}
                  </li>
                ) : (
                  ""
                )}
                {data.targetAmountByUser ? (
                  <li>
                    <span className="text-dark">Target Amount: </span>{" "}
                    {data.targetAmountByUser} {data.deployedIn}
                  </li>
                ) : (
                  ""
                )}
                {data &&
                  data.musicId &&
                  data.musicId.artists &&
                  data.musicId.artists.length ? (
                  <>
                    <li className="d-flex">
                      <span className="text-dark">Artist Name: </span>{" "}
                      <div
                        className="wrap-word"
                        title={data.musicId.artists[0].artistName}
                      >
                        {data.musicId.artists[0].artistName}
                      </div>
                    </li>
                    <li>
                      <span className="text-dark">Artist Role: </span>
                      {data.musicId.artists[0].artistType}{" "}
                    </li>
                  </>
                ) : (
                  ""
                )}
                {data && data.musicId && data.musicId.contentType === "album"
                  ? renderCoOwnersInAlbum()
                  : null}
                {/*
                {data && data.musicId && data.musicId.contentType === "album"
                  ? renderContributors()
                  : null} */}
              </ul>
            </div>
            <div className="col-md-6">
              <ul>
                {data.musicId.genres && data.musicId.genres.length ? (
                  <li>
                    <span className="text-dark">Genre: </span>
                    <div className="wrap-word" title={data.musicId.genres[0]}>
                      {data.musicId.genres[0]}{" "}
                    </div>
                  </li>
                ) : (
                  ""
                )}
                {data.musicId.upc ? (
                  <li>
                    <span className="text-dark">UPC: </span>
                    {data.musicId.upc}{" "}
                  </li>
                ) : (
                  ""
                )}
                {data.musicId && data.musicId.musicType ? (
                  <li>
                    <span className="text-dark">Type: </span>
                    {data.musicId.musicType == "new"
                      ? "Crowdfund"
                      : "Crowdsale"}{" "}
                  </li>
                ) : (
                  ""
                )}
                {data.startDate ? (
                  <li>
                    <span className="text-dark">Start Date: </span>
                    {moment(data.startDate).format(
                      "ddd, DD MMMM YYYY hh:mm a"
                    )}{" "}
                  </li>
                ) : (
                  ""
                )}
                {data.endDate ? (
                  <li>
                    <span className="text-dark">End Date: </span>
                    {moment(data.endDate).format("ddd, DD MMMM YYYY hh:mm a")}
                  </li>
                ) : (
                  ""
                )}
                {data.musicId.contentDetails.duration ? (
                  <li>
                    <span className="text-dark">Duration: </span>
                    {data.musicId.contentDetails.duration} min
                  </li>
                ) : (
                  ""
                )}

                {data.zeptaCoinsRaised ? (
                  <li>
                    <span className="text-dark">Zepta Coin Raised: </span>
                    {Number(data.zeptaCoinsRaised).toFixed(4)} ZPTC
                  </li>
                ) : (
                  ""
                )}
                {data && data.deployedIn === "USD" ? (
                  <>
                    {data.usdCoinsRaised ? (
                      <li>
                        <span className="text-dark">Fiat($) Raised:</span>{" "}
                        {`$${Number(data.usdCoinsRaised.toFixed(2))}`}
                      </li>
                    ) : (
                      ""
                    )}
                  </>
                ) : (
                  <>
                    {data.zeptaCoinsRaised ? (
                      <li>
                        <span className="text-dark">Fiat($) Raised:</span>{" "}
                        {`$${liveprice
                            ? Number(
                              (
                                data.zeptaCoinsRaised *
                                liveprice.data.zeptaPrice
                              ).toFixed(2)
                            )
                            : 0
                          }`}
                      </li>
                    ) : (
                      ""
                    )}
                  </>
                )}
                {data.musicId &&
                  data.musicId.royaltyStatement &&
                  data.musicId.royaltyStatement.url ? (
                  <li>
                    <a
                      href={data.musicId.royaltyStatement.url}
                      target="_blank"
                    // className="text-grey"
                    >
                      Current Royalty Statement
                    </a>
                  </li>
                ) : (
                  ""
                )}
                {data.featured_by ? (
                  <li>
                    <span className="text-dark">Featured By: </span>
                    {data.featured_by}
                  </li>
                ) : (
                  ""
                )}

                <li>
                  <ShareTray data={data} />
                </li>
              </ul>
            </div>
            <div className="row mx-3 ">
              <ul style={{ marginLeft: "5px" }}>
                <li className="text-dark">Description:</li>
                <li>
                  <div className="dataDesc text-justify">
                    {data.musicId.description}
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </Modal.Body>
      {/* <Modal.Footer className='p-3'>
            <button className='button-medium border-shadow   medium-btn' type='submit' title={"Update Profile"} >Submit</button>
        </Modal.Footer> */}
    </Modal>
  );
};

export default CrowdsaleDetails;
