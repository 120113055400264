import React, { useState } from "react";
import { Button } from "react-bootstrap";
import { X, XLg } from "react-bootstrap-icons";
import { useHistory } from "react-router";
const NoticeBar = ({ isVisible = false, message = "" }) => {
  const history = useHistory();
  const [show, setShow] = useState(true);
  const handleClose = () => setShow(false);

  const inlineBlock = {
    display: "inline-block !important",
  };
  return (
    <>
      <center>
        {isVisible && show ? (
          <div
            style={{
              backgroundColor: "#D5C79C",
              padding: "8px",

              marginBottom: "5px",
            }}
          >
            <div style={inlineBlock}>
              {message}
              <XLg
                style={{
                  display: "inline-block !important",
                  float: "right",
                  cursor: "pointer",
                  marginTop: "4px",
                }}
                onClick={handleClose}
              />{" "}
            </div>
          </div>
        ) : null}
      </center>
    </>
  );
};

export default NoticeBar;
