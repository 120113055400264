import React, { useState } from "react";
import { Form, Modal } from "react-bootstrap";
import { SubmitButton } from "../../components/components";

export default function SendZptcModal({
  showModal,
  hideModal,
  ual,
  account,
  onSubmit,
}) {
  const [accountName, setAccountName] = useState("");
  const [quantity, setQuantity] = useState("");
  const [memo, setMemo] = useState("");
  const resetDetails = () => {
    setAccountName("");
    setMemo("");
    setQuantity("");
  };
  return (
    <Modal
      size="md"
      show={showModal}
      onHide={() => {
        hideModal(false);
        resetDetails();
      }}
    >
      <Modal.Header closeButton>
        <Modal.Title className="text-dark">Send ZeptaCoin</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <label className="input-label">Account name</label>
          <input
            className={"input-box-sm"}
            type="text"
            name="account"
            value={accountName}
            placeholder="Account name"
            onChange={(e) => setAccountName(e.target.value)}
          />
          <label className="input-label">Quantity</label>
          <input
            className={"input-box-sm"}
            type="number"
            placeholder="Quantity"
            min={0}
            value={quantity}
            onWheel={(e) => e.target.blur()}
            name="quantity"
            onChange={(e) => setQuantity(e.target.value)}
          />
          <label className="input-label">Memo</label>
          <input
            className={"input-box-sm"}
            type="text"
            placeholder="Memo"
            name="memo"
            value={memo}
            onChange={(e) => setMemo(e.target.value)}
          />
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <SubmitButton
          onClick={() => {
            onSubmit(account, accountName, quantity, memo);
          
          }}
          title={"Send"}
          loading={false}
        />
      </Modal.Footer>
    </Modal>
  );
}
