import React from 'react'

export const UserContext = React.createContext();


const initialState = {
    overlayLoader: false,
    loadertext : `Please don't refresh and move out of the tab. Please check your lobstr wallet to sign this transaction. It will take few seconds. `
  };
  
  const types = {
    SET_OVERLAY: "SET_OVERLAY",
  };

  export const appDataReducer = (state = initialState, action) => {
    switch (action.type) {
      case types.SET_OVERLAY:
        return {
            overlayLoader:action.payload,
            ...state
        };
       
      default:
        return state;
    }
  };

  //ACTIONS

  export const setOverlayLoader = (data) => ({
    type: types.SET_OVERLAY,
    payload: data,
  });