import { WidthBox } from "../components/components";
import "../styles/style.scss";
import InfoBox from "./InfoMessage/InfoHover";

export default function WalletButton({
  icon,
  title,
  onClick,
  msg = "",
  disable,
}) {
  return (
    <>
      <div
        className={
          "wallet-button d-flex flex-row justify-content-center align-items-center"
        }
        onClick={disable ? null : onClick}
      >
        <img width={"20px"} height={"20px"} src={icon} />
        <WidthBox width={"12px"} />

        <span className={"add-payment-button-text"}>{title}</span>
        {disable && (
          <a href="https://zeptagram.com/investor-relations" target="_blank">
            <InfoBox className="ml-2" msg={msg} />
          </a>
        )}
      </div>
    </>
  );
}
