import React, { useEffect, useState } from "react";
import { ProgressBar } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { getRequiredProtectedData } from "../../redux/apicalltemplate";
import { userApis } from "../../redux/api";
import { useDispatch, useSelector } from "react-redux";
import { bookmarkStart } from "../../redux/user/Action";

const CrowdCardMedium = ({
  el,
  handleCrowdsaleDetails,
  handleInvest,
  liveprice,
  type = "crowdsale",
  ismycrowdsale = false,
  userid = "",
  source = "",
  isBookmark=false,
}) => {
  const history=useHistory();
  const [color,setColor]=useState(false);
  const dispatch=useDispatch();

 useEffect(()=>{
 
  setColor(isBookmark)
 },[isBookmark])

  const handleLike=async ()=>{
 
    setColor(!color);
    const response = await getRequiredProtectedData(
      "PUT",
      `${ !color ?  userApis.BOOKMARK:userApis.UNBOOKMARK }/${el._id}`,
      {},
      {}
    );

    if (response && response.data) {
      // setData(response.data.data);

      dispatch(bookmarkStart({}));
     
    }


  }
  return (
    <div className="container-sm m-2">
      <span
        className="custom_img_container"
        onClick={() => handleCrowdsaleDetails(el)}
      >
        <img
          className="mid-image"
          src={el.musicId && el.musicId.bitMapImage}
          alt="Music Thumbnail"
        />
        {source && source === "search" ? (
          <div class="custom_btn">
            {el.musicId && el.musicId.musicType === "new"
              ? "Crowdfund"
              : "Crowdsale"}
          </div>
        ) : (
          ""
        )}
      </span>
      <div className="heart-parent">
      <div className='heart' style={{right:'23px'}} onClick={()=>{
        handleLike()
        }}>
        <svg width="27" height="29" viewBox="0 0 32 29"
            fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M16 28.4948C31.583 16.2315 30.9971 11.8964 30.9971 8.49857C30.9971 5.10078 28.1852 1 23.4986 1C18.812 1 16 5.99905 16 5.99905C16 5.99905 13.188 1 8.50143 1C3.81483 1 1.00286 5.10078 1.00286 8.49857C1.00286 11.8964 0.41704 16.2315 16 28.4948Z" 
            fill={color?'red':'white'} stroke="black" stroke-width="0.15" />
        </svg>
      </div>
      </div>
      <div
        className="text-dark text-center data-ellipsis mw-200 med-title"
        title={el.musicId && el.musicId.longName}
      >
        {el.musicId && el.musicId.longName}
      </div>
      <div className="text-grey">
        {el.featured_by
          ? el.featured_by
          : el.musicId && el.musicId.shareHolderName}
      </div>
 
     

      {!ismycrowdsale && (
        <>
          {el ? (
            <>
              <ProgressBar
                className="progressBar"
                variant={"success"}
                now={(el.usdCoinsRaised / el.targetAmountByUser) * 100}
              />
              <span className="text-dark" style={{ fontSize: "13px" }}>
                <span
                  style={{ color: "#4BB543" }}
                >{`$${el.usdCoinsRaised}`}</span>
                {` raised of $${el.targetAmountByUser}`}
              </span>
            </>
          ) : (
          
            <></>
          )}
          <div
            className="align-center"
            style={{ height: "60px", width: "100%" }}
          >
            {userid &&
              (userid === el.isuuer || el.state === "successful" ? null : (
                <button
                  className="button-medium border-shadow w-100 m-2"
                  onClick={() => handleInvest(el, type)}
                >
                  Invest
                </button>
              ))}

           {!localStorage.getItem('token') &&  (
                <button
                  className="button-medium border-shadow w-100 m-2"
                  onClick={() => history.push('/login')}
                >
                  Invest
                </button>
               )}
          </div>
        </>
      )}
    </div>
  );
};

export default CrowdCardMedium;
