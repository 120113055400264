
 
import React from "react";
import { Tabs,Tab } from "react-bootstrap";
import { MusicCard } from "./MusicCard";
import { CrowdsaleList } from "./CrowdsaleList";

export default function ArtistPage() {


    return (
        <div className={"wrapper h-100"} style={{ background: 'white' }}>
            <div className="align-center " style={{ background: '#D4C7A1', height: '200px' }}>

            </div>
            <div className="container my-2 ">

                <div className="d-flex row " style={{ marginTop: '50px', position: 'relative' }}>
                    <div style={{
                        height: '280px', width: '220px',
                        transform: 'translateY(-175px)',
                        position: 'absolute',
                        padding: 0
                    }} className="col-md-3  box-shadow border-radius m-2">
                        
                            <img className="border-radius" style={{background: 'white',
                                width: '100%',height:'200px'}} src={'https://i.ibb.co/Sx3v0D5/domain-names-g1cbbfe1d2-1920.png'}/>
                                <div style={{marginTop:'20px'}}  className="mx-2 w-100 align-center ">
                                <button className="button-medium ">Follow</button> 
                                </div>
      
                    </div>
                    <div className="col-md-3 m-3 " style={{
                        height: '80px', width: '220px'
                    }}> </div>

                    <div className="col-md-9 p-3 box-shadow border-radius m-2 text-dark">Lorem ipsum dolor sit amet consectetur. Turpis malesuada malesuada semper sollicitudin purus cursus. Vel mauris ultrices enim felis ac nibh sed. Malesuada neque ut bibendum consectetur fames. Facilisis augue pellentesque ultrices eu.
                    </div>
                </div>
                <div>
                <Tabs  defaultActiveKey="home" className='align-center tabbss  '>
                    <Tab className="text-dark"  eventKey="home" title="Home">
                    <MusicCard/>
                    </Tab>
                    <Tab  className="text-dark"  eventKey="profile" title="Profile">
                     <CrowdsaleList/>
                    </Tab>
                    
                    </Tabs>
                                    </div>

                <div>

                </div>
            </div>
        </div>
    );
}
