import React, { useEffect, useState } from "react";
import { useFetch } from "../../api/api_client";
import { userApis } from "../../redux/api";
import { LoadingView } from "../../components/loading_view";
import copyIcon from "../../icons/copy.png";
import {
  Col,
  Dropdown,
  Form,
  Image,
  Row,
  DropdownButton,
} from "react-bootstrap";
import RefreshIcon from "../../icons/refresh.svg";
import { WidthBox } from "../../components/components";
import moment from "moment";
import { getTxnName, txnList } from "../../utils/utils";
import { CopyToClipboard } from "react-copy-to-clipboard/lib/Component";
import { SuccessToast } from "../../components/ToastMessage/SuccessToast";
import { toast } from "react-toastify";

export default function TransactionHistory({ accountName }) {
  const [page, setPage] = useState(1);
  const [searchEnable, setSearchEnable] = useState(false);
  const [refresh, setRefresh] = useState(1);
  const [order, setOrder] = useState("-1");
  const [orderName, setOrderName] = useState("Decreasing");
  const [txnName, setTxnName] = useState("");
  const [txnType, setTransactionType] = useState("");
  const [sorobanTxn, setSorobanTxn] = useState([])

  const musicLimit = 6;

  const { loading, error, data } = useFetch(
    `${userApis.TRANSACTION_HISTORY}?page=${page}&limit=${musicLimit}&order=${order}&txnType=${txnType}`,
    [page, refresh, order, txnType]
  );
  const clearParams = () => {
    
    setOrderName("Decreasing");
    setTransactionType("");
    setTxnName("");
    setOrder("-1");
  };

  useEffect(() => {
  const fetchData = async () => {
    const token = localStorage.getItem("token")
    try {
      const sorobanTxnHistoryResponse = await fetch(`${userApis.SOROBAN_TXN_HISTORY}`, {
        method: "GET",
        headers: {
          'x-auth-token': token, // Replace 'YOUR_AUTH_TOKEN' with your actual token
          'Content-Type': 'application/json'
        }
      });

      if (!sorobanTxnHistoryResponse.ok) {
        throw new Error('Network response was not ok');
      }

      const responseData = await sorobanTxnHistoryResponse.json();
      console.log("==============sorobanTxnHistoryResponse===============", responseData);
      setSorobanTxn(responseData.data)
    } catch (error) {
      console.error("Error fetching data: ", error);
    }
  };

  fetchData();
}, []);


  return (
    <div style={{ minHeight: "200px" }}>
      <div style={{ marginBottom: "16px" }}>
        <div className={"d-flex justify-content-between"}>
          <span
            onClick={() => {
              setRefresh(refresh + 1);
            }}
          >
            <h6 style={{ display: "inline-block" }}>Transaction History</h6>
            <WidthBox width={"4px"} />
            <Image src={RefreshIcon} className={"icon-setting"} />
          </span>
          <div className="text-end m-2">
            <i
              className="fas fa-filter cur-pointer text-dark"
              onClick={() => setSearchEnable(!searchEnable)}
            ></i>
          </div>
        </div>
      </div>
      {searchEnable && (
        <div className={`row  my-2 text-center `}>
          <div className="col-md-5">
            <label
              className="text-dark"
              htmlFor="mtoname"
              style={{ marginLeft: "10px" }}
            >
              Transaction Type
            </label>
            <DropdownButton
              title={txnName ? txnName : "Select a Transaction"}
              id="dropdown-basic-button"
              className="custom_dropdown input-box-sm  align-middle filter-dropdown"
              variant=""
            >
              {txnList.map((el, i) => (
                <Dropdown.Item
                  key={i}
                  onClick={() => (
                    setTransactionType(el.val), setTxnName(el.name)
                  )}
                >
                  <span>{el.name}</span>
                </Dropdown.Item>
              ))}
            </DropdownButton>
          </div>

          <div className="col-md-5">
            <label
              className="text-dark"
              htmlFor="mtoname"
              style={{ marginLeft: "10px" }}
            >
              Order By
            </label>
            <DropdownButton
              title={orderName ? orderName : "Select a Order"}
              id="dropdown-basic-button"
              className="custom_dropdown input-box-sm  align-middle filter-dropdown"
              variant=""
            >
              {[
                { val: "1", name: "Increasing" },
                { val: "-1", name: "Decreasing" },
              ].map((el, i) => (
                <Dropdown.Item
                  key={i}
                  onClick={() => (setOrder(el.val), setOrderName(el.name))}
                >
                  <span className="w-100">
                    {el.name}
                    {el.name === "Increasing" ? (
                      <i className="fas fa-angle-up mx-2"></i>
                    ) : (
                      <i className="fas fa-angle-down mx-2"></i>
                    )}
                  </span>
                </Dropdown.Item>
              ))}
            </DropdownButton>
          </div>
          <div className="col-md-2 d-flex align-items-end p-3">
            <button
              className="button-medium border-shadow mx-auto  medium-btn"
              type="submit"
              onClick={() => clearParams()}
            >
              Clear
            </button>
          </div>
        </div>
      )}
      {loading ? (
        <div style={{ height: "200px" }}>
          <LoadingView />
        </div>
      ) : data ? (
       data["data"].length === 0 ? (
  sorobanTxn.length > 0 ? (
    <div className={"table-responsive order-table box-shadow w-100"}>
        <table className={"table table-bordered table-hover "} >
                <thead>
                  <tr>
                    <th scope="col">User PublicKey</th>
                    <th scope="col">Action</th>
                    <th scope="col">Hash</th>
                    <th scope="col">Created_at</th>
                    <th scope="col">Status</th>
                  </tr>
                </thead>
                <tbody style={{backgroundColor:"white"}}>
                  {sorobanTxn.map((item) => {
                    return (
                      <tr>
                            <td style={{ display: "flex", gap: "10px", cursor: "pointer" }}>
                              {item.forUserPublickey && (
                                    <>
                                      {item.forUserPublickey.slice(0, 4)}
                                      {"..."}
                                      {item.forUserPublickey.slice(-4)}
                                    </>
                                  )}
                               <CopyToClipboard
                              text={item.forUserPublickey}
                              onCopy={() => {
                                  toast.success(<SuccessToast message={'Copied!'} />,{
                                      toastId:1,
                                      updateId:1
                                  });

                              }}
                            >
                            <img
                              src={copyIcon}
                              width={"17px"}
                              height={"17px"}
                              alt="copyIcon"
                              className={"mx-2 cur-pointer"}
                            />
                            </CopyToClipboard>
                            </td>
                        <td>
                          {item?.action}
                        </td>
                        <td style={{display:"flex", gap:"10px", cursor:"pointer"}}>
                         {item?.hash && (
                          <>
                            {item.hash.slice(0, 4)}
                            {"..."}
                            {item.hash.slice(-4)}
                          </>
                        )}
                        <CopyToClipboard
                              text={item.hash}
                              onCopy={() => {
                                  toast.success(<SuccessToast message={'Copied!'} />,{
                                      toastId:1,
                                      updateId:1
                                  });

                              }}
                            >
                            <img
                              src={copyIcon}
                              width={"17px"}
                              height={"17px"}
                              alt="copyIcon"
                              className={"mx-2 cur-pointer"}
                            />
                            </CopyToClipboard>
                        </td>
                         <td>
                          {item?.created_at}
                        </td>
                        <td>
                          {item?.status}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
      </div>
    
  ) : (
    <div style={{ height: "200px" }}>
      <span
        style={{ marginTop: "18px" }}
        className={"d-flex flex-row justify-content-center"}
      >
        {" "}
        No transaction found for this wallet
      </span>
    </div>
  )
) : (
          <>
            <div className={"table-responsive order-table box-shadow w-100"}>
              <table className={"table table-bordered table-hover"}>
                <thead>
                  <tr>
                    <th scope="col">Timestamp</th>
                    <th scope="col">From</th>
                    <th scope="col">To</th>
                    <th scope="col">Quantity</th>

                    <th scope="col">Type</th>
                    <th scope="col">Status</th>
                  </tr>
                </thead>
                <tbody>
                  {data.data.map((item) => {
                    return (
                      <tr>
                        <td>
                          {item["created_at"] &&
                            moment(item["created_at"]).format(
                              "DD MMM YYYY hh:mm a"
                            )}
                        </td>
                        <td>
                          {item["txnData"].from ? item["txnData"].from : "-"}
                        </td>
                        <td>{item["txnData"].to ? item["txnData"].to : "-"}</td>
                        <td>
                          {item["txnData"].quantity
                            ? item["txnData"].quantity
                            : item["txnData"].amount
                            ? item["txnData"].amount
                            : "-"}
                        </td>

                        <td>{item.txnType ? getTxnName(item.txnType) : "-"}</td>
                        <td>
                          {" "}
                          {item["txnData"].from
                            ? item["txnData"].from === accountName
                              ? "Sent"
                              : "Receive"
                            : item.confirmed
                            ? "Confirmed"
                            : "Not Confirmed"}
                        </td>
                        <td>
                          <i
                            onClick={() => {
                              const baseurl =
                                process.env.REACT_APP_ENV === "development"
                                  ? "telostest"
                                  : "telos";
                              const url = `https://eosauthority.com/transaction/${item.txnId}?network=${baseurl}`;
                              window.open(url, "_blank");
                              //  onSelect(item['_id'])
                            }}
                            className="fas fa-external-link-alt cur-pointer"
                          ></i>
                        </td>
                        {/* <td style={{
                                            <td>{item.txnType ? getTxnName(item.txnType) : "-"}</td>
                                            <td>
                                                {" "}
                                                {item["txnData"].from
                                                    ? item["txnData"].from == accountName
                                                        ? "Sent"
                                                        : "Receive"
                                                    : item.confirmed
                                                        ? "Confirmed"
                                                        : "Not Confirmed"}
                                            </td>
                                            <td>
                                                <i
                                                    onClick={() => {
                                                        const envirornment = process.env.REACT_APP_ENV
                                                        let url = 'https://telos.bloks.io/tx/'
                                                       // if (envirornment === 'development') {
                                                       //     url = 'https://testnet.teloscan.io/tx/'
                                                       // }
                                                        url = url + item.txnId;
                                                        window.open(url, "_blank");
                                                        //  onSelect(item['_id'])
                                                    }}
                                                    className="fas fa-external-link-alt cur-pointer"
                                                />
                                            </td>
                                            {/* <td style={{
                                            color: item['verification'].isVerified ? '#00B907' : "#ff6161"
                                        }}>
                                            {item['verification'].isVerified ? 'Verified' : 'Not Verified'}
                                        </td> */}
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
            <div className={"verified-music-nav-container"}>
              <span
                className={page > 1 ? "text-hover " : "page-action-disabled"}
                onClick={() => {
                  if (page > 1) setPage(page - 1);
                }}
              >
                PREV
              </span>
              <span
                className={
                  data.nextPage
                    ? "page-action-enabled "
                    : "page-action-disabled"
                }
                onClick={() => {
                  if (data.nextPage) setPage(page + 1);
                }}
              >
                NEXT
              </span>
            </div>
          </>
        )
      ) : (
        <h2>ERROR {"Opps! Something went wrong"}</h2>
      )}
    </div>
  );
}
