import moment from "moment";
import React, { useRef, useState } from "react";
import { useEffect } from "react";
import { Badge, Spinner } from "react-bootstrap";
import { useSelector } from "react-redux";
import { useLocation, useParams } from "react-router-dom";
import NoRecordTable from "../../components/NoRecordFound/NoRecordTable";
import { userApis } from "../../redux/api";
import { getRequiredProtectedData } from "../../redux/apicalltemplate";

const Chat = ({}) => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(null);
  const [page, setPage] = useState(1);
  const { id } = useParams();
  const [msg, setMsg] = useState("");
  const [msgloading, setMsgLoading] = useState(false);
  const [nextpage, setNextPage] = useState(false);
  const [reload, setReload] = useState(false);
  // const ticketdetails = useSelector((state) => state.user.ticketdata);S
  const contentRef = useRef();
  const [ticketdetails, setTicketDetails] = useState(null);
  //page=${data.page}&limit=${data.limit}&sortBy=${data.sortBy}
  // &order=${data.order}&search=${data.search}&from=${data.from}

  useEffect(async () => {
    const params = {
      page: 1,
      limit: 10,
      ticketNumber: id,
    };

    const response = await getRequiredProtectedData(
      "GET",
      `${userApis.GET_TICKETS}`,
      {},
      params
    );

    if (response && response.data) {
      setTicketDetails(response.data.data[0]);
    }
  }, [id]);

  useEffect(async () => {
    const params = {
      page: page,
      limit: 30,
    };
    setLoading(true);
    const response = await getRequiredProtectedData(
      "GET",
      `${userApis.GET_CHATS}/${id}`,
      {},
      params
    );
    if (response && response.data) {
      if (data) {
        setData([...data, ...response.data.data]);
      } else {
        setData(response.data.data);
      }

      //console.log(response.data);
      setNextPage(response.data.nextPage ? true : false);
    }
    setLoading(false);
    await contentRef.current.scrollTo(0, document.body.scrollHeight);
  }, [id, page, reload]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    let data = {};
    data.ticketNumber = id;
    data.message = msg;
    setMsgLoading(true);
    const responsedata = await getRequiredProtectedData(
      "POST",
      `${userApis.SEND_MSG}`,
      data
    );

    //----forfetching data again
    const params = {
      page: 1,
      limit: 30,
    };
    const response = await getRequiredProtectedData(
      "GET",
      `${userApis.GET_CHATS}/${id}`,
      {},
      params
    );
    if (response && response.data) {
      setData(response.data.data);
      setNextPage(response.data.nextPage ? true : false);
    }

    setMsgLoading(false);
    setMsg("");
    // await contentRef.current.scrollTo(0, document.body.scrollHeight);
  };

  const handleReload = () => {
    setPage(1);
    setData(null);
    setReload(!reload);
  };

  return (
    <div className="background-grey wrapper h-100vh">
      <div className=" container  h-100 ">
        <div className="row">
          <div className="col-md-4 text-dark chat-window">
            <div style={{ fontSize: "30px" }}>
              {" "}
              Manage Ticket{" "}
              <i
                className="fas fa-sync-alt cur-pointer text-dark"
                onClick={() => handleReload()}
              ></i>
            </div>

            <div className="my-4">
              <div>
                {" "}
                Ticket Number : {ticketdetails && ticketdetails.ticketNumber}
              </div>
              <div> Ticket Id: {ticketdetails && ticketdetails._id}</div>
              <div>
                {" "}
                Created For :{" "}
                {ticketdetails &&
                  ticketdetails.createdFor &&
                  ticketdetails.createdFor.type}
              </div>
              <div>
                {" "}
                Created At :{" "}
                {ticketdetails &&
                  ticketdetails.created_at &&
                  moment(ticketdetails.created_at).format("DD-MM-YYYY LT")}
              </div>
            </div>
          </div>
          <div className="col-md-8">
            <div className="chat-box" ref={contentRef}>
              {nextpage && (
                <button
                  className="text-center button-medium border-shadow cur-pointer  more-button mx-auto"
                  onClick={() => setPage(page + 1)}
                >
                  {!loading ? (
                    "More"
                  ) : (
                    <span
                      className="spinner-border spinner-border-smd"
                      role="status"
                      aria-hidden="true"
                    ></span>
                  )}
                </button>
              )}
              {loading ? (
                <div className="align-center h-100">
                  <Spinner hidden={false} animation="border" />
                </div>
              ) : data && data.length ? (
                <div className="d-flex  flex-column-reverse">
                  {data.map((el, i) => {
                    return (
                      <div>
                        {el.from === "User" ? (
                          <div className="user-msg">
                            <div className={"message-pill"}> {el.message}</div>
                            <Badge pill bg="primary" className="msg-badge">
                              {moment(el.updated_at).format("DD-MM-YYYY LT")}
                            </Badge>
                          </div>
                        ) : (
                          <div className="user-msg-admin">
                            <div className={"message-pill-admin"}>
                              {" "}
                              {el.message}
                            </div>
                            <Badge pill bg="primary" className="msg-badge">
                              {moment(el.updated_at).format("DD-MM-YYYY LT")}
                            </Badge>
                          </div>
                        )}
                      </div>
                    );
                  })}
                </div>
              ) : (
                <div className="align-center h-100">
                  <NoRecordTable />
                </div>
              )}
            </div>
            <div className="msg-box mb-2">
              <form
                className="d-flex align-items-center justify-content-around"
                onSubmit={handleSubmit}
              >
                <input
                  className={"input-textarea"}
                  type="text"
                  col="2"
                  aria-label="With textarea"
                  value={msg}
                  onChange={(e) => setMsg(e.target.value)}
                  placeholder="Enter Message"
                />
                <div>
                  <button
                    className="text-center button-medium border-shadow cur-pointer  more-button mx-2"
                    type="submit"
                  >
                    {!msgloading ? (
                      <i className="fas fa-paper-plane"></i>
                    ) : (
                      <>
                        <span
                          className="spinner-border spinner-border-smd"
                          role="status"
                          aria-hidden="true"
                        ></span>
                      </>
                    )}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Chat;
