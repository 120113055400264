
import React from 'react'

export default function TwoFactorAuth() {
    return (
        <div className='wrapper container h-100 '>
                     <h2 className='text-center py-3 '>Two Factor Authentication</h2>
        </div>
    )
}
