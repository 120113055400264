import React, { useState } from "react";
import { Modal, Form } from "react-bootstrap";
import Laptop from "../../icons/laptop.svg";
import Mobile from "../../icons/mobile.svg";
import { useEffect } from "react";
import { getRequiredProtectedData } from "../../redux/apicalltemplate";
import { userApis } from "../../redux/api";
import SwalError from "../../components/ToastMessage/SwalError";
import SwalSuccess from "../../components/ToastMessage/SwalSuccess";
import { useHistory } from "react-router-dom";

const DeviceDetails = ({ show, setShow, data, userDetails, setReload }) => {
  const handleClose = () => setShow(false);
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [blockloading, setBlockLoading] = useState(false);

  const handleRemove = async () => {
    try {
      setLoading(true);
      const responselist = await getRequiredProtectedData(
        "delete",
        `${userApis.REMOVE_DEVICE}/${data._id}`,
        {}
      );
      if (responselist && responselist.data) {
        SwalSuccess(responselist.data.message);

        if (userDetails.device_id === data._id) {
          localStorage.clear();
          history.push("/login");
        }

        setReload((prev) => !prev);
      }
    } catch (error) {
      if (error && error.response && error.response.data) {
        SwalError(error.response.data.message);
      }
    } finally {
      setShow(false);
      setLoading(false);
    }
  };

  const blockunblockDevice = async (status) => {
    try {
      setBlockLoading(true);

      const type = status ? "unblock" : "block";

      const responselist = await getRequiredProtectedData(
        "put",
        type === "block"
          ? `${userApis.BLOCK_DEVICE}/${data._id}`
          : `${userApis.UNBLOCK_DEVICE}/${data._id}`,
        {}
      );
      if (responselist && responselist.data) {
        SwalSuccess(responselist.data.message);

        if (userDetails.device_id === data._id) {
          localStorage.clear();
          history.push("/login");
        }

        setReload((prev) => !prev);
      }
    } catch (error) {
      if (error && error.response && error.response.data) {
        SwalError(error.response.data.message);
      }
    } finally {
      setShow(false);
      setBlockLoading(false);
    }
  };

  return (
    <Modal show={show} onHide={handleClose}>
      {/* <Modal.Header closeButton></Modal.Header> */}

      <Modal.Body className="p-3">
        <div className="d-flex align-items-center justify-content-center flex-wrap m-2 mb-5">
          <div className=" text-center">
            <div className="custom_img_container">
              {data.platform == "iPhone" || data.platform == "Android" ? (
                <img
                  className="smx-image my-4"
                  //  style={{ marginBottom: "30px" }}
                  src={Mobile}
                />
              ) : (
                <img className="mid-image" src={Laptop} />
              )}
            </div>
            <div className="text-dark ">{data.platform}</div>
            {data.last_location && (
              <div className=" f-14 ">
                {data.last_location.city ? data.last_location.city + " ," : ""}{" "}
                {data.last_location.country}
              </div>
            )}
            {userDetails && userDetails.device_id === data._id ? (
              <div className="my-2 active-device f-12 font-weight-bold">
                <li>Active(This Device)</li>
              </div>
            ) : (
              <div className="my-2 inactive-device f-12 font-weight-bold">
                <li>InActive</li>
              </div>
            )}
            <div className="mt-3 f-14 text-center">
              <div className="d-flex m-1">
                <div className=" link-label text-dark mx-1">IP</div>
                <div style={{ width: "150px", textAlign: "left" }}>
                  {data.IPs.map((el) => (
                    <div className="data-ellipsis" title={el}>
                      {el}
                    </div>
                  ))}{" "}
                </div>
              </div>
              <div className="d-flex m-1">
                <div className=" link-label text-dark mx-1">Logged In With</div>
                <div>{data.browser}</div>
              </div>
              <div className="d-flex m-1">
                <div className=" link-label text-dark mx-1">
                  Browser Version
                </div>
                <div>{data.version}</div>
              </div>
              <div className="d-flex m-1">
                <div className=" link-label text-dark mx-1">Verified</div>
                <div>{data.is_verified ? "Yes" : "No"}</div>
              </div>
              <div className="d-flex m-1">
                <div className=" link-label  text-dark mx-1">OS</div>
                <div>{data.os}</div>
              </div>
              <div className="d-flex m-1 align-items-center">
                <div className=" link-label  text-dark mx-1">Status</div>
                <div>
                  {data.block_status && data.block_status.is_blocked ? (
                    <div className=" inactive-device f-12 font-weight-bold">
                      Blocked
                    </div>
                  ) : (
                    <div className=" active-device f-12 font-weight-bold">
                      Not Blocked
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="d-flex justify-content-center">
              {
                <button
                  className="button-medium cur-pointer more-button mx-1 "
                  onClick={handleRemove}
                >
                  {!loading ? (
                    "Remove Device"
                  ) : (
                    <>
                      <span
                        className="spinner-border spinner-border-smd"
                        role="status"
                        aria-hidden="true"
                      ></span>
                    </>
                  )}
                </button>
              }

              {data.block_status && (
                <button
                  className="button-medium cur-pointer more-button mx-1"
                  onClick={() =>
                    blockunblockDevice(data.block_status.is_blocked)
                  }
                >
                  {!blockloading ? (
                    data.block_status.is_blocked ? (
                      "Unblock Device"
                    ) : (
                      "Block Device"
                    )
                  ) : (
                    <>
                      <span
                        className="spinner-border spinner-border-smd"
                        role="status"
                        aria-hidden="true"
                      ></span>
                    </>
                  )}
                </button>
              )}
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default DeviceDetails;
