import React from "react";
import { Modal } from "react-bootstrap";
import QRCode from "qrcode.react";

export default function TokenReceiveModal({
  publicKey,
  showReceiveModal,
  setReceiveModal,
}) {
  return (
    <Modal
      show={showReceiveModal}
      onHide={() => {
        setReceiveModal(false);
      }}
    >
      <Modal.Header closeButton>
        <Modal.Title className="text-dark">Receive Zeptacoin</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="text-center">
          <QRCode value={publicKey} bgColor="#9b9b9b" />
          <h5 className="text-dark">
            Address
            <div className="key-text mt-3">{publicKey}</div>
          </h5>
        </div>
      </Modal.Body>
    </Modal>
  );
}
