import React, { useEffect, useState } from "react";
import { ProgressBar } from "react-bootstrap";
import Message from "../../images/message.svg";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { bookmarkStart } from "../../redux/user/Action";
import { getRequiredProtectedData } from "../../redux/apicalltemplate";
import { userApis } from "../../redux/api";
const CrowdCardLarge = ({
  el,
  handleCrowdsaleDetails,
  handleInvest,
  liveprice,
  type = "crowdsale",
  userid = "",
  isBookmark=false,
}) => {

  const history=useHistory();
  const [color,setColor]=useState(false);

  const dispatch=useDispatch();

  useEffect(()=>{
 
   setColor(isBookmark)
  },[isBookmark]);

  const handleLike=async ()=>{
 
    setColor(!color);
    const response = await getRequiredProtectedData(
      "PUT",
      `${ !color ?  userApis.BOOKMARK:userApis.UNBOOKMARK }/${el._id}`,
      {},
      {}
    );

    if (response && response.data) {
      // setData(response.data.data);

      dispatch(bookmarkStart({}));
     
    }


  }

  return (
    <div className="container-md">
      <div className="">
        <span onClick={() => handleCrowdsaleDetails(el)}>
          <img
            className="lg-image"
            src={el.musicId && el.musicId.bitMapImage}
            alt="Music Thumbnail"
          />
        </span>
        <div className="heart-parent"  >
        <div className='heart'  onClick={()=>{
        handleLike()
        }}>
        <svg width="27" height="29" viewBox="0 0 32 29"
            fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M16 28.4948C31.583 16.2315 30.9971 11.8964 30.9971 8.49857C30.9971 5.10078 28.1852 1 23.4986 1C18.812 1 16 5.99905 16 5.99905C16 5.99905 13.188 1 8.50143 1C3.81483 1 1.00286 5.10078 1.00286 8.49857C1.00286 11.8964 0.41704 16.2315 16 28.4948Z" 
            fill={color?'red':'white'} stroke="black" stroke-width="0.15" />
        </svg>
      </div>
      </div>
        <div
          className="text-dark text-center data-ellipsis mw-250 med-title"
          title={el.musicId && el.musicId.longName}
        >
          {el.musicId && el.musicId.longName}
        </div>
        <div className="text-grey text-center">
          {el.featured_by
            ? el.featured_by
            : el.musicId && el.musicId.shareHolderName}
        </div>
      
      </div>
      {el && el.deployedIn === "USD" ? (
        <>
          <ProgressBar
            className="progressBar"
            variant={"success"}
            now={(el.usdCoinsRaised / el.targetAmountByUser) * 100}
          />
          <span className="text-dark" style={{ fontSize: "13px" }}>
            <span style={{ color: "#4BB543" }}>{`$${el.usdCoinsRaised}`}</span>
            {` raised of $${el.targetAmountByUser}`}
          </span>
        </>
      ) : (
        <>
          <ProgressBar
            className="progressBar"
            variant={"success"}
            now={
              liveprice
                ? ((el.zeptaCoinsRaised * liveprice.data.zeptaPrice) /
                    el.targetAmountByUser) *
                  100
                : 0
            }
          />
          <span className="text-dark" style={{ fontSize: "13px" }}>
            <span style={{ color: "#4BB543" }}>{`$${
              liveprice &&
              Number(
                (el.zeptaCoinsRaised * liveprice.data.zeptaPrice).toFixed(2)
              )
            }`}</span>
            {` raised of $${el.targetAmountByUser}`}
          </span>
        </>
      )}

      {userid &&
        (userid === el.isuuer || el.state === "successful" ? null : (
          <button
            className="button-medium border-shadow w-100 m-2"
            onClick={() => handleInvest(el, "crowdsale")}
          >
            Invest
          </button>
        ))}

{!localStorage.getItem('token') &&  (
                <button
                  className="button-medium border-shadow w-100 m-2"
                  onClick={() => history.push('/login')}
                >
                  Invest
                </button>
               )}
    </div>
  );
};

export default CrowdCardLarge;
