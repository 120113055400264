import { useEffect } from 'react';
import { Widget,addResponseMessage, toggleInputDisabled, toggleMsgLoader } from 'react-chat-widget';

import 'react-chat-widget/lib/styles.css';
import { getRequiredProtectedData } from '../../redux/apicalltemplate';
import { userApis } from '../../redux/api';


export const WidgetChat=()=>{
    useEffect(() => {
        addResponseMessage('Welcome to Zeptagram support');
        addResponseMessage('You can ask us anything about the platform');
      }, []);
    

   const handleNewUserMessage = async(newMessage) => {
        // console.log(`New message incomig! ${newMessage}`);
        toggleMsgLoader()
        toggleInputDisabled()
        // Now send the message throught the backend API
        const response = await getRequiredProtectedData(
            "POST",
            `${userApis.CHAT_API}`,
            {message:newMessage},
          );
          if (response && response.data) {
            // setData(response.data.data);
            // console.log(response.data)
            let newMessage=response.data.slice(2);
            addResponseMessage(newMessage)
          }

        toggleMsgLoader()
          toggleInputDisabled()
        // addResponseMessage(response);
      }

    return(
        <Widget 
        handleNewUserMessage={ handleNewUserMessage} 
        //  profileAvatar={logo}
        emojis={false}
          title="Welcome to Zeptagram"
          subtitle="Ask us anything about the platform" />
    )
}