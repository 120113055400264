import React from "react";
import Img1 from "../../../images/guide/image6.png";
import Img2 from "../../../images/guide/image28.png";
import Img3 from "../../../images/guide/image35.png";
import Img4 from "../../../images/guide/image20.png";
import Img5 from "../../../images/guide/image8.png";
import Img6 from "../../../images/guide/image23.png";
import Img7 from "../../../images/guide/image10.png";

import Img8 from "../../../images/guide/anchor/1.png";
import Img9 from "../../../images/guide/anchor/2.png";
import Img10 from "../../../images/guide/anchor/3.png";
import Img11 from "../../../images/guide/anchor/4.png";
import Img12 from "../../../images/guide/anchor/5.png";
import Img13 from "../../../images/guide/anchor/6.png";
import Img14 from "../../../images/guide/anchor/7.png";
import Img15 from "../../../images/guide/anchor/8.png";
import Img16 from "../../../images/guide/anchor/9.png";
import Img17 from "../../../images/guide/anchor/10.png";
import Img18 from "../../../images/guide/anchor/11.png";
import Img19 from "../../../images/guide/anchor/12.png";
import Img20 from "../../../images/guide/anchor/13.png";
import Img21 from "../../../images/guide/anchor/14.png";
import Img22 from "../../../images/guide/anchor/15.png";

export const WalletGuide = () => {
  return (
    <div className="ml-3">
      <p className="text-justify">
        Wallet is similar to your bank account. It is unique to you. While
        visiting your bank you deposit, withdraw money and also send to or
        receive money from others. Similarly in your Zeptagram wallet you can do
        all the above operations with much fewer steps and from your device.
      </p>
      <br />
      <p className="text-justify">
        Your wallet public key is like your bank account number, on basis of
        what you can perform all the above operations.
      </p>
      <h5 className="text-dark">To Create A Wallet </h5>
      <ul>
        <li>Click on Create Wallet and it will open a dialog box.</li>
        <img className="guide-img" src={Img1} />
        <li>
          Enter a 4 digit pin.Remember this pin as this will be needed for all
          crypto transactions in future.
        </li>
        <img className="guide-img guide-md" src={Img2} />
        <li>Click on Confirm and your wallet will be created successfully.</li>
      </ul>
      <p>
        <b>Note:</b> Do not share your private keys with anyone.
      </p>
      <img className="guide-img" src={Img3} />
      <h5 className="text-dark">Add Zeptacoins</h5>
      <p className="text-justify">
        On this particular operation you can add or buy Zeptacoins to your
        wallet. This can be performed in two ways either by Fiat($) or in
        Zeptacoins. If you enter an amount like 10$, then the actual amount
        deducted will be 10.25$, where this .25$ will be used for Zeptagram
        transaction fees. You can also see how much equivalent Zeptacoin your
        account will add. Since Zeptagram runs on Telos Blockchain, the
        equivalent Telos Token will also appear on the screen.
      </p>
      <br />
      <p className="text-justify">
        <b>Note</b>:This service is not available for now.It is temporarily
        disabled due to ongoing tokensale for both investors and publishers.
      </p>
      <img className="guide-img guide-md" src={Img4} />
      <h5 className="text-dark">Withdraw Zeptacoins</h5>
      <p className="text-justify">
        You can also withdraw Zeptacoin/Dollar (fiat money) from your wallet
        like any bank withdrawal. In a similar way 2.5% of the transaction fee
        will be deducted from Zeptagram.
      </p>
      <br />
      <p className="text-justify">
        Like if you enter an amount like 10 in Zeptacoin / Fiat, you can see how
        much equivalent in Telos/USD it should be and also after 2.5% deduction
        telos/usd amount respectively.
      </p>
      <p className="text-justify">
        <b>Note:</b>This service is temporarily unavailable due to ongoing
        tokensale for investors. For publishers, it is available.
      </p>
      <img className="guide-img guide-md" src={Img5} />
      <h5 className="text-dark">Send Zeptacoins</h5>
      <p className="text-justify">
        This is the same operation as sending money to some other account from
        your account. In crypto-world, the address is your wallet public key
        from where you can send Zeptacoin to some other Zeptacoin holder.
      </p>
      <p className="text-justify">
        In place of Account Name, you will add the account name. The number of
        Zeptacoin you want to send to that account. Also you can write a
        memo(message) to the receiver, for a better understanding.
      </p>
      <img className="guide-img guide-md" src={Img6} />
      <h5 className="text-dark">Receive Zeptacoins</h5>
      <p className="text-justify">
        This is a similar operation of sending your account number to others. It
        can be done by two processes. You can share the public key (Address) or
        you can have a QR code. But one thing to remember, only Zeptacoin can be
        received; other transactions can cause loss of your deposit.
      </p>
      <img className="guide-img guide-md" src={Img7} />
    </div>
  );
};
