import React from "react";
import Img1 from "../../../images/guide/image34.png";
import Img2 from "../../../images/guide/image32.png";
import Aml from "../../../images/guide/aml.png";
export const KYCGuide = () => {
  return (
    <div className="mx-3">
      <p>
        KYC or Know Your Customer is a basic mechanism of knowing you better. On
        doing the KYC process you can do it in three different ways: Manually
        can fill it for Publisher KYC, Manually can fill it for Investors KYC
        and Aver KYC process for Investors.
        <br /> We have Two Types of KYC
        <ul>
          <li>
            <b>Publisher</b>
          </li>
          <li>
            <b>Investor</b>
          </li>
        </ul>
      </p>
      <p>
        <h5 className="text-dark">Publisher KYC</h5>
        <p>
          If you are an independent singer/ record label then you have to do
          this to complete your KYC.
        </p>
        <ul>
          <li>
            <b>PRO Name</b>: It is the name of the collecting Agency the artist
            is signed with who do the royalty payouts.
          </li>
          <li>
            <b>IPI Number</b>: A CAE Number (or IPI number) is an international
            identification number assigned to songwriters and publishers to
            uniquely identify rights holders
          </li>
          <li>
            <b>Type</b> : Independent/Record Label, You can be an independent
            singer also you can be a record label
          </li>
        </ul>
        <img className="guide-img guide-md" src={Img1} />
      </p>
      <p>
        <h5 className="text-dark">Investor KYC (Manual)</h5>
        <p>
          If you have logged in as an Investor, then you have to fill in all the
          details and submit for verifying your KYC.
        </p>
        <ul>
          <li>
            <b>ID-Name</b>: Name of the identity card( Green card/Passport )
          </li>
          <li>
            <b>ID-Number</b>: The unique identity number printed on your above
            mentioned identity card.
          </li>
          <li>
            <b>ID-Issue Date</b>: The date on which the ID card was issued
          </li>
          <li>
            <b>ID-Expiry Date</b>: The date on which the ID’s authenticity will
            expire
          </li>
          <li>
            <b>Issued By</b>: The governing authority who have issued the ID
          </li>
          <li>
            <b>Vat Number</b>: A value added tax identification number or VAT
            identification number is an identifier used in many countries,
            including the countries of the European Union, for value added tax
            purposes.
          </li>
          <li>
            <b>Front-side ID Image</b>: Upload the front-side image of the above
            mentioned ID card
          </li>
          <li>
            <b>Back-side ID Image</b>: Upload the back-side image of the above
            mentioned ID card
          </li>
        </ul>
        <img className="guide-img guide-md" src={Img2} />
      </p>
      <p>
        <h5 className="text-dark">Investor Kyc Guide Via Aver</h5>
        <p>
          Aver provides KYC/AML services (Know Your Customer/Anti Money
          Laundering).It helps to integrate trust between customers and service
          providers. Zeptagram has tied up with Aver to bring in a flawless
          service.
          <br />
          Requirements
        </p>
        <ul>
          <li>Personal Access Code</li>
          <li>Details of provided ID card.</li>
          <li>A live picture (selfie).</li>
        </ul>
      </p>

      <p>
        <h5 className="text-dark">AML KYC</h5>
        <p className="text-justify">
          Anti-Money Laundering (AML) is a series of measures and procedures
          carried out by financial institutions and other regulated entities to
          prevent financial crimes. AML compliance, including KYC, is mandatory
          for regulated entities under AML/CFT regulations.
          <br /> <br />
          AML KYC includes a series a question for an investor.There is no need
          for AML KYC for a user who has has done investor KYC using AVER. For a
          regular KYC investor it is mandatory.
        </p>
        <img className="guide-img guide-md" src={Aml} />
      </p>
    </div>
  );
};
