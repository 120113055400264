import axios from "axios";
import { JsonRpc, RpcError } from "eosjs";
import { userApis } from "../redux/api";
import {
  getRequiredData,
  getRequiredProtectedData,
} from "../redux/apicalltemplate";

const rpcUrl =
  process.env.REACT_APP_NETWORK_PROTOCOL +
  "://" +
  process.env.REACT_APP_RPC +
  ":" +
  process.env.REACT_APP_NETWORK_PORT;
const rpc = new JsonRpc(rpcUrl, { fetch });

const tokenSymbol = process.env.REACT_APP_TOKEN_SYMBOL;

export default class BlockchainService {
  constructor() {
    this.atomicAssetContract = "atomicassets";
    this.atomicMarketContract = "atomicmarket";
    this.eosioTokenContract = "eosio.token";
    this.tokenSymbol =
      process.env.REACT_APP_TOKEN_PRECISION +
      "," +
      process.env.REACT_APP_TOKEN_SYMBOL;
    this.contractName = process.env.REACT_APP_CONTRACT_NAME;
  }

  getAccountInfo = async (accontName) => {
    let res = null;
    try {
      res = await rpc.get_account(accontName);
    } catch (err) {
      res = null;
    }
     
    return res;
  };

  getAccountName = (ual) => {
    if (ual.activeUser) {
      let userName = ual.activeUser.accountName;
      return userName;
    }

    return "";
  };

  getUserBalance = async (userName) => {
    var res = await rpc.get_currency_balance(
      "eosio.token",
      userName,
      tokenSymbol
    );
    if (res) {
      res = res.toString();
      return res.substr(0, res.indexOf(".") + 3);
    }
    return 0;
  };

  getAuthorization = (actor, permission) => {
    return { actor, permission };
  };

  getAction = (contractName, actonName, data, authorization) => {
    return {
      actions: [
        {
          account: contractName,
          name: actonName,
          authorization,
          data,
        },
      ],
    };
  };

  saveTnx = async (result, ual, txnType, optionalData = {}) => {
    const tnx = {
      txnId: result.transaction.transaction_id,
      blockNumber: result.transaction.processed.block_num,
      blockTime: result.transaction.processed.block_time,
      receipt: {
        status: result.transaction.processed.receipt.status,
        cpuUsage: result.transaction.processed.receipt.cpu_usage_us,
        netUsageWords: result.transaction.processed.receipt.net_usage_words,
        netUsage: result.transaction.processed.net_usage,
      },
      contractName:
        result.transaction.processed.action_traces[0].receipt.receiver,
      actionName: result.transaction.processed.action_traces[0].act.name,
      actors: [
        ual.activeUser?.accountName,
        result.transaction.processed.action_traces[0].act.data?.to,
      ],
      txnType: txnType,
      status: "send",
      info: { ...optionalData },

      txnData:
        txnType !== "trade_zptc_transfer"
          ? {
              ...result.transaction.processed.action_traces[0].act.data,
              ...optionalData,
            }
          : { ...result.transaction.processed.action_traces[0].act.data },
      confirmed: true,
    };
    const obj = {
      txnType: txnType,
      actors: tnx.actors,
      txn_object: tnx,
    };
  
    await getRequiredProtectedData("POST", userApis.ANCHOR_SAVE_TNX, obj, {});
  };

  pushTransaction = async (trx, ual, txnType, optionalData) => {
    let res = {
      success: false,
      message: "",
    };
    try {
      const result = await ual.activeUser.signTransaction(trx, {
        blocksBehind: 12,
        expireSeconds: 120,
        broadcast: true,
        sign: true,
      });

      await this.saveTnx(result, ual, txnType, optionalData);
      // await axios
      //   .post(userApis.ANCHOR_SAVE_TNX, obj)
      //   .then(() => console.log(result))
      //   .catch((ecster_uri) => console.log(ecster_uri));
      res.message = result;
      res.success = true;
    } catch (e) {
      console.log("E", e);
      if (e.message.toString().includes("balance"))
        res.message = "Kindly buy more " + tokenSymbol + " for the transaction";
      else if (e.message.toString().includes("CPU"))
        res.message = "Kindly stake more CPU for the transaction";
      else if (e.message.toString().includes("net0"))
        res.message = "Kindly stake more NET for the transaction";
      else res.message = e.message;

      res.success = false;
    }

    return res;
  };

  defaultPushAction = async (trx) => {
    let res = {
      success: false,
      message: "",
    };

    try {
      const result = await this.api.transact(trx, {
        blocksBehind: 3,
        expireSeconds: 30,
      });
      res.message = result;
      res.success = true;
    } catch (e) {
      res.success = false;
      res.message = "Transaction Faild";
      if (e.json) {
        var errorJson = e.json.error.details[0].message;
        if (e instanceof RpcError) res.message = errorJson;
      }
    }

    return res;
  };

  getAssetFromNumber(amount, symbol, precision) {
    amount = amount.toString();
    let asset = amount;
    /* Append a decimal character in case not provided */
    if (amount.indexOf(".") === -1) {
      amount = amount + ".";
    }
    let decimalPlaces = amount.length - 1 - amount.indexOf(".");
    if (decimalPlaces > precision) {
      asset = amount.substring(0, amount.indexOf(".") + (precision + 1));
    } else if (decimalPlaces < precision) {
      asset = amount + "0".repeat(precision - decimalPlaces);
    }

    asset = `${asset} ${symbol}`;
    return asset;
  }

  buyRam = (userAccount, quantity, symbol, ramAmount) => {
    let contract = "eosio";
    let actionName = "buyram";
    let authorization = this.getAuthorization(userAccount, "active");
    let data = {
      payer: userAccount,
      receiver: userAccount,
      quant:
        symbol === "TLOS"
          ? this.getAssetFromNumber(quantity, symbol, 4)
          : symbol === "WAX"
          ? this.getAssetFromNumber(quantity, symbol, 8)
          : "",
    };
    let action = this.getAction(contract, actionName, data, [authorization]);
    return action;
  };

  transferToken = (from, to, quantity, memo, tokenContract) => {
    let contract = this.contractName;
    let actionName = "transfer";
    let authorization = this.getAuthorization(from, "active");

    let data = {
      from,
      to,
      quantity: this.getAssetFromNumber(quantity, "ZPTC", 4),
      memo,
    };

    let action = this.getAction(contract, actionName, data, [authorization]);
    return action;
  };

  getTransactionDetails = async (trxId) => {
    let res = null;
    try {
      let transactDetails = await rpc.history_get_transaction(trxId);
      res = transactDetails;
    } catch (err) {
      res = null;
    }
    return res;
  };

  getRamPrice = async (accontName) => {
    try {
      const tableQuery = {
        json: true, // Get the response as json
        code: "eosio", // Contract that we target
        scope: "eosio", // Account that owns the data
        table: "rammarket", // Table name
        limit: 10, // Maximum number of rows that we want to get
        reverse: false, // Optional: Get reversed data
        show_payer: false,
      };
      const data = await rpc.get_table_rows(tableQuery);
      const connectorBalance = Number(data.rows[0].quote.balance.slice(0, -4));
      const CW = Number(data.rows[0].quote.weight);
      const outstandingSupply = Number(data.rows[0].base.balance.slice(0, -4));
      const ramPrice = connectorBalance / (outstandingSupply * CW) / 2;
      return ramPrice;
    } catch (err) {
      console.log("error", err);
    }
  };

  sanitizeEosioName(name) {
    if (!isNaN(name.charAt(name.length - 1))) {
      name += " "; // add simple whitespace
    }
    return name;
  }
  viewTableData = async (
    scope,
    contractName,
    tableName,
    keyValue,
    limit,
    secondaryIndex
  ) => {
    try {
      const tableQuery = {
        json: true,
        code: contractName,
        scope: scope,
        table: tableName,
        index_position: secondaryIndex,
        key_type: "i64",
        lower_bound: keyValue ? this.sanitizeEosioName(keyValue) : keyValue,
        upper_bound: keyValue ? this.sanitizeEosioName(keyValue) : keyValue,
        limit: limit ? limit : 100,
        reverse: false, // Optional: Get reversed data
        show_payer: false, // Optional: Show ram payer
      };
      return rpc.get_table_rows(tableQuery);
    } catch (err) {
      console.log(err);
    }
  };

  getTokenBalance = async (accountName) => {
    const tableData = await this.viewTableData(
      accountName,
      this.contractName,
      "accounts"
    );
    const symbol = "ZPTC";
    return tableData.rows[0] ? tableData.rows[0].balance : `0.0000 ${symbol}`;
  };
  createCollectionAction = (
    userAccount,
    displayName,
    image,
    description,
    url,
    title,
    marketFee
  ) => {
    let contract = this.atomicAssetContract;
    let actionName = "createcol";
    let authorization = this.getAuthorization(userAccount, "active");

    //collection-data
    let author = userAccount;
    let collection_name = title ?? userAccount;
    let allow_notify = true;
    let authorized_accounts = [userAccount];
    let notify_accounts = [userAccount];
    let market_fee = marketFee ?? this.defaultMarketFee;
    //let market_fee = this.defaultMarketFee;
    let data = [
      { key: "name", value: ["string", displayName] },
      { key: "img", value: ["string", image] },
      { key: "description", value: ["string", description] },
      { key: "url", value: ["string", url] },
    ];

    let actionData = {
      author,
      collection_name,
      allow_notify,
      authorized_accounts,
      notify_accounts,
      market_fee,
      data,
    };
    let action = this.getAction(contract, actionName, actionData, [
      authorization,
    ]);
    return action;
  };
  createMintAssetAction = (
    userAccount,
    schemaName,
    templateId,
    assetName,
    description,
    rarity,
    category,
    assetContentKey,
    assetContentValue,
    userCollection,
    musicId
  ) => {
    let contract = this.atomicAssetContract;
    let actionName = "mintasset";
    let authorization = this.getAuthorization(userAccount, "active");
    //template-data
    let authorized_minter = userAccount;
    let collection_name = userCollection ?? userAccount;
    let schema_name = schemaName;
    let template_id = templateId ?? -1;
    let new_asset_owner = userAccount;
    let immutable_data =
      templateId > 0
        ? []
        : [
            { key: "name", value: ["string", assetName] },
            { key: assetContentKey, value: ["string", assetContentValue] },
            { key: "description", value: ["string", description] },
            { key: "rarity", value: ["string", rarity] },
            { key: "category", value: ["string", category] },
            { key: "musicId", value: ["string", musicId] },
          ];
    let mutable_data = [];
    let tokens_to_back = [];

    let data = {
      authorized_minter,
      collection_name,
      schema_name,
      template_id,
      new_asset_owner,
      immutable_data,
      mutable_data,
      tokens_to_back,
    };

    let action = this.getAction(contract, actionName, data, [authorization]);
    return action;
  };
  createSchemaAction = (
    userAccount,
    schemaName,
    collectionName,
    schemaData
  ) => {
    let contract = this.atomicAssetContract;
    let actionName = "createschema";
    let authorization = this.getAuthorization(userAccount, "active");

    //schema-data
    let authorized_creator = userAccount;
    let collection_name = collectionName ?? userAccount;
    let schema_name = schemaName ?? this.defaultSchemaName;
    let schema_format = [
      { name: "name", type: "string" },
      { name: "img", type: "string" },
      { name: "video", type: "string" },
      { name: "audio", type: "string" },
      { name: "description", type: "string" },
      { name: "rarity", type: "string" },
      { name: "category", type: "string" },
      { name: "musicId", type: "string" },
    ];

    if (schemaData && schemaData.length > 0) {
      schema_format.push(...schemaData);
    }

    let data = {
      authorized_creator,
      collection_name,
      schema_name,
      schema_format,
    };

    let action = this.getAction(contract, actionName, data, [authorization]);
    return action;
  };
}
