import React, { useState, useEffect } from "react";
import { SuccessToast } from "../../../components/ToastMessage/SuccessToast";
import copyIcon from "../../../icons/copy.png";
import { CopyToClipboard } from "react-copy-to-clipboard/lib/Component";
import { shortAddress } from "../../../utils/utils";
import {
  switchMetamaskNetwork,
  claimAmount,
  metamaskChainId,
  getRemoteIdByChainid,
} from "../../../components/TokenBridge/methods";
import { networkList } from "../../../components/TokenBridge/helper";
import { toast } from "react-toastify";
export const ClaimList = ({ el, account, currentChainId, handleClaimData }) => {
  const [validNetwork, setValidNetwork] = useState(false);
  const [claimLoading, setClaimLoading] = useState(false);
  const networkNameFromId = (id) => {
    const net = networkList.find((el) => el.remoteId === id);
    return net ? net.name : "";
  };

  const claimToken = async (el) => {
    setClaimLoading(true);
    const ans = await claimAmount(el, account);
    setClaimLoading(false);
    if (ans) {
      setTimeout(() => {
        handleClaimData();
      }, 200);
    }
  };

  useEffect(() => {
    const correctNetwork = (chain_id, currentChainId) => {
      const currentId = getRemoteIdByChainid(currentChainId);
      if (currentId) {
        let ans = currentId === chain_id;
        setValidNetwork(ans);
      } else setValidNetwork(false);
    };
    correctNetwork(el.chain_id, currentChainId);
  }, [el, currentChainId]);

  return (
    <div className="d-flex align-items-center justify-content-between ">
      <div className="mx-1 text-dark"> {el.quantity}</div>
      <div className="mx-1">
        <span>
          {shortAddress(el.eth_address)}
          <span>
            <CopyToClipboard
              text={el.eth_address}
              onCopy={() => {
                toast.success(<SuccessToast message={"Copied!"} />, {
                  toastId: 1,
                  updateId: 1,
                });
              }}
            >
              <img
                src={copyIcon}
                width={"17px"}
                className={"mx-2 cur-pointer"}
              />
            </CopyToClipboard>
          </span>
        </span>
      </div>
      <div className="mx-1">
        {validNetwork && !el.claimed && (
          <span
            className="more-button button-medium border-shadow cur-pointer"
            onClick={() => claimToken(el)}
          >
            {claimLoading ? (
              <span
                className="spinner-border spinner-border-smd"
                role="status"
                aria-hidden="true"
              ></span>
            ) : (
              "Claim"
            )}
          </span>
        )}
        {el.claimed ? (
          <div className="align-center status-pill status-claim m-2">
            Claimed
          </div>
        ) : null}

        {/* // */}
        {!validNetwork && (
          <span
            className="more-button button-medium border-shadow cur-pointer"
            onClick={() =>
              switchMetamaskNetwork(networkNameFromId(el.chain_id))
            }
          >
            Switch Network
          </span>
        )}
      </div>
    </div>
  );
};
