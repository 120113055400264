import React from "react";
// import { useHistory } from "react-router-dom";

const ErrorPage = () => {
  //   const history = useHistory();
  return (
    <div className="error-image-overlay">
      <img
        src="https://i.imgur.com/qIufhof.png"
        className="error-image-conatiner"
      />
      <h2 className="text-dark"> Something is broken : )</h2>
      <button
        className="music-action-button box-shadow mt-3 p-2"
        size={"button-small-long"}
        title={"Create your wallet"}
        onClick={() => window.location.replace("/")}
      >
        {" "}
        Go to Home
      </button>
    </div>
  );
};
export default ErrorPage;
