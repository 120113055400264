import React from "react";
import { ProgressBar } from "react-bootstrap";

const CrowdCardSmall = ({ el, liveprice, handleCrowdsaleDetails }) => {
  return (
    <div className="d-flex justify-content-center p-2 box-shadow-hover width-content">
      <span onClick={() => handleCrowdsaleDetails(el)}>
        <img
          className="sm-image"
          src={el.musicId && el.musicId.bitMapImage}
          alt="Logo"
        />
      </span>
      <div className="p-1" style={{ width: "250px" }}>
        <div className="text-dark  mw-250 dataDesc">
          {el.musicId && el.musicId.longName}
        </div>
        <div className="text-grey ">
          {el.featured_by
            ? el.featured_by
            : el.musicId && el.musicId.shareHolderName}
        </div>

        {el && el.deployedIn === "USD" ? (
          <>
            <ProgressBar
              className="progressBar"
              variant={"success"}
              now={(el.usdCoinsRaised / el.targetAmountByUser) * 100}
            />
            <span className="text-dark" style={{ fontSize: "13px" }}>
              <span
                style={{ color: "#4BB543" }}
              >{`$${el.usdCoinsRaised}`}</span>
              {` raised of $${el.targetAmountByUser}`}
            </span>
          </>
        ) : (
          <>
            <ProgressBar
              className="progressBar"
              variant={"success"}
              now={
                liveprice
                  ? ((el.zeptaCoinsRaised * liveprice.data.zeptaPrice) /
                      el.targetAmountByUser) *
                    100
                  : 0
              }
            />
            <span className="text-dark" style={{ fontSize: "13px" }}>
              <span style={{ color: "#4BB543" }}>{`$${
                liveprice &&
                Number(
                  (el.zeptaCoinsRaised * liveprice.data.zeptaPrice).toFixed(2)
                )
              }`}</span>
              {` raised of $${el.targetAmountByUser}`}
            </span>
          </>
        )}
      </div>
    </div>
  );
};

export default CrowdCardSmall;
