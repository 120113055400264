import { Modal, Form } from "react-bootstrap";
import { SubmitButton } from "../../components/components";
import BootstrapSwitchButton from "bootstrap-switch-button-react";
import React, { useState } from "react";
import { useSubmit } from "../../api/api_client";
import { notifyError, notifySuccess } from "../../utils/utils";
import { isNotValid } from "../../utils/utils";
import { userApis } from "../../redux/api";
import { ToggleSwitch } from "../../components/Buttons/ToggleSwitch/ToggleSwitch";

function AddCoinModal({ show, setShow, onAddDollarResponse, livePrice }) {
  const [loading, setLoading] = useState(false);

  const [data, setData] = useState({
    zeptacoin: "",
    telos: "",
    usd: "",
    after_charge: "",
  });

  const [telos, setTelos] = useState(true);

  const reset = () => {
    var data = {};
    data["telos"] = "";
    data["usd"] = "";
    data["after_charge"] = "";
    data["zeptacoin"] = "";
    setData(data);
  };

  const handleInputChange = (e) => {
    const { zeptaPrice, eosPrice, telosInZepta, zeptaInTelos } = livePrice;

    var data = {};
    if (e.target.value == "") {
      data["telos"] = "";
      data["usd"] = "";
      data["after_charge"] = "";
      data["zeptacoin"] = "";
      setData(data);
    } else if (telos) {
      const telosNumber = Number.parseFloat(e.target.value);
      data["telos"] = telosNumber;
      data["usd"] = telosNumber * eosPrice;
      data["after_charge"] = telosNumber + (telosNumber * 2.5) / 100;
      data["zeptacoin"] = Number.parseFloat(telosNumber / telosInZepta);
      setData(data);
    } else {
      const usd = Number.parseFloat(e.target.value);
      data["usd"] = usd;
      data["telos"] = (usd / eosPrice).toFixed(4);
      data["after_charge"] = usd + (usd * 2.5) / 100;
      data["zeptacoin"] = usd / zeptaPrice;
      setData(data);
    }
  };

  const handleSubmit = async () => {
    setLoading(true);

    var amount = data["after_charge"];
    if (isNotValid(amount)) {
      notifyError("Enter valid amount");
      return;
    }

    let url = userApis.ADD_FLAT;

    if (telos) {
    }

    // eslint-disable-next-line react-hooks/rules-of-hooks
    await useSubmit(
      url,
      {
        amount: amount,
      },
      "POST"
    )
      .then((response) => {
        setLoading(false);
        //console.log(response);
        notifySuccess(response.message);
      })
      .catch((e) => {
        setLoading(false);
        notifyError(e.message);
        //   // console.error(error)
      });
  };

  return (
    <Modal
      show={show}
      size="md"
      onHide={() => {
        setShow();
      }}
    >
      <Modal.Header closeButton>
        <Modal.Title className="text-dark">Add Zeptacoin</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <div className="d-flex align-items-center justify-content-center flex-wrap m-2">
            <div className="text-dark mx-3">Telos</div>
            <ToggleSwitch
              setValue={() => {
                setTelos(!telos);
                reset();
              }}
            />
            <div className="text-dark mx-3">Fiat($)</div>
          </div>
          <label className="input-label">Amount</label>
          <Form.Control
            className={"input-box-sm"}
            type="number"
            name="amount"
            placeholder={telos ? "Telos" : "USD"}
            onChange={handleInputChange}
          />
          <label className="input-label">
            Amount (after 2.5% transaction charge)
          </label>
          <Form.Control
            className={"input-box-sm"}
            value={data["after_charge"]}
            placeholder={
              telos
                ? "Telos (after 2.5% transaction charge)"
                : "Dollar" + " (after 2.5% transaction charge)"
            }
            disabled
          />
          <label className="input-label">Zeptacoins</label>
          <Form.Control
            className={"input-box-sm"}
            placeholder="Zeptacoins"
            value={data["zeptacoin"]}
            disabled
          />
          <label className="input-label">{telos ? "USD" : "TELOS"}</label>
          <Form.Control
            className={"input-box-sm"}
            disabled
            value={telos ? data["usd"] : data["telos"]}
            placeholder={telos ? "USD" : "Telos"}
            name="telos_after_"
          />
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <SubmitButton
          onClick={handleSubmit}
          loading={loading}
          title={"Add Zeptacoin"}
        />
      </Modal.Footer>
    </Modal>
  );
}

export default AddCoinModal;
