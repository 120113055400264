import React, { useState, useEffect } from "react";

import { Button, Form, Modal, Spinner } from "react-bootstrap";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { freighterLoginStart } from "../../redux/user/Action";
import StellarSdk from "stellar-sdk";
import QRCode from "qrcode.react";
import { freighterLogin } from "../../redux/user/Saga";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import freighterApi from "@stellar/freighter-api";
import
{
    getPublicKey,
    isConnected,
    isAllowed,
    setAllowed,
    getUserInfo,
    signAuthEntry,
    signTransaction,
    signBlob,
} from "@stellar/freighter-api";

const FreighterLogin = () =>
{
    const { freighterLoginLoading } = useSelector(
        (state) => state.user,
        shallowEqual
    );
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const history = useHistory();

    const storedPayload = localStorage.getItem("authToken");
    useEffect(() =>
    {
        if (loading)
        {
            try
            {

            } catch (error)
            {
                console.error(error); // Handle errors properly
            }
        }
        if (storedPayload)
        {
            const payload = JSON.parse(storedPayload);
            dispatch(freighterLoginStart(payload));
        }
    }, [loading, storedPayload]);

    // --------------------------FREIGHTER WALLET FUNCTION------------------------
    const handleFreighterLogin = async () =>
    {
        console.log("I AM FREIGHTER WALLET")
        const retrievePublicKey = async () =>
        {
            try
            {
                return await getPublicKey();
            } catch (e)
            {
                return e;
            }
        };

        const retrieveUserInfo = async () =>
        {
            try
            {
                let userInfo = await getUserInfo();

                if (!userInfo.publicKey)
                {
                    await setAllowed();
                    userInfo = await getUserInfo();
                }

                return userInfo.publicKey;
            } catch (e)
            {
                return e;
            }
        };


        const publicKey = await retrievePublicKey();
        const result1 = await retrieveUserInfo();

        console.log("Result from Retrieved Public Key:", publicKey);
        console.log("Result from User Info:", result1);
        const payload = {
            data: publicKey
        };
        dispatch(freighterLoginStart(payload))
    }



    return (
        <div>
            {freighterLoginLoading ? (
                <span
                    className="spinner-border spinner-border-smd"
                    role="status"
                    aria-hidden="true"
                ></span>
            ) : (
                <span onClick={handleFreighterLogin}>Connect Wallet</span>
            )}
        </div>
    );
};

export default FreighterLogin;
