import Web3 from "web3";
import { Api, JsonRpc, Serialize } from "eosjs";
import detectEthereumProvider from "@metamask/detect-provider";

import { networkList } from "./helper";
import { ContractABI } from "./ABI";
import { CONTRACT_ADDRESS } from "./config";

export const initWeb3 = async (type = "metamask") => {
  if (type === "metamask") {
    window.web3 = new Web3(window.ethereum);
    await window.ethereum.request({ method: "eth_requestAccounts" });
    const web3 = await new Web3(window.web3.currentProvider);
    return web3;
  }
};

export const metamaskInit = async () => {
  try {
    const provider = await detectEthereumProvider();
    let web3 = null;
    let accounts = null;
    let hasError = false;
    let errorInfo = null;
    if (provider) {
      if (provider !== window.ethereum) {
        console.error("Do you have multiple wallets installed?");
      }
      web3 = new Web3(Web3.givenProvider || "http://localhost:8545");
      await provider.request({
        method: "eth_requestAccounts",
      });
      accounts = await web3.eth.getAccounts();
    } else {
      hasError = true;
      errorInfo = "Please install MetaMask!";
    }
    return {
      account: accounts[0] || null,
      hasError,
      errorInfo,
    };
  } catch (err) {
    return {
      account: null,
      hasError: true,
      errorInfo: err.message,
    };
  }
};

export const metamaskBalance = async (account) => {
  const web3 = new Web3(Web3.givenProvider);
  const balance = await web3.eth.getBalance(account);
  return balance;
};

export const metamaskChainId = async () => {
  const web3 = new Web3(Web3.givenProvider);
  const chainId = await web3.eth.getChainId();
  return chainId;
};
export function getEvmNetwork(chainId) {
  return networkList.find((el) => el.chainId === chainId);
}
export const retrieveContract = async (data) => {
  try {
    const error = typeof window !== "undefined" && Boolean(window.ethereum);
    const web3 = await initWeb3("metamask");
    let contractAddress = "";
    if (process.env.REACT_APP_ENV == "development") {
      if (data.chain_id == 1) {
        contractAddress = "0x751C49444e6cd8867273b600e0814A3Dec903aA5";
      } else if (data.chain_id == 2) {
        contractAddress = "0xA4ba34334b6De2fe6C6F3c9d4b1765d92C96d859";
      }
    } else {
      contractAddress = CONTRACT_ADDRESS;
    }

    ///0x751C49444e6cd8867273b600e0814A3Dec903aA5
    const contractDetails = new web3.eth.Contract(ContractABI, contractAddress);
    return {
      error: false,
      contractDetails,
    };
  } catch (err) {
    return {
      error: true,
      contractDetails: null,
    };
  }
};

const fromHexString = (hexString) =>
  new Uint8Array(hexString.match(/.{1,2}/g).map((byte) => parseInt(byte, 16)));

const toHexString = (bytes) =>
  bytes.reduce((str, byte) => str + byte.toString(16).padStart(2, "0"), "");

const getSignData = async (teleportData) => {
  // logteleport(uint64_t id, uint32_t timestamp, name from, asset quantity, uint8_t chain_id, checksum256 eth_address)
  const sb = new Serialize.SerialBuffer({
    textEncoder: new TextEncoder(),
    textDecoder: new TextDecoder(),
  });
  sb.pushNumberAsUint64(teleportData.id);
  sb.pushUint32(teleportData.time);
  sb.pushName(teleportData.account);
  sb.pushAsset(teleportData.quantity);
  sb.push(teleportData.chain_id);
  sb.pushArray(fromHexString(teleportData.eth_address));

  return {
    claimAccount: "0x" + teleportData.eth_address,
    data: "0x" + toHexString(sb.array.slice(0, 69)),
    signatures: teleportData.signatures,
  };
};
export const claimAmount = async (data, accountAddress) => {
  try {
    const { error, contractDetails } = await retrieveContract(data);
    if (error) {
      return false;
    }
    const signData = await getSignData(data);
    let response = await contractDetails.methods
      .claim(signData.data, signData.signatures)
      .send({
        from: accountAddress,
      });
    // console.log(response);
    return response;
  } catch (err) {
    return false;
  }
};

// function getEvmNetworkList(state) {
//   return state.networkList;
// }

export const getEvmNetworkByName = (name) => {
  return networkList.find((el) => el.name.toUpperCase() === name.toUpperCase());
};

// export const getRemoteIdByChainid = (chainid) => {
//   let arrval= networkList.find((el) => el.chainId === chainid);
// };
export const getRemoteIdByChainid = (chainId) => {
  const net = networkList.find((el) => el.chainId == chainId);
  return net ? net.remoteId : "";
};
export const switchMetamaskNetwork = async (networkName) => {
  let chainId = getEvmNetworkByName(networkName).chainId;

  chainId = "0x" + chainId.toString(16);
  // console.log("switchToSelectedEvm - chainId:", chainId);
  try {
    await window.ethereum.request({
      method: "wallet_switchEthereumChain",
      params: [{ chainId: chainId }],
    });
  } catch (err) {
    const net = getEvmNetworkByName(networkName);
    if (err.code === 4902) {
      // Unrecognized chain ID
      if (net.rpcUrls.length > 0) {
        await window.ethereum.request({
          method: "wallet_addEthereumChain",
          params: [
            {
              chainId: chainId,
              chainName: net.chainName,
              nativeCurrency: net.nativeCurrency,
              rpcUrls: net.rpcUrls,
            },
          ],
        });
      }
    }
  }
};
