import React, { useState } from "react";
import { Form, Modal } from "react-bootstrap";
import { SubmitButton } from "../../components/components";
import SwalError from "../../components/ToastMessage/SwalError";
import AppServices from "../../services/AppService";
import { acceptedFileTypesArray, imageMaxSize } from "./CreateNewCollection";

export default function CreateCollection({
  showModal,
  hideModal,
  userCollection,
  ual,
  account,
  onSubmit,
}) {
  const [image, setimage] = useState("");
  const [collectionName, setCollectionName] = useState("");
  const [websiteURL, setWebsiteURL] = useState("");
  const [displayName, setDisplayName] = useState("");
  const [marketFee, setMarketFee] = useState("");
  const [description, setDescription] = useState("");
  const [loading, setLoading] = useState(false);

  const verifyFile = (files) => {
    if (files && files.length > 0) {
      const currentFile = files[0];
      const currentFileType = currentFile.type;
      const currentFileSize = currentFile.size;
      if (currentFileSize > imageMaxSize) {
        SwalError(
          "This file is not allowed. " + currentFileSize / 1000 + "Kb too large"
        );
        setLoading(false);
        return false;
      }
      if (!acceptedFileTypesArray.includes(currentFileType)) {
        SwalError("This file is not supported or file size is too large");
        setLoading(false);
        return false;
      }
      return true;
    }
  };
  const resetDetails = () => {
    setimage("");
    setCollectionName("");
    setWebsiteURL("");
    setDisplayName("");
    setMarketFee("");
    setDescription("");
  };

  const submitHandler = async () => {
    try {
      setLoading(true);
      console.log(displayName, collectionName, image, websiteURL);
      if (image !== "" && displayName.trim() !== "" && collectionName !== "") {
        if (!/^$|^([a-z1-5]{12})+$/.test(collectionName)) {
          setLoading(false);
          return SwalError("Please enter valid collection name");
        }
        if (!/^[a-zA-Z0-9]*$/.test(displayName)) {
          SwalError("Please enter valid display name");
          setLoading(false);
          return;
        }
        if (displayName.length > 20) {
          SwalError("DisplayName should not be more than 20 character");
          setLoading(false);
          return;
        }
        if (marketFee > 6 || marketFee < 0) {
          SwalError("Please enter a valid fee");
          setLoading(false);
          return;
        }
        if (websiteURL) {
          console.log("qwfws", websiteURL);
          const pattern =
            /^https?:\/\/(?:www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b(?:[-a-zA-Z0-9()@:%_\+.~#?&\/=]*)$/;
          console.log("qwfws", websiteURL, pattern.test(websiteURL));
          if (!pattern.test(websiteURL)) {
            SwalError("Please enter a valid URL");
            setLoading(false);
            return;
          }
        }
        if (!verifyFile(image)) return;
        if (userCollection.length > 0) {
          const data = userCollection.find(
            (data) =>
              data.collection_name === collectionName ||
              data.collection_name === displayName
          );
          if (data) {
            setLoading(false);
            return SwalError(
              "collection name already exists please choose another name"
            );
          }
        }

        let res = await new AppServices().uploadFileOnIpfs(image[0]);
        if (res) {
          onSubmit({
            image: res.data.IpfsHash,
            displayName,
            marketFee,
            description,
            websiteURL,
            collectionName,
          });
          resetDetails();
          hideModal(false);
          setLoading(false);
        } else {
          setLoading(false);
          SwalError("Can't upload image on IPFS");
        }
      } else {
        if (!collectionName) {
          SwalError("Please enter collection name");
        } else if (!displayName.trim()) {
          SwalError("Please enter display name");
        } else {
          SwalError("Please upload a picture");
        }
        setLoading(false);
      }
    } catch (e) {
      setLoading(false);
      console.log(e, "error");
      SwalError("Something went wrong");
    }
  };
  return (
    <Modal
      size="md"
      show={showModal}
      onHide={() => {
        hideModal(false);
        // resetDetails();
      }}
    >
      <Modal.Header closeButton>
        <Modal.Title className="text-dark">Create New Collection</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <label className="input-label">Upload image *</label>
          <input
            className={"input-box-sm"}
            type="file"
            name="image"
            onChange={(e) => {
              setimage(e.target.files);
            }}
          />
          <label className="input-label">
            Collection Name * (12 Character, 1-5 & a-z)
          </label>
          <input
            className={"input-box-sm"}
            type="text"
            name="CollectionName"
            onChange={(e) => setCollectionName(e.target.value)}
          />
          <label className="input-label">Display Name*</label>
          <input
            className={"input-box-sm"}
            type="text"
            min={0}
            maxLength={20}
            onWheel={(e) => e.target.blur()}
            name="Name"
            pattern="[a-zA-Z0-9]+"
            onChange={(e) => setDisplayName(e.target.value)}
          />
          <label className="input-label">Website URL</label>
          <input
            className={"input-box-sm"}
            type="text"
            name="URL"
            onChange={(e) => setWebsiteURL(e.target.value)}
          />
          <label className="input-label">Collection Description</label>
          <textarea
            className={"input-box-sm"}
            rows={5}
            type="text"
            name="Description"
            onChange={(e) => setDescription(e.target.value)}
          />
          <label className="input-label">Market Fee (0% - 6%)</label>
          <input
            className={"input-box-sm"}
            type="Number"
            name="Fee"
            onChange={(e) => setMarketFee(e.target.value)}
          />
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <SubmitButton
          onClick={() => submitHandler()}
          title={"Send"}
          loading={loading}
        />
      </Modal.Footer>
    </Modal>
  );
}
