import React, { useEffect, useState } from "react";
import { Modal, Form, DropdownButton, Dropdown } from "react-bootstrap";
import SwalSuccess from "../../../components/ToastMessage/SwalSuccess";
import SwalError from "../../../components/ToastMessage/SwalError";
import { getRequiredProtectedData } from "../../../redux/apicalltemplate";
import { userApis } from "../../../redux/api";
import Web3 from "web3";

import { toast } from "react-toastify";
import { SuccessToast } from "../../../components/ToastMessage/SuccessToast";
import ETHIcon from "../../../icons/eth.png";
import TelosIcon from "../../../icons/telos.png";
import BnbIcon from "../../../icons/bnb.svg";
import PinInput from "react-pin-input";
import {
  claimAmount,
  metamaskChainId,
  switchMetamaskNetwork,
  metamaskInit,
} from "../../../components/TokenBridge/methods";
import copyIcon from "../../../icons/copy.png";
import { CopyToClipboard } from "react-copy-to-clipboard/lib/Component";
import { shortAddress } from "../../../utils/utils";

import { ClaimList } from "./ClaimList";
import { LoadingView } from "../../../components/loading_view";
const TokenBridge = ({ show, setShow }) => {
  const [address, setAddress] = useState("");
  const [amount, setAmount] = useState("");
  const [pin, setPin] = useState("");
  const [account, setAccount] = React.useState("");
  const [hasError, setHasError] = React.useState(false);
  const [errorInfo, setErrorInfo] = React.useState(null);

  // claim table
  const [claimTable, setClaimTable] = useState([]);

  const [networkImage, setNetworkImage] = useState(ETHIcon);
  const [network, setNetwork] = useState("ETH");
  const [currentChainId, setCurrentChainId] = useState("");
  const handleClose = () => setShow(false);
  const [loading, setLoading] = useState(false);
  const [claimLoading, setClaimLoading] = useState(false);
  const updateStates = (metamaskInitObj) => {
    setAddress(metamaskInitObj.account);
    setAccount(metamaskInitObj.account);
    setHasError(metamaskInitObj.hasError);
    setErrorInfo(metamaskInitObj.errorInfo);
  };

  const onMetamaskInit = async () => {
    try {
      const metamaskInitObj = await metamaskInit();
      updateStates(metamaskInitObj);
      const jsonValue = JSON.stringify(metamaskInitObj);
      await window.sessionStorage.setItem("@metamask", jsonValue);
    } catch (error) {
      // console.error("metamask context error: ", error);
    }
  };

  useEffect(async () => {
    onMetamaskInit();
  }, []);

  useEffect(async () => {
    if (account) {
      let chainId = await metamaskChainId();
      setCurrentChainId(chainId);
      handleClaimData();
    }
  }, [account]);

  window.ethereum.on("accountsChanged", async (accounts) => {
    if (accounts && accounts.length) {
      setAddress(accounts[0]);
      setAccount(accounts[0]);
    }
  });
  window.ethereum.on("chainChanged", async () => {
    const web3 = new Web3(Web3.givenProvider);
    const chainId = await web3.eth.getChainId();
    setCurrentChainId(chainId);
  });

  //handle form submit------------------------
  const handleSubmit = async (event) => {
    event.preventDefault();
    const web3 = new Web3(Web3.givenProvider);
    const newAddress = address.replace("0x", "") + "000000000000000000000000";
    let data = {
      pin: pin,
      amount: amount,
      address: newAddress,
      to: network,
    };

    // return;
    setLoading(true);
    const response = await getRequiredProtectedData(
      "POST",
      userApis.TOKEN_BRIDGE,
      data,
      {}
    );
    if (response && response.data && response.data.success) {
      SwalSuccess(response.data.message);
      handleClaimData();
    }
    if (response && response.data && !response.data.success) {
      SwalError(response.data.message);
    }
    if (response && response.response && response.response.data) {
      SwalError(response.response.data.message);
    }
    setLoading(false);
  };

  const handleClaimData = async () => {
    // return;
    setClaimLoading(true);
    const response = await getRequiredProtectedData(
      "GET",
      userApis.CLAIM_BRIDGE,
      {},
      {}
    );
    if (response && response.data && response.data.success) {
      // SwalSuccess(response.data.message);
      setClaimTable(response.data.data);
    }
    if (response && response.data && !response.data.success) {
      // SwalError(response.data.message);
    }
    if (response && response.response && response.response.data) {
      // SwalError(response.response.data.message);
    }
    setClaimLoading(false);
  };

  return (
    <Modal show={show} onHide={handleClose} size="lg">
      <Modal.Header closeButton>
        <Modal.Title>Send ZPTC</Modal.Title>
      </Modal.Header>
      <div className="align-center flex-column p-3">
        {!account && (
          <button className="button-medium" onClick={onMetamaskInit}>
            Connect Metamask
          </button>
        )}
        {account && (
          <div>
            {" "}
            <span className="text-dark">Account: </span>
            <span>
              {account && shortAddress(account)}
              <span>
                <CopyToClipboard
                  text={account}
                  onCopy={() => {
                    toast.success(<SuccessToast message={"Copied!"} />, {
                      toastId: 1,
                      updateId: 1,
                    });
                  }}
                >
                  <img
                    src={copyIcon}
                    width={"17px"}
                    className={"mx-2 cur-pointer"}
                  />
                </CopyToClipboard>
              </span>
            </span>
          </div>
        )}
      </div>
      <Form onSubmit={handleSubmit}>
        <Modal.Body className="px-5">
          <div className="row">
            <div className="col-md-7">
              <label className="text-dark" style={{ marginLeft: "10px" }}>
                To
              </label>
              <Form.Control
                className={"input-box-sm"}
                value={address}
                type="text"
                required
                onChange={(e) => setAddress(e.target.value)}
                placeholder="Enter Address"
              />
            </div>
            <div className="col-md-5">
              <label className="text-dark" style={{ marginLeft: "10px" }}>
                Network
              </label>
              <DropdownButton
                title={
                  <span className="">
                    <img width={30} src={networkImage} alt="" />
                    <span className="mx-1">{network}</span>
                  </span>
                }
                id="dropdown-basic-button"
                className="custom_dropdown input-box-sm  align-middle token-dropdown"
                variant=""
              >
                {[
                  { networkImage: ETHIcon, network: "ETH" },
                  // { networkImage: TelosIcon, network: "TELOS" },
                  { networkImage: BnbIcon, network: "BSC" },
                ].map((el, i) => (
                  <Dropdown.Item
                    key={i}
                    onClick={() => (
                      setNetworkImage(el.networkImage), setNetwork(el.network)
                    )}
                  >
                    <span className="d-flex align-items-center">
                      <img width={30} src={el.networkImage} alt="" />
                      <div className="mx-1">{el.network}</div>
                    </span>
                  </Dropdown.Item>
                ))}
              </DropdownButton>
            </div>
          </div>

          <label className="text-dark" style={{ marginLeft: "10px" }}>
            Amount
          </label>
          <Form.Control
            className={"input-box-sm"}
            value={amount}
            type="number"
            required
            onChange={(e) => setAmount(e.target.value)}
            placeholder="Enter your Amount"
          />

          <label className="text-dark" style={{ marginLeft: "10px" }}>
            Pin
          </label>
          <PinInput
            className="mx-auto"
            length={4}
            initialValue={pin}
            secret
            onChange={(value, index) => setPin(value)}
            type="numeric"
            inputMode="number"
            style={{ padding: "10px", marginLeft: "auto" }}
            inputStyle={{ borderColor: "#daca9e" }}
            inputFocusStyle={{ borderColor: "blue" }}
            onComplete={(value, index) => {}}
            required
          />
        </Modal.Body>
        <Modal.Footer className="p-3">
          <div className="w-100">
            <div>
              <button
                className="button-medium border-shadow medium-btn ml-auto"
                type="submit"
                title={"Submit"}
              >
                {!loading ? (
                  "Submit"
                ) : (
                  <>
                    <span
                      className="spinner-border spinner-border-smd"
                      role="status"
                      aria-hidden="true"
                    ></span>
                  </>
                )}
              </button>
            </div>
            {claimLoading ? (
              <div style={{ height: "200px" }}>
                <LoadingView />
              </div>
            ) : claimTable ? (
              claimTable.length == 0 ? (
                <div className="align-center" style={{ height: "200px" }}>
                  {" "}
                  No Claim Data Found for this wallet
                </div>
              ) : (
                <>
                  {claimTable && (
                    <div className="box-shadow  border-md p-2 m-2 my-4">
                      <h5 className=" text-center font-weight-bold">
                        Claim Tokens
                      </h5>
                      <div className="claim-table">
                        {claimTable.map((el, i) => {
                          return (
                            <ClaimList
                              el={el}
                              account={account}
                              currentChainId={currentChainId}
                              handleClaimData={handleClaimData}
                            />
                          );
                        })}
                      </div>
                    </div>
                  )}
                </>
              )
            ) : null}
          </div>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

export default TokenBridge;
