import React from "react";
import NFT1 from "../../../images/guide/nft/nft1.png";
import NFT2 from "../../../images/guide/nft/nft2.png";
import NFT3 from "../../../images/guide/nft/nft3.png";
import NFT4 from "../../../images/guide/nft/nft4.png";
import NFT5 from "../../../images/guide/nft/nft5.png";
import NFT6 from "../../../images/guide/nft/nft6.png";
import NFT7 from "../../../images/guide/nft/nft7.png";
import NFT8 from "../../../images/guide/nft/nft8.png";
import NFT9 from "../../../images/guide/nft/nft9.png";
import NFT10 from "../../../images/guide/nft/nft10.png";
import NFT11 from "../../../images/guide/nft/nft11.png";
import NFT12 from "../../../images/guide/nft/nft12.png";

export const NFTGuide = () => {
  return (
    <div className="mx-3">
      <p className="text-justify">
      Users can create Music NFTs using Zeptagram. A music NFT is a unique digital asset that lives on the blockchain, and can be bought or sold. Users can choose Zeptagram NFT marketplace for launching their NFTs. Also they can choose to sell or list it for auction according to their desired price.
      </p>
      
      <br />
      <h4 className="text-dark">Steps to create NFT</h4>
      <ul>
        <li>
        Sign in to the platform using Anchor.
        <img className="guide-img guide-md" src={NFT1} />
        </li>
        <li>
        Ensure your publisher KYC is complete
        </li>
        <li>
        Navigate to "My Music" and then "Music List."
        <img className="guide-img guide-md" src={NFT3} />
        </li>
        <li>
        Choose the verified song you want to create an NFT for.
        <img className="guide-img guide-md" src={NFT2} />
        </li>
        <li>
        Click "Create NFT" in the song details.
        <img className="guide-img guide-md" src={NFT4} />
        <img className="guide-img guide-md" src={NFT5} />
        </li>
        <p>If there are no collections available, create a collection and schema.</p>
       
        <img className="guide-img guide-md" src={NFT6} />
        <img className="guide-img guide-md" src={NFT7} />
        <img className="guide-img guide-md" src={NFT8} />
        <li>
        Go to the "Create Music NFT" window, upload an image and fill in the details, then click "Create NFT."
        <img className="guide-img guide-md" src={NFT9} />
        <img className="guide-img guide-md" src={NFT10} />
      
        </li>

        <li>After successfully creation, you can view your created music NFTs in 'My Music' section.
        <img className="guide-img guide-md" src={NFT11} />
        <img className="guide-img guide-md" src={NFT12} />
 
        </li>
      </ul>
     
       
    </div>
  );
};
