import React from "react";
import "./NoRecordTable.scss";
import NodataFound from "../../icons/nodata.svg";
const NoRecordTable = () => {
  return (
    <div className="no-data">
      <img src={NodataFound} width={200} />
      <div className="text-center text-dark mt-2">No Data Found</div>
    </div>
  );
};

export default NoRecordTable;
