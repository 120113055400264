export const getZeptaPrice = async (date = "30-12-2021", type) => {
  const response = await fetch(
    `https://api.coingecko.com/api/v3/coins/zeptagram/history?date=${date}`
  );
  const price = await response.json();

  if (price && price.market_data && price.market_data.current_price.usd) {
    return price.market_data.current_price.usd.toFixed(3);
  } else return 0.5;
};
