import React, { useState } from "react";
import Zeptagram from "../../images/zeptagram.svg";
import Band from "../../images/band.svg";
import Girl from "../../images/lady.svg";
import Neon from "../../images/neonbrand.svg";
import { useHistory } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { reset2faStart } from "../../redux/user/Action";
import GoogleCaptcha from "../../components/GoogleCaptcha/Captcha";

const Reset2fa = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const history = useHistory();
  const recaptchaRef = React.useRef();

  const dispatch = useDispatch();
  const reset2faloading = useSelector((state) => state.user.reset2faloading);
  const handleSubmit = async (e) => {
    e.preventDefault();
    const captchatoken = await recaptchaRef.current.executeAsync();
    if (!captchatoken) {
      return;
    }
    const payload = {
      email: email,
      captcha: captchatoken,
    };
    dispatch(reset2faStart(payload));
  };

  return (
    <div className="row m-auto h-100  w-100  ">
      <div className="col-md-6  ">
        <div onClick={() => history.push("/login")}>
          <img src={Zeptagram} alt="" />
        </div>
        <div className="align-center h-84">
          <div className="d-flex flex-column  ">
            <h4 className="text-dark">Reset 2FA Authentication</h4>
            <div className="text-grey w-500 f-14">
              Enter your email and click Reset. We will send you an OTP to reset
              your 2FA.
            </div>
            <form className="d-flex flex-column " onSubmit={handleSubmit}>
              <div className="label-txt"> Email</div>
              <input
                type="email"
                value={email}
                className="input-box my-1 align-self-start"
                onChange={(e) => setEmail(e.target.value)}
                placeholder="Email"
                required
              />
              {/* <input
                type="password"
                className="input-box my-3 align-self-start"
                onChange={(e) => setPassword(e.target.value)}
                placeholder="Password"
                required
              /> */}
              <div className="d-flex align-items-center">
                <button
                  className="button-medium border-shadow m-2 medium-btn"
                  type="submit"
                >
                  {!reset2faloading ? (
                    "Reset"
                  ) : (
                    <>
                      <span
                        className="spinner-border spinner-border-smd"
                        role="status"
                        aria-hidden="true"
                      ></span>
                    </>
                  )}
                </button>
                <button
                  className="button-medium btn-grey border-shadow m-2"
                  type="button"
                  onClick={() => history.push("/login")}
                >
                  Cancel
                </button>
              </div>
            </form>

            {/* <div className='text-grey mt-3'>Did not get an email? <span className='text-hover' onClick={()=>history.push('/resendotp')}>Click here</span> and we will resend it to you!</div> */}
          </div>
        </div>
        <GoogleCaptcha recaptchaRef={recaptchaRef} />
      </div>
      <div className="col-md-6 image-container">
        <span className="verify-image verify-image-1">
          <img src={Neon} alt="" />
        </span>
        <span className="verify-image verify-image-2">
          <img src={Band} alt="" />
        </span>
        <div className="verify-image verify-image-3">
          <img height={380} className="girl-img" src={Girl} alt="" />
        </div>
      </div>
    </div>
  );
};

export default Reset2fa;
