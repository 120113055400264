import React from "react";
import Img1 from "../../../images/guide/image9.png";
import Img2 from "../../../images/guide/image22.png";
import Img3 from "../../../images/guide/image11.png";
import Img4 from "../../../images/guide/image12.png";
import Img5 from "../../../images/guide/image37.png";
import Img6 from "../../../images/guide/image3.png";
import Img7 from "../../../images/guide/image31.png";
import Img8 from "../../../images/guide/image40.png";
import Img9 from "../../../images/guide/image4.png";
import Img10 from "../../../images/guide/image24.png";
export const MusicGuide = () => {
  return (
    <div className="mx-3">
      <p className="text-justify">
        Zeptagram is a unique product to enhance the financing activity for good
        music creation and publishing. It also eliminates the intermediary and
        compensates both investors and publishers at the best.
        <br /> For music creators and owners, you can enlist your best music on
        our Zeptagram platform. To start with you need to fill a form and send
        the music for verification. If your music is already streaming on any
        platform and you have your royalty statement then it will be{" "}
        <b>crowd-sale</b>. If it’s a new music with no prior listing in any
        streaming platform, then it will be <b>crowd-funding.</b> In more laymen
        terms, a crowdsale represents sales of music rights on the platform
        without preconditions. A crowdfunding campaign is used when selling
        music rights for a specific cause, to produce an album, to do a tour or
        for charity donations. <br />
        <br />
        <b>ISWC</b> stands for International Standard Musical Work Code. It
        identifies a composition. <b>ISRC</b> stands for International Standard
        Recording Code. It identifies a sound recording.So if type of the
        content is an album,then it will be a ISRC and if type of the content is
        a song then it is a ISWC.
      </p>
      <p className="text-justify">
        To create a campaign
        <ul>
          <li>
            <b>Listing Type</b>: It refers to the type of list you want to go
            for your music(crowd-sale/crowd-fund)
          </li>
          <li>
            <b>ISWC Code</b>: International standard Musical Work Code assigned
            by the collection society of your nation.
          </li>
          <li>
            <b>Title</b>: Name of the song
          </li>
          <li>
            <b>Short Name</b>: It’s very important , hence please look into the
            associated tooltip while naming it
          </li>
          <li>
            <b>Streaming App link</b>: The link of the music which has been
            enlisted on any streaming app.
          </li>
          <li>
            <b>Work-key (Song ID)</b>:This is the identity/unique number of your
            song, and issued by PRO while registering a song.
          </li>

          <li>
            <b>Composer</b>: Name of the song composer
          </li>
          <li>
            <b>Co-owners</b>: The people who have assisted you in making the
            song
          </li>
          <li>
            <b>Your ownership</b>: In case of a band song, then enlist the
            ownership of the song you posses
          </li>
          <li>
            <b>Published Year</b>: In case of crowd-sale, mention the year when
            the song was enlisted.
          </li>
          <li>
            <b>Track Duration</b>: The length of the music track (always give it
            in the format mentioned in the tooltip)
          </li>
          <li>
            <b>Description</b>: Describe the song in the easiest way to catch
            more stares of investors.
          </li>
        </ul>
      </p>
      <p className="text-justify">
        <b>ISWC </b>
        <ul>
          <li>
            <b>Crowdfunding</b>: Fill the ‘ADD SONG’ form with details for the
            songs.
            <img className="guide-img" src={Img1} />
            <img className="guide-img" src={Img2} />
            <p className="text-justify">
              <b>Note:</b> As Composers and Co owners can be multiple ,so after
              writing the names, clicks on ‘Add’ button in front of respective
              fields to add them.
            </p>
            <img className="guide-img" src={Img3} />
          </li>
          <li>
            <b>Crowdsale </b>
            For crowdsale User has to upload a royalty sheet and add revenue
            apart from adding above fields.You can upload a blank pdf if you
            don't have a royality sheet.
            <img className="guide-img" src={Img4} />
            <img className="guide-img" src={Img5} />
            <p className="text-justify">
              Click on Add and You will find your created music in the ‘Music
              List’.
            </p>
            <img className="guide-img" src={Img6} />
            <p className="text-justify">
              {" "}
              Click on music and Send for Verification
            </p>
            <img className="guide-img" src={Img7} />
            <p className="text-justify">
              Wait for your song to be verified by the Zeptagram Department.
              Post Verification, you will Be able to see in Verified Music.
            </p>
            <img className="guide-img" src={Img8} />
          </li>
        </ul>
      </p>
      <p className="text-justify">
        <b>ISRC</b>
        <ul>
          <li>
            For ISRC song users can also specify genre, owners name,artists and
            contributors for the album.
          </li>
          <img className="guide-img" src={Img9} />
          <img className="guide-img" src={Img10} />
        </ul>
        For both Crowdsale and Crowdfund steps will remain the same as provided
        in above already.
      </p>
    </div>
  );
};
