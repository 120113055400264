import { Col, Row } from "react-bootstrap";
import { Form } from "react-bootstrap";
import { HeightBox, SubmitButton } from "../../components/components";
import { useState } from "react";
import { useSubmit } from "../../api/api_client";
import { userApis } from "../../redux/api";
import InfoToast from "../../components/ToastMessage/InfoToast";
import { notifyError, notifySuccess } from "../../utils/utils";
import { CopyToClipboard } from "react-copy-to-clipboard/lib/Component";
import AppStoreIcon from "../../images/appstore.svg";
import PlayStoreIcon from "../../images/googleplaystore.svg";

export default function SecurityPage() {
  const faResult = localStorage.getItem("2faEnabled");

  const isEnaled = faResult != null && faResult === "true";
  const [faEnabled, setFaEnabled] = useState(isEnaled);
  const [disableOTP, setDisableOTP] = useState("");
  const [showVerify, setShowVerify] = useState(false);
  const [disableLoading, setDisableLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [faResponse, setFaResponse] = useState();
  const [verificationCode, setVerificationCode] = useState("");

  const enable2fa = () => {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useSubmit(userApis.ENABLE_2FA, {}, "PUT")
      .then((response) => {
        const secretCode = response["secretCode"].split("=")[1];
        setFaResponse({
          secretCode: secretCode,
          qrCode: response["qrCode"],
        });
        setShowVerify(true);
      })
      .catch((e) => {
        // console.error(e);
      });
  };

  const disable2fa = (e) => {
    e.preventDefault();
    if (!disableOTP) {
      InfoToast(`Please enter 2fa code`);
      return;
    }
    setDisableLoading(true);
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useSubmit(
      userApis.DISABLE_2FA,
      {
        otpToken: disableOTP,
      },
      "PUT"
    )
      .then((response) => {
        notifySuccess("Disabled");
        localStorage.setItem("2faEnabled", "false");
        //console.log(response);
        setFaEnabled(false);
        setDisableLoading(false);
      })
      .catch((e) => {
        notifyError(e.message);
        setDisableLoading(false);
        // console.error(e);
      });
  };

  const handleVerify = () => {
    if (verificationCode === "" || verificationCode.length !== 6) {
      notifyError("2FA code length must be 6 characters");
      return;
    }
    setLoading(true);
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useSubmit(
      userApis.VERIFY_2FA,
      {
        "2faToken": verificationCode,
      },
      "PUT"
    )
      .then((response) => {
        notifySuccess("Verified");
        localStorage.setItem("2faEnabled", "true");
        setFaEnabled(true);
        setShowVerify(false);
        setLoading(false);
        // window.location.reload();
      })
      .catch((e) => {
        notifyError(e.message);
        setLoading(false);
        // console.error(e);
      });
  };

  return (
    <>
      <div className={"wrapper h-100"}>
        <div className={"container"}>
          <h3>Two Step Verification</h3>

          <HeightBox height={"30px"} />

          <div hidden={showVerify}>
            {faEnabled && (
              <form style={{ maxWidth: "300px" }} onSubmit={disable2fa}>
                <label
                  className="text-dark f-16"
                  style={{ marginLeft: "10px" }}
                >
                  Enter your 2FA Code
                </label>
                <Form.Control
                  className={"input-box-sm"}
                  value={disableOTP}
                  onChange={(e) => setDisableOTP(e.target.value)}
                  type="number"
                  placeholder="2FA Code"
                  required
                />
                <button
                  className="button-medium border-shadow   medium-btn"
                  type="submit"
                  title={"Disable 2FA"}
                >
                  {disableLoading ? (
                    <span
                      className="spinner-border spinner-border-smd"
                      role="status"
                      aria-hidden="true"
                    ></span>
                  ) : (
                    "Disable 2FA"
                  )}
                </button>
              </form>
            )}

            {!faEnabled && (
              <Form.Check
                type="checkbox"
                defaultChecked={faEnabled}
                onChange={(ev) => {
                  //console.log(ev.target.checked);
                  //  setFaEnabled()
                  if (ev.target.checked) {
                    enable2fa();
                  } else {
                    disable2fa();
                  }
                }}
                label={`${
                  faEnabled ? "Disable" : "Enable"
                } Two-Factor Authentication via Google Authenticator /Authy`}
              />
            )}

            <HeightBox height={"30px"} />
          </div>

          <div hidden={!showVerify}>
            <h6>Manual Key</h6>
            <HeightBox height={"20px"} />

            <p className="text-dark">
              {faResponse !== undefined ? faResponse["secretCode"] : ""}
            </p>

            <div style={{ width: "150px" }}>
              <CopyToClipboard
                text={faResponse !== undefined ? faResponse["secretCode"] : ""}
                onCopy={() => {
                  notifySuccess("Copied! ");
                }}
              >
                <button className={"button-small-long"}>Copy</button>
              </CopyToClipboard>
            </div>

            <HeightBox height={"30px"} />

            <h6>SCAN QR CODE FROM AUTHENTICATOR APP TO GET YOUR 2FA CODE</h6>
            <h6 className="font-weight-light f-14 ">
              You can download from link below
            </h6>
            <Row className="my-2" style={{ maxWidth: "300px" }}>
              <Col sm={6} className={"d-flex justify-content-center"}>
                <a
                  target={"_blank"}
                  title="Download Google Authenticator for Android"
                  href={
                    "https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2"
                  }
                >
                  <img height={"100px"} src={PlayStoreIcon} />
                </a>
              </Col>
              <Col sm={6} className={"d-flex justify-content-center"}>
                <a
                  target={"_blank"}
                  title="Download Google Authenticator for IOS"
                  href={
                    "https://apps.apple.com/app/google-authenticator/id388497605"
                  }
                >
                  <img height={"100px"} src={AppStoreIcon} />
                </a>
              </Col>
            </Row>
            <img src={faResponse ? faResponse["qrCode"] : ""} />

            <Row>
              <Col xs={12} md={4}>
                <input
                  className={"input-box-sm"}
                  name={"code"}
                  onChange={(e) => {
                    setVerificationCode(e.target.value);
                  }}
                  type={"number"}
                  placeholder={"Verification Code"}
                />
              </Col>
            </Row>

            <HeightBox height={"10px"} />

            <div style={{ width: "150px" }}>
              <SubmitButton
                size={"button-small-long"}
                loading={loading}
                onClick={() => {
                  handleVerify();
                }}
                title={"Verify"}
              />
            </div>

            <HeightBox height={"40px"} />
          </div>
        </div>
      </div>
    </>
  );
}
