import React from "react";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { getRequiredProtectedData } from "../../redux/apicalltemplate";
import { crowdsaleApis } from "../../redux/api";
import { useState } from "react";
import CrowdCardMedium from "../../components/CrowdCards/CrowdCardMedium";

const NewCrowdsales = ({ handleCrowsaleModal }) => {
  const [live, setLive] = useState(null);
  const [successful, setSuccessful] = useState(null);
  const [upcoming, setUpcoming] = useState(null);
  const userdata = useSelector((state) => state.user.data);

  useEffect(async () => {
    const response = await getRequiredProtectedData(
      "GET",
      `${crowdsaleApis.NEW_CROWDSALES}`,
      {},
      {}
    );

    if (response && response.data) {
      if (response.data.live && response.data.live.data) {
        setLive(response.data.live.data);
      }
      if (response.data.successful && response.data.successful.data) {
        setSuccessful(response.data.successful.data);
      }
      if (response.data.upcoming && response.data.upcoming.data) {
        setUpcoming(response.data.upcoming.data);
      }
    }
  }, []);
  const bookmarks=useSelector(state=>state.user.bookmarks)


  return (
    <div>
      {successful ? (
        <>
          <h4 className="text-dark">Successful Crowdsale/Crowdfund</h4>
          <div className="d-flex align-items-center justify-content-around flex-wrap">
            {successful
              ? successful
                  .filter((el, i) => i < 4)
                  .map((el, i) => {
                    return (
                      <CrowdCardMedium
                        key={i}
                        el={el}
                        ismycrowdsale={true}
                        handleCrowdsaleDetails={(el) => handleCrowsaleModal(el)}
                        type={"crowdfund"}
                        userid={userdata && userdata._id}
                        isBookmark={!!bookmarks.find(ml=>ml.crowdsaleId==el._id)}
                        
                      />
                    );
                  })
              : null}
          </div>
        </>
      ) : null}

      {live ? (
        <>
          <h4 className="text-dark">Live Crowdsale/Crowdfund</h4>
          <div className="d-flex align-items-center justify-content-around flex-wrap">
            {live
              ? live
                  .filter((el, i) => i < 4)
                  .map((el, i) => {
                    return (
                      <CrowdCardMedium
                        key={i}
                        el={el}
                        ismycrowdsale={true}
                        handleCrowdsaleDetails={(el) => handleCrowsaleModal(el)}
                        type={"crowdfund"}
                        userid={userdata && userdata._id}
                        isBookmark={!!bookmarks.find(ml=>ml.crowdsaleId==el._id)}
                      />
                    );
                  })
              : null}
          </div>
        </>
      ) : null}

      {upcoming ? (
        <>
          <h4 className="text-dark">Upcoming Crowdsale/Crowdfund</h4>
          <div className="d-flex align-items-center justify-content-around flex-wrap">
            {upcoming
              ? upcoming
                  .filter((el, i) => i < 4)
                  .map((el, i) => {
                    return (
                      <CrowdCardMedium
                        key={i}
                        el={el}
                        ismycrowdsale={true}
                        handleCrowdsaleDetails={(el) => handleCrowsaleModal(el)}
                        type={"crowdfund"}
                        userid={userdata && userdata._id}
                        isBookmark={!!bookmarks.find(ml=>ml.crowdsaleId==el._id)}
                      />
                    );
                  })
              : null}
          </div>
        </>
      ) : null}
    </div>
  );
};

export default NewCrowdsales;
