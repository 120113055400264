import React, { useState } from "react";
import { Modal, Form } from "react-bootstrap";
import InfoToast from "../../components/ToastMessage/InfoToast";
import SwalSuccess from "../../components/ToastMessage/SwalSuccess";
import SwalError from "../../components/ToastMessage/SwalError";
import { getRequiredProtectedData } from "../../redux/apicalltemplate";
import { userApis } from "../../redux/api";
import PinInput from "react-pin-input";

const ClaimTokensale = ({ show, setShow, claimData }) => {
  const [pin, setPin] = useState("");
  const [poolid, setPoolId] = useState("");
  const handleClose = () => setShow(false);
  const [loading, setLoading] = useState(false);

  //handle form submit------------------------
  const handleSubmit = async (event) => {
    event.preventDefault();
    if (pin.length != 4) {
      InfoToast("Pin must be 4 characters long");
      return;
    }
    if (!poolid) {
      InfoToast("Please Enter Pool ID");
      return;
    }
    setLoading(true);
    let data = {};
    data.pin = pin;
    if (poolid) {
      data.pool_id = `${poolid}`;
    }

    const response = await getRequiredProtectedData(
      "POST",
      `${userApis.CLAIM_TOKENSALE}`,
      data,
      {}
    );
    if (response && response.data && response.data.success) {
      SwalSuccess(response.data.message);
      setTimeout(() => {
        setShow(false);
      }, 800);
    }
    if (response && response.data && !response.data.success) {
      SwalError(response.data.message);
    }
    if (response && response.response && response.response.data) {
      SwalError(response.response.data.message);
    }
    setLoading(false);
  };

  return (
    <Modal show={show} onHide={handleClose} size="lg">
      <Modal.Header closeButton>
        <Modal.Title className="text-dark">Claim Tokensale Token</Modal.Title>
      </Modal.Header>
      <Form onSubmit={handleSubmit}>
        <Modal.Body className="p-5">
          <div>
            <div className="table-responsive order-table  w-100">
              <table className="table table-bordered table-hover">
                <thead>
                  <tr>
                    <th scope="col" className="text-hover">
                      Allocation
                    </th>
                    <th scope="col" className="text-hover">
                      Bid
                    </th>
                    <th scope="col" className="text-hover">
                      Claimed
                    </th>
                    <th scope="col" className="text-hover">
                      To Be Claimed
                    </th>
                    <th scope="col" className="text-hover">
                      Vesting
                    </th>
                    <th scope="col" className="text-hover">
                      Pool ID
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {claimData &&
                    claimData.map((el) => {
                      return (
                        <tr className="cur-pointer text-dark" key={el.id}>
                          <td>{el.allocation}</td>
                          <td>{el.bid}</td>
                          <td>{el.claimed}</td>
                          <td>{el.to_be_claimed}</td>
                          <td>{el.vesting}</td>
                          <td>{el.pool_id}</td>
                        </tr>
                      );
                    })}
                </tbody>
              </table>
            </div>
          </div>
          <div className="align-center flex-column mt-2">
            <div>
              <label className="text-dark" style={{ marginLeft: "10px" }}>
                Enter Pin*
              </label>
              <div className="text-center">
                <PinInput
                  className="mx-auto"
                  length={4}
                  initialValue={pin}
                  secret
                  onChange={(value, index) => setPin(value)}
                  type="numeric"
                  inputMode="number"
                  style={{ padding: "10px", marginLeft: "auto" }}
                  inputStyle={{ borderColor: "#daca9e" }}
                  inputFocusStyle={{ borderColor: "blue" }}
                  onComplete={(value, index) => {}}
                  required
                />
              </div>
            </div>
            <div>
              <label className="text-dark" style={{ marginLeft: "10px" }}>
                Pool Id*
              </label>
              <Form.Control
                className={"input-box-sm"}
                value={poolid}
                onChange={(e) => setPoolId(e.target.value)}
                type="text"
                placeholder="Pool ID"
                required
              />
            </div>
            <div className="text-grey text-end" style={{ fontSize: "10px" }}>
              *Enter Pool Id for which you want to claim
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer className="p-3">
          <button
            className="button-medium border-shadow medium-btn"
            type="submit"
            title={"Submit"}
          >
            {!loading ? (
              "Confirm"
            ) : (
              <>
                <span
                  className="spinner-border spinner-border-smd"
                  role="status"
                  aria-hidden="true"
                ></span>
              </>
            )}
          </button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

export default ClaimTokensale;
