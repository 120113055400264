import {Image} from "react-bootstrap";
import '../../styles/style.scss'

export default function SettingComponent(props) {

    return <div>

        <div  onClick={props.onClick} className={"setting-container"}>
            <Image src={props.icon} style={{background:props.icon_color}} className={"setting-icon"}/>
            <div className={"setting-sub-container"}>
            <span className={"setting-heading"}>
                {props.title}
            </span>
                <span className={"setting-subheading"}>
                 {props.subtitle}
            </span>
            </div>
        </div>
        <div className={"setting-divider"}/>
    </div>
}