import React, { useEffect, useState, useCallback, useRef } from "react";
import {
  Navbar,
  NavLink,
  Nav,
  Dropdown,
  DropdownButton,
} from "react-bootstrap";
import Zeptagram from "../../images/zeptagram.svg";
import Home from "../../images/home.svg";
import Music from "../../images/music.svg";
import MyCrowdsale from "../../images/mycrowdsale.svg";
import Security from "../../images/security.svg";
import Rocket from "../../images/rocket.svg";
import Vault from "../../images/vault.svg";
import Wallet from "../../images/wallet.svg";
import Search from "../../images/search.svg";
import PortfolioIcon from "../../images/portfolio.svg";
import "./Header.scss";
import { useDispatch, useSelector } from "react-redux";
import { bookmarkStart, userDetailsStart } from "../../redux/user/Action";
import { useHistory, useLocation } from "react-router";
import { logout } from "../../redux/user/Action";
import Notification from "../Notification/Notification";
import debounce from "lodash.debounce";
import { useOutsideHook } from "../../hooks/useOutSideHook";
import AmlDialog from "../../containers/profile/amlDialog";
import NoticeBar from "../NoticeBar/NoticeBar";
import { notifyError } from "../../utils/utils";
import { NoticePopup } from "../NoticePopup/NoticePopup";
import { userApis } from "../../redux/api";
import axios from "axios";
import { getPublicKey, signTransaction } from "@stellar/freighter-api";


const Header = ({ ual }) => {
  const dispatch = useDispatch();
  const userdetails = useSelector((state) => state.user.data);
  const history = useHistory();
  const [expanded, setExpanded] = useState(false);
  const [notify, setNotify] = useState(false);
  const location = useLocation();


  const [showNotice,setShowNotice]=useState(false)

  const handleOkNotice=()=>{
    setShowNotice(false);
    localStorage.setItem('generalnoticeviewed',true)
  }
  const handleCancelNotice=()=>{
    localStorage.setItem('generalnoticeviewed',true)
    setShowNotice(false)
  }


  useEffect(() => {
    dispatch(userDetailsStart({}));
    dispatch(bookmarkStart({}));
  }, []);

  const handlePage = (name) => {
    setExpanded(false);
    history.push(`/${name}`);
  };



  useEffect(()=>{

if(userdetails &&  userdetails?._id && !userdetails?.is_anchor_enabled && !userdetails?.isOauth){
  let isanchorEnabled = userdetails?.is_anchor_enabled || userdetails?.isOauth ;
  const passwordCh=userdetails?.password_changed;
  if(passwordCh){
    let today = Date.now();
    let changeDate= new Date(userdetails?.change_passsword_after)
   
    if (changeDate < today) {
      if(!localStorage.getItem('generalnoticeviewed')){
        !isanchorEnabled && setShowNotice(true)
      }
      return;
    }
     
  }
  if(passwordCh===false){
    // changes
    if(!localStorage.getItem('generalnoticeviewed')){
      !isanchorEnabled && setShowNotice(true)
    }
  
    return;
  }
}

  },[userdetails])

  const FreighterSignTransaction = async () => {
    console.log("FREIGHTER SIGN TRANSACTION FUNCTION STARTED");

    const retrievePublicKey = async () => {
        try {
            return await getPublicKey();
        } catch (e) {
            console.error("Error retrieving public key:", e.message);
            return null;
        }
    };

    const publicKey = await retrievePublicKey();
    if (!publicKey) {
        console.error("Failed to retrieve public key. Exiting function.");
        return;
    }

    const payload = {
        data: publicKey
    };

    let responseSoroban;
    try {
        responseSoroban = await axios({
            method: "post",
            url: `${userApis.SOROBAN_CALL}`,
            data: { publicKey: payload.data },
        });
        console.log("===========RESPONSE AFTER SOROBAN CALL===========", responseSoroban.data);
    } catch (e) {
        console.error("Error in Soroban call:", e.message);
        return;
    }

    if (responseSoroban?.data?.success) {
        const userSignTransaction = async (xdr) => {
            try {
                console.log("XDR++++++++++++++++++", xdr);
                const signedTransaction = await signTransaction(xdr);
                return { signedTransaction };
            } catch (e) {
                console.error("Error signing transaction:", e);
                return { error: e };
            }
        };

        const userSignedTransaction = await userSignTransaction(responseSoroban.data.xdr);

        if (userSignedTransaction.error) {
            console.error("Error signing transaction:", userSignedTransaction.error);
        } else {
            console.log("Signed Transaction:", userSignedTransaction.signedTransaction);
        }
    } else {
        console.error("Soroban call was not successful.");
    }
};
  const handleSearch = (e) => {
    history.push(`/home/campaigns?name=${e.target.value}`);

    if (location.pathname === "/home/campaigns") {
      history.push(`/home/campaigns?name=${e.target.value}`);
    }
  };
  const debouncedChangeHandler = useCallback(debounce(handleSearch, 300), []);
  const handleClose = () => {
    setExpanded(false);
  };

  const wrapperRef = useRef(null);
  useOutsideHook(wrapperRef, handleClose, "navbar-toggle-id");
  const amlStatus =
    userdetails && userdetails.aml ? userdetails.aml.current_status : false;
  const investorStatus = userdetails?.kycInvestor;

  console.log("UAL", ual)
  console.log("LOCATION PATH NAME", location.pathname)

  return (
    <>
      <NoticeBar
        isVisible={userdetails?.private_key_exported === false}
        message={
          "Please export your private key before 28th of May. After this you will not be able to get your private key."
        }
      />
      <AmlDialog amlStatus={amlStatus} investorStatus={investorStatus} />
      <NoticePopup  show={showNotice} setShow={setShowNotice} message={'Please change your password as it is more than 6 months old'} handleClose={handleCancelNotice} handleOk={handleOkNotice}/>
      <Navbar
        bg="light"
        expand="lg"
        className="d-flex flex-column p-2 sticky-header "
        expanded={expanded}
      >
        <div className="d-flex w-100">
          <Navbar.Toggle
            id="navbar-toggle-id"
            aria-controls="basic-navbar-nav"
            onClick={() => setExpanded(expanded ? false : "expanded")}
          />
          <div className="logo-nav  mx-2">
            <img
              className="cur-pointer"
              tabIndex={0}
              onClick={() => history.push("/")}
              src={Zeptagram}
              alt="logo"
            />
          </div>

          <div className="search-nav pt-3">
            <img className="search-icon" src={Search} alt="" />
            <input
              type="text"
              placeholder="Search Live Campaigns "
              className="input-box search-bar box-shadow "
              title={"Live Crowdsale"}
              onChange={debouncedChangeHandler}
            />
          </div>
          <div>
             {/* <button
                className="button-medium w-100"
                style={{ borderRadius: "5px" }}
                onClick={FreighterSignTransaction}
              >
                FREI
              </button> */}
          </div>
          <div className="profile-nav d-flex profile-nav-before">
            <a
              className="mx-2 my-auto  box-shadow"
              style={{ borderRadius: "5px" }}
              target="_blank"
              href="https://nft.zeptagram.com/"
            >
              <button
                className="button-medium w-100"
                style={{ borderRadius: "5px" }}
              >
                NFT
              </button>
            </a>
            <div className="mx-2 align-center text-grey ">
              <i
                className="far fa-bell fa-2x cur-pointer"
                onClick={() => setNotify(true)}
              ></i>
            </div>
            {
              localStorage.getItem('token') ? 
              <div className="  cur-pointer">
              {/* onClick={()=>history.push('/profile')} */}
              <Dropdown>
                <Dropdown.Toggle
                  variant="success"
                  id="dropdown-basic"
                  className="align-center"
                >
                  <div className="profile-pic">
                    <img
                      className="profile-img"
                      src={
                        userdetails && userdetails.profileImgUri
                          ? userdetails.profileImgUri
                          : Zeptagram
                      }
                      alt=""
                    />
                  </div>
                  <div
                    className="text-dark mx-2 profile-name"
                    title={userdetails && userdetails.name}
                  >
                    {userdetails && userdetails.name}
                  </div>
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  <Dropdown.Item onClick={() => history.push("/profile")}>
                    Account Info
                  </Dropdown.Item>
                  {/* <Dropdown.Item href="#/action-2">Settings</Dropdown.Item> */}
                  <Dropdown.Item
                    onClick={() => {
                      // ual.activeUser && ual.logout();
                      dispatch(logout({ ual: ual }));
                    }}
                  >
                    Log out
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </div>:
            <div className='w-100 align-center'>

           <button
           className="button-medium cur-pointer"
           onClick={()=>history.push('/login')}
           style={{ borderRadius: "5px",height:'50px',minWidth:'70px' }}
         >
           Login
         </button>
         </div>
            }
            
            
            
          </div>
        </div>

        <Navbar.Collapse
          id="basic-navbar-nav"
          className="nav-small"
          style={{ zIndex: 100 }}
          ref={wrapperRef}
        >
          <div className="d-flex  header-apart">
            <Nav className="d-flex  mb-2 child-apart">
              <NavLink className="profile-nav d-flex profile-nav-after">
                <div className="mx-2 align-center text-grey">
                  <i className="far fa-bell fa-2x "></i>
                </div>
                <div className=" align-center">
                  <div className="profile-pic">
                    <img
                      src={
                        userdetails && userdetails.profileImgUri
                          ? userdetails.profileImgUri
                          : Zeptagram
                      }
                      className="profile-img"
                      alt=""
                    />
                  </div>
                  <div className="text-dark mx-2">
                    {userdetails && userdetails.name}
                  </div>
                </div>
              </NavLink>
              <div className="d-flex convert">
                <div className="search-nav-inside pt-3">
                  <img className="search-icon" src={Search} alt="" />
                  <input
                    type="text"
                    placeholder="Search Live Campaigns "
                    className="input-box search-bar box-shadow "
                    title={"Live Crowdsale"}
                    onChange={debouncedChangeHandler}
                  />
                </div>

                <div
                  className={`p-2 d-flex cur-pointer text-grey ${location.pathname === "/home" ? "active-nav" : ""
                    } width-content`}
                  onClick={() => handlePage("home")}
                >
                  <span className="align-top">
                    <img
                      className={` ${location.pathname === "/home" ? "filter-red" : ""
                        }`}
                      src={Home}
                    />
                  </span>
                  <div className="mx-2">Home</div>
                </div>

                <div>
                  
                </div>
                {/* {ual.activeUser === null ? (
                  <div
                    className={`p-2 d-flex cur-pointer text-grey ${location.pathname === "/wallet" ? "active-nav" : ""
                      } width-content`}
                    onClick={() => handlePage("wallet")}
                  >object)
                    <span className="align-top">
                      <img
                        className={` ${location.pathname === "/wallet" ? "filter-red" : ""
                          }`}
                        src={Wallet}
                      />
                    </span>
                    <div className=" mx-2">Wallet</div>
                  </div>
                ) : (
                  <div
                    className={`p-2 d-flex cur-pointer text-grey ${location.pathname === "/wallet" ? "active-nav" : ""
                      } width-content`}
                    onClick={() => handlePage("wallet")}
                  >
                    <span className="align-top">
                      <img
                        className={` ${location.pathname === "/wallet"
                            ? "filter-red"
                            : ""
                          }`}
                        src={Wallet}
                      />
                    </span>
                    <div className=" mx-2"> Wallet</div>
                  </div>
                )} */}
                <div
                  className={`p-2 d-flex cur-pointer text-grey ${location.pathname === "/wallet" ? "active-nav" : ""
                    } width-content`}
                  onClick={() => handlePage("wallet")}
                >
                  <span className="align-top">
                    <img
                      className={` ${location.pathname === "/wallet" ? "filter-red" : ""
                        }`}
                      src={Wallet}
                    />
                  </span>
                  <div className=" mx-2">Wallet</div>
                </div>





                <div
                  className={`p-2 d-flex cur-pointer text-grey ${location.pathname === "/trade" ? "active-nav" : ""
                    } width-content`}
                  onClick={() => handlePage("trade")}
                >
                  <span className="align-top">
                    <img
                      className={` ${location.pathname === "/trade" ? "filter-red" : ""
                        }`}
                      src={Vault}
                    />
                  </span>
                  <div className=" mx-2">Trading</div>
                </div>
                <div
                  className={`p-2 d-flex cur-pointer text-grey ${location.pathname === "/mycrowdsale" ? "active-nav" : ""
                    } width-content`}
                  onClick={() => handlePage("mycrowdsale")}
                >
                  <span className="align-top">
                    <img
                      className={` ${location.pathname === "/mycrowdsale" ? "filter-red" : ""
                        }`}
                      src={MyCrowdsale}
                    />
                  </span>
                  <div className=" mx-2">My Crowdsale</div>
                </div>

                <div
                  className={`p-2 d-flex cur-pointer text-grey ${location.pathname === "/security" ? "active-nav" : ""
                    } width-content`}
                  onClick={() => handlePage("security")}
                >
                  <span className="align-top">
                    <img
                      className={` ${location.pathname === "/security" ? "filter-red" : ""
                        }`}
                      src={Security}
                    />
                  </span>
                  <div className="mx-2">Security</div>
                </div>

                <div
                  className={`p-2 d-flex cur-pointer text-grey ${location.pathname === "/music" ? "active-nav" : ""
                    } width-content`}
                  onClick={() => handlePage("music")}
                >
                  <span className="align-top">
                    <img
                      className={` ${location.pathname === "/music" ? "filter-red" : ""
                        }`}
                      src={Music}
                    />
                  </span>
                  <div className=" mx-2">My Music</div>
                </div>
                <div
                  className={`p-2 d-flex cur-pointer text-grey ${location.pathname === "/portfolio" ? "active-nav" : ""
                    } width-content`}
                  onClick={() => handlePage("portfolio")}
                >
                  <span className="align-top">
                    <img
                      className={` ${location.pathname === "/portfolio" ? "filter-red" : ""
                        }`}
                      src={PortfolioIcon}
                    />
                  </span>
                  <div className=" mx-2">Portfolio</div>
                </div>

                <div
                  className={`p-2 d-flex cur-pointer text-grey ${location.pathname === "/guide" ? "active-nav" : ""
                    } width-content`}
                  onClick={() => handlePage("guide")}
                >
                  <span className="align-top mt-1">
                    <i
                      style={{ fontSize: "18px" }}
                      className={`fas fa-book ${location.pathname === "/guide" ? "filter-red" : ""
                        }`}
                    ></i>
                    {/* <img src={Rocket} /> */}
                  </span>

                  <div className="mx-2">Guide</div>
                </div>
              </div>

              <div
                className={`p-2 d-flex cur-pointer  text-grey  width-content ${location.pathname === "/support" ? "active-nav" : ""
                  }`}
                onClick={() => handlePage("support")}
              >
                <span className="align-top">
                  <img
                    className={` ${location.pathname === "/support" ? "filter-red" : ""
                      }`}
                    src={Rocket}
                  />
                </span>
                <div className=" mx-2">Support</div>
              </div>
                {
                  localStorage.getItem('token') ?   <div className="display-nav mb-2">
                  <div
                    className={`p-2 d-flex cur-pointer  text-grey  width-content  ${location.pathname === "/profile" ? "active-nav" : ""
                      }`}
                    onClick={() => handlePage("profile")}
                  >
                    <span className="align-top mt-1">
                      <i
                        className={`fas fa-users ${location.pathname === "/profile" ? "filter-red" : ""
                          }`}
                      ></i>
                      {/* <img src={Rocket} /> */}
                    </span>
                    <div className=" mx-2">Account Info</div>
                  </div>
  
                  <div
                    className={`p-2 d-flex cur-pointer  text-grey  width-content  `}
                    onClick={() => (setNotify(true), setExpanded(false))}
                  >
                    <span className="align-top mt-1">
                      <i  style={{fontSize:'18px'}} className="far fa-bell  "></i>
                    </span>
                    <div className=" mx-2">Notifications</div>
                  </div>
  
                
  
                  <div
                    className={`p-2 d-flex cur-pointer  text-grey  width-content `}
                    onClick={() => {
                      // ual.logout();
                      // dispatch(logout());
                      dispatch(logout({ ual: ual }));
                    }}
                  >
                    <span className="align-top mt-1">
                      <i  style={{fontSize:'18px'}} className="fas fa-sign-out-alt"></i>
                      {/* <img src={Rocket} /> */}
                    </span>
                    <div className=" mx-2">Log Out</div>
                  </div>
                  <div
                    className={`p-2 d-flex cur-pointer  text-grey  width-content  `}
                  >
                    <div className=" mx-2">
                      <a target="_blank" href="https://nft.zeptagram.com/">
                        NFT
                      </a>
                    </div>
                  </div>
                </div> :
                   <div
                   className={`p-2 d-flex cur-pointer  text-grey  width-content display-nav `}
                   onClick={() => {
                     history.push('/login')
                   }}
                 >
                   <span className="align-top mt-1">
                     <i  style={{fontSize:'18px'}} className="fas fa-sign-out-alt"></i>
                     
                   </span>
                   <div className=" mx-2">Log In</div>
                 </div>
                }
            
            </Nav>
            {notify && <Notification show={notify} setShow={setNotify} />}
          </div>
        </Navbar.Collapse>
      </Navbar>
    </>
  );
};

export default Header;
