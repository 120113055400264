import React from 'react'
import { Modal } from 'react-bootstrap';

export default function FiatPaymentLoaderModal({showReceiveModal,setReceiveModal}) {
  return (
    <Modal
    show={showReceiveModal}
    onHide={() => {
      setReceiveModal(false);
    }}
  >
    <Modal.Header closeButton>
      <Modal.Title className="text-dark">Receive Zeptacoin</Modal.Title>
    </Modal.Header>
    <Modal.Body>
      <div className="text-center">
       
        <h5 className="text-dark">
          Address
          <div className="key-text mt-3">{""}</div>
        </h5>
      </div>
    </Modal.Body>
  </Modal>
  )
}
