import React from "react";

export const Description = ({ message }) => {
  const [submitbtn, setSubmitBtn] = React.useState(false);
  const mesLength = message.length;

  return (
    <div>
      {mesLength < 100
        ? message
        : submitbtn
        ? message
        : `${message.slice(0, 80)}.....`}
      {mesLength > 100 && (
        <span
          className="text-dark mx-3 cur-pointer"
          onClick={() => setSubmitBtn(!submitbtn)}
        >
          {submitbtn ? "Less" : "More"}
        </span>
      )}
    </div>
  );
};
