import React from "react";


import Img16 from "../../../images/guide/anchor/9.png";
import Img17 from "../../../images/guide/anchor/10.png";
import Img18 from "../../../images/guide/anchor/11.png";
import Img19 from "../../../images/guide/anchor/12.png";
import Img20 from "../../../images/guide/anchor/13.png";
import Img21 from "../../../images/guide/anchor/14.png";
import Img22 from "../../../images/guide/anchor/15.png";

import ReactPlayer from "react-player/lazy";

import Im1 from "../../../images/guide/anchor/new/im1.png";
import Im2 from "../../../images/guide/anchor/new/im2.png";
import Im3 from "../../../images/guide/anchor/new/im3.png";
import Im4 from "../../../images/guide/anchor/new/im4.png";
import Im5 from "../../../images/guide/anchor/new/im5.png";
import Im6 from "../../../images/guide/anchor/new/im6.png";
import Im7 from "../../../images/guide/anchor/new/im7.png";
import Im8 from "../../../images/guide/anchor/new/im8.png";
import Im9 from "../../../images/guide/anchor/new/im9.png";
import Im10 from "../../../images/guide/anchor/new/im10.png";
import Im11 from "../../../images/guide/anchor/new/im11.png";
import Im12 from "../../../images/guide/anchor/new/im12.png";
import Im13 from "../../../images/guide/anchor/new/im13.png";
import Im14 from "../../../images/guide/anchor/new/im14.png";
import Im15 from "../../../images/guide/anchor/new/im15.png";
import Im16 from "../../../images/guide/anchor/new/im16.png";

 

export const AnchorGuide = () => {
  return (
    <div className="ml-3">
      <h3 className="text-dark">Anchor Wallet</h3>
      <p className="text-justify">

   
        <b>What is Anchor?</b> <br />
        Anchor is a security and privacy focused open-source digital wallet for
        all EOSIO-based networks.
        <br />
        <b> How can you install ?</b> <br />
        Download desktop & mobile app from here{" "}
        <a target="_blank" href="https://greymass.com/en/anchor/download">
          https://greymass.com/en/anchor/download
        </a>
        <br />
        <b> How can you create new wallet ?</b>
        <br />
        New user can create new wallet from here{" "}
        <a target="_blank" href="https://app.telos.net/accounts/add">
          https://app.telos.net/accounts/add
        </a>
      </p>

      <div className="text-center" >
      <div className="text-dark">Export Anchor keys from Zeptagram wallet</div>
      <div className="align-center"  >
      <ReactPlayer  
      controls url={'https://www.youtube.com/watch?v=GXfRAkollBw'}
          />
      </div>
      </div>

      <p>
        Export existing wallet from zeptagram Follow these steps to export your
        wallet :
        <ul>
       <li>Login to the platform with any of the given option e.g. Email/Password, Google or Apple </li>
       <img className="guide-img " src={Im1} />
       <li>System will redirect to the forget password screen to change your password if it has not been changed already. This option is only for email/password login users. Enter your registered email and submit the form. Apple/Google login users will be redirected to change pin screen after login.</li>
       <img className="guide-img " src={Im2} />
       <img className="guide-img " src={Im3} />
       <img className="guide-img " src={Im4} />
       <li>Email users can enter the password in change pin screen and change pin</li>
       <img className="guide-img " src={Im5} />
       <img className="guide-img " src={Im6} />
       <li>Apple/Google users will get a 6 digit OTP on your registered email. Please fill up the OTP and change the pin.Screen will be logged out after changing the pin</li>
       <img className="guide-img " src={Im7} />
       <img className="guide-img " src={Im8} />
       <img className="guide-img " src={Im9} />
       <li>Go to the Wallet page and  click on the Export Private Key button.</li>
       <img className="guide-img " src={Im10} />
       <li>Click on Send OTP, Enter the OTP received in email and Verify .</li>
       <img className="guide-img " src={Im11} />
       <img className="guide-img " src={Im12} />
       <li>Users will receive an email with a pdf containing account details including Private Keys.</li>
       <img className="guide-img " src={Im13} />


       <h5 className="text-dark">Import account in Anchor Wallet</h5>
       <div className="text-center" >
      <div className="text-dark">Import account in Anchor Wallet</div>
      <div className="align-center"  >
      <ReactPlayer  
      controls url={'https://www.youtube.com/watch?v=Uxnwp_cFloY'}
          />
      </div>
      </div>

       <li>Open Anchor Wallet and click on Manage Wallets</li>

   
       <img className="guide-img " src={Im14} />
       <li>Select Import Account(s)</li>
       <img className="guide-img " src={Im15} />
       <li>Enter the private key received in email  into ‘Import a Private Key’ </li>
       <img className="guide-img " src={Im16} />
       <li>The wallet gets imported and users can login using Anchor.</li>
       
        </ul>
      </p>
      <p>
        <h5 className="text-dark">How to use connect anchor with zeptagram?</h5>
      
        <div className="text-center" >
      <div className="text-dark">Login using Anchor Wallet</div>
      <div className="align-center"  >
      <ReactPlayer  
      controls url={'https://www.youtube.com/watch?v=aAI6WoBx-hY'}
          />
      </div>
      </div>
      
        <ul>
          <li>Go to app.zeptagram.com</li>
          <img className="guide-img " src={Img16} />
          <li>
            You will find multiple option to login on platform e.g. google,
            apple and anchor login.
          </li>

          <li>Click on Anchor(Blue) Icon</li>
          <li>Select option “Anchor”</li>
          <img className="guide-img " src={Img17} />
          <li>
            You have two options, either scan QR code using anchor mobile app OR
            Launch anchor desktop application.
          </li>
          <img className="guide-img " src={Img18} />
          <li>
            After click, It will send a open anchor app and send prompt to sign
            txn.
          </li>
          <img className="guide-img " src={Img19} />
          <li>
            After sign that transaction, it will create a secure connection with
            zeptagram platform and ask to setup your profile, if you profile is not setup.
          </li>
          <img className="guide-img " src={Img20} />
          <li>
            After submit profile details, it will ask to verify your email
          </li>
          <img className="guide-img " src={Img21} />
          <li>Enter OTP and Verify</li>
          <li>Your account is setup.</li>
          <li>
            Now again click on Anchor Icon on login page & sign the transaction
            on anchor wallet
          </li>
          <img className="guide-img " src={Img22} />
          <li>Now you login to platform.</li>
        </ul>
      </p>


  

    </div>
  );
};
