export const musicparams = {
  page: 1,
  limit: 10,
  pageSize: 5,
  sortBy: "",
  order: -1,
  contentType: "",
  longName: "",
  sortName: "",
  inCrowdsale: "",
  musicNumber: "",
  musicType: "",
  revenueGenerated: "",
  totalShare: "",
  request: "",
  verified: true,
  nextPage: false,
  selector:
    "contentType musicType longName sortName verification created_at royaltyStatement startDate endDate totalShare",
  selectorValue: 1,
};

export const crowdsaleparams = {
  page: 1,
  limit: 10,
  pageSize: 5,
  sortBy: "",
  order: -1,
  state: "",
  type: "",
  forWhiteListed: "",
  percentageRights: "",
  conversionVal: "zeptacoins",
  rate: "",
  name: "",
  minContribution: "",
  maxContribution: "",
  softCap: "",
  nextPage: false,
  populateMusic: true,
  selector:
    "state softCap minContribution maxContribution percentageRights rate zeptaCoinsRaised targetAmount startDate endDate musicId tokenName",
  selectorValue: 1,
};
