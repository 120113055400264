import React from 'react'
import './style.scss'

export const ToggleSwitch=({value,setValue})=>{
return(
 <label className="switch" >
  <input type="checkbox"  checked={value=='crypto'?true:false}  onClick={setValue}/>
  <span className="slider round"/>
</label>)
}

