import React, { useState } from "react";
import { Modal, Form } from "react-bootstrap";
import InfoToast from "../../components/ToastMessage/InfoToast";
import SwalSuccess from "../../components/ToastMessage/SwalSuccess";
import SwalError from "../../components/ToastMessage/SwalError";
import { getRequiredProtectedData } from "../../redux/apicalltemplate";
import { crowdsaleApis, userApis } from "../../redux/api";

const ChangeEmail = ({ show, setShow, id }) => {
  const [email, setEmail] = useState("");
  const [otp, setOtp] = useState("");
  const [isotp, setIsOtp] = useState(false);
  const handleClose = () => setShow(false);
  const [loading, setLoading] = useState(false);

  //handle form submit------------------------
  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    let data = {};
    data.email = email;
    if (isotp) {
      data.otp = otp;
    }

    const response = await getRequiredProtectedData(
      "PUT",
      `${!isotp ? userApis.CHANGE_EMAIL : userApis.CHANGE_EMAIL_VERIFY}`,
      data,
      {}
    );
    if (response && response.data && response.data.success) {
      if (!isotp) {
        SwalSuccess("Enter OTP and click confirm to change the Email");
        setIsOtp(true);
      } else {
        SwalSuccess(response.data.message);
        setTimeout(() => {
          setShow(false);
        }, 800);
      }
    }
    if (response && response.data && !response.data.success) {
      SwalError(response.data.message);
    }
    if (response && response.response && response.response.data) {
      SwalError(response.response.data.message);
    }
    setLoading(false);
  };

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Change Email</Modal.Title>
      </Modal.Header>
      <Form onSubmit={handleSubmit}>
        <Modal.Body className="p-5">
          <label className="text-dark" style={{ marginLeft: "10px" }}>
            New Email
          </label>
          <Form.Control
            className={"input-box-sm"}
            value={email}
            type="email"
            onChange={(e) => setEmail(e.target.value)}
            placeholder="Enter your new Email"
          />
          <div className="text-grey text-end" style={{ fontSize: "10px" }}>
            *Please Enter your new Email
          </div>

          {isotp ? (
            <>
              <label className="text-dark" style={{ marginLeft: "10px" }}>
                OTP
              </label>
              <Form.Control
                className={"input-box-sm"}
                value={otp}
                type="number"
                onChange={(e) => setOtp(e.target.value)}
                placeholder="Enter OTP sent to your new Email"
              />
            </>
          ) : (
            ""
          )}
        </Modal.Body>
        <Modal.Footer className="p-3">
          <button
            className="button-medium border-shadow medium-btn"
            type="submit"
            title={"Submit"}
          >
            {!loading ? (
              !isotp ? (
                "Submit"
              ) : (
                "Confirm"
              )
            ) : (
              <>
                <span
                  className="spinner-border spinner-border-smd"
                  role="status"
                  aria-hidden="true"
                ></span>
              </>
            )}
          </button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

export default ChangeEmail;
