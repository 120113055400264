import React, { useState } from "react";
import Zeptagram from "../../images/zeptagram.svg";
import Band from "../../images/band.svg";
import Girl from "../../images/lady.svg";
import Neon from "../../images/neonbrand.svg";
import { useDispatch, useSelector } from "react-redux";
import { resendOtpStart } from "../../redux/user/Action";
import { useHistory } from "react-router";
import GoogleCaptcha from "../../components/GoogleCaptcha/Captcha";
const ResendOtp = () => {
  const [email, setEmail] = useState("");
  const dispatch = useDispatch();
  const history = useHistory();
  const recaptchaRef = React.useRef();

  const loading = useSelector((state) => state.user.resendotploading);
  const handleSubmit = async (e) => {
    e.preventDefault();
    const captchatoken = await recaptchaRef.current.executeAsync();
    if (!captchatoken) {
      return;
    }
    const data = {
      email: email,
      captcha: captchatoken,
    };
    dispatch(resendOtpStart(data));
  };
  return (
    <div className="row m-auto h-100 w-100  ">
      <div className="col-md-6 ">
        <div>
          <img
            className="cur-pointer"
            onClick={() => history.push("/login")}
            src={Zeptagram}
            alt=""
          />
        </div>
        <div className="h-84 align-center">
          <form
            className="d-flex flex-column justify-content-center"
            onSubmit={handleSubmit}
          >
            <h4 className="text-dark">Send OTP for Verification</h4>
            <input
              type="text"
              className="input-box my-3"
              onChange={(e) => setEmail(e.target.value)}
              placeholder="Email"
              required
            />
            <button
              className="button-medium border-shadow mt-3  medium-btn"
              type="submit"
            >
              {!loading ? (
                "Submit"
              ) : (
                <>
                  <span
                    className="spinner-border spinner-border-smd"
                    role="status"
                    aria-hidden="true"
                  ></span>
                </>
              )}
            </button>
            <div className="text-grey mt-3">
              Click on Submit and we will send you a otp to your registered
              email!
            </div>
          </form>
        </div>
        <GoogleCaptcha recaptchaRef={recaptchaRef} />
      </div>
      <div className="col-md-6 image-container">
        <span className="verify-image verify-image-1">
          <img src={Neon} alt="" />
        </span>
        <span className="verify-image verify-image-2">
          <img src={Band} alt="" />
        </span>
        <div className="verify-image verify-image-3">
          <img height={380} className="girl-img" src={Girl} alt="" />
        </div>
      </div>
    </div>
  );
};

export default ResendOtp;
