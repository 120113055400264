import { Col, Form, Image, Modal, Row, Spinner } from "react-bootstrap";
import { HeightBox, SubmitButton } from "../../components/components";
import React, { useEffect, useState } from "react";
import { PencilFill, PersonFill } from "react-bootstrap-icons";
import {
  useDelete,
  useFetch,
  useSubmit,
  useUpload,
} from "../../api/api_client";
import { userApis } from "../../redux/api";
import { LoadingView } from "../../components/loading_view";
import { notifyError, notifySuccess } from "../../utils/utils";
import ReactPlayer from "react-player/lazy";
import NodataFound from "../../icons/cancel.svg";
import { useHistory } from "react-router-dom";
import SwalError from "../../components/ToastMessage/SwalError";

export default function MusicDetailsModel({
  show,
  setShow,
  id,
  onEdit,
  refresh,
  setRefresh,
  showNFTsModal,
  ual,
}) {
  let avatarUploadRef;
  const history = useHistory();
  const [file, setFile] = useState();
  const [avatarData, setAvatarData] = useState();
  const [deleting, setDeleting] = useState(false);
  const [sendingRequest, setRequestSending] = useState(false);
  const [uploading, setUploading] = useState(false);

  const [isPlayerVisible, setIsPlayerVisible] = useState(true);
  const [isSupportVisible, setIsSupportVisible] = useState(false);
  const handleError = (e) => {
    setIsPlayerVisible(false);
    setIsSupportVisible(true);
  };

  const deleteSong = () => {
    setDeleting(true);
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useDelete(`${userApis.MUSIC_DETAIL_ID}${id}`)
      .then((r) => {
        r.data && notifySuccess(r.data.message);
        setDeleting(false);
        setShow(true);

        //setShow(false);
        setRefresh(refresh + 1);
      })
      .catch((error) => {
        setDeleting(false);
        if (error && error.response && error.response.data) {
          notifyError(error.response.data.message);
        }
      });
  };

  const uploadPhoto = (art) => {
    let data = {};
    data.image = file;
    data.field = "bitMapImage";
    data.musicId = id;

    const formData = new FormData();
    formData.append("bitMapImage", art);
    setUploading(true);
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useUpload(`${userApis.UPLOAD_ARTWORK}${id}`, formData)
      .then((r) => {
        notifySuccess("Uploaded");
        setUploading(false);
        setShow(false);
        setRefresh(refresh + 1);
      })
      .catch((error) => {
        setUploading(false);
        if (error && error.response && error.response.data) {
          notifyError(error.response.data.message);
        }
      });
  };

  const sendForVerification = () => {
    setRequestSending(true);
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useSubmit(
      `${userApis.VERIFY_MUSIC}${id}`,
      {
        musicId: id,
      },
      "PUT"
    )
      .then((r) => {
        notifySuccess("Sent");
        setRequestSending(false);
        setShow(true);
        setRefresh(refresh + 1);
      })
      .catch((e) => {
        setRequestSending(false);
        notifySuccess("Couldn't make request");
      });
  };

  const {
    data = undefined,
    loading = false,
    error,
  } = useFetch(`${id ? userApis.MUSIC_DETAIL_ID + id : ""}`, [id]);
  const handleEdit = (data) => {
    setShow();
    setTimeout(() => {
      onEdit(data);
    }, 300);
  };
  const viewNFTs = () => {
    setShow();
    showNFTsModal(true);
  };
  return (
    <Modal
      show={show}
      size="lg"
      onHide={() => {
        setShow();
      }}
    >
      <Modal.Header closeButton>
        <Modal.Title className="text-dark">SONG DETAILS</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {loading ? (
          <LoadingView />
        ) : data !== undefined ? (
          <div>
            <div className="text-center p-2">
              <div className={"avatar_container "}>
                <input
                  ref={(ref) => (avatarUploadRef = ref)}
                  type="file"
                  accept="image/jpeg,image/jpg, image/png"
                  hidden={true}
                  id={"input"}
                  multiple="false"
                  onChange={(event) => {
                    const art = event.target.files[0];
                    if (art.size > 524288) {
                      notifyError("Please upload a file smaller than 512 KB");
                      return;
                    }

                    const reader = new FileReader();
                    reader.readAsDataURL(art);
                    reader.onloadend = function (ev) {
                      setAvatarData(reader.result);
                      setFile(art);
                      uploadPhoto(art);
                    };
                  }}
                />

                {/*<PersonFill*/}
                {/*    //hidden={pageState["avatarData"].avatarData !== undefined || profileImgUri != null}*/}
                {/*            size={"36px"} className={"avatar_icon"}/>*/}

                <Image
                  //   hidden={pageState["avatarData"].avatarData === undefined && profileImgUri == null}

                  src={avatarData ?? data["data"].bitMapImage}
                  width={"160px"}
                  height={"160px"}
                  roundedCircle
                  id={"avatar-img"}
                />

                <Spinner
                  hidden={!uploading}
                  className={"uploadSpinner"}
                  animation="border"
                />

                <span
                  className={"avatar_update_icon"}
                  onClick={() => {
                    avatarUploadRef.click();
                  }}
                >
                  <PencilFill />
                </span>
              </div>
            </div>
            <div>
              {isPlayerVisible ? (
                data &&
                data["data"] &&
                data.data.streamingAppLink && (
                  <div className="align-center m-2 mb-4">
                    <ReactPlayer
                      url={data.data.streamingAppLink}
                      onError={handleError}
                    />
                  </div>
                )
              ) : isSupportVisible ? (
                <div className="d-flex flex-column align-center m-2">
                  <img
                    style={{ maxWidth: "250px", height: "250px" }}
                    src={NodataFound}
                    alt="video"
                  />
                  <h6 className="mt-3">
                    Unsupported Format.{" "}
                    <a
                      className="text-dark "
                      href={data.data.streamingAppLink}
                      target="_blank"
                    >
                      <i
                        className="far fa-play-circle"
                        style={{ cursor: "pointer", fontSize: "20px" }}
                      ></i>
                      <span className="text-dark mx-1"> Listen/Watch </span>
                    </a>{" "}
                    here.
                  </h6>
                </div>
              ) : null}

              <Row className="m-2">
                <Col sm={6}>
                  <p className={"music-detail-p"}>
                    <span className="text-dark">Content Type: </span>
                    {data.data["contentType"]}
                  </p>
                  {data.data["musicType"] ? (
                    <p className={"music-detail-p"}>
                      <span className="text-dark">Listing Type: </span>
                      {data.data["musicType"] === "existing"
                        ? "Crowdsale"
                        : "Crowdfunding"}
                    </p>
                  ) : null}
                  <p className={"music-detail-p"}>
                    <span className="text-dark">Long Name: </span>
                    <div className="wrap-word " title={data.data["longName"]}>
                      {data.data["longName"]}
                    </div>
                  </p>
                  <p className={"music-detail-p"}>
                    <span className="text-dark">Short Name: </span>
                    {data.data["sortName"]}
                  </p>
                  {data.data["coOwners"] ? (
                    <p className={"music-detail-p "}>
                      {" "}
                      <div className="text-dark">Co-owners:</div>
                      <div className="wrap-word">
                        <ul className="coowner-list">
                          {data.data.coOwners &&
                            data.data.coOwners.split(",").map((el) => {
                              return <li title={el}>{el}</li>;
                            })}
                        </ul>

                        {/* {data.data.coOwners.charAt(0).toUpperCase() +
                          data.data.coOwners.slice(1)} */}
                      </div>
                    </p>
                  ) : null}

                  {data.data["writers"] ? (
                    <p className={"music-detail-p"}>
                      {" "}
                      <div className="text-dark"> Composers: </div>
                      <div className="wrap-word">
                        <ul className="coowner-list">
                          {data.data.writers &&
                            data.data.writers.split(",").map((el) => {
                              return <li title={el}>{el}</li>;
                            })}
                        </ul>
                      </div>
                    </p>
                  ) : null}

                  {data.data["shareHolderName"] ? (
                    <p className={"music-detail-p"}>
                      {" "}
                      <span className="text-dark"> Share-holder Name:</span>
                      {data.data.shareHolderName.charAt(0).toUpperCase() +
                        data.data.shareHolderName.slice(1)}
                    </p>
                  ) : null}

                  {data.data["totalShare"] ? (
                    <p className={"music-detail-p"}>
                      {" "}
                      <span className="text-dark"> Your Ownership: </span>
                      {data.data.totalShare}%
                    </p>
                  ) : null}

                  {data.data["artists"] && data.data["artists"].length > 0
                    ? data.data["artists"].map((item) => {
                        return (
                          <>
                            <p className={"music-detail-p"}>
                              <div className="text-dark"> Artist Name :</div>
                              <div className="wrap-word">
                                {" "}
                                {item.artistName}
                              </div>
                            </p>
                            <p className={"music-detail-p"}>
                              <span className="text-dark">
                                {" "}
                                <div className="wrap-word">
                                  {" "}
                                  {item.artistName} :{" "}
                                </div>
                              </span>{" "}
                              {item.artistType}
                            </p>
                          </>
                        );
                      })
                    : null}

                  {data.data["contentDetails"] ? (
                    <p className={"music-detail-p"}>
                      {" "}
                      <span className="text-dark"> Total Duration: </span>
                      {data.data["contentDetails"].duration} min
                    </p>
                  ) : null}

                  {data.data["genres"] && data.data["genres"].length > 0
                    ? data.data["genres"].map((item) => {
                        return (
                          <>
                            <p className={"music-detail-p"}>
                              <div className="text-dark"> Genre : </div>
                              <div className="wrap-word">{item}</div>
                            </p>
                          </>
                        );
                      })
                    : null}
                </Col>
                <Col sm={6}>
                  {data.data["coOwnersInAlbum"] &&
                  data.data["coOwnersInAlbum"].length > 0
                    ? data.data["coOwnersInAlbum"].map((item) => {
                        return (
                          <>
                            <p className={"music-detail-p"}>
                              <div className="text-dark"> Owner :</div>
                              <div className="wrap-word">
                                {item.coOwnerName}
                              </div>
                            </p>
                            <p className={"music-detail-p"}>
                              <div className="text-dark wrap-word">
                                {" "}
                                {item.coOwnerName} Ownership :{" "}
                              </div>
                              <div className="wrap-word">
                                {item.coOwnerPercentageRights}%
                              </div>
                            </p>
                          </>
                        );
                      })
                    : null}

                  {data.data["contributors"] &&
                  data.data["contributors"].length > 0
                    ? data.data["contributors"].map((item) => {
                        return (
                          <>
                            <p className={"music-detail-p"}>
                              <div className="text-dark">
                                Contributors Name :{" "}
                              </div>
                              <div className="wrap-word">
                                {item.contributorName}
                              </div>
                            </p>
                            <p className={"music-detail-p"}>
                              <div className="text-dark wrap-word">
                                {" "}
                                {item.contributorName} :{" "}
                              </div>
                              <div className="wrap-word">
                                {item.contributorType}
                              </div>
                            </p>
                          </>
                        );
                      })
                    : null}

                  {/*renderCoOwnersInAlbum*/}
                  {/*renderContributors*/}

                  {data.data["verification"] ? (
                    <p className={"music-detail-p"}>
                      <span className="text-dark"> Verification Request: </span>{" "}
                      {data.data["verification"].request}
                    </p>
                  ) : null}

                  {data.data["isVerified"] ? (
                    <p className={"music-detail-p"}>
                      {" "}
                      <span className="text-dark">
                        {" "}
                        Verification Status:
                      </span>{" "}
                      {data.data["isVerified"] ? "Verified" : "Not Verified"}
                    </p>
                  ) : null}

                  <p className={"music-detail-p "}>
                    <div className="text-dark">
                      {data.data["contentType"] === "album"
                        ? "ISRC Code:"
                        : "ISWC Code:"}{" "}
                    </div>

                    <div
                      className="wrap-word "
                      title={data.data["musicNumber"]}
                    >
                      {data.data["musicNumber"]}
                    </div>
                  </p>

                  {data.data["revenueGenerated"] ? (
                    <p className={"music-detail-p"}>
                      {" "}
                      <span className="text-dark"> Revenue Generated: </span>$
                      {data.data["revenueGenerated"]}
                    </p>
                  ) : null}

                  {data.data["crowdsale"] ? (
                    <p className={"music-detail-p"}>
                      {" "}
                      <span className="text-dark">
                        {" "}
                        In{" "}
                        {data.data.musicType == "new"
                          ? "Crowdfunding"
                          : "Crowdsale"}
                        :{" "}
                      </span>
                      {data.data["crowdsale"].inCrowdsale ? "Yes" : "No"}
                    </p>
                  ) : null}

                  <p className={"music-detail-p"}>
                    {" "}
                    <span className="text-dark"> Created At:</span>{" "}
                    {new Date(data.data.created_at).toDateString()}
                  </p>

                  {data.data.royaltyStatement !== undefined &&
                  data.data.royaltyStatement.isGiven !== undefined &&
                  data.data.royaltyStatement.isGiven &&
                  data.data.musicType === "existing" ? (
                    <a
                      href={data.data.royaltyStatement.url}
                      target="_blank"
                      style={{ color: "#daca9e" }}
                    >
                      <p className={"music-detail-p"}>Royalty Sheet URL</p>
                    </a>
                  ) : null}

                  {data && data.data && data.data.streamingAppLink ? (
                    <a
                      href={data.data.streamingAppLink}
                      target="_blank"
                      style={{ color: "#daca9e" }}
                    >
                      <p className={"music-detail-p"}>LISTEN</p>
                    </a>
                  ) : null}
                </Col>
              </Row>
              <Row className="mx-2">
                <div className={""}>
                  {" "}
                  <h5 className="text-dark"> Description</h5>{" "}
                  <div className="wrap-word text-justify">
                    {data.data.description}
                  </div>
                </div>
              </Row>
            </div>
            {data &&
              data.data &&
              data.data.verification &&
              data.data.verification.request == "rejected" &&
              data.data.verification.rejectReason && (
                <Row className="mx-2 my-2">
                  <div>
                    <h5
                      className="text-dark"
                      style={{ color: "rgb(255, 97, 97)" }}
                    >
                      Rejected Reason{" "}
                    </h5>{" "}
                    <div className="wrap-word text-justify">
                      {data.data.verification &&
                        data.data.verification.rejectReason}
                    </div>
                  </div>
                </Row>
              )}
          </div>
        ) : (
          <h2>ERROR {"Opps! Something went wrong"}</h2>
        )}
      </Modal.Body>
      <Modal.Footer>
        {data !== undefined &&
        data.data["verification"] !== undefined &&
        data.data["verification"].request === "notSend" ? (
          <SubmitButton
            loading={sendingRequest}
            onClick={sendForVerification}
            size={"long"}
            title={"SEND VERIFICATION"}
          />
        ) : null}

        {data !== undefined &&
        data.data["verification"] !== undefined &&
        data.data["verification"].request === "rejected" ? (
          <SubmitButton
            loading={sendingRequest}
            onClick={sendForVerification}
            size={"long"}
            title={"RESEND VERIFICATION"}
          />
        ) : null}

        {data !== undefined &&
        data.data["verification"] !== undefined &&
        data.data["verification"].isVerified !== true ? (
          <SubmitButton
            size={"long"}
            title={"EDIT"}
            onClick={() => {
              handleEdit(data.data);
            }}
          />
        ) : null}
        {/* {data !== undefined &&
        data.data["verification"] !== undefined &&
        data.data["verification"].isVerified !== true ? (
          <SubmitButton
            loading={deleting}
            onClick={deleteSong}
            size={"long"}
            title={"DELETE"}
          />
        ) : null} */}

        <SubmitButton
          loading={deleting}
          onClick={deleteSong}
          size={"long"}
          title={"DELETE"}
        />
        {data && !data.data.verification.isVerified && <SubmitButton
          loading={false}
          onClick={() => {
            if (ual.activeUser) {
              data.data.nft_created ? viewNFTs() : history.push(`music-nfts/${data.data["sortName"]}`)
            } else {
              SwalError('Please login with Anchor to create music NFTs')
            }
          }}
          size={"long"}
          title={data.data.nft_created ? "View NFT" : "Create NFT"}
        />}
      </Modal.Footer>
    </Modal>
  );
}
