import React, { useEffect, useState } from "react";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import Zeptagram from "../../images/zeptagram.svg";
import { loginStart, logout } from "../../redux/user/Action";
import { useHistory } from "react-router-dom";
import VisibleEye from "../../components/VisibleEye/VisibleEye";
import Band from "../../images/band.svg";
import Girl from "../../images/lady.svg";
import Neon from "../../images/neonbrand.svg";
import GoogleAuth from "./GoogleLogin";
import AppleLogin from "./AppleLogin";
import GoogleCaptcha from "../../components/GoogleCaptcha/Captcha";
import AnchorLogin from "../AnchorWallet/AnchorLogin";
import LobstrLogin from "./LobstrLogin.js";
import AppServices from "../../services/AppService";
import AnchorIcon from "../../icons/anchor.png";
import LobstrIcon from "../../icons/lobstr.png";
import FreighterIcon from "../../icons/freighter.jpg";
import UserIcon from "../../icons/user.jpg";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import { getRequiredProtectedData } from "../../redux/apicalltemplate";
import { stellarApis } from "../../redux/api";
import { CreateAccount } from "./CreateAccount";
import LoadWallet from "../Swap/LoadWallet";
import WalletConnectService from "../Swap/walletconnect";
import WalletConnectLobstr from "../Swap/walletConnectLobstr";

import { freighterLoginStart } from "../../redux/user/Saga.js";
import FreighterLogin from "./FreighterLogin.js";

// if (await isConnected()) {
//   alert("User has Freighter!");
// }


const appService = new AppServices();
function Login({ ual }) {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [remember, setRemember] = useState(false);
  const [visible, setVisibility] = useState(false);
  const dispatch = useDispatch();
  const loading = useSelector((state) => state.user.loginloading);
  const history = useHistory();
  const recaptchaRef = React.useRef();
  const [currentTab, setCurrTab] = useState("user");
  const [show, setShow] = useState(false);
  const [walletLoading, setWalletLoading] = useState(false);
  const [walletData, setWalletData] = useState(null);
  const [showLoadWallet, setShowLoadWallet] = useState(false);
  const [encryptedKey, setEncryptedKey] = useState(false);
  const [zptcBalance, setZptcBalance] = useState("");
  const walletConnect = new WalletConnectLobstr();

  const { anchorLoginLoading, lobstrLoginLoading } = useSelector(
    (state) => state.user,
    shallowEqual
  );

  useEffect(()=>{
    localStorage.removeItem('payload')
  },[])

  async function handleSubmit(event) {
    event.preventDefault();
    if (ual?.activeUser !== null) {
      await appService.logout(ual);
    }
    const captchatoken = await recaptchaRef.current.executeAsync();

    //logic to send token to backend for validation
    const payload = {
      type: null,
      data: {
        email: email,
        password: password,
        rememberMe: remember,
        captcha: captchatoken,
      },
    };
    if (captchatoken) {
      dispatch(loginStart(payload));
    }
  }

  const generateWallet = async () => {
    setWalletLoading(true);
    // const captchatoken = await recaptchaRef.current.executeAsync();
    const params = { type: "mobile" };
    const response = await getRequiredProtectedData(
      "GET",
      `${stellarApis.CREATE_WALLET}`,
      {},
      params
    );
    if (response && response.data) {
      setShow(true);
      setWalletData(response.data);
      setCurrTab("anc");
    }
    setWalletLoading(false);
  };
  function ForEncryptedKey() {
    setEncryptedKey(!encryptedKey);
  }

  return (
    <div className="row m-auto w-100 h-100 bg-white">
      <div className="col-md-6  ">
        <div>
          <img
            className="cur-pointer"
            onClick={() => history.push("/")}
            src={Zeptagram}
            alt=""
          />
        </div>
        <div className="align-center h-84">
          <div className="d-flex flex-column justify-content-center">
            <form
              className="d-flex flex-column"
              onSubmit={handleSubmit}
              autoComplete="nope"
            >
              <div className="d-flex flex-column border-shadow login-box">
                <span className="heading-medium mb-2 align-self-baseline">
                  Login
                </span>

                <div className="icon-container my-2">
                  <div
                    style={{ position: "relative" }}
                    className="mx-2"
                    onClick={() => setCurrTab("user")}
                  >
                    <img
                      src={UserIcon}
                      style={{ width: "38px" }}
                      className={`${
                        currentTab == "user" ? "active-tab" : ""
                      } cur-pointer border-circle`}
                    />
                    {currentTab == "user" && (
                      <div className="active-line"></div>
                    )}
                   
                  </div>

                  <GoogleAuth
                    ual={ual}
                    mainLoading={
                      loading || anchorLoginLoading || lobstrLoginLoading
                    }
                  />

                  <AppleLogin
                    ual={ual}
                    mainLoading={
                      loading || anchorLoginLoading || lobstrLoginLoading
                    }
                  />
                  <div style={{ position: "relative" }}>
                    <img
                      onClick={() => setCurrTab("anc")}
                      className={`icon-img ${
                        currentTab == "anc" || currentTab == "create"
                          ? "active-tab"
                          : ""
                      } cur-pointer  border-circle`}
                      src={AnchorIcon}
                      alt="anchor login"
                    />
                    {(currentTab == "anc" || currentTab == "create") && (
                      <div className="active-line"></div>
                    )}
                  </div>

                  
                  {/* --------------LOBSTR WALLET START------------ */}
                  <div style={{ position: "relative" }}>
                    <img
                      onClick={() => setCurrTab("lob")}
                      className={`icon-img ${
                        currentTab == "lob" || currentTab == "create"
                          ? "active-tab"
                          : ""
                      } cur-pointer  border-circle`}
                      src={LobstrIcon}
                      alt="Lobstr login"
                    />
                    {(currentTab == "lob" || currentTab == "create") && (
                      <div className="active-line"></div>
                    )}
                  </div>
                  {/* ----------------------------------------- */}

                  {/*----------------------FREIGHTER WALLET---------------------- */}
                   <div style={{ position: "relative" }}>
                    <img
                      onClick={() => setCurrTab("frei")}
                      className={`icon-img ${
                        currentTab == "frei" || currentTab == "create"
                          ? "active-tab"
                          : ""
                      } cur-pointer  border-circle`}
                      src={FreighterIcon}
                      alt="Freighter login"
                    />
                    {(currentTab == "frei" || currentTab == "create") && (
                      <div className="active-line"></div>
                    )}
                  </div>

                </div>

                {currentTab == "user" && (
                  <div>
                    <input
                      type="email"
                      value={email}
                      className="input-box my-3"
                      onChange={(e) => setEmail(e.target.value)}
                      placeholder="Email"
                      required
                      tabindex="0"
                      autoComplete="nope"
                    />
                    <div className="eye-cont">
                      <input
                        type={`${!visible ? "password" : "text"}`}
                        value={password}
                        className="input-box my-3"
                        onChange={(e) => setPassword(e.target.value)}
                        placeholder="Password"
                        required
                        tabindex="0"
                        autoComplete="nope"
                      />
                      <VisibleEye
                        visible={visible}
                        setVisibility={setVisibility}
                      />
                    </div>

                    <div>
                      <input
                        name="rem-password"
                        type="checkbox"
                        role={"button"}
                        tabindex="0"
                        className="align-middle"
                        onChange={(e) => setRemember(e.target.checked)}
                      />
                      <label
                        htmlFor="rem-password"
                        className="mx-2 text-grey align-middle"
                      >
                        Remember password
                      </label>
                    </div>

                    <button
                      className="button-medium border-shadow  m-auto my-3"
                      tabindex="0"
                      type="submit"
                      disabled={
                        loading || anchorLoginLoading || lobstrLoginLoading
                      }
                    >
                      {!loading ? (
                        "Sign In"
                      ) : (
                        <>
                          <span
                            className="spinner-border spinner-border-smd"
                            role="status"
                            aria-hidden="true"
                          ></span>
                        </>
                      )}
                    </button>
                    <div
                      className="text-grey f-14   text-center"
                      style={{ marginTop: "20px" }}
                    >
                      Don't have an account ?{" "}
                      <span
                        className="text-dark cur-pointer btn-def"
                        onClick={() => {
                          setCurrTab("anc");
                        }}
                      >
                        Click Here
                      </span>
                    </div>
                  </div>
                )}

                {/* LoadWallet component */}
                {currentTab === "lob" && (
                  <div style={{ margin: "20px" }}>
                    <div className="align-center">
                      <button
                        type="button"
                        className="button-medium border-shadow "
                        style={{ width: "180px", marginTop: "40px" }}
                      >
                        <LobstrLogin
                          ual={ual}
                          recaptchaRef={recaptchaRef}
                          ForEncryptedKey={ForEncryptedKey}
                          setZptcBalance={setZptcBalance}
                          walletConnect={walletConnect}
                          showModal={true} // Pass showModal prop to show the modal
                          onClose={() => setShowLoadWallet(false)} // Add a close handler
                        />
                      </button>
                    </div>
                    <div
                      className="d-flex flex-column justify-content-between " style={{ marginTop: "30px" }}
                    >
                      <p>Step 1: Click on Connect Wallet and a QR will be generated. <br/></p>
                      <p>Step 2: Scan the QR using Lobstr App.<br/></p>
                      <p>Step 3: Allow the connection request from the Lobstr App.{" "}</p>
                    </div>
                  </div>
                )}

                                {/* LoadWallet component */}
                {currentTab === "frei" && (
                  <div style={{ margin: "20px" }}>
                    <div className="align-center" >
                          <button
                        type="button"
                        className="button-medium border-shadow "
                        style={{ width: "180px", marginTop: "40px" }}
                      >
                        <FreighterLogin
                        />
                        {/* <LobstrLogin
                          ual={ual}
                          recaptchaRef={recaptchaRef}
                          ForEncryptedKey={ForEncryptedKey}
                          setZptcBalance={setZptcBalance}
                          walletConnect={walletConnect}
                          showModal={true} // Pass showModal prop to show the modal
                          onClose={() => setShowLoadWallet(false)} // Add a close handler
                        /> */}
                      </button>
                    </div>
                    <div
                      className="d-flex flex-column justify-content-between " style={{ marginTop: "30px" }}
                    >
                      <p>Step 1: Freighter wallet. <br/></p>
                     
                    </div>
                  </div>
                )}


                {currentTab == "anc" && (
                  <div style={{ margin: "20px" }}>
                    <div className="align-center">
                      <button
                        type="button"
                        className="button-medium border-shadow "
                        style={{ width: "180px", marginTop: "40px" }}
                      >
                        <AnchorLogin ual={ual} recaptchaRef={recaptchaRef} />
                      </button>
                    </div>
                    <div
                      className="text-grey f-14   text-center"
                      style={{ marginTop: "30px" }}
                    >
                      Having trouble with anchor login ?{" "}
                      <a
                        className="text-dark cur-pointer btn-def"
                        target="_blank"
                        href={
                          "https://zeptagram-test2.s3.eu-north-1.amazonaws.com/28-3-Instructions+to+login+with+Anchor.pdf"
                        }
                      >
                        Click Here
                      </a>
                    </div>
                    <div
                      className="align-center"
                      style={{ marginTop: "10px", flexDirection: "column" }}
                    >
                      <div className="text-dark mx-1 mt-3">
                        Don't have Anchor wallet?
                      </div>
                      <button
                        type="button"
                        className="button-medium my-2"
                        style={{
                          width: "230px",
                          background: "#3650a2",
                          color: "white",
                        }}
                        onClick={generateWallet}
                        disabled={walletLoading}
                      >
                        {walletLoading ? (
                          <span
                            className="spinner-border spinner-border-smd"
                            role="status"
                            aria-hidden="true"
                          ></span>
                        ) : (
                          "Create new account"
                        )}
                      </button>
                      <div
                        className="text-grey f-14   text-center"
                        style={{ marginTop: "10px" }}
                      >
                        Don't know, How to import new account in Anchor ?{" "}
                        <a
                          className="text-dark cur-pointer btn-def"
                          target="_blank"
                          href={
                            "https://zeptagram-test2.s3.eu-north-1.amazonaws.com/28-3-Instructions+to+import+account+in+Anchor.pdf"
                          }
                        >
                          Click Here
                        </a>
                      </div>
                    </div>
                  </div>
                )}

                {show && (
                  <CreateAccount
                    show={show}
                    setShow={setShow}
                    walletData={walletData}
                  />
                )}
              </div>

              <button
                className="text-dark font-weight-bold m-auto mt-3 btn-def anchor-text"
                tabindex="0"
                role={"button"}
                onClick={() => history.push("/forgetpassword")}
              >
                Forgot password ?
              </button>
              <button
                className="text-dark cur-pointer font-weight-bold m-auto mt-2 btn-def anchor-text"
                tabindex="0"
                role={"button"}
                onClick={() => history.push("/reset2fa")}
              >
                Reset 2FA
              </button>
            </form>
            <GoogleCaptcha recaptchaRef={recaptchaRef} />
          </div>
          {/*  Google Login */}
        </div>
      </div>
      <div className="col-md-6 image-container">
        <span className="verify-image verify-image-1">
          <img src={Neon} alt="" />
        </span>
        <span className="verify-image verify-image-2">
          <img src={Band} alt="" />
        </span>
        <div className="verify-image verify-image-3">
          <img height={380} className="girl-img" src={Girl} alt="" />
        </div>
      </div>
      {/* <div className='col-md-6 main-image '> */}
      {/* <img src={Lady} className='img-cover' alt='zeptagram'/> */}
      {/* </div> */}
    </div>
  );
}

export default Login;
