import React, { useEffect } from "react";
import { Col, Nav, Row, Tab } from "react-bootstrap";
import { AnchorGuide } from "./guides/AnchorGuide";
import { CrowdsaleGuide } from "./guides/CrowdsaleGuide";
import { Home } from "./guides/Home";
import { InvestGuide } from "./guides/InvestGuide";
import { KYCGuide } from "./guides/KycGuide";
import { MusicGuide } from "./guides/MusicGuide";
import { NFTGuide } from "./guides/NFTGuide";
import { PopularOffering } from "./guides/PopularOffering";
import { TradingGuide } from "./guides/TradeGuide";
import { WalletGuide } from "./guides/WalletGuide";
import { SwapGuide } from "./guides/SwapGuide";

const Guide = () => {
  return (
    <div className="wrapper h-100vh">
      <div className="container">
        <div className="guide-body">
          <Tab.Container id="left-tabs-example" defaultActiveKey="first">
            <Row>
              <Col sm={2}>
                <Nav variant="pills" className="guide flex-column">
                  <Nav.Item>
                    <Nav.Link eventKey="first">Overview</Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="second">Invest</Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="fourth">Music</Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="third"> Campaign</Nav.Link>
                  </Nav.Item>

                  <Nav.Item>
                    <Nav.Link eventKey="fifth">Trade</Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="sixth">KYC</Nav.Link>
                  </Nav.Item>
                  {/* <Nav.Item>
                    <Nav.Link eventKey="seven">Wallet</Nav.Link>
                  </Nav.Item> */}
                  <Nav.Item>
                    <Nav.Link eventKey="eight">Popular Offering</Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="nine">Anchor Wallet</Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="ten">NFT</Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="eleven">Swap</Nav.Link>
                  </Nav.Item>
                </Nav>
              </Col>
              <Col sm={10}>
                <Tab.Content>
                  <Tab.Pane eventKey="first">
                    <h1 className="text-dark text-center">Zeptagram Guide</h1>
                    <Home />
                  </Tab.Pane>
                  <Tab.Pane eventKey="second">
                    <h1 className="text-dark text-center">Investment Guide</h1>
                    <InvestGuide />
                  </Tab.Pane>
                  <Tab.Pane eventKey="third">
                    <h1 className="text-dark text-center">Campaign Guide</h1>
                    <CrowdsaleGuide />
                  </Tab.Pane>
                  <Tab.Pane eventKey="fourth">
                    <h1 className="text-dark text-center">Music Guide</h1>
                    <MusicGuide />
                  </Tab.Pane>
                  <Tab.Pane eventKey="fifth">
                    <h1 className="text-dark text-center">Trading Guide</h1>
                    <TradingGuide />
                  </Tab.Pane>
                  <Tab.Pane eventKey="sixth">
                    <h1 className="text-dark text-center">KYC Guide</h1>
                    <KYCGuide />
                  </Tab.Pane>
                  {/* <Tab.Pane eventKey="seven">
                    <h1 className="text-dark text-center">Wallet Guide</h1>
                    <WalletGuide />
                  </Tab.Pane> */}

                  <Tab.Pane eventKey="nine">
                    <h1 className="text-dark text-center">Anchor Guide</h1>
                    <AnchorGuide />
                    
                  </Tab.Pane>
                  <Tab.Pane eventKey="ten">
                    <h1 className="text-dark text-center">NFT Guide</h1>
                    <NFTGuide />
                    
                  </Tab.Pane>
                  <Tab.Pane eventKey="eleven">
                    <h1 className="text-dark text-center">Swap Guide</h1>
                    <SwapGuide />
                    
                  </Tab.Pane>
                  <Tab.Pane eventKey="eight">
                    <h1 className="text-dark text-center">
                      Popular Offering Guide
                    </h1>
                    <PopularOffering />
                  </Tab.Pane>
                </Tab.Content>
              </Col>
            </Row>
          </Tab.Container>
        </div>
      </div>
    </div>
  );
};

export default Guide;
