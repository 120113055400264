import React, { useState } from "react";
import Zeptagram from "../../images/zeptagram.svg";
import Band from "../../images/band.svg";
import Girl from "../../images/lady.svg";
import Neon from "../../images/neonbrand.svg";
import { useDispatch, useSelector } from "react-redux";
import { forgotPasswordStart } from "../../redux/user/Action";
import { useHistory } from "react-router";
import GoogleCaptcha from "../../components/GoogleCaptcha/Captcha";
import InfoToast from "../../components/ToastMessage/InfoToast";

const ForgetPassword = () => {
  const [email, setEmail] = useState("");
  const dispatch = useDispatch();
  const history = useHistory();

  const recaptchaRef = React.useRef();
  let emailregex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
  const loading = useSelector((state) => state.user.forgetpasswordloading);

  React.useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const email = params.get("email");
    setEmail(email);
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (email.length > 50) {
      InfoToast("Email allows maximum 50 Characters");
      return;
    }
    if (!emailregex.test(email.trim())) {
      InfoToast("Please Enter a Valid Email");
      return;
    }
    const captchatoken = await recaptchaRef.current.executeAsync();
    if (!captchatoken) {
      return;
    }
    const data = {
      email: email,
      captcha: captchatoken,
    };
    dispatch(forgotPasswordStart(data));
  };
  return (
    <div className="row m-auto h-100 w-100  ">
      <div className="col-md-6  ">
        {/* <div><img className='cur-pointer' onClick={()=>history.push('/login')} src={Zeptagram} alt=''/></div> */}
        <div>
          <img
            className="cur-pointer"
            onClick={() => history.push("/login")}
            src={Zeptagram}
            alt=""
          />
        </div>
        <div className="h-84 align-center ">
          <div className="d-flex flex-column justify-content-center">
            <form className="" onSubmit={handleSubmit}>
              <h4 className="text-dark">Forgot Password</h4>

              <div className="text-grey w-500">
              Click on Submit and we will send you an OTP to reset your password
              </div>
              <input
                type="email"
                value={email}
                className="input-box my-3"
                onChange={(e) => setEmail(e.target.value)}
                placeholder="Email"
                required
              />
              <div className="d-flex align-items-center">
                <button
                  className="button-medium border-shadow  medium-btn"
                  type="submit"
                >
                  {!loading ? (
                    "Submit"
                  ) : (
                    <>
                      <span
                        className="spinner-border spinner-border-smd"
                        role="status"
                        aria-hidden="true"
                      ></span>
                    </>
                  )}
                </button>

                <button
                  className="button-medium btn-grey border-shadow m-2"
                  type="button"
                  onClick={() => history.push("/login")}
                >
                  Cancel
                </button>
              </div>

              {/* <div className='text-grey mt-3'>Click on Submit and we will sent you an email to your registered mail.</div> */}
            </form>
          </div>
        </div>
        <GoogleCaptcha recaptchaRef={recaptchaRef} />
      </div>
      <div className="col-md-6 image-container">
        <span className="verify-image verify-image-1">
          <img src={Neon} alt="" />
        </span>
        <span className="verify-image verify-image-2">
          <img src={Band} alt="" />
        </span>
        <div className="verify-image verify-image-3">
          <img height={380} className="girl-img" src={Girl} alt="" />
        </div>
      </div>
    </div>
  );
};

export default ForgetPassword;
