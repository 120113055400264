export const userActionTypes = {
  LOGIN_START: "LOGIN_START",
  LOGIN_SUCCESS: "LOGIN_SUCCESS",
  LOGIN_FAILURE: "LOGIN_FAILURE",

  SIGNUP_START: "SIGNUP_START",
  SIGNUP_SUCCESS: "SIGNUP_SUCCESS",
  SIGNUP_FAILURE: "SIGNUP_FAILURE",

  VERIFY_EMAIL_START: "VERIFY_EMAIL_START",
  VERIFY_EMAIL_SUCCESS: "VERIFY_EMAIL_SUCCESS",
  VERIFY_EMAIL_FAILURE: "VERIFY_EMAIL_FAILURE",

  FORGOT_PASSWORD_START: "FORGOT_PASSWORD_START",
  FORGOT_PASSWORD_SUCCESS: "FORGOT_PASSWORD_SUCCESS",
  FORGOT_PASSWORD_FAILURE: "FORGOT_PASSWORD_FAILURE",

  VERIFY_FORGOT_PASSWORD_START: "VERIFY_FORGOT_PASSWORD_START",
  VERIFY_FORGOT_PASSWORD_SUCCESS: "VERIFY_FORGOT_PASSWORD_SUCCESS",
  VERIFY_FORGOT_PASSWORD_FAILURE: "VERIFY_FORGOT_PASSWORD_FAILURE",

  RESEND_OTP_START: "RESEND_OTP_START",
  RESEND_OTP_SUCCESS: "RESEND_OTP_SUCCESS",
  RESEND_OTP_FAILURE: "RESEND_OTP_FAILURE",

  RESET_2FA_START: "RESET_2FA_START",
  RESET_2FA_SUCCESS: "RESET_2FA_SUCCESS",
  RESET_2FA_FAILURE: "RESET_2FA_FAILURE",

  VERIFY_RESET_2FA_START: "VERIFY_RESET_2FA_START",
  VERIFY_RESET_2FA_SUCCESS: "VERIFY_RESET_2FA_SUCCESS",
  VERIFY_RESET_2FA_FAILURE: "VERIFY_RESET_2FA_FAILURE",

  USER_DETAIL_START: "USER_DETAIL_START",
  USER_DETAIL_SUCCESS: "USER_DETAIL_SUCCESS",
  USER_DETAIL_FAILURE: "USER_DETAIL_FAILURE",

  BOOKMARK_START: "BOOKMARK_START",
  BOOKMARK_SUCCESS: "BOOKMARK_SUCCESS",
  BOOKMARK_FAILURE: "BOOKMARK_FAILURE",

  LOGOUT: "LOGOUT",
  LOGOUT_SUCCESS: "LOGOUT_SUCCESS",
  LOGOUT_FAILURE: "LOGOUT_FAILURE",

  PASSWORD_CHANGE: "PASSWORD_CHANGE",
  PASSWORD_CHANGE_SUCCESS: "PASSWORD_CHANGE_SUCCESS",
  PASSWORD_CHANGE_FAILURE: "PASSWORD_CHANGE_FAILURE",

  TICKET_DATA: "TICKET_DATA",

  CLEAR_STATE_DATA: "CLEAR_STATE_DATA",

  ANCHOR_LOGIN_START: "ANCHOR_LOGIN_START",
  ANCHOR_LOGIN_SUCCESS: "ANCHOR_LOGIN_SUCCESS",
  ANCHOR_LOGIN_FAILURE: "ANCHOR_LOGIN_FAILURE",

  ANCHOR_SIGNUP_START: "ANCHOR_SIGNUP_START",
  ANCHOR_SIGNUP_SUCCESS: "ANCHOR_SIGNUP_SUCCESS",
  ANCHOR_SIGNUP_FAILURE: "ANCHOR_SIGNUP_FAILURE",

  LOBSTR_LOGIN_START: "LOBSTR_LOGIN_START",
  LOBSTR_LOGIN_SUCCESS: "LOBSTR_LOGIN_SUCCESS",
  LOBSTR_LOGIN_FAILURE: "LOBSTR_LOGIN_FAILURE",

  LOBSTR_SIGNUP_START: "LOBSTR_SIGNUP_START",
  LOBSTR_SIGNUP_SUCCESS: "LOBSTR_SIGNUP_SUCCESS",
  LOBSTR_SIGNUP_FAILURE: "LOBSTR_SIGNUP_FAILURE",

  FREIGHTER_LOGIN_START: "FREIGHTER_LOGIN_START",
  FREIGHTER_LOGIN_SUCCESS: "FREIGHTER_LOGIN_SUCCESS",
  FREIGHTER_LOGIN_FAILURE: "FREIGHTER_LOGIN_FAILURE",

  FREIGHTER_SIGNUP_START: "FREIGHTER_SIGNUP_START",
  FREIGHTER_SIGNUP_SUCCESS: "FREIGHTER_SIGNUP_SUCCESS",
  FREIGHTER_SIGNUP_FAILURE: "FREIGHTER_SIGNUP_FAILURE",
};
