import React from "react";
import Img1 from "../../../images/guide/image36.png";
import Img2 from "../../../images/guide/image15.png";
import Img3 from "../../../images/guide/image16.png";
import Img4 from "../../../images/guide/image1.png";
import Img5 from "../../../images/guide/image26.png";
import Img6 from "../../../images/guide/image38.png";
import ReactPlayer from "react-player";
export const InvestGuide = () => {
  return (
    <div className="mx-3">
      <p className="text-justify">
        {/* <h5 className="text-dark">Invest using FIAT</h5>
        Here you can invest via Fiat ($) considering you have already set up a
        pay-out account for you.In Amount (incl.VAT), enter the amount you want
        to invest in USD. VAT will be deducted as a part of your acquired
        purchase. In the next field, you can check the amount deducted from your
        account after paying Zeptagram charges (transaction).
        <br /> Ex: If you enter an amount of 10$, then in Amount+7.5% you will
        see 10.75, which will be deducted from your account. <br />
        <br /> */}
        <h5 className="text-dark">Invest Using Crypto</h5>
        You can also invest via Zeptacoin, just enter the amount of Zeptacoin
        you want to invest(pricing based on real time),similarly you will be
        shown the amount will be deducted, considering 5% of the fee will be
        deducted for Zeptagram transaction charge. Finally for any crypto
        transaction you need to put your wallet PIN to authenticate the
        transaction.
      </p>
      <br />

      {/* <p className="text-justify">
        Click on the invest button in any crowdsale/crowdfund and the user can
        see an invest dialog. User can invest in crowdsale using two methods
      </p>
      <ul>
        <li>
          <b>Zeptacoin</b>
        </li>
        <li>
          <b>Fiat</b>
        </li>
      </ul> */}
      {/* <br /> */}
      {/* <h5 className="text-dark">Via Fiat</h5>
      <div className="text-center" >
      <div className="text-dark">Invest via Fiat</div>
      <div className="align-center"  >
      <ReactPlayer 
      controls url={'https://www.youtube.com/watch?v=cwNtviCB3kQ'}
          />
      </div>
      </div>
      <img className="guide-img guide-md" src={Img1} />
      <p className="text-justify">
        Upon Submit users will be redirected to Payment Gateway where they have
        to fill their personal details in order to invest.This will be needed to
        create a payment receipt for the investment.
      </p>
      <img className="guide-img" src={Img2} />
      <p className="text-justify">
        Upon Filling they will be redirected to fill their card information in
        order to proceed with payment.
      </p>
      <img className="guide-img" src={Img3} />
      <p className="text-justify">
        Upon filling info and placing order, You will be redirected to the
        Success page on successful investment.
      </p>
      <img className="guide-img" src={Img4} />
      <p className="text-justify">
        To Verify the investment You can see Transaction History in the Wallet
        Page. You will see your investment amount in ZPTC(excluding platform
        service charge).
      </p> */}

      <h5 className="text-dark">Via Zeptacoin</h5>

      <div className="text-center" >
      <div className="text-dark">Invest via Zeptacoin</div>
      <div className="align-center"  >
      <ReactPlayer 
      controls url={'https://www.youtube.com/watch?v=kzhlZSADRfA'}
          />
      </div>
      </div>
      
      <img className="guide-img guide-md" src={Img5} />
      <img className="guide-img" src={Img6} />
      <p className="text-justify">
        On submit You will be proceeding to Portfolio Page where you can see
        your all investments.
        <br /> <b>Note:</b> Amount in $ is based on conversion rate of real time
        price of ZPTC.So Actual Price may differ.
      </p>
    </div>
  );
};
