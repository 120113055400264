import React, { useEffect, useState } from "react";
import "../AnchorWallet/index.scss";
import { HeightBox, WidthBox } from "../../components/components";
import AccountInfoComponent from "../../components/account-info-component";
import { Image, Tab, Tabs } from "react-bootstrap";
import SwapIcon from "../../icons/swap.svg";
import { shallowEqual, useSelector } from "react-redux";
import { useFetch } from "../../api/api_client";
import { userApis } from "../../redux/api";
import SwapWalletButton from "../Swap/Swap_wallet_button";
import LoadWallet from "../Swap/LoadWallet";
import { stringConstants } from "../../constants";
import StellarTransactionHistory from "../Swap/TransactionHistory";
import Swap from "../Swap/Swap";
import Instruction from "../Swap/Instruction";
import RefreshIcon from "../../icons/refresh.svg";
import WalletConnectService from "../Swap/walletconnect";
import { Redirect, useHistory, useParams } from "react-router-dom";
import SwalError from "../../components/ToastMessage/SwalError";

export default function StellarMobileView()
{
  let params = useParams();
  const { data } = useSelector((state) => state.user, shallowEqual);
  const history = useHistory();
  const [currWallTab, setCurrWallTab] = useState("stellar");
  const [publicKeyChecker, setPublicKeyChecker] = useState(false);
  const [ual, Setual] = useState({
    activeUser: { accountName: "prakashnimje" },
  });
  const [token, setToken] = useState("");
  const [encryptedKey, setEncryptedKey] = useState(false);
  const [swapModel, setSwapModel] = useState(false);
  const [refreshTransaction, setRefreshTransaction] = useState(1);
  const [zptcBalance, setZptcBalance] = useState("");
  const [stellarZptc, setstellarZptc] = useState(0);
  const [stellarLTM, setstellarLTM] = useState(0);

  const walletConnect = new WalletConnectService();

  const [stellarPubKey, setStellarPubKey] = useState(false);
  function StellarKey()
  {
    setStellarPubKey(!stellarPubKey);
  }

  function ForPublicKey()
  {
    setPublicKeyChecker(!publicKeyChecker);
  }
  function ForEncryptedKey()
  {
    setEncryptedKey(!encryptedKey);
  }

  const publicKey = localStorage.getItem(
    stringConstants.PUBLIC_KEY_LOCAL_STORAGE
  );
  const stellarPrivateKey = localStorage.getItem(
    stringConstants.ENCRYPTED_KEY_LOCAL_STORAGE
  );

  const stellarPublicKeys = localStorage.getItem("stellerPublicKey");

  const zptc_balance = localStorage.getItem("zptcBalance");

  const livePrices = useFetch(userApis.LIVE_PRICES);

  const fetchStellarZptcBalance = async (stellarPublicKeys) =>
  {
    try
    {
      const balance = await walletConnect.getStellarZPTCBalance(
        stellarPublicKeys
      );
      setstellarZptc(balance);
    } catch (e)
    {
      console.log(e);
    }
  };
  //---tokensale participate--

  const fetchStellarLTMBalance = async (stellarPublicKeys) =>
  {
    try
    {
      const balance = await walletConnect.getStellarLTMBalance(
        stellarPublicKeys
      );
      setstellarLTM(balance);
    } catch (e)
    {
      console.log(e);
    }
  };
  useEffect(() =>
  {
    let search = window.location.search;
    let params = new URLSearchParams(search);
    let token = params.get("token");
    let accountName = params.get("account");
    console.log({ token, accountName });
    if (token && accountName)
    {
      setToken(token);
      Setual({ activeUser: { accountName: accountName } });
    } else
    {
      SwalError("Something went wrong");
      history.push("/");
    }
  }, []);

  useEffect(async () =>
  {
    if (stellarPublicKeys)
    {
      await walletConnect.initWalletConnect();

      await fetchStellarLTMBalance(stellarPublicKeys);
      await fetchStellarZptcBalance(stellarPublicKeys);

    }
  }, [stellarPublicKeys, refreshTransaction]);

  return (
    <>
      <div className={"wrapper"}>
        <div className={"container h-100"}>
          <div className={"d-flex flex-row align-items-center flexWrap "}></div>
          <h1 className="text-center py-3 border-bottom-dark">Wallets</h1>
          {/* <div className={"section-divider"} /> */}

          <div className={"row section-2"}>
            <div className={"col-md-4 col-xs-1 account-info-sec"}>
              <Tabs
                defaultActiveKey={currWallTab}
                id="uncontrolled-tab-example"
                className="mb-2 kyc-tab"
                onSelect={(k) =>
                {
                  sessionStorage.setItem("currTab", k);
                  setCurrWallTab(k);
                }}
              >
                <Tab eventKey="stellar" title="Stellar"></Tab>
              </Tabs>

              <h3 className={"section-heading"}>Wallet Info</h3>

              {currWallTab === "stellar" && stellarPublicKeys && (
                <>
                  <AccountInfoComponent
                    showCopyIcon={true}
                    title={stellarPublicKeys}
                    subtitle={"Public Key"}
                  />
                </>
              )}

              {currWallTab === "stellar" && stellarPublicKeys && (
                <>
                  <AccountInfoComponent
                    title={
                      stellarZptc
                        ? stellarZptc + " ZPTC "
                        : //   `${(
                        //     Number.parseFloat(data?.zeptaPrice) * stellarZptc
                        //   ).toFixed(2)} USD`
                        "0.0000 ZPTC"
                    }
                    subtitle={"ZPTC Balance"}
                  />{" "}
                  <WidthBox width={"4px"} />{" "}
                  <Image
                    src={RefreshIcon}
                    style={{
                      marginTop: "-10.2em",
                      marginLeft: "12em",
                      cursor: "pointer",
                    }}
                    className={"icon-setting"}
                    onClick={() =>
                    {
                      stellarPublicKeys &&
                        fetchStellarZptcBalance(stellarPublicKeys);
                    }}
                  />
                </>
              )}

              {currWallTab === "stellar" && stellarPublicKeys && (
                <>
                  <AccountInfoComponent
                    title={stellarLTM ? stellarLTM + " XLM" : "0.0000 XLM"}
                    subtitle={"XLM Balance"}
                  />{" "}
                  <WidthBox width={"4px"} />{" "}
                  <Image
                    src={RefreshIcon}
                    style={{
                      marginTop: "-10.2em",
                      marginLeft: "12em",
                      cursor: "pointer",
                    }}
                    className={"icon-setting"}
                    onClick={() =>
                    {
                      stellarPublicKeys &&
                        fetchStellarLTMBalance(stellarPublicKeys);
                    }}
                  />{" "}
                </>
              )}

              {currWallTab === "stellar" && (
                <>
                  {/* {publicKey || stellarPublicKeys ? null : ( */}
                  {stellarPublicKeys ? (
                    <button
                      className={`music-action-button box-shadow  p-1 mt-3`}
                      size={"button-small-long"}
                      title={"Disconnect your wallet"}
                      onClick={async () =>
                      {
                        localStorage.removeItem("stellerPublicKey");
                        localStorage.removeItem("zptcBalance");
                        localStorage.removeItem("StellarPrivateKey");
                        let arr = [];

                        for (let key in localStorage)
                        {
                          if (key.includes("wc@"))
                          {
                            arr.push(key);
                          }
                        }
                        walletConnect.clearClient();
                        arr.forEach((el) =>
                        {
                          localStorage.removeItem(el);
                        });

                        window.location.reload();
                      }}
                    >
                      Disconnect
                    </button>
                  ) : (
                    <>
                      <LoadWallet
                        ForEncryptedKey={ForEncryptedKey}
                        setZptcBalance={setZptcBalance}
                        walletConnect={walletConnect}
                      />

                      {/* <div className="anchor-wallet-buttons">
                        <GenerateWallet ForPublicKey={ForPublicKey} />
                      </div> */}
                    </>
                  )}
                </>
              )}

              <HeightBox height={"40px"} />
            </div>
            <div className={"col-md-8 col-xs-1 setting-sec"}>
              <h3 className={"section-heading"}>Actions</h3>

              {currWallTab === "stellar" && (
                <div
                  className={"d-flex flex-row flex-wrap justify-content-center"}
                >
                  <SwapWalletButton
                    disable={!stellarPublicKeys}
                    title={"Swap"}
                    icon={SwapIcon}
                    msg={
                      "Swap your Stellar ZPTC into Telos ZPTC. Connect with 'Wallet Connect' first to access."
                    }
                    onClick={() =>
                    {
                      setSwapModel(true);
                    }}
                  />
                </div>
              )}
              <HeightBox height={"16px"} />
              {currWallTab == "stellar" && !stellarPublicKeys && (
                <Instruction />
              )}

              {currWallTab === "stellar" && stellarPublicKeys && token && (
                <StellarTransactionHistory
                  refresh={refreshTransaction}
                  setRefresh={setRefreshTransaction}
                  token={token}
                />
              )}
              <div></div>
            </div>
          </div>
        </div>
      </div>
      {swapModel && (
        <Swap
          showModal={swapModel}
          refreshTransaction={refreshTransaction}
          setRefreshTransaction={setRefreshTransaction}
          hideModel={setSwapModel}
          zptcBalance={zptcBalance}
          setZptcBalance={setZptcBalance}
          walletConnect={walletConnect}
          ual={ual}
          token={token}
        />
      )}
    </>
  );
}
