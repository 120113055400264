import React, { useState } from "react";
import { useEffect } from "react";
import { useHistory } from "react-router-dom";
import SwalError from "../../components/ToastMessage/SwalError";
import AppleIcon from "../../icons/apple.jpg";
import { userApis } from "../../redux/api";
import { getRequiredData } from "../../redux/apicalltemplate";
import { initApple, singInApple } from "./utils";
import AppServices from "../../services/AppService";

const appService = new AppServices();
const AppleLogin = ({ ual, mainLoading }) => {
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    initApple();
  }, []);

  const history = useHistory();
  const handleAppleLogin = async () => {
    setLoading(true);
    try {
      if (ual?.activeUser !== null) {
        await appService.logout(ual);
      }

      const data = await singInApple();
      try {
        const responsedata = await getRequiredData(
          "POST",
          userApis.APPLE_LOGIN,
          data
        );

        if (responsedata) {
          localStorage.setItem("token", responsedata.data.authToken);
          if (responsedata.data.success) {
            history.push("/home");
          }
        }
      } catch (error) {
        if (error && error.response && error.response.data) {
          // console.log(error.response.data.message);
          SwalError(error.response.data.message);
        }

        if (
          !error.response.data.authToken &&
          error.response.data.is2FA_enabled == true
        ) {
          localStorage.setItem("isAppleLogin", true);
          localStorage.setItem("googleLoginData", JSON.stringify(data));
          localStorage.setItem("2faEnabled", true);
          if (error.response.data.is2faEnabledViaMobile) {
            localStorage.setItem("2famobileenabled", true);
            localStorage.setItem("2faEnabled", true);
          }
          history.push("/verify2fa");
        }

        if (
          error.response.data.user_id &&
          error.response.data.message == "Unverified device."
        ) {
          localStorage.setItem("user_id", error.response.data.user_id);
          localStorage.setItem("device_id", error.response.data.device_id);
          localStorage.setItem("isAppleLogin", true);
          localStorage.setItem("googleLoginData", JSON.stringify(data));
          history.push(`/verify-device`);
        }
      }
    } catch (error) {
      //console.log(error);
      setLoading(false);
    }
  };

  return (
    <div>
      {loading ? (
        <span
          className="spinner-border spinner-border-smd"
          role="status"
          aria-hidden="true"
        ></span>
      ) : (
        <button onClick={handleAppleLogin} className="btn-def" disabled={loading || mainLoading}>
          <img  className="icon-img border-circle" src={AppleIcon} alt="apple login" />
        </button>
      )}
    </div>
  );
};

export default AppleLogin;
