import React, { useState } from "react";
import { Form } from "react-bootstrap";
import VisibleEye from "./VisibleEye/VisibleEye";
import * as PropTypes from "prop-types";

export function PasswordInput({
  placeholder,
  name,
  onChange,
  isPassword = true,
  required,
}) {
  const [visible, setVisible] = useState(false);
  return (
    <div className="eye-cont">
      <input
        className={"input-box-sm"}
        type={`${!visible ? (!isPassword ? "text" : "password") : "text"}`}
        name={name}
        onChange={onChange}
        placeholder={placeholder}
        autoComplete="nope"
        required={required ? true : false}
      />
      {isPassword && (
        <VisibleEye visible={visible} setVisibility={(v) => setVisible(v)} />
      )}
    </div>
  );
}

PasswordInput.propTypes = {
  onChange: PropTypes.func,
  name: PropTypes.string,
  placeholder: PropTypes.string,
};

export function SubmitButton({
  loading,
  onClick,
  title,
  hidden = false,
  size = "short",
}) {
  return (
    <button
      disabled={loading}
      hidden={hidden}
      className={`border-shadow  ${
        size === "short" ? "button-small" : "button-small-long"
      }`}
      type="submit"
      onClick={onClick}
    >
      {!loading ? (
        title
      ) : (
        <span
          className="spinner-border spinner-border-smd"
          role="status"
          aria-hidden="true"
        />
      )}
    </button>
  );
}

SubmitButton.propTypes = {
  onClick: PropTypes.func,
  loading: PropTypes.bool,
  title: PropTypes.string,
};

export function HeightBox({ height, hidden }) {
  return <div hidden={hidden} style={{ height: height }} />;
}

export function WidthBox({ width }) {
  return <span style={{ width: width }} />;
}
