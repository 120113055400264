import { toast } from "react-toastify";
import {
  ErrorToast,
  SuccessMessage,
  SuccessToast,
} from "../components/ToastMessage/SuccessToast";

export const isValidTwitterUrl = (url) => {
  let regEmail =
    /(?:https:\/\/)(?:www\.)?twitter\.com\/(?:(?:\w)*#!\/)?(?:pages\/)?(?:[\w\-]*\/)*([\w\-]*)/;
  return regEmail.test(url);
};

export const isNotValid = (data) => {
  return (
    data === null ||
    data === "" ||
    data === undefined ||
    (data && `${data}`.trim() === "")
  );
};

export const checkLength = (data, length = 1) => {
  return data !== undefined && data.length >= length;
};

export const isValidInstagramUrl = (url) => {
  let regEmail =
    /(?:https:\/\/)(?:www\.)?instagram\.com\/(?:(?:\w)*#!\/)?(?:pages\/)?(?:[\w\-]*\/)*([\w\-]*)/;
  return regEmail.test(url);
};

export const notifyError = (message) => {
  toast.error(<ErrorToast message={message} />, {
    toastId: 1,
    updateId: 1,
  });
};

export const notifySuccess = (message) => {
  toast.success(<SuccessToast message={message} />, {
    toastId: 1,
    updateId: 1,
  });
};

export const txnList = [
  { name: "Claim", val: "claim" },
  { name: "New Account", val: "new_account" },
  { name: "Buy Ram", val: "buy_ram" },
  { name: "Refund", val: "trade_refund" },
  { name: "EOS Transfer", val: "eos_transfer" },
  { name: "ZPTC Transfer", val: "zptc_transfer" },
  { name: "ZPT Transfer", val: "zpt_transfer" },
  { name: "MTO Create", val: "mto_create" },
  { name: "MTO Transfer", val: "mto_transfer" },
  { name: "List Sale", val: "list_sale" },
  { name: "Invest", val: "invest" },
  { name: "Give Royalty", val: "give_royalty" },
  {
    name: "ZPTC Transfer By Admin",
    val: "zpt_transfer_by_admin",
  },
  { name: "MTO Transfer By Admin", val: "mto_transfer_by_admin" },
  { name: "EOS Transfer By Admin", val: "eos_transfer_by_admin" },
];

export const listingTypeList = [
  {
    name: "Crowdfunding",
    val: "new",
  },
  {
    name: "Crowdsale",
    val: "existing",
  },
];

export const contentTypeList = [
  {
    name: "Album",
    val: "album",
  },
  {
    name: "Song",
    val: "song",
  },
];

export const stateList = [
  {
    name: "live",
    val: "live",
  },
  {
    name: "verified",
    val: "verified",
  },
  {
    name: "rejected",
    val: "rejected",
  },
  {
    name: "finalised",
    val: "finalised",
  },
  {
    name: "successful",
    val: "successful",
  },
  {
    name: "failed",
    val: "failed",
  },
  {
    name: "stopped",
    val: "stopped",
  },
  {
    name: "draft",
    val: "draft",
  },
  {
    name: "In Verification",
    val: "In Verification",
  },
];

export const getTxnName = (val) => {
  const outpt = txnList.filter((el) => el.val === val);
  return outpt && outpt.length ? outpt[0].name : val;
};

export const shortAddress = (address) => {
  const pre = address.substr(0, 6);
  const last = address.substr(-4);
  return `${pre}...${last}`;
};


export const removeStellarSession=(walletConnect)=>{

  localStorage.removeItem("stellerPublicKey");
  localStorage.removeItem("zptcBalance");
  localStorage.removeItem("StellarPrivateKey");
  let arr= [];

  for(let key in localStorage){
    if(key.includes('wc@')){
        arr.push(key)
    }

  }
  if(walletConnect){
    walletConnect.clearClient();
  }

   arr.forEach((el)=>{
    localStorage.removeItem(el)
   })
}