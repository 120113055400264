import React, { useState } from "react";
import Zeptagram from "../../images/zeptagram.svg";
import Band from "../../images/band.svg";
import Girl from "../../images/lady.svg";
import Neon from "../../images/neonbrand.svg";
import { useHistory } from "react-router";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import { loginStart } from "../../redux/user/Action";
import InfoToast from "../../components/ToastMessage/InfoToast";
import GoogleCaptcha from "../../components/GoogleCaptcha/Captcha";
import { userApis } from "../../redux/api";
import { getRequiredData } from "../../redux/apicalltemplate";
import SwalError from "../../components/ToastMessage/SwalError";
import SwalSuccess from "../../components/ToastMessage/SwalSuccess";
import crypto from "crypto-js";
import { anchorLoginStart } from "../../redux/user/Action";

const Verify2fa = ({ ual }) => {
  const [otp, setOtp] = useState("");
  const history = useHistory();
  const dispatch = useDispatch();
  const recaptchaRef = React.useRef();
  const [googleLoading, setGoogleLoading] = useState(false);
  const { anchorLoginLoading } = useSelector(
    (state) => state.user,
    shallowEqual
  );
  const loading = useSelector((state) => state.user.loginloading);
  const handleSubmit = async (e) => {
    e.preventDefault();
    const captchatoken = await recaptchaRef.current.executeAsync();
    if (!captchatoken) {
      return;
    }
    if (otp.length !== 6) {
      InfoToast("Pin must be  6 characters long");
      return;
    }
    let payload;
    if (ual?.activeUser) {
      const signature =
        ual.activeUser.signerProof?.data.hexString ||
        "EA674fdDe714fd979de3EdF0F56AA9716B898e";
      const walletName = ual.activeUser.accountName;
      const key = process.env.REACT_APP_SECRET_KEY;
      const data = {
        signature,
        walletName,
      };
      const hash = crypto
        .SHA256(JSON.stringify(data), key)
        .toString(crypto.enc.Hex);
      const payload = {
        type: localStorage.getItem("2famobileenabled") ? "mobile" : null,
        data: {
          signature: signature,
          walletName: walletName,
          hash: hash,
          email: sessionStorage.getItem("email"),
          otpToken: otp,
          rememberMe: sessionStorage.getItem("rememberme"),
          captcha: captchatoken,
        },
      };
      if (captchatoken) {
        dispatch(anchorLoginStart(payload));
      }
      return;
    } else {
      payload = {
        type: localStorage.getItem("2famobileenabled") ? "mobile" : null,
        data: {
          email: sessionStorage.getItem("email"),
          password: sessionStorage.getItem("pwd"),
          rememberMe: sessionStorage.getItem("rememberme"),
          otpToken: otp,
          captcha: captchatoken,
        },
      };
    }

    if (
      localStorage.getItem("isGoogleLogin") ||
      localStorage.getItem("isAppleLogin")
    ) {
      let data = JSON.parse(localStorage.getItem("googleLoginData"));
      let newdata = {
        ...data,
        otpToken: otp,
        captcha: captchatoken,
      };
      try {
        setGoogleLoading(true);
        const responsedata = await getRequiredData(
          "POST",
          localStorage.getItem("isAppleLogin")
            ? userApis.APPLE_LOGIN
            : userApis.GOOGLE_LOGIN,
          newdata
        );

        if (responsedata && responsedata.data && !responsedata.data.success) {
          SwalError(responsedata.data.message);
        }
        setGoogleLoading(false);
        if (responsedata) {
          localStorage.setItem("token", responsedata.data.authToken);
          if (responsedata.data.success) {
            localStorage.removeItem("googleLoginData");
            history.push("/home");
          }
        }
      } catch (error) {
        setGoogleLoading(false);
        if (error && error.response && error.response.data) {
          SwalError(error.response.data.message);
        }
      }
    } else {
      dispatch(loginStart(payload));
    }
  };

  return (
    <div className="row m-auto h-100 w-100">
      <div className="col-md-6">
        <div onClick={() => history.push("/login")}>
          <img src={Zeptagram} alt="" />
        </div>
        <div className="align-center h-84">
          <div className="d-flex flex-column justify-content-center">
            <h4 className="text-dark">Verify Two Factor Authentication</h4>
            <div className="text-grey">
              {localStorage.getItem("2famobileenabled")
                ? "A 6 digit Code has been sent to your Email id. Enter the code to continue.Dont refresh the page"
                : "Please Enter a code from your Default Authenticator App"}
            </div>
            <form className="d-flex flex-column" onSubmit={handleSubmit}>
              <input
                type="number"
                minLength={6}
                maxLength={6}
                className="input-box my-3 align-self-start"
                onChange={(e) => setOtp(e.target.value)}
                placeholder="OTP"
                required
              />
              <div className="d-flex align-items-center">
                <button
                  className="button-medium border-shadow m-2 medium-btn"
                  type="submit"
                >
                  {}
                  {!loading && !googleLoading && !anchorLoginLoading ? (
                    "Verify"
                  ) : (
                    <>
                      <span
                        className="spinner-border spinner-border-smd"
                        role="status"
                        aria-hidden="true"
                      ></span>
                    </>
                  )}
                </button>
                <button
                  className="button-medium btn-grey border-shadow m-2"
                  type="button"
                  onClick={() => {
                    ual.activeUser && ual.logout();
                    history.push("/login");
                  }}
                >
                  Cancel
                </button>
              </div>
            </form>
            {localStorage.getItem("2famobileenabled") ? (
              <div className="text-grey mt-3">
                Did not get an email?{" "}
                <span
                  className="text-hover"
                  onClick={() => history.push("/resendotp")}
                >
                  Click here
                </span>{" "}
                and we will resend it to you!
              </div>
            ) : null}
          </div>
        </div>
        <GoogleCaptcha recaptchaRef={recaptchaRef} />
      </div>
      <div className="col-md-6 image-container">
        <span className="verify-image verify-image-1">
          <img src={Neon} alt="" />
        </span>
        <span className="verify-image verify-image-2">
          <img src={Band} alt="" />
        </span>
        <div className="verify-image verify-image-3">
          <img height={380} className="girl-img" src={Girl} alt="" />
        </div>
      </div>
    </div>
  );
};

export default Verify2fa;
