import React, { useState } from "react";
import { Pagination } from "react-pagination-bar"
import 'react-pagination-bar/dist/index.css'
const PaginationBar = ({ count, setPage, page, pagePostsLimit=10 }) => {
 
  return (
  
    <div className="mt-2">
   <Pagination
    currentPage={page}
    itemsPerPage={pagePostsLimit}
    onPageChange={(pageNumber) => setPage(pageNumber)}
    totalItems={count}
    pageNeighbours={2}
  />
    </div>
 
  );
};

export default PaginationBar;
