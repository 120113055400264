import React, { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import CrowdsaleDetails from "../../components/CrowdsaleDetails/CrowdsaleDetails";
import { userApis, crowdsaleApis } from "../../redux/api";
import { getRequiredProtectedData } from "../../redux/apicalltemplate";
import { userDetailsStart } from "../../redux/user/Action";
import copyIcon from "../../icons/copy.png";
import { getLivePriceStart} from "../../redux/crowdsale/Action";
import debounce from "lodash.debounce";
import { Accordion, Card, Button, Spinner } from "react-bootstrap";
import { useFetch } from "../../api/api_client";
import { useSelector } from "react-redux";
import NodataFound from "../../icons/nodataportfolio.svg";
import NoRecordTable from "../../components/NoRecordFound/NoRecordTable";
import { LoadingView } from "../../components/loading_view";
import { CardItem } from "./Card";
import { SuccessToast } from "../../components/ToastMessage/SuccessToast";
import { CopyToClipboard } from "react-copy-to-clipboard/lib/Component";
import { toast } from "react-toastify";

const Portfolio = () => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(null);
  const [crowdsaleData, setCrowdsaleData] = useState(false);
  const [loadingCrowdsale, setLoadingCrowdsale] = useState(true);
  const userDetails = useSelector((state) => state.user.data);
  const [callagain, setCallAgain] = useState(false);
  const [showcrowdsale, setShowCrowdsale] = useState(false);
  const { liveprice } = useSelector((state) => state.crowdsale);
  const [totalVal, setTotalVal] = useState({});
  const history = useHistory();
  const dispatch = useDispatch();
  const [sorobanTxn, setSorobanTxn] = useState([])

  useEffect(async () => {
    //page=${data.page}&limit=${data.limit}&sortBy=${data.sortBy}&order=${data.order}
    const params = {};
    setLoading(true);
    const response = await getRequiredProtectedData(
      "GET",
      `${userApis.GET_PORTFOLIO}`,
      {},
      params
    );
    if (response && response.data) {
      setData(response.data.data);
    }
    setLoading(false);
  }, [callagain]);

    useEffect(() => {
  const fetchData = async () => {
    const token = localStorage.getItem("token")
    try {
      const sorobanTxnHistoryResponse = await fetch(`${userApis.SOROBAN_TXN_HISTORY}?action=invest`, {
        method: "GET",
        headers: {
          'x-auth-token': token, // Replace 'YOUR_AUTH_TOKEN' with your actual token
          'Content-Type': 'application/json'
        }
      });

      if (!sorobanTxnHistoryResponse.ok) {
        throw new Error('Network response was not ok');
      }

      const responseData = await sorobanTxnHistoryResponse.json();
      console.log("==============sorobanTxnHistoryResponse===============", responseData);
      setSorobanTxn(responseData.data)
    } catch (error) {
      console.error("Error fetching data: ", error);
    }
  };

  fetchData();
}, []);

console.log("--------------------------SOROBAN TXN-----------------", sorobanTxn)
  useEffect(() => {
    dispatch(userDetailsStart({}));
  }, []);

  useEffect(() => {
    dispatch(getLivePriceStart({}));
  }, []);

  const getTotalRightsParticular = (value) => {
    const val = value.reduce(
      (sum, curr) => sum + (curr.txnData.percentage || 0),
      0
    );

    return val.toFixed(3);
  };
  const getTotalAmount = (value) => {
    const val = value.reduce(
      (sum, curr) => sum + (curr.txnData.amount || 0),
      0
    );

    return val.toFixed(2);
  };

  const handleCrowdsale = async (token_name) => {
    if (token_name) {
      setLoadingCrowdsale(true);

      const params = {
        token_name: token_name,
        page: 1,
        limit: 8,
        selector:
          "state softCap minContribution maxContribution percentageRights rate zeptaCoinsRaised isuuer startDate endDate type token_name saleId targetAmountByUser",
        selectorValue: 1,
        populateMusic: true,
      };
      setShowCrowdsale(true);
      const responselist = await getRequiredProtectedData(
        "GET",
        crowdsaleApis.GET_ALL_CROWDSALE,
        {},
        params
      );
      if (
        responselist &&
        responselist.data &&
        responselist.data.data &&
        responselist.data.data.length
      ) {
        setCrowdsaleData(responselist.data.data[0]);
        setTimeout(() => {}, 300);
      } else {
        setShowCrowdsale(false);
      }
      setLoadingCrowdsale(false);
    }
  };

  return (
    <div className="wrapper h-100vh">
      <div className="container ">
        <h1 class="text-center py-3 border-bottom-dark mb-2 font-weight-bold">
          Portfolio
        </h1>

       {loading ? (
  <div className="w-100 align-center" style={{ minHeight: "300px" }}>
    <Spinner hidden={false} animation="border" />
  </div>
) : (
  <>
    <div className="border-md p-2 m-2 text-center">
      {userDetails && userDetails.walletCreated === false ? (
        <div>
          <div>
            ** Please create your wallet first before proceeding to KYC
          </div>
          <div className="w-100">
            <button
              className="music-action-button box-shadow mt-3 mx-auto"
              onClick={() => {
                history.push("/wallet");
              }}
            >
              Wallet
            </button>
          </div>
        </div>
      ) : (
        sorobanTxn.length > 0 ? (
           <div className={"table-responsive order-table box-shadow w-100"}>
        <table className={"table table-bordered table-hover "} >
                <thead>
                  <tr>
                    <th scope="col">Token Name</th>
                    <th scope="col">User PublicKey</th>
                    <th scope="col">Action</th>
                    <th scope="col">Hash</th>
                    <th scope="col">Amount</th>
                    <th scope="col">Latest Ledger</th>
                    <th scope="col">Status</th>
                  </tr>
                </thead>
                <tbody style={{backgroundColor:"white"}}>
                  {sorobanTxn.map((item) => {
                    return (
                      <tr>
                        <td>
                          {item?.token_name}
                        </td>
                            <td style={{ display: "flex", gap: "10px", cursor: "pointer" }}>
                              {item.forUserPublickey && (
                                    <>
                                      {item.forUserPublickey.slice(0, 4)}
                                      {"..."}
                                      {item.forUserPublickey.slice(-4)}
                                    </>
                                  )}
                               <CopyToClipboard
                              text={item.forUserPublickey}
                              onCopy={() => {
                                  toast.success(<SuccessToast message={'Copied!'} />,{
                                      toastId:1,
                                      updateId:1
                                  });

                              }}
                            >
                            <img
                              src={copyIcon}
                              width={"17px"}
                              height={"17px"}
                              alt="copyIcon"
                              className={"mx-2 cur-pointer"}
                            />
                            </CopyToClipboard>
                            </td>
                        <td>
                          {item?.action}
                        </td>
                        <td style={{display:"flex", gap:"10px", cursor:"pointer"}}>
                         {item?.hash && (
                          <>
                            {item.hash.slice(0, 4)}
                            {"..."}
                            {item.hash.slice(-4)}
                          </>
                        )}
                        <CopyToClipboard
                              text={item.hash}
                              onCopy={() => {
                                  toast.success(<SuccessToast message={'Copied!'} />,{
                                      toastId:1,
                                      updateId:1
                                  });

                              }}
                            >
                            <img
                              src={copyIcon}
                              width={"17px"}
                              height={"17px"}
                              alt="copyIcon"
                              className={"mx-2 cur-pointer"}
                            />
                            </CopyToClipboard>
                        </td>
                         <td>
                          {item?.amount}
                        </td>
                        
                        <td>
                          {item?.latestLedger}
                        </td>
                        <td>
                          {item?.status}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
      </div>
        ) : (
          null
        )
      )}
    </div>
    <div>
      {!data || (data && !data.length) ? (
        <div className="align-center w-100 flex-column">
          <NoRecordTable />
        </div>
      ) : (
        <Accordion>
          {data.map((el, i) => (
            <CardItem
              key={i}
              el={el}
              i={i}
              getTotalRightsParticular={getTotalRightsParticular}
              handleCrowdsale={handleCrowdsale}
              liveprice={liveprice}
            />
          ))}
        </Accordion>
      )}
    </div>
    {showcrowdsale && (
      <CrowdsaleDetails
        show={showcrowdsale}
        setShow={setShowCrowdsale}
        data={crowdsaleData}
        loading={loadingCrowdsale}
        liveprice={liveprice}
      />
    )}
  </>
)}

      </div>
    </div>
  );
};

export default Portfolio;
