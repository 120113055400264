import React from "react";
import Guide from "../../../images/guide/study.svg";
export const Home = () => {
  return (
    <div className="mx-3">
      <div className=" align-center mt-3">
        {" "}
        <img
          className=""
          style={{ borderRadius: "53%", width: "400px" }}
          width={400}
          height={380}
          src={Guide}
        />
      </div>
      <div className="">
        <h5 className="text-justify text-dark">
          Welcome to Zeptagram Guide Section
        </h5>
        <p className="text-justify">
          This guide is updated actively to help user for a smooth journey.You
          can refer to each section chronologically to enhance more knowledge
          about platform.
        </p>
        {/* <h5 className="text-dark">Changelogs</h5>
          <ul>
            <li>Added Zeptagram Guide</li>
            <li>Link provided for Zepta NFT platform</li>
          </ul> */}
      </div>
    </div>
  );
};
