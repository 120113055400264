import React, { useEffect, useState } from "react";
import { Dropdown, DropdownButton } from "react-bootstrap";
import PhoneInput from "react-phone-number-input";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import GoogleCaptcha from "../../components/GoogleCaptcha/Captcha";
import InfoToast from "../../components/ToastMessage/InfoToast";
import VisibleEye from "../../components/VisibleEye/VisibleEye";
import Band from "../../images/band.svg";
import Girl from "../../images/lady.svg";
import Neon from "../../images/neonbrand.svg";
import Zeptagram from "../../images/zeptagram.svg";

import { anchorSignupStart } from "../../redux/user/Action";
import { Gender } from "../../utils/static";
export default function ProfileRegistration(props) {
  const { ual } = props;
  let { id } = useParams();
  const [email, setEmail] = useState("");
  const [user, setUser] = useState("");
  const [gender, setGender] = useState("");
  const [phone, setPhone] = useState("");
  const [visible, setVisibility] = useState(false);
  const history = useHistory();
  const dispatch = useDispatch();
  const recaptchaRef = React.useRef();
  const loading = useSelector((state) => state.user.signuploading);
  useEffect(() => {
    if (localStorage.getItem("token")) {
      return;
    }
    ual.activeUser === null &&
      ual.activeUser?.accountName !== id &&
      history.push("/login");
  }, []);
  async function handleSubmit(event) {
    event.preventDefault();
    const captchatoken = await recaptchaRef.current.executeAsync();
    if (!captchatoken) {
      return;
    }

    let userregex = /^[a-zA-Z ]*$/;
    let emailregex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    if (!userregex.test(user.trim())) {
      InfoToast("Please Enter a Valid User Name");
      return;
    }
    if (email.length > 50) {
      InfoToast("Email allows maximum 50 Characters");
      return;
    }
    if (!emailregex.test(email.trim())) {
      InfoToast("Please Enter a Valid Email");
      return;
    }
    // if (email.length < 10) {
    //   InfoToast("please Enter correct phone number");
    //   return;
    // }
    const data = {
      email: email,
      fullName: user,
      captcha: captchatoken,
      accountName: ual.activeUser?.accountName || id,
    };
    dispatch(anchorSignupStart(data));
  }

  return (
    <div className="row m-auto w-100 h-100">
      <div className="col-md-6 ">
        <div>
          <img
            className="cur-pointer"
            onClick={() => history.push("/login")}
            src={Zeptagram}
            alt=""
          />
        </div>
        <div className="align-center h-100">
          <div className="d-flex flex-column justify-content-center">
            <form
              className="d-flex flex-column"
              onSubmit={handleSubmit}
              autoComplete="nope"
            >
              <span className="heading-medium mb-2 align-self-baseline">
                Profile Setup
              </span>

              <input
                type="text"
                className="input-box my-3"
                value={user}
                tabIndex={1}
                minLength={2}
                maxLength={50}
                autoFocus
                onChange={(e) => setUser(e.target.value)}
                placeholder="Name"
                required
                autoComplete="nope"
              />
              <input
                type="email"
                className="input-box my-3"
                value={email}
                minLength={2}
                maxLength={50}
                tabIndex={1}
                onChange={(e) => setEmail(e.target.value)}
                placeholder="Email"
                required
                autoComplete="nope"
              />
              {/* <input
                type="phone"
                className="input-box my-3"
                value={phone}
                minLength={2}
                maxLength={10}
                tabIndex={1}
                onChange={(e) => setPhone(e.target.value)}
                placeholder="Phone Number"
                required
                autoComplete="nope"
              /> */}

              <div className="align-center">
                <button
                  className="button-medium button-reverse border-shadow m-2 my-4"
                  type="submit"
                >
                  {!loading ? (
                    "Submit"
                  ) : (
                    <>
                      <span
                        className="spinner-border spinner-border-smd"
                        role="status"
                        aria-hidden="true"
                      ></span>
                    </>
                  )}
                </button>
              </div>
            </form>
          </div>
        </div>
        <GoogleCaptcha recaptchaRef={recaptchaRef} />
      </div>
      <div className="col-md-6 image-container">
        <span className="verify-image verify-image-1">
          <img src={Neon} alt="" />
        </span>
        <span className="verify-image verify-image-2">
          <img src={Band} alt="" />
        </span>
        <span className="verify-image verify-image-3">
          <img src={Girl} height={380}  className="girl-img"alt="" />
        </span>
      </div>
    </div>
  );
}
