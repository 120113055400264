import React from "react";
import { useState, useEffect } from "react";
import { userApis } from "../../redux/api";
import { getRequiredProtectedData } from "../../redux/apicalltemplate";

import InvestCrowdsale from "../../components/Invest/Invest";

import NoRecordTable from "../../components/NoRecordFound/NoRecordTable";
import { useDispatch, useSelector } from "react-redux";

import AppServices from "../../services/AppService";
import { Spinner } from "react-bootstrap";
import CrowdCardMedium from "../../components/CrowdCards/CrowdCardMedium";
import CrowdsaleDetails from "../../components/CrowdsaleDetails/CrowdsaleDetails";
const Bookmarks = ({ ual }) => {
  const [data, setData] = useState([]);

 
  const [show, setShow] = useState(false);
  const [reload, setReload] = useState(false);
  const loading=useSelector(state=>state.user.loading)

  const [showcrowdsale, setShowCrowdsale] = useState(false);
  const [crowdsaledata, setCrowdsaleData] = useState(false);
  const [text, setText] = useState("Crowdsale");
  const {
   
    liveprice,
 
  } = useSelector((state) => state.crowdsale);
  const bookmarks=useSelector(state=>state.user.bookmarks)

  const appServices = new AppServices();

  const userDetails = useSelector((state) => state.user.data);

  const handleCrowdsaleDetails = (el) => {
    setCrowdsaleData(el);
    setShowCrowdsale(true);
  };
  const handleInvest = (el, type) => {
    setCrowdsaleData(el);
    if (type == "crowdfund") {
      setText("Crowdfund");
    } else {
      setText("Crowdsale");
    }
    setShow(true);
  };


  return (
    <div className="wrapper h-100vh">
      <div className="container mx-auto " style={{ maxWidth: "850px" }}>
        <h1 class="text-center py-3  mb-2 font-weight-bold">Bookmark Crowdsales and Crowdfunds</h1>
        
        <div className="d-flex flex-wrap mt-5 justify-content-center">
          {loading ? (
            <Spinner hidden={false} animation="border" />
          ) : (
            <>
              {bookmarks && bookmarks.length ? (
                [...bookmarks].map((el, i) => {
                  return (
                    <CrowdCardMedium key={el.data.crowdsaleId} el={el.data}  liveprice={liveprice}
                    handleCrowdsaleDetails={handleCrowdsaleDetails}
                    handleInvest={handleInvest}
                    isBookmark={true}
                    userid={userDetails && userDetails._id}
                    
                    
                    
                    />
                    
                    
                  );
                })
              ) : (
                <NoRecordTable />
              )}
            </>
          )}
        </div>
        {showcrowdsale && (
          <CrowdsaleDetails
            show={showcrowdsale}
            setShow={setShowCrowdsale}
            data={crowdsaledata}
            liveprice={liveprice}
          />
        )}
            {show && !(appServices.isLogin() || ual?.activeUser) && (
          <InvestCrowdsale
            show={show}
            setShow={setShow}
            text={text}
            liveprice={liveprice}
            crowdsaledata={crowdsaledata}
          />
        )}
      </div>

    </div>
  );
};

export default Bookmarks;
