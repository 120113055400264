import Swal from "sweetalert2";

const SwalError = (message) => {
  Swal.fire({
    titleText: message,
    type: "error",
    icon: "error",
    toast: true,
    position: "top",
    showConfirmButton: false,
    timer: 5000,
    toast: true,
    timerProgressBar: true,
    background: "white",
  });
};

export default SwalError;
