import moment from "moment";
import React, { useCallback, useEffect, useState } from "react";
import { Spinner } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import NoRecordTable from "../../components/NoRecordFound/NoRecordTable";
import { userApis } from "../../redux/api";
import { getRequiredProtectedData } from "../../redux/apicalltemplate";
import { ticketData } from "../../redux/user/Action";
import CreateTicket from "./CreateTicket";
import debounce from "lodash.debounce";
import { Description } from "./DesMessage";

const Support = () => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(null);
  const [page, setPage] = useState(1);
  const [description, setDescription] = useState("");
  const [showticket, setShowTicket] = useState(false);
  const [callagain, setCallAgain] = useState(false);
  const [submitbtn, setSubmitBtn] = useState(false);
  const [btnId, setBtnId] = useState("");
  const history = useHistory();
  const dispatch = useDispatch();
  useEffect(async () => {
    //page=${data.page}&limit=${data.limit}&sortBy=${data.sortBy}&order=${data.order}
    const params = {
      page: page,
      limit: 10,
      description: description,
    };
    setLoading(true);
    const response = await getRequiredProtectedData(
      "GET",
      `${userApis.GET_TICKETS}`,
      {},
      params
    );
    if (response && response.data) {
      setData(response.data.data);
    }
    setLoading(false);
  }, [page, callagain, description]);

  const handleSearch = (e) => {
    setDescription(e.target.value);
  };
  const debouncedChangeHandler = useCallback(debounce(handleSearch, 300), []);

  return (
    <div className="wrapper h-100">
      <div className="container  ">
        <h3>Manage Ticket</h3>
        <div className="d-flex justify-content-between align-items-center flex-wrap">
          <button
            className="text-center button-medium border-shadow cur-pointer more-button "
            style={{ padding: "10px 30px" }}
            onClick={() => setShowTicket(true)}
          >
            New Ticket
          </button>
          <input
            style={{ width: "250px", display: "inline-block" }}
            type="text"
            placeholder="Enter your search"
            className="input-box-sm"
            onChange={debouncedChangeHandler}
          />
        </div>
        <div>
          <h5>Ticket List</h5>
          <div className=" align-center" style={{ minHeight: "300px" }}>
            {loading ? (
              <Spinner hidden={false} animation="border" />
            ) : data && data.length ? (
              <div className="table-responsive ticket-table w-100">
                <table className="table ">
                  <thead>
                    <tr>
                      <th scope="col" className="text-hover text-center ">
                        Subject
                      </th>
                      <th scope="col" className="text-hover text-center ">
                        Description
                      </th>
                      <th scope="col" className="text-hover text-center ">
                        Ticket Number
                      </th>
                      <th scope="col" className="text-hover text-center">
                        Status
                      </th>
                      <th scope="col" className="text-hover text-center">
                        Created For
                      </th>

                      <th scope="col" className="text-hover text-center">
                        Created At
                      </th>
                      <th scope="col" className="text-hover text-center">
                        Updated At
                      </th>
                      <th scope="col" className="text-hover text-center"></th>
                    </tr>
                  </thead>
                  <tbody>
                    {data &&
                      data.map((el) => {
                        return (
                          <tr className=" " key={el._id}>
                            <td className="text-center align-middle">
                              {el.subject}
                            </td>
                            <td className="text-center align-middle">
                              <Description message={el.description} />
                            </td>
                            <td className="text-center align-middle">
                              {el.ticketNumber}
                            </td>
                            <td className="text-center align-middle">
                              <div className="dark-button p-2 m-2 text-center border-md font-weight-bold">
                                {el.status === "NOT_RESOLVED"
                                  ? "NOT RESOLVED"
                                  : el.status}
                              </div>
                            </td>
                            <td className="text-center align-middle">
                              {el.createdFor && el.createdFor.type}
                            </td>

                            <td className="text-center align-middle">
                              {moment(el.createdAt).format("DD-MM-YYYY LT")}
                            </td>
                            <td className="text-center align-middle">
                              {moment(el.updated_at).format("DD-MM-YYYY LT")}
                            </td>
                            <td
                              className="text-dark align-center h-100 cur-pointer"
                              onClick={async () => {
                                dispatch(ticketData(el));
                                await history.push(`/chat/${el.ticketNumber}`);
                              }}
                            >
                              <i className="fas fa-arrow-right"></i>
                            </td>
                          </tr>
                        );
                      })}
                  </tbody>
                </table>
              </div>
            ) : (
              <NoRecordTable />
            )}
          </div>
           
          {showticket && (
            <CreateTicket
              show={showticket}
              setShow={setShowTicket}
              setCallAgain={setCallAgain}
              callagain={callagain}
            />
          )}
          {/* <Route
        exact
        path="/chat/:id"
        render={(props) =>
          localStorage.getItem("token") ? (
            <Chat ticketData={ticketData} />
          ) : (
            <Redirect to="/login" />
          )
        }
      /> */}
        </div>
      </div>
    </div>
  );
};

export default Support;
