import React, { useEffect, useState } from "react";
import { Modal, Form } from "react-bootstrap";
import InfoToast from "../../components/ToastMessage/InfoToast";
import SwalSuccess from "../../components/ToastMessage/SwalSuccess";
import SwalError from "../../components/ToastMessage/SwalError";
import { getRequiredProtectedData } from "../../redux/apicalltemplate";
import { crowdsaleApis } from "../../redux/api";
import PinInput from "react-pin-input";
import AppServise from "../../services/AppService";
import
{
    getPublicKey,
    isConnected,
    isAllowed,
    setAllowed,
    getUserInfo,
    signAuthEntry,
    signTransaction,
    signBlob,
} from "@stellar/freighter-api";


const appServise = new AppServise();
const DeployCrowdsale = ({ show, setShow, id, musicType,handleReloadCrowdsale }) => {
  const [pin, setPin] = useState("");
  const handleClose = () => setShow(false);
  const [loading, setLoading] = useState(false);


 

  //handle form submit------------------------
  const handleSubmit = async (event) => {
    event.preventDefault();

    if (await isConnected()) {
    setLoading(true);
    let data = {};
    // data.pin = pin;
    const response = await getRequiredProtectedData(
      "PATCH",
      `${crowdsaleApis.DEPLOY_SOROBAN_CROWDSALE}/${id}`,
      data,
      {}
    );
    console.log("===================REPONSE AFTER FREIGHTER=============", response)
     if (response && response.data && response.data.success) {
      SwalSuccess(response.data.message);
      setTimeout(() => {
        setShow(false);
        handleReloadCrowdsale();
      }, 800);
    }
    if (response && response.data && !response.data.success) {
      SwalError(response.data.message);
    }
    if (response && response.response && response.response.data) {
      SwalError(response.response.data.message);
    }
    setLoading(false);
    }
    else{
   if (pin.length != 4) {
      InfoToast("Pin must be 4 characters long");
      return;
    }
    setLoading(true);
    let data = {};
    data.pin = pin;

    const response = await getRequiredProtectedData(
      "PATCH",
      `${crowdsaleApis.DEPLOY_CROWDSALE}/${id}`,
      data,
      {}
    );
    if (response && response.data && response.data.success) {
      SwalSuccess(response.data.message);
      setTimeout(() => {
        setShow(false);
        handleReloadCrowdsale();
      }, 800);
    }
    if (response && response.data && !response.data.success) {
      SwalError(response.data.message);
    }
    if (response && response.response && response.response.data) {
      SwalError(response.response.data.message);
    }
    setLoading(false);
    }
 
  };

  const handleAnchorSubmit = async () => {
    setLoading(true);
    let data = {};

    const response = await getRequiredProtectedData(
      "PATCH",
      `${crowdsaleApis.DEPLOY_CROWDSALE_WITH_ANCHOR}/${id}`,
      data,
      {}
    );
    if (response && response.data && response.data.success) {
      SwalSuccess(response.data.message);
      setTimeout(() => {
        setShow(false);
        handleReloadCrowdsale();
      }, 800);
    }
    if (response && response.data && !response.data.success) {
      SwalError(response.data.message);
    }
    if (response && response.response && response.response.data) {
      SwalError(response.response.data.message);
    }
    setLoading(false);
  };

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Deploy {musicType==='new' ? 'Crowdfunding' : 'Crowdsale'}</Modal.Title>
      </Modal.Header>
      {!appServise.isLogin() ? (
        <Form onSubmit={handleSubmit}>
          <Modal.Body className="p-5">
            <label className="text-dark" style={{ marginLeft: "10px" }}>
              Enter Pin*
            </label>
            <div className="text-center">
              <PinInput
                className="mx-auto"
                length={4}
                initialValue={pin}
                secret
                onChange={(value, index) => setPin(value)}
                type="numeric"
                inputMode="number"
                style={{ padding: "10px", marginLeft: "auto" }}
                inputStyle={{ borderColor: "#daca9e" }}
                inputFocusStyle={{ borderColor: "blue" }}
                onComplete={(value, index) => {}}
                required
              />
            </div>
          </Modal.Body>
          <Modal.Footer className="p-3">
            <button
              className="button-medium border-shadow medium-btn"
              type="submit"
              disabled={loading}
              title={"Submit"}
            >
              {!loading ? (
                "Submit"
              ) : (
                <>
                  <span
                    className="spinner-border spinner-border-smd"
                    role="status"
                    aria-hidden="true"
                  ></span>
                </>
              )}
            </button>
          </Modal.Footer>
        </Form>
      ) : (
        <>
          <Modal.Body className="p-5">
            {/* <label className="text-dark" style={{ marginLeft: "10px" }}>
              Enter Pin*
            </label> */}
            <div className="text-center">
              <h3>Please Confirm to Deploy On {musicType==='new' ? 'Crowdfunding' : 'Crowdsale'}</h3>
            </div>
          </Modal.Body>
          <Modal.Footer className="p-3">
            <button
              className="button-medium border-shadow medium-btn"
              type="cancle"
              disabled={loading}
              title={"cancle"}
              onClick={handleClose}
            >
              Cancel
            </button>
            <button
              className="button-medium border-shadow medium-btn"
              type="submit"
              disabled={loading}
              title={"Submit"}
              onClick={handleAnchorSubmit}
            >
              {!loading ? (
                "Submit"
              ) : (
                <>
                  <span
                    className="spinner-border spinner-border-smd"
                    role="status"
                    aria-hidden="true"
                  ></span>
                </>
              )}
            </button>
          </Modal.Footer>
        </>
      )}
    </Modal>
  );
};

export default DeployCrowdsale;
