import PolarChart from "./polar_chart";
import { useFetch } from "../../api/api_client";
import { userApis } from "../../redux/api";
import { LoadingView } from "../../components/loading_view";
import React, { useReducer, useState } from "react";

import MusicTable from "./music_table";
import VerifiedMusic from "./verified_music";
import { HeightBox } from "../../components/components";
import ISWCMusicDialog from "./add_iswc_dialog";
import MusicDetailsModel from "./music_details_model";
import { useHistory } from "react-router-dom";
import SwalError from "../../components/ToastMessage/SwalError";
import ShowNFTsModal from "../NFT_Components/ShowNFTsModal";
 
export default function MyMusic({ ual }) {
  const history = useHistory();
  const [refreshmusic, setRefreshMusic] = useState(1);
  const [detailsDialog, showDetailsDialog] = useState({
    show: false,
    id: "",
  });
  const [showNFTsModal, setShowNFTsModal] = useState(false);
  
  const [kycRefresh, setKycRefresh] = useState(false);

  const { data:kycData, loading:kycLoading } = useFetch(userApis.KYC_DETAILS, [kycRefresh]);
 

  function stateReducer(state, action) {
    switch (action.type) {
      case "showAddModel":
        return {
          ...state,
          addModel: !state["addModel"],
          albumFlag: action["albumFlag"],
          editData: action["editData"],
        };
      default:
        return state;
    }
  }

  const [pageState, dispatchState] = useReducer(stateReducer, {
    addModel: false,
    albumFlag: false,
    editData: undefined,
  });

  // const [iswcDialog, showIswcDialog] = useState(false)
  //  const [albumFlag, setAlbumFlag] = useState(false)

  //const [albumFlag, setAlbumFlag] = useState(false)

  const { data, error, loading } = useFetch(userApis.MUSIC_CHART) || {};

  if (loading) return <LoadingView />;

  if (error) {
    //console.log(error);

    if (error.error === "Please complete your publisher KYC to proceed") {
      SwalError("Please complete your publisher KYC to proceed");
      history.push("profile");
      return <div></div>;
    }
    var message = error.error ?? "Opps! Something went wrong";

    return <h2>{message}</h2>;
  }

  return (
    <>
      {pageState["addModel"] && (
        <ISWCMusicDialog
        kycData={kycData}
        kycLoading={kycLoading}
        kycRefresh={kycRefresh}
        setKycRefresh={setKycRefresh}
          albumFlag={pageState.albumFlag}
          editData={pageState["editData"]}
          setShow={() => {
            dispatchState({
              type: "showAddModel",
              albumFlag: false,
              editData: undefined,
            });
            //   showIswcDialog(false)
            // window.location.reload();
          }}
          show={pageState["addModel"]}
          refresh={refreshmusic}
          setRefresh={setRefreshMusic}
        />
      )}
      {detailsDialog["show"] && (
        <MusicDetailsModel
          onEdit={(details) => {
            dispatchState({
              type: "showAddModel",
              albumFlag: details["contentType"] === "album",
              editData: details,
            });
          }}
          refresh={refreshmusic}
          setRefresh={setRefreshMusic}
          show={detailsDialog["show"]}
          id={detailsDialog["id"]}
          setShow={(reload = false) => {
            showDetailsDialog({ show: false });
          }}
          showNFTsModal={setShowNFTsModal}
          ual={ual}
        />
      )}

      <ShowNFTsModal
        showModal={showNFTsModal}
        hideModal={setShowNFTsModal}
        ual={ual}
      />

      <div className="wrapper">
        <div className={"container my-music-container "}>
          <div className={"row"}>
            <div
              className={"col-md-6 col-sm-6 col-xs-1 music-action-container"}
            >
              <div className={"music-action"}>
                <div className={"text-dark mb-2 text-center"}>
                  Add a Song ISWC
                </div>
                <button
                  className={"music-action-button box-shadow"}
                  onClick={() => {
                    dispatchState({ type: "showAddModel", albumFlag: false });
                  }}
                >
                  Add
                </button>
              </div>

              <div className={"music-action"}>
                <div className={"text-dark mb-2 text-center"}>
                  Add a Song ISRC
                </div>
                <button
                  className={"music-action-button box-shadow"}
                  onClick={() => {
                    dispatchState({ type: "showAddModel", albumFlag: true });
                  }}
                >
                  Add
                </button>
              </div>
              <div className={"music-action"}>
                <div className={"text-dark mb-2 text-center"}>
                  Music NFTs
                </div>
                <button
                  className={"music-action-button box-shadow"}
                  onClick={() => {
                    if(!ual.activeUser){
                      SwalError('Please login with Anchor to create music NFTs')
                      return
                    }
                    setShowNFTsModal(true)
                  }}
                >
                  View NFTs
                </button>
              </div>
             
            </div>
        
            <div
              className={"col-md-6 col-xs-1  music-graph-container"}
              style={{ paddingBottom: "16px", paddingTop: "16px" }}
            >
              {data && <PolarChart data={data} />}
            </div>
       
            <HeightBox height={"50px"} />
            <div className={"divider "} />
            <HeightBox height={"50px"} />
            <div>
              <h4 className="text-dark">Verified Music</h4>
              <HeightBox height={"30px"} />
              <VerifiedMusic
                onSelect={(id) => {
                  showDetailsDialog({ show: true, id: id });
                }}
                refresh={refreshmusic}
                setRefresh={setRefreshMusic}
              />
            </div>
            <div className={"divider"} />
            <HeightBox height={"50px"} />
            <div>
              <MusicTable
                refresh={refreshmusic}
                setRefresh={setRefreshMusic}
                onSelect={(id) => {
                  showDetailsDialog({ show: true, id: id });
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
