import React, { useEffect, useState } from "react";
import BlockChainService from "../../services/BlockchainService";
import "./index.scss";
import CreateWallet from "../../images/createwallet.svg";
import AddIcon from "../../icons/add.svg";
import ReceiveIcon from "../../icons/receive.svg";
import WithdrawIcon from "../../icons/withdraw.svg";
import SendIcon from "../../icons/send.svg";
import SwapIcon from "../../icons/swap.svg";
import MaleIcon from "../../icons/male_icon.jpeg";
import AppServices from "../../services/AppService";
import { HeightBox, WidthBox } from "../../components/components";
import AccountInfoComponent from "../../components/account-info-component";
import WalletButton from "../../components/wallet_button";
import { Image, Tab, Tabs } from "react-bootstrap";
import TransactionHistory from "../wallet/transaction_history";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import SendZptcModal from "./SendZptcModal";
import { logout } from "../../redux/user/Action";
import { SuccessToast } from "../../components/ToastMessage/SuccessToast";
import { toast } from "react-toastify";
import { LoadingView } from "../../components/loading_view";
import { toUpper } from "lodash";
import TokenReceiveModal from "./TokenReceiveModal";
import {
  isNotValid,
  notifyError,
  removeStellarSession,
} from "../../utils/utils";

import WithdrawModal from "./WithdrawModal";
import { useFetch } from "../../api/api_client";
import { userApis } from "../../redux/api";
import ClaimTokensale from "./claim_tokensale";
import { getRequiredProtectedData } from "../../redux/apicalltemplate";
import RoyalitiesHistory from "../wallet/royalities_history";
import { Link, useHistory } from "react-router-dom";
import GenerateWallet from "../Swap/GenerateWallet";
import SwapWalletButton from "../Swap/Swap_wallet_button";
import LoadWallet from "../Swap/LoadWallet";
import { stringConstants } from "../../constants";
import StellarTransactionHistory from "../Swap/TransactionHistory";
import Swap from "../Swap/Swap";
import Instruction from "../Swap/Instruction";
import RefreshIcon from "../../icons/refresh.svg";
import WalletConnectService from "../Swap/walletconnect";
import SwapTelos from "../Swap/SwapTelos";
import IconPushpin from "../../icons/pushpin.svg";
import Exportpin from "../Exportpin/Exportpin";

const blockchainService = new BlockChainService();
const appServices = new AppServices();
export default function AnchorWallet({ ual }) {
  const dispatch = useDispatch();
  const [walletConnected, setWalletConnected] = useState(false);
  const [accountName, setaccountName] = useState("");
  const { data } = useSelector((state) => state.user, shallowEqual);
  const [walletDetails, setWalletDetails] = useState("");
  const [showSendModal, setshowSendModal] = useState(false);
  const [showReceiveModal, setReceiveModal] = useState(false);
  const [showWithdrawModal, setWithdrawModal] = useState(false);
  const [publicKeyAnchor, setPublicKeyAnchor] = useState("");
  const [loading, setLoading] = useState(false);
  const [showClaim, setShowClaim] = useState(false);
  const [claimmodal, setClaimModal] = useState(false);
  const [claimData, setClaimData] = useState([]);
  const [currWallTab, setCurrWallTab] = useState(
    sessionStorage.getItem("currTab") || "telos"
  );
  const history = useHistory();
  const [publicKeyChecker, setPublicKeyChecker] = useState(false);
  const [encryptedKey, setEncryptedKey] = useState(false);
  const [swapModel, setSwapModel] = useState(false);
  const [swapModelTelos, setSwapModelTelos] = useState(false);
  const [refreshTransaction, setRefreshTransaction] = useState(1);
  const [zptcBalance, setZptcBalance] = useState("");
  const [stellarZptc, setstellarZptc] = useState(0);
  const [stellarLTM, setstellarLTM] = useState(0);
  const [exportpinModal, setExportpinModal] = useState(false);

  const walletConnect = new WalletConnectService();

  const [stellarPubKey, setStellarPubKey] = useState(false);
  function StellarKey() {
    setStellarPubKey(!stellarPubKey);
  }

  function ForPublicKey() {
    setPublicKeyChecker(!publicKeyChecker);
  }
  function ForEncryptedKey() {
    setEncryptedKey(!encryptedKey);
  }

  const publicKey = localStorage.getItem(
    stringConstants.PUBLIC_KEY_LOCAL_STORAGE
  );
  const stellarPrivateKey = localStorage.getItem(
    stringConstants.ENCRYPTED_KEY_LOCAL_STORAGE
  );

  const stellarPublicKeys = localStorage.getItem("stellerPublicKey");

  const zptc_balance = localStorage.getItem("zptcBalance");

  const livePrices = useFetch(userApis.LIVE_PRICES);

  const fetchStellarZptcBalance = async (stellarPublicKeys) => {
    try {
      const balance = await walletConnect.getStellarZPTCBalance(
        stellarPublicKeys
      );
      setstellarZptc(balance);
    } catch (e) {
      console.log(e);
    }
  };

  //---tokensale participate--

  const fetchStellarLTMBalance = async (stellarPublicKeys) => {
    try {
      const balance = await walletConnect.getStellarLTMBalance(
        stellarPublicKeys
      );
      setstellarLTM(balance);
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(async () => {
    const response = await getRequiredProtectedData(
      "GET",
      userApis.CHECK_CLAIM_TOKENSALE
    );

    if (response && response.data && response.data.success) {
      if (typeof response.data?.data !== "string") {
        setShowClaim(true);
        setClaimData(response.data.data);
      }
    }
    if (response && response.data && !response.data.success) {
      setShowClaim(false);
    }
    if (response && response.response && response.response.data) {
      setShowClaim(false);
    }
  }, []);

  useEffect(async () => {
    if (stellarPublicKeys) {
      await walletConnect.initWalletConnect();
      await fetchStellarZptcBalance(stellarPublicKeys);
      await fetchStellarLTMBalance(stellarPublicKeys);
    }
  }, [stellarPublicKeys, refreshTransaction]);

  // show public keys
  useEffect(async () => {
    if (accountName) {
      const response = await getRequiredProtectedData(
        "POST",
        userApis.ANCHOR_PUBLIC_KEY,
        {
          accountName: accountName,
        }
      );

      if (response && response.data && response.data.success) {
        setPublicKeyAnchor(response.data.data);
      }
      if (response && response.data && !response.data.success) {
        setPublicKeyAnchor("");
      }
      if (response && response.response && response.response.data) {
        setPublicKeyAnchor("");
      }
    }
  }, [accountName]);

  useEffect(() => {
    if (ual.activeUser) {
      setLoading(true);
      getwalletDetails();
    } else {
      setWalletConnected(false);
    }
  }, [ual.activeUser]);

  const getwalletDetails = async () => {
    setaccountName(ual.activeUser?.accountName);
    const [info, balance] = await Promise.all([
      blockchainService.getAccountInfo(ual.activeUser?.accountName),
      blockchainService.getTokenBalance(ual.activeUser?.accountName),
    ]);
    const details = {
      telosBalance: info.core_liquid_balance,
      publicKey: info.permissions[0].required_auth.keys[0].key,
    };
    setWalletConnected(true);
    setWalletDetails({
      ...details,
      zeptaBalance: parseFloat(balance.replace("ZPTC", "")),
    });
    setLoading(false);
  };
  const SendZeptaCoin = async (account, accountName, quantity, memo) => {
    if (isNotValid(account) || account.length != 12) {
      notifyError("Enter valid account name");
      return;
    }
    if (isNotValid(quantity) || parseInt(quantity) <= 0) {
      notifyError("Enter valid quantity");
      return;
    }
    if (isNotValid(memo)) {
      notifyError("Enter valid memo");
      return;
    }

    const tnx = blockchainService.transferToken(
      account,
      accountName,
      quantity,
      memo
    );
    const detais = await blockchainService.pushTransaction(
      tnx,
      ual,
      "zptc_transfer",
      {}
    );

    getwalletDetails();
    setshowSendModal(false);
    if (detais.success) {
      toast.success(
        <SuccessToast
          message={`${quantity} ZPTC has been successfully sent to ${accountName}`}
        />,
        {
          toastId: 1,
          updateId: 1,
        }
      );
    }
  };
  if (loading) return <LoadingView />;
  if (!walletConnected && ual.activeUser === null) {
    return (
      <>
        <div className={"wrapper"}>
          <div className={"container"}>
            <div
              className={"d-flex row flex-justify-center align-content-center"}
              style={{ height: "80vh" }}
            >
              <div className="align-center flex-column">
                <img src={CreateWallet} className="wallet-svg" />
                <button
                  className="music-action-button box-shadow mt-3 p-1"
                  size={"button-small-long"}
                  title={"Create your wallet"}
                  onClick={async () => {
                    const data = await appServices.login(ual);
                    setWalletConnected(appServices.isLogin());
                  }}
                >
                  Connect Anchor Wallet
                </button>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }

  return (
    <>
      <div className={"wrapper"}>
        <div className={"container h-100"}>
          <div className={"d-flex flex-row align-items-center flexWrap "}></div>
          <h1 className="text-center py-3 border-bottom-dark">Wallets</h1>
          {/* <div className={"section-divider"} /> */}

          <div className={"row section-2"}>
            <div className={"col-md-4 col-xs-1 account-info-sec"}>
              <Tabs
                defaultActiveKey={currWallTab}
                id="uncontrolled-tab-example"
                className="mb-2 kyc-tab"
                onSelect={(k) => {
                  sessionStorage.setItem("currTab", k);
                  setCurrWallTab(k);
                }}
              >
                <Tab eventKey="telos" title="Telos"></Tab>
                <Tab eventKey="stellar" title="Stellar"></Tab>
              </Tabs>

              <h3 className={"section-heading"}>Wallet Info</h3>

              {currWallTab === "telos" && (
                <>
                  <AccountInfoComponent
                    showCopyIcon={true}
                    title={accountName}
                    subtitle={"Account Name"}
                  />
                  <>
                    <AccountInfoComponent
                      title={
                        walletDetails
                          ? walletDetails?.zeptaBalance +
                            " ZPTC | " +
                            `${(
                              Number.parseFloat(data?.zeptaPrice) *
                              walletDetails?.zeptaBalance
                            ).toFixed(2)} USD`
                          : "0.00 ZPTC"
                      }
                      subtitle={"ZPTC Balance"}
                      isRefresh={true}
                      handleRefresh={() => {
                        getwalletDetails();
                      }}
                    />
                  </>

                  <AccountInfoComponent
                    title={walletDetails?.telosBalance || "0.00 TLOS"}
                    subtitle={"Telos Balance"}
                    info={"Use anchor to withdraw TLOS balance."}
                    isRefresh={true}
                    handleRefresh={() => {
                      getwalletDetails();
                    }}
                  />
                </>
              )}

              {currWallTab === "stellar" && stellarPublicKeys && (
                <>
                  <AccountInfoComponent
                    showCopyIcon={true}
                    title={stellarPublicKeys}
                    subtitle={"Public Key"}
                  />
                </>
              )}

              {currWallTab === "stellar" && stellarPublicKeys && (
                <>
                  <AccountInfoComponent
                    title={
                      stellarZptc
                        ? stellarZptc +
                          " ZPTC | " +
                          `${(
                            Number.parseFloat(data?.zeptaPrice) * stellarZptc
                          ).toFixed(2)} USD`
                        : "0.0000 ZPTC"
                    }
                    subtitle={"ZPTC Balance"}
                  />{" "}
                  <WidthBox width={"4px"} />{" "}
                  <Image
                    src={RefreshIcon}
                    style={{
                      marginTop: "-10.2em",
                      marginLeft: "12em",
                      cursor: "pointer",
                    }}
                    className={"icon-setting"}
                    onClick={() => {
                      stellarPublicKeys &&
                        fetchStellarZptcBalance(stellarPublicKeys);
                    }}
                  />{" "}
                </>
              )}

              {currWallTab === "stellar" && stellarPublicKeys && (
                <>
                  <AccountInfoComponent
                    title={stellarLTM ? stellarLTM + " XLM" : "0.0000 XLM"}
                    subtitle={"XLM Balance"}
                  />{" "}
                  <WidthBox width={"4px"} />{" "}
                  <Image
                    src={RefreshIcon}
                    style={{
                      marginTop: "-10.2em",
                      marginLeft: "12em",
                      cursor: "pointer",
                    }}
                    className={"icon-setting"}
                    onClick={() => {
                      stellarPublicKeys &&
                        fetchStellarLTMBalance(stellarPublicKeys);
                    }}
                  />{" "}
                </>
              )}

              {currWallTab === "stellar" && stellarPublicKeys && (
                <>
                  <div className="text-dark" style={{ marginTop: "-30px" }}>
                    <a
                      href="https://lobstr.co/trade/native/ZPTC:GDRV4ZV7I7BYYN25FJTEWHZJLTTX6FGWRQ6J5ZYWOLTRVRS2PH3UHFSV"
                      target="_blank"
                    >
                      Buy ZPTC via LOBSTR
                    </a>
                  </div>
                </>
              )}

              {currWallTab === "telos" && (
                <AccountInfoComponent
                  showCopyIcon={true}
                  title={publicKeyAnchor}
                  subtitle={"Public key"}
                />
              )}

              {/* Steller ZPTC */}

              {/* {currWallTab === "stellar" && publicKey && (
                <>
                  <AccountInfoComponent
                    showCopyIcon={true}
                    title={publicKey}
                    subtitle={"Stellar ZPTC Public Key"}
                  />
                </>
              )} */}

              {currWallTab === "stellar" && (
                <>
                  {/* {publicKey || stellarPublicKeys ? null : ( */}
                  {stellarPublicKeys ? (
                    <button
                      className={`music-action-button box-shadow  p-1 mt-3`}
                      size={"button-small-long"}
                      title={"Disconnect your wallet"}
                      onClick={async () => {
                        removeStellarSession(walletConnect);

                        window.location.reload();
                      }}
                    >
                      Disconnect
                    </button>
                  ) : (
                    <>
                      <LoadWallet
                        ForEncryptedKey={ForEncryptedKey}
                        setZptcBalance={setZptcBalance}
                        walletConnect={walletConnect}
                      />

                      {/* <div className="anchor-wallet-buttons">
                        <GenerateWallet ForPublicKey={ForPublicKey} />
                      </div> */}
                    </>
                  )}
                </>
              )}
              {/* {currWallTab === "telos" && (
              <div className="my-2">
                <button
                  disabled={!stellarPrivateKey}
                  className={`music-action-button box-shadow  p-1 `}
                  onClick={() => history.push("/swap")}
                >
                  Swap
                </button>
              </div>
              )} */}
              <HeightBox height={"40px"} />
            </div>
            <div className={"col-md-8 col-xs-1 setting-sec"}>
              <h3 className={"section-heading"}>Actions</h3>
              {currWallTab === "telos" && (
                <div
                  className={"d-flex flex-row flex-wrap justify-content-center"}
                >
                  {/* <WalletButton
                    title={"Add Zeptacoins"}
                    icon={AddIcon}
                    disable={true}
                    msg={
                      "Service temporary unavailable due to on-going token sale. Visit: https://zeptagram.com/investor-relations"
                    }
                    onClick={() => {
                      // dispatchAction("showAddModal");
                    }}
                  /> */}

                  {currWallTab === "telos" && (
                    <SwapWalletButton
                      // disable={!stellarPublicKeys}
                      title={"Swap"}
                      icon={SwapIcon}
                      trigger={"hover"}
                      msg={"Swap your Telos ZPTC into Stellar ZPTC."}
                      onClick={() => {
                        setSwapModelTelos(true);
                      }}
                    />
                  )}
                  <WalletButton
                    title={"Send Zeptacoins"}
                    icon={SendIcon}
                    onClick={() => {
                      setshowSendModal(true);
                    }}
                  />
                  {/* <WalletButton
                    title={"Withdraw Zeptacoins"}
                    icon={WithdrawIcon}
                    // disable={
                    //   userDetails.data &&
                    //   userDetails.data.data &&
                    //   userDetails.data.data["kycPublisher"] === "verified"
                    //     ? false
                    //     : true
                    // }
                    disable={true}
                    msg={
                      "Service temporary unavailable due to on-going token sale. Visit: https://zeptagram.com/investor-relations"
                    }
                    onClick={() => {
                      setWithdrawModal(true);
                    }}
                  /> */}
                  <WalletButton
                    title={"Receive Zeptacoins"}
                    icon={ReceiveIcon}
                    onClick={() => {
                      setReceiveModal(true);
                    }}
                  />

                  <WalletButton
                    title={"Export Pin"}
                    icon={IconPushpin}
                    onClick={() => {
                      setExportpinModal(true);
                    }}
                  />
                  {/* <WalletButton
                  title={"Token Swap"}
                  icon={ReceiveIcon}
                  onClick={() => {
                    setShowTokenSwap(true);
                  }}
                /> */}
                  {showClaim && (
                    <WalletButton
                      title={"Claim Tokensale"}
                      icon={ReceiveIcon}
                      onClick={() => {
                        setClaimModal(true);
                      }}
                    />
                  )}
                </div>
              )}
              {currWallTab === "stellar" && (
                <div
                  className={"d-flex flex-row flex-wrap justify-content-center"}
                >
                  <SwapWalletButton
                    disable={!stellarPublicKeys}
                    title={"Swap"}
                    icon={SwapIcon}
                    msg={
                      "Swap your Stellar ZPTC into Telos ZPTC. Connect with 'Wallet Connect' first to access."
                    }
                    onClick={() => {
                      setSwapModel(true);
                    }}
                  />
                </div>
              )}
              <HeightBox height={"16px"} />
              {currWallTab == "stellar" && !stellarPublicKeys && (
                <Instruction />
              )}
              {currWallTab === "telos" && (
                <>
                  <TransactionHistory accountName={accountName} />
                  <HeightBox height={"16px"} />
                  <RoyalitiesHistory accountName={accountName} />
                </>
              )}
              {currWallTab === "stellar" && stellarPublicKeys && (
                <StellarTransactionHistory
                  refresh={refreshTransaction}
                  setRefresh={setRefreshTransaction}
                />
              )}
              <div></div>
              {claimmodal && (
                <ClaimTokensale
                  show={claimmodal}
                  setShow={setClaimModal}
                  claimData={claimData}
                  ual={ual}
                />
              )}
            </div>
          </div>
        </div>
      </div>
      {showSendModal && (
        <SendZptcModal
          showModal={showSendModal}
          hideModal={setshowSendModal}
          ual={ual}
          account={accountName}
          onSubmit={SendZeptaCoin}
        />
      )}

      <TokenReceiveModal
        showReceiveModal={showReceiveModal}
        publicKey={walletDetails.publicKey}
        setReceiveModal={setReceiveModal}
      />
      <WithdrawModal
        showWithdrawModal={showWithdrawModal}
        closeModal={setWithdrawModal}
        withdrawType="crypto"
        withdrawCryptoData={{}}
        withdrawFlatData={{}}
        livePrices={livePrices}
        ual={ual}
      />
      {swapModel && (
        <Swap
          showModal={swapModel}
          refreshTransaction={refreshTransaction}
          setRefreshTransaction={setRefreshTransaction}
          hideModel={setSwapModel}
          zptcBalance={zptcBalance}
          setZptcBalance={setZptcBalance}
          walletConnect={walletConnect}
          ual={ual}
        />
      )}

      {exportpinModal && (
        <Exportpin
          showModal={exportpinModal}
          hideModel={setExportpinModal}
          ual={ual}
        />
      )}

      {swapModelTelos && (
        <SwapTelos
          showModal={swapModelTelos}
          refreshTransaction={refreshTransaction}
          setRefreshTransaction={setRefreshTransaction}
          hideModel={setSwapModelTelos}
          zptcBalance={zptcBalance}
          setZptcBalance={setZptcBalance}
          walletConnect={walletConnect}
          ual={ual}
        />
      )}
    </>
  );
}
