import React, { useState } from "react";
import { useEffect } from "react";
import { useParams, useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import InvestCrowdsale from "../../components/Invest/Invest";
import CrowdsaleDetails from "../../components/CrowdsaleDetails/CrowdsaleDetails";
import CrowdCardMedium from "../../components/CrowdCards/CrowdCardMedium";
import {
  finalizedCrowdfundStart,
  finalizedCrowdsaleStart,
  getLivePriceStart,
  liveCrowdfundStart,
  liveCrowdsaleStart,
  popularCrowdsaleStart,
  successfulCrowdsaleStart,
} from "../../redux/crowdsale/Action";
import {
  popularcrowdsalepayload,
  livecrowdsalepayload,
  livecrowdfundpayload,
  finalizedcrowdsalepayload,
  finalizedcrowdfundpayload,
  successfulcrowdsalepayload,
  allLiveSongPayload,
} from "./crowdsaleparams";
import { Spinner } from "react-bootstrap";
import NoRecordTable from "../../components/NoRecordFound/NoRecordTable";
import AppServices from "../../services/AppService";
import AnchorInvestModal from "../AnchorWallet/AnchorInvestModal";
const appServices = new AppServices()
const CrowdDetails = ({ ual }) => {
  const [show, setShow] = useState(false);
  const [showcrowdsale, setShowCrowdsale] = useState(false);
  const [crowdsaledata, setCrowdsaleData] = useState(false);
  const [text, setText] = useState("Crowdsale");
  const [page, setPage] = useState(1);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [crowddata, setCrowddata] = useState(null);
  const { id } = useParams();
  const userdata = useSelector((state) => state.user.data);
  const {
    popularcrowdsale,
    livecrowdfund,
    livecrowdsale,
    liveprice,
    successfulcrowdsale,
    livecrowdfundloading,
    successfulcrowdsaleloading,
    popularcrowdsaleloading,
    livecrowdsaleloading,
    finalizedcrowdsale,
    finalizedcrowdfund,
    finalizedcrowdsaleloading,
  } = useSelector((state) => state.crowdsale);

  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }
  let query = useQuery();
  const bookmarks=useSelector(state=>state.user.bookmarks)


  useEffect(() => {
    if (id === "campaigns") {
      dispatch(
        liveCrowdsaleStart({
          ...allLiveSongPayload,
          params: {
            ...allLiveSongPayload.params,
            page: page,
            name: query.get("name"),
          },
        })
      );
    }
    if (id === "crowdsale") {
      dispatch(
        liveCrowdsaleStart({
          ...livecrowdsalepayload,
          params: {
            ...livecrowdsalepayload.params,
            page: page,
            name: query.get("name"),
          },
        })
      );
    }
    if (id === "crowdfund") {
      dispatch(
        liveCrowdfundStart({
          ...livecrowdfundpayload,
          params: { ...livecrowdfundpayload.params, page: page },
        })
      );
    }
    if (id === "successfulcrowdsale") {
      dispatch(
        successfulCrowdsaleStart({
          ...successfulcrowdsalepayload,
          params: { ...successfulcrowdsalepayload.params, page: page },
        })
      );
    }
    if (id === "popularcrowdsale") {
      dispatch(
        popularCrowdsaleStart({
          ...popularcrowdsalepayload,
          params: { ...popularcrowdsalepayload.params, page: page },
        })
      );
    }
    if (id === "upcoming") {
      dispatch(
        finalizedCrowdsaleStart({
          ...finalizedcrowdsalepayload,
          params: { ...finalizedcrowdsalepayload.params, page: page },
        })
      );
    }
    if (id === "upcomingcrowdfund") {
      dispatch(
        finalizedCrowdfundStart({
          ...finalizedcrowdfundpayload,
          params: { ...finalizedcrowdfundpayload.params, page: page },
        })
      );
    }
    setLoading(true);
  }, [id, page, query.get("name")]);

  //for setting data inside ------------------------
  useEffect(() => {
    if (id === "campaigns") {
      setCrowddata(livecrowdsale);
      setLoading();
    }
    if (id === "crowdsale") {
      setCrowddata(livecrowdsale);
      setLoading();
    }
    if (id === "crowdfund") {
      setCrowddata(livecrowdfund);
    }
    if (id === "successfulcrowdsale") {
      setCrowddata(successfulcrowdsale);
    }
    if (id === "popularcrowdsale") {
      setCrowddata(popularcrowdsale);
    }
    if (id === "upcoming") {
      setCrowddata(finalizedcrowdsale);
    }
    if (id === "upcomingcrowdfund") {
      setCrowddata(finalizedcrowdfund);
    }
    setLoading(false);
  }, [
    livecrowdfund,
    livecrowdsale,
    successfulcrowdsale,
    popularcrowdsale,
    finalizedcrowdsale,
  ]);

  useEffect(() => {
    dispatch(getLivePriceStart({}));
  }, []);

  const handleCrowdsaleDetails = (el) => {
    setCrowdsaleData(el);
    setShowCrowdsale(true);
  };

  const handleInvest = (el, type) => {
    setCrowdsaleData(el);
    if (type == "crowdfund") {
      setText("Crowdfund");
    } else {
      setText("Crowdsale");
    }
    setShow(true);
  };

  return (
    <>
      {loading ? (
        <Spinner
          hidden={false}
          className="position-center"
          animation="border"
        />
      ) : (
        <div className="wrapper h-100 mt-5 p-2">
          <div className="container ">
            <div className="h3 text-dark text-center">
              {id == "campaigns" && "Live Campaigns"}
              {id == "crowdsale" && "Live Crowdsale Campaigns"}
              {id == "crowdfund" && "Live Crowdfund Campaigns"}
              {id == "successfulcrowdsale" && "Successful  Campaigns"}
              {id == "popularcrowdsale" && "Popular Crowdsale Campaigns"}
              {id == "upcoming" && "Upcoming Crowdsale Campaigns"}
              {id == "upcomingcrowdfund" && "Upcoming Crowdfund Campaigns"}
            </div>
            {show && !(appServices.isLogin() || ual.activeUser) && (
              <InvestCrowdsale
                show={show}
                setShow={setShow}
                text={text}
                liveprice={liveprice}
                crowdsaledata={crowdsaledata}
              />
            )}
            {show && (appServices.isLogin() || ual.activeUser) && (
              <AnchorInvestModal
                show={show}
                setShow={setShow}
                text={text}
                liveprice={liveprice}
                crowdsaledata={crowdsaledata}
                ual={ual}
              />
            )}
            {showcrowdsale && (
              <CrowdsaleDetails
                show={showcrowdsale}
                setShow={setShowCrowdsale}
                data={crowdsaledata}
                liveprice={liveprice}
              />
            )}
            <div className="d-flex align-items-center flex-wrap mt-4 justify-content-around">
              {crowddata && crowddata.data && crowddata.data.length ? (
                crowddata.data.map((el, i) => {
                  return (
                    <CrowdCardMedium
                      key={i}
                      el={el}
                      liveprice={liveprice}
                      handleCrowdsaleDetails={handleCrowdsaleDetails}
                      handleInvest={handleInvest}
                      userid={userdata && userdata._id}
                      source="search"
                      isBookmark={!!bookmarks.find(ml=>ml.crowdsaleId==el._id)}
                    />
                  );
                })
              ) : (
                <NoRecordTable />
              )}
            </div>
            <div className="d-flex justify-content-between align-items-center w-100">
              <button
                className="btn button-medium width-100"
                disabled={page == 1}
                onClick={() => setPage(page - 1)}
              >
                Previous
              </button>
              <button
                className="btn button-medium width-100"
                disabled={crowddata ? !crowddata.nextPage : true}
                onClick={() => setPage(page + 1)}
              >
                Next
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default CrowdDetails;
