import React from "react";
import Img1 from "../../../images/guide/image7.png";
import Img2 from "../../../images/guide/image25.png";
import Img3 from "../../../images/guide/image17.png";
import Img4 from "../../../images/guide/image5.png";
import Img5 from "../../../images/guide/image19.png";
import Img6 from "../../../images/guide/image18.png";
import Img7 from "../../../images/guide/image21.png";
import Img8 from "../../../images/guide/image33.png";
import Img9 from "../../../images/guide/image29.png";
import Img10 from "../../../images/guide/image30.png";
export const CrowdsaleGuide = () => {
  return (
    <div className="mx-3">
      <p className="text-justify">
        In Zeptagram your main motive is to create a live funding/selling
        campaign. Hence once you have sent your song/album for verification,
        wait until we mail you on the verification.
        <br /> Once you receive the mail, login to your Zeptagram account and
        click ‘My Crowdsale’. There you can see the verified music list in
        ‘Create Crowdsale/Crowdfunding List’. Your next goal is to create a
        successful campaign. Hence click that particular listing.
      </p>
      <img className="guide-img" src={Img1} />
      <p className="text-justify">
        On clicking ,you will be landed on ‘Add Crowdsale’ Popup. *** You have
        to be a verified publisher. For this you have to submit Publisher
        KYC.See KYC Guide.***
      </p>
      <ul>
        <li>
          <b>Targeted Amount:</b> The amount you want to raise. It should be in
          dollars. You can’t create a target less than 500$ in Zeptagram.
        </li>
        <li>
          <b>Percentage Rights to list:</b> It is the % of the ownership of the
          song you want to shift to your investors in lieu of the amount raised
        </li>
        <li>
          <b>Minimum Contribution:</b> Minimum Contribution is a check you want
          to have for your investors on the minimum amount of investment. Ex: If
          you set the minimum amount to be 10$, then no investor less than 10$
          can invest in your song.
        </li>
        <li>
          <b>Royalty Duration:</b> The duration of years you want your investors
          to receive royalty in lieu of their investment. Once the loyalty term
          ends then the ownership rights will be returned to you
        </li>
        <li>
          <b>Start Date:</b> Starting date of campaign to be live. You can start
          the campaign at any date, if not today
        </li>
        <li>
          <b>End Date</b>: Ending date of the live campaign
        </li>
      </ul>
      <img className="guide-img guide-md" src={Img2} />
      <p className="text-justify">
        Once you fill this form, and then send it again for verification.On
        Success ,you will be able to see your crowdsale/crowdfund in
        ‘Crowdsale/Crowdfunding List’ .
      </p>
      <img className="guide-img" src={Img3} />
      <img className="guide-img" src={Img4} />
      <p className="text-justify">
        Click on that particular Crowdsale, you will be presented with a dialog
        , where you have to upload your agreement docs to proceed. Click on
        ‘Upload Agreement’.
      </p>
      <img className="guide-img" src={Img5} />
      <p className="text-justify">
        Post agreement upload ,you have to send Crowdsale for verification.
      </p>
      <img className="guide-img" src={Img6} />
      <br />
      <h5 className="text-dark">Deploying Crowd-sale/Crowd-funding</h5>
      <p className="text-justify">
        Now, your crowd-sale/funding is created and verified, but it’s not done.
        You need to deploy it on our Blockchain network. So a few more steps
        ahead! Now click on Deploy Crowdfunding/Crowdsale. You will be directed
        to authenticate the transaction. Deploying Crowd-sale/Crowd-funding
      </p>
      <ul>
        <li>Enter your PIN on this screen and submit!</li>
      </ul>
      <p className="text-justify">
        You are done! You can now click back to the Home page and see your live
        campaign there. Also you will receive a confirmation mail from
        Zeptagram!
      </p>
      <img className="guide-img" src={Img7} />
      <img className="guide-img" src={Img8} />
      <p className="text-justify">
        Post deploy you will see the state of crowdsale is changed in
        ‘Crowdsale/Crowdfunding List’.
      </p>
      <img className="guide-img" src={Img9} />
      <p className="text-justify">
        You can also see your Campaign on the Home page . Before the start date
        it will be in ‘Upcoming Crowdsale Campaigns’.After start date you can
        see them in ‘Live Crowdsale Campaigns’.
      </p>
      <img className="guide-img" src={Img10} />
      <p className="text-justify">
        For Crowdfund also all the steps would be the same and listed will be in
        their respective Crowdfunding List.
      </p>
    </div>
  );
};
