import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import InfoToast from "../../components/ToastMessage/InfoToast";
import Band from "../../images/band.svg";
import Girl from "../../images/lady.svg";
import Neon from "../../images/neonbrand.svg";
import { freighterSignupStart } from "../../redux/user/Action";
import { setAllowed, getUserInfo } from "@stellar/freighter-api";

export default function ProfileRegistrationFreighter(props) {
  const [email, setEmail] = useState("");
  const [user, setUser] = useState("");
  const [publickey, setPublickey] = useState("");
  const dispatch = useDispatch();

  const loading = useSelector((state) => state.user.signuploading);

  async function handleSubmit(event) {
    event.preventDefault();

    let userregex = /^[a-zA-Z ]*$/;
    let emailregex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    const trimmedUser = user.trim(); // Trim the user input
    const trimmedEmail = email.trim(); // Trim the email input

    if (!userregex.test(trimmedUser)) {
      InfoToast("Please Enter a Valid User Name");
      return;
    }

    if (trimmedEmail.length > 50) {
      // Use trimmedEmail here
      InfoToast("Email allows a maximum of 50 characters");
      return;
    }

    if (!emailregex.test(trimmedEmail)) {
      // Use trimmedEmail here
      InfoToast("Please Enter a Valid Email");
      return;
    }

     const retrieveUserInfo = async () =>
        {
            try
            {
                let userInfo = await getUserInfo();

                if (!userInfo.publicKey)
                {
                    await setAllowed();
                    userInfo = await getUserInfo();
                }

                return userInfo.publicKey;
            } catch (e)
            {
                return e;
            }
        };
        const publickey = await retrieveUserInfo();


    const data = {
      email: trimmedEmail, // Use trimmedEmail here
      fullName: trimmedUser, // Use trimmedUser here
      publickey: publickey,
    };

    console.log("DATA FROM PROFILE REGISTRATION", data);

    dispatch(freighterSignupStart(data));
  }

  return (
    <div className="row m-auto w-100 h-100">
      <div className="col-md-6 ">
        <div className="align-center h-100">
          <div className="d-flex flex-column justify-content-center">
            <form
              className="d-flex flex-column"
              onSubmit={handleSubmit}
              autoComplete="nope"
            >
              <span className="heading-medium mb-2 align-self-baseline">
                Profile Setup
              </span>

              <input
                type="text"
                className="input-box my-3"
                value={user}
                tabIndex={1}
                minLength={2}
                maxLength={50}
                autoFocus
                onChange={(e) => setUser(e.target.value)}
                placeholder="Name"
                required
                autoComplete="nope"
              />
              <input
                type="email"
                className="input-box my-3"
                value={email}
                minLength={2}
                maxLength={50}
                tabIndex={1}
                onChange={(e) => setEmail(e.target.value)}
                placeholder="Email"
                required
                autoComplete="nope"
              />

              <div className="align-center">
                <button
                  className="button-medium button-reverse border-shadow m-2 my-4"
                  type="submit"
                >
                  {!loading ? (
                    "Submit"
                  ) : (
                    <>
                      <span
                        className="spinner-border spinner-border-smd"
                        role="status"
                        aria-hidden="true"
                      ></span>
                    </>
                  )}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
      <div className="col-md-6 image-container">
        <span className="verify-image verify-image-1">
          <img src={Neon} alt="" />
        </span>
        <span className="verify-image verify-image-2">
          <img src={Band} alt="" />
        </span>
        <span className="verify-image verify-image-3">
          <img src={Girl} height={380} className="girl-img" alt="" />
        </span>
      </div>
    </div>
  );
}
