import React from "react";
import { Button, OverlayTrigger, Tooltip } from "react-bootstrap";

const InfoBox = ({ msg = "", title,trigger }) => {
  return (
    <OverlayTrigger
      placement="bottom"
      trigger={trigger ? trigger : "click"}
      overlay={<Tooltip id="button-tooltip-2">{msg}</Tooltip>}
    >
      {({ ref, ...triggerHandler }) => (
        <Button
          variant="white"
          {...triggerHandler}
          className="p-1 d-inline-flex align-items-center justify-content-center f-14 text-dark cursor-pointer"
        >
          <i ref={ref} className="fas fa-info-circle box-shadow-hover"></i>
        </Button>
      )}
    </OverlayTrigger>
  );
};

export default InfoBox;
