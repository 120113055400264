import React from "react";
import Img1 from "../../../images/guide/image2.png";
import Img2 from "../../../images/guide/image13.png";
export const PopularOffering = () => {
  return (
    <div className="mx-3">
      <p>There are special listing opportunities which have been popular.</p>
      <p>It contains:</p>
      <ul>
        <li>
          <b>Album Cover</b>: An image is displayed for the respective album
          cover of the listed offering
        </li>
        <li>
          <b>Title</b>: Name of the media file
        </li>
        <li>
          <b>Name</b> of the publisher
        </li>

        <li>
          <b>Amount raised against the claimed amount</b>: The scale shows how
          much investment is made till now
        </li>
      </ul>
      <img className="guide-img guide-sm" src={Img1} />
      <br />
      <p> The crowdsale/crowdfunding details includes:</p>
      <ul>
        <li>
          <b>Token Name</b>: It is the MTO name that you can see in the trading
          page
        </li>
        <li>
          <b>Shareholder Name</b>: Name of the publisher
        </li>
        <li>
          <b>Content Type</b>: It is the type of media file uploaded for
          investment
        </li>
        <li>
          <b>State</b>: It is the status of the crowd-sale/funding. In this case
          it will be crowd-funding.
        </li>
        <li>
          <b>Percentage Rights</b>: % of the ownership offered by publisher to
          investors
        </li>
        <li>
          <b>Per Percent Amount</b>: It is the per percent of the target amount
          (like here target amount is 5000$, so Per percent Amount is 50$)
        </li>
        <li>
          <b>Soft-cap</b>: The minimum % of crowdsale raised to make it
          successful
        </li>
        <li>
          <b>Min-contribution</b>: The minimum permissible amount of investment
          that an investor needs to invest. This is set by the crowdsale/fund
          creator.
        </li>
        <li>
          <b>Target Amount</b>: The final amount for a successful
          crowd-sale/funding
        </li>
        <li>
          <b>Crowdsale Type</b>: It says whether the listing is a crowdsale or a
          crowdfund.
        </li>
        <li>
          <b>Start-date</b>: Date at which this crowdsale has began
        </li>
        <li>
          <b>End-date</b>: date at which this crowdsale will end
        </li>
        <li>
          <b>Duration</b>: The play- duration of the media uploaded
        </li>
        <li>
          {" "}
          <b>Zeptacoins raised</b>: Number of Zeptacoin raised against the
          present investment
        </li>
        <li>
          <b>Fiat($) raised</b>: Equivalent USD($) raised
        </li>
        <li>
          <b>Listen(symbol)</b> : Clicking this you can stream the listed media
          file
        </li>
        <li>
          <b>Social media icons</b> : For sharing the live crowdsale information
          in social medias
        </li>
      </ul>
      <img className="guide-img" src={Img2} />
    </div>
  );
};
