import { useState } from "react";
import { Modal, Form } from "react-bootstrap";
import { SubmitButton } from "../../components/components";
import PinInput from "react-pin-input";
const ConfirmPinModal = ({ show, setShow, title, onSubmit }) => {
  const [pin, setPin] = useState("");
  //show={pageState["pinModal"]}
  return (
    <Modal
      show={show}
      onHide={() => {
        setShow();
        //dispatchState({type: "showPinModal"})
      }}
    >
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Label>Enter your Pin</Form.Label>
          <PinInput
            className="mx-auto"
            length={4}
            // initialValue={pin}
            secret
            onChange={(value, index) => setPin(value)}
            type="numeric"
            inputMode="number"
            style={{ padding: "10px", marginLeft: "auto" }}
            inputStyle={{ borderColor: "#daca9e" }}
            inputFocusStyle={{ borderColor: "blue" }}
            onComplete={(value, index) => {}}
            required
          />
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <SubmitButton
          onClick={() => {
            if (pin.length > 4) {
              //todo error
              return;
            }
            onSubmit(pin);
          }}
          title={"Confirm"}
        />
      </Modal.Footer>
    </Modal>
  );
};

export default ConfirmPinModal;
