import React, { useState } from "react";
import { Modal, Form } from "react-bootstrap";
import { ToggleSwitch } from "../Buttons/ToggleSwitch/ToggleSwitch";
import { useEffect } from "react";
import { investCrowdsaleStart } from "../../redux/crowdsale/Action";
import InfoToast from "../ToastMessage/InfoToast";
import { useDispatch, useSelector } from "react-redux";
import PinInput from "react-pin-input";
import { crowdsaleApis, userApis } from "../../redux/api";
import axios from "axios";
import
{
    getPublicKey,
    isConnected,
    isAllowed,
    setAllowed,
    getUserInfo,
    signAuthEntry,
    signTransaction,
    signBlob,
} from "@stellar/freighter-api";
import InfoBox from "../InfoMessage/InfoHover";

const InvestCrowdsale = ({ show, setShow, text, liveprice, crowdsaledata }) => {
  const [terms, setTerms] = useState(false);
  const [amount, setAmount] = useState("");
  const [pin, setPin] = useState("");
  const [toggle, setToggle] = useState("crypto");
  const [minInvest, setMinInvest] = useState(0);
  const [maxInvest, setMaxInvest] = useState(0);
  const [type, setType] = useState("Crowdsale");
  const dispatch = useDispatch();
  const handleClose = () => setShow(false);
  const [isFreighter, setIsFreighter] = useState(false)
  const [freighterKey, setFreighterKey] = useState("")
const [xdr, setXdr] = useState(false)
const [xdr_value, setXdr_value] = useState("...")
  const investloading = useSelector((state) => state.crowdsale.investloading);


  useEffect(() => {
  const retrieveUserInfo = async () => {
    try {
      let userInfo = await getUserInfo();

      if (!userInfo.publicKey) {
        await setAllowed();
        userInfo = await getUserInfo();
      }

      return userInfo.publicKey;
    } catch (e) {
      console.error(e); // It's a good practice to log errors
      return null;
    }
  };

  retrieveUserInfo().then(publicKey => {
    console.log("USER INFO============", publicKey);
    setFreighterKey(publicKey);
  }).catch(error => {
    console.error("Error in retrieving user info: ", error);
  });
}, []);

  const changeToggle = () => {
    if (toggle === "fiat") {
      setToggle("crypto");
    } else {
      InfoToast('Fiat option is disabled temporarily.')
      // setToggle("fiat");
    }
  };

  useEffect(() => {
    setMinInvest(crowdsaledata.minContributionByUser);
    setMaxInvest(crowdsaledata.maxContributionByUser);
    if (crowdsaledata && crowdsaledata.musicId) {
      if (crowdsaledata.musicId.musicType === "new") {
        setType("Crowdfund");
      } else {
        setType("Crowdsale");
      }
    }
    // percentageRights: item.percentageRights * item.rate,
  }, [crowdsaledata]);

  //handle invest----------------------
  const handleInvestInCrowdsale = async(event) => {
    event.preventDefault();
    if (terms) {
      // if (toggle === "crypto" ) {

      // } else {
        let data = {};

        if (parseInt(amount) <= 0) {
          InfoToast(`Please enter valid amount`);
          return;
        }

        if (toggle === "crypto") {
          data.amount = amount * liveprice.data.zeptaPrice;
        } else {
          data.amount = amount;
        }

        data.crowdsaleId = crowdsaledata._id;
        data.pin = pin;

        if (toggle === "crypto") {
          if (data.amount < minInvest) {
            InfoToast(
              `Amount must be greater than or equal to minimum investment ie. ${minInvest} $`
            );
          } else {
            let dataTelos = {};
            dataTelos.amount = amount;
            dataTelos.crowdsaleId = data.crowdsaleId;
            dataTelos.pin = pin;
            // dispatch(investCrowdsaleStart({ data: dataTelos, type: "crypto" }));
          }
        } else if (toggle === "fiat") {
          if (amount > 15000) {
            InfoToast(
              `Amount must be less than or equal to $15000 in fiat transactions.`
            );
            return;
          }
          // if (minInvest > 15000) {
          //   InfoToast(
          //     `Minimum Investment must be less than or equal to $15000`
          //   );
          // }
          if (amount < minInvest) {
            InfoToast(
              `Amount must be greater than or equal to  minimum investment ie. ${minInvest} $`
            );
          } else if (amount < 10) {
            InfoToast(`Min Amount must be $ 10`);
          } else {
            let dataFiat = {};
            let amountSend =
              Number(amount) + (amount / 100) * 2.5 + (amount / 100) * 5;
            dataFiat.amount = Math.round(amountSend * 100) / 100;
            dataFiat.actualAmount = amount;
            dataFiat.crowdsaleId = data.crowdsaleId;
            dispatch(investCrowdsaleStart({ data: dataFiat, type: "fiat" }));
          }
        }
         if(await isConnected()){
           let dataFreighter = {};
            let amountSend =
              Number(amount) + (amount / 100) * 2.5 + (amount / 100) * 5;
            dataFreighter.amount = Math.round(amountSend * 100) / 100;
            const actualAmount = amount;
            dataFreighter.crowdsaleId = data.crowdsaleId;
            dataFreighter.xdr = xdr
            dataFreighter.xdr_value = xdr_value
          if (amount > 15000) {
            InfoToast(
              `Amount must be less than or equal to $15000 in fiat transactions.`
            );
            return;
          }
          if (amount < minInvest) {
            InfoToast(
              `Amount must be greater than or equal to  minimum investment ie. ${minInvest} $`
            );
          } else if (amount < 10) {
            InfoToast(`Min Amount must be $ 10`);
          } else {
            
      const token = localStorage.getItem("token")
      const responseBefore = await axios({
          method: "post",
          url: crowdsaleApis.SOROBAN_INVEST,
          data: {
            amount: dataFreighter.amount,
            crowdsaleId: dataFreighter.crowdsaleId,
            xdr: dataFreighter.xdr,
            xdr_value: dataFreighter.xdr_value
          },
          headers: {
            "x-auth-token": token
          }
        });

        console.log("RESPONSE BEFORE", responseBefore)

        if(responseBefore.data.success){
        const userSignTransaction = async (xdr) => {
            try {
                console.log("XDR++++++++++++++++++", xdr);
                const signedTransaction = await signTransaction(xdr,{
                  network:"FUTURENET"
                });
                return { signedTransaction };
            } catch (e) {
                console.error("Error signing transaction:", e);
                return { error: e };
            }
        };

        const userSignedTransaction = await userSignTransaction(responseBefore.data.xdr);

        if (userSignedTransaction.error) {
            console.error("Error signing transaction:", userSignedTransaction.error);
        } else {
            console.log("----------Signed Transaction---------:", userSignedTransaction.signedTransaction);

                dataFreighter.xdr= true
                dataFreighter.xdr_value = userSignedTransaction.signedTransaction
                console.log("===========FREIGHTER XDR DATA AFTER SIGN TRANSAACTIONS==============", dataFreighter, "VALUE", userSignedTransaction.signedTransaction)
                dispatch(investCrowdsaleStart({ data: dataFreighter, type: "Freighter" }));
          }
        }
          }
        }
      // }
    } else {
      InfoToast("Please agree to the terms to invest");
    }
  };

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Invest in {type}   </Modal.Title>
      </Modal.Header>
      <Form onSubmit={handleInvestInCrowdsale}>
        <Modal.Body className="p-5">
          <div className="d-flex align-items-center justify-content-center flex-wrap m-2 mb-5">
            <div className="text-dark mx-3">Fiat($)</div>
            <ToggleSwitch value={toggle} setValue={changeToggle} />
            <div className="text-dark mx-3">XLM</div>
          </div>
          <label className="text-dark" style={{ marginLeft: "10px" }}>
            Enter your Amount({toggle === "fiat" ? "" : "XLM"} incl. VAT)
          </label>
          <Form.Control
            className={"input-box-sm"}
            type="number"
            onWheel={(e) => e.target.blur()}
            name="amount"
            value={amount}
            onChange={(e) => {
              if (e.target.value.length <= 9) {
                setAmount(e.target.value);
              }
            }}
            placeholder="Amount"
            autoComplete="false"
            maxLength="11"
            required
          />
          <div style={{ fontSize: "12px" }} className=" text-dark text-end">
            ~{" "}
            {toggle === "fiat"
              ? amount
              : (amount * liveprice.data.zeptaPrice).toFixed(2)}{" "}
            USD
          </div>
          <label className="text-dark" style={{ marginLeft: "10px" }}>
            Amount + {toggle === "fiat" ? 7.5 : 5}% fee ($)
          </label>
          <Form.Control
            className={"input-box-sm"}
            value={
              amount
                ? (toggle === "fiat"
                    ? amount * 1.075
                    : amount * liveprice.data.zeptaPrice * 1.05
                  ).toFixed(2)
                : ""
            }
            type="number"
            disabled
            onWheel={(e) => e.target.blur()}
            placeholder="Total Amount"
          />

          <div>
            {toggle === "fiat" ? (
              ""
            ) : (
              <>
                {/* <label className="text-dark" style={{ marginLeft: "10px" }}>
                  Enter your wallet pin
                </label>
                <div className="text-center">
                  <PinInput
                    className="mx-auto"
                    length={4}
                    initialValue={pin}
                    secret
                    onChange={(value, index) => setPin(value)}
                    type="numeric"
                    inputMode="number"
                    style={{ padding: "10px", marginLeft: "auto" }}
                    inputStyle={{ borderColor: "#daca9e" }}
                    inputFocusStyle={{ borderColor: "blue" }}
                    onComplete={(value, index) => {}}
                    required
                  />
                </div> */}
              </>
            )}

            <Form.Check
              type="checkbox"
              onClick={(e) => setTerms(e.target.checked)}
              label={
                <span>
                  I agree to the
                  <a
                    href={
                      type === "Crowdfund"
                        ? "https://zeptaterms.s3.amazonaws.com/69+C+Crowdfunding+Campaigns+Zeptagram+AB-3.docx"
                        : "https://zeptagram-test2.s3.eu-north-1.amazonaws.com/sellerandbuyer-converted.pdf"
                    }
                    target="_blank"
                    className="text-dark cur-pointer"
                    rel="noreferrer"
                  >
                    {" "}
                    Zeptagram {type} Agreement
                  </a>
                </span>
              }
              required
            />
          </div>
        </Modal.Body>
        <Modal.Footer className="p-3">

        {!investloading ? (
            <button
            className="button-medium border-shadow  medium-btn"
            type="submit"
            disabled={investloading}
            title={"Submit"}
          >Submit</button>
          ) : (

            <span
                  className="spinner-border spinner-border-smd"
                  role="status"
                  aria-hidden="true"
                ></span>

          )}
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

export default InvestCrowdsale;
