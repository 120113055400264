import React from "react";
import "react-toastify/dist/ReactToastify.css";
import "./SuccessToast.scss";
export const SuccessToast = ({ message }) => {
  return (
    <div className="toast-cont ml-3">
      <div className="">
        <i className="far fa-check-circle fa-2x color-success m-2"></i>
      </div>
      <div className="ml-3">
        {typeof message === "string" ? message : "Successful"}
      </div>
    </div>
  );
};

export const ErrorToast = ({ message }) => {
  return (
    <div className="toast-cont ml-3">
      <div className="">
        <i className="far fa-times-circle fa-2x color-error m-2"></i>
      </div>
      <div className="ml-3">
        {typeof message === "string"
          ? message
          : "Please try again after sometime"}
      </div>
    </div>
  );
};

export const SuccessMessage = ({ message }) => {
  return (
    <div className="success_modal_body custom-shadow-box">
      <div className="success_error_notification_image">
        <i className="far fa-check-circle fa-7x success_icon"></i>
      </div>
      <div className="success_notification_body">{message}</div>
    </div>
  );
};
