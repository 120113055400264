import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import HomePage from "../HomePage/HomePage";
import {
  popularcrowdsalepayload,
  livecrowdsalepayload,
  livecrowdfundpayload,
  finalizedcrowdsalepayload,
  finalizedcrowdfundpayload,
  successfulcrowdsalepayload,
  recommendcrowdsalepayload,
} from "../HomePage/crowdsaleparams";
import {
  finalizedCrowdfundStart,
  finalizedCrowdsaleStart,
  getLivePriceStart,
  liveCrowdfundStart,
  liveCrowdsaleStart,
  popularCrowdsaleStart,
  successfulCrowdsaleStart,
  recommendCrowdsaleStart,
} from "../../redux/crowdsale/Action";
import { Spinner } from "react-bootstrap";

const Home = ({ ual }) => {
  const dispatch = useDispatch();
  const { popularcrowdsale, livecrowdsale } = useSelector(
    (state) => state.crowdsale
  );
  useEffect(() => {
    dispatch(popularCrowdsaleStart(popularcrowdsalepayload));
    dispatch(recommendCrowdsaleStart(recommendcrowdsalepayload));
    dispatch(liveCrowdsaleStart(livecrowdsalepayload));
    dispatch(liveCrowdfundStart(livecrowdfundpayload));
    dispatch(finalizedCrowdfundStart(finalizedcrowdfundpayload));
    dispatch(finalizedCrowdsaleStart(finalizedcrowdsalepayload));
    dispatch(successfulCrowdsaleStart(successfulcrowdsalepayload));
    dispatch(successfulCrowdsaleStart(successfulcrowdsalepayload));
    dispatch(getLivePriceStart({}));
  }, []);

  return (
    <>
      {livecrowdsale && popularcrowdsale ? (
        <div className="wrapper">
          <HomePage ual={ual} />
        </div>
      ) : (
        <Spinner
          hidden={false}
          className="position-center"
          animation="border"
        />
      )}
      {/* <Spinner hidden={false} className={"uploadSpinner"} animation="border" /> */}
    </>
  );
};

export default Home;
