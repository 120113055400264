import React, { useEffect, useState } from "react";
import { Form, Modal } from "react-bootstrap";
import InfoBox from "../../components/InfoMessage/InfoHover";
import { ToggleSwitch } from "../../components/Buttons/ToggleSwitch/ToggleSwitch";
import { SubmitButton } from "../../components/components";
import { userApis } from "../../redux/api";
import { isNotValid, notifyError, notifySuccess } from "../../utils/utils";
import { useSubmit } from "../../api/api_client";
import BlockchainService from "../../services/BlockchainService";
import { toast } from "react-toastify";
import {
  ErrorToast,
  SuccessToast,
} from "../../components/ToastMessage/SuccessToast";
const blockchainService = new BlockchainService();
export default function WithdrawModal({
  showWithdrawModal,
  closeModal,
  livePrices,
  ual,
}) {
  const [pageState, setpageState] = useState({
    withdrawCryptoData: {},
    withdrawFlatData: {},
    withdrawType: "crypto",
  });
  const [withdrawLoading, setWithdrawLoading] = useState(false);

  //   useEffect(() => {
  //     if (pageState.withdrawType === "crypto") {
  //       setpageState({ ...pageState, withdrawFlatData: {} });
  //     } else {
  //       setpageState({ ...pageState, withdrawCryptoData: {} });
  //     }
  //     console.log("kwdqhgsjHGbefwjfksm")
  //   }, [pageState.withdrawType]);
  const { eosPrice, zeptaPrice } =
    (livePrices["data"] && livePrices["data"].data) || {};
  const telosInZepta = eosPrice && (zeptaPrice / eosPrice).toFixed(4);
  const zeptaInTelos = zeptaPrice && (eosPrice / zeptaPrice).toFixed(4);

  const handleWithdrawDataChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    if (pageState["withdrawType"] == "crypto") {
      const cryptoData = pageState["withdrawCryptoData"];
      cryptoData[name] = value;
      //   dispatchAction("setWithdrawCryptoData", cryptoData);
      setpageState({ ...pageState, ...cryptoData });
      if (value == "") {
        cryptoData["zeptacoin"] = "";
        cryptoData["telos"] = "";
      } else if (name == "zeptacoin") {
        var zeptacoin = Number.parseFloat(value);
        var telos = zeptacoin * telosInZepta; //TODO conversion over here
        cryptoData["telos"] = telos;
      } else {
        var telos = Number.parseFloat(value);
        var zeptacoin = telos * zeptaInTelos; //TODO conversion over here
        cryptoData["zeptacoin"] = zeptacoin;
      }
      //   dispatchAction("setWithdrawCryptoData", cryptoData);
      setpageState({ ...pageState, ...cryptoData });
    } else {
      const flatData = pageState["withdrawFlatData"];

      if (value == "") {
        flatData["zeptacoin"] = "";
        flatData["usd"] = "";
      } else if (name == "zeptacoin") {
        flatData[name] = Number.parseFloat(value);
        var zeptacoin = Number.parseFloat(value);
        var usd = Number.parseFloat(zeptaPrice) * zeptacoin; //TODO conversion over here
        flatData["usd"] = usd;
      } else {
        flatData[name] = Number.parseFloat(value);
        var usd = Number.parseFloat(value);
        var zeptacoin = usd / Number.parseFloat(zeptaPrice); //TODO conversion over here
        flatData["zeptacoin"] = zeptacoin;
      }

      // dispatchAction("setWithdrawFlatData", flatData);
      setpageState({ ...pageState, ...flatData });
    }
  };
  // -------------------------withdraw ---------------------------------------------
  const withdrawCoins = async () => {
    console.log("consolwnefwhdbjs", "rewfegfsujbfgb");
    const type = pageState["withdrawType"];
    const data = {};
    let url = userApis.WITHDRAW_CRYPTO_ANCHOR;

    if (type === "crypto") {
      data["quantity"] = pageState["withdrawCryptoData"].zeptacoin;
    } else {
      url = userApis.WITHDRAW_FIAT_ANCHOR;
      data["amount"] = `${pageState["withdrawFlatData"].zeptacoin}`;
    }
    if (
      type === "crypto" &&
      (isNotValid(data["quantity"]) || data["quantity"] === 12)
    ) {
      notifyError("Enter valid quantity");
      return;
    }
    console.log(data,"Defwvpfeadbsuhjvfbrvfhj")

    setWithdrawLoading(true);
    // eslint-disable-next-line react-hooks/rules-of-hooks
    await useSubmit(url, data, "POST")
      .then(async (response) => {
        if (response.tnx && response.success) {
          const result = await blockchainService.pushTransaction(
            response.tnx,
            ual,
            "zptc_withdraw",
            response.optionalData
          );
          if (result.success) {
            setWithdrawLoading(false);
            notifySuccess("Successfully sent");
            closeModal(false);
            setpageState({
              withdrawCryptoData: {},
              withdrawFlatData: {},
              withdrawType: "crypto",
            });
          } else {
            toast.error(<ErrorToast message={result.message} />, {
              toastId: 1,
              updateId: 1,
            });
          }
        }

        // closeModal(false)
      })
      .catch((e) => {
        notifyError(e.message);
        setWithdrawLoading(false);
        // dispatchState({ type: "setWithdrawStatus" });
      });
  };
  return (
    <Modal
      show={showWithdrawModal}
      onHide={() => {
        closeModal(false);
        setWithdrawLoading(false)
        setpageState({
          withdrawCryptoData: {},
          withdrawFlatData: {},
          withdrawType: "crypto",
        });
      }}
    >
      <Modal.Header closeButton>
        <Modal.Title className="text-dark">Withdraw</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <div className="d-flex align-items-center justify-content-center flex-wrap m-2">
            <div className="text-dark mx-3">Crypto</div>
            <ToggleSwitch
              setValue={() => {
                setpageState({
                  ...pageState,
                  withdrawType:
                    pageState["withdrawType"] === "crypto" ? "flat" : "crypto",
                });
              }}
            />
            <div className="text-dark mx-3">Fiat($)</div>
          </div>
          <label className="input-label">
            Zeptacoin*{" "}
            <InfoBox
              className="ml-1"
              msg={"Enter amount of Zeptacoin you want to withdraw."}
            />{" "}
          </label>
          <Form.Control
            className={"input-box-sm"}
            type="number"
            onWheel={(e) => e.target.blur()}
            name="zeptacoin"
            placeholder="Zeptacoin"
            value={
              pageState["withdrawType"] === "crypto"
                ? pageState["withdrawCryptoData"].zeptacoin
                : pageState["withdrawFlatData"].zeptacoin
            }
            onChange={handleWithdrawDataChange}
          />
          <label
            hidden={pageState["withdrawType"] != "crypto"}
            className="input-label"
          >
            Telos <InfoBox className="ml-1" msg={"Equivalence in Telos"} />{" "}
          </label>
          <Form.Control
            className={"input-box-sm"}
            hidden={pageState["withdrawType"] != "crypto"}
            type="number"
            onWheel={(e) => e.target.blur()}
            value={pageState["withdrawCryptoData"].telos}
            placeholder="Telos"
            name="telos"
            onChange={handleWithdrawDataChange}
          />
          <label
            hidden={pageState["withdrawType"] === "crypto"}
            className="input-label"
          >
            USD <InfoBox className="ml-1" msg={"Equivalence in USD"} />{" "}
          </label>
          <Form.Control
            value={pageState["withdrawFlatData"].usd}
            hidden={pageState["withdrawType"] === "crypto"}
            className={"input-box-sm"}
            type="number"
            placeholder="USD"
            onWheel={(e) => e.target.blur()}
            name="usd"
            onChange={handleWithdrawDataChange}
          />
          <label
            hidden={pageState["withdrawType"] != "crypto"}
            className="input-label"
          >
            Telos(after 2.5% deduction)
          </label>
          <Form.Control
            className={"input-box-sm"}
            value={
              pageState["withdrawCryptoData"]["telos"] != ""
                ? pageState["withdrawCryptoData"]["telos"] -
                  (pageState["withdrawCryptoData"]["telos"] * 2.5) / 100
                : ""
            }
            hidden={pageState["withdrawType"] != "crypto"}
            type="number"
            onWheel={(e) => e.target.blur()}
            disabled
            placeholder="Telos(after 2.5% deduction)"
            name="telos_after_"
          />
          <label
            hidden={pageState["withdrawType"] == "crypto"}
            className="input-label"
          >
            USD(after 2.5% deduction)
          </label>
          <Form.Control
            value={
              pageState["withdrawFlatData"]["usd"] != ""
                ? pageState["withdrawFlatData"]["usd"] -
                  (pageState["withdrawFlatData"]["usd"] * 2.5) / 100
                : ""
            }
            className={"input-box-sm"}
            type="number"
            onWheel={(e) => e.target.blur()}
            disabled
            hidden={pageState["withdrawType"] == "crypto"}
            placeholder="USD(after 2.5% deduction)"
            name="usd_after_"
          />
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <SubmitButton
          onClick={() => {
            // console.log(pageState);
            withdrawCoins();
            // dispatchState({ type: "showWithdrawModal" });
            // setTimeout(() => {
            //     dispatchAction("showPinModal");
            // }, 400);
          }}
          title={"Withdraw"}
          loading={withdrawLoading}
        />
      </Modal.Footer>
    </Modal>
  );
}
