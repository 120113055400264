import React, { useState, useEffect } from "react";
import CrowdsaleGraph from "../../components/Chart/Chart";
import { DropdownButton, Form, Dropdown, Spinner } from "react-bootstrap";
import { getRequiredProtectedData } from "../../redux/apicalltemplate";
import { crowdsaleApis } from "../../redux/api";
import CrowdsalePermissions from "./CrowdsalewithPermissions";
import AddCrowdsale from "./AddCrowdsale";
import moment from "moment";
import { useSelector } from "react-redux";
import { crowdsaleparams, musicparams } from "./helper";
import UploadCrowdsale from "./UploadCrowdsale";
import ExtendCrowdsale from "./ExtendCrowdsale";
import DeployCrowdsale from "./DeployCrowdsale";
import PaginationBar from "../../components/Pagination/Navbar";
import NewCrowdsales from "./NewCrowdsale";
import SwalError from "../../components/ToastMessage/SwalError";
import { useHistory } from "react-router-dom";
import NoRecordTable from "../../components/NoRecordFound/NoRecordTable";
import InfoBox from "../../components/InfoMessage/InfoHover";
import { contentTypeList, listingTypeList, stateList } from "../../utils/utils";

const MyCrowdsale = ({ setBookDetails, setMtoName }) => {
  const [listSearch, setListSearch] = useState(false);
  const [searchbook, setSearchBook] = useState("");
  const [analytics, setAnalytics] = useState("");
  const [createCrowdsaleTable, setCreateCrowdsaleTable] = useState(false);
  const [createloading, setCreateLoading] = useState(false);
  const [crowdsaletable, setCrowdsaleTable] = useState(false);
  const [crowdloading, setCrowdloading] = useState(false);
  const [conversionValue, setConversionValue] = useState("");
  const [crowdsalegraph, setCrowdsaleGraph] = useState("");
  const [loadgraph, setLoadGraph] = useState(false);
  const [timeLimit, setTimeLimit] = useState("day");
  const [addcrowdsale, setAddCrowdsale] = useState(false);

  const [saleType, setSaleType] = useState("Crowdsale");

  const [showcrowdsale, setShowCrowdsale] = useState(false);
  const [modaldata, setModalData] = useState("");
  const [musicId, setMusicId] = useState("");

  //----reload table-------------
  const [reloadcrowdsale, setReloadCrowdsale] = useState(false);
  const [pagecrowdsale, setPageCrowdsale] = useState(1);

  //-----reload create table
  const [reloadmusic, setReloadMusic] = useState(false);
  const [pagemusic, setPageMusic] = useState(1);

  //--------upload-----------------
  const [showupload, setShowUpload] = useState(false);
  //--------extend-----------------
  const [showextend, setShowExtend] = useState(false);
  //--------deploy-----------------
  const [showdeploy, setShowDeploy] = useState(false);
  //--------edit-----------------
  const [showedit, setShowEdit] = useState(false);

  const [totalcrowdsale, setTotalCrowdsale] = useState(0);
  const [totalmusic, setTotalMusic] = useState(0);

  const userdetails = useSelector((state) => state.user.data);

  const history = useHistory();

  const [searchEnable, setSearchEnable] = useState(false);
  const [searchEnableList, setSearchEnableList] = useState(false);
  const [content, setContent] = useState("");
  const [contentType, setContentName] = useState("");
  const [listingName, setListingName] = useState("");
  const [listingType, setListingType] = useState("");
  const [statetype, setStateType] = useState("");
  const [availableRights, setAvailableRights] = useState("");
  const clearParamsList = () => {
    setStateType("");
  };
  const handleReloadMusic = () => {
    setPageMusic(1);
    setReloadMusic(!reloadmusic);
  };

  const handleReloadCrowdsale = () => {
    setPageCrowdsale(1);
    setReloadCrowdsale(!reloadcrowdsale);
  };

  //for graphical data------------------------
  useEffect(async () => {
    setLoadGraph(true);
    const params = {
      isuuer: null,
    };

    const responsetable = await getRequiredProtectedData(
      "GET",
      `${crowdsaleApis.GET_CROWDSALE_GRAPH}`,
      {},
      params
    );
    if (responsetable && responsetable.data) {
      setCrowdsaleGraph(responsetable.data.data);
    }
    setLoadGraph(false);
  }, []);

  //for analytics---------------
  useEffect(async () => {
    const responsetable = await getRequiredProtectedData(
      "GET",
      `${crowdsaleApis.GET_ANALYTICS}`,
      {},
      {}
    );
    if (responsetable && responsetable.data) {
      setAnalytics(responsetable.data.data);
    }
  }, []);

  //for crowdsale  table--------------------------
  useEffect(async () => {
    setCrowdloading(true);
    const params = crowdsaleparams;
    const responsetable = await getRequiredProtectedData(
      "GET",
      `${crowdsaleApis.GET_CROWDSALE}`,
      {},
      {
        ...params,
        page: pagecrowdsale,
        state: statetype,
      }
    );
    if (responsetable && responsetable.data) {
      setTotalCrowdsale(responsetable.data && responsetable.data.totalRecords);
      setCrowdsaleTable(responsetable.data);
    }
    setCrowdloading(false);

    //
  }, [reloadcrowdsale, pagecrowdsale, statetype]);
  //-------------------------------------------------------------

  //for create crowdsale  table--------------------------
  useEffect(async () => {
    setCreateLoading(true);
    const params = musicparams;
    const responsetable = await getRequiredProtectedData(
      "GET",
      `${crowdsaleApis.GET_MUSIC}`,
      {},
      {
        ...params,
        page: pagemusic,
        contentType: content,
        musicType: listingType,
      }
    );
    if (responsetable && responsetable.data) {
      setTotalMusic(responsetable.data && responsetable.data.totalRecords);
      setCreateCrowdsaleTable(responsetable.data);
      let calculateAvRights = {};
      if (responsetable.data.data.length > 0) {
        responsetable.data.data.map((item) => {
          calculateAvRights[item._id] = 0;
          if (item.sale.length > 0) {
            item.sale.map((item2) => {
              if (
                item2.state === "stopped" &&
                !item2.tokenName &&
                !item2.deploymentState
              ) {
              } else {
                calculateAvRights[item._id] =
                  calculateAvRights[item._id] + Number(item2.percentageRights);
              }
            });
          }
        });
        setAvailableRights(calculateAvRights);
      }
    }
    //error----
    if (
      responsetable &&
      responsetable.response &&
      responsetable.response.data &&
      responsetable.response.data.message ==
        "Please complete your publisher KYC to proceed"
    ) {
      SwalError(responsetable.response.data.message);
      history.push("/profile");
    }

    setCreateLoading(false);
  }, [reloadmusic, pagemusic, content, listingType]);

  //handle crowdsale modal--------------
  const handleCrowsaleModal = (el) => {
    setModalData(el);
    setShowCrowdsale(true);
  };

  //handle add crwodsale------------------
  const handleAddCrowdsale = (el, type = "Crowdsale") => {
    el && setMusicId(el._id);
    setSaleType(type);
    setAddCrowdsale(true);
  };
  //open upload modal and close details modal
  const toggleUploadModal = () => {
    setShowCrowdsale(false);
    setShowUpload(true);
  };
  //open extend modal and close details modal
  const toggleExtendCrowdsale = () => {
    setShowCrowdsale(false);
    setShowExtend(true);
  };
  //open deploy modal and close details modal
  const toggleDeployCrowdsale = () => {
    setShowCrowdsale(false);
    setShowDeploy(true);
  };
  //open edit modal and close details modal
  const toggleEditCrowdsale = (type = "Crowdsale") => {
    setShowCrowdsale(false);
    setSaleType(type);
    setShowEdit(true);
  };

  const clearParams = () => {
    setListingName("");
    setListingType("");
    setContent("");
    setContentName("");
  };

  return (
    <div className="wrapper ">
      <div className=" container h-100   ">
        <h1 className="text-center py-3 border-bottom-dark">My Crowdsale</h1>
        <div
          className="align-center border-bottom-dark"
          style={{ minHeight: "350px" }}
        >
          <div className="d-flex align-items-center justify-content-around flex-wrap  w-100">
            {/* <div className="my-2">
            <h4 className="text-dark">Create a new Crowdsale</h4>
            <button
              className="button-medium border-shadow dark-button mx-auto"
              style={{ width: "300px" }}
            >
              My Crowdsale
            </button>
          </div> */}
            {/* music */}
            <div className="container-sm m-2 crowdsale-card">
              <h3 className="text-dark">Music</h3>
              <h1 className="my-2">
                {analytics && analytics.music
                  ? analytics.music.total
                    ? analytics.music.total
                    : 0
                  : "-"}
              </h1>
              <div className="text-dark" style={{ fontSize: "13px" }}>
                Since last month
              </div>
            </div>
            {/* Crowdsale */}
            <div className="container-sm m-2 crowdsale-card">
              <h3 className="text-dark">Crowdsale</h3>
              <h1 className="my-2">
                {analytics && analytics.crowdsale
                  ? analytics.crowdsale.total
                    ? analytics.crowdsale.total
                    : 0
                  : "-"}
              </h1>
              <div className="text-dark" style={{ fontSize: "13px" }}>
                Since last month
              </div>
            </div>
            {/* Crowdfunding */}
            <div className="container-sm m-2 crowdsale-card">
              <h3 className="text-dark">Crowdfunding</h3>
              <h1 className="my-2">
                {analytics && analytics.offering
                  ? analytics.offering.total
                    ? analytics.offering.total
                    : 0
                  : "-"}
              </h1>
              <div className="text-dark" style={{ fontSize: "13px" }}>
                Since last month
              </div>
            </div>
            {/* Fans */}
            <div className="container-sm m-2 crowdsale-card">
              <h3 className="text-dark">Fans</h3>
              <h1 className="my-2">
                {analytics && analytics.fans
                  ? analytics.fans.total
                    ? analytics.fans.total
                    : 0
                  : "-"}
              </h1>
              <div className="text-dark" style={{ fontSize: "13px" }}>
                Since last month
              </div>
            </div>
          </div>
        </div>
        {/* <div className='text-end'>
                <div className='m-2'>
                    <button className={`filter-time ${timeLimit=='day'?'active-filter':''}`} onClick={()=>setTimeLimit('day')}>D</button>
                    <button className={`filter-time ${timeLimit=='7days'?'active-filter':''}`}  onClick={()=>setTimeLimit('7days')}>W</button>
                    <button className={`filter-time ${timeLimit=='30days'?'active-filter':''}`} onClick={()=>setTimeLimit('30days')}>M</button>
                    <button className={`filter-time ${timeLimit=='monthly'?'active-filter':''}`} onClick={()=>setTimeLimit('monthly')}>Y</button>
                </div>
            </div> */}
        <div
          className="border-bottom-dark align-center"
          style={{ height: "450px", width: "100%" }}
        >
          {loadgraph ? (
            <Spinner hidden={false} animation="border" />
          ) : (
            <CrowdsaleGraph
              data={crowdsalegraph}
              xdatakey="date"
              ydatakey="coin_raised"
              yName="Coin Raised"
            />
          )}
        </div>
        <div className="border-bottom-dark py-3">
          <h4 className="text-dark">
            Create Crowdsale/Crowdfunding List{" "}
            <InfoBox
              msg={"Click on any list item below to add crowdsale/crowdfund. "}
            />
          </h4>
          <div className="text-end m-2">
            <i
              className="fas fa-sync-alt cur-pointer text-dark"
              onClick={handleReloadMusic}
            ></i>
          </div>
          <div className="text-end m-2">
            <i
              className="fas fa-filter cur-pointer text-dark"
              onClick={() => setSearchEnable(!searchEnable)}
            ></i>
          </div>
          {searchEnable && (
            <div className={`row  my-2 text-center `}>
              <div className="col-md-5">
                <label
                  className="text-dark"
                  htmlFor="mtoname"
                  style={{ marginLeft: "10px" }}
                >
                  Listing Type
                </label>
                <DropdownButton
                  title={listingName ? listingName : "Select a Listing Type"}
                  id="dropdown-basic-button"
                  className="custom_dropdown input-box-sm  align-middle filter-dropdown"
                  variant=""
                >
                  {listingTypeList.map((el, i) => (
                    <Dropdown.Item
                      key={i}
                      onClick={() => (
                        setListingType(el.val), setListingName(el.name)
                      )}
                    >
                      <span>{el.name}</span>
                    </Dropdown.Item>
                  ))}
                </DropdownButton>
              </div>

              <div className="col-md-5">
                <label
                  className="text-dark"
                  htmlFor="mtoname"
                  style={{ marginLeft: "10px" }}
                >
                  Content Type
                </label>
                <DropdownButton
                  title={contentType ? contentType : "Select a Content Type"}
                  id="dropdown-basic-button"
                  className="custom_dropdown input-box-sm  align-middle filter-dropdown"
                  variant=""
                >
                  {contentTypeList.map((el, i) => (
                    <Dropdown.Item
                      key={i}
                      onClick={() => (
                        setContent(el.val), setContentName(el.name)
                      )}
                    >
                      <span className="w-100">{el.name}</span>
                    </Dropdown.Item>
                  ))}
                </DropdownButton>
              </div>
              <div className="col-md-2 d-flex align-items-end p-3">
                <button
                  className="button-medium border-shadow mx-auto  medium-btn"
                  type="submit"
                  onClick={() => clearParams()}
                >
                  Clear
                </button>
              </div>
            </div>
          )}

          <div className=" align-center" style={{ minHeight: "300px" }}>
            {createloading ? (
              <Spinner hidden={false} animation="border" />
            ) : createCrowdsaleTable &&
              createCrowdsaleTable.data &&
              createCrowdsaleTable.data.length ? (
              <div className="table-responsive order-table box-shadow w-100">
                <table className="table table-bordered table-hover">
                  <thead className="stick-header">
                    <tr style={{ borderTop: 0 }}>
                      <th scope="col" className="text-hover">
                        Content Type
                      </th>
                      <th scope="col" className="text-hover">
                        Listing Type
                      </th>
                      <th scope="col" className="text-hover">
                        Long Name
                      </th>
                      <th scope="col" className="text-hover">
                        Verification Request
                      </th>
                      <th scope="col" className="text-hover">
                        Verification Status
                      </th>
                      <th scope="col" className="text-hover">
                        Created At
                      </th>
                      <th scope="col" className="text-hover">
                        Available Rights(%)
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {createCrowdsaleTable &&
                      createCrowdsaleTable.data.map((el) => {
                        const crowdtype =
                          el.musicType && el.musicType == "existing"
                            ? "Crowdsale"
                            : "Crowdfunding";
                        return (
                          <tr
                            className="cur-pointer text-dark"
                            key={el._id}
                            onClick={() => handleAddCrowdsale(el, crowdtype)}
                          >
                            <th scope="row">{el.contentType}</th>

                            <td>
                              {el.musicType == "existing"
                                ? "Crowdsale"
                                : "Crowdfunding"}
                            </td>
                            <td>{el.longName}</td>
                            <td>
                              {el.verification.request === "notSend"
                                ? "not send"
                                : el.verification.request}
                            </td>
                            <td>
                              {el.verification.isVerified
                                ? "Verified"
                                : "Not Verified"}
                            </td>
                            <td>
                              {" "}
                              {moment(el.created_at).format(
                                "ddd, DD MMMM YYYY"
                              )}{" "}
                            </td>
                            <td>
                              {" "}
                              {availableRights[el._id]} / {el.totalShare}{" "}
                            </td>
                          </tr>
                        );
                      })}
                  </tbody>
                </table>
              </div>
            ) : (
              <NoRecordTable />
            )}
          </div>
          <div className="d-flex justify-content-end">
            {createCrowdsaleTable &&
              createCrowdsaleTable.data &&
              createCrowdsaleTable.data.length && (
                <PaginationBar count={totalmusic} setPage={setPageMusic} page={pagemusic} />
              )}
          </div>
        </div>

        <div className="border-bottom-dark py-3">
          <h4 className="text-dark">
            Crowdsale/Crowdfunding List{" "}
            <InfoBox msg={"Click on any list item below to know more. "} />
          </h4>
          <div className="text-end m-2">
            <i
              className="fas fa-sync-alt cur-pointer text-dark"
              onClick={handleReloadCrowdsale}
            ></i>
          </div>
          <div className="text-end m-2">
            <i
              className="fas fa-filter cur-pointer text-dark"
              onClick={() => setSearchEnableList(!searchEnableList)}
            ></i>
          </div>
          {searchEnableList && (
            <div className={`row  my-2 text-center `}>
              <div className="col-md-3">
                <label
                  className="text-dark"
                  htmlFor="mtoname"
                  style={{ marginLeft: "10px" }}
                >
                  State
                </label>
                <DropdownButton
                  style={{ textTransform: "capitalize" }}
                  title={statetype ? statetype : "Select a State"}
                  id="dropdown-basic-button"
                  className="custom_dropdown input-box-sm  align-middle filter-dropdown"
                  variant=""
                >
                  {stateList.map((el, i) => (
                    <Dropdown.Item key={i} onClick={() => setStateType(el.val)}>
                      <span style={{ textTransform: "capitalize" }}>
                        {el.name}
                      </span>
                    </Dropdown.Item>
                  ))}
                </DropdownButton>
              </div>

              <div className="col-md-2 d-flex align-items-end p-3">
                <button
                  className="button-medium border-shadow mx-auto  medium-btn"
                  type="submit"
                  onClick={() => clearParamsList()}
                >
                  Clear
                </button>
              </div>
            </div>
          )}

          <div className=" align-center" style={{ minHeight: "300px" }}>
            {crowdloading ? (
              <Spinner hidden={false} animation="border" />
            ) : crowdsaletable &&
              crowdsaletable.data &&
              crowdsaletable.data.length ? (
              <div className="table-responsive order-table box-shadow w-100">
                <table className="table table-bordered table-hover">
                  <thead className="stick-header">
                    <tr style={{ borderTop: 0 }}>
                      <th scope="col" className="text-hover">
                        Music Name
                      </th>
                      <th scope="col" className="text-hover">
                        State
                      </th>
                      <th scope="col" className="text-hover">
                        Soft Cap
                      </th>
                      <th scope="col" className="text-hover">
                        Target Amount
                      </th>
                      <th scope="col" className="text-hover">
                        Min Contribution
                      </th>
                      <th scope="col" className="text-hover">
                        Max Contribution
                      </th>
                      <th scope="col" className="text-hover">
                        Percentage Rights
                      </th>
                      <th scope="col" className="text-hover">
                        Zeptacoins Raises
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {crowdsaletable &&
                      crowdsaletable.data.map((el) => {
                        return (
                          <tr
                            className="cur-pointer text-dark"
                            key={el._id}
                            onClick={() => handleCrowsaleModal(el)}
                          >
                            <th scope="row">{el.musicId.sortName}</th>
                            <td>{el.state}</td>
                            <td>$200</td>
                            <td>${el.targetAmountByUser}</td>
                            <td>${el.minContributionByUser}</td>
                            <td>${el.maxContributionByUser}</td>
                            <td>{el.percentageRights}%</td>

                            <td>
                              {conversionValue === "dollar"
                                ? Number(el.zeptaCoinsRaised.toFixed(2))
                                : Number(el.zeptaCoinsRaised.toFixed(2))}
                            </td>
                          </tr>
                        );
                      })}
                  </tbody>
                </table>
              </div>
            ) : (
              <NoRecordTable />
            )}
          </div>
          {/* <button onClick={() => setShowEdit(true)}>dEPLOY</button> */}
          <div className="d-flex justify-content-end">
            {crowdsaletable &&
            crowdsaletable.data &&
            crowdsaletable.data.length ? (
              <PaginationBar count={totalcrowdsale} setPage={setPageCrowdsale} page={pagecrowdsale} />
            ) : null}
          </div>
          <NewCrowdsales handleCrowsaleModal={handleCrowsaleModal} />
        </div>

        {showcrowdsale && (
          <CrowdsalePermissions
            show={showcrowdsale}
            setShow={setShowCrowdsale}
            id={modaldata._id}
            walletAvailable={userdetails && userdetails.walletCreated}
            toggleUploadModal={toggleUploadModal}
            toggleExtendCrowdsale={toggleExtendCrowdsale}
            toggleDeployCrowdsale={toggleDeployCrowdsale}
            toggleEditCrowdsale={toggleEditCrowdsale}
            setModalData={setModalData}
            handleReloadCrowdsale={handleReloadCrowdsale}
          />
        )}
        {addcrowdsale && (
          <AddCrowdsale
            show={addcrowdsale}
            setShow={setAddCrowdsale}
            musicId={musicId}
            type={saleType}
          />
        )}

        {showupload && (
          <UploadCrowdsale
            show={showupload}
            setShow={setShowUpload}
            id={modaldata._id}
            modaldata={modaldata}
            handleReloadCrowdsale={handleReloadCrowdsale}
          />
        )}

        {showextend && (
          <ExtendCrowdsale
            show={showextend}
            setShow={setShowExtend}
            id={modaldata._id}
            modaldata={modaldata}
            musicType={modaldata.musicId.musicType}
            handleReloadCrowdsale={handleReloadCrowdsale}
          />
        )}
        {showdeploy && (
          <DeployCrowdsale
            show={showdeploy}
            setShow={setShowDeploy}
            id={modaldata._id}
            musicType={modaldata.musicId.musicType}
            handleReloadCrowdsale={handleReloadCrowdsale}
          />
        )}
        {showedit && (
          <AddCrowdsale
            show={showedit}
            setShow={setShowEdit}
            musicId={modaldata && modaldata._id}
            isEdit={true}
            editdata={modaldata}
            type={saleType}
          />
        )}
      </div>
    </div>
  );
};

export default MyCrowdsale;
