import { Tabs } from "react-bootstrap";
import { Tab } from "bootstrap";
import { useFetch } from "../../api/api_client";
import { userApis } from "../../redux/api";
import { LoadingView } from "../../components/loading_view";
import React from "react";
import NoRecordTable from "../../components/NoRecordFound/NoRecordTable";

export default function FaqPage() {
  const { data, error, loading } = useFetch(userApis.FAQ);

  if (loading) return <LoadingView />;

  if (error) {
    return <h2>ERROR {"Opps! Something went wrong"}</h2>;
  }

  const faqs = data["data"];

  //console.log(faqs);
  return (
    <div className={"wrapper"}>
      <div className="container h-100">
        <h3 className="faq_heading">Frequently Asked Questions</h3>

        <div className={"qa_container"}>
          {faqs && faqs.length ? (
            faqs.map((faq) => {
              return QA(faq["question"], faq["answer"]);
            })
          ) : (
            <NoRecordTable />
          )}
        </div>
      </div>
    </div>
  );
}

function QA(question, answer) {
  return (
    <div className={"qa-container"}>
      <span className={"question_heading"}>Ques.{question}</span>
      <span className={"answer_text"}>{answer}</span>
    </div>
  );
}
