import React, { useEffect, useState } from "react";
import { tradeApis } from "../../redux/api";
import { getRequiredProtectedData } from "../../redux/apicalltemplate";
import Trade from "./Trade";
import {
  getLivePriceStart,
  popularCrowdsaleStart,
} from "../../redux/crowdsale/Action";
import { popularcrowdsalepayload } from "../HomePage/crowdsaleparams";
import { useDispatch } from "react-redux";

const TradePage = ({ ual }) => {
  const [tradelist, setTradeList] = useState([]);
  const [tradegraph, setTradeGraph] = useState("");
  const [tradetable, setTradeTable] = useState([]);
  const [bookdetails, setBookDetails] = useState("");
  const [paramsTable, setParamsTable] = useState({ status: "", owner: "" });
  const [timeLimit, setTimeLimit] = useState("day");
  const [mtoname, setMtoName] = useState("");
  const dispatch = useDispatch();

  //loaders-----
  const [loadgraph, setLoadGraph] = useState(false);
  const [loadlist, setLoadList] = useState(false);
  const [loadtable, setLoadTable] = useState(false);

  useEffect(
    () =>
      (async () => {
        const params = {
          bookName: mtoname,
        };
        setLoadList(true);
        const responselist = await getRequiredProtectedData(
          "POST",
          tradeApis.GET_TRADING_DATA,
          {},
          params
        );
        if (responselist && responselist.data) {
          setTradeList(responselist.data);
          if (
            responselist.data &&
            responselist.data.data &&
            responselist.data.data.length
          ) {
            setBookDetails(responselist.data.data[0]);
          }
        }
        setLoadList(false);
      })(),
    [mtoname]
  );

  useEffect(() => {
    dispatch(popularCrowdsaleStart(popularcrowdsalepayload));
    dispatch(getLivePriceStart({}));
  }, []);

  //for calling  trading charts based on bookname-------
  useEffect(async () => {
    if (bookdetails) {
      const params = {
        timeLimit: timeLimit,
      };
      setLoadGraph(true);
      const responsegraph = await getRequiredProtectedData(
        "GET",
        `${tradeApis.GET_TRADING_GRAPH}/${bookdetails.bookName}`,
        {},
        params
      );
      if (responsegraph && responsegraph.data) {
        setTradeGraph(responsegraph.data.data);
      }
      setLoadGraph(false);
    }
  }, [bookdetails, timeLimit]);

  useEffect(async () => {
    if (bookdetails) {
      setLoadTable(true);
      const responsetable = await getRequiredProtectedData(
        "GET",
        `${tradeApis.GET_BOOK_DATA}/${bookdetails.bookName}`,
        {},
        paramsTable
      );
      if (responsetable && responsetable.data) {
        setTradeTable(responsetable.data.data);
      }
      setLoadTable(false);
    }
  }, [bookdetails, paramsTable]);

  return (
    <div className="wrapper">
      <Trade
        tradelist={tradelist}
        tradetable={tradetable}
        tradegraph={tradegraph}
        setBookDetails={setBookDetails}
        bookdetails={bookdetails}
        setParamsTable={setParamsTable}
        paramsTable={paramsTable}
        setTimeLimit={setTimeLimit}
        timeLimit={timeLimit}
        mtoname={mtoname}
        setMtoName={setMtoName}
        loadgraph={loadgraph}
        loadlist={loadlist}
        loadtable={loadtable}
        ual={ual}
      />
    </div>
  );
};

export default TradePage;
