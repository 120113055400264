import React from 'react'


export const TradingList=()=>{
    return(
        <div>
        <div className='text-darker'>foot1111</div>
        <div className='d-flex justify-content-between align-items-center border-bottom'>
           <div className='text-grey-light ' style={{fontWeight:'400'}}> Volume : 0 Price :1 $</div>
           <div className='text-grey-light'> TUE 22 Jun 2021</div>
        </div>
       </div>
    )
}

export const TradeOrder=({type,data})=>{
    return(
        <div>
        <div className={`${type=='buy'?'text-buy':'text-sell'} font-weight-bold`}>{(data.quantity * 10000).toFixed(2)}</div>
        <div className='d-flex justify-content-between align-items-center border-bottom'>
           <div className='text-grey-light ' style={{fontWeight:'400'}}> Price : {((data.price) / 10000).toFixed(2)} | Amount { ((data.quantity * 10000) * ((data.price) / 10000)).toFixed(2) }$</div>
           <div className='text-grey-light text-capitalize'>{data.status}</div>
        </div>
       </div>
    )
}
export const MtoDetails=({name,value})=>{
return(
    <div className='m-2 box-shadow-hover p-2'>
        <div className='text-dark'>{name}</div>
        <div>{value ? value.toFixed(4):'0' }</div>
    </div>
)
}