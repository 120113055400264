const base_url = process.env.REACT_APP_BASE_URL;

//const base_url = "https://staging-api.zeptagram.com/";

export const userApis = {
  LOGIN: `${base_url}api/v1/user/login/local`,
  LOGIN_MOBILE: `${base_url}api/v1/user/login/local?type=mobile`,
  SIGNUP: `${base_url}api/v1/user/signup/local`,
  LOGOUT: `${base_url}api/v1/user/logout`,
  VERIFY_ACCOUNT: `${base_url}api/v1/user/verifyAccount`,
  FORGET_PASSWORD: `${base_url}api/v1/user/forgotPassword`,
  RESET_FORGOT_PASSWORD: `${base_url}api/v1/user/resetForgotPassword`,
  GOOGLE_LOGIN: `${base_url}api/v1/user/login/google/callback`,
  APPLE_LOGIN: `${base_url}api/v1/user/login/apple/callback`,
  RESET_2FA: `${base_url}api/v1/user/2fa/reset`,
  VERIFY_RESET_2FA: `${base_url}api/v1/user/2fa/resetVerify`,
  RESEND_OTP_PASSWORD: `${base_url}api/v1/user/resendOtp`,
  RESET_PASSWORD: `${base_url}api/v1/user/resetPassword`,
  GET_DETAILS: `${base_url}api/v1/user/getDetails`,
  PAYMENT_DETAIL: `${base_url}api/v1/user/payment/createAccount`,
  CHANGE_PASSWORD: `${base_url}api/v1/user/resetPassword`,
  UPDATE_PROFILE: `${base_url}api/v1/user/profile/update`,
  CHANGE_PIN: `${base_url}api/v1/user/wallet/resetPin`,
  UPDATE_AVATAR: `${base_url}api/v1/user/profile/uploadImage`,
  DELETE_ACCOUNT: `${base_url}api/v1/user/request/account/delete`,
  INVESTOR_KYC: `${base_url}api/v1/user/kyc/investor`,
  PUBLISHER_DETAILS: `${base_url}api/v1/user/kyc/publisher`,
  KYC_DETAILS: `${base_url}api/v1/user/getKycDetails`,
  KYC_IMAGE_UPLOAD: `${base_url}api/v1/user/music/upload/royaltysheet`,
  AVER_URL: `${base_url}api/v1/user/aver/check`,
  WALLET_DETAILS: `${base_url}api/v1/user/wallet/details`,
  LIVE_PRICES: `${base_url}api/v1/user/transaction/getLivePrices`,
  WITHDRAW_CRYPTO: `${base_url}api/v1/user/wallet/withdraw/crypto?tokenName="ZPTC"`,
  WITHDRAW_FLAT: `${base_url}api/v1/user/payment/withdraw_in_USD`,
  ADD_FLAT: `${base_url}api/v1/user/payments/start`,
  CONFIRM_FLAT: `${base_url}api/v1/user/payments/confirm`,
  BUY_CRYPTO: `${base_url}api/v1/user/wallet/buyZeptacoins/crypto`,
  CREATE_WALLET: `${base_url}api/v1/user/wallet/add`, // pin mnemonic
  RANDOM_MNEMONIC: `${base_url}api/v1/user/wallet/mnemonic`,
  TRANSACTION_HISTORY: `${base_url}api/v1/user/transaction/history`,
  ROYALITIES_HISTORY: `${base_url}api/v1/user/royalty/releases`,
  NOTIFICATION: `${base_url}api/v1/user/notifications`, //?page=1&limit=100&sortBy=created_at&order=-1
  PRIVACY_POLICY: `${base_url}api/v1/privatePolicy`,
  FAQ: `${base_url}api/v1/FAQ?limit=100&order=1`,
  MUSIC_CHART: `${base_url}api/v1/user/music/preview/summary`,
  GET_MUSIC: `${base_url}api/v1/user/music`,
  SEND_ZEPTACOIN: `${base_url}api/v1/user/transaction/sendZeptacoins`,
  MUSIC_DETAIL_ID: `${base_url}api/v1/user/music/`,
  ADD_MUSIC: `${base_url}api/v1/user/music`,
  VERIFY_MUSIC: `${base_url}api/v1/user/music/verify/`, //PUT
  EDIT_MUSIC: `${base_url}api/v1/user/music/`, //PUT //DATA
  UPLOAD_ARTWORK: `${base_url}api/v1/user/music/upload/`, //patch  //musicNo
  GET_TICKETS: `${base_url}api/v1/user/support/tickets`, //page=${data.page}&limit=${data.limit}&sortBy=${data.sortBy}&order=${data.order}
  ADD_TICKET: `${base_url}api/v1/user/support/addTicket`,
  GET_CHATS: `${base_url}api/v1/user/support/chats`, // /${data.ticketNo}   page=${data.page}&limit=${data.limit}&sortBy=${data.sortBy}&order=${data.order}&search=${data.search}&from=${data.from}
  SEND_MSG: `${base_url}api/v1/user/support/addMessage`,
  VERIFY_2FA: `${base_url}api/v1/user/2fa/verify-setup`, //patch  //musicNo
  DISABLE_2FA: `${base_url}api/v1/user/2fa/disable`,
  ENABLE_2FA: `${base_url}api/v1/user/2fa/enable`,
  UPLOAD_KYC_IMAGE: `${base_url}api/v1/user/kyc/uploadDocImage?side=`,
  AML_KYC_DETAILS: `${base_url}api/v1/user/publisher/AML`,
  CHECK_CLAIM_TOKENSALE: `${base_url}api/v1/user/tokensale`,
  CLAIM_TOKENSALE: `${base_url}api/v1/user/tokensale/claim`,
  TOKENSALE_PARTICIPATE: `${base_url}api/v1/user/tokensale/isParticipated`,
  TERMS_AND_CONDITION: `${base_url}api/v1/termAndConditions`,
  ABOUT_US: `${base_url}api/v1/aboutus`,
  CHANGE_EMAIL: `${base_url}api/v1/user/change/email`,
  CHANGE_EMAIL_VERIFY: `${base_url}api/v1/user/verify/change-email`,
  VIEW_PRIVATE_KEY: `${base_url}api/v1/user/wallet/privateKey`,
  RESEND_OTP_2FA: `${base_url}api/v1/user/resendOtp2fa`,
  TOKEN_BRIDGE: `${base_url}api/v1/user/txn/bridge`,
  CLAIM_BRIDGE: `${base_url}api/v1//user/txn/bridge/claim`,
  GET_PORTFOLIO: `${base_url}api/v1/user/wallet/balances`,
  GET_DEVICES: `${base_url}api/v1/user/devices`,
  RESEND_DEVICE_OTP: `${base_url}api/v1/user/resendotp/device`,
  VERIFY_DEVICE: `${base_url}api/v1/user/verify/device`,
  BLOCK_DEVICE: `${base_url}api/v1/user/block/device`,
  UNBLOCK_DEVICE: `${base_url}api/v1/user/unblock/device`,
  REMOVE_DEVICE: `${base_url}api/v1/user/remove/device`,
  GET_CSRF: `${base_url}api/v1/user/csrf`,
  ANCHOR_LOGIN: `${base_url}api/v1/user/login/anchor`,
  ANCHOR_SIGNUP: `${base_url}api/v1/user/signup/anchor`,
  LOBSTR_LOGIN: `${base_url}api/v1/user/login/lobstr`,
  LOBSTR_SIGNUP: `${base_url}api/v1/user/signup/lobstr`,
  FREIGHTER_LOGIN: `${base_url}api/v1/user/login/freighter`,
  FREIGHTER_SIGNUP: `${base_url}api/v1/user/signup/freighter`,
  SOROBAN_CALL: `${base_url}api/v1/user/soroban/call`,
  SOROBAN_TXN_HISTORY: `${base_url}api/v1/user/Soroban/txn/history`,
  ANCHOR_SAVE_TNX: `${base_url}api/v1/user/anchor/txn`,
  WITHDRAW_CRYPTO_ANCHOR: `${base_url}api/v1/user/wallet/withdraw/crypto/anchor?tokenName="ZPTC"`,
  WITHDRAW_FIAT_ANCHOR: `${base_url}api/v1/user/payment/withdraw_in_USD`,
  NOTICE: `${base_url}api/v1/user/notice/notices`,
  SUBSCRIBE_NEWSLETTER: `${base_url}api/v1/user/mail/subscribe`,
  UNSUBSCRIBE_NEWSLETTER: `${base_url}api/v1/user/promotions/unsubscribe`,
  ANCHOR_PUBLIC_KEY: `${base_url}api/v1/user/anchor/publicKey`,
  USER_TXN: `${base_url}api/v1/user/confirm/swap/txn`,
  CHAT_API: `${base_url}api/v1/user/support/chat`,
  LIKE: `${base_url}api/v1/user/like`,
  MY_LIKES: `${base_url}api/v1/user/mylikes`,
  BANNER: `${base_url}api/v1//user/banner`,
  BOOKMARK: `${base_url}api/v1/user/bookmark`,
  UNBOOKMARK: `${base_url}api/v1/user/unbookmark`,
  ALL_BOOKMARK: `${base_url}api/v1/user/bookmark`,
  EXPORT_PIN: `${base_url}api/v1/user/wallet/export/pin`,
  RESEND_OTP_KEYEXPORT: `https://zepta-new-api.zeptagram.com/api/v1/user/resendOtpKeyExport`
};

export const crowdsaleApis = {
  GET_ALL_CROWDSALE: `${base_url}api/v1/public/viewAllCrowdsale`,
  GET_CROWDSALE: `${base_url}api/v1/user/crowdsale`,
  GET_CROWDSALE_BY_ID: `${base_url}user/crowdsale/`,
  UPLOAD_CROWDSALE_AGREEMENT: `${base_url}api/v1/user/crowdsale/upload/aggrement/`,
  CREATE_CROWDSALE: `${base_url}api/v1/user/crowdsale/`,
  VERIFY_CROWDSALE: `${base_url}api/v1/user/crowdsale/verify/`,
  EXTEND_CROWDSALE: `${base_url}api/v1/user/crowdsale/extend/endDate/`,
  GET_CROWDSALE_LISTING_FEE: `${base_url}api/v1/user/crowdsale/listingFee/`,
  GET_LIVE_PRICE: `${base_url}api/v1/user/transaction/getLivePrices`,
  INVEST_VIA_CRYPTO: `${base_url}api/v1/user/invest`,
  SOROBAN_INVEST: `${base_url}api/v1/user/invest/soroban`,
  INVEST_VIA_FIAT: `${base_url}api/v1/user/invest/fiat`,
  INVEST_CONFIRM: `${base_url}api/v1/user/invest/payments/confirm`,
  SEND_ZEPTACOIN: `${base_url}api/v1/user/transaction/sendZeptacoins`,
  INVEST_VIA_FIAT_ANCHOR: `${base_url}api/v1/user/invest/anchor/fiat`,
  INVEST_CONFIRM_ANCHOR: `${base_url}api/v1/user/invest/payments/Anchor/confirm`,
  PUBLISHER_AGREEMENT: `${base_url}api/v1/user/publisher/agreement`,

  RESEND_OTP_PASSWORD: `${base_url}api/v1/user/resendOtp`,
  RESET_PASSWORD: `${base_url}api/v1/user/resetPassword`,
  GET_DETAILS: `${base_url}api/v1/user/getDetails`,
  PAYMENT_DETAIL: `${base_url}api/v1/user/payment/createAccount`,
  CHANGE_PASSWORD: `${base_url}api/v1/user/resetPassword`,
  UPDATE_PROFILE: `${base_url}api/v1/user/profile/update`,
  CHANGE_PIN: `${base_url}api/v1/user/wallet/resetPin`,
  UPDATE_AVATAR: `${base_url}api/v1/user/profile/uploadImage`,
  INVESTOR_KYC: `${base_url}api/v1/user/kyc/investor`,
  PUBLISHER_DETAILS: `${base_url}api/v1/user/kyc/publisher`,
  KYC_DETAILS: `${base_url}api/v1/user/getKycDetails`,
  KYC_IMAGE_UPLOAD: `${base_url}api/v1/user/kyc/uploadDocImage?side=`,
  AVER_URL: `${base_url}api/v1/user/aver/check`,
  WALLET_DETAILS: `${base_url}api/v1/user/wallet/details`,
  LIVE_PRICES: `${base_url}api/v1/user/transaction/getLivePrices`,
  WITHDRAW_CRYPTO: `${base_url}api/v1/user/wallet/withdraw/crypto?tokenName="ZPTC"`,
  WITHDRAW_FLAT: `${base_url}api/v1/user/payment/withdraw_in_USD`,
  ADD_FLAT: `${base_url}api/v1/user/payments/start`,
  CONFIRM_FLAT: `${base_url}api/v1/user/payments/confirm`,
  BUY_CRYPTO: `${base_url}api/v1/user/wallet/buyZeptacoins/crypto`,
  CREATE_WALLET: `${base_url}api/v1/user/wallet/add`, // pin mnemonic
  RANDOM_MNEMONIC: `${base_url}api/v1/user/wallet/mnemonic`,
  NOTIFICATION: `${base_url}api/v1/user/notifications`, //?page=1&limit=100&sortBy=created_at&order=-1
  PRIVACY_POLICY: `${base_url}api/v1/privatePolicy`,
  FAQ: `${base_url}api/v1/FAQ?limit=100&order=1`,
  MUSIC_CHART: `${base_url}api/v1/user/music/preview/summary`,
  GET_MUSIC: `${base_url}api/v1/user/music`,

  MUSIC_DETAIL_ID: `${base_url}api/v1/user/music/`,
  ADD_MUSIC: `${base_url}api/v1/user/music`,
  VERIFY_MUSIC: `${base_url}api/v1/user/music/verify/`, //PUT
  EDIT_MUSIC: `${base_url}api/v1/user/music/`, //PUT //DATA
  UPLOAD_ARTWORK: `${base_url}api/v1/user/music/upload/`, //patch  //musicNo

  //MY CROWDSALE APIS------------------------------
  GET_ANALYTICS: `${base_url}api/v1/user/analytics`,
  GET_CROWDSALE_BY_ID: `${base_url}api/v1/user/crowdsale`, //${data.crowdsaleId}
  GET_CROWDSALE: `${base_url}api/v1/user/crowdsale`,
  GET_MUSIC: `${base_url}api/v1/user/music`,
  GET_CROWDSALE_GRAPH: `${base_url}api/v1/user/crowdsale/graph/preview`, //?isuuer=${data.userId}
  CREATE_CROWDSALE: `${base_url}api/v1/user/crowdsale`, //${musicId}
  VERIFY_CROWDSALE: `${base_url}api/v1/user/crowdsale/verify`, //${data.crowdsaleId}
  UPLOAD_CROWDSALE: `${base_url}api/v1/user/crowdsale/upload/aggrement`, //${crowdsaleId}
  DEPLOY_CROWDSALE: `${base_url}api/v1/user/crowdsale/start`,
  DEPLOY_SOROBAN_CROWDSALE: `${base_url}api/v1/user/crowdsale/soroban/start`,
  EXTEND_CROWDSALE: `${base_url}api/v1/user/crowdsale/extend/endDate`, //${crowdsaleId}
  STOP_CROWDSALE: `${base_url}api/v1/user/crowdsale/stop`, //${crowdsaleId}
  NEW_CROWDSALES: `${base_url}api/v1/user/crowdsale/new/preview`,
  GET_CROWDSALE_LISTING_FEE: `${base_url}api/v1/user/crowdsale/listingFee/`, //${getCrowdsaleListingFeeData.crowdsaleId}
  DEPLOY_CROWDSALE_WITH_ANCHOR: `${base_url}api/v1/user/anchorCrowdsale/start`,
  INVEST_IN_CROWDSALE_WITH_ANCHOR: `${base_url}api/v1/user/anchor/invest`,
};

export const tradeApis = {
  GET_BOOK_DATA: `${base_url}api/v1/user/getBookData`, //but and sell order
  GET_TRADING_GRAPH: `${base_url}api/v1/user/trading/graphPriceStatsWithData`, //graph and mto
  GET_TRADING_DATA: `${base_url}api/v1/user/trading/stats`, //trading table
  ADD_ORDER: `${base_url}api/v1/user/addOrder`,
  ADD_ORDER_WITH_ANCHOR: `${base_url}api/v1/user/anchor/addOrder`,
  GET_USER_TRADE_DATA: `${base_url}api/v1/user/trading/getBuyOrSellOrder`,
  CANCEL_ORDER: `${base_url}api/v1/user/trading/cancelOrder/`,
};

export const stellarApis = {
  GENERATE_KEYPAIR: `${base_url}api/v1/generate-keypair`, // generate keypair for stellar
  FUND_STELLAR_ACCOUNT: `${base_url}api/v1/fund-stellar-account`, //fund the stellar account with lumens
  STELLAR_ASSET_TRANSACTION: `${base_url}api/v1/asset-transaction`, // asset transaction between accounts
  STELLAR_TRANSACTION_HISTORY: `${base_url}api/v1/user/swap/txn`, // transaction history of stellar
  STELLAR_TRANSACTION_DETAILS: `${base_url}api/v1/user/stellar/txn`, // transaction details of stellar
  STELLAR_ADMIN_ACCOUNT_TRANSACTION: `${base_url}api/v1/admin-account-transaction`, // stellar trasaction for user to admin
  TELOS_ADMIN_TO_USER_TRANSACTION: `${base_url}api/v1/telos-transaction`, // telos transaction for admin to user
  CREATE_WALLET: `${base_url}api/v1/user/wallet/create`,
  SWAP_TELOS: `${base_url}api/v1/user/swap/telos`,
  SWAP_TELOS_STELLAR: `${base_url}api/v1/user/swap/telos/stellar`,
};
