import { Doughnut } from "react-chartjs-2";

export default function PolarChart(props) {
  const data = props["data"];

  let option = {
    data: (canvas) => {
      return {
        datasets: [
          {
            data: [
              data["sendForUpdateMusic"],
              data["verifiedMusic"],
              data["draftMusic"],
              data["musicSendForVerification"],
              data["rejectedMusic"],
            ],
            backgroundColor: [
              "#f39c12",
              "#384e66",
              "#36A2EB",
              "#1C7947",
              "#8D2828",
            ],
            label: "Music Stats", // for legend
          },
        ],
        labels: [
          "For Update",
          "Verified",
          "Draft",
          "For Verification",
          "Rejected",
        ],
        elements: {
          arc: {
            borderColor: "transparent",
          },
        },
      };
    },

    // options: chart_1_2_3_options
  };

  return (
    <Doughnut
      data={option.data}
      width={"200px"}
      height={"230px"}
      options={{
        legend: {
          display: true,
        },
        responsive: true,
        maintainAspectRatio: false,
        // cutoutPercentage: 60
      }}
    />
  );
}
