import React from 'react'
import { Modal } from 'react-bootstrap'
import { CopyToClipboard } from 'react-copy-to-clipboard/lib/Component';
import { SuccessToast } from '../../components/ToastMessage/SuccessToast';
import { CSVLink } from "react-csv";

import { toast } from "react-toastify";
import copyIcon from "../../icons/copy.png";
import { traverseAndFlatten } from './utils';

export const CreateAccount=({show,setShow, walletData})=>{
    const handleClose = () => setShow(false);

const headers = [
  { label: "Account Name", key: "accountName" },
  { label: "Active Private Key", key: "activeKeyPair.wif" },
  { label: "Active Public Key", key: "activeKeyPair.publicKey"},
  { label: "Owner Private Key", key: "owneKeyPair.wif"},
  { label: "Owner Public Key", key: "owneKeyPair.publicKey"},
];

function flatten(obj) {
  var flattenedObject = {};
  traverseAndFlatten(obj, flattenedObject);
  return flattenedObject;
}

return (<Modal size='lg'   backdrop="static" show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Create Account</Modal.Title>
      </Modal.Header>
   
        <Modal.Body className="p-5">
             
{
  walletData &&  <div>
<CSVLink style={{width:'150px'}} className="button-medium text-center border-shadow  ml-auto my-2" filename={"zeptagram_private_keys.csv"} data={[flatten(walletData)]} headers={headers}>
 Export Keys
</CSVLink>
  <div className="text-dark f-14">Account Name</div>
   <div style={{background:'#e6e0cc'}} className="text-dark my-2 p-3 border-shadow create-anc border-md">
    {walletData.accountName}
     <CopyToClipboard title='copy'
             text= {walletData.accountName}
             onCopy={() => {
                 toast.success(<SuccessToast message={'Copied!'} />,{
                     toastId:1,
                     updateId:1
                 });

             }}
           >
             <img
               src={copyIcon}
               width={"17px"}
               className={"mx-2 cur-pointer"}
             />
           </CopyToClipboard>
     </div>
     <div className="text-dark f-16">Active</div>
     <div className="border-shadow p-2 m-1" style={{borderRadius:'10px'}}>
     <div className="text-dark f-14">Public Key</div>
   <div style={{background:'#e6e0cc'}} className="text-dark my-2 p-3 border-shadow create-anc border-md">
    {walletData.activeKeyPair?.publicKey}
     <CopyToClipboard title='copy'
             text= {walletData.activeKeyPair?.publicKey}
             onCopy={() => {
                 toast.success(<SuccessToast message={'Copied!'} />,{
                     toastId:1,
                     updateId:1
                 });

             }}
           >
             <img
               src={copyIcon}
               width={"17px"}
               className={"mx-2 cur-pointer"}
             />
           </CopyToClipboard>
     </div>
     <div className="text-dark f-14">Private Key</div>
     <div style={{background:'#e6e0cc'}} className="text-dark my-2 p-3 border-shadow create-anc border-md">
     {walletData.activeKeyPair?.wif}
     <CopyToClipboard title='copy'
             text={walletData.activeKeyPair?.wif}
             onCopy={() => {
                 toast.success(<SuccessToast message={'Copied!'} />,{
                     toastId:1,
                     updateId:1
                 });

             }}
           >
             <img
               src={copyIcon}
               width={"17px"}
               className={"mx-2 cur-pointer"}
             />
           </CopyToClipboard>
     </div>

</div>

     <div className="text-dark f-16">Owner</div>
     <div className="border-shadow p-2 m-1" style={{borderRadius:'10px'}}>
     <div className="text-dark f-14">Public Key</div>
   <div style={{background:'#e6e0cc'}} className="text-dark my-2 p-3 border-shadow create-anc border-md">
    {walletData.owneKeyPair?.publicKey}
     <CopyToClipboard title='copy'
             text= {walletData.owneKeyPair?.publicKey}
             onCopy={() => {
                 toast.success(<SuccessToast message={'Copied!'} />,{
                     toastId:1,
                     updateId:1
                 });

             }}
           >
             <img
               src={copyIcon}
               width={"17px"}
               className={"mx-2 cur-pointer"}
             />
           </CopyToClipboard>
     </div>
     <div className="text-dark f-14">Private Key</div>
     <div style={{background:'#e6e0cc'}} className="text-dark my-2 p-3 border-shadow create-anc border-md">
     {walletData.owneKeyPair?.wif}
     <CopyToClipboard title='copy'
             text={walletData.owneKeyPair?.wif}
             onCopy={() => {
                 toast.success(<SuccessToast message={'Copied!'} />,{
                     toastId:1,
                     updateId:1
                 });

             }}
           >
             <img
               src={copyIcon}
               width={"17px"}
               className={"mx-2 cur-pointer"}
             />
           </CopyToClipboard>
     </div>
     </div>
   <div className="text-dark mt-2" >Save these details secretly.</div>
   <div className="" >Follow  <a
               className="text-dark cur-pointer btn-def"
               target="_blank"
               href={
                 "https://zeptagram-test2.s3.eu-north-1.amazonaws.com/28-3-Instructions+to+import+account+in+Anchor.pdf"
               }
             >
              these
             </a>  instructions carefully to import anchor wallet.</div>

    </div>
}
        </Modal.Body>

    </Modal>)
}