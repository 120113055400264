import React, { Component, useState } from "react";
import { Spinner } from "react-bootstrap";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { useFetch } from "../../api/api_client";
import NoRecordTable from "../../components/NoRecordFound/NoRecordTable";
import { userApis } from "../../redux/api";

export default function VerifiedMusic({ onSelect, refresh }) {
  let [verifiedMusicPage, setVerifiedMusicPage] = useState(1);

  const { loading, error, data } =
    useFetch(
      `${userApis.GET_MUSIC}?page=${verifiedMusicPage}&limit=5&verified=&&request=accepted&selector=longName bitMapImage contentType createdBy shareHolderName verification isuuer&selectorValue=1`,
      [verifiedMusicPage, refresh]
    ) || {};

  if (loading)
    return (
      <div className="align-center" style={{ height: "280px" }}>
        <Spinner hidden={false} animation="border" />
      </div>
    );

  if (error) return "error";

  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 6,
      slidesToSlide: 1, // optional, default to 1.
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 3,
      slidesToSlide: 1, // optional, default to 1.
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 2,
      slidesToSlide: 1, // optional, default to 1.
    },
  };
  return (
    <div>
      {data && data.data && data.data.length ? (
        <div>
          <Carousel
            swipeable={true}
            draggable={true}
            showDots={false}
            responsive={responsive}
            ssr={false} // means to render carousel on server-side.
            infinite={false}
            // autoPlay={this.props.deviceType !== "mobile" ? true : false}
            autoPlaySpeed={1000}
            keyBoardControl={true}
            customTransition="all .5"
            transitionDuration={500}
            containerClass="carousel-container"
            removeArrowOnDeviceType={["tablet", "mobile", "desktop"]}
            // deviceType={this.props.deviceType}
            //  dotListClass="custom-dot-list-style"
            itemClass="carousel-item-padding-40-px"
          >
            {data["data"].map((item, i) => {
              return (
                <div
                  key={i}
                  className={"container-sm m-2"}
                  style={{ width: "inherit" }}
                  onClick={() => {
                    onSelect(item["_id"]);
                  }}
                >
                  <div className={"verified-music"}>
                    <img className="mid-image" src={item["bitMapImage"]} />
                    <span className={"text-dark text-center"}>
                      {item["longName"]}
                    </span>
                    <span className={"text-grey text-center"}>
                      {item["shareHolderName"]}
                    </span>
                  </div>
                </div>
              );
            })}
          </Carousel>

          <div className={"verified-music-nav-container"}>
            <span
              className={
                verifiedMusicPage > 1 ? "text-hover " : "page-action-disabled"
              }
              onClick={() => {
                if (verifiedMusicPage > 1)
                  setVerifiedMusicPage(verifiedMusicPage - 1);
              }}
            >
              PREV
            </span>
            <span
              className={
                data.nextPage ? "page-action-enabled " : "page-action-disabled"
              }
              onClick={() => {
                if (data.nextPage) {
                  setVerifiedMusicPage(verifiedMusicPage + 1);
                }
              }}
            >
              NEXT
            </span>
          </div>
        </div>
      ) : (
        <NoRecordTable />
      )}
    </div>
  );
}
