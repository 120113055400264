import { useState } from "react";
import { Modal, Form, Row, Col } from "react-bootstrap";
import { SubmitButton } from "../../components/components";
import PinInput from "react-pin-input";

const AdvanceDetailsModal = ({ show, setShow, details, advancedInfo }) => {
  return (
    <Modal
      show={show}
      onHide={() => {
        setShow();
      }}
      size="lg"
    >
      <Modal.Header closeButton>
        <Modal.Title className="text-dark">ADVANCED WALLET DETAILS</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div>
          <Row>
            <Col xl={6}>
              <span className={"details_span"}>
                <span className="text-dark">Privileged :</span>
                {advancedInfo && advancedInfo["privileged"] === "true"
                  ? " true"
                  : " false"}{" "}
              </span>
              <span className={"details_span"}>
                {" "}
                <span className="text-dark"> Ram Quota :</span>{" "}
                {advancedInfo && advancedInfo["ram_quota"]}{" "}
              </span>

              <span className={"details_span"}>
                {/* <span className="text-dark">TLOS Balance </span>0: */}
              </span>
              <span className={"details_span"}>
                <span className="text-dark"> Net Weight :</span>{" "}
                {advancedInfo && advancedInfo["net_weight"]}
              </span>
              <span className={"details_span"}>
                {" "}
                <span className="text-dark"> CPU Weight :</span>
                {advancedInfo && advancedInfo["cpu_weight"]}
              </span>
              {advancedInfo &&
                advancedInfo["net_weight"] &&
                advancedInfo["net_weight"].max && (
                  <span className={"details_span"}>
                    <span className="text-dark"> Max. Net Limit :</span>
                    {advancedInfo && advancedInfo["net_weight"].max}
                  </span>
                )}
              {advancedInfo &&
                advancedInfo["net_weight"] &&
                advancedInfo["net_weight"].used && (
                  <span className={"details_span"}>
                    <span className="text-dark"> Net Limit Used :</span>
                    {advancedInfo && advancedInfo["net_weight"].used}
                  </span>
                )}
              {advancedInfo &&
                advancedInfo["net_weight"] &&
                advancedInfo["net_weight"].available && (
                  <span className={"details_span"}>
                    {" "}
                    <span className="text-dark"> Available Net Limit :</span>
                    {advancedInfo && advancedInfo["net_weight"].available}
                  </span>
                )}

              <span className={"details_span"}>
                {" "}
                <span className="text-dark"> Max. CPU Limit :</span>
                {advancedInfo && advancedInfo["cpu_limit"].max}
              </span>
              <span className={"details_span"}>
                {" "}
                <span className="text-dark"> CPU Limit Used :</span>
                {advancedInfo && advancedInfo["cpu_limit"].used}
              </span>
              <span className={"details_span"}>
                {" "}
                <span className="text-dark"> Available CPU Limit :</span>
                {advancedInfo && advancedInfo["cpu_limit"].available}
              </span>
              <span className={"details_span"}>
                {" "}
                <span className="text-dark"> RAM Usage : </span>
                {advancedInfo && advancedInfo["ram_usage"]}
              </span>
            </Col>
            <Col xl={6}>
              <div>
                <span className={"details_span"}>
                  <span className="text-dark">Permission Name </span>:
                  {advancedInfo &&
                    advancedInfo["permissions"] &&
                    advancedInfo["permissions"][0].perm_name}{" "}
                </span>
                <span className={"details_span word-break"}>
                  {" "}
                  <span className="text-dark"> Key : </span>
                  {advancedInfo &&
                    advancedInfo["permissions"] &&
                    advancedInfo["permissions"][0].required_auth["keys"][0]
                      .key}{" "}
                </span>
                <span className={"details_span"}>
                  <span className="text-dark">threshold:</span>
                  {advancedInfo &&
                    advancedInfo["permissions"] &&
                    advancedInfo["permissions"][0].required_auth["threshold"]}
                </span>
              </div>
              <div>
                <span className={"details_span"}>
                  <span className="text-dark"> Permission Name : </span>
                  {advancedInfo &&
                    advancedInfo["permissions"] &&
                    advancedInfo["permissions"][1].perm_name}{" "}
                </span>
                <span className={"details_span word-break"}>
                  {" "}
                  <span className="text-dark"> Key : </span>
                  {advancedInfo &&
                    advancedInfo["permissions"] &&
                    advancedInfo["permissions"][1].required_auth["keys"][0]
                      .key}{" "}
                </span>
                <span className={"details_span"}>
                  <span className="text-dark"> threshold:</span>
                  {advancedInfo &&
                    advancedInfo["permissions"] &&
                    advancedInfo["permissions"][1].required_auth["threshold"]}
                </span>
              </div>
            </Col>
          </Row>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default AdvanceDetailsModal;
