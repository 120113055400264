import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { MtoDetails, TradeOrder } from "../../components/TradeCards/TradeCards";

import InvestCrowdsale from "../../components/Invest/Invest";
import CrowdCardMedium from "../../components/CrowdCards/CrowdCardMedium";
import CrowdsaleDetails from "../../components/CrowdsaleDetails/CrowdsaleDetails";
import Order from "../../components/Orders/Order";
import TradeGraph from "../../components/Chart/Chart";
import { DropdownButton, Form, Dropdown, Spinner } from "react-bootstrap";
import NoRecordTable from "../../components/NoRecordFound/NoRecordTable";
import InfoBox from "../../components/InfoMessage/InfoHover";
import AnchorInvestModal from "../../containers/AnchorWallet/AnchorInvestModal";

import AppServices from "../../services/AppService";
import { getRequiredProtectedData } from "../../redux/apicalltemplate";
import { tradeApis } from "../../redux/api";
import moment from "moment";
import InfoToast from "../../components/ToastMessage/InfoToast";
import SwalError from "../../components/ToastMessage/SwalError";
import PaginationBar from "../../components/Pagination/Navbar";
const appServices = new AppServices();

const Trade = ({
  tradelist,
  tradegraph,
  tradetable,
  bookdetails,
  setBookDetails,
  timeLimit,
  setTimeLimit,
  paramsTable,
  setParamsTable,
  setMtoName,
  loadgraph,
  loadlist,
  loadtable,
  ual,
}) => {
  const [show, setShow] = useState(false);
  const [showcrowdsale, setShowCrowdsale] = useState(false);
  const [text, setText] = useState("Crowdsale");
  const [crowdsaledata, setCrowdsaleData] = useState(false);
  const { popularcrowdsale, liveprice } = useSelector(
    (state) => state.crowdsale
  );
  const bookmarks=useSelector(state=>state.user.bookmarks)

  const [ordershow, setOrderShow] = useState(false);
  const [ordertext, setOrderText] = useState("Buy");
  const [marketcap, setMarketCap] = useState("");

  const [owner, setOwner] = useState("");
  const [status, setStatus] = useState("");
  const [fetchgraph, setFetchGraph] = useState([]);
  const [listSearch, setListSearch] = useState(false);
  const [tableSearch, setTableSearch] = useState(false);
  const [searchbook, setSearchBook] = useState("");

  const [usertrade,setUserTrade]=useState([]);
  const [usertradeLoading,setUserTradeLoading]=useState(false);
  const [traderefresh,setTradeRefresh]=useState(false)
  const [mytradepage,setMyTradePage]=useState(1)
  const [userStatus, setUserStatus] = useState("");
  const [userSide, setUserSide] = useState("");
  const [viewUserFilter, setViewUserFilter] = useState(false);
  const [cancelLoader,setCancelLoader]=useState('')

  const userdata = useSelector((state) => state.user.data);
 
  //for graphical data------------------------
  useEffect(() => {
    if (
      tradegraph &&
      tradegraph.tradingData &&
      tradegraph.tradingData.finalFetchedGraphData
    ) {
      let curr = tradegraph.tradingData.finalFetchedGraphData;
      let newdata = curr.map((el, i) => {
        return { date: el.date, latestPrice: el.latestPrice };
      });
      setFetchGraph(newdata);
    }
  }, [tradegraph]);


    //for user trade data------------------------
    useEffect(async() => {
      const params={page:mytradepage,limit:10,side:userSide,status:userStatus};
 
      setUserTradeLoading(true);
      const response = await getRequiredProtectedData(
        "GET",
        `${tradeApis.GET_USER_TRADE_DATA}`,
        {},
        params
      );
 
      if (response && response.data) {
        setUserTrade(response.data);
      }
      setUserTradeLoading(false);
   
    }, [traderefresh,mytradepage,userStatus,userSide]);


const handleCancelOrder=async(id)=>{
  setCancelLoader(id)
  const response = await getRequiredProtectedData(
    "PUT",
    `${tradeApis.CANCEL_ORDER}${id}`,
    {},
    {}
  );
  if (response && response.data && response.data.success) {
    setTradeRefresh(!traderefresh)
  
    InfoToast(response.data.message);
  }
  if (response && response.data && !response.data.success) {
 
    InfoToast(response.data.message);
  }
  if (response && response.response && response.response.data) {

    SwalError(response.response.data.message);
  }
  setCancelLoader('')
}

  //for MTO updation---------------
  useEffect(() => {
    if (tradegraph && bookdetails) {
      let marketcap = tradegraph.supply_in_market * bookdetails.latestPrice;
      setMarketCap(marketcap);
    }
  }, [tradegraph, bookdetails]);

  //for invest and crowdsale data--------------------------
  const handleCrowdsaleDetails = (el) => {
    setCrowdsaleData(el);
    setShowCrowdsale(true);
  };
  const handleInvest = (el, type) => {
    setCrowdsaleData(el);
    if (type === "crowdfund") {
      setText("Crowdfund");
    } else {
      setText("Crowdsale");
    }
    setShow(true);
  };
  //-------------------------------------------------------------

  //handle list search------------
  const handleListSearch = (e) => {
    e.preventDefault();
    setMtoName(searchbook);
  };
  return (
    <div className="container h-100 ">
      <h1 className="text-center py-3 border-bottom-dark">Trading</h1>
      <div
        className="align-center border-bottom-dark"
        style={{ minHeight: "350px" }}
      >
        {!popularcrowdsale ? (
          <Spinner hidden={false} animation="border" />
        ) : (
          <div className="d-flex align-items-center justify-content-around flex-wrap  w-100">
            {popularcrowdsale &&
              popularcrowdsale.data &&
              popularcrowdsale.data
                .filter((el, i) => i < 4)
                .map((el, i) => {
                  return (
                    <CrowdCardMedium
                      key={i}
                      el={el}
                      liveprice={liveprice}
                      handleCrowdsaleDetails={handleCrowdsaleDetails}
                      handleInvest={handleInvest}
                      userid={userdata && userdata._id}
                      isBookmark={!!bookmarks.find(ml=>ml.crowdsaleId==el._id)}
                    />
                  );
                })}
          </div>
        )}
      </div>
      <div className="text-end">
        <div className="m-2">
          <button
            className={`filter-time ${
              timeLimit == "day" ? "active-filter" : ""
            }`}
            onClick={() => setTimeLimit("day")}
          >
            D
          </button>
          <button
            className={`filter-time ${
              timeLimit == "7days" ? "active-filter" : ""
            }`}
            onClick={() => setTimeLimit("7days")}
          >
            W
          </button>
          <button
            className={`filter-time ${
              timeLimit == "30days" ? "active-filter" : ""
            }`}
            onClick={() => setTimeLimit("30days")}
          >
            M
          </button>
          <button
            className={`filter-time ${
              timeLimit == "monthly" ? "active-filter" : ""
            }`}
            onClick={() => setTimeLimit("monthly")}
          >
            Y
          </button>
        </div>
      </div>
      <div
        className="border-bottom-dark align-center"
        style={{ height: "450px", width: "100%" }}
      >
        {loadgraph ? (
          <Spinner hidden={false} animation="border" />
        ) : (
          <TradeGraph
            data={fetchgraph}
            xdatakey="date"
            ydatakey="latestPrice"
            yName="Latest Price"
          />
        )}
      </div>
      <div className="border-bottom-dark py-3">
        <h4 className="text-dark">
          Trading List{" "}
          <InfoBox msg={"Click on any trade below to know more. "} />
        </h4>
        <div className="text-end m-2">
          <i
            className="fas fa-filter cur-pointer text-dark"
            onClick={() => setListSearch(!listSearch)}
          ></i>
        </div>
        {listSearch && (
          <div>
            <form
              onSubmit={handleListSearch}
              className={` mx-auto my-2 text-center `}
              style={{ maxWidth: "300px" }}
            >
              <label
                className="text-dark"
                htmlFor="mtoname"
                style={{ marginLeft: "10px" }}
              >
                MTO Name
              </label>
              <Form.Control
                id="mtoname"
                className={"input-box-sm"}
                type="text"
                onChange={(e) => setSearchBook(e.target.value)}
                value={searchbook}
                placeholder="Search"
                required
                aria-required={true}
              />
              <div className="d-flex">
                <button
                  className="button-medium border-shadow mx-auto"
                  style={{ width: "100px" }}
                  type="submit"
                >
                  Submit
                </button>
                <button
                  className="button-medium border-shadow mx-auto"
                  style={{ width: "100px" }}
                  type="button"
                  onClick={() => (setMtoName(""), setSearchBook(""))}
                >
                  Clear
                </button>
              </div>
            </form>
          </div>
        )}
        <div className=" align-center">
          {loadlist ? (
            <Spinner hidden={false} animation="border" />
          ) : tradelist && tradelist.data && tradelist.data.length ? (
            <div className="table-responsive order-table box-shadow w-100">
              <table className="table table-bordered table-hover">
                <thead className="stick-header">
                  <tr style={{ borderTop: 0 }} className="text-hover">
                    <th scope="col text-hover">Music Name</th>
                    <th scope="col text-hover">MTO Name</th>
                    <th scope="col text-hover">Latest Price($)</th>
                    <th scope="col text-hover">Lowest Price($)</th>
                    <th scope="col text-hover">Highest Price($)</th>
                    <th scope="col text-hover">Total Volume</th>
                    <th scope="col text-hover">24 HR Price Change($)</th>
                  </tr>
                </thead>
                <tbody>
                  {tradelist &&
                    tradelist.data &&
                    tradelist.data.map((el) => {
                      return (
                        <tr
                          className="cur-pointer text-dark"
                          key={el.coinId}
                          onClick={() => setBookDetails(el)}
                        >
                          <th scope="row">{el?.longName || "-"}</th>
                          <th scope="row">{el.bookName}</th>
                          <td>{el.latestPrice && el.latestPrice.toFixed(6)}</td>
                          <td>{el.lowPrice && el.lowPrice.toFixed(6)}</td>
                          <td>{el.highPrice && el.highPrice.toFixed(6)}</td>
                          <td>{el.totalAmount && el.totalAmount.toFixed(6)}</td>
                          <td>{el.priceChange24hr}</td>
                        </tr>
                      );
                    })}
                </tbody>
              </table>
            </div>
          ) : (
            <NoRecordTable />
          )}
        </div>
      </div>






      <div className="border-bottom-dark py-3">
        <h4 className="text-dark">
          My Trading{" "}
          <InfoBox msg={"Click on any trade below to know more. "} />
        </h4>
        <div className="text-end m-2">
          <i
            className="fas fa-filter cur-pointer text-dark"
            onClick={() => setViewUserFilter(!viewUserFilter)}
          ></i>
        </div>
        {viewUserFilter && (
          <div className="text-center">
            <form
              onSubmit={handleListSearch}
              className={`  my-2 mx-auto  `}
           
            >
              <div className="row">
              <div className="col-md-4 ">
              <label
                className="text-dark"
                htmlFor="mtoname"
                style={{ marginLeft: "10px" }}
              >
                Side
              </label>
              <DropdownButton
                title={userSide ? userSide : "Select a Side"}
                id="dropdown-basic-button"
                className="custom_dropdown input-box-sm  align-middle filter-dropdown mx-auto w-content"
                variant=""
              >
                {[
                  { val: "buy", name: "buy" },
                  { val: "sell", name: "sell" },
                ].map((el, i) => (
                  <Dropdown.Item
                    key={i}
                    
                    onClick={() => (
                       
                      setUserSide(el.val)
                    )}
                  >
                    <span>{el.name}</span>
                  </Dropdown.Item>
                ))}
              </DropdownButton>
            </div>

                <div className="col-md-4  ">
              <label
                className="text-dark"
                htmlFor="mtoname"
                style={{ marginLeft: "10px" }}
              >
                Status
              </label>
              <DropdownButton
                title={userStatus ? userStatus : "Select a Status"}
                id="dropdown-basic-button"
                className="custom_dropdown input-box-sm  align-middle filter-dropdown mx-auto w-content"
                variant=""
              >
                {[
                  { val: "confirmed", name: "confirmed" },
                  { val: "active", name: "active" },
                ].map((el, i) => (
                  <Dropdown.Item
                    key={i}
                   
                    onClick={() => (
                     
                      setUserStatus(el.name)
                    )}
                  >
                    <span>{el.name}</span>
                  </Dropdown.Item>
                ))}
              </DropdownButton>
            </div>
            <div className="align-center col-md-4">
             
             <button
               className="button-medium border-shadow  "
               style={{ width: "100px" }}
               type="button"
               onClick={() => (setUserSide(""), setUserStatus(""))}
             >
               Clear
             </button>
           </div>
         
              </div>

            </form>
          </div>
        )}
        <div className=" align-center">
          {usertradeLoading ? (
            <div className="align-center" style={{height:'300px'}}>  <Spinner hidden={false} animation="border" /></div>
           
          ) : usertrade && usertrade?.data?.length ? (
            <div className="table-responsive order-table box-shadow w-100">
              <table className="table table-bordered table-hover">
                <thead className="stick-header">
                  <tr style={{ borderTop: 0 }} className="text-hover">
                   
                    <th scope="col text-hover">Book Name</th>
                    <th scope="col text-hover">Amount</th>
                    {/* <th scope="col text-hover">Owner</th> */}
                    <th scope="col text-hover"> Price($)</th>
                    <th scope="col text-hover">Quantity</th>
                 
                    <th scope="col text-hover">Side</th>
                    <th scope="col text-hover">Status</th>
                    <th scope="col text-hover">Type</th>
                    <th scope="col text-hover">Created At</th>
                    <th scope="col text-hover">Action</th>
                  </tr>
                </thead>
                <tbody>
                  {usertrade?.data?.map((el) => {
                      return (
                        <tr
                          className="cur-pointer text-dark"
                          key={el.coinId}
                          
                        >
                          <th scope="row">{el.bookName}</th>
                          <th scope="row">{el?.amount || "-"}</th>
                          {/* <td>{el.owner}</td> */}
                          <td>{el.price && el.price.toFixed(4)}</td>
                          <td>{el.quantity && el.quantity.toFixed(4)}</td>
                          <td>{el.side}</td>
                          <td>{el.status}</td>
                          <td>{el.type}</td>
                          <td><div style={{width:'max-content'}}> <div>{el.createdAt ? moment(el.createdAt).format('DD-MM-YYYY '):'-'}</div><div className="f-12">{el.createdAt ? moment(el.createdAt).format(' HH:MM:SS A'):'-'}</div> </div></td>
                          <td className="text-center">{userdata?._id === el?.owner && el.status !=='confirmed'  ? <button style={{width:'80px'}} onClick={()=>handleCancelOrder(el?._id)} className="button-medium border-shadow" disabled={cancelLoader===el?._id}>
                            
                            {
                              cancelLoader==el?._id ?
                              <Spinner size="sm" hidden={false} animation="border" />:'Cancel'
                            }
                            
                            
                            </button>:'-' }  </td>
                        </tr>
                      );
                    })}
                </tbody>
              </table>
             
            </div>
          ) : (
            <NoRecordTable />
          )}
          
        </div>
        <div className="d-flex justify-content-end">
        {usertrade?.data?.length ? (
                <PaginationBar count={usertrade.totalRecords} setPage={setMyTradePage} page={mytradepage} />
              ) :null}
        </div>
      
      </div>

      <div className="row border-bottom-dark">
        <div className="my-3">
          <h4 className="text-dark">MTO details</h4>
          <div className="align-center" style={{ minHeight: "150px" }}>
            {loadgraph ? (
              <Spinner hidden={false} animation="border" />
            ) : (
              <div className="row w-100">
                <div className="col col-md-3 align-center">
                  <img
                    className="mid-image"
                    src={
                      tradegraph &&
                      tradegraph.musicData &&
                      tradegraph.musicData.bitMapImage
                    }
                    alt=""
                  />
                </div>
                <div
                  className="col col-md-9 d-flex flex-wrap align-items-center"
                  style={{ minWidth: "320px" }}
                >
                  <MtoDetails
                    name={"24 hr Price Change($)"}
                    value={
                      bookdetails &&
                      bookdetails.priceChange24hr &&
                      bookdetails.priceChange24hr / 10000
                    }
                  />
                  <MtoDetails
                    name={"24 hr Total Volume"}
                    value={bookdetails && bookdetails.totalQuantity24hr}
                  />
                  <MtoDetails
                    name={"Lowest Price($)"}
                    value={bookdetails && bookdetails.lowPrice / 10000}
                  />
                  <MtoDetails
                    name={"Highest Price($)"}
                    value={bookdetails && bookdetails.highPrice / 10000}
                  />
                  <MtoDetails name={"Market Cap($)"} value={marketcap} />
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      <div style={{ marginBottom: "30px" }}>
        <div className="text-end m-2">
          <i
            className="fas fa-filter cur-pointer text-dark"
            onClick={() => setTableSearch(!tableSearch)}
          ></i>
        </div>
        {tableSearch && (
          <div className={`row  my-2 text-center `}>
            <div className="col-md-5">
              <label
                className="text-dark"
                htmlFor="mtoname"
                style={{ marginLeft: "10px" }}
              >
                Owner
              </label>
              <DropdownButton
                title={owner ? owner : "Select an Owner"}
                id="dropdown-basic-button"
                className="custom_dropdown input-box-sm  align-middle filter-dropdown"
                variant=""
              >
                {[
                  { val: "me", name: "Your Orders" },
                  { val: "all", name: "All" },
                ].map((el, i) => (
                  <Dropdown.Item
                    key={i}
                    onClick={() => (
                      setParamsTable({
                        status: paramsTable.status,
                        owner: el.val,
                      }),
                      setOwner(el.name)
                    )}
                  >
                    <span>{el.name}</span>
                  </Dropdown.Item>
                ))}
              </DropdownButton>
            </div>

            <div className="col-md-5">
              <label
                className="text-dark"
                htmlFor="mtoname"
                style={{ marginLeft: "10px" }}
              >
                Status
              </label>
              <DropdownButton
                title={status ? status : "Select a Status"}
                id="dropdown-basic-button"
                className="custom_dropdown input-box-sm  align-middle filter-dropdown"
                variant=""
              >
                {[
                  { val: "confirmed", name: "Confirmed" },
                  { val: "active", name: "Active" },
                ].map((el, i) => (
                  <Dropdown.Item
                    key={i}
                    className="w-75"
                    onClick={() => (
                      setParamsTable({
                        status: el.val,
                        owner: paramsTable.owner,
                      }),
                      setStatus(el.name)
                    )}
                  >
                    <span>{el.name}</span>
                  </Dropdown.Item>
                ))}
              </DropdownButton>
            </div>
            <div className="col-md-2 d-flex align-items-end p-3">
              <button
                className="button-medium border-shadow mx-auto  medium-btn"
                type="submit"
                onClick={() => (
                  setParamsTable({ status: "", owner: "" }),
                  setOwner(""),
                  setStatus("")
                )}
              >
                Clear
              </button>
            </div>
          </div>
        )}
        <div className="row ">
          <div className="col-md-6 my-3">
            <h4 className="text-dark">Buy Orders</h4>
            <div className="align-center" style={{ minHeight: "300px" }}>
              {loadtable ? (
                <Spinner hidden={false} animation="border" />
              ) : (
                <div className="order-table p-1 w-100">
                  {tradetable &&
                  tradetable.buyOrdersDB &&
                  tradetable.buyOrdersDB.length ? (
                    tradetable.buyOrdersDB.map((el, i) => (
                      <TradeOrder type={"buy"} data={el} key={el._id} />
                    ))
                  ) : (
                    <div className="align-center text-dark h-100">
                      No Buy Orders Found
                    </div>
                  )}
                </div>
              )}
            </div>

            <button
              className="button-medium border-shadow mx-auto mt-3"
              onClick={() => (setOrderShow(true), setOrderText("Buy"))}
            >
              Buy
            </button>
          </div>
          <div className="col-md-6 my-3 ">
            <h4 className="text-dark">Sell Orders</h4>
            <div className="align-center" style={{ minHeight: "300px" }}>
              {loadtable ? (
                <Spinner hidden={false} animation="border" />
              ) : (
                <div className="order-table p-1 w-100">
                  {tradetable &&
                  tradetable.sellOrdersDB &&
                  tradetable.sellOrdersDB.length ? (
                    tradetable.sellOrdersDB.map((el, i) => (
                      <TradeOrder type={"sell"} data={el} key={el._id} />
                    ))
                  ) : (
                    <div className="align-center text-dark h-100">
                      No Sell Orders Found
                    </div>
                  )}
                </div>
              )}
            </div>
            {/* for order ,invest and details popup */}
            <button
              className="button-medium border-shadow mx-auto mt-3"
              onClick={() => (setOrderShow(true), setOrderText("Sell"))}
            >
              Sell
            </button>
            {ordershow && (
              <Order
                show={ordershow}
                setShow={setOrderShow}
                text={ordertext}
                bookdetails={bookdetails}
                ual={ual}
                tradelist={tradelist}
                setBookDetails={setBookDetails}
              />
            )}
            {show && !appServices.isLogin() && (
              <InvestCrowdsale
                show={show}
                setShow={setShow}
                text={text}
                liveprice={liveprice}
                crowdsaledata={crowdsaledata}
              />
            )}
            {show && appServices.isLogin() && (
              <AnchorInvestModal
                show={show}
                setShow={setShow}
                text={text}
                liveprice={liveprice}
                crowdsaledata={crowdsaledata}
                ual={ual}
              />
            )}
            {showcrowdsale && (
              <CrowdsaleDetails
                show={showcrowdsale}
                setShow={setShowCrowdsale}
                data={crowdsaledata}
                liveprice={liveprice}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Trade;
