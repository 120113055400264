import React from "react";
import { useState, useEffect } from "react";
import { userApis } from "../../redux/api";
import { getRequiredProtectedData } from "../../redux/apicalltemplate";
import Laptop from "../../icons/laptop.svg";
import Mobile from "../../icons/mobile.svg";
import DeviceDetails from "./DeviceDetails";
import NoRecordTable from "../../components/NoRecordFound/NoRecordTable";
import { useDispatch, useSelector } from "react-redux";
import { userDetailsStart } from "../../redux/user/Action";
import { Spinner } from "react-bootstrap";
const LinkedDevices = () => {
  const [data, setData] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [modalData, setModalData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [reload, setReload] = useState(false);

  const dispatch = useDispatch();
  const userDetails = useSelector((state) => state.user.data);
  useEffect(async () => {
    try {
      setLoading(true);
      dispatch(userDetailsStart({}));
      const responselist = await getRequiredProtectedData(
        "GET",
        userApis.GET_DEVICES,
        {}
      );
      if (responselist && responselist.data) {
        setData(responselist.data.data);
      }
      setLoading(false);
    } catch (e) {
      setData(null);
      setLoading(false);
    }
  }, [reload]);

  const handleModal = (el) => {
    setModalData(el);
    setTimeout(() => {
      setShowModal(true);
    }, 200);
  };
  return (
    <div className="wrapper h-100vh">
      <div className="container mx-auto " style={{ maxWidth: "850px" }}>
        <h1 class="text-center py-3  mb-2 font-weight-bold">Linked Devices</h1>
        <span className="f-14">
          Following is the list of devices you’ve used to Log-in/ access your
          account
        </span>
        <div className="d-flex flex-wrap mt-5 justify-content-center">
          {loading ? (
            <Spinner hidden={false} animation="border" />
          ) : (
            <>
              {data && data.length ? (
                data.map((el, i) => {
                  return (
                    <div
                      key={i}
                      className="container-sm m-2 linked-devices"
                      onClick={() => handleModal(el)}
                    >
                      <div className="custom_img_container">
                        {el.platform == "iPhone" || el.platform == "Android" ? (
                          <img
                            className="smx-image mt-4"
                            style={{ marginBottom: "35px" }}
                            src={Mobile}
                          />
                        ) : (
                          <img className="mid-image" src={Laptop} />
                        )}
                      </div>
                      <div className="text-dark">{el.platform}</div>
                      {el.last_location && (
                        <div className=" ">
                          {el.last_location.city
                            ? el.last_location.city + " ,"
                            : ""}{" "}
                          {el.last_location.country}
                        </div>
                      )}

                      {el.is_verified ? (
                        <div className="my-2 active-device f-12 font-weight-bold">
                          Verified
                        </div>
                      ) : (
                        <div className="my-2 inactive-device f-12 font-weight-bold">
                          Not Verified
                        </div>
                      )}
                      {userDetails && userDetails.device_id === el._id ? (
                        <div className="my-2 active-device f-12 font-weight-bold">
                          <li>Active(This Device)</li>
                        </div>
                      ) : (
                        <div className="my-2 inactive-device f-12 font-weight-bold">
                          <li>InActive</li>
                        </div>
                      )}
                    </div>
                  );
                })
              ) : (
                <NoRecordTable />
              )}
            </>
          )}
        </div>
        {showModal && (
          <DeviceDetails
            show={showModal}
            setShow={setShowModal}
            data={modalData}
            userDetails={userDetails}
            setReload={setReload}
          />
        )}
      </div>
    </div>
  );
};

export default LinkedDevices;
