import React, { useState } from "react";
import { useFetch } from "../../api/api_client";
import { userApis } from "../../redux/api";
import { LoadingView } from "../../components/loading_view";
import {
    Col,
    Dropdown,
    Form,
    Image,
    Row,
    DropdownButton,
} from "react-bootstrap";
import RefreshIcon from "../../icons/refresh.svg";
import { WidthBox } from "../../components/components";
import moment from "moment";
import { getTxnName, txnList } from "../../utils/utils";

export default function RoyalitiesHistory({ accountName }) {
    const [page, setPage] = useState(1);
    const [searchEnable, setSearchEnable] = useState(false);
    const [refresh, setRefresh] = useState(1);
    const [order, setOrder] = useState("-1");
    const [orderName, setOrderName] = useState("Decreasing");
    const [txnName, setTxnName] = useState("");
    const [txnType, setTransactionType] = useState("");
    const musicLimit = 6;

    //?page=1&limit=5&sortBy=&order=-1
    const { loading, error, data } = useFetch(
        `${userApis.ROYALITIES_HISTORY}?page=${page}&limit=${musicLimit}`,
        [page, refresh, order, txnType]
    );
    const clearParams = () => {
        setOrderName("Decreasing");
        setTransactionType("");
        setTxnName("");
        setOrder("-1");
    };


    return (
        <div style={{ minHeight: "200px" }}>
            <div style={{ marginBottom: "16px" }}>
                <div className={"d-flex justify-content-between"}>
                    <span
                        onClick={() => {
                            setRefresh(refresh + 1);
                        }}
                    >
                        <h6 style={{ display: "inline-block" }}>Royalty Releases</h6>
                        <WidthBox width={"4px"} />
                        <Image src={RefreshIcon} className={"icon-setting"} />
                    </span>
                    
                </div>
            </div>
            
            {loading ? (
                <div style={{ height: "200px" }}>
                    <LoadingView />
                </div>
            ) : data ? (
                data["data"].length === 0 ? (
                    <div style={{ height: "200px" }}>
                        <span
                            style={{ marginTop: "18px" }}
                            className={"d-flex flex-row justify-content-center"}
                        >
                            {" "}
                            No transaction found for this wallet
                        </span>
                    </div>
                ) : (
                    <>
                        <div className={"table-responsive order-table box-shadow w-100"}>
                            <table className={"table table-bordered table-hover"}>
                                <thead>
                                    <tr>
                                        <th scope="col">Timestamp</th>
                                        <th scope="col">SaleId</th>
                                        <th scope="col">Amount</th>
                                        <th scope="col">Token name</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {data.data.map((item) => {
                                        return (
                                            <tr>
                                                <td>
                                                    {item["created_at"] &&
                                                        moment(item["created_at"]).format(
                                                            "DD MMM YYYY HH:MM a"
                                                        )}
                                                </td>
                                                
                                                <td>{item["sale_id"]}</td>
                                                <td>
                                                    {item["amount"]}
                                                </td>
                                                <td>
                                                    {item["token_name"]}
                                                </td>
                                                <td>
                                                    <i
                                                        onClick={() => {
                                                            const baseurl =
                                                                process.env.REACT_APP_ENV === "development"
                                                                    ? "telostest"
                                                                    : "telos";
                                                            const url = `https://eosauthority.com/transaction/${item.txnId}?network=${baseurl}`;
                                                            window.open(url, "_blank");
                                                            //  onSelect(item['_id'])
                                                        }}
                                                        className="fas fa-external-link-alt cur-pointer"
                                                    ></i>
                                                </td>
                                            </tr>
                                        );
                                    })}
                                </tbody>
                            </table>
                        </div>
                        <div className={"verified-music-nav-container"}>
                            <span
                                className={page > 1 ? "text-hover " : "page-action-disabled"}
                                onClick={() => {
                                    if (page > 1) setPage(page - 1);
                                }}
                            >
                                PREV
                            </span>
                            <span
                                className={
                                    data.nextPage
                                        ? "page-action-enabled "
                                        : "page-action-disabled"
                                }
                                onClick={() => {
                                    if (data.nextPage) setPage(page + 1);
                                }}
                            >
                                NEXT
                            </span>
                        </div>
                    </>
                )
            ) : (
                <div style={{ height: "200px" }}>
                <span
                    style={{ marginTop: "18px" }}
                    className={"d-flex flex-row justify-content-center"}
                >
                    {" "}
                    No transaction found for this wallet
                </span>
            </div>
            )}
        </div>
    );
}
