import React, { useState } from "react";
import { useEffect } from "react";
import { Form, Modal, Spinner } from "react-bootstrap";
import { toast } from "react-toastify";
import { SubmitButton } from "../../components/components";
import { SuccessToast } from "../../components/ToastMessage/SuccessToast";
import SwalError from "../../components/ToastMessage/SwalError";
import AppServices from "../../services/AppService";
import BlockchainService from "../../services/BlockchainService";
import { acceptedFileTypesArray, imageMaxSize } from "./CreateNewCollection";

const RARITY = ['COMMON', 'UNCOMMON', 'RARE', 'EPIC', 'LEGENDARY', 'MYTHIC', 'OTHER']
const ASSET_TYPE = ['ART', 'GAME', 'PHOTOGRAPHY ', 'MUSIC', 'VIDEO', 'OTHER']
export default function CreateNFT({
  showModal,
  hideModal,
  ual,
  account,
  onSubmit,
  collectionName

}) {
  const [image, setImage] = useState("");
  const [itemDetails, setitemDetails] = useState("");
  const [displayName, setDisplayName] = useState("");
  const [description, setDescription] = useState("");
  const [rarity, setRarity] = useState("RARE");
  const [assetType, setAssetType] = useState("ART");
  const [schema, setSchema] = useState([])
  const [loading, setLoading] = useState(false);
  const [schemaLoader, setschemaLoader] = useState(true);
  const [schemaName, setschemaName] = useState('');
  const [selectedSchema, setselectedSchema] = useState('');
  const resetDetails = () => {
    setImage("");
    setitemDetails("");
    setDisplayName("");
    setDescription("");
    // setMarketFee("")
  };
  const getSchemas = async () => {
    setschemaLoader(true)
    let userSchema = await new AppServices().getCollectionSchema(collectionName);
    const filter_data = userSchema.filter(data => {
      const found = data.format.filter(x => x.name === 'musicId')
      if (found.length !== 0) {
        return data
      } else {
        return null
      }
    })
    setschemaLoader(false)
    setSchema(filter_data)
    return;
  }
  useEffect(() => {
    if (collectionName) {
      getSchemas();
    }
  }, [collectionName])


  const verifyFile = (files) => {
    if (files && files.length > 0) {
      const currentFile = files[0];
      const currentFileType = currentFile.type;
      const currentFileSize = currentFile.size;
      if (currentFileSize > imageMaxSize) {
        SwalError("This file is not allowed. " + currentFileSize / 1000 + " Kb too large");
        setLoading(false)
        return false;
      }
      if (!acceptedFileTypesArray.includes(currentFileType)) {
        SwalError("This file is not supported or file size is too large")
        setLoading(false)
        return false;
      }
      return true;
    }
  };

  const submitHandler = async () => {
    try {
      setLoading(true)
      if (image !== "" && displayName) {
        if (!/^[a-zA-Z0-9]*$/.test(displayName)) {
          SwalError("Please enter valid display name");
          setLoading(false);
          return
        }
        if (displayName.length > 20) {
          SwalError("displayName should not be more than 20 character");
           setLoading(false);
          return;
        }
        if (!verifyFile(image)) return;
        let res = await new AppServices().uploadFileOnIpfs(image[0]);
        if (res) {
          onSubmit({ image: res.data.IpfsHash, displayName, description, assetType, rarity, schema: schema[0].schema_name });
          resetDetails();
          hideModal({ show: false, details: '' });
          setLoading(false)
        } else {
          setLoading(false)
          SwalError("Can't upload image on IPFS")
        }
      } else {
        // if (!/^$|^([a-z1-5]{12})+$/.test(itemDetails)) {
        //   setLoading(false)
        //   SwalError("please enter valid item Detail+ name")
        // } else
        if (!displayName) {
          setLoading(false)
          SwalError("Please enter display name")
        } else {
          setLoading(false)
          SwalError("Please upload a picture")
        }
      }
    } catch (e) {
      setLoading(false)
      SwalError("something went wrong")
    }
  }
  const createSchema = async (schema, currentCollection, schemaData) => {
    setLoading(true);
    if (schema === "") {
      setLoading(false);
      return SwalError("Please enter schema name");
    }
    if (!/^[a-zA-Z0-9]*$/.test(schema)) {
          SwalError("Please enter valid schema name");
          setLoading(false);
          return
        }
    if (!currentCollection) {
      setLoading(false);
      SwalError("somthing went wrong");
    }
    const blockchainService = new BlockchainService()
    const userAccount = ual.activeUser?.accountName
    let createSchemaAction = blockchainService.createSchemaAction(
      userAccount,
      schema,
      currentCollection,
      schemaData
    );
    const result = await blockchainService.pushTransaction(
      createSchemaAction,
      ual,
      "create_schema"
    );
    if (result.success) {
      setTimeout(() => getSchemas(), 1000);
      setLoading(false);
      toast.success(
        <SuccessToast
          message={`Schema created successfully`}
        />,
        {
          toastId: 1,
          updateId: 1,
        }
      );
    } else {
      if (result.message.includes("insufficient ram")) {
        SwalError('Insufficient ram')
        return;
      }
      SwalError(result.message);
      setLoading(false);
    }
  }
  return (
    <Modal
      size="md"
      show={showModal}
      onHide={() => {
        hideModal({ show: false });
        resetDetails();
        setSchema([])
      }}
    >
      <Modal.Header closeButton>
        <Modal.Title className="text-dark">{schema.length > 0 ?
          "Create Music NFT" :
          "Create Schema"}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {schema.length > 0 && schemaLoader === false ? <><Form>
          <label className="input-label">Upload image *</label>
          <input
            className={"input-box-sm"}
            type="file"
            name="image"

            onChange={(e) => setImage(e.target.files)}
          />
          {/* <label className="input-label">Item Details * (12 Character, 1-5 & a-z)</label>
          <input
            className={"input-box-sm"}
            type="text"
            name="itemDetails"

            onChange={(e) => setitemDetails(e.target.value)}
          /> */}
          <label className="input-label">Display Name*</label>
          <input
            className={"input-box-sm"}
            type="text"

            min={0}
            onWheel={(e) => e.target.blur()}
            name="displayName"
            onChange={(e) => setDisplayName(e.target.value)}
          />
          <label className="input-label"> Description
          </label>
          <textarea
            className={"input-box-sm"}
            rows={5}
            type="text"

            name="Description"
            onChange={(e) => setDescription(e.target.value)}
          />
          <label className="input-label"> RARITY
          </label>
          <select required="" name="musicType" class="input-box-sm form-control">
            {RARITY.map((data) => <option onClick={() => setRarity(data)} value="new">{data}</option>)}
          </select>
          <label className="input-label"> ASSET TYPE
          </label>
          <select required="" name="musicType" class="input-box-sm form-control">
            {ASSET_TYPE.map((data) => <option value="new" onClick={() => setAssetType(data)} >{data}</option>)}
          </select>

        </Form></> : schema.length === 0 && schemaLoader === false ? <>
          <Form>
            <label className="input-label">Schema Name</label>
            <input
              className={"input-box-sm"}
              type="text"
              min={0}
              maxLength={12}
              onWheel={(e) => e.target.blur()}
              name="schemaName"
              placeholder="12 Characters Max"
              onChange={(e) => setschemaName(e.target.value)}
            /></Form></> : <>
          <div style={{ height: "150px", display: 'flex', alignItems: "center", justifyContent: 'center' }}> <Spinner animation="border" role="status">
            <span className="visually-hidden">Loading...</span>
          </Spinner></div>

        </>}

      </Modal.Body>
      <Modal.Footer>
        <SubmitButton
          onClick={() => {
            schema.length > 0 ?
              submitHandler() :
              createSchema(schemaName, collectionName)
          }}
          title={schema.length > 0 ?
            "Create NFT" :
            "Create Schema"}
          loading={loading}
        />
      </Modal.Footer>
    </Modal >
  );
}



