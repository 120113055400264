import React, { useState } from "react";
import { useFetch } from "../../api/api_client";
import { stellarApis } from "../../redux/api";
import { LoadingView } from "../../components/loading_view";
import {
  Col,
  Dropdown,
  Form,
  Image,
  Row,
  DropdownButton,
} from "react-bootstrap";
import RefreshIcon from "../../icons/refresh.svg";
import { WidthBox } from "../../components/components";
import moment from "moment";

import NoRecordTable from "../../components/NoRecordFound/NoRecordTable";

export default function StellarTransactionHistory({
  accountName,
  refresh,
  setRefresh,
  token,
}) {
  const [page, setPage] = useState(1);
  const [searchEnable, setSearchEnable] = useState(false);
  // const [refresh, setRefresh] = useState(1);
  const [order, setOrder] = useState("-1");
  const [orderName, setOrderName] = useState("Decreasing");
  const [txnName, setTxnName] = useState("");
  const [txnType, setTransactionType] = useState("");
  const musicLimit = 6;

  const stellarAccount = localStorage.getItem("stellerPublicKey");
  //?page=1&limit=5&sortBy=&order=-1
  const { loading, error, data } = useFetch(
    `${stellarApis.STELLAR_TRANSACTION_HISTORY}?page=${page}&limit=${musicLimit}&order=${order}&txnType=${txnType}`,
    [page, refresh, order, txnType],
    token
  );
  const clearParams = () => {
   
    setOrderName("Decreasing");
    setTransactionType("");
    setTxnName("");
    setOrder("-1");
  };

  return (
    <div style={{ minHeight: "200px" }}>
      <div style={{ marginBottom: "16px" }}>
        <div className={"d-flex justify-content-between"}>
          <span
            onClick={() => {
              setRefresh(refresh + 1);
            }}
          >
            <h6 style={{ display: "inline-block" }}>Transaction History</h6>
            <WidthBox width={"4px"} />
            <Image src={RefreshIcon} className={"setting-icon"} />
          </span>
          <div className="text-end m-2">
            <i
              className="fas fa-filter cur-pointer text-dark"
              onClick={() => setSearchEnable(!searchEnable)}
            ></i>
          </div>
        </div>
      </div>
      {searchEnable && (
        <div className={`row  my-2 text-center `}>
          {/* <div className="col-md-5">
            <label
              className="text-dark"
              htmlFor="mtoname"
              style={{ marginLeft: "10px" }}
            >
              Transaction Type
            </label>
            <DropdownButton
              title={txnName ? txnName : "Select a Transaction"}
              id="dropdown-basic-button"
              className="custom_dropdown input-box-sm  align-middle filter-dropdown"
              variant=""
            >
              {txnList.map((el, i) => (
                <Dropdown.Item
                  key={i}
                  onClick={() => (
                    setTransactionType(el.val), setTxnName(el.name)
                  )}
                >
                  <span>{el.name}</span>
                </Dropdown.Item>
              ))}
            </DropdownButton>
          </div> */}

          <div className="col-md-5">
            <label
              className="text-dark"
              htmlFor="mtoname"
              style={{ marginLeft: "10px" }}
            >
              Order By
            </label>
            <DropdownButton
              title={orderName ? orderName : "Select a Order"}
              id="dropdown-basic-button"
              className="custom_dropdown input-box-sm  align-middle filter-dropdown"
              variant=""
            >
              {[
                { val: "1", name: "Increasing" },
                { val: "-1", name: "Decreasing" },
              ].map((el, i) => (
                <Dropdown.Item
                  key={i}
                  onClick={() => (setOrder(el.val), setOrderName(el.name))}
                >
                  <span className="w-100">
                    {el.name}
                    {el.name === "Increasing" ? (
                      <i className="fas fa-angle-up mx-2"></i>
                    ) : (
                      <i className="fas fa-angle-down mx-2"></i>
                    )}
                  </span>
                </Dropdown.Item>
              ))}
            </DropdownButton>
          </div>
          <div className="col-md-2 d-flex align-items-end p-3">
            <button
              className="button-medium border-shadow mx-auto  medium-btn"
              type="submit"
              onClick={() => clearParams()}
            >
              Clear
            </button>
          </div>
        </div>
      )}
      {loading ? (
        <div style={{ height: "200px" }}>
          <LoadingView />
        </div>
      ) : data ? (
        data["data"].length === 0 ? (
          <div style={{ height: "200px" }}>
            <span
              style={{ marginTop: "18px" }}
              className={"d-flex flex-row justify-content-center"}
            >
              {" "}
              No transaction found for this wallet
            </span>
          </div>
        ) : (
          <>
            <div className={"table-responsive order-table box-shadow w-100"}>
              <table className={"table table-bordered table-hover"}>
                <thead>
                  <tr>
                    <th scope="col">Timestamp</th>
                    <th scope="col">From</th>
                    <th scope="col">To</th>
                    <th scope="col">Quantity</th>

                    <th scope="col">Type</th>
                    <th scope="col">Status</th>
                  </tr>
                </thead>
                <tbody>
                  {data.data.map((item) => {
                    return (
                      <tr>
                        <td>
                          {item["created_at"] &&
                            moment(item["created_at"]).format(
                              "DD MMM YYYY hh:mm a"
                            )}
                        </td>
                        <td>
                          <div
                            style={{ width: "200px", wordBreak: "break-all" }}
                          >
                            {item["from"] ? item["from"] : "-"}
                          </div>
                          <i
                            onClick={() => {
                              const baseurl =
                                process.env.REACT_APP_ENV == "development"
                                  ? "public"
                                  : "public";
                              const url = `https://stellar.expert/explorer/${baseurl}/account/${item.from}`;
                              window.open(url, "_blank");
                              //  onSelect(item['_id'])
                            }}
                            className="fas fa-external-link-alt cur-pointer"
                          ></i>
                        </td>
                        <td>
                          <div
                            style={{ width: "200px", wordBreak: "break-all" }}
                          >
                            {item["to"] ? item["to"] : "-"}
                            <i
                              onClick={() => {
                                const baseurl =
                                  process.env.REACT_APP_ENV === "development"
                                    ? "public"
                                    : "public";
                                const url = `https://stellar.expert/explorer/${baseurl}/account/${item.to}`;
                                window.open(url, "_blank");
                                //  onSelect(item['_id'])
                              }}
                              className="fas fa-external-link-alt cur-pointer"
                            ></i>
                          </div>
                        </td>
                        <td>{item["asset"] ? item["asset"] : "-"}</td>
                        {/* <td>{item.type ? getTxnName(item.type) : "-"}</td> */}

                        <td>{item.type ? item.type : "-"}</td>
                        <td>{item["status"] ? item["status"] : "-"}</td>
                        {/* <td>
                          <i
                            onClick={() => {
                              const baseurl =
                                process.env.REACT_APP_ENV === "development"
                                  ? "public"
                                  : "public";
                              //const url = `https://eosauthority.com/transaction/${item.txnId}?network=${baseurl}`;
                              const url = `https://stellar.expert/explorer/${baseurl}/tx/${item.hash}`
                              window.open(url, "_blank");
                              //  onSelect(item['_id'])
                            }}
                            className="fas fa-external-link-alt cur-pointer"
                          ></i>
                        </td> */}
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
            <div className={"verified-music-nav-container"}>
              <span
                className={page > 1 ? "text-hover " : "page-action-disabled"}
                onClick={() => {
                  if (page > 1) setPage(page - 1);
                }}
              >
                PREV
              </span>
              <span
                className={
                  data.nextPage
                    ? "page-action-enabled "
                    : "page-action-disabled"
                }
                onClick={() => {
                  if (data.nextPage) setPage(page + 1);
                }}
              >
                NEXT
              </span>
            </div>
          </>
        )
      ) : (
        <NoRecordTable />
      )}
    </div>
  );
}
