import React, { useState } from "react";
import { Modal, Form } from "react-bootstrap";
import { useEffect } from "react";
import InfoToast from "../../components/ToastMessage/InfoToast";
import { useDispatch, useSelector } from "react-redux";
import { getAxiosData } from "../../redux/apicalltemplate";
import { crowdsaleApis, userApis } from "../../redux/api";
import BlockchainService from "../../services/BlockchainService";
import { toast } from "react-toastify";
import {
  ErrorToast,
  SuccessToast,
} from "../../components/ToastMessage/SuccessToast";
import { ToggleSwitch } from "../../components/Buttons/ToggleSwitch/ToggleSwitch";
import { investCrowdsaleStart } from "../../redux/crowdsale/Action";
import { useHistory } from "react-router-dom";
import InfoBox from "../../components/InfoMessage/InfoHover";
const blockchainService = new BlockchainService();
const AnchorInvestModal = ({
  show,
  setShow,
  text,
  liveprice,
  crowdsaledata,
  ual,
}) => {
  const [terms, setTerms] = useState(false);
  const [amount, setAmount] = useState("");
  const [toggle, setToggle] = useState("crypto");
  const [minInvest, setMinInvest] = useState(0);
  const [maxInvest, setMaxInvest] = useState(0);
  const [type, setType] = useState("Crowdsale");
  const [investloading, setinvestloading] = useState(false);
  const investLoading = useSelector((state) => state.crowdsale.investloading);
  const history = useHistory();
  const dispatch = useDispatch();

  const handleClose = () => {
    setShow(false);
    setAmount("");
  };

  const changeToggle = () => {
    if (toggle === "fiat") {
      setToggle("crypto");
    } else {
      InfoToast('Fiat option is disabled temporarily.')
      // setToggle("fiat");
    }
  };
  //   const investloading = useSelector((state) => state.crowdsale.investloading);

  useEffect(() => {
    setMinInvest(crowdsaledata.minContributionByUser);
    setMaxInvest(crowdsaledata.maxContributionByUser);
    if (crowdsaledata && crowdsaledata.musicId) {
      if (crowdsaledata.musicId.musicType === "new") {
        setType("Crowdfund");
      } else {
        setType("Crowdsale");
      }
    }
    // percentageRights: item.percentageRights * item.rate,
  }, [crowdsaledata]);

  //handle invest----------------------
  const handleInvestInCrowdsale = async (event) => {
    event.preventDefault();
    setinvestloading(true);

    if (ual.activeUser === null) {
      InfoToast(`Please Connect Your Anchor Wallet`);
      setinvestloading(false);
      return;
    }
    if (terms) {
      let data = {};

      if (parseInt(amount) <= 0) {
        InfoToast(`Please enter valid amount`);
        setinvestloading(false);
        return;
      }

      if (toggle === "crypto") {
        data.amount = amount * liveprice.data.zeptaPrice;
      } else {
        data.amount = amount;
      }

      data.crowdsaleId = crowdsaledata._id;
      if (toggle === "crypto") {
        if (data.amount < minInvest) {
          InfoToast(
            `Amount must be greater than or equal to minimum investment ie. ${minInvest} $`
          );
          setinvestloading(false);
        } else {
          let dataTelos = {};
          dataTelos.amount = amount;
          dataTelos.crowdsaleId = data.crowdsaleId;
          dataTelos.accountName = ual.activeUser?.accountName;
          try {
            const tnx = await getAxiosData(
              "POST",
              crowdsaleApis.INVEST_IN_CROWDSALE_WITH_ANCHOR,
              { ...dataTelos },
              {}
            );
            if (tnx.data && tnx.data.success) {
              const resullt = await blockchainService.pushTransaction(
                tnx.data.tnx,
                ual,
                "invest",
                tnx.data.optionalData
              );
              if (resullt.success) {
                setinvestloading(false);
                toast.success(
                  <SuccessToast
                    message={
                      tnx.data.optionalData.amount +
                      " ZPTC invested in " + type
                    }
                  />,
                  {
                    toastId: 1,
                    updateId: 1,
                  }
                );
                handleClose();
                history.push("/portfolio")
              } else {
                setinvestloading(false);
                toast.error(<ErrorToast message={resullt.message} />, {
                  toastId: 1,
                  updateId: 1,
                });
              }
            } else if (tnx.data && !tnx.data.success) {
              toast.error(<ErrorToast message={data.message} />, {
                toastId: 1,
                updateId: 1,
              });
              setinvestloading(false);
            } else {
              toast.error(<ErrorToast message={data.message} />, {
                toastId: 1,
                updateId: 1,
              });
              setinvestloading(false);
            }
          } catch (error) {
            setinvestloading(false);
            if (error && error.response && error.response.data) {
              toast.error(
                <ErrorToast message={error.response.data.message} />,
                {
                  toastId: 1,
                  updateId: 1,
                }
              );
            } else {
              toast.error(
                <ErrorToast message={"Please try again after sometime..."} />,
                {
                  toastId: 1,
                  updateId: 1,
                }
              );
            }
          }
        }
      } else if (toggle === "fiat") {
        if (amount < minInvest) {
          InfoToast(
            `Amount must be greater than or equal to  minimum investment ie. ${minInvest} $`
          );
          setinvestloading(false);
        } else if (amount < 10) {
          InfoToast(`Min Amount must be $ 10`);
          setinvestloading(false);
        } else {
          let dataFiat = {};
          let amountSend =
            Number(amount) + (amount / 100) * 2.5 + (amount / 100) * 5;
          dataFiat.amount = Math.round(amountSend * 100) / 100;
          dataFiat.actualAmount = amount;
          dataFiat.crowdsaleId = data.crowdsaleId;
          dispatch(investCrowdsaleStart({ data: dataFiat, type: "Anchor" }));
        }
      }
    } else {
      InfoToast("Please agree to the terms to invest");
      setinvestloading(false);
    }
  };

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Invest in {type} <InfoBox
              className="ml-2"
              msg={
                <p>To see guide, checkout our video tutorial {
                  toggle=='fiat' ?
                  <a href="https://www.youtube.com/watch?v=cwNtviCB3kQ" target="_blank">click here : FIAT</a> :
                   <a href="https://www.youtube.com/watch?v=kzhlZSADRfA" target="_blank">click here : ZPTC</a>
                  }
                  
                  </p>
                
              }
            />  </Modal.Title>
      </Modal.Header>
      <Form onSubmit={handleInvestInCrowdsale}>
        <Modal.Body className="p-5">
          <div className="d-flex align-items-center justify-content-center flex-wrap m-2 mb-5">
            <div className="text-dark mx-3">Fiat($)</div>
            <ToggleSwitch value={toggle} setValue={changeToggle} />
            <div className="text-dark mx-3">Zeptacoin(ZPTC)</div>
          </div>
          <label className="text-dark" style={{ marginLeft: "10px" }}>
            Enter your Amount({toggle == "fiat" ? "" : "ZPTC"} incl. VAT)
          </label>
          <Form.Control
            className={"input-box-sm"}
            type="number"
            onWheel={(e) => e.target.blur()}
            name="amount"
            value={amount}
            onChange={(e) => {
              if (e.target.value.length <= 9) {
                setAmount(e.target.value);
              }
            }}
            placeholder="Amount"
            autoComplete="false"
            maxLength="11"
            required
          />
          <div style={{ fontSize: "12px" }} className="  text-dark text-end">
            ~{" "}
            {toggle == "fiat"
              ? amount
              : (amount * liveprice?.data.zeptaPrice).toFixed(2)}{" "}
            USD
          </div>
          <label className="text-dark" style={{ marginLeft: "10px" }}>
            Amount + {toggle == "fiat" ? 7 : 5}% fee ($)
          </label>
          <Form.Control
            className={"input-box-sm"}
            value={
              amount
                ? (toggle == "fiat"
                  ? amount * 1.07
                  : amount * liveprice.data.zeptaPrice * 1.05
                ).toFixed(2)
                : ""
            }
            type="number"
            disabled
            onWheel={(e) => e.target.blur()}
            placeholder="Total Amount"
          />

          <div>
            <Form.Check
              type="checkbox"
              onClick={(e) => setTerms(e.target.checked)}
              label={
                <span>
                  I agree to the
                  <a
                    href={
                      type == "Crowdfund"
                        ? "https://zeptaterms.s3.amazonaws.com/69+C+Crowdfunding+Campaigns+Zeptagram+AB-3.docx"
                        : "https://zeptagram-test2.s3.eu-north-1.amazonaws.com/sellerandbuyer-converted.pdf"
                    }
                    target="_blank"
                    className="text-dark cur-pointer"
                  >
                    {" "}
                    Zeptagram {type} Agreement
                  </a>
                </span>
              }
              required
            />
          </div>
        </Modal.Body>
        <Modal.Footer className="p-3">
          {toggle === "fiat" ? (
            <button
              className="button-medium border-shadow   medium-btn"
              type="submit"
              title={"Update Profile"}
              disabled={investLoading ? true : false}
            >
              {investLoading ? (
                <span
                  className="spinner-border spinner-border-smd"
                  role="status"
                  aria-hidden="true"
                ></span>
              ) : (
                "Submit"
              )}
            </button>
          ) : (
            <button
              className="button-medium border-shadow   medium-btn"
              type="submit"
              title={"Update Profile"}
              disabled={investloading ? true : false}
            >
              {investloading ? (
                <span
                  className="spinner-border spinner-border-smd"
                  role="status"
                  aria-hidden="true"
                ></span>
              ) : (
                "Submit"
              )}
            </button>
          )}
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

export default AnchorInvestModal;
