import React from "react";
import "./Maintanence.scss";
const Mantainence = () => {
  return (
    <div className="align-center flex-column " style={{ height: "100vh" }}>
      <img className="maint-svg" src="https://i.imgur.com/priepQL.png" />
      <h1 className="text-dark text-center">Site under Maintainence</h1>
      <h5 className="text-center">
        {" "}
        We're busy upgrading with new technology. We apologize for the
        inconvenience.{" "}
      </h5>
    </div>
  );
};

export default Mantainence;
