import React, { useEffect, useState } from "react";
import { Modal, Form } from "react-bootstrap";
import InfoToast from "../../components/ToastMessage/InfoToast";
import SwalSuccess from "../../components/ToastMessage/SwalSuccess";
import SwalError from "../../components/ToastMessage/SwalError";
import { getRequiredProtectedData } from "../../redux/apicalltemplate";
import { crowdsaleApis } from "../../redux/api";
import DatePicker from "react-datepicker";
import moment from "moment";

const ExtendCrowdsale = ({
  show,
  setShow,
  id,
  modaldata,
  musicType,
  handleReloadCrowdsale,
}) => {
  const [enddate, setEndDate] = useState("");
  const handleClose = () => setShow(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (modaldata) {
      setEndDate(new Date(modaldata.endDate));
    }
  }, [modaldata]);

  //handle form submit------------------------
  const handleSubmit = async (event) => {
    event.preventDefault();
    let extendedEndDate = new Date(enddate);
    let originalEndDate = new Date(modaldata.endDate);

    if (extendedEndDate > originalEndDate) {
      let data = {};
      data.saleid = modaldata.saleId;
      data.endDate = moment(extendedEndDate).endOf("day");
      // return;
      setLoading(true);
      const response = await getRequiredProtectedData(
        "POST",
        `${crowdsaleApis.EXTEND_CROWDSALE}/${id}`,
        data,
        {}
      );
      if (response && response.data && response.data.success) {
        SwalSuccess(response.data.message);
        setTimeout(() => {
          setShow(false);
          handleReloadCrowdsale();
        }, 800);
      }
      if (response && response.data && !response.data.success) {
        SwalError(response.data.message);
      }
      if (response && response.response && response.response.data) {
        SwalError(response.response.data.message);
      }
      setLoading(false);
    } else {
      InfoToast(
        `End date must be greater than ${originalEndDate.toDateString()}`
      );
    }
  };

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Extend {musicType==='new' ? 'Crowdfunding' : 'Crowdsale'}</Modal.Title>
      </Modal.Header>
      <Form onSubmit={handleSubmit}>
        <Modal.Body className="p-5">
          <label className="text-dark" style={{ marginLeft: "10px" }}>
            End Date*
          </label>
          <DatePicker
            selected={enddate}
            onChange={(date) => setEndDate(date)}
            placeholderText={"End Date"}
            minDate={new Date()}
            dateFormat="MMMM d, yyyy "
            required
          />

          <div className="text-dark m-3" style={{ fontSize: "15px" }}>
            Extended date must be greater than{" "}
            {new Date(modaldata.endDate).toDateString()}
          </div>
        </Modal.Body>
        <Modal.Footer className="p-3">
          <button
            className="button-medium border-shadow medium-btn"
            type="submit"
            title={"Extend"}
            disabled={loading}
          >
            {!loading ? (
              "Extend"
            ) : (
              <>
                <span
                  className="spinner-border spinner-border-smd"
                  role="status"
                  aria-hidden="true"
                ></span>
              </>
            )}
          </button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

export default ExtendCrowdsale;
