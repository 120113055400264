export const crowdsaleActionTypes = {
  POPULAR_CROWDSALE_START: "POPULAR_CROWDSALE_START",
  POPULAR_CROWDSALE_SUCCESS: "POPULAR_CROWDSALE_SUCCESS",
  POPULAR_CROWDSALE_FAILURE: "POPULAR_CROWDSALE_FAILURE",

  RECOMMEND_CROWDSALE_START: "RECOMMEND_CROWDSALE_START",
  RECOMMEND_CROWDSALE_SUCCESS: "RECOMMEND_CROWDSALE_SUCCESS",
  RECOMMEND_CROWDSALE_FAILURE: "RECOMMEND_CROWDSALE_FAILURE",

  LIVE_CROWDSALE_START: "LIVE_CROWDSALE_START",
  LIVE_CROWDSALE_SUCCESS: "LIVE_CROWDSALE_SUCCESS",
  LIVE_CROWDSALE_FAILURE: "LIVE_CROWDSALE_FAILURE",

  LIVE_CROWDFUND_START: "LIVE_CROWDFUND_START",
  LIVE_CROWDFUND_SUCCESS: "LIVE_CROWDFUND_SUCCESS",
  LIVE_CROWDFUND_FAILURE: "LIVE_CROWDFUND_FAILURE",

  GET_LIVE_PRICE_START: "GET_LIVE_PRICE_START",
  GET_LIVE_PRICE_SUCCESS: "GET_LIVE_PRICE_SUCCESS",
  GET_LIVE_PRICE_FAILURE: "GET_LIVE_PRICE_FAILURE",

  INVEST_CROWDSALE_START: "INVEST_CROWDSALE_START",
  INVEST_CROWDSALE_SUCCESS: "INVEST_CROWDSALE_SUCCESS",
  INVEST_CROWDSALE_FAILURE: "INVEST_CROWDSALE_FAILURE",

  INVEST_CROWDSALE_CONFIRM_START: "INVEST_CROWDSALE_CONFIRM_START",
  INVEST_CROWDSALE_CONFIRM_SUCCESS: "INVEST_CROWDSALE_CONFIRM_SUCCESS",
  INVEST_CROWDSALE_CONFIRM_FAILURE: "INVEST_CROWDSALE_CONFIRM_FAILURE",

  SUCCESSFUL_CROWDSALE_START: "SUCCESSFUL_CROWDSALE_START",
  SUCCESSFUL_CROWDSALE_SUCCESS: "SUCCESSFUL_CROWDSALE_SUCCESS",
  SUCCESSFUL_CROWDSALE_FAILURE: "SUCCESSFUL_CROWDSALE_FAILURE",

  FINALIZED_CROWDSALE_START: "FINALIZED_CROWDSALE_START",
  FINALIZED_CROWDSALE_SUCCESS: "FINALIZED_CROWDSALE_SUCCESS",
  FINALIZED_CROWDSALE_FAILURE: "FINALIZED_CROWDSALE_FAILURE",

  FINALIZED_CROWDFUND_START: "FINALIZED_CROWDFUND_START",
  FINALIZED_CROWDFUND_SUCCESS: "FINALIZED_CROWDFUND_SUCCESS",
  FINALIZED_CROWDFUND_FAILURE: "FINALIZED_CROWDFUND_FAILURE",
};
