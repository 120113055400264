import AccountInfoComponent from "../../components/account-info-component";
import { Image, Table, Form, Modal, Row, Col } from "react-bootstrap";
import { WidthBox, HeightBox, SubmitButton } from "../../components/components";
import "../../styles/style.scss";
import WalletButton from "../../components/wallet_button";
import AddIcon from "../../icons/add.svg";
import ReceiveIcon from "../../icons/receive.svg";
import WithdrawIcon from "../../icons/withdraw.svg";
import SendIcon from "../../icons/send.svg";
import { getRequiredProtectedData } from "../../redux/apicalltemplate";
import { useFetch, useSubmit, useUpload } from "../../api/api_client";
import { userApis } from "../../redux/api";
import React, { useEffect, useReducer, useState } from "react";
import QRCode from "qrcode.react";
import PinInput from "react-pin-input";
import AddCoinModal from "./add_modal";
import CreateWalletModal from "./create_wallet_modal";
import AdvanceDetailsModal from "./advance_details_modals";
import MaleIcon from "../../icons/male_icon.jpeg";
import FemaleIcon from "../../icons/female_icon.jpeg";
import ClaimTokensale from "./claim_tokensale";
import CreateWallet from "../../images/createwallet.svg";
import {
  isNotValid,
  isValid,
  isValidInstagramUrl,
  isValidTwitterUrl,
  notifyError,
  notifySuccess,
} from "../../utils/utils";
import SwapIcon from "../../icons/swap.svg";
import ConfirmPinModal from "./confim_pin_modal";
import { LoadingView } from "../../components/loading_view";
import TransactionHistory from "./transaction_history";
import { userDetailsStart } from "../../redux/user/Action";
import { ToggleSwitch } from "../../components/Buttons/ToggleSwitch/ToggleSwitch";
import SwalError from "../../components/ToastMessage/SwalError";
import PrivateKey from "./PrivateKey";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import TokenBridge from "./tokenbridge/TokenBridge";
import InfoBox from "../../components/InfoMessage/InfoHover";
import RoyalitiesHistory from "./royalities_history";
import WalletConnectLobstr from "../Swap/walletConnectLobstr";
import SwapWalletButton from "../Swap/Swap_wallet_button";
import SwapTelos from "../Swap/SwapTelos";
import Swap from "../Swap/Swap";
import
{
    getPublicKey,
    isConnected,
    isAllowed,
    setAllowed,
    getUserInfo,
    signAuthEntry,
    signTransaction,
    signBlob,
} from "@stellar/freighter-api";
import { result } from "underscore";

export default function WalletPage() {
  function stateReducer(state, action) {
    switch (action.type) {
      case "showSendModal":
        return { ...state, sendModal: !state["sendModal"] };
      case "setProfileData":
        return { ...state, sendData: action["sendData"] };
      case "setSendingStatus":
        return { ...state, sending: !state["sending"] };
      case "showReceiveModal":
        return { ...state, receiveModal: !state["receiveModal"] };
      case "showWithdrawModal":
        return { ...state, withdrawModal: !state["withdrawModal"] };
      case "changeWithdrawType":
        return {
          ...state,
          withdrawType: state["withdrawType"] === "crypto" ? "flat" : "crypto",
        };
      case "setWithdrawFlatData":
        return { ...state, withdrawFlatData: state["withdrawFlatData"] };
      case "setWithdrawCryptoData":
        return { ...state, withdrawCryptoData: state["withdrawCryptoData"] };
      case "showPinModal":
        return { ...state, pinModal: !state["pinModal"] };
      case "setWithdrawStatus":
        return { ...state, withdrawStatus: !state["withdrawStatus"] };
      case "showAddModal":
        return { ...state, addModal: !state["addModal"] };
      case "showCreateWalletModal":
        return { ...state, createWalletModal: !state["createWalletModal"] };
      case "showDetailsModal":
        return { ...state, detailsModal: !state["detailsModal"] };
      case "clearWithdrawlModal":
        return {
          ...state,
          withdrawCryptoData: {
            zeptacoin: "",
            telos: "",
          },
          withdrawFlatData: {
            zeptacoin: "",
            usd: "",
          },
        };
      case "clear":
        return {
          sendModal: false,
          sendData: {},
          sending: false,
          receiveModal: false,
          withdrawModal: false,
          withdrawType: "crypto",
          pinModal: false,
          withdrawCryptoData: {
            zeptacoin: "",
            telos: "",
            pin: "",
          },
          withdrawFlatData: {
            zeptacoin: "",
            usd: "",
          },
          withdrawStatus: false,
          createWalletModal: false,
          detailsModal: false,
        };
      default:
        return state;
    }
  }

  //----loaders-----------
  const [withdrawLoading, setWithdrawLoading] = useState(false);
  const [sendLoading, setSendLoading] = useState(false);
  const [addLoading, setAddLoading] = useState(false);
  const [showClaim, setShowClaim] = useState(false);
  const [refreshWallet, setRefreshWallet] = useState(false);
  const [claimmodal, setClaimModal] = useState(false);
  const [privatekeyModal, setPrivateKeyModal] = useState(false);
  const [claimData, setClaimData] = useState([]);
  const [showTokenSwap, setShowTokenSwap] = useState(false);
  const [publicKeyAnchor, setPublicKeyAnchor] = useState("");
  const userdata = useSelector((state) => state.user.data);
  const [userRefresh, setUserRefresh] = useState(false);
  const history = useHistory();
  const dispatch = useDispatch();
  const [zptcBalance, setZptcBalance] = useState("");
  const [stellarZptc, setstellarZptc] = useState(0);
  const [stellarLTM, setstellarLTM] = useState(0);
  const [swapModel, setSwapModel] = useState(false);
  const [refreshTransaction, setRefreshTransaction] = useState(1);
  const token = localStorage.getItem("token");
  const [freighterKey, setFreighterKey] = useState("")
  let publicKey;

useEffect(() => {
  const retrieveUserInfo = async () => {
    try {
      let userInfo = await getUserInfo();

      if (!userInfo.publicKey) {
        await setAllowed();
        userInfo = await getUserInfo();
      }

      return userInfo.publicKey;
    } catch (e) {
      console.error(e); // It's a good practice to log errors
      return null;
    }
  };

  retrieveUserInfo().then(publicKey => {
    console.log("USER INFO============", publicKey);
    setFreighterKey(publicKey);
  }).catch(error => {
    console.error("Error in retrieving user info: ", error);
  });
}, []);
 
  const walletConnect = new WalletConnectLobstr();
  useEffect(() => {
    dispatch(userDetailsStart({}));
  }, []);

  const fetchStellarZptcBalance = async (publicKey) => {
    try {
      const balance = await walletConnect.getStellarZPTCBalance(publicKey);
      setstellarZptc(balance);
    } catch (e) {
      console.log(e);
    }
  };

  const fetchStellarLTMBalance = async (publicKey) => {
    try {
      const balance = await walletConnect.getStellarLTMBalance(publicKey);
      setstellarLTM(balance);
    } catch (e) {
      console.log(e);
    }
  };

useEffect(() => {
  const fetchData = async () => {
    if (publicKey) {
      try {
        await walletConnect.initWalletConnect();
        await fetchStellarLTMBalance(publicKey);
        await fetchStellarZptcBalance(publicKey);
      } catch (error) {
        console.error('Error fetching data with publicKey:', error);
      }
    }
  };

  fetchData();
}, [publicKey, refreshTransaction]);

  const [pageState, dispatchState] = useReducer(stateReducer, {
    sendModal: false,
    sendData: {},
    sending: false,
    receiveModal: false,
    withdrawModal: false,
    withdrawType: "crypto",
    pinModal: false,
    withdrawCryptoData: {
      zeptacoin: "",
      telos: "",
      pin: "",
    },
    withdrawFlatData: {
      zeptacoin: "",
      usd: "",
    },
    withdrawStatus: false,
    createWalletModal: false,
    detailsModal: false,
  });

  const dispatchAction = (type, data) => {
    dispatchState({ type: type, data: data });
  };
  const handleSendDataChange = (e) => {
    let sendData = pageState["sendData"];
    sendData[e.target.name] = e.target.value;
    dispatchState({ type: "setSendData", sendData: sendData });
  };

  //---tokensale participate--
  useEffect(async () => {
    const response = await getRequiredProtectedData(
      "GET",
      userApis.CHECK_CLAIM_TOKENSALE
    );

    if (response && response.data && response.data.success) {
      if (typeof response.data?.data !== "string") {
        setShowClaim(true);
        setClaimData(response.data.data);
      }
    }
    if (response && response.data && !response.data.success) {
      setShowClaim(false);
    }
    if (response && response.response && response.response.data) {
      setShowClaim(false);
    }

  }, []);




  //-------------send---------------------------------------------------
  const sendCoins = async () => {
    const { account, quantity, pin, memo } = pageState["sendData"];

    if (isNotValid(account) || account.length !== 12) {
      notifyError("Enter valid account name");
      return;
    }
    if (isNotValid(quantity) || parseInt(quantity) <= 0) {
      notifyError("Enter valid quantity");
      return;
    }
    if (isNotValid(pin) || pin.length !== 4) {
      notifyError("Pin must be 4 digit long");
      return;
    }

    if (isNotValid(memo)) {
      notifyError("Enter valid memo");
      return;
    }

    dispatchState({ type: "setSendingStatus" });
    // eslint-disable-next-line react-hooks/rules-of-hooks
    await useSubmit(userApis.SEND_ZEPTACOIN, pageState["sendData"], "POST")
      .then((response) => {
        dispatchState({ type: "setSendingStatus" });
        dispatchState({ type: "showSendModal" });
        setRefreshWallet(!refreshWallet);
        notifySuccess("Successfully sent");
      })
      .catch((e) => {
        notifyError(e.message);

        dispatchState({ type: "setSendingStatus" });
      });
  };

  // -------------------------withdraw ---------------------------------------------
  const withdrawCoins = async () => {
    const type = pageState["withdrawType"];
    const data = {};
    let url = userApis.WITHDRAW_CRYPTO;

    if (
      pageState["withdrawCryptoData"].pin === undefined ||
      pageState["withdrawCryptoData"].pin.length !== 4
    ) {
      notifyError("Enter valid pin");
      return;
    }
    if (type === "crypto") {
      data["pin"] = pageState["withdrawCryptoData"].pin;
      data["quantity"] = pageState["withdrawCryptoData"].zeptacoin;
    } else {
      url = userApis.WITHDRAW_FLAT;
      data["amount"] = `${pageState["withdrawFlatData"].zeptacoin}`;
    }
    if (
      type === "crypto" &&
      (isNotValid(data["quantity"]) || data["quantity"] === 12)
    ) {
      notifyError("Enter valid quantity");
      return;
    }

    dispatchState({ type: "setWithdrawStatus" });
    setWithdrawLoading(true);
    // eslint-disable-next-line react-hooks/rules-of-hooks
    await useSubmit(url, data, "POST")
      .then((response) => {
        dispatchState({ type: "setWithdrawStatus" });
        dispatchState({ type: "showWithdrawModal" });
        setWithdrawLoading(false);
        setRefreshWallet(!refreshWallet);
        notifySuccess("Successfully sent");
      })
      .catch((e) => {
        notifyError(e.message);
        setWithdrawLoading(false);
        dispatchState({ type: "setWithdrawStatus" });
      });
  };

  const userDetails = useFetch(userApis.GET_DETAILS, [userRefresh]);
  const livePrices = useFetch(userApis.LIVE_PRICES);

  // let publicKey = payload.data.namespaces.stellar.accounts[0].slice(15);
  const { data, error, loading } = useFetch(userApis.WALLET_DETAILS, [
    refreshWallet,
  ]);



const payload = JSON.parse(localStorage.getItem("payload"));

if (payload) {
  publicKey = payload.data.namespaces.stellar.accounts[0].slice(15);
} 
else if(freighterKey != null){
publicKey = freighterKey;
}
else {
  ({ publicKey } = (data && data["data"]) || {});
}


  const { advancedInfo } = data || {};
  console.log("DATA", data)
  const { accountName, balance, ownerKey, ownerId, tlosBalance,  } =
    (data && data["data"]) || {};

    console.log("PUBLIC KEY", publicKey)

  if (loading || livePrices["loading"] || userDetails["loading"])
    return <LoadingView />;

  const { eosPrice, zeptaPrice } =
    (livePrices["data"] && livePrices["data"].data) || {};
  var telosInZepta = eosPrice && (zeptaPrice / eosPrice).toFixed(4);
  var zeptaInTelos = zeptaPrice && (eosPrice / zeptaPrice).toFixed(4);

  const handleWithdrawDataChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;

    if (name === "pin") {
      const cryptoData = pageState["withdrawCryptoData"];
      cryptoData[name] = value;
    } else if (pageState["withdrawType"] === "crypto") {
      const cryptoData = pageState["withdrawCryptoData"];
      cryptoData[name] = value;
      dispatchAction("setWithdrawCryptoData", cryptoData);
      if (value === "") {
        cryptoData["zeptacoin"] = "";
        cryptoData["telos"] = "";
      } else if (name === "zeptacoin") {
        var zeptacoin = Number.parseFloat(value);
        var telos = zeptacoin * telosInZepta; //TODO conversion over here
        cryptoData["telos"] = telos;
      } else {
        var telos = Number.parseFloat(value);
        var zeptacoin = telos * zeptaInTelos; //TODO conversion over here
        cryptoData["zeptacoin"] = zeptacoin;
      }
      dispatchAction("setWithdrawCryptoData", cryptoData);
    } else {
      const flatData = pageState["withdrawFlatData"];

      if (value === "") {
        flatData["zeptacoin"] = "";
        flatData["usd"] = "";
      } else if (name === "zeptacoin") {
        flatData[name] = Number.parseFloat(value);
        var zeptacoin = Number.parseFloat(value);
        var usd = Number.parseFloat(zeptaPrice) * zeptacoin; //TODO conversion over here
        flatData["usd"] = usd;
      } else {
        flatData[name] = Number.parseFloat(value);
        var usd = Number.parseFloat(value);
        var zeptacoin = usd / Number.parseFloat(zeptaPrice); //TODO conversion over here
        flatData["zeptacoin"] = zeptacoin;
      }

      dispatchAction("setWithdrawFlatData", flatData);
    }
  };

  const { walletCreated } =
    (userDetails["data"] && userDetails["data"].data) || {};
  const profileImgUri =
    userDetails && userDetails.data && userDetails.data.data["profileImgUri"];
  const gender =
    userDetails && userDetails.data && userDetails.data.data["gender"];
  const avatarImage =
    profileImgUri !== undefined && profileImgUri !== ""
      ? profileImgUri
      : gender && gender.toLowerCase() === "male"
      ? MaleIcon
      : FemaleIcon;

  if (!walletCreated) {
    return (
      <>
        <CreateWalletModal
          show={pageState["createWalletModal"]}
          setShow={() => {
            dispatchState({ type: "showCreateWalletModal" });
          }}
          onReload={() => {
            dispatchState({ type: "showCreateWalletModal" });
            window.location.reload();
          }}
        />

        <div className={"wrapper"}>
          <div className={"container"}>
            <div
              className={"d-flex row flex-justify-center align-content-center"}
              style={{ height: "80vh" }}
            >
              <div className="align-center flex-column">
                <img src={CreateWallet} className="wallet-svg" />
                <button
                  className="music-action-button box-shadow mt-3"
                  size={"button-small-long"}
                  title={"Create your wallet"}
                  onClick={() => {
                    dispatchState({ type: "showCreateWalletModal" });
                  }}
                >
                  {" "}
                  Create your wallet{" "}
                </button>
                {/*
                {userdata && userdata.is2FA_enabled ? (
                  <button
                    className="music-action-button box-shadow mt-3"
                    size={"button-small-long"}
                    title={"Create your wallet"}
                    disabled={userdata && !userdata.is2FA_enabled}
                    onClick={() => {
                      dispatchState({ type: "showCreateWalletModal" });
                    }}
                  >
                    {" "}
                    Create your wallet{" "}
                  </button>
                ) : (
                  <div className="text-center mt-2">
                    <h6>(Please enable 2fa before creating wallet )</h6>
                    <button
                      className="music-action-button box-shadow mt-3 mx-auto
                      "
                      size={"button-small-long"}
                      onClick={() => {
                        history.push("/security");
                      }}
                    >
                      Enable 2fa{" "}
                    </button>
                  </div>
                )} */}
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }

  let privateKeyExported = userDetails?.data?.data?.private_key_exported;

  const WarningText = () => (
    <span style={{ color: "red", fontSize: "12px" }}>
      Service temporary unavailable due to on-going token sale. Visit:
      <a href="https://zeptagram.com/investor-relations" target="_blank">
        https://zeptagram.com/investor-relations
      </a>
    </span>
  );
  return (
    <>
      <Modal
        size="md"
        show={pageState["sendModal"]}
        onHide={() => {
          dispatchState({ type: "showSendModal" });
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title className="text-dark">Send ZeptaCoin</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <label className="input-label">
              Account name*{" "}
              <InfoBox
                className="ml-1"
                msg={"Reciever's Account Name. E.g.- asda112124"}
              />
            </label>

            <input
              className={"input-box-sm"}
              type="text"
              name="account"
              placeholder="Account name"
              onChange={handleSendDataChange}
            />
            <label className="input-label">
              Quantity*{" "}
              <InfoBox className="ml-1" msg={"Quantity(in ZPTC). E.g.- 4"} />
            </label>
            <input
              className={"input-box-sm"}
              type="number"
              placeholder="Quantity"
              min={0}
              onWheel={(e) => e.target.blur()}
              name="quantity"
              onChange={handleSendDataChange}
            />
            <label className="input-label">Memo*</label>
            <input
              className={"input-box-sm"}
              type="text"
              placeholder="Memo"
              name="memo"
              onChange={handleSendDataChange}
            />
            <label className="input-label">
              Pin* <InfoBox className="ml-1" msg={"Enter your 4 digit pin"} />
            </label>

            <PinInput
              className="mx-auto"
              length={4}
              secret
              onChange={(value, index) => {
                let sendData = pageState["sendData"];
                sendData["pin"] = value;
                dispatchState({ type: "setSendData", sendData: sendData });
              }}
              type="numeric"
              inputMode="number"
              style={{ padding: "10px", marginLeft: "auto" }}
              inputStyle={{ borderColor: "#daca9e" }}
              inputFocusStyle={{ borderColor: "blue" }}
              onComplete={(value, index) => {}}
              required
            />
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <SubmitButton
            onClick={sendCoins}
            title={"Send"}
            loading={pageState["sending"]}
          />
        </Modal.Footer>
      </Modal>

      <Modal
        show={pageState["withdrawModal"]}
        onHide={() => {
          dispatchState({ type: "showWithdrawModal" });
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title className="text-dark">Withdraw</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <div className="d-flex align-items-center justify-content-center flex-wrap m-2">
              <div className="text-dark mx-3">Crypto</div>
              <ToggleSwitch
                setValue={() => {
                  dispatchAction("changeWithdrawType");
                }}
              />
              <div className="text-dark mx-3">Fiat($)</div>
            </div>
            <label className="input-label">
              Zeptacoin*{" "}
              <InfoBox
                className="ml-1"
                msg={"Enter amount of Zeptacoin you want to withdraw."}
              />{" "}
            </label>
            <Form.Control
              className={"input-box-sm"}
              type="number"
              onWheel={(e) => e.target.blur()}
              name="zeptacoin"
              placeholder="Zeptacoin"
              value={
                pageState["withdrawType"] === "crypto"
                  ? pageState["withdrawCryptoData"].zeptacoin
                  : pageState["withdrawFlatData"].zeptacoin
              }
              onChange={handleWithdrawDataChange}
            />
            <label
              hidden={pageState["withdrawType"] !== "crypto"}
              className="input-label"
            >
              Telos <InfoBox className="ml-1" msg={"Equivalence in Telos"} />{" "}
            </label>
            <Form.Control
              className={"input-box-sm"}
              hidden={pageState["withdrawType"] !== "crypto"}
              type="number"
              onWheel={(e) => e.target.blur()}
              value={pageState["withdrawCryptoData"].telos}
              placeholder="Telos"
              name="telos"
              onChange={handleWithdrawDataChange}
            />
            <label
              hidden={pageState["withdrawType"] === "crypto"}
              className="input-label"
            >
              USD <InfoBox className="ml-1" msg={"Equivalence in USD"} />{" "}
            </label>
            <Form.Control
              value={pageState["withdrawFlatData"].usd}
              hidden={pageState["withdrawType"] === "crypto"}
              className={"input-box-sm"}
              type="number"
              placeholder="USD"
              onWheel={(e) => e.target.blur()}
              name="usd"
              onChange={handleWithdrawDataChange}
            />
            <label
              hidden={pageState["withdrawType"] !== "crypto"}
              className="input-label"
            >
              Telos(after 2.5% deduction)
            </label>
            <Form.Control
              className={"input-box-sm"}
              value={
                pageState["withdrawCryptoData"]["telos"] !== ""
                  ? pageState["withdrawCryptoData"]["telos"] -
                    (pageState["withdrawCryptoData"]["telos"] * 2.5) / 100
                  : ""
              }
              hidden={pageState["withdrawType"] !== "crypto"}
              type="number"
              onWheel={(e) => e.target.blur()}
              disabled
              placeholder="Telos(after 2.5% deduction)"
              name="telos_after_"
            />
            <label
              hidden={pageState["withdrawType"] === "crypto"}
              className="input-label"
            >
              USD(after 2.5% deduction)
            </label>
            <Form.Control
              value={
                pageState["withdrawFlatData"]["usd"] !== ""
                  ? pageState["withdrawFlatData"]["usd"] -
                    (pageState["withdrawFlatData"]["usd"] * 2.5) / 100
                  : ""
              }
              className={"input-box-sm"}
              type="number"
              onWheel={(e) => e.target.blur()}
              disabled
              hidden={pageState["withdrawType"] === "crypto"}
              placeholder="USD(after 2.5% deduction)"
              name="usd_after_"
            />

            <Form.Label>Enter your Pin</Form.Label>
            <PinInput
              className="mx-auto"
              length={4}
              // initialValue={pin}
              secret
              onChange={(value, index) =>
                handleWithdrawDataChange({
                  target: {
                    name: "pin",
                    value: value,
                  },
                })
              }
              type="numeric"
              inputMode="number"
              style={{ padding: "10px", marginLeft: "auto" }}
              inputStyle={{ borderColor: "#daca9e" }}
              inputFocusStyle={{ borderColor: "blue" }}
              onComplete={(value, index) => {}}
              required
            />
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <SubmitButton
            onClick={() => {
              withdrawCoins();

              // dispatchState({ type: "showWithdrawModal" });
              // setTimeout(() => {
              //     dispatchAction("showPinModal");
              // }, 400);
            }}
            title={"Withdraw"}
            loading={withdrawLoading}
          />
        </Modal.Footer>
      </Modal>

      <Modal
        show={pageState["receiveModal"]}
        onHide={() => {
          dispatchState({ type: "showReceiveModal" });
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title className="text-dark">Receive Zeptacoin</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="text-center">
            <QRCode value={publicKey} bgColor="#9b9b9b" />
            <h5 className="text-dark">
              Address
              <div className="key-text mt-3">{publicKey}</div>
            </h5>
            {/* <div
              style={{
                marginTop: "20px",
                padding: "15px 6px",
                border: "1px solid #727c91",
                borderRadius: "10px",
              }}
            >
              <span style={{ marginLeft: "5px", color: "#9b9b9b" }}>
                Send only TELOS to this deposit address. Sending any other
                currency to this address may result in loss of your deposit.
              </span>
            </div> */}
          </div>
        </Modal.Body>
      </Modal>

      <ConfirmPinModal
        title="Withdraw"
        show={pageState["pinModal"]}
        setShow={() => {
          dispatchState({ type: "showPinModal" });
        }}
        onSubmit={(pin) => {
          dispatchState({ type: "showPinModal" });
          //withdrawCoins(pin);
        }}
      />

      <AddCoinModal
        livePrice={{
          zeptaPrice: zeptaPrice,
          telosInZepta: telosInZepta,
          eosPrice: eosPrice,
          zeptaInTelos: zeptaInTelos,
        }}
        show={pageState["addModal"]}
        setShow={() => {
          dispatchState({ type: "showAddModal" });
        }}
        onAddDollarResponse={(response) => {
          // EcsterPay
          // 		.start({
          //             cartKey: response.data,
          // 			shopTermsUrl: 'https://zepta-user.quillhash.com/privacy-policy',
          // 			showCart: true,
          // 			showPaymentResult: true,
          //             showDelivery: true,
          //             onCheckoutStartInit: (data) => {
          // 				notifySuccess('Initiating payment gateway. Please wait');
          // 			},
          // 			onCheckoutStartSuccess: (data) => {
          //                 notifySuccess('Closer mate! Please enter required details');
          //             },
          // 			onCheckoutStartFailure: (data) => {
          //                // window.location = `/dashboard/wallet?paymentErrorMsg=Unable to reach payment gateway. Please check your internet connection.`;
          // 			},
          // 			onPaymentSuccess: (data) => {
          //                 notifySuccess('Payment successfull. Updating the wallet ...');
          // 				// this.props.userFiatPaymentConfirm({
          //                 //     cartId: this.state.cartKey,
          //                 //     actualAmount: this.state.dollarAmountAddZeptacoins,
          // 				//     status: 'success'
          //                 // })
          // 			},
          // 			onPaymentFailure: (data) => {
          //                 notifyError('Payment failed. Redirecting ...');
          // 				// this.props.userFiatPaymentConfirm({
          //                 // cartId: this.state.cartKey,
          //                 // actualAmount: this.state.dollarAmountAddZeptacoins,
          // 				// 	status: 'failed'
          // 				// })
          // 			},
          // 			onPaymentDenied: (data) => {
          //                 notifyError('Payment denied. Redirecting ...');
          // 				// this.props.userFiatPaymentConfirm({
          //                 // cartId: this.state.cartKey,
          //                 // actualAmount: this.state.dollarAmountAddZeptacoins,
          // 				// 	status: 'denied'
          // 				// })
          // 			},
          // 		})
        }}
      />

      <AdvanceDetailsModal
        advancedInfo={advancedInfo}
        show={pageState["detailsModal"]}
        setShow={() => {
          dispatchState({ type: "showDetailsModal" });
        }}
      />

      <div className={"wrapper  "}>
        <div className={"container h-100"}>
          <div className={"d-flex flex-row align-items-center"}>
            <div className={"avatar_container"}>
              <Image
                //  hidden={pageState["avatarData"].avatarData===undefined && profileImgUri==null}
                rounded={true}
                src={avatarImage}
                width={"160px"}
                height={"160px"}
                id={"avatar-img"}
              />
            </div>
            <WidthBox width={"16px"} />
            <h2 id={"fullname-header"}>
              {userDetails && userDetails.data && userDetails.data.data["name"]}
            </h2>
          </div>

          <div className={"section-divider"} />

          <div className={"row section-2"}>
            <div className={"col-md-4 col-xs-1 account-info-sec"}>
              <h3 className={"section-heading"}>Wallet Info</h3>
              {/*<AccountInfoComponent*/}
              {/*  showCopyIcon={true}*/}
              {/*  title={ownerId}*/}
              {/*  subtitle={"Owner Id"}*/}
              {/*/>*/}
              <AccountInfoComponent
                showCopyIcon={true}
                title={publicKey}
                subtitle={"Public Key"}
              />
              {/* <AccountInfoComponent
                showCopyIcon={true}
                title={accountName}
                subtitle={"Account Name"}
              /> */}
              {/* <AccountInfoComponent
                title={
                  balance
                    ? balance.toFixed(2) +
                      " ZPTC | " +
                      `${(Number.parseFloat(zeptaPrice) * balance).toFixed(
                        2
                      )} USD`
                    : "0.00 ZPTC"
                }
                subtitle={"ZPTC Balance"}
              /> */}
             {/* <AccountInfoComponent
                title={
                  stellarZptc
                    ? stellarZptc +
                      " ZPTC | " +
                      `${(Number.parseFloat(zeptaPrice) * stellarZptc).toFixed(
                        2
                      )} USD`
                    : "0.00 ZPTC"
                }
                subtitle={"ZPTC Balance"}
              /> */}
              <AccountInfoComponent
                title={stellarLTM ? stellarLTM + " XLM" : "0.0000 XLM"}
                subtitle={"XLM Balance"}
              />{" "}
              {/* <AccountInfoComponent
                title={tlosBalance ? tlosBalance : "0.00 TLOS"}
                subtitle={"Telos Balance"}
                info={"Use anchor to withdraw TLOS balance."}
              /> */}
              {/* <AccountInfoComponent
                showCopyIcon={true}
                title={publicKey}
                subtitle={"Public key"}
              /> */}
              {!privateKeyExported && (
                <div
                  className={
                    "d-flex row justify-content-center align-items-center mb-2"
                  }
                >
                  {/* //  // */}
                  <button
                    className="button-medium border-shadow  medium-btn"
                    onClick={() => {
                      setPrivateKeyModal(true);
                    }}
                  >
                    Export Private Key{" "}
                    <InfoBox
                      className="ml-2"
                      msg={
                        <p>
                          To see guide, checkout our video tutorial{" "}
                          {
                            <a
                              href="https://www.youtube.com/watch?v=GXfRAkollBw"
                              target="_blank"
                            >
                              click here
                            </a>
                          }
                        </p>
                      }
                    />
                  </button>
                </div>
              )}
              <div
                className={
                  "d-flex row justify-content-center align-items-center"
                }
              >
                <span
                  className={"advance-detail-bt"}
                  onClick={() => {
                    dispatchAction("showDetailsModal");
                  }}
                >
                  See Advance details
                </span>
              </div>
              <HeightBox height={"40px"} />
            </div>
            <div className={"col-md-8 col-xs-1 setting-sec"}>
              <HeightBox height={"16px"} />

              <TransactionHistory accountName={accountName} />

              <HeightBox height={"16px"} />

              <RoyalitiesHistory accountName={accountName} />

              <div></div>
              {claimmodal && (
                <ClaimTokensale
                  show={claimmodal}
                  setShow={setClaimModal}
                  claimData={claimData}
                />
              )}
              {privatekeyModal && (
                <PrivateKey
                  show={privatekeyModal}
                  setShow={setPrivateKeyModal}
                  setUserRefresh={setUserRefresh}
                  userRefresh={userRefresh}
                />
              )}
              {showTokenSwap && (
                <TokenBridge setShow={setShowTokenSwap} show={showTokenSwap} />
              )}

              {swapModel && (
                <Swap
                  showModal={swapModel}
                  refreshTransaction={refreshTransaction}
                  setRefreshTransaction={setRefreshTransaction}
                  hideModel={setSwapModel}
                  zptcBalance={zptcBalance}
                  setZptcBalance={setZptcBalance}
                  walletConnect={walletConnect}
                  ual=""
                  token={token}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
