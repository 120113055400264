import React, { useState } from "react";
import { Modal, Form } from "react-bootstrap";
import InfoToast from "../../components/ToastMessage/InfoToast";
import SwalSuccess from "../../components/ToastMessage/SwalSuccess";
import SwalError from "../../components/ToastMessage/SwalError";
import { getRequiredProtectedData } from "../../redux/apicalltemplate";
import { userApis } from "../../redux/api";
import BlockchainService from "../../services/BlockchainService";

const blockchainService = new BlockchainService();
const ClaimTokensale = ({ show, setShow, claimData, ual }) => {
  const [poolid, setPoolId] = useState("");
  const handleClose = () => setShow(false);
  const [loading, setLoading] = useState(false);

  //handle form submit------------------------
  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!poolid) {
      InfoToast("Please Enter Pool ID");
      return;
    }
    setLoading(true);
    let data = {};
    if (poolid) {
      data.pool_id = `${poolid}`;
    }

    const auth = blockchainService.getAuthorization(
      ual?.activeUser.accountName,
      "active"
    );
    const actionData = {
      account: ual?.activeUser.accountName,
      pool_id: poolid,
    };
    const actionName = "claim";
    const contractName = process.env.REACT_APP_CLAIM_CONTRACT_NAME;
    const getAction = blockchainService.getAction(
      contractName,
      actionName,
      actionData,
      [auth]
    );
    const optionalData = {
      from: "pools.start",
      memo: "claim",
      quantity: "ZPTC",
      to: ual?.activeUser.accountName,
    };
    // return
    const response = await blockchainService.pushTransaction(
      getAction,
      ual,
      actionName,
      optionalData
    );

    if (response && response.success) {
      SwalSuccess("Token Claimed Success");
      setTimeout(() => {
        setShow(false);
      }, 800);
    }
    if (response && !response.success) {
      SwalError(response.message);
    }
    setLoading(false);
  };

  return (
    <Modal show={show} onHide={handleClose} size="lg">
      <Modal.Header closeButton>
        <Modal.Title className="text-dark">Claim Tokensale Token</Modal.Title>
      </Modal.Header>
      <Form onSubmit={handleSubmit}>
        <Modal.Body className="p-5">
          <div>
            <div className="table-responsive order-table  w-100">
              <table className="table table-bordered table-hover">
                <thead>
                  <tr>
                    <th scope="col" className="text-hover">
                      Allocation
                    </th>
                    <th scope="col" className="text-hover">
                      Bid
                    </th>
                    <th scope="col" className="text-hover">
                      Claimed
                    </th>
                    <th scope="col" className="text-hover">
                      To Be Claimed
                    </th>
                    <th scope="col" className="text-hover">
                      Vesting
                    </th>
                    <th scope="col" className="text-hover">
                      Pool ID
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {claimData &&
                    claimData.map((el) => {
                      return (
                        <tr className="cur-pointer text-dark" key={el.id}>
                          <td>{el.allocation}</td>
                          <td>{el.bid}</td>
                          <td>{el.claimed}</td>
                          <td>{el.to_be_claimed}</td>
                          <td>{el.vesting}</td>
                          <td>{el.pool_id}</td>
                        </tr>
                      );
                    })}
                </tbody>
              </table>
            </div>
          </div>
          <div className="align-center flex-column mt-2">
            <div>
              <label className="text-dark" style={{ marginLeft: "10px" }}>
                Pool Id*
              </label>
              <Form.Control
                className={"input-box-sm"}
                value={poolid}
                onChange={(e) => setPoolId(e.target.value)}
                type="text"
                placeholder="Pool ID"
                required
              />
            </div>
            <div className="text-grey text-end" style={{ fontSize: "10px" }}>
              *Enter Pool Id for which you want to claim
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer className="p-3">
          <button
            className="button-medium border-shadow medium-btn"
            type="submit"
            title={"Submit"}
          >
            {!loading ? (
              "Confirm"
            ) : (
              <>
                <span
                  className="spinner-border spinner-border-smd"
                  role="status"
                  aria-hidden="true"
                ></span>
              </>
            )}
          </button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

export default ClaimTokensale;
