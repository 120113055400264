import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import Zeptagram from "../../images/zeptagram.svg";
import Lady from "../../images/singinglady.svg";
import { Dropdown } from "react-bootstrap";
import DropdownButton from "react-bootstrap/DropdownButton";
import { signupStart } from "../../redux/user/Action";
import { useHistory } from "react-router-dom";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";
import { Gender } from "../../utils/static";
import VisibleEye from "../../components/VisibleEye/VisibleEye";
import Band from "../../images/band.svg";
import Girl from "../../images/lady.svg";
import Neon from "../../images/neonbrand.svg";
import GoogleCaptcha from "../../components/GoogleCaptcha/Captcha";
import InfoToast from "../../components/ToastMessage/InfoToast";

function Signup() {
  const [email, setEmail] = useState("");
  const [user, setUser] = useState("");
  const [password, setPassword] = useState("");
  const [gender, setGender] = useState("");
  const [phone, setPhone] = useState("");
  const [visible, setVisibility] = useState(false);
  const [isdetails, setIsDetails] = useState(true);
  const history = useHistory();
  const dispatch = useDispatch();
  const recaptchaRef = React.useRef();

  const loading = useSelector((state) => state.user.signuploading);
  const handleDetails = () => {
    if (email && user && password) {
      setIsDetails(false);
    }
  };

  async function handleSubmit(event) {
    event.preventDefault();
    const captchatoken = await recaptchaRef.current.executeAsync();
    if (!captchatoken) {
      return;
    }

    let userregex = /^[a-zA-Z ]*$/;
    let emailregex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    if (!userregex.test(user.trim())) {
      InfoToast("Please Enter a Valid User Name");
      return;
    }
    if (email.length > 50) {
      InfoToast("Email allows maximum 50 Characters");
      return;
    }
    if (!emailregex.test(email.trim())) {
      InfoToast("Please Enter a Valid Email");
      return;
    }
    let passwordregex =
      /^(?=.*\d)(?=.*[!@#$%^&?*()])(?=.*[a-z])(?=.*[A-Z]).{8,}$/;
    if (!password.match(passwordregex)) {
      InfoToast(
        "Password should be minimum 8 character long, contain at least one lowercase letter, one uppercase letter, one numeric digit, and one special character. ex: Test@1234"
      );
      return;
    }

    const data = {
      email: email,
      password: password,
      fullName: user,
      // phone: phone,
      gender: "notGiven",
      captcha: captchatoken,
    };

    dispatch(signupStart(data));
  }

  return (
    <div className="row m-auto w-100 h-100">
      <div className="col-md-6 ">
        <div>
          <img
            className="cur-pointer"
            onClick={() => history.push("/")}
            src={Zeptagram}
            alt=""
          />
        </div>
        <div className="align-center h-100">
          <div className="d-flex flex-column justify-content-center">
            {/* <div className="align-center my-5">
            <img src={Zeptagram} alt="" />
          </div> */}
            <form
              className="d-flex flex-column"
              onSubmit={handleSubmit}
              autoComplete="nope"
            >
              <span className="heading-medium mb-2 align-self-baseline">
                User Register
              </span>
              {isdetails ? (
                <>
                  <input
                    type="text"
                    className="input-box my-3"
                    value={user}
                    tabIndex={1}
                    minLength={2}
                    maxLength={50}
                    autoFocus
                    onChange={(e) => setUser(e.target.value)}
                    placeholder="Name"
                    required
                    autoComplete="nope"
                  />
                  <input
                    type="email"
                    className="input-box my-3"
                    value={email}
                    minLength={2}
                    maxLength={50}
                    tabIndex={1}
                    onChange={(e) => setEmail(e.target.value)}
                    placeholder="Email"
                    required
                    autoComplete="nope"
                  />
                  <div className="eye-cont">
                    <input
                      type={`${!visible ? "password" : "text"}`}
                      value={password}
                      tabIndex={1}
                      className="input-box my-3"
                      onChange={(e) => setPassword(e.target.value)}
                      placeholder="Password"
                      required
                    />
                    <VisibleEye
                      visible={visible}
                      setVisibility={setVisibility}
                    />
                  </div>
                  {/* <input type='password' className='input-box my-3' value={password} onChange={(e)=>setPassword(e.target.value)} placeholder='Password' required/> */}
                </>
              ) : (
                <>
                  <DropdownButton
                    title={
                      gender ? (
                        gender
                      ) : (
                        <p className="text-grey">{"Select a Gender"}</p>
                      )
                    }
                    id="dropdown-basic-button"
                    className="custom_dropdown input-box w-100 align-middle mb-3 pb-2"
                    variant=""
                  >
                    {Gender.map((el, i) => (
                      <Dropdown.Item
                        key={i}
                        onClick={() => setGender(el.value)}
                      >
                        <span>{el.name}</span>
                      </Dropdown.Item>
                    ))}
                  </DropdownButton>

                  {/* <input type='text' className='input-box my-3' value={gender} onChange={(e)=>setGender(e.target.value)}  placeholder='Gender' required/> */}
                  <PhoneInput
                    placeholder="Enter phone number"
                    defaultCountry="SE"
                    className="input-box"
                    international
                    value={phone}
                    required
                    onChange={setPhone}
                  />
                </>
              )}

              {/* <div>
                <input
                  name="rem-password"
                  type="checkbox"
                  className="align-middle"
                />
                <label
                  htmlFor="rem-password"
                  className="mx-2 text-grey align-middle"
                >
                  Remember password
                </label>
              </div> */}

              <div className="align-center">
                {/* <div className=" d-flex flex-column align-items-end"> */}
                {/* <button
                  className="button-medium border-shadow m-2 "
                  type="button"
                  onClick={() => history.push("/login")}
                >
                  Sign In
                </button> */}

                {
                  // isdetails ? (
                  //   <>
                  //     <button
                  //       className="button-medium button-reverse border-shadow m-2"
                  //       type="button"
                  //       onClick={handleDetails}
                  //     >
                  //       Continue
                  //     </button>
                  //   </>
                  // ) : (
                  <button
                    className="button-medium button-reverse border-shadow m-2 my-4"
                    type="submit"
                  >
                    {!loading ? (
                      "Sign Up"
                    ) : (
                      <>
                        <span
                          className="spinner-border spinner-border-smd"
                          role="status"
                          aria-hidden="true"
                        ></span>
                      </>
                    )}
                  </button>
                }
              </div>

              <div className="d-flex align-items-center ">
                <div className="text-dark f-14">Already have an account?</div>
                <button
                  className="text-hover mx-2 font-weight-bold btn-def"
                  onClick={() => history.push("/login")}
                >
                  Sign In
                </button>
              </div>
            </form>
          </div>
        </div>
        <GoogleCaptcha recaptchaRef={recaptchaRef} />
      </div>

      <div className="col-md-6 image-container">
        <span className="verify-image verify-image-1">
          <img src={Neon} alt="" />
        </span>
        <span className="verify-image verify-image-2">
          <img src={Band} alt="" />
        </span>
        <div className="verify-image verify-image-3">
          <img height={380} className="girl-img" src={Girl} alt="" />
        </div>
      </div>
      {/* <div className='col-md-6 main-image'> */}
      {/* <img src={Lady} className='img-cover' alt='zeptagram'/> */}
      {/* </div> */}
    </div>
  );
}

export default Signup;
