
import React, { useState, useEffect } from "react";
import "bootstrap-icons/font/bootstrap-icons.css";
import "./GenerateWallet.css";
import { stellarApis } from "../../redux/api";
import { swapTransaction } from "../../redux/apicalltemplate";
import { toast } from "react-toastify";
import { Form, Modal } from "react-bootstrap";
import {
  SuccessToast,
  ErrorToast,
} from "../../components/ToastMessage/SuccessToast";
import { successMessage, stringConstants } from "../../constants";




const GenerateWallet = ({ForPublicKey},showModal) => {

  

  const [copySuccess, setCopySuccess] = useState([]);
  const [visible, setVisible] = useState(false);

  useEffect(async () => {
    const responseData = await swapTransaction(
      stringConstants.METHOD.POST,
      `${stellarApis.GENERATE_KEYPAIR}`,
      {}
    );
    console.log("responseData", responseData);
    if (responseData && responseData.data) {
      setCopySuccess(Object.values(responseData.data));
    }
  }, []);

  const onCopy = () => {
    toast.success(<SuccessToast message={successMessage.COPY_SUCCESS} />, {
      toastId: 1,
      updateId: 1,
    });
  };
  const onSuccess = () => {
    toast.success(<SuccessToast message={successMessage.ACCOUNT_CREATED_SUCCESS} />, {
      toastId: 1,
      updateId: 1,
    });
  };


  const copyToClipboard = (e) => {
    navigator.clipboard.writeText(copySuccess[0]);
    onCopy();
  };


  const copyToClipboardPrivatekey = (e) => {
    navigator.clipboard.writeText(copySuccess[1]);
    onCopy();
  };


  const encryptPrivateKey = () => {
    const obj = { publicKey: copySuccess[0], privateKey: copySuccess[1] };
    const blob = new Blob([JSON.stringify(obj, null, 2)], {
      type: "application/json",
    });
    const a = document.createElement("a");
    a.download = "my-file.txt";
    a.href = URL.createObjectURL(blob);
    a.addEventListener("click", (e) => {
      setTimeout(() => URL.revokeObjectURL(a.href), 30 * 1000);
    });
    a.click();
    var PublicKey = localStorage.setItem(stringConstants.PUBLIC_KEY_LOCAL_STORAGE, copySuccess[0]);
    onSuccess();
    setVisible(false);
    ForPublicKey();
  };

  return (
    <div>
      <div className="popup-link" onClick={()=> setVisible(true)}>
        <div className="genetatewallet">Generate Wallet</div>
      </div>

      {visible && (
        <Modal
          size="lg"
          show={showModal}
          onHide={() => {
            setVisible(false);
          }}
        >
          <Modal.Header closeButton>
            <Modal.Title className="text-dark">Stellar Wallet Key Pair</Modal.Title>
          </Modal.Header>
         <Modal.Body>
          <Form>
         <div>
              <div className="private-key-copy">
                <div>PublicKey : {copySuccess[0]}</div>
                <button
                  type="button"
                  className="bi bi-clipboard copy-icon"
                  onClick={copyToClipboard}
                ></button>
              </div>
              <div className="private-key-copy">
                <div>privateKey : {copySuccess[1]}</div>{" "}
                <button
                  type="button"
                  className="bi bi-clipboard copy-icon"
                  onClick={copyToClipboardPrivatekey}
                ></button>
              </div>
            </div>
            </Form>
         </Modal.Body>
          <Modal.Footer>
          <button className="save-private-key" onClick={encryptPrivateKey}>
                Save Private Key
              </button>
          </Modal.Footer>
        </Modal>
      )}
    </div>
  );
};
export default GenerateWallet;