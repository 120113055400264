import React, { useState } from "react";
import Zeptagram from "../../images/zeptagram.svg";
import Band from "../../images/band.svg";
import Girl from "../../images/lady.svg";
import Neon from "../../images/neonbrand.svg";
import { useHistory } from "react-router";
import { reset2faStart, verifyReset2faStart } from "../../redux/user/Action";
import { useDispatch, useSelector } from "react-redux";
import GoogleCaptcha from "../../components/GoogleCaptcha/Captcha";
import { notifyError } from "../../utils/utils";

const VerifyReset2fa = () => {
  const [email, setEmail] = useState("");
  const [otp, setOtp] = useState("");
  const history = useHistory();
  const dispatch = useDispatch();
  const recaptchaRef = React.useRef();

  const verifyreset2faloading = useSelector(
    (state) => state.user.verifyreset2faloading
  );
  const handleSubmit = async (e) => {
    e.preventDefault();
    const captchatoken = await recaptchaRef.current.executeAsync();
    if (!captchatoken) {
      return;
    }

    if (!email) {
      notifyError("Please Enter Valid Email");
      return;
    }
    if (otp.length !== 6) {
      notifyError("Please Enter Valid OTP");
      return;
    }

    const data = {
      email: email,
      otp: otp,
      captcha: captchatoken,
    };
    dispatch(verifyReset2faStart(data));
  };
  React.useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const email = params.get("email");
    setEmail(email);
  }, []);

  const handleOtp = async () => {
    const captchatoken = await recaptchaRef.current.executeAsync();
    if (!captchatoken) {
      return;
    }
    const mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    if (!email || !mailformat.test(email)) {
      notifyError("Please Enter a Valid Email");
      return;
    }
    const payload = {
      email: email,
      // password: password,
      captcha: captchatoken,
    };
    dispatch(reset2faStart(payload));
  };
  return (
    <div className="row m-auto h-100  w-100  ">
      <div className="col-md-6 ">
        <div onClick={() => history.push("/login")}>
          <img src={Zeptagram} alt="" />
        </div>
        <div className="align-center h-84">
          <div className="d-flex flex-column justify-content-center">
            <h4 className="text-dark">Verify Reset 2FA Authentication</h4>
            <div className="text-grey w-500">
              We sent you a authentication email for OTP. Once you verify your
              authentication code you will be able to reset 2FA for your
              account.
            </div>
            <form className="d-flex flex-column" onSubmit={handleSubmit}>
              <div className="label-txt"> Email</div>
              <input
                type="email"
                className="input-box my-1 align-self-start"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder="Email"
                required
              />
              <div className="label-txt"> OTP</div>
              <input
                type="number"
                minLength="6"
                maxLength="6"
                className="input-box my-1 align-self-start"
                onChange={(e) => setOtp(e.target.value)}
                placeholder="OTP"
                required
                value={otp}
              />
              <div className="d-flex align-items-center">
                <button
                  className="button-medium border-shadow m-2 medium-btn"
                  type="submit"
                >
                  {!verifyreset2faloading ? (
                    "Verify"
                  ) : (
                    <>
                      <span
                        className="spinner-border spinner-border-smd"
                        role="status"
                        aria-hidden="true"
                      ></span>
                    </>
                  )}
                </button>
                <button
                  className="button-medium btn-grey border-shadow m-2"
                  type="button"
                  onClick={() => history.push("/login")}
                >
                  Cancel
                </button>
              </div>
              <div className="text-grey mt-3 w-500">
                Did not get an email?{" "}
                <span
                  className="text-dark cur-pointer"
                  onClick={() => handleOtp()}
                >
                  Click here
                </span>{" "}
                and we will resend it to you!
              </div>
              <GoogleCaptcha recaptchaRef={recaptchaRef} />
            </form>

            {/* <div className='text-grey mt-3'>Did not get an email? <span className='text-hover' onClick={()=>history.push('/resendotp')}>Click here</span> and we will resend it to you!</div> */}
          </div>
        </div>
      </div>
      <div className="col-md-6 image-container">
        <span className="verify-image verify-image-1">
          <img src={Neon} alt="" />
        </span>
        <span className="verify-image verify-image-2">
          <img src={Band} alt="" />
        </span>
        <div className="verify-image verify-image-3">
          <img height={380} className="girl-img" src={Girl} alt="" />
        </div>
      </div>
    </div>
  );
};

export default VerifyReset2fa;
