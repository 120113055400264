import WalletConnectClient, {
  SIGN_CLIENT_EVENTS,
} from "@walletconnect/sign-client";
// eslint-disable-next-line import/no-extraneous-dependencies
import { getInternalError, getSdkError } from "@walletconnect/utils";
import { toast } from "react-toastify";
import * as StellarSdk from "stellar-sdk";
import { ErrorToast } from "../../components/ToastMessage/SuccessToast";

import SwalError from "../../components/ToastMessage/SwalError";
import SwalSuccess from "../../components/ToastMessage/SwalSuccess";
import { userApis, stellarApis } from "../../redux/api";

import { getRequiredProtectedData, swapTransaction } from "../../redux/apicalltemplate";
import { lobstrLoginStart } from "../../redux/user/Action";

var server = new StellarSdk.Server("https://horizon.stellar.org");
const TRANSACTION_TIMEOUT = 60 * 60 * 24 * 30;

const PROJECT_ID = "0f5350f91a3f2235d75e1b525204bc39";

const METADATA = {
  name: "Zeptagram",
  description: "Zeptagram is an advanced music platform.",
  url: "https://zeptagram.com",
  icons: [
    "https://uploads-ssl.webflow.com/61b1e452fb1693b05e459202/61b1f2be6e3f0d8a4097a3cb_logo.png",
  ],
};

const PUBNET = "stellar:pubnet";

const STELLAR_METHODS = {
  // SIGN_AND_SUBMIT: 'stellar_signAndSubmitXDR',
  // SIGN: 'stellar_signXDR',
  SIGN: "stellar_signAndSubmitXDR",
};

const REQUIRED_NAMESPACES = {
  stellar: {
    chains: [PUBNET],
    methods: Object.values(STELLAR_METHODS),
    events: [],
  },
};

export default class WalletConnectService
{
  constructor()
  {
    this.appMeta = null;
    this.client = null;
    this.session = null;
    this.account = null;
  }

  async initWalletConnect()
  {
    try
    {
      if (this.client)
      {
        return null;
      }

      this.client = await WalletConnectClient.init({
        projectId: PROJECT_ID,
        metadata: METADATA,
      });

      this.listenWalletConnectEvents();

      if (!this.client.session.length)
      {
        console.log("session is null! ");
        return null;
      }

      this.session = await this.client.session.getAll()[0];

      console.log("THIS SESSION LINE 76", this.session);

      // eslint-disable-next-line no-unused-vars
      const [chain, reference, publicKey] =
        this.session.namespaces.stellar.accounts[0].split(":");

      this.appMeta = this.session.peer.metadata;
      const keypair = StellarSdk.Keypair.fromPublicKey(publicKey);
      this.account = await server.loadAccount(publicKey);

      return "logged";
    } catch (e)
    {
      console.log("err", e);
    }
  }

  clearClient()
  {
    this.appMeta = null;
    this.client = null;
    this.session = null;
    this.account = null;
  }

  async restoreConnectionIfNeeded()
  {
    if (this.session)
    {
      this.client = await WalletConnectClient.init({
        // logger: 'debug',
        projectId: PROJECT_ID,
        metadata: METADATA,
      });
    }
  }

  listenWalletConnectEvents()
  {
    // this.client.on(SIGN_CLIENT_EVENTS.session_delete, ({ topic }) => this.onSessionDeleted(topic));
  }

  onSessionDeleted(topic)
  {
    if (this.session && this.session.topic === topic)
    {
      this.session = null;
      this.appMeta = null;
      this.driver.session.handlers.handleLogout();
    }
  }

  login = async (onShowModel, onCloseModel, showModal) =>
  {
    this.onShowModel = onShowModel;
    this.onCloseModel = onCloseModel;
    this.showModal = showModal;
    try
    {
      const result = await this.initWalletConnect();

      // if (result === 'logged') {
      //     return;
      // }

      await this.connect();
    } catch (err)
    {
      console.log("errr", err);
      const errorMessage =
        "Connection Failed.Either account is not valid or not activated";

      SwalError(errorMessage);
    }
  };

  async deletePairing(topic)
  {
    await this.client.pairing.delete(topic, getInternalError("UNKNOWN_TYPE"));
  }

  async connect(pairing)
  {
    try
    {
      const { uri, approval } = await this.client.connect({
        pairingTopic: pairing ? pairing.topic : undefined,
        requiredNamespaces: REQUIRED_NAMESPACES,
      });

      if (!pairing)
      {
        console.log(uri, "----NOT PAIRING URI----");
        this.onShowModel(uri);
      }

      this.session = await approval();

      console.log("THIS SESSION", this.session);
      // eslint-disable-next-line no-unused-vars
      const [chain, reference, publicKey] =
        this.session.namespaces.stellar.accounts[0].split(":");

      const asset = new StellarSdk.Asset(
        "ZPTC",
        "GDRV4ZV7I7BYYN25FJTEWHZJLTTX6FGWRQ6J5ZYWOLTRVRS2PH3UHFSV"
      );
      var assetCode = "ZPTC";
      var zeroBalance = "0.0000";

      //
      const keypair = StellarSdk.Keypair.fromPublicKey(publicKey);
      this.account = await server.loadAccount(publicKey);

      var Balance = this.account.balances.find((balance) =>
      {
        if (balance.asset_code === assetCode)
        {
          var decimalBanance = (
            Math.floor(balance.balance * 100) / 100
          ).toFixed(4);
          console.log("decimalBanance", decimalBanance);
          localStorage.setItem("zptcBalance", decimalBanance);
          localStorage.setItem("stellerPublicKey", publicKey);
          return balance.balance;
        } else
        {
          localStorage.setItem("stellerPublicKey", publicKey);
          // localStorage.setItem("zptcBalance", zeroBalance)
          // console.log(localStorage.getItem("zptcBalance"));
        }
      });

      // console.log(Balance.asset_code)
      // if(Balance.asset_code == assetCode){
      // var decimalBanance = (Math.floor(Balance.balance * 100) / 100).toFixed(4);
      // localStorage.setItem("stellerPublicKey", publicKey);
      // localStorage.setItem("zptcBalance", decimalBanance);
      // console.log("account", this.account);
      // }
      // else {
      //   var zeroBalance ='0.0000';
      //   localStorage.setItem("zptcBalance", zeroBalance);
      //   localStorage.setItem("stellerPublicKey", publicKey);
      //   console.log(zeroBalance)
      // }
      //

      this.onCloseModel();
    } catch (e)
    {
      console.log("err", e);

      this.appMeta = null;
      if (e?.message === "cancelled")
      {
        return Promise.resolve({ status: "cancel" });
      }

      const errorMessage =
        e?.message === "rejected"
          ? "Connection canceled by the user"
          : "Connection Failed.require a valid and active account.";
      toast.error(<ErrorToast message={errorMessage} />, {
        toastId: 1,
        updateId: 1,
      });

      this.showModal();
      if (this.session)
      {
        return Promise.resolve({ status: "cancel" });
      }
    }
  }

  async signAndSubmit(
    setLoading,
    amount,
    hideModel,
    telosTransaction,
    randomNumber,
    setOverlayLoader,
    token
  )
  {
    await this.initWalletConnect();
    console.log("signAndSumbit...");
    const opts = {
      destination: "GDD6UHM422V2BVHA2GBLLY72JNIYQ5CBCW3EXAFXYYX5VFKGCKHQTJ5U",
      asset: new StellarSdk.Asset(
        "ZPTC",
        "GDRV4ZV7I7BYYN25FJTEWHZJLTTX6FGWRQ6J5ZYWOLTRVRS2PH3UHFSV"
      ),
      amount: `${amount}`,
      withMuxing: false, //todo check this
    };

    let op;
    const baseAccount = opts.withMuxing
      ? StellarSdk.MuxedAccount.fromAddress(opts.destination, "0")
        .baseAccount()
        .accountId()
      : null;
    try
    {
      // We need to check the activation of the destination,
      // if the account is not activated, it will be created in catch with createAccount
      await server.loadAccount(
        opts.withMuxing ? baseAccount : opts.destination
      );
      op = this.buildOpSendPayment(opts);
    } catch (e)
    {
      console.log(e);
      setLoading(false);
      setOverlayLoader(false);
      if (!opts.asset.isNative())
      {
        throw new Error(
          "Destination account does not exist. To create it, you must send at least 1 XLM."
        );
      }
      if (opts.withMuxing)
      {
        // eslint-disable-next-line no-param-reassign
        opts.destination = baseAccount;
      }
      op = this.buildOpCreateAccount(opts);
      console.log("op", op);
    }

    // return this.handlers.buildSignSubmit(op, memo, opts.withMuxing);

    //  await this.account.incrementSequenceNumber();

    console.log("acccount -> ", this.account);

    console.log("opts", opts);

    const tx = new StellarSdk.TransactionBuilder(this.account, {
      fee: 100000,
      networkPassphrase: StellarSdk.Networks.PUBLIC,
      withMuxing: Boolean(opts.withMuxing),
    })
      .addMemo(StellarSdk.Memo.text(randomNumber.toString()))
      .setTimeout(TRANSACTION_TIMEOUT);

    console.log("tx", tx);

    console.log("randomNumber", randomNumber);

    tx.addOperation(op);

    const xdr = tx.build().toEnvelope().toXDR("base64");

    console.log("xdr", xdr);
    console.log("making request");

    // const server = new StellarSdk.Server('https://horizon-testnet.stellar.org');

    // const transactionResult = await server.submitTransaction(xdr);

    const TransactionResult = this.client
      .request({
        topic: this.session.topic,
        chainId: PUBNET,
        request: {
          method: STELLAR_METHODS.SIGN,
          params: {
            xdr,
          },
        },
      })
      .then(async (result) =>
      {
        setLoading(false);
        setOverlayLoader(false);
        console.log("payment result  ...", result);
        console.log("status: " + result.status);

        SwalSuccess("Transaction Confirmed Successfully");
        // eslint-disable-next-line no-unused-vars
        const [chain, reference, publicKey] =
          this.session.namespaces.stellar.accounts[0].split(":");

        var assetCode = "ZPTC";

        //
        const keypair = StellarSdk.Keypair.fromPublicKey(publicKey);
        this.account = await server.loadAccount(publicKey);

        var Balance = this.account.balances.find((balance) =>
        {
          if (balance.asset_code == assetCode)
          {
            var decimalBanance = (
              Math.floor(balance.balance * 100) / 100
            ).toFixed(4);
            localStorage.setItem("zptcBalance", decimalBanance);
          }
        });

        //

        //
        // const response = await getRequiredProtectedData(
        //     "PUT",
        //     `${userApis.USER_TXN}`,
        //     {signedXDR:result.signedXDR,publicKey},

        //   );

        //   if (response && response.data && response.data.success) {
        //     SwalSuccess('Transaction Confirmed Successfully');
        //   }

        //   if (response && response.data && !response.data.success) {
        //     SwalError(response.data.message);

        //   }
        //   if (response && response.response && response.response.data) {
        //     SwalError(response.response.data.message);

        //   }

        hideModel(false);
        // return result;
        if (result.status == "success")
        {
          const params = {
            from: this.account.id,
            to: opts.destination,
            amount: amount,
            addMemo: randomNumber,
          };
          console.log("params", params);
          const responseData = await swapTransaction(
            "POST",
            `${stellarApis.STELLAR_TRANSACTION_DETAILS}`,
            {},
            params,
            token
          ).then((response) =>
          {
            console.log("Data Saved Successfully");
          });
          telosTransaction();
        }
      })
      .catch((e) =>
      {
        setLoading(false);
        setOverlayLoader(false);
        const message =
          e?.message == "cancelled_by_user"
            ? "Transaction cancelled by user"
            : e?.message || "Something went wrong";
        SwalError(message);
      });
    // console.log("TransactionResult", TransactionResult);
  }

  getStellarZPTCBalance = async (publicKey) =>
  {
    return new Promise(async (resolve, reject) =>
    {
      const account = await server.loadAccount(publicKey);
      account.balances.forEach((balance) =>
      {
        if (balance.asset_code === "ZPTC")
        {
          var decimalBanance = (
            Math.floor(balance.balance * 100) / 100
          ).toFixed(4);
          resolve(decimalBanance);
        }
      });
    });
  };

  getStellarLTMBalance = async (publicKey) =>
  {
    return new Promise(async (resolve, reject) =>
    {
      const account = await server.loadAccount(publicKey);
      // console.log('account',account)
      account.balances.forEach((balance) =>
      {
        if (balance.asset_type === "native")
        {
          let bal = balance.balance;
          resolve(bal);
        }
      });
    });
  };

  buildOpSendPayment(opts)
  {
    return StellarSdk.Operation.payment({
      destination: opts.destination,
      asset: opts.asset,
      amount: opts.amount,
      withMuxing: Boolean(opts.withMuxing),
    });
  }
}
