import { Image } from "react-bootstrap";
import { notifyError, notifySuccess } from "../utils/utils";

import copyIcon from "../icons/copy.png";
import { CopyToClipboard } from "react-copy-to-clipboard/lib/Component";
import {toast} from "react-toastify";
import {SuccessToast} from "./ToastMessage/SuccessToast";
import InfoBox from "./InfoMessage/InfoHover";
import RefreshIcon from "../icons/refresh.svg";

export default function AccountInfoComponent(props) {
  return (
    <div onClick={props.onClick}>
      <div className={"account-info-container"}>
        <div className={"account-info-sub-container"}>
          <span className={"account-info-heading"}>
            {props.title}

            <span
              hidden={
                props.showCopyIcon == undefined || props.showCopyIcon == false
              }
            >
              <CopyToClipboard
                text={props.title}
                onCopy={() => {
                    toast.success(<SuccessToast message={'Copied!'} />,{
                        toastId:1,
                        updateId:1
                    });

                }}
              >
                <img
                  src={copyIcon}
                  width={"17px"}
                  className={"mx-2 cur-pointer"}
                />
              </CopyToClipboard>

            </span>
            {props.info ? <InfoBox className="ml-2" msg={props.info} /> : null}


          </span>
          <div>
          <span className={"account-info-subheading"}>{props.subtitle}
          
          {
          props.isRefresh ?   <Image
          src={RefreshIcon}
           
          className={"icon-setting mx-2 cur-pointer"}
          onClick={props.handleRefresh}
        />  :null
         }
          
           </span>
         
         
          </div>
        
       
        </div>
        {/* IoCopyOutline */}
      </div>

      <div className={"setting-divider"} />
    </div>
  );
}
