import React from "react";
import {
  ComposedChart,
  Line,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  Legend,
  Scatter,
} from "recharts";
import moment from "moment";

const Graph = ({
  data,
  xdatakey = "date",
  ydatakey = "latestPrice",
  yName = "Latest Price",
}) => {
  const tickForm = (timeStr) => moment(timeStr).format("YYYY-MM-DD HH:mm");
  return (
    <ResponsiveContainer width="100%" height="100%">
      <ComposedChart
        width={800}
        height={400}
        data={data}
        margin={{
          top: 20,
          right: 20,
          bottom: 20,
          left: 20,
        }}
      >
        <CartesianGrid stroke="lightgrey" strokeDasharray="5 5" />
        <XAxis
          dataKey={xdatakey}
          tickFormatter={tickForm}
          domain={["auto", "auto"]}
        />

        <YAxis />
        <Tooltip
          labelFormatter={(value) => moment(value).format("YYYY-MM-DD HH:mm")}
        />
        <Legend />
        <Bar
          dataKey={ydatakey}
          name={yName}
          barSize={20}
          fill="#4BB543"
          radius={[5, 5, 0, 0]}
        />
        {/* <Line type="monotone" dataKey="cnt" stroke="#FDC132" strokeDasharray="5 5"/> */}
      </ComposedChart>
    </ResponsiveContainer>
  );
};
export default Graph;
