import React, { useState } from "react";
import { Modal, Form } from "react-bootstrap";
import { ToggleSwitch } from "../Buttons/ToggleSwitch/ToggleSwitch";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import PinInput from "react-pin-input";

import InfoToast from "../ToastMessage/InfoToast";
import { getRequiredProtectedData } from "../../redux/apicalltemplate";
import { tradeApis } from "../../redux/api";
import { useEffect } from "react";
import SwalError from "../ToastMessage/SwalError";
import BlockchainService from "../../services/BlockchainService";
import SwalSuccess from "../ToastMessage/SwalSuccess";
import InfoBox from "../InfoMessage/InfoHover";
const Order = ({
  show,
  setShow,
  text = "Buy",
  bookdetails,
  ual,
  tradelist,
  setBookDetails,
}) => {
  const {
    data: { zeptaPrice },
  } = useSelector((state) => state.user, shallowEqual);
  const [pin, setPin] = useState("");
  const [price, setPrice] = useState(0);
  const [loader, setLoader] = useState(false);
  const [quantity, setQuantity] = useState("");
  const [total, setTotal] = useState("");
  const dispatch = useDispatch();
  const handleClose = () => setShow(false);
  const [list, setList] = useState([]);

  //tradelist
  useEffect(() => {
    if (tradelist?.data?.length) setList(tradelist?.data);
  }, [tradelist]);

  //handle price
  useEffect(() => {
    setPrice(bookdetails.latestPrice / 10000);
  }, [bookdetails]);

  //handle invest----------------------

  const handleOrder = async (event) => {
    try {
      event.preventDefault();
      if (!ual.activeUser) {
        InfoToast("Trading is allowed only for Anchor users");
        return;
      }

      let orderDetails = {};
      if (!bookdetails) {
        InfoToast("Select a MTO from Trading List");
        return;
      }
      orderDetails.tokenName = bookdetails.bookName;
      orderDetails.price = Number(price);
      orderDetails.quantity = Number(quantity / 10000);
      orderDetails.side = text === "Buy" ? "buy" : "sell";
      if (price < 0) {
        InfoToast("Price must be greater than 0");
      } else if (quantity <= 0) {
        InfoToast("Quantity must be greater than 0");
      } else if (!pin && !ual.activeUser) {
        InfoToast("Please enter a valid wallet pin");
      } else if (pin.length != 4 && !ual.activeUser) {
        InfoToast("Pin must be 4 characters long");
      } else {
        if (ual.activeUser) {
          orderDetails.walletName = ual.activeUser?.accountName;
        } else {
          orderDetails.pin = pin;
        }
        setLoader(true);

        const result = await new BlockchainService().getTokenBalance(
          ual.activeUser?.accountName
        );
        if (zeptaPrice * Number(result.split(" ")[0]) < price * quantity) {
          setLoader(false);
          return InfoToast("You don't have enough ZPTC to do the transaction");
        }
        const response = await getRequiredProtectedData(
          "POST",
          ual.activeUser
            ? tradeApis.ADD_ORDER_WITH_ANCHOR
            : tradeApis.ADD_ORDER,
          orderDetails
        );
        if (
          text === "Buy" &&
          ual?.activeUser &&
          response?.data?.success &&
          response?.data?.tnx
        ) {
          if (response && response.data && response.data.success) {
            const resullt = await new BlockchainService().pushTransaction(
              response.data.tnx,
              ual,
              "trade_zptc_transfer",
              response.data.optionalData
            );
            if (resullt.success) {
              setLoader(false);
              handleClose();
              SwalSuccess("Order placed success");
            } else {
              setLoader(false);
              SwalSuccess(resullt.message);
            }
          }
          return;
        }

        if (response && response.data && response.data.success) {
          setLoader(false);
          InfoToast(response.data.message);
          handleClose();
        }
        if (response && response.data && !response.data.success) {
          setLoader(false);
          InfoToast(response.data.message);
        }
        if (response && response.response && response.response.data) {
          setLoader(false);
          SwalError(response.response.data.message);
        }
        setLoader(false);
      }
    } catch (e) {
      console.log(e);
    }
  };
  // const handleAnchorOrder=(event)=>{
  //   event.preventDefault();
  //   console.log('wehjdwdfbvchjx')
  // }
  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title className="text-center"> {text} Order <InfoBox
              className="ml-2"
              msg={
                <p>To see guide, checkout our video tutorial {
                   
                  <a href="https://www.youtube.com/watch?v=vOK3_WjGrvE" target="_blank">click here</a>  
                   
                  }
                  
                  </p>
                
              }
            />  </Modal.Title>
      </Modal.Header>
      <Form onSubmit={handleOrder}>
        <Modal.Body className="p-5">
          <label className="text-dark" style={{ marginLeft: "10px" }}>
            MTO Name
          </label>
          {/* <Form.Control
            className={"input-box-sm"}
            type="text"
            name="mtoname"
            value={bookdetails && bookdetails.bookName}
            readOnly={true}
            disabled
          /> */}
          <Form.Control
            onChange={(e) => {
              setBookDetails(JSON.parse(e.target.value));
            }}
            defaultValue={JSON.stringify(bookdetails)}
            as="select"
            className={"input-box-sm"}
          >
            {tradelist?.data.map((el) => (
              <option key={el.bookName} value={JSON.stringify(el)}>
                {el.bookName}
              </option>
            ))}
          </Form.Control>

          <label className="text-dark" style={{ marginLeft: "10px" }}>
            Price(Per MTO)*
          </label>
          <Form.Control
            className={"input-box-sm"}
            type="number"
            value={price}
            onChange={(e) => setPrice(e.target.value)}
            placeholder="Price Per MTO"
          />
          <label className="text-dark" style={{ marginLeft: "10px" }}>
            Quantity(MTO)*
          </label>
          <Form.Control
            className={"input-box-sm"}
            value={quantity}
            onChange={(e) => setQuantity(e.target.value)}
            type="number"
            placeholder="Quantity"
            max={1000000000}
          />
          <label className="text-dark" style={{ marginLeft: "10px" }}>
            Total($)*
          </label>
          <Form.Control
            className={"input-box-sm"}
            value={
              quantity
                ? price * quantity
                  ? (price * quantity).toFixed(4)
                  : 0
                : 0
            }
            type="number"
            placeholder="Total"
          />
          {!ual.activeUser && (
            <div>
              <label className="text-dark" style={{ marginLeft: "10px" }}>
                Enter your Wallet Pin*
              </label>
              <PinInput
                className="mx-auto"
                length={4}
                initialValue={pin}
                secret
                onChange={(value, index) => setPin(value)}
                type="numeric"
                inputMode="number"
                style={{ padding: "10px", marginLeft: "auto" }}
                inputStyle={{ borderColor: "#daca9e" }}
                inputFocusStyle={{ borderColor: "blue" }}
                onComplete={(value, index) => {}}
                required
              />
            </div>
          )}

          <div className="text-dark f-14 px-1">
            Note: 0.75% of order amount will be deducted as admin fee
          </div>
        </Modal.Body>
        <Modal.Footer className="p-3">
          <button
            className="button-medium border-shadow   medium-btn"
            type="submit"
            title={"Submit Order"}
            disabled={loader}
          >
            {loader ? (
              <span
                className="spinner-border spinner-border-smd"
                role="status"
                aria-hidden="true"
              ></span>
            ) : (
              "Submit"
            )}
          </button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

export default Order;
