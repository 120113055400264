import React, { useState, useEffect, useContext } from "react";
import "bootstrap-icons/font/bootstrap-icons.css";
import "./Swap.css";
import { getRequiredProtectedData, swapTransaction } from "../../redux/apicalltemplate";
import { stellarApis } from "../../redux/api";
import CryptoJS from "crypto-js";
import { toast } from "react-toastify";
import PinInput from "react-pin-input";

import StellarSdk from "stellar-sdk";
import StellarBase from "stellar-base";

import { Form, Modal } from "react-bootstrap";
import {
  SuccessToast,
  ErrorToast,
} from "../../components/ToastMessage/SuccessToast";
import { errorMessage, stringConstants, successMessage } from "../../constants";
import BlockchainService from "../../services/BlockchainService";
import { useDispatch } from "react-redux";
import { setOverlayLoader } from "../../context";
import InfoBox from "../../components/InfoMessage/InfoHover";

import axios from "axios";
import SwalError from "../../components/ToastMessage/SwalError";
import SwalSuccess from "../../components/ToastMessage/SwalSuccess";
import { useHistory } from "react-router-dom";

const blockchainService = new BlockchainService();
var server = new StellarSdk.Server("https://horizon-testnet.stellar.org");
let accountForTransaction;

const SwapTelos = ({
  ual,
  showModal,
  hideModel,
  setRefreshTransaction,
  refreshTransaction,
  setZptcBalance,
  zptcBalance,
  walletConnect,
  token,
}) => {
  const [accountName, setAccountName] = useState("");
  const [quantity, setQuantity] = useState(null);
  const [walletAddress, setWalletAddress] = useState('');

  const [loadingSwap, setLoadingSwap] = useState(false);

  const [hide, setHide] = useState(false);
  const [showload, setShowLoad] = useState(false);
  const history=useHistory();


  const preventMinus = (e) => {
    if (e.code === "Minus") {
      e.preventDefault();
    }
  };



  const dispatch = useDispatch();


  useEffect(() => {
    setAccountName(ual.activeUser?.accountName);
  }, []);
const handleSubmit=async(e)=>{
e.preventDefault();

  const data = {
    walletName:accountName,
    reciver_public_key:walletAddress,
    quantity:`${quantity}`,
  };

  setLoadingSwap(true);
  const response = await getRequiredProtectedData(
    "POST",
    `${stellarApis.SWAP_TELOS}`,
    data,
  );
  if (response && response.data && response.data.success) {

    const resullt = await new BlockchainService().pushTransaction(
      response.data.tnx,
      ual,
      "zptc_swap",
      response.data.optionalData
    );
    if (resullt.success) {  
      const responsebody = await getRequiredProtectedData(
        "POST",
        `${stellarApis.SWAP_TELOS_STELLAR}`,
        data,
      );
      setLoadingSwap(false);
      if (responsebody && responsebody.data && responsebody.data.success) {
        SwalSuccess(responsebody.data.message);
        hideModel(false);
      }
      if (responsebody && responsebody.data && !responsebody.data.success) {
        SwalError(responsebody.data.message);
        setLoadingSwap(false);
      }
      if (responsebody && responsebody.response && responsebody.response.data) {
        SwalError(responsebody.response.data.message);
        setLoadingSwap(false);
      }
    } else {
      setLoadingSwap(false);
      SwalError(resullt.message);
    }
    // setData(response.data.data);


  } 

  if (response && response.data && !response.data.success) {
    SwalError(response.data.message);
    setLoadingSwap(false);
  }
  if (response && response.response && response.response.data) {
    SwalError(response.response.data.message);
    setLoadingSwap(false);
    
  }


}

  return (
    <Modal
      size="md"
      show={showModal}
      centered
      onHide={() => {
        hideModel(false);
      }}
      backdrop="static"
    >
 
      <Modal.Header className={`${!showload ? "" : "hide-load"}`} closeButton>
        <Modal.Title className="text-dark">
          <div className="heading">
            <div className="text-center">
              <div>
                Swap TLOS/STELLAR{" "}
                <InfoBox
                  className="ml-2"
                  msg={
                    <p>
                      To see guide, checkout our 'swap' section in  <span className="text-hover" onClick={()=>history.push('/guide')}>Guide </span> or for video tutorial{" "}
                      {
                        <a
                          href="https://www.youtube.com/watch?v=6BG6U68_B28"
                          target="_blank"
                        >
                          click here
                        </a>
                      }
                    </p>
                  }
                />{" "}
              </div>
            </div>
          </div>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className={`${!showload ? "" : "hide-load"}`}>
        <form onSubmit={handleSubmit}>
        <div>
          <div className="trade-text">
            <h6>Swap zptc from telos to stellar</h6>
          </div>
          <div className="col-sm-4 pt-4">
            <div className=" text-dark">Wallet Name</div>
          </div>
          <div>
            <input
              type="string"
              name="stellarAmount"
              disabled
      
              className={"input-box-sm input text-center"}
              value={accountName}
               
            ></input>
          </div>
         
        </div>



        <div>
          <div className=" pt-2">
            <div className=" text-dark">Receiver Public Key</div>
          </div>
          <div>
            <input
              type="string"
              name="publicKey"             
              className={"input-box-sm input text-center"}
              value={walletAddress}

              onChange={(event) =>
                setWalletAddress(event.target.value)
              }
              required
            ></input>
          </div>

           
        </div>

        <div>
          <div className=" pt-2">
            <div className="c text-dark">Quantity</div>
          </div>
          <div>
            <input
              type="number"
              min="1"
              name="quantity"
             
              onKeyPress={preventMinus}
              className={"input-box-sm input text-center"}
              value={quantity}
 
              onChange={(event) =>
                setQuantity(event.target.value)
              }
              required
            ></input>
          </div>

           
        </div>

        <div>
          <div className="swap">
            <button
              className="submit-transaction"
              disabled={loadingSwap}
              
            >
              {loadingSwap ? (
                <span
                  className="spinner-border spinner-border-smd"
                  role="status"
                  aria-hidden="true"
                ></span>
              ) : (
                "Swap"
              )}
            </button>
          </div>
        </div>
        </form>
      </Modal.Body>
    </Modal>
  );
};
export default SwapTelos;
