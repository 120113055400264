import { useState } from "react";
import { Col, Modal, Row } from "react-bootstrap";
import { useSubmit } from "../../api/api_client";
import AppStoreIcon from '../../icons/app-store.png'
import PlayStoreIcon from '../../icons/playstore.png'
import { userApis } from "../../redux/api";
import { notifyError, notifySuccess } from "../../utils/utils";

export default function SubscribeDialog({ show, setShow, promotionsSubscription, refreshUser, setRefreshUser }) {

  const [loading, setLoading] = useState(false)

  async function update() {
    try {
      setLoading(true)
      // eslint-disable-next-line react-hooks/rules-of-hooks
      const response = await useSubmit(!promotionsSubscription ? userApis.SUBSCRIBE_NEWSLETTER : userApis.UNSUBSCRIBE_NEWSLETTER, {}, "PUT")
      notifySuccess(!promotionsSubscription ? 'Subscribed' : 'Unsubscribed')
      setLoading(false)
      setShow();
      setRefreshUser(!refreshUser)
    } catch (e) {
      console.log(e)
      notifyError(e.message)
      setLoading(false)

    }
  }

  return (<Modal show={show} onHide={() => { setShow() }} size='md'>
    <Modal.Header closeButton>
      <Modal.Title>{!promotionsSubscription ? 'Subscribe' : 'Unsubscribe'}</Modal.Title>
    </Modal.Header>
    <Modal.Body>
      Do you want to {!promotionsSubscription ? 'subscribe' : 'unsubscribe'} to newsletter?
    </Modal.Body>
    <Modal.Footer className="p-3">
      <button
        onClick={update}
        className="button-medium border-shadow medium-btn"
        type="submit"
        title={"Submit"}
      >
        {!loading ? (
          !promotionsSubscription ? 'Subscribe' : 'Unsubscribe'
        ) : (
          <>
            <span
              className="spinner-border spinner-border-smd"
              role="status"
              aria-hidden="true"
            ></span>
          </>
        )}
      </button>
    </Modal.Footer>
  </Modal>)
}