import { createStore, applyMiddleware } from "redux";
import createSagaMiddleware from "redux-saga";
import rootReducer from "./rootReducer";
import rootSaga from "./rootSaga";
import logger from "redux-logger";
import { routerMiddleware } from "connected-react-router";
import history from "../utils/history";
import { persistStore } from "redux-persist";
const sagaMiddleware = createSagaMiddleware();
const middlewares = [sagaMiddleware, routerMiddleware(history)];

// if (process.env.NODE_ENV === 'development') {
//     middlewares.push(logger);
// }

export const store = createStore(rootReducer, applyMiddleware(...middlewares));

sagaMiddleware.run(rootSaga);
export const persistor = persistStore(store);

export default { store, persistor, history };
