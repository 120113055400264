import { all, call } from "redux-saga/effects";
import { crowdsaleSaga } from "./crowdsale/Saga";
import { userSaga } from "./user/Saga";
// import { showsSaga } from "../components/Shows/showsSaga";

export default function* rootSaga() {
   yield all([call(userSaga),
      call(crowdsaleSaga)
   ]);
}
