import React, { useState } from "react";
// import { GoogleLogin as GoogleLoginOld } from "react-google-login";
import { useHistory } from "react-router-dom";
import SwalError from "../../components/ToastMessage/SwalError";
 
import { userApis } from "../../redux/api";
import { getRequiredData } from "../../redux/apicalltemplate";
import { GoogleLogin, GoogleOAuthProvider } from '@react-oauth/google';

import AppServices from "../../services/AppService";


const appService = new AppServices();

const GoogleAuth = ({ ual, mainLoading }) => {
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const handleGoogleLogin = async () => {

    if (ual?.activeUser !== null) {
      await appService.logout(ual);
    }

    setLoading(true);
    document.getElementById("googleButton").click();
  };

  const googleResponse = async (response) => {

    if (ual?.activeUser !== null) {
      await appService.logout(ual);
    }

    setLoading(false);
   
    const data = {};
    data.accessToken = response.clientId;
    // data.profile = response.profileObj;
    // data.profile.photoUrl = response.profileObj.imageUrl;
    data.type = "sucess";
    data.id_token = response.credential;
    data.refreshToken = "";

    try {
      const responsedata = await getRequiredData(
        "POST",
        userApis.GOOGLE_LOGIN,
        data
      );
      if (responsedata) {
        localStorage.setItem("token", responsedata.data.authToken);
        if (responsedata.data.success) {
          history.push("/home");
        }
      }
    } catch (error) {

      if (error && error.response && error.response.data) {
        // console.log(error.response.data.message);
        SwalError(error.response.data.message);
      }
      if (
        !error.response.data.authToken &&
        error.response.data.is2FA_enabled == true
      ) {
        localStorage.setItem("isGoogleLogin", true);
        localStorage.setItem("googleLoginData", JSON.stringify(data));
        localStorage.setItem("2faEnabled", true);
        if (error.response.data.is2faEnabledViaMobile) {
          localStorage.setItem("2famobileenabled", true);
          localStorage.setItem("2faEnabled", true);
        }
        history.push("/verify2fa");
      }

      if (
        error.response.data.user_id &&
        error.response.data.message == "Unverified device."
      ) {
        localStorage.setItem("user_id", error.response.data.user_id);
        localStorage.setItem("device_id", error.response.data.device_id);
        localStorage.setItem("isGoogleLogin", true);
        localStorage.setItem("googleLoginData", JSON.stringify(data));
        history.push(`/verify-device`);
      }
    }

    // this.props.googleAuth(data);
  };

  const responseGoogleFailure = async(response) => {
   

    setLoading(false);
  };
 

  return (
    <div>
      <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}>

        {/* {loading ? (
          <span
            className="spinner-border spinner-border-smd"
            role="status"
            aria-hidden="true"
          ></span>
        ) : (
          <button
            disabled={loading || mainLoading}
            className="btn-def"
            style={{ width: "60px" }}
            type='button'
              onClick={handleGoogleLogin}
           
          >
            <img
              style={{ width: "100%", height: "100%" }}
              src={GoogleIcon}
              className="fab fa-2x fa-google google-img"
            />
          </button>
        )} */}

        <div style={{ display: "none" }}>
        {/* <GoogleLoginOld
          render={(renderProps) => (
            <button

              id="googleButton"
              className="btn btn-block transparentButton"
              onClick={renderProps.onClick}
            >
              <i className="fab fa-google"></i> Login With Google
            </button>
          )}
          className="btn btn-block transparentButton"
          id="googleLoginButton"
          // clientId="948828632139-8vrrmrmg735i3lialt70bnmt4dalvrcg.apps.googleusercontent.com"
          clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}
          buttonText="LOGIN WITH GOOGLE"
          redirectUri="localhost:7011/signin?googleLogin=true"
          onSuccess={googleResponse}
          onFailure={responseGoogleFailure}
        /> */}

      </div>
        <GoogleLogin
          onSuccess={googleResponse}
          onError={responseGoogleFailure}
          useOneTap
          cancel_on_tap_outside={true}
          type='icon'
          shape='circle'
         
          
        />  
       </GoogleOAuthProvider>

    </div>

  );
};

export default GoogleAuth;
