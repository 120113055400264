import { combineReducers } from "redux";
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { connectRouter } from 'connected-react-router';
import { userReducer } from "./user/Reducer";
import history from '../utils/history';
import { crowdsaleReducer } from "./crowdsale/Reducer";
import { userActionTypes } from "./user/Types";
import { appDataReducer } from "../context";
// import {
//   createFilter,
// } from 'redux-persist-transform-filter';
// const saveUserFilter = createFilter('audit', ['logindata']);
//--------------------------------


const appReducer = combineReducers({
  user: userReducer,
  router: connectRouter(history),
  crowdsale: crowdsaleReducer,
  appData: appDataReducer
});

const rootReducer = (state, action) => {
  if (action.type === userActionTypes.LOGOUT_SUCCESS) {
    state = undefined;

  }
  if (action.type === userActionTypes.LOGOUT_FAILURE) {
    state = undefined;

  }

  return appReducer(state, action);

}

export default rootReducer

