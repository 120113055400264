let clientId = "com.zepta.gram.signin.testing";
if (
  window.location.host === "app.zeptagram.com" ||
  window.location.host === "staging-app.zeptagram.com" ||
  window.location.host === "test-user.zeptagram.com" ||
  window.location.host === "test-user-new.zeptagram.com"
) {
  clientId = "com.zepta.gram.signin.production";
} else if (
  window.location.host === "zepta-user.zeptagram.com" ||
  window.location.host === "localhost:3000"
) {
  clientId = "com.zepta.gram.signin.testing";
}

export const initApple = () => {
  window.AppleID.auth.init({
    clientId, // As defined in apple developer settings
    scope: "name email",
    redirectURI: `${window.location.origin}`, // As defined in apple developer settings
    state: "origin:web", // Used for differentiating from backend
    usePopup: true,
  });
};

export const singInApple = async () => {
  const { authorization, user } = await window.AppleID.auth.signIn();

  const profile = {};
  profile.identityToken = authorization.id_token;
  profile.origin = authorization.state;

  if (user) {
    profile.email = user.email;
    profile.fullName = {};
    profile.fullName.givenName = user.name.firstName;
    profile.fullName.familyName = user.name.lastName;
  }

  return { profile };
};



export function traverseAndFlatten(currentNode, target, flattenedKey) {
  for (var key in currentNode) {
      if (currentNode.hasOwnProperty(key)) {
          var newKey;
          if (flattenedKey === undefined) {
              newKey = key;
          } else {
              newKey = flattenedKey + '.' + key;
          }

          var value = currentNode[key];
          if (typeof value === "object") {
              traverseAndFlatten(value, target, newKey);
          } else {
              target[newKey] = value;
          }
      }
  }
}