import Swal from "sweetalert2";

const SwalSuccess = (message) => {
  Swal.fire({
    titleText: message,
    type: "success",
    icon: "success",
    toast: true,
    position: "top",
    showConfirmButton: false,
    timer: 5000,
    toast: true,
    timerProgressBar: true,
    background: "white",
  });
};

export default SwalSuccess;
