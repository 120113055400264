import React, { useContext, useEffect, useState } from "react";
import "./App.css";
import { Routes } from "./routes/Routes";
import { useSelector } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import Header from "./components/Header/Header";
import axios from "axios";
import { useHistory } from "react-router-dom";
import Zeptagram from "../src/images/zeptagram.svg";
import Mantainence from "./containers/Maintanence/Maintanence";
import { getRequiredData } from "./redux/apicalltemplate";
import { userApis } from "../src/redux/api";
import AnchorUal from "./Anchor";
import { Notice } from "./components/Notice/Notice";

import LoadingOverlay from "react-loading-overlay";
import { UserContext } from "./context";
import { WidgetChat } from "./components/ChatWidget/Chat";
import { isAllowed, setAllowed, getUserInfo } from '@stellar/freighter-api';

function App()
{
  // const { loginloading } = useSelector((state) => state.user);
  // const [isMaintainence, setIsMaintainence] = useState(true);
  // const { cartdata } = useSelector((state) => state.crowdsale);

  // ------------------------FREIGHTER SECTION----------------------------------------------
  // const getPk = async () =>
  // {
  //   console.log("-----------------GET PK---------------------")
  //   const publicKey = await getUserInfo();
  //   console.log(publicKey)
  //   return publicKey;
  // }
  // getPk("hiii kaus")
  // const connect = async () =>
  // {
  //   console.log(" ============================================================== HELLO ============================")
  //   await setAllowed();

  // }

  useEffect(() =>
  {
    window.process = {
      ...window.process,
    };
  }, []);


  let maintanence = false;
  //
  const history = useHistory();
  ///axios interceptors-----------for handlings error---
  axios.interceptors.response.use(
    (response) =>
    {
      return response;
    },
    (error) =>
    {
      //Unable to get access token
      if (
        error.response &&
        error.response.status === 401 &&
        error.response.data &&
        error.response.data &&
        ((error.response.data.error &&
          error.response.data.error === "invalid_token") ||
          error.response.data.message === "Unauthorised user." ||
          error.response.data.message === "Unknown device." ||
          error.response.data.message === "Invalid or expired token")
      )
      {
        if (localStorage.getItem("token"))
        {
          localStorage.clear();
          history.push("/login");
        }
      }

      return Promise.reject(error);
    }
  );

  React.useEffect(async () =>
  {
    await getRequiredData("GET", userApis?.GET_CSRF);
  }, []);


  const { loadertext, overlayLoader } = useSelector((state => state.appData));

  return (
    <div className="tab-content">
      <LoadingOverlay
        className="row m-auto h-100 w-100"
        active={overlayLoader}
        spinner
        text={loadertext}
      >

        <ToastContainer limit={2} />

        {maintanence ? (
          <Mantainence />
        ) : (
          <div className="content">
            {/* {localStorage.getItem("token") ? <Header /> : <div></div>} */}
            {/* <Routes cartdata={cartdata} /> */}
            {/* <button onClick={connect}>Hello</button>
            <button onClick={getPk}>showpublickey</button> */}
            <AnchorUal />
          </div>
        )}
        <Notice />
        {
          <WidgetChat />
        }
      </LoadingOverlay>
    </div>
  );
}

export default App;
