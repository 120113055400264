import React from 'react';
import Girl from "../../images/lady.svg";
import Heart from "../../images/heart.svg";

export const CrowdsaleList =()=>{
    return(
        <div className='d-flex flex-wrap align-center box-shadow py-3 border-radius-2' >

<div className="table-responsive   w-100">
                <table className="table  table-hover">
                  <thead className="stick-header" style={{background:'white',fontWeight:'bold'}}>
                    <tr style={{ borderTop: 0 }}>
                    <th scope="col" className="text-hover">
                         
                      </th>
                      <th scope="col" className="text-hover">
                        Music Name
                      </th>
                      <th scope="col" className="text-hover">
                        Listing Type
                      </th>
                      <th scope="col" className="text-hover">
                       Target Amount
                      </th>
                      <th scope="col" className="text-hover">
                       State
                      </th>
                      <th scope="col" className="text-hover">
                      Amount Raised
                      </th>
                      <th scope="col" className="text-hover">
                     End Date
                      </th>
                      
                    </tr>
                  </thead>
                  <tbody>
                    {Array.from({length:12}).map((el,i) => {
                         
                        return (
                          <tr
                            className="cur-pointer text-dark"
                            key={i}
                           
                          >
                              <td className='vertical-center' >
                                
                                <svg width="32" height="29" viewBox="0 0 32 29"
                                 fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M16 28.4948C31.583 16.2315 30.9971 11.8964 30.9971 8.49857C30.9971 5.10078 28.1852 1 23.4986 1C18.812 1 16 5.99905 16 5.99905C16 5.99905 13.188 1 8.50143 1C3.81483 1 1.00286 5.10078 1.00286 8.49857C1.00286 11.8964 0.41704 16.2315 16 28.4948Z" fill="white" stroke="black" stroke-width="0.15"/>
</svg>
{/* 
<svg xmlns="http://www.w3.org/2000/svg" width="32" height="29" viewBox="0 0 32 29" fill="none">
<path d="M16 28.4948C31.583 16.2315 30.9971 11.8964 30.9971 8.49857C30.9971 5.10078 28.1852 1 23.4986 1C18.812 1 16 5.99905 16 5.99905C16 5.99905 13.188 1 8.50143 1C3.81483 1 1.00286 5.10078 1.00286 8.49857C1.00286 11.8964 0.41704 16.2315 16 28.4948Z" fill="#CA4330" stroke="black" stroke-width="0.15"/>
</svg> */}

                                </td>
                             <th scope="row">
                              <div className=''>
                                <img className='box-shadow border-radius ' width={80} height={80} src={Girl}/>
                                <div className='text-dark'>Snowman</div>
                              </div>
                             </th>
                            <td className='vertical-center'>Crowdsale</td>

                            <td className='vertical-center'>
                               $ 24324324
                            </td>
                            <td className='vertical-center'>{'Successful'}</td>
                            <td className='vertical-center'>
                              531.2432
                            </td>
                            <td className='vertical-center'>
                               Thur 23 sept
                            </td>
                            <td className='vertical-center'>
                               <button className="button-medium " style={{width:'100px'}}>Invest</button>
                            </td>
                           
                          </tr>
                        );
                      })}
                  </tbody>
                </table>
              </div>

        </div>
      
    )
}