import { takeLatest, put, all, call } from "redux-saga/effects";
import { userApis } from "../api";
import { userActionTypes } from "./Types";
import
{
  SuccessToast,
  ErrorToast,
} from "../../components/ToastMessage/SuccessToast";
import { ToastContainer, toast } from "react-toastify";
import { push } from "connected-react-router";
import axios from "axios";
import
{
  loginSuccess,
  loginFailure,
  logoutFailure,
  logoutSuccess,
  signupSuccess,
  signupFailure,
  verifyEmailSuccess,
  verifyEmailFailure,
  forgotPasswordFailure,
  forgotPasswordSuccess,
  resendOtpFailure,
  resendOtpSuccess,
  verifyforgotPasswordFailure,
  verifyforgotPasswordSuccess,
  userDetailsSuccess,
  userDetailsFailure,
  passwordChanged,
  reset2faSuccess,
  reset2faFailure,
  verifyReset2faSuccess,
  verifyReset2faFailure,
  anchorLoginSuccess,
  lobstrLoginSuccess,
  freighterLoginSuccess,
  bookmarkSuccess,
  bookmarkFailure,
} from "../user/Action";
import { removeStellarSession } from "../../utils/utils";
import { signTransaction } from "@stellar/freighter-api";

// login---
export function* loginStart({ payload })
{
  try
  {
    const { type } = payload;
    const response = yield axios({
      method: "post",
      url: `${type === "mobile" ? userApis.LOGIN_MOBILE : userApis.LOGIN}`,
      headers: {
        Authorization: localStorage.getItem("token"),
      },
      data: payload.data,
    });
    const { data } = response;

    if (data && data.authToken)
    {
      localStorage.setItem("token", data.authToken);
    }
    if (data)
    {
      //  localStorage.setItem('wallet_pin_changed',data['wallet_pin_changed'])

      if (data.message === "Unverified email, can not login")
      {
        yield put(push(`/verify-email?email=${payload.data.email}`));
      }
      if (data.authToken)
      {
        if (data.is2FA_enabled === true)
        {
          localStorage.removeItem("email");
          localStorage.removeItem("pwd");
          localStorage.removeItem("rememberme");
          sessionStorage.removeItem("email");
          sessionStorage.removeItem("pwd");
          sessionStorage.removeItem("rememberme");
        }
        yield put(push("/home"));
      }

      if (!data.authToken && data.is2FA_enabled === true)
      {
        sessionStorage.setItem("email", payload.data.email);
        sessionStorage.setItem("pwd", payload.data.password);
        sessionStorage.setItem("rememberme", payload.data.rememberMe);
        localStorage.setItem("2faEnabled", true);
        if (data.is2faEnabledViaMobile)
        {
          localStorage.setItem("2famobileenabled", true);
          localStorage.setItem("2faEnabled", true);
        }
        yield put(push("/verify2fa"));
      }

      yield put(loginSuccess(data));
    }
  } catch (error)
  {
    if (error && error.response && error.response.data)
    {
      if (
        error.response.data.user_id &&
        error.response.data.message === "Unverified device"
      )
      {
        sessionStorage.setItem("email", payload.data.email);
        sessionStorage.setItem("pwd", payload.data.password);
        sessionStorage.setItem("rememberme", payload.data.rememberMe);
        // localStorage.setItem("2faEnabled", true);
        // if (data.is2faEnabledViaMobile) {
        //   localStorage.setItem("2famobileenabled", true);
        //   localStorage.setItem("2faEnabled", true);
        // }

        localStorage.setItem("user_id", error.response.data.user_id);
        localStorage.setItem("device_id", error.response.data.device_id);
        yield put(push(`/verify-device`));
      }
      yield toast.error(<ErrorToast message={error.response.data.message} />, {
        toastId: 1,
        updateId: 1,
      });

      if (
        error.response.data.password_changed === false ||
        error.response.data.message === "Please reset your password."
      )
      {
        yield put(push(`/forgetpassword?email=${payload.data.email}`));
      }

      if (error.response.data.message === "Unverified email, can not login")
      {
        yield put(push(`/verify-email?email=${payload.data.email}`));
      }
      if (error.response.data.is2FA_enabled === true)
      {
        sessionStorage.setItem("email", payload.data.email);
        sessionStorage.setItem("pwd", payload.data.password);
        sessionStorage.setItem("rememberme", payload.data.rememberMe);
        localStorage.setItem("2faEnabled", true);
        if (error.response.data.is2faEnabledViaMobile)
        {
          localStorage.setItem("2famobileenabled", true);
          localStorage.setItem("2faEnabled", true);
        }
        yield put(push("/verify2fa"));
      }
    }
    yield put(loginFailure(error));
  }
}

export function* login()
{
  yield takeLatest(userActionTypes.LOGIN_START, loginStart);
}

// signup-----
export function* signupStart({ payload })
{
  try
  {
    const response = yield axios({
      method: "post",
      url: userApis.SIGNUP,
      headers: {
        Authorization: localStorage.getItem("token"),
      },
      data: payload,
    });
    const { data } = response;
    if (data)
    {
      if (data && data.message)
      {
        yield toast.success(<SuccessToast message={data.message} />, {
          toastId: 1,
          updateId: 1,
        });
        if (data.message === "Account for given email already exists")
        {
          yield put(push("/login"));
        } else
        {
          yield put(push(`/verify-email?email=${payload.email}`));
        }
      }

      yield put(signupSuccess(data));
    }
  } catch (error)
  {
    if (error && error.response && error.response.data)
    {
      yield toast.error(<ErrorToast message={error.response.data.message} />, {
        toastId: 1,
        updateId: 1,
      });
    }
    yield put(signupFailure(error));
  }
}

export function* signup()
{
  yield takeLatest(userActionTypes.SIGNUP_START, signupStart);
}

//  verify email---
export function* verifyEmailStart({ payload })
{
  try
  {
    const response = yield axios({
      method: "post",
      url: userApis.VERIFY_ACCOUNT,
      headers: {
        Authorization: localStorage.getItem("token"),
      },
      data: payload,
    });
    const { data } = response;
    if (data)
    {
      if (data && data.message)
      {
        yield toast.success(<SuccessToast message={data.message} />, {
          toastId: 1,
          updateId: 1,
        });
      }
      if (data && data.authToken)
      {
        localStorage.setItem("token", data.authToken);
        yield put(push("/"));
      } else
      {
        yield put(push("/login"));
      }

      yield put(verifyEmailSuccess(data));
    }
  } catch (error)
  {
    if (error && error.response && error.response.data)
    {
      yield toast.error(<ErrorToast message={error.response.data.message} />, {
        toastId: 1,
        updateId: 1,
      });
    }
    yield put(verifyEmailFailure(error));
  }
}

export function* verifyEmail()
{
  yield takeLatest(userActionTypes.VERIFY_EMAIL_START, verifyEmailStart);
}

// forget password---
export function* forgotPasswordStart({ payload })
{
  try
  {
    const response = yield axios({
      method: "post",
      url: userApis.FORGET_PASSWORD,
      headers: {
        Authorization: localStorage.getItem("token"),
      },
      data: payload,
    });
    const { data } = response;
    if (data)
    {
      if (data && data.message)
      {
        yield toast.success(<SuccessToast message={data.message} />, {
          toastId: 1,
          updateId: 1,
        });
      }
      yield put(push(`/verifypassword?email=${payload.email}`));
      yield put(forgotPasswordSuccess(data));
    }
  } catch (error)
  {
    if (error && error.response && error.response.data)
    {
      yield toast.error(<ErrorToast message={error.response.data.message} />, {
        toastId: 1,
        updateId: 1,
      });
    }
    yield put(forgotPasswordFailure(error));
  }
}

export function* forgotPassword()
{
  yield takeLatest(userActionTypes.FORGOT_PASSWORD_START, forgotPasswordStart);
}

// verify forget password---
export function* verifyforgotPasswordStart({ payload })
{
  try
  {
    const response = yield axios({
      method: "post",
      url: userApis.RESET_FORGOT_PASSWORD,
      headers: {
        Authorization: localStorage.getItem("token"),
      },
      data: payload,
    });
    const { data } = response;
    if (data)
    {
      if (data && data.message)
      {
        yield toast.success(<SuccessToast message={data.message} />, {
          toastId: 1,
          updateId: 1,
        });
      }
      if (data.success)
      {
        yield put(push("/login"));
      }
      yield put(verifyforgotPasswordSuccess(data));
    }
  } catch (error)
  {
    if (error && error.response && error.response.data)
    {
      yield toast.error(<ErrorToast message={error.response.data.message} />, {
        toastId: 1,
        updateId: 1,
      });
    }
    yield put(verifyforgotPasswordFailure(error));
  }
}

export function* verifyforgotPassword()
{
  yield takeLatest(
    userActionTypes.VERIFY_FORGOT_PASSWORD_START,
    verifyforgotPasswordStart
  );
}

// resend otp------
export function* resendOtpStart({ payload })
{
  try
  {
    const response = yield axios({
      method: "post",
      url: userApis.RESEND_OTP_PASSWORD,
      headers: {
        Authorization: localStorage.getItem("token"),
      },
      data: payload,
    });
    const { data } = response;
    if (data)
    {
      if (data && data.message)
      {
        yield toast.success(<SuccessToast message={data.message} />, {
          toastId: 1,
          updateId: 1,
        });
      }
      yield put(resendOtpSuccess(data));
    }
  } catch (error)
  {
    if (error && error.response && error.response.data)
    {
      yield toast.error(<ErrorToast message={error.response.data.message} />);
    }
    yield put(resendOtpFailure(error));
  }
}

export function* resendOtp()
{
  yield takeLatest(userActionTypes.RESEND_OTP_START, resendOtpStart);
}

export function* userDetailStart({ payload })
{
  try
  {
    const response = yield axios({
      method: "get",
      url: userApis.GET_DETAILS,
      headers: {
        "x-auth-token": localStorage.getItem("token"),
      },
      data: payload,
    });
    const { data } = response;
    if (data && data.data)
    {
      yield put(userDetailsSuccess(data.data));
    }
  } catch (error)
  {
    yield put(userDetailsFailure(error));
  }
}

export function* userDetail()
{
  yield takeLatest(userActionTypes.USER_DETAIL_START, userDetailStart);
}

export function* bookmarkStart({ })
{
  try
  {
    const response = yield axios({
      method: "get",
      url: userApis.ALL_BOOKMARK,
      headers: {
        "x-auth-token": localStorage.getItem("token"),
      },
    });
    const { data } = response;
    if (data && data.data)
    {
      yield put(bookmarkSuccess(data.data));
    }
  } catch (error)
  {
    yield put(bookmarkFailure(error));
  }
}

export function* bookmark()
{
  yield takeLatest(userActionTypes.BOOKMARK_START, bookmarkStart);
}

// logout-------
export function* logoutStart({ payload })
{
  const { ual } = payload;
  try
  {
    const response = yield axios({
      method: "put",
      url: userApis.LOGOUT,
      headers: {
        "x-auth-token": localStorage.getItem("token"),
      },
    });
    const { data } = response;
    if (data)
    {
      localStorage.clear();
      sessionStorage.clear();
      if (ual?.activeUser) ual.logout();
      yield put(push("/login"));
      yield put(logoutSuccess(data));
    }
  } catch (error)
  {
    localStorage.clear();
    sessionStorage.clear();
    if (ual?.activeUser) ual.logout();
    yield put(push("/login"));
    yield put(logoutFailure(error));
  }
}

export function* logout()
{
  yield takeLatest(userActionTypes.LOGOUT, logoutStart);
}

export function* startChangePassword({ payload })
{
  try
  {
    const response = yield axios({
      method: "post",
      url: userApis.CHANGE_PASSWORD,
      headers: {
        "Content-Type": "application/json",
        "x-auth-token": localStorage.getItem("token"),
      },
      data: {
        oldPassword: payload["oldPassword"],
        newPassword: payload["newPassword"],
      },
    });

    const { data } = response;

    if (!data.success)
    {
      yield toast.error(<ErrorToast message={data.message} />, {
        toastId: 1,
        updateId: 1,
      });
    }

    if (data.success)
    {
      yield toast.success(<SuccessToast message={data.message} />, {
        toastId: 1,
        updateId: 1,
      });
      yield put(passwordChanged(data));
    }
  } catch (error) { }
}

export function* changePassword()
{
  yield takeLatest(userActionTypes.PASSWORD_CHANGE, startChangePassword);
}

export function* reset2faStart({ payload })
{
  try
  {
    const response = yield axios({
      method: "put",
      url: userApis.RESET_2FA,
      headers: {
        "x-auth-token": localStorage.getItem("token"),
      },
      data: payload,
    });
    const { data } = response;
    if (data)
    {
      if (!data.success)
      {
        yield toast.error(<ErrorToast message={data.message} />, {
          toastId: 1,
          updateId: 1,
        });
      }

      if (data.success)
      {
        yield toast.success(<SuccessToast message={data.message} />, {
          toastId: 1,
          updateId: 1,
        });
        // `/verify-email?email=${payload.email}`
        yield put(push(`/verifyreset2fa?email=${payload.email}`));
        yield put(reset2faSuccess(data));
      }
    }
  } catch (error)
  {
    if (error && error.response && error.response.data)
    {
      yield toast.error(<ErrorToast message={error.response.data.message} />, {
        toastId: 1,
        updateId: 1,
      });
    }
    yield put(reset2faFailure(error));
  }
}

export function* reset2fa()
{
  yield takeLatest(userActionTypes.RESET_2FA_START, reset2faStart);
}

export function* verifyReset2faStart({ payload })
{
  try
  {
    const response = yield axios({
      method: "put",
      url: userApis.VERIFY_RESET_2FA,
      headers: {
        "x-auth-token": localStorage.getItem("token"),
      },
      data: payload,
    });
    const { data } = response;

    if (data)
    {
      if (!data.success)
      {
        yield toast.error(<ErrorToast message={data.message} />, {
          toastId: 1,
          updateId: 1,
        });
      }
      if (data.success)
      {
        yield toast.success(<SuccessToast message={data.message} />, {
          toastId: 1,
          updateId: 1,
        });
        yield put(push("/login"));
        yield put(verifyReset2faSuccess(data));
      }
    }
  } catch (error)
  {
    if (error && error.response && error.response.data)
    {
      yield toast.error(<ErrorToast message={error.response.data.message} />);
    }
    yield put(verifyReset2faFailure(error));
  }
}

export function* verifyReset2fa()
{
  yield takeLatest(userActionTypes.VERIFY_RESET_2FA_START, verifyReset2faStart);
}

export function* anchorLoginStart({ payload })
{
  try
  {
    const { type } = payload;
    console.log("ANCHOR PAYLOAD FROM SAGA", payload);
    const response = yield axios({
      method: "post",
      url: `${type === "mobile" ? userApis.LOGIN_MOBILE : userApis.ANCHOR_LOGIN
        }`,
      headers: {
        Authorization: localStorage.getItem("token"),
      },
      data: {
        signature: payload.data.signature,
        captcha: payload.data.captcha,
        hash: payload.data.hash,
        accountName: payload.data.walletName,
        email: payload.data.email,
        rememberMe: payload.rememberMe,
        otpToken: payload.data.otpToken,
      },
    });
    const { data } = response;
    if (data && data.authToken)
    {
      localStorage.setItem("token", data.authToken);
    }

    if (data)
    {
      if (data.message === "Unverified email, can not login")
      {
        yield put(push(`/verify-email?email=${data.email}`));
      }
      if (data.message === "User not found")
      {
        yield put(push(`/profileRegistration/${payload.data.walletName}`));
      }
      if (data.authToken)
      {
        if (data.is2FA_enabled == true)
        {
          localStorage.removeItem("email");
          localStorage.removeItem("pwd");
          localStorage.removeItem("rememberme");
          sessionStorage.removeItem("email");
          sessionStorage.removeItem("pwd");
          sessionStorage.removeItem("rememberme");
        }
        yield removeStellarSession();
        yield put(push("/home"));
      }

      if (!data.authToken && data.is2FA_enabled == true)
      {
        sessionStorage.setItem("email", payload.data.email);
        sessionStorage.setItem("pwd", payload.data.password);
        sessionStorage.setItem("rememberme", payload.data.rememberMe);
        localStorage.setItem("2faEnabled", true);
        if (data.is2faEnabledViaMobile)
        {
          localStorage.setItem("2famobileenabled", true);
          localStorage.setItem("2faEnabled", true);
        }
        yield put(push("/verify2fa"));
      }
      yield removeStellarSession();
      yield put(anchorLoginSuccess(data));
    }
  } catch (error)
  {
    if (error && error.response && error.response.data)
    {
      if (error.response.data.message === "Unverified email, can not login")
      {
        yield put(push(`/verify-email?email=${error.response.data.email}`));
      }
      if (error.response.data.message === "User not found")
      {
        yield put(push(`/profileRegistration/${payload.data.walletName}`));
      }
      if (
        error.response.data.user_id &&
        error.response.data.message === "Unverified device."
      )
      {
        sessionStorage.setItem("email", error.response.data.email);
        // sessionStorage.setItem("rememberme", payload.data.rememberMe);
        // localStorage.setItem("2faEnabled", true);
        // if (data.is2faEnabledViaMobile) {
        //   localStorage.setItem("2famobileenabled", true);
        //   localStorage.setItem("2faEnabled", true);
        // }

        localStorage.setItem("user_id", error.response.data.user_id);
        localStorage.setItem("device_id", error.response.data.device_id);
        yield put(push(`/verify-device`));
      }
      yield toast.error(<ErrorToast message={error.response.data.message} />, {
        toastId: 1,
        updateId: 1,
      });

      if (error.response.data.message === "Unverified email, can not login")
      {
        yield put(push(`/verify-email?email=${error.response.data.email}`));
      }
      if (error.response.data.is2FA_enabled === true)
      {
        sessionStorage.setItem("email", error.response.data.email);
        // sessionStorage.setItem("rememberme", payload.data.rememberMe);
        localStorage.setItem("2faEnabled", true);
        if (error.response.data.is2faEnabledViaMobile)
        {
          localStorage.setItem("2famobileenabled", true);
          localStorage.setItem("2faEnabled", true);
        }
        yield put(push("/verify2fa"));
      }
    }
    yield put(loginFailure(error));
  }
}

export function* anchorLogin()
{
  yield takeLatest(userActionTypes.ANCHOR_LOGIN_START, anchorLoginStart);
}

export function* anchorSignupStart({ payload })
{
  try
  {
    const response = yield axios({
      method: "post",
      url: userApis.ANCHOR_SIGNUP,
      headers: {
        Authorization: localStorage.getItem("token"),
      },
      data: payload,
    });
    const { data } = response;
    if (data)
    {
      if (data && data.message)
      {
        yield toast.success(<SuccessToast message={data.message} />, {
          toastId: 1,
          updateId: 1,
        });
        if (data.message == "Account for given email already exists")
        {
          yield put(push("/login"));
        } else
        {
          yield put(push(`/verify-email?email=${payload.email}`));
        }
      }

      yield put(signupSuccess(data));
    }
  } catch (error)
  {
    if (error && error.response && error.response.data)
    {
      yield toast.error(<ErrorToast message={error.response.data.message} />, {
        toastId: 1,
        updateId: 1,
      });
    }
    yield put(signupFailure(error));
  }
}

export function* anchorSignup()
{
  yield takeLatest(userActionTypes.ANCHOR_SIGNUP_START, anchorSignupStart);
}

export function* lobstrLoginStart({ payload })
{
  console.log("PAYLOAD FROM SAGA", payload);

  try
  {
    console.log("Payload from try SAGA", payload);

    const { type } = payload;
    const response = yield axios({
      method: "post",
      url: `${type === "null" ? userApis.LOBSTR_SIGNUP : userApis.LOBSTR_LOGIN
        }`,

      data: payload.data,
    });

    console.log("===========RESPONSE FROM SAGA=======>>>>>", response);
    const { data } = response;
    if (data && data.authToken)
    {
      localStorage.setItem("token", data.authToken);
    }

    if (data)
    {
      if (data.message === "Unverified email, can not login")
      {
        yield put(push(`/verify-email?email=${data.email}`));
      }
      if (data.message === "User not found")
      {
        yield put(push(`/profileRegistration/${payload.data.walletName}`));
      }
      if (data.authToken)
      {
        if (data.is2FA_enabled == true)
        {
          localStorage.removeItem("email");
          localStorage.removeItem("pwd");
          localStorage.removeItem("rememberme");
          sessionStorage.removeItem("email");
          sessionStorage.removeItem("pwd");
          sessionStorage.removeItem("rememberme");
        }
        yield removeStellarSession();
        yield put(push("/home"));
      }

      if (!data.authToken && data.is2FA_enabled == true)
      {
        sessionStorage.setItem("email", payload.data.email);
        sessionStorage.setItem("pwd", payload.data.password);
        sessionStorage.setItem("rememberme", payload.data.rememberMe);
        localStorage.setItem("2faEnabled", true);
        if (data.is2faEnabledViaMobile)
        {
          localStorage.setItem("2famobileenabled", true);
          localStorage.setItem("2faEnabled", true);
        }
        yield put(push("/verify2fa"));
      }
      yield removeStellarSession();
      yield put(lobstrLoginSuccess(data));
    }
  } catch (error)
  {
    console.log("ERROR", error);
    if (error && error.response && error.response.data)
    {
      if (error.response.data.message === "Unverified email, can not login")
      {
        yield put(push(`/verify-email?email=${error.response.data.email}`));
      }
      if (error.response.data.message === "Please create your profile.")
      {
        yield put(push(`/profileRegistrationLobstr`));
      }
      if (
        error.response.data.user_id &&
        error.response.data.message === "Unverified device."
      )
      {
        sessionStorage.setItem("email", error.response.data.email);
        // sessionStorage.setItem("rememberme", payload.data.rememberMe);
        // localStorage.setItem("2faEnabled", true);
        // if (data.is2faEnabledViaMobile) {
        //   localStorage.setItem("2famobileenabled", true);
        //   localStorage.setItem("2faEnabled", true);
        // }

        localStorage.setItem("user_id", error.response.data.user_id);
        localStorage.setItem("device_id", error.response.data.device_id);
        yield put(push(`/verify-device`));
      }
      // yield toast.error(<ErrorToast message={error.response.data.message} />, {
      //   toastId: 1,
      //   updateId: 1,
      // });

      if (error.response.data.message === "Unverified email, can not login")
      {
        yield put(push(`/verify-email?email=${error.response.data.email}`));
      }
      if (error.response.data.is2FA_enabled === true)
      {
        sessionStorage.setItem("email", error.response.data.email);
        // sessionStorage.setItem("rememberme", payload.data.rememberMe);
        localStorage.setItem("2faEnabled", true);
        if (error.response.data.is2faEnabledViaMobile)
        {
          localStorage.setItem("2famobileenabled", true);
          localStorage.setItem("2faEnabled", true);
        }
        yield put(push("/verify2fa"));
      }
    }
    yield put(loginFailure(error));
  }
}

export function* lobstrLogin()
{
  yield takeLatest(userActionTypes.LOBSTR_LOGIN_START, lobstrLoginStart);
}

export function* lobstrSignupStart({ payload })
{
  try
  {
    const response = yield axios({
      method: "post",
      url: userApis.LOBSTR_SIGNUP,
      headers: {
        Authorization: localStorage.getItem("token"),
      },
      data: payload,
    });
    const { data } = response;
    if (data)
    {
      if (data && data.message)
      {
        yield toast.success(<SuccessToast message={data.message} />, {
          toastId: 1,
          updateId: 1,
        });
        if (data.message == "Account for given email already exists")
        {
          yield put(push("/login"));
        } else
        {
          yield put(push(`/verify-email?email=${payload.email}`));
        }
      }

      yield put(signupSuccess(data));
    }
  } catch (error)
  {
    if (error && error.response && error.response.data)
    {
      yield toast.error(<ErrorToast message={error.response.data.message} />, {
        toastId: 1,
        updateId: 1,
      });
    }
    yield put(signupFailure(error));
  }
}

export function* lobstrSignup()
{
  yield takeLatest(userActionTypes.LOBSTR_SIGNUP_START, lobstrSignupStart);
}

export function* freighterLoginStart({ payload })
{
  console.log("PAYLOAD FROM SAGA", payload);

  try
  {
    console.log("Payload from try SAGA", payload);

    const response = yield axios({
      method: "post",
      url: `${userApis.FREIGHTER_LOGIN}`,
      data: { publicKey: payload.data },
    });

    console.log("===========RESPONSE FROM SAGA=======>>>>>", response);
    const { data } = response;
    if (data && data.authToken)
    {
      localStorage.setItem("token", data.authToken);
    }

    if (data)
    {
      if (data.message === "Unverified email, can not login")
      {
        yield put(push(`/verify-email?email=${data.email}`));
      }
      if (data.message === "Please create your profile.")
      {
        yield put(push(`/profileRegistration`));
      }
      if (data.authToken)
      {
        console.log("AUTHENTICATED!!!!")

        if (data.is2FA_enabled === true)
        {
          localStorage.removeItem("email");
          localStorage.removeItem("pwd");
          localStorage.removeItem("rememberme");
          sessionStorage.removeItem("email");
          sessionStorage.removeItem("pwd");
          sessionStorage.removeItem("rememberme");
        }
        yield removeStellarSession();
        yield put(push("/home"));
      }

      if (!data.authToken && data.is2FA_enabled == true)
      {
        sessionStorage.setItem("email", payload.data.email);
        sessionStorage.setItem("pwd", payload.data.password);
        sessionStorage.setItem("rememberme", payload.data.rememberMe);
        localStorage.setItem("2faEnabled", true);
        if (data.is2faEnabledViaMobile)
        {
          localStorage.setItem("2famobileenabled", true);
          localStorage.setItem("2faEnabled", true);
        }
        yield put(push("/verify2fa"));
      }
      yield removeStellarSession();
      yield put(freighterLoginSuccess(data));
    }
  } catch (error)
  {
    console.log("ERROR", error);

    console.log("ERORO", error.response.data.message)
    if (error.response.data.message === "Please create your profile.")
    {

      yield put(push("/profileRegistrationFreighter"));
    }
    if (error && error.response && error.response.data)
    {
      if (error.response.data.message === "Unverified email, can not login")
      {
        yield put(push(`/verify-email?email=${error.response.data.email}`));
      }
      if (error.response.message === "Please create your profile.")
      {
        yield put(push(`/profileRegistrationFreighter`));
      }
      if (
        error.response.data.user_id &&
        error.response.data.message === "Unverified device."
      )
      {
        sessionStorage.setItem("email", error.response.data.email);
        // sessionStorage.setItem("rememberme", payload.data.rememberMe);
        // localStorage.setItem("2faEnabled", true);
        // if (data.is2faEnabledViaMobile) {
        //   localStorage.setItem("2famobileenabled", true);
        //   localStorage.setItem("2faEnabled", true);
        // }

        localStorage.setItem("user_id", error.response.data.user_id);
        localStorage.setItem("device_id", error.response.data.device_id);
        yield put(push(`/verify-device`));
      }
      // yield toast.error(<ErrorToast message={error.response.data.message} />, {
      //   toastId: 1,
      //   updateId: 1,
      // });

      if (error.response.data.message === "Unverified email, can not login")
      {
        yield put(push(`/verify-email?email=${error.response.data.email}`));
      }
      if (error.response.data.is2FA_enabled === true)
      {
        sessionStorage.setItem("email", error.response.data.email);
        // sessionStorage.setItem("rememberme", payload.data.rememberMe);
        localStorage.setItem("2faEnabled", true);
        if (error.response.data.is2faEnabledViaMobile)
        {
          localStorage.setItem("2famobileenabled", true);
          localStorage.setItem("2faEnabled", true);
        }
        yield put(push("/verify2fa"));
      }
    }
    yield put(loginFailure(error));
  }
}

export function* freighterLogin()
{
  yield takeLatest(userActionTypes.FREIGHTER_LOGIN_START, freighterLoginStart);
}

export function* freighterSignupStart({ payload })
{
  try
  {
    const response = yield axios({
      method: "post",
      url: userApis.FREIGHTER_SIGNUP,
      headers: {
        Authorization: localStorage.getItem("token"),
      },
      data: payload,
    });
    const { data } = response;
    if (data)
    {
      if (data && data.message)
      {
        yield toast.success(<SuccessToast message={data.message} />, {
          toastId: 1,
          updateId: 1,
        });
        if (data.message == "Account for given email already exists")
        {
          yield put(push("/login"));
        } else
        {
          yield put(push(`/verify-email?email=${payload.email}`));
        }
      }

      yield put(signupSuccess(data));
    }
  } catch (error)
  {
    if (error && error.response && error.response.data)
    {
      yield toast.error(<ErrorToast message={error.response.data.message} />, {
        toastId: 1,
        updateId: 1,
      });
    }
    yield put(signupFailure(error));
  }
}

export function* freighterSignup()
{
  yield takeLatest(userActionTypes.FREIGHTER_SIGNUP_START, freighterSignupStart);
}

export function* userSaga()
{
  yield all([
    call(login),
    call(logout),
    call(signup),
    call(verifyEmail),
    call(forgotPassword),
    call(verifyforgotPassword),
    call(resendOtp),
    call(userDetail),
    call(bookmark),

    call(changePassword),
    call(verifyReset2fa),
    call(reset2fa),
    call(anchorLogin),
    call(anchorSignup),
    call(lobstrLogin),
    call(lobstrSignup),

    call(freighterLogin),
    call(freighterSignup),
  ]);
}
