import "../../styles/style.scss";
import {
  Button,
  Image,
  Modal,
  ProgressBar,
  Form,
  FormGroup,
  Spinner,
} from "react-bootstrap";
import SettingComponent from "./account_setting_component";
import AccountInfoComponent from "../../components/account-info-component";
import React, { useEffect, useReducer, useState } from "react";

import Header from "../../components/Header/Header";
import KycView from "./kycview";
import { useFetch, useSubmit, useUpload } from "../../api/api_client";
import { userApis } from "../../redux/api";
import LoadingSpinner from "../../components/Loader/LoadingSpinner";
import PinInput from "react-pin-input";

import AccountIcon from "../../icons/account.svg";
import PinIcon from "../../icons/change_pin.svg";
import PassIcon from "../../icons/change_pass.svg";
import LikeIcon from "../../icons/laptop.svg";
import RateIcon from "../../icons/rate.svg";
import NFTs from "../../icons/nft.png";
import PrivacyIcon from "../../icons/privacy.svg";
import NotificationIcon from "../../icons/notification.svg";
import FeedbackIcon from "../../icons/feedback.svg";

import AvatarIcon from "../../icons/avatar.png";
import PaymentDialog from "./payment_dialog";
import { useHistory, useLocation, useParams } from "react-router-dom";
import MaleIcon from "../../icons/male_icon.jpeg";
import FemaleIcon from "../../icons/female_icon.jpeg";

import * as PropTypes from "prop-types";
import {
  isNotValid,
  isValid,
  isValidInstagramUrl,
  isValidTwitterUrl,
  notifyError,
  notifySuccess,
} from "../../utils/utils";
import {
  HeightBox,
  PasswordInput,
  SubmitButton,
  WidthBox,
} from "../../components/components";
import { PencilFill, PersonFill } from "react-bootstrap-icons";
import AddPaymentIcon from "../../images/add_payment_icon.svg";
import { LoadingView } from "../../components/loading_view";
import PhoneInput, { isPossiblePhoneNumber } from "react-phone-number-input";
import RateDialog from "./RateDialog";
import ChangeEmail from "./changeEmail";
import {
  logout,
  resendOtpStart,
  userDetailsStart,
} from "../../redux/user/Action";
import { useDispatch, useSelector } from "react-redux";
import GoogleCaptcha from "../../components/GoogleCaptcha/Captcha";
import { getRequiredProtectedData } from "../../redux/apicalltemplate";
import SwalSuccess from "../../components/ToastMessage/SwalSuccess";
import SwalError from "../../components/ToastMessage/SwalError";
import InfoToast from "../../components/ToastMessage/InfoToast";
import OtpInput from "../../components/InputOtp/InputOtp";
import AppServices from "../../services/AppService";
import SubscribeDialog from "./subsribe_model";
import { DeleteAccount } from "./DeleteAccount";

const appServices = new AppServices();
export default function ProfilePage({ ual }) {
  const history = useHistory();
  const [changeEmailmodal, setChangeEmailModal] = useState(false);
  const [rateDialog, showRateDialog] = useState(false);
  const [subsribeDialog, showSubsribeDialog] = useState(false);
  const userDetails = useSelector((state) => state.user.data);
  const { search } = useLocation();
  const recaptchaRef = React.useRef();
  const [resendLoading, setResendLoading] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  const [refreshUser, setRefreshUser] = useState(false)

  function stateReducer(state, action) {
    switch (action.type) {
      case "showProfileModal":
        return { ...state, profileModal: !state["profileModal"] };
      case "setProfileData":
        return { ...state, profileData: action["profileData"] };
      case "setProfileUpdating":
        return { ...state, profileUpdating: !state["profileUpdating"] };
      case "showPasswordModal":
        return { ...state, passwordModal: !state["passwordModal"] };
      case "passwordData":
        return { ...state, passwordData: action["data"] };
      case "setPasswordUpdating":
        return { ...state, passwordUpdating: !state["passwordUpdating"] };
      case "showPinModaltrue":
        return { ...state, pinModal: true };

      case "showPinModal":
        return { ...state, pinModal: !state["pinModal"] };
      case "pinData":
        return { ...state, pinData: action["data"] };
      case "setPinUpdating":
        return { ...state, pinUpdating: !state["pinUpdating"] };

      case "avatarChange":
        return { ...state, avatarData: action["data"] };
      case "setAvatarUpdating":
        return { ...state, avatarUpdating: !state["avatarUpdating"] };
      case "showPaymentModal":
        return { ...state, paymentModal: !state["paymentModal"] };
      default:
        return state;
    }
  }

  const [pageState, dispatchState] = useReducer(stateReducer, {
    profileModal: false,
    profileData: {},
    profileUpdating: false,
    passwordModal: false,
    passwordData: {},
    passwordUpdating: false,
    pinModal: false,
    pinData: {},
    pinUpdating: false,
    avatarData: {},
    paymentModal: false,
  });

  const [forceUpdatePin, setForceUpdatePin] = useState(false);

  useEffect(() => {
    if (userDetails && userDetails?.walletCreated) {
      const pinChanged = userDetails?.wallet_pin_changed;
      const walletCreated = userDetails?.walletCreated;

      if (walletCreated && pinChanged == false) {
        setForceUpdatePin(true);
        dispatchAction("showPinModaltrue");
      }
    }
  }, [userDetails]);

  const handleOTP = async (e) => {
    const data = {
      email: userDetails.email,
    };
    setResendLoading(true);
    const response = await getRequiredProtectedData(
      "POST",
      userApis.RESEND_OTP_2FA,
      data
    );

    if (response && response.data && response.data.success) {
      SwalSuccess(response.data.message);
      setResendLoading(false);
    }

    if (response && response.data && !response.data.success) {
      SwalError(response.data.message);
      setResendLoading(false);
    }
    if (response && response.response && response.response.data) {
      SwalError(response.response.data.message);
      setResendLoading(false);
    }
  };

  const dispatchAction = (type, data) => {
    if (type === "showPasswordModal" && isOauth) {
      SwalError(
        "You cannot change your password, you are logged in using social login."
      );
    } else {
      dispatchState({ type: type, data: data });
    }
  };

  let avatarUploadRef;
  const dispatch = useDispatch();
  const handleProfileInputChange = (e) => {
    let profileData = pageState["profileData"];
    //console.log(profileData);
    profileData[e.target.name] = e.target.value;
    dispatchState({ type: "setProfileData", profileData: profileData });
  };
  const updateProfile = async () => {
    const { fullName, phone, instagramUrl, twitterUrl, gender } =
      pageState["profileData"];

    if (isNotValid(fullName) || fullName.trim().length < 2) {
      InfoToast("Enter valid full name");
      return;
    }
    let userregex = /^[a-zA-Z ]*$/;
    if (!userregex.test(fullName.trim())) {
      InfoToast("Please Enter a Valid Full Name");
      return;
    }

    // if (phone == undefined || phone.length < 6) {
    //   InfoToast("Enter valid phone");
    //   return;
    // }
    // if (!isPossiblePhoneNumber(phone)) {
    //   InfoToast("Please Enter Valid Phone Number");
    //   return;
    // }

    if (instagramUrl && !isValidInstagramUrl(instagramUrl)) {
      notifyError("Enter valid Instagram url");
      return;
    }
    if (twitterUrl && !isValidTwitterUrl(twitterUrl)) {
      notifyError("Enter valid Twitter url");
      return;
    }
    if (isNotValid(gender)) {
      notifyError("Enter valid Gender");
      return;
    }

    dispatchState({ type: "setProfileUpdating" });
    // eslint-disable-next-line react-hooks/rules-of-hooks
    await useSubmit(userApis.UPDATE_PROFILE, pageState["profileData"], "PUT")
      .then((response) => {
        dispatchState({ type: "setProfileUpdating" });
        dispatchState({ type: "showProfileModal" });
        notifySuccess("Successfully updated");
        window.location.reload();
      })
      .catch((e) => {
        notifyError(e.message);
        // console.error(error);
        dispatchState({ type: "setProfileUpdating" });
      });
  };

  const handlePasswordInputChange = (e) => {
    e.preventDefault();
    let passwordData = pageState["passwordData"];
    passwordData[e.target.name] = e.target.value;
    dispatchAction("passwordData", passwordData);
  };
  const updatePassword = () => {
    const { oldPassword, newPassword, confirmPassword } =
      pageState["passwordData"];

    if (
      isNotValid(oldPassword) &&
      isNotValid(newPassword) &&
      isNotValid(confirmPassword)
    ) {
      notifyError("Your current , New & Confirmed password is required");
      return;
    }
    if (oldPassword == undefined || oldPassword == "") {
      notifyError("Current Password is required");
      return;
    }

    if (
      newPassword == undefined ||
      newPassword === "" ||
      confirmPassword == undefined ||
      confirmPassword === ""
    ) {
      notifyError("New & Confirmed password is required");
      return;
    }

    if (newPassword.length < 8) {
      InfoToast("Password should be minimum 8 character long");
      return;
    }

    if (newPassword !== confirmPassword) {
      InfoToast("Current password does not match!");
      return;
    }
    let passwordregex = /^(?=.*\d)(?=.*[!@#$%^&?*()])(?=.*[a-z])(?=.*[A-Z]).*$/;
    if (!newPassword.match(passwordregex)) {
      InfoToast(
        "Password should contain at least one lowercase letter, one uppercase letter, one numeric digit, and one special character. ex: Test@123"
      );
      return;
    }
    dispatchAction("setPasswordUpdating");
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useSubmit(userApis.CHANGE_PASSWORD, {
      oldPassword: oldPassword,
      newPassword: newPassword,
    })
      .then((response) => {
        dispatchAction("setPasswordUpdating");
        dispatchAction("showPasswordModal");
        notifySuccess("Successfully updated ");
        //console.log(response);
      })
      .catch((e) => {
        // console.error(e);
        notifyError(e.message);
        dispatchAction("setPasswordUpdating");
      });
  };

  const updatePin = () => {
    const { pin, cpin, password } = pageState["pinData"];

    if (isNotValid(pin) || pin.length !== 4) {
      notifyError("Pin must be 4 digit");
      return;
    }
    if (isNotValid(cpin) || cpin.length !== 4) {
      notifyError("Confirm Pin must be 4 digit");
      return;
    }
    if (pin !== cpin) {
      notifyError("Pin & Confirm Pin should match");
      return;
    }
    if (isNotValid(password)) {
      if (userDetails && !userDetails.isOauth) {
        notifyError("Enter a valid password");
      } else {
        notifyError("Please re enter a valid otp");
      }

      return;
    }
    if (userDetails && !userDetails.isOauth) {
      let passwordregex =
        /^(?=.*\d)(?=.*[!@#$%^&?*()])(?=.*[a-z])(?=.*[A-Z]).{8,}$/;
      if (!password.match(passwordregex)) {
        notifyError(
          "Password should be minimum 8 character long, contain at least one lowercase letter, one uppercase letter, one numeric digit, and one special character. ex: Test@1234"
        );
        return;
      }
    }
    if (userDetails && userDetails.isOauth) {
      if (!password || (password && password.length !== 6)) {
        notifyError("OTP should be 6 digits long.");
        return;
      }
    }

    const data = {
      pin,
      password,
    };
    if (userDetails && userDetails.isOauth) {
      data.otp = password;
      data.password = "1234565";
    }
    dispatchAction("setPinUpdating");

    // eslint-disable-next-line react-hooks/rules-of-hooks
    useSubmit(userApis.CHANGE_PIN, data, "PUT")
      .then((response) => {
        dispatchAction("setPinUpdating");
        dispatchAction("showPinModal");
        //console.log("successfully updated ");
        //console.log(response);
        notifySuccess("Pin Updated");
        localStorage.setItem("wallet_pin_changed", true);
        dispatch(logout({ ual: ual }));
      })
      .catch((e) => {
        notifyError(e.message);
        // console.error(error);
        dispatchAction("setPinUpdating");
      });
  };

  const updateAvatar = (file, avatarData) => {
    dispatchAction("avatarChange", { file: file, avatarData: avatarData });
    dispatchAction("setAvatarUpdating");

    let formData = new FormData();
    formData.append("image", file); //append the values with key, value pair
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useUpload(userApis.UPDATE_AVATAR, formData)
      .then((response) => {
        dispatchAction("setAvatarUpdating");
        const profilePicUrl = response["profilePicImageUrl"];
        const profile = data["data"];
        profile["profileImgUri"] = profilePicUrl;
        notifySuccess("Successfully updated");
        dispatch(userDetailsStart({}));
      })
      .catch((e) => {
        notifyError(e.message);
        dispatchAction("setAvatarUpdating");
      });
  };

  const { data, error, loading } = useFetch(userApis.GET_DETAILS, [refreshUser]);

  if (loading) return <LoadingView />;

  if (error) {
    return <h2>ERROR {"Opps! Something went wrong"}</h2>;
  }

  const {
    name,
    email,
    phone,
    profileImgUri,
    gender,
    instagramUrl,
    twitterUrl,
    aver,
    isOauth,
    promotionsSubscription,
  } = data["data"];

  const avatarUrl =
    pageState["avatarData"].avatarData !== undefined
      ? pageState["avatarData"].avatarData
      : profileImgUri !== undefined && profileImgUri !== ""
        ? profileImgUri
        : gender.toLowerCase() === "male"
          ? MaleIcon
          : FemaleIcon;
  return (
    <>
      <SubscribeDialog
        show={subsribeDialog}
        promotionsSubscription={promotionsSubscription}
        setShow={() => {
          showSubsribeDialog(!subsribeDialog);
        }}
        refreshUser={refreshUser}
        setRefreshUser={setRefreshUser}
      />
      <RateDialog
        show={rateDialog}
        setShow={() => {
          showRateDialog(!rateDialog);
        }}
      />

      <Modal
        show={pageState["profileModal"]}
        onHide={() => {
          dispatchState({ type: "showProfileModal" });
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>Update Profile</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <label className="input-label">Full name</label>
            <Form.Control
              defaultValue={name}
              className={"input-box-sm"}
              type="text"
              name="fullName"
              maxLength={30}
              placeholder="Full name"
              onChange={handleProfileInputChange}
            />
            <HeightBox height={"6px"} />
            <label className="input-label">Phone</label>
            <PhoneInput
              placeholder="Enter phone number"
              defaultCountry="SE"
              className="input-box-sm"
              international
              value={phone}
              required
              limitMaxLength
              name="phone"
              onChange={(number) => {
                let profileData = pageState["profileData"];
                profileData["phone"] = number;
                dispatchState({
                  type: "setProfileData",
                  profileData: profileData,
                });
              }}
            />
            <HeightBox height={"6px"} />
            <label className="input-label">Twitter Url</label>
            <Form.Control
              className={"input-box-sm"}
              defaultValue={twitterUrl}
              type="text"
              placeholder="Enter Twitter Url"
              name="twitterUrl"
              onChange={handleProfileInputChange}
            />
            <HeightBox height={"6px"} />
            <label className="input-label">Instagram Url</label>
            <Form.Control
              className={"input-box-sm"}
              defaultValue={instagramUrl}
              type="text"
              placeholder="Enter Instagram Url"
              name={"instagramUrl"}
              onChange={handleProfileInputChange}
            />

            <HeightBox height={"6px"} />
            <label className="input-label">Gender</label>
            <Form.Control
              className={"input-box-sm"}
              as="select"
              defaultValue={gender}
              onChange={(e) => {
                let profileData = pageState["profileData"];
                profileData["gender"] = e.target.value;
                dispatchState({
                  type: "setProfileData",
                  profileData: profileData,
                });
              }}
            >
              <option value={"male"}>Male</option>
              <option value={"female"}>Female</option>
              <option value={"notGiven"}>Prefer not to say</option>
            </Form.Control>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <SubmitButton
            onClick={updateProfile}
            title={"Update Profile"}
            loading={pageState["profileUpdating"]}
          />
        </Modal.Footer>
      </Modal>
      {pageState["paymentModal"] && (
        <PaymentDialog
          show={pageState["paymentModal"]}
          setShow={() => {
            dispatchAction("showPaymentModal");
          }}
        />
      )}

      <Modal
        show={pageState["pinModal"]}
        backdrop="static"
        onHide={() => {
          if (!forceUpdatePin) {
            dispatchAction("showPinModal");
          }
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>Change Pin</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form autoComplete="nope">
            <div>
              <label className="input-label">
                {userDetails && userDetails.isOauth ? "OTP" : "Password"}
              </label>
              {userDetails && userDetails.isOauth ? (
                <OtpInput
                  istrue={true}
                  handleOTP={handleOTP}
                  onChange={(e) => {
                    const data = pageState["pinData"];
                    data["password"] = e;
                    dispatchAction("pinData", data);
                  }}
                  required
                  isPassword={userDetails && userDetails.isOauth ? false : true}
                  name={"password"}
                  placeholder={
                    userDetails && userDetails.isOauth ? "OTP" : "Password"
                  }
                  resendLoading={resendLoading}
                  autocomplete="nope"
                />
              ) : (
                <PasswordInput
                  istrue={true}
                  onChange={(e) => {
                    const data = pageState["pinData"];
                    data["password"] = e.target.value;
                    dispatchAction("pinData", data);
                  }}
                  isPassword={userDetails && userDetails.isOauth ? false : true}
                  name={"password"}
                  placeholder={
                    userDetails && userDetails.isOauth ? "OTP" : "Password"
                  }
                  autocomplete="nope"
                />
              )}
            </div>
            <label className="input-label">Pin</label>
            <PinInput
              className="mx-auto"
              length={4}
              secret
              onChange={(value, index) => {
                const data = pageState["pinData"];
                data["pin"] = value;
                dispatchAction("pinData", data);
              }}
              type="numeric"
              inputMode="number"
              style={{ padding: "10px", marginLeft: "auto" }}
              inputStyle={{ borderColor: "#daca9e" }}
              inputFocusStyle={{ borderColor: "blue" }}
              onComplete={(value, index) => { }}
              required
            />
            <label className="input-label">Confirm Pin</label>
            <PinInput
              className="mx-auto"
              length={4}
              secret
              onChange={(value, index) => {
                const data = pageState["pinData"];
                data["cpin"] = value;
                dispatchAction("pinData", data);
              }}
              type="numeric"
              inputMode="number"
              style={{ padding: "10px", marginLeft: "auto" }}
              inputStyle={{ borderColor: "#daca9e" }}
              inputFocusStyle={{ borderColor: "blue" }}
              onComplete={(value, index) => { }}
              required
            />
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <div className="f-14 text-dark ">
            Note : You will be logged out after changing pin successfully.
          </div>
          {forceUpdatePin && (
            <SubmitButton
              title={"Logout"}
              onClick={() => {
                dispatch(logout({ ual: ual }));
              }}
              loading={false}
            />
          )}

          <SubmitButton
            title={"Change Pin"}
            onClick={updatePin}
            loading={pageState["pinUpdating"]}
          />
        </Modal.Footer>
      </Modal>

      <Modal
        show={pageState["passwordModal"]}
        onHide={() => {
          dispatchAction("showPasswordModal");
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>Change Password</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <PasswordInput
              onChange={handlePasswordInputChange}
              placeholder={"Current Password"}
              name={"oldPassword"}
              required
            />
            <PasswordInput
              onChange={handlePasswordInputChange}
              placeholder={"New Password"}
              name={"newPassword"}
              required
            />
            <PasswordInput
              onChange={handlePasswordInputChange}
              placeholder={"Confirm Password"}
              name={"confirmPassword"}
              required
            />
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <SubmitButton
            title={"Change Password"}
            onClick={updatePassword}
            loading={pageState["passwordUpdating"]}
          />
        </Modal.Footer>
      </Modal>

      {/* <Header/> */}

      <div className={"wrapper"}>
        <div className={"container"}>
          <div className={"d-flex flex-row align-items-center"}>
            <div className={"avatar_container"}>
              <input
                ref={(ref) => (avatarUploadRef = ref)}
                type="file"
                accept="image/jpeg,image/jpg, image/png"
                hidden={true}
                id={"input"}
                multiple="false"
                onChange={(event) => {
                  const file = event.target.files[0];

                  if (file && file.size > 524288) {
                    notifyError("Please upload a file smaller than 512 KB");
                    return;
                  }

                  const reader = new FileReader();
                  reader.readAsDataURL(file);
                  reader.onloadend = function (ev) {
                    updateAvatar(file, reader.result);
                  };
                }}
              />

              <Image
                rounded={true}
                src={avatarUrl}
                width={"160px"}
                height={"160px"}
                id={"avatar-img"}
              />
              <Spinner
                hidden={!pageState["avatarUpdating"]}
                className={"uploadSpinner"}
                animation="border"
              />
              <span
                className={"avatar_update_icon"}
                onClick={() => {
                  avatarUploadRef.click();
                }}
              >
                <PencilFill />
              </span>
            </div>
            <WidthBox width={"16px"} />
            <h2 id={"fullname-header"}>{name}</h2>
          </div>

          <div className={"section-divider"} />

          <div className={"row section-2"}>
            <div className={"col-md-4 col-xs-1 account-info-sec"}>
              <h3 className={"section-heading"}>Account Info</h3>
              <AccountInfoComponent title={name} subtitle={"Full Name "} />
              <AccountInfoComponent title={email} subtitle={"Email"} />
              <AccountInfoComponent
                title={phone}
                subtitle={"Your Phone Number "}
              />
              <AccountInfoComponent title={gender} subtitle={"Gender"} />
              {twitterUrl ? (
                <AccountInfoComponent
                  title={twitterUrl}
                  subtitle={"Twitter URL"}
                />
              ) : (
                <span />
              )}
              {instagramUrl ? (
                <AccountInfoComponent
                  title={instagramUrl}
                  subtitle={"Instagram URL"}
                />
              ) : (
                <span></span>
              )}
            </div>
            <div className={"col-md-4 col-xs-1 setting-sec"}>
              <h3 className={"section-heading"}>Setting</h3>

              <SettingComponent
                icon={AccountIcon}
                title={"Profile Information"}
                subtitle={"Name, Email, Security"}
                icon_color={"#384E66"}
                onClick={() => {
                  //console.log("phone number");
                  //console.log(phone);
                  const profileData = {
                    fullName: name,
                    instagramUrl: instagramUrl,
                    twitterUrl: twitterUrl,
                    phone: phone,
                    gender: gender,
                  };

                  dispatchState({
                    type: "setProfileData",
                    profileData: profileData,
                  });
                  //     dispatchAction("setProfileData",profileData)

                  dispatchAction("showProfileModal");
                  // dispatchState({type: "showProfileModal"})
                }}
              />

              {!appServices.isLogin() && (
                <SettingComponent
                  icon={PinIcon}
                  title={"Change PIN"}
                  subtitle={" Change your transaction PIN"}
                  icon_color={"#A8BBCF"}
                  onClick={() => {
                    dispatchAction("showPinModal");
                  }}
                />
              )}
              {/* <SettingComponent
                icon={PinIcon}
                title={"Change Email"}
                subtitle={" Change your current Email"}
                icon_color={"#FFDF92"}
                onClick={() => {
                  setChangeEmailModal(true);
                }}
              /> */}
              <SettingComponent
                icon={PassIcon}
                title={"Linked Devices"}
                onClick={() => {
                  history.push("/devices");
                }}
                subtitle={"View all linked devices"}
                icon_color={"#E3B46F"}
              />
              {!appServices.isLogin() && (
                <SettingComponent
                  icon={PassIcon}
                  title={"Change Password"}
                  onClick={() => {
                    dispatchAction("showPasswordModal");
                  }}
                  subtitle={"Change your current password "}
                  icon_color={"#E3B46F"}
                />
              )}
              {changeEmailmodal && (
                <ChangeEmail
                  show={changeEmailmodal}
                  setShow={setChangeEmailModal}
                />
              )}
              {(
                <SettingComponent
                  icon={LikeIcon}
                  title={'Bookmark Section'}
                  onClick={() => {
                   
                     history.push("/bookmark");
                  }}
                  subtitle={"You can see all your bookmark sales and fundings."}
                  icon_color={"pink"}
                />
              )}

              {(
                <SettingComponent
                  icon={PassIcon}
                  title={!promotionsSubscription ? 'Subscribe' : 'Unsubscribe'}
                  onClick={() => {
                    showSubsribeDialog(true);
                    // history.push("/devices");
                  }}
                  subtitle={!promotionsSubscription ? "Subscribe to get updates on mail" : "Unsubscribe to stop getting updates on mail"}
                  icon_color={"#E3B46F"}
                />
              )}



              <h3 className={"section-heading"}>General</h3>

              <SettingComponent
                icon={RateIcon}
                onClick={() => {
                  showRateDialog(true);
                }}
                title={"Rate our App"}
                subtitle={"Rate & Review us "}
                icon_color={"#F6BB86"}
              />
              {/* <SettingComponent
                icon={FeedbackIcon}
                title={"Send Feedback"}
                subtitle={" Share your thought"}
                icon_color={"#FFDF92"}
              /> */}
              <SettingComponent
                icon={PrivacyIcon}
                title={"Privacy Policy"}
                subtitle={"Privacy Policy"}
                icon_color={"#CA4330"}
                onClick={() => {
                  history.push("/privacy");
                }}
              />
              <SettingComponent
                icon={PassIcon}
                title={"About Us"}
                onClick={() => {
                  history.push("/aboutus");
                }}
                subtitle={"About Us"}
                icon_color={"#E3B46F"}
              />
              <SettingComponent
                icon={PinIcon}
                title={"Terms and Conditions"}
                subtitle={"Terms and Conditions"}
                icon_color={"#FFDF92"}
                onClick={() => {
                  history.push("/terms");
                }}
              />

              <SettingComponent
                icon={AccountIcon}
                title={"FAQ"}
                subtitle={"Frequently Asked Question"}
                icon_color={"#FFA07A"}
                onClick={() => {
                  history.push("/faq");
                }}
              />
              <SettingComponent
                icon={AccountIcon}
                title={"Delete Account"}
                subtitle={"Delete your account"}
                icon_color={"#8b0000"}
                onClick={() => {
                  // history.push("/faq");
                  setShowDelete(true)
                }}
              />
            </div>

            <div className={"col-md-4 col-xs-1 kyc-sec"}>
              <h3 className={"section-heading"}>KYC</h3>
              {userDetails && userDetails.walletCreated == false ? (
                <div>
                  <div>
                    ** Please create your wallet first before proceeding to KYC
                  </div>
                  <button
                    className="music-action-button box-shadow mt-3"
                    onClick={() => {
                      history.push("/wallet");
                    }}
                  >
                    Wallet
                  </button>
                </div>
              ) : (
                <KycView aver={aver} userDetails={userDetails} />
              )}
            </div>
          </div>
          <DeleteAccount show={showDelete} setShow={setShowDelete} ual={ual} />
          <div className={"section-divider"} />

          <h3 className={"section-heading"}>Payment Setting</h3>

          <div
            onClick={() => {
              dispatchAction("showPaymentModal");
            }}
            className={
              "add-payment-button d-flex flex-row justify-content-center align-items-center"
            }
          >
            <img width={"20px"} height={"20px"} src={AddPaymentIcon} />
            <WidthBox width={"12px"} />
            <span className={"add-payment-button-text"}>
              Add Payment methods
            </span>
          </div>
        </div>
      </div>
    </>
  );
}
