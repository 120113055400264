import React, { useEffect, useState } from "react";
import Zeptagram from "../../images/zeptagram.svg";
import Band from "../../images/band.svg";
import Girl from "../../images/lady.svg";
import Neon from "../../images/neonbrand.svg";
import { useHistory, useParams } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { resendOtpStart, verifyEmailStart } from "../../redux/user/Action";
import GoogleCaptcha from "../../components/GoogleCaptcha/Captcha";
import { notifyError } from "../../utils/utils";

const VerifyEmail = () => {
  const [email, setEmail] = useState("");
  const [otp, setOtp] = useState("");
  const history = useHistory();
  const recaptchaRef = React.useRef();

  const dispatch = useDispatch();
  const loading = useSelector((state) => state.user.verifyemailloading);
  const handleSubmit = async (e) => {
    e.preventDefault();
    const captchatoken = await recaptchaRef.current.executeAsync();
    if (!captchatoken) {
      return;
    }
    if (!email) {
      notifyError("Please Enter Valid Email");
      return;
    }
    if (otp.length !== 6) {
      notifyError("Please Enter Valid OTP");
      return;
    }
    const data = {
      email: email,
      otp: otp,
      captcha: captchatoken,
    };
    dispatch(verifyEmailStart(data));
  };

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const email = params.get("email");
    setEmail(email);
  }, []);

  const handleOtp = async () => {
    const captchatoken = await recaptchaRef.current.executeAsync();
    if (!captchatoken) {
      return;
    }
    const mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    if (!email || !mailformat.test(email)) {
      notifyError("Please Enter Valid Email");
      return;
    }

    const data = {
      email: email,
      captcha: captchatoken,
    };
    dispatch(resendOtpStart(data));
  };

  return (
    <div className="row m-auto h-100 w-100">
      <div className="col-md-6  ">
        <div onClick={() => history.push("/login")}>
          <img src={Zeptagram} alt="" />
        </div>
        <div className="align-center h-84">
          <div className="d-flex flex-column justify-content-center">
            <h4 className="text-dark">Verify your email</h4>
            <div className="text-grey w-500">
              We sent you a verification email just to be sure you are human.
              Once you verify your account you will be able to use all the
              Zeptagram online platform.
            </div>
            <form className="d-flex flex-column" onSubmit={handleSubmit}>
              <input
                type="email"
                className="input-box my-3 align-self-start"
                onChange={(e) => setEmail(e.target.value)}
                placeholder="Email"
                required
                value={email}
              />
              <input
                type="number"
                className="input-box my-3 align-self-start"
                onChange={(e) => setOtp(e.target.value)}
                placeholder="OTP"
                required
                value={otp}
              />
              <div className="d-flex align-items-center">
                <button
                  className="button-medium border-shadow m-2 medium-btn"
                  type="submit"
                >
                  {!loading ? (
                    "Verify"
                  ) : (
                    <>
                      <span
                        className="spinner-border spinner-border-smd"
                        role="status"
                        aria-hidden="true"
                      ></span>
                    </>
                  )}
                </button>
                <button
                  className="button-medium btn-grey border-shadow m-2"
                  type="button"
                  onClick={() => history.push("/login")}
                >
                  Cancel
                </button>
              </div>
            </form>

            <div className="text-grey mt-3 w-500">
              Did not get an email?{" "}
              <span
                className="text-dark cur-pointer"
                onClick={() => handleOtp()}
              >
                Click here
              </span>{" "}
              and we will resend it to you!
            </div>
          </div>
        </div>
        <GoogleCaptcha recaptchaRef={recaptchaRef} />
      </div>
      <div className="col-md-6 image-container">
        <span className="verify-image verify-image-1">
          <img src={Neon} alt="" />
        </span>
        <span className="verify-image verify-image-2">
          <img src={Band} alt="" />
        </span>
        <div className="verify-image verify-image-3">
          <img height={380} className="girl-img" src={Girl} alt="" />
        </div>
      </div>
    </div>
  );
};

export default VerifyEmail;
