import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { investCrowdsaleConfirmStart } from "../../redux/crowdsale/Action";
import InfoToast from "../ToastMessage/InfoToast";
import SwalError from "../ToastMessage/SwalError";
import SwalSuccess from "../ToastMessage/SwalSuccess";
import { useParams, useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { ErrorToast, SuccessToast } from "../ToastMessage/SuccessToast";
import { crowdsaleApis } from "../../redux/api";
import { getAxiosData } from "../../redux/apicalltemplate";
import BlockchainService from "../../services/BlockchainService";
import { toast } from "react-toastify";
import FiatPaymentLoaderModal from "../../containers/AnchorWallet/FiatPaymentLoaderModal";
import LoadingOverlay from "react-loading-overlay";
const blockchainService = new BlockchainService();
const EscterPayScreen = ({ cartdata, ual }) => {
  // Handling the pay screen.

  const [cardId, setCartId] = useState("");
  const [investCrowdsaleId, setInvestCrowdsaleId] = useState("");
  const [investloading, setinvestloading] = useState(false);
  const { id, crowdsaleid, amount } = useParams();
  const history = useHistory();

  const dispatch = useDispatch();


  useEffect(() => {
    const data = {
      cartId: id,
    };
    setCartId(id);
    if (!cartdata) {
      history.push("/home");
      return;
    } else {
      const { data, type } = cartdata;
      setInvestCrowdsaleId(data.crowdsaleId);
    }

    const toggleEcsterPayScreen = (data) => {
      /*global EcsterPay*/
      EcsterPay.start({
        cartKey: data.cartId,
        shopTermsUrl: "https://zepta-user.quillhash.com/privacy-policy",
        showCart: true,
        showPaymentResult: true,
        showDelivery: true,
        onCheckoutStartInit: (data) => {
          InfoToast("Initiating payment gateway. Please wait");
        },
        onCheckoutStartSuccess: (data) => {
          //    InfoToast(
          //        "Closer mate! Please enter required details"
          //    );
        },
        onCheckoutStartFailure: (data) => {
          SwalError(
            "Unable to reach payment gateway. Please check your internet connection."
          );
          history.push("/home");
          console.log(data,"data==============>")
        },
        onPaymentSuccess: (data) => {
          //console.log(data, "-----", cardId, amount, investCrowdsaleId);
          SwalSuccess("Payment successfull. Updating the wallet ...");
          if (ual.activeUser) {
            AnchorPayment({
              cartId: id,
              crowdsaleId: crowdsaleid,
              actualAmount: amount,
              status: "success",
            });
            return
          }
          dispatch(
            investCrowdsaleConfirmStart({
              cartId: id,
              crowdsaleId: crowdsaleid,
              actualAmount: amount,
              status: "success",
            })
          );

          //   setTimeout(() => {
          //     history.push("/wallet");
          //   }, 400);
        },
        onPaymentFailure: (data) => {
          SwalError("Payment failed. Redirecting ...");
          dispatch(
            investCrowdsaleConfirmStart({
              cartId: id,
              crowdsaleId: crowdsaleid,
              actualAmount: amount,
              status: "failed",
            })
          );
          history.push("/home");
        },
        onPaymentDenied: (data) => {
          SwalError("Payment denied. Redirecting ...");
          dispatch(
            investCrowdsaleConfirmStart({
              cartId: id,
              crowdsaleId: crowdsaleid,
              actualAmount: amount,
              status: "denied",
            })
          );
          history.push("/home");
        },
      });
    };
    toggleEcsterPayScreen(data);
  }, []);
  const AnchorPayment = async (data) => {
    setinvestloading(true)
    try {
      const tnx = await getAxiosData(
        "POST",
        crowdsaleApis.INVEST_CONFIRM_ANCHOR,
        { ...data },
        {}
      );
      if (tnx.data && tnx.data.success) {
        const resullt = await blockchainService.pushTransaction(
          tnx.data.tnx,
          ual,
          "invest",
          tnx.data.optionalData
        );
        if (resullt.success) {
          setinvestloading(false);
          toast.success(
            <SuccessToast
              message={
                tnx.data.optionalData.amount.toFixed(2) + " ZPTC invested in crowdsale"
              }
            />,
            {
              toastId: 1,
              updateId: 1,
            }
          );
          history.push("/portfolio")
          // handleClose();
        } else {
          setinvestloading(false);
          toast.error(<ErrorToast message={resullt.message} />, {
            toastId: 1,
            updateId: 1,
          });
          history.push("/home");
        }
      } else if (tnx.data && !tnx.data.success) {
        toast.error(<ErrorToast message={data.message} />, {
          toastId: 1,
          updateId: 1,
        });
        setinvestloading(false);
        history.push("/home");
      } else {
        toast.error(<ErrorToast message={data.message} />, {
          toastId: 1,
          updateId: 1,
        });
        setinvestloading(false);
        history.push("/home");
      }
    } catch (error) {
      setinvestloading(false);
      if (error && error.response && error.response.data) {
        toast.error(<ErrorToast message={error.response.data.message} />, {
          toastId: 1,
          updateId: 1,
        });
        history.push("/home");
      } else {
        toast.error(
          <ErrorToast message={"Please try again after sometime..."} />,
          {
            toastId: 1,
            updateId: 1,
          }
        );
        history.push("/home");
      }
    }
  };

  return (<>
  <LoadingOverlay
      className="row m-auto h-100 w-100"
      active={investloading}
      spinner
      text="Please Wait  ...."
    >
    <div className="wrapper container h-100">
      <div id="ecster-pay-ctr"></div>
    </div>
   {/* {investloading&& <FiatPaymentLoaderModal showReceiveModal={investloading} setReceiveModal={setinvestloading} />} */}
   </LoadingOverlay>
    </>
  );
};

export default EscterPayScreen;
