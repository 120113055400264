/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { ListView } from "./List";
import { Accordion, Card, Button, Spinner } from "react-bootstrap";
import { tradeApis } from "../../redux/api";
import { getRequiredProtectedData } from "../../redux/apicalltemplate";
import { getZeptaPrice } from "./data";
import moment from "moment";

export const CardItem = ({
  el,
  i,
  getTotalRightsParticular,
  handleCrowdsale,
  liveprice,
}) => {
  const [refresh, setRefresh] = useState(false);
  const [softCapCheck, SetCheckSoftCap] = useState(0);

  const [totalCurrentUsd, setTotalCurrentUSD] = useState(0);
  const [totalCurrentZptc, setTotalCurrentZPTC] = useState(0);

  const [totalValSpentZPTC, setTotalspentZPTC] = useState(0);
  const [totalValSpentUSD, setTotalspentUSD] = useState(0);

  useEffect(async () => {
    let raisedFundInUSD = 0;
    if (el.deployedIn === "USD") {
      raisedFundInUSD = el.totalSoldRights;
    } else {
      const { data } = liveprice;
      raisedFundInUSD = Number(
        (el.zeptaCoinsRaised * data.zeptaPrice).toFixed(2)
      );
    }
    const amt_usd = Number(
      (Number(el.softCap * el.targetAmount) / 100).toFixed(2)
    );
    const result = raisedFundInUSD >= amt_usd;
    SetCheckSoftCap(result);

    const { txn_history } = el;
    let amtUSD = 0;
    let amtZPTC = 0;
    if (el.deployedIn === "USD") {
      amtUSD = Number((el.rights * Number(el.per_percent_amt)).toFixed(2));
    }

    txn_history.forEach(async (txn) => {
      amtZPTC += Number(txn.txnData.amount);
      if (el.deployedIn === "ZPTC") {
        let time = txn.txnData && moment(txn.blockTime).format("DD-MM-YYYY");
        const priceNew = await getZeptaPrice(time);
        amtUSD += await Number((txn.txnData.amount * priceNew).toFixed(2));
      }
      setTotalspentZPTC(Number(amtZPTC.toFixed(4)));
      setTotalspentUSD(Number(amtUSD.toFixed(2)));
    });
  }, [el]);

  useEffect(async () => {
    if (el) {
      // setLoadingCrowdsale(true);
      const params = {
        timeLimit: "day",
      };
      // setShowCrowdsale(true);
      const responselist = await getRequiredProtectedData(
        "GET",
        `${tradeApis.GET_TRADING_GRAPH}/${el.tokenName}`,
        {},
        params
      );
      if (
        responselist &&
        responselist.data &&
        responselist.data.data &&
        responselist.data.data.tradingData &&
        responselist.data.data.tradingData.initialTradesData
      ) {
        // el
        let price =
          responselist.data.data.tradingData.initialTradesData.latestPrice;
        let balChan =
          ((Math.round(totalValSpentZPTC * 100 * 10000) / 100) * price) / 10000;

        //console.log('balChan',balChan,'price',price)
        setTotalCurrentUSD(balChan ? balChan.toFixed(2) : 0);
        // let time = moment().format("DD-MM-YYYY");
        // const priceNew = await getZeptaPrice(time);
        // let zptcNew = (balChan / priceNew).toFixed(2);
        //setTotalCurrentZPTC(totalValSpentZPTC);
      } else {
        // setShowCrowdsale(false);
      }
      // setLoadingCrowdsale(false);
    }
  }, [el]);

  return (
    <Card key={i} className="m-2 box-shadow  border-md ">
      <Card.Header>
        <div className="row ">
          <div className="col-md-2 align-center ">
            <img
              className="box-shadow  border-md"
              width={100}
              height={100}
              src={el.bitMapImage}
              alt="image"
            />
          </div>
          <div className="col-md-4 align-center">
            <div>
              <div className="text-dark text-center ">{el.longName}</div>
              <div className="text-center">
                MTOs:
                <span className="text-dark mx-1 ">{el.mtoToken}</span>
              </div>
              <div className=" text-center">
                Token Name:
                <span className="text-dark mx-1">{el.tokenName}</span>
              </div>
              <div className=" text-center">
                Overall Ownership :{" "}
                <span className="text-dark">
                  {el.rights.toFixed(2)}%{" "}
                  {softCapCheck ? (
                    <span></span>
                  ) : (
                    <span>(Allocation Pending)</span>
                  )}{" "}
                </span>
              </div>
            </div>
          </div>
          <div className="col-md-3 align-center flex-column">
            {/* <div className="d-flex"> */}

            <div>Total Spent </div>
            <div className="text-dark">
              {totalValSpentZPTC} ZPTC | $ {totalValSpentUSD} {}
            </div>
            <div>Current Value </div>
            <div className="text-dark">
              {totalCurrentUsd ? (
                <>
                  {totalValSpentZPTC} ZPTC | $ {totalCurrentUsd}
                </>
              ) : (
                <>
                  {totalValSpentZPTC} ZPTC | $ {totalValSpentUSD}{" "}
                </>
              )}
            </div>
          </div>
          <div className="col-md-3 align-center">
            <button
              className="more-button button-medium border-shadow cur-pointer"
              onClick={() => handleCrowdsale(el.tokenName)}
            >
              View Details
            </button>
            <Accordion.Toggle
              as={Button}
              className=""
              variant="light"
              eventKey={`${i}`}
            >
              {" "}
              <i
                className="fas  fa-caret-down text-dark"
                title="View More"
                style={{ fontSize: "22px" }}
              ></i>
            </Accordion.Toggle>
          </div>
        </div>
      </Card.Header>
      <Accordion.Collapse eventKey={`${i}`}>
        <Card.Body>
          <div className={"table-responsive portfolio-table box-shadow w-100"}>
            <table className={"table table-bordered table-hover"}>
              <thead>
                <tr>
                  <th scope="col">
                    <span className="text-dark">Quantity</span>
                  </th>
                  <th scope="col">
                    <span className="text-dark">Invested At</span>
                  </th>
                  <th scope="col">
                    <span className="text-dark">From</span>
                  </th>
                  <th scope="col">
                    <span className="text-dark">To</span>
                  </th>
                  {/* <th scope="col">
                    <span className="text-dark">Ownership</span>
                  </th> */}
                  <th scope="col">
                    <span className="text-dark">Txn ID</span>
                  </th>
                </tr>
              </thead>
              <tbody>
                {el.txn_history && el.txn_history.length
                  ? el.txn_history.map((txn) => {
                      return (
                        <ListView
                          txn={txn}
                          id={el.tokenName}
                          setRefresh={setRefresh}
                          refresh={refresh}
                          per_percent_amt={el.per_percent_amt}
                          softCapReached={softCapCheck}
                        />
                      );
                    })
                  : null}
              </tbody>
            </table>
          </div>
        </Card.Body>
      </Accordion.Collapse>
    </Card>
  );
};
