import React,{useState} from 'react'
import {Modal,Form, Spinner} from 'react-bootstrap'
import { getRequiredProtectedData } from '../../redux/apicalltemplate';
import moment from 'moment';
import {  userApis } from '../../redux/api';
import { useEffect } from 'react';

const Notification=({show,setShow})=>{
    const handleClose = () => setShow(false);
    const [notification,setNotifications]=useState(null)
    const [page,setPage]=useState(1);
    const [loading,setLoading]=useState(false)


    useEffect(async ()=>{
        const params={
            page:page,
            order:-1,
            sortBy:'created_at',
            limit:100
        }
        setLoading(true)
        const responselist=await getRequiredProtectedData('GET',userApis.NOTIFICATION,{},params)
        if(responselist && responselist.data && responselist.data.data){
            setNotifications(responselist.data.data);
        }else{
            setNotifications(null)
        }
        setLoading(false)
    },[page])
    
    //handle invest----------------------

  const handleOrder =async (event) => {
//     event.preventDefault();

  }


return(
    <Modal show={show} onHide={handleClose}  size="lg">
        <Modal.Header closeButton>
            <Modal.Title className='text-center'> Notifications</Modal.Title>
        </Modal.Header>
        <Form onSubmit={handleOrder}>

        <Modal.Body className='p-3'>
               
        <div className="d-flex flex-column w-100 notification-table" >
        {
            loading ?
            <div className='align-center h-100'>
                <Spinner hidden={false}  animation="border" />
            </div>:
              notification && notification.length ?  notification.map((el,i)=>
                <div key={el._id} className='box-shadow m-2 p-2'>
                    <div className='d-flex justify-content-between align-items-center'>
                    <div style={{marginRight:'10px'}}><span className='text-dark'>Subject :</span> {el.subject}</div>
                    <div><span className='text-dark'>Created At :</span> {moment(el.created_at).format('YYYY-MM-DD hh:mm a')}</div>
                     </div>
              
               <div><span className='text-dark'>Category :</span> {el.category}</div>
               <div><span className='text-dark'>Content :</span> {el.content}</div>
                </div>
                ):
                <div className='align-center text-dark h-100'>
                No Notifications
              </div>
        }
           
        </div>
                
           
        </Modal.Body>
        <Modal.Footer className='p-3'>
            {/* <button className='button-medium border-shadow   medium-btn' type='submit' title={"Update Profile"} >Submit</button> */}
            {/* <div className='d-flex justify-content-between align-items-center w-100'>
              <button className='btn button-medium width-100' disabled={page==1} onClick={()=>setPage(page-1)}>Previous</button>
              <button className='btn button-medium width-100' disabled={false} onClick={()=>setPage(page+1)} >Next</button>
          </div> */}
        </Modal.Footer>

        </Form>
    </Modal>
)


}

export default Notification
