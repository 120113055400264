import React from "react";
import { useState } from "react";
import { Modal, Form } from "react-bootstrap";
import { SubmitButton } from "../../components/components";
import PinInput from "react-pin-input";
import { isNotValid, notifyError } from "../../utils/utils";
import { fetchUrl, useFetch, useSubmit } from "../../api/api_client";
import { userApis } from "../../redux/api";
import GoogleCaptcha from "../../components/GoogleCaptcha/Captcha";

const CreateWalletModal = ({ show, setShow, onReload }) => {
  //console.log("showModal "+show)
  const [loading, setLoading] = useState(false);
  const [pin, setPin] = useState("");
  const [confirmPin, setConfirmPin] = useState("");
  const recaptchaRef = React.useRef();
  const handleSubmit = async () => {
    if (pin.length < 4) {
      notifyError("Enter valid pin");
      return;
    }

    if (confirmPin != pin) {
      notifyError("Confirm pin are not same as pin");
      return;
    }
    const captchatoken = await recaptchaRef.current.executeAsync();
    setLoading(true);

    //const mnemonicResponse = await fetchUrl(userApis.RANDOM_MNEMONIC);

    //var mnemonic = mnemonicResponse["data"].data;
    //console.log(mnemonicResponse['data'].data);

    try {
      // eslint-disable-next-line react-hooks/rules-of-hooks
      await useSubmit(userApis.CREATE_WALLET, {
        pin: pin,
        captcha: captchatoken,
      });
      onReload();
    } catch (e) {
      notifyError(e.message);
      setLoading(false);
    }
  };

  //show={pageState["pinModal"]}
  return (
    <Modal
      show={show}
      onHide={() => {
        setShow();
        //dispatchState({type: "showPinModal"})
      }}
    >
      <Modal.Header closeButton>
        <Modal.Title>Create Wallet</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Label>Pin</Form.Label>
          <PinInput
            className="mx-auto"
            length={4}
            // initialValue={pin}
            secret
            onChange={(value, index) => setPin(value)}
            type="numeric"
            inputMode="number"
            style={{ padding: "10px", marginLeft: "auto" }}
            inputStyle={{ borderColor: "#daca9e" }}
            inputFocusStyle={{ borderColor: "blue" }}
            onComplete={(value, index) => {}}
            required
          />
          <Form.Label>Confirm Pin</Form.Label>
          <PinInput
            className="mx-auto"
            length={4}
            // initialValue={pin}
            secret
            onChange={(value, index) => setConfirmPin(value)}
            type="numeric"
            inputMode="number"
            style={{ padding: "10px", marginLeft: "auto" }}
            inputStyle={{ borderColor: "#daca9e" }}
            inputFocusStyle={{ borderColor: "blue" }}
            onComplete={(value, index) => {}}
            required
          />
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <SubmitButton
          loading={loading}
          onClick={handleSubmit}
          title={"Confirm"}
        />
      </Modal.Footer>
      <GoogleCaptcha recaptchaRef={recaptchaRef} />
    </Modal>
  );
};

export default CreateWalletModal;
