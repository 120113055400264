import React from "react";
import { Spinner } from "react-bootstrap";

const WithSpinner = (WrappedComponent) => {
  const Spinn = ({ isloading, ...otherprops }) => {
    return isloading ? (
      <div className="SpinnerOverlay">
        <div className="loader">
          <Spinner animation="border" variant="primary" />
        </div>
      </div>
    ) : (
      <WrappedComponent {...otherprops} />
    );
  };
  return Spinn;
};

export default WithSpinner;
