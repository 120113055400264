import React from "react";
import Thankyou from "../../images/thankyou.svg";
const ThankyouAver = () => {
  return (
    <div className="wrapper h-100">
      <div className="container h-100">
        <div
          className="align-center flex-column h-100"
          style={{ minHeight: "600px" }}
        >
          <img src={Thankyou} className="wallet-svg" />
          <h2 className="text-dark mt-3 text-center">Thank you</h2>
          <h5 className="text-dark text-center">
            For using AVER for investor verification on Zeptagram
          </h5>
        </div>
      </div>
    </div>
  );
};

export default ThankyouAver;
