import { WidthBox } from "../../components/components";
import "../../styles/style.scss";
import InfoBox from "../../components/InfoMessage/InfoHover";

export default function SwapWalletButton({
  icon,
  title,
  onClick,
  msg = "",
  disable,
  trigger
}) {
  return (
    <>
      <div
        className={
          "wallet-button d-flex flex-row justify-content-center align-items-center"
        }
         onClick={disable ? null : onClick}
      >
        <img width={"20px"} height={"20px"} src={icon} />
        <WidthBox width={"12px"} />

        <span className={"add-payment-button-text"}>{title}</span>
            <InfoBox className="ml-2" msg={msg} trigger={trigger} />
      </div>
    </>
  );
}
