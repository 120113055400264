import React, { useEffect, useState } from "react";
import { Modal, Form, Spinner } from "react-bootstrap";
import moment from "moment";
import ShareTray from "../../components/ShareTray/ShareTray";
import { getRequiredProtectedData } from "../../redux/apicalltemplate";
import { crowdsaleApis } from "../../redux/api";
import InfoToast from "../../components/ToastMessage/InfoToast";
import SwalError from "../../components/ToastMessage/SwalError";
import SwalSuccess from "../../components/ToastMessage/SwalSuccess";
import ReactPlayer from "react-player/lazy";
import NodataFound from "../../icons/cancel.svg";

const CrowdsalePermissions = ({
  show,
  setShow,
  id,
  walletAvailable,
  toggleUploadModal,
  toggleExtendCrowdsale,
  toggleDeployCrowdsale,
  toggleEditCrowdsale,
  setModalData,
  handleReloadCrowdsale,
}) => {
  const handleClose = () => setShow(false);
  const [data, setData] = useState("");
  const [liveprice, setLivePrice] = useState(null);
  const [loading, setLoading] = useState(true);
  const [isPlayerVisible, setIsPlayerVisible] = useState(true);
  const [isSupportVisible, setIsSupportVisible] = useState(false);
  const [reload, setReload] = useState(false);
  //loaders---------------
  const [verifyloading, setVerifyLoading] = useState(false);
  const [stopcrowdsaleloading, setStopCrowdsaleLoading] = useState(false);

  useEffect(async () => {
    setLoading(true);
    const response = await getRequiredProtectedData(
      "GET",
      `${crowdsaleApis.GET_CROWDSALE_BY_ID}/${id}`,
      {},
      {}
    );
    if (response && response.data) {
      if (response.data.price) {
        setLivePrice(response.data.price);
      }
      if (response.data.data) {
        setData(response.data.data);
        setModalData(response.data.data);
      }
    }
    setLoading(false);
  }, [id, reload]);

  if (loading) {
    return (
      <Spinner hidden={false} className="position-center" animation="border" />
    );
  }

  const renderCoOwnersInAlbum = () => {
    const { coOwnersInAlbum } = data && data.musicId;

    return coOwnersInAlbum.map((coOwner, i) => {
      return (
        <>
          <li className="d-flex">
            <span className="text-dark">Owner: </span>
            <div className="wrap-word " title={coOwner.coOwnerName}>
              {" "}
              {coOwner.coOwnerName}{" "}
            </div>
          </li>
          <li className="d-flex">
            <span className="text-dark">{`${coOwner.coOwnerName} Ownership :`}</span>{" "}
            {`${coOwner.coOwnerPercentageRights}%`}{" "}
          </li>
        </>
      );
    });
  };

  // const renderContributors = () => {
  //   const { contributors } = data && data.musicId;

  //   return contributors.map((contributor, i) => {
  //     return (
  //       <li>
  //         <span className="text-dark">{contributor.contributorType} :</span>{" "}
  //         {contributor.contributorName}{" "}
  //       </li>
  //     );
  //   });
  // };

  //stop crowdsale------------------------------------------
  const handleStopCrowdsale = async () => {
    setStopCrowdsaleLoading(true);
    const response = await getRequiredProtectedData(
      "PATCH",
      `${crowdsaleApis.STOP_CROWDSALE}/${id}`,
      {},
      {}
    );
    if (response && response.data && response.data.success) {
      SwalSuccess(response.data.message);
      setTimeout(() => {
        setShow(false);
        handleReloadCrowdsale();
      }, 300);
    }
    if (response && response.data && !response.data.success) {
      SwalError(response.data.message);
    }
    if (response && response.response && response.response.data) {
      SwalError(response.response.data.message);
    }
    setStopCrowdsaleLoading(false);
    //calling api again to fetch data----------------
    const responseid = await getRequiredProtectedData(
      "GET",
      `${crowdsaleApis.GET_CROWDSALE_BY_ID}/${id}`,
      {},
      {}
    );
    if (responseid && responseid.data) {
      if (responseid.data.price) {
        setLivePrice(responseid.data.price);
      }
      if (responseid.data.data) {
        setData(responseid.data.data);
      }
    }
  };

  //--------------------------------------------------------------------

  //----VERIFY CROWDSALE-----------
  const handleVerifyCrowdsale = async (e) => {
    e.preventDefault();
    setVerifyLoading(true);
    const response = await getRequiredProtectedData(
      "PUT",
      `${crowdsaleApis.VERIFY_CROWDSALE}/${id}`,
      {},
      {}
    );
    if (response && response.data && response?.data?.success) {
      SwalSuccess(response?.data?.message);
      setTimeout(() => {
        setShow(false);
        handleReloadCrowdsale();
      }, 300);
    }
    if (response && response?.data && !response?.data?.success) {
      SwalError(response?.data?.message);
    }
    if (response && response?.response && response?.response.data) {
      SwalError(response?.response?.data?.message);
    }
    setVerifyLoading(false);
  };

  const DollarToZPTC = (price, rate) => {
    const priceInCents = price * 100;
    const ZPTCPerCent = 1 / priceInCents;
    const rateInCent = rate * 100;
    return (ZPTCPerCent * rateInCent).toFixed(2);
  };
  const handleError = (e) => {
    setIsPlayerVisible(false);
    setIsSupportVisible(true);
  };
  const Capitalize = (str) => {
    return str.charAt(0).toUpperCase() + str.slice(1);
  };
  return (
    <Modal show={show} onHide={handleClose} size="lg">
      <Modal.Header closeButton>
        <Modal.Title>
          {" "}
          {data?.musicId && data?.musicId?.musicType === "new"
            ? "Crowdfund"
            : "Crowdsale"}{" "}
          Details
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="p-5">
        <div style={{ fontWeight: "bold" }}>
          <div className="align-center m-2 ">
            <img
              src={data && data?.musicId?.bitMapImage}
              className="img-mid box-shadow"
              alt="Song Image"
            />
          </div>
          {isPlayerVisible ? (
            data?.musicId &&
            data?.musicId?.streamingAppLink && (
              <div className="align-center">
                <ReactPlayer
                  url={data?.musicId?.streamingAppLink}
                  onError={handleError}
                />
              </div>
            )
          ) : isSupportVisible ? (
            <div className="d-flex flex-column align-center m-2">
              <img
                style={{ maxWidth: "250px", height: "250px" }}
                src={NodataFound}
                alt="video"
              />
              <h6 className="mt-3">
                Unsupported Format.{" "}
                <a
                  className="text-dark "
                  href={data?.musicId?.streamingAppLink}
                  target="_blank"
                >
                  <i
                    className="far fa-play-circle"
                    style={{ cursor: "pointer", fontSize: "20px" }}
                  ></i>
                  <span className="text-dark mx-1"> Listen/Watch </span>
                </a>{" "}
                here.
              </h6>
            </div>
          ) : null}

          <div className="row mt-4 ">
            <div className="col-md-6">
              <ul>
                {data && data?.tokenName ? (
                  <li>
                    <span className="text-dark">Token Name: </span>
                    {data && data?.tokenName}{" "}
                  </li>
                ) : (
                  ""
                )}
                {data && data?.musicId?.shareHolderName ? (
                  <li>
                    <span className="text-dark">Shareholder Name: </span>
                    {data && data?.musicId?.shareHolderName}{" "}
                  </li>
                ) : (
                  ""
                )}
                {data && data?.musicId?.contentType ? (
                  <li>
                    <span className="text-dark">Content Type: </span>
                    {data?.musicId?.contentType}{" "}
                  </li>
                ) : (
                  ""
                )}
                 {data?.musicId?.sortName ? (
                  <li>
                    <span className="text-dark">Short Name: </span>
                    {data.musicId.sortName}{" "}
                  </li>
                ) : (
                  ""
                )}
                {data && data?.musicId?.longName ? (
                  <li>
                    <span className="text-dark">Long Name: </span>{" "}
                    <div className="wrap-word " title={data?.musicId?.longName}>
                      {data.musicId.longName}{" "}
                    </div>
                  </li>
                ) : (
                  ""
                )}
                {data.state ? (
                  <li>
                    <span className="text-dark">State: </span>
                    {data.state === "stopped" ||
                    data.state === "rejected" ||
                    data.state === "failed" ? (
                      <span style={{ color: "rgb(255, 97, 97)" }}>
                        {Capitalize(data.state)}
                      </span>
                    ) : (
                      <>{Capitalize(data.state)}</>
                    )}{" "}
                  </li>
                ) : (
                  ""
                )}
                {data?.verification && data?.verification?.request ? (
                  <li>
                    <span className="text-dark">
                      {data.musicId.musicType == "new"
                        ? "Crowdfund"
                        : "Crowdsale"}{" "}
                      Status:{" "}
                    </span>
                    {data.verification.request}{" "}
                  </li>
                ) : (
                  ""
                )}

                {data && data.rDuration ? (
                  <li>
                    <span className="text-dark">Royalty Duration: </span>
                    {data.rDuration} years{" "}
                  </li>
                ) : (
                  ""
                )}

                {data && data.percentageRights ? (
                  <li>
                    <span className="text-dark">Percentage Rights: </span>
                    {data.percentageRights} %{" "}
                  </li>
                ) : (
                  ""
                )}
                {data && data.rate && data.deployedIn === "USD" ? (
                  <li>
                    <span className="text-dark">Rights per percente: </span>
                    {data?.rate?.toFixed(2)} {data.deployedIn} |{" "}
                    {liveprice &&
                      DollarToZPTC(
                        liveprice.zeptaPrice,
                        data?.rate?.toFixed(2)
                      )}{" "}
                    ZPTC
                  </li>
                ) : (
                  <li>
                    <span className="text-dark">Rights per percente: </span>
                    {data?.rate?.toFixed(2)} {data?.deployedIn} | $
                    {liveprice && (data?.rate * liveprice?.zeptaPrice)?.toFixed(2)}
                  </li>
                )}
                {data && data?.softCap ? (
                  <li>
                    <span className="text-dark">Soft-cap: </span>
                    {/* {data.softCap}{" "} */}$ 200
                  </li>
                ) : (
                  ""
                )}
                {data && data?.minContribution ? (
                  <li>
                    <span className="text-dark">Min-contribution: </span>${" "}
                    {data?.minContributionByUser}{" "}
                  </li>
                ) : (
                  ""
                )}
                {data && data?.targetAmount ? (
                  <li>
                    <span className="text-dark">Target Amount: </span>{" "}
                    {data?.targetAmountByUser} {data?.deployedIn}
                  </li>
                ) : (
                  ""
                )}

                {data &&
                data?.musicId &&
                data?.musicId?.artists &&
                data?.musicId?.artists.length ? (
                  <>
                    <li className="d-flex">
                      <span className="text-dark">Artist Name: </span>{" "}
                      <div
                        className="wrap-word "
                        title={data?.musicId?.artists[0]?.artistName}
                      >
                        {data?.musicId?.artists[0]?.artistName}
                      </div>
                    </li>
                    <li>
                      <span className="text-dark">Artist Role: </span>
                      {data?.musicId?.artists[0]?.artistType}{" "}
                    </li>
                  </>
                ) : (
                  ""
                )}
                {data && data?.musicId && data?.musicId?.contentType === "album"
                  ? renderCoOwnersInAlbum()
                  : null}
                {/*
                {data && data.musicId && data.musicId.contentType === "album"
                  ? renderContributors()
                  : null} */}
              </ul>
            </div>
            <div className="col-md-6">
              <ul>
                {data && data?.musicId?.genres && data?.musicId?.genres?.length ? (
                  <li>
                    <span className="text-dark">Genre: </span>
                    <div className="wrap-word " title={data?.musicId?.genres[0]}>
                      {data?.musicId?.genres[0]}{" "}
                    </div>
                  </li>
                ) : (
                  ""
                )}
                {data && data?.musicId?.upc ? (
                  <li>
                    <span className="text-dark">UPC: </span>
                    {data?.musicId?.upc}{" "}
                  </li>
                ) : (
                  ""
                )}
                {data.musicId && data.musicId.musicType ? (
                  <li>
                    <span className="text-dark">Type: </span>
                    {data.musicId.musicType == "new"
                      ? "Crowdfund"
                      : "Crowdsale"}{" "}
                  </li>
                ) : (
                  ""
                )}
                {data && data.startDate ? (
                  <li>
                    <span className="text-dark">Start Date: </span>
                    {moment(data.startDate).format("ddd, DD MMMM YYYY")}{" "}
                  </li>
                ) : (
                  ""
                )}
                {data && data.endDate ? (
                  <li>
                    <span className="text-dark">End Date: </span>
                    {moment(data.endDate).format("ddd, DD MMMM YYYY")}
                  </li>
                ) : (
                  ""
                )}
                {data && data?.musicId?.contentDetails?.duration ? (
                  <li>
                    <span className="text-dark">Duration: </span>
                    {data?.musicId?.contentDetails?.duration} min
                  </li>
                ) : (
                  ""
                )}
                {data && data.featured_by ? (
                  <li>
                    <span className="text-dark">Featured By: </span>
                    {data.featured_by}
                  </li>
                ) : (
                  ""
                )}
                {data.blockStatus ? (
                  <li>
                    <span className="text-dark">Block Status: </span>
                    {data.blockStatus.isBlocked ? (
                      <span style={{ color: "red" }}>Blocked</span>
                    ) : (
                      <span>Unblocked</span>
                    )}{" "}
                  </li>
                ) : (
                  ""
                )}

                {data && data.zeptaCoinsRaised ? (
                  <li>
                    <span className="text-dark">Zepta Coin Raised: </span>
                    {Number(data.zeptaCoinsRaised).toFixed(4)} ZPTC
                  </li>
                ) : (
                  ""
                )}
                {data && data?.deployedIn === "USD" ? (
                  data && data?.usdCoinsRaised ? (
                    <li>
                      <span className="text-dark">Fiat($) Raised:</span>{" "}
                      {`$${Number(data?.usdCoinsRaised.toFixed(2))}`}
                    </li>
                  ) : (
                    ""
                  )
                ) : data && data?.zeptaCoinsRaised ? (
                  <li>
                    <span className="text-dark">Fiat($) Raised:</span>{" "}
                    {`$${
                      liveprice
                        ? Number(
                            (
                              data?.zeptaCoinsRaised * liveprice?.zeptaPrice
                            ).toFixed(2)
                          )
                        : 0
                    }`}
                  </li>
                ) : (
                  ""
                )}

                {data.musicId &&
                data?.musicId?.royaltyStatement &&
                data?.musicId?.royaltyStatement.url ? (
                  <li>
                    <a
                      href={data?.musicId?.royaltyStatement?.url}
                      target="_blank"
                      // className="text-grey"
                    >
                      Current Royalty Statement
                    </a>
                  </li>
                ) : (
                  ""
                )}

                <li>
                  <ShareTray data={data} />
                </li>
              </ul>
            </div>
            <div className="row mx-3">
              <ul style={{ marginLeft: "5px" }}>
                <li className="text-dark">Description:</li>
                <li>
                  <div className="dataDesc text-justify">
                    {data.musicId.description}
                  </div>
                </li>
              </ul>
            </div>
            {data.verification &&
              data.verification.request &&
              data.verification.request === "rejected" && (
                <div className="row mx-3">
                  <ul style={{ marginLeft: "5px" }}>
                    <li
                      className="text-dark"
                      // style={{ color: "rgb(255, 97, 97)" }}
                    >
                      Rejected Reason:
                    </li>
                    <li>
                      <div
                        className="dataDesc text-justify"
                        style={{ color: "rgb(255, 97, 97)" }}
                      >
                        {data.verification && data.verification.rejectReason}
                      </div>
                    </li>
                  </ul>
                </div>
              )}
          </div>

          {
            // Actions---------------------------------------------------------
            data.verification ? (
              data.verification.isVerified == false &&
              !data.tokenName &&
              data.state != "live" &&
              data.state != "successful" &&
              data.state != "finalised" &&
              data.state != "stopped" ? (
                <button
                  className="button-medium border-shadow dark-button mx-auto"
                  style={{ marginBottom: "20px" }}
                  onClick={() => {
                    const crowdtype =
                      data.type === "newSong" ? "Crowdfunding" : "Crowdsale";
                    toggleEditCrowdsale(crowdtype);
                  }}
                >
                  {data.type === "newSong"
                    ? "EDIT CROWDFUNDING"
                    : "EDIT CROWDSALE"}
                </button>
              ) : null
            ) : null
          }

          {data.state === "draft" && !data.agreement.isGiven ? (
            <button
              className="button-medium border-shadow dark-button mx-auto"
              style={{ marginBottom: "20px" }}
              onClick={toggleUploadModal}
            >
              UPLOAD AGREEMENT DOCUMENTS
            </button>
          ) : null}

          {(data.state === "draft" || data.state === "rejected") &&
          !!data.agreement.isGiven &&
          (data.verification.request === "notSend" ||
            data.verification.request === "rejected") ? (
            !!verifyloading ? (
              <button
                className="button-medium border-shadow dark-button mx-auto"
                style={{ marginBottom: "20px" }}
              >
                <i
                  className="fa fa-spinner fa-spin"
                  style={{ fontSize: "20px", color: "white" }}
                ></i>{" "}
                Loading...
              </button>
            ) : (
              <button
                className="button-medium border-shadow dark-button mx-auto"
                onClick={handleVerifyCrowdsale}
                style={{ marginBottom: "20px" }}
              >
                {data.state === "rejected"
                  ? "RESEND FOR VERIFICATION"
                  : "SEND FOR VERIFICATION"}
              </button>
            )
          ) : null}

          {data.state === "verified" &&
          (!data.deploymentState || data.deploymentState !== "live") &&
          !!data.verification.isVerified ? (
            !!walletAvailable ? (
              <button
                className="button-medium border-shadow dark-button mx-auto"
                onClick={toggleDeployCrowdsale}
                style={{ marginBottom: "20px" }}
              >
                {data.type === "newSong"
                  ? "DEPLOY CROWDFUNDING"
                  : "DEPLOY CROWDSALE"}
              </button>
            ) : null
          ) : null}

          {data.state === "live" && !!data.saleId ? (
            <button
              className="button-medium border-shadow dark-button mx-auto"
              style={{ marginBottom: "20px" }}
              onClick={toggleExtendCrowdsale}
            >
              {data.type === "newSong"
                ? "EXTEND CROWDFUNDING"
                : "EXTEND CROWDSALE"}
            </button>
          ) : null}

          {data.state != "stopped" &&
          data.state != "live" &&
          data.state != "rejected" ? (
            !!stopcrowdsaleloading ? (
              <button
                className="button-medium border-shadow dark-button mx-auto"
                style={{ marginBottom: "20px" }}
              >
                <i
                  className="fa fa-spinner fa-spin"
                  style={{ fontSize: "20px", color: "white" }}
                ></i>{" "}
                Loading...
              </button>
            ) : (
              <button
                className="button-medium border-shadow dark-button mx-auto"
                style={{ marginBottom: "20px" }}
                onClick={handleStopCrowdsale}
              >
                {data.type === "newSong"
                  ? "STOP CROWDFUNDING"
                  : "STOP CROWDSALE"}
              </button>
            )
          ) : null}
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default CrowdsalePermissions;
