import React from "react";
import { useState } from "react";

import { Modal } from "react-bootstrap";
import SwalError from "../../components/ToastMessage/SwalError";
import SwalSuccess from "../../components/ToastMessage/SwalSuccess";
import { userApis } from "../../redux/api";
import { getRequiredProtectedData } from "../../redux/apicalltemplate";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../../redux/user/Action";

export const DeleteAccount = ({ show, setShow, ual }) => {

    const [loading, setLoading] = useState(false)
    const dispatch = useDispatch();
    const handleClose = () => {
        setShow(false)
    }

    const handleOk = async () => {
        setLoading(true);
        const response = await getRequiredProtectedData(
            "put",
            `${userApis.DELETE_ACCOUNT}`,
            {},
        );

        if (response && response.data && response.data.success) {
            SwalSuccess(response.data.message);

            setShow(false);
            dispatch(logout({ ual: ual }));
        }
        if (response && response.data && !response.data.success) {
            SwalError(response.data.message);
        }
        if (response && response.response && response.response.data) {
            SwalError(response.response.data.message);
        }
        setLoading(false);
    }

    return (
        <div>
            {(
                <Modal size="lg" show={show} onHide={handleClose}>
                    <Modal.Header closeButton>
                        <Modal.Title>Are you sure you want to delete your account?</Modal.Title>
                    </Modal.Header>

                    <Modal.Body className="p-2">
                        <div className=" m-2 mb-5 justify-center">

                            <div
                                className="text-dark mt-2 text-justify"
                                style={{ fontWeight: "500" }}
                            >
                                {'Once perform this action, you will lose all your access to this particular account.'}
                            </div>

                        </div>
                    </Modal.Body>
                    <Modal.Footer className="p-3">


                        {!loading ? (
                            <button
                                className="button-medium border-shadow  medium-btn"
                                type='button'
                                onClick={handleOk}
                                disabled={loading}
                                title={"Submit"}
                            >Delete</button>
                        ) : (

                            <span
                                className="spinner-border spinner-border-smd"
                                role="status"
                                aria-hidden="true"
                            ></span>

                        )}

                    </Modal.Footer>
                </Modal>
            )}
        </div>
    );
};
