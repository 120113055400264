import { Carousel } from "react-bootstrap"
import Banner from '../../images/zeptagrambanner.png'
import { useEffect, useState } from "react"
import { getRequiredProtectedData } from "../../redux/apicalltemplate"
import { userApis } from "../../redux/api"





export const CarouselHome=()=>{
  const [data,setData]=useState([])

useEffect(async()=>{
  const response = await getRequiredProtectedData(
    "GET",
    `${userApis.BANNER}`,
    {},
  );
   if(response && response.data && response.data.data){
    setData(response.data.data);
  }else{
    setData(null)
  }
},[])
    return(
        <Carousel  style={{maxHeight:'400px',marginTop:'-25px',marginBottom:'40px'}} >
          {
            data?.map(el=> <Carousel.Item>
              <img
                className="d-block w-100 car-image"
                src={el.banner[0]}
                alt="First slide"
                style={{maxHeight:'400px'}}
              />
              <Carousel.Caption style={{width: 'max-content',
    margin: 'auto',
    padding:'10px',
    borderRadius:'6px',
    backdropFilter: 'blur(5px)'

}}>
                <h3 style={{fontWeight:'bold'}}>{el.tag}</h3>
                <p style={{fontWeight:'bold'}}>{el.description}</p>
              </Carousel.Caption>
            </Carousel.Item>)
          }
        
      </Carousel>
    )
}