import { WidthBox } from "../../components/components";
import { Image, Spinner } from "react-bootstrap";
import React from "react";
import { FilePersonFill } from "react-bootstrap-icons";
import { notifyError } from "../../utils/utils";

export default function KycImageView({
  hint,
  imageUrl,
  fileData,
  onChange,
  loading,
  disable = false,
}) {
  let avatarUploadRef;

  return (
    <>
      <input
        ref={(ref) => (avatarUploadRef = ref)}
        type="file"
        accept="image/jpeg,image/jpg, image/png"
        disabled={disable}
        id={"input"}
        className={"input-box-sm"}
        type={"file"}
        multiple="false"
        onChange={(event) => {
          const file = event.target.files[0];

          if (file.size > 524288) {
            notifyError("Please upload a file smaller than 512 KB");
            return;
          }

          const reader = new FileReader();
          reader.readAsDataURL(file);
          reader.onloadend = function (ev) {
            onChange(file, reader.result);
          };
        }}
      />
    </>
  );
}
