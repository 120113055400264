import React, { useState } from 'react'
import { useEffect } from 'react';
import { Modal, Spinner } from 'react-bootstrap'
import AppServices from '../../services/AppService';
const appService = new AppServices()
export default function ShowNFTsModal({ showModal, hideModal, ual }) {
    const [musicNfts, setMusicNfts] = useState([]);
    const [loader, setLoader] = useState(false);
    useEffect(() => {
        (async () => {
            if (showModal) {
                setLoader(true)
                const data = await appService.getAtomicAssets(ual.activeUser?.accountName, 1, '')
                const filterdata = appService.filterData(data);
                if (filterdata.length !== 0) {
                    console.log(filterdata);
                    setMusicNfts(filterdata);
                }
                setLoader(false)
            }
        })();

    }, [ual.activeUser?.accountName, showModal])
    return (<>
        <Modal size="lg"
            fullscreen={true}
            show={showModal}
            onHide={() => {
                hideModal(false);
            }}>
            <Modal.Header closeButton>
                <Modal.Title className="text-dark"> Music NFTs</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {!loader ?
                    <div className='row collectionContainer' style={{ gap: '20px', justifyContent: 'space-evenly' }}>
                        {musicNfts.length > 0 ? musicNfts.map((data, index) => <div key={index} className='collectionCard2 col-sm-2' style={{}} onClick={() => window.open(`${process.env.REACT_APP_NFT_URL}/assets/${data.asset_id}`, "_blank")}>
                            <img src={appService.getIpfsUrl(data.data.img)} style={{ width: "100%", height: '160px', borderRadius: '30px' }} />
                            <div>
                                <p className='text-dark mb-2 text-center'>{data.name}</p>
                                <p style={{ textAlign: "center", color: '#d6c79c', fontWeight: 'bold  ' }}>{`#${data.asset_id}`}</p>
                            </div>
                        </div>) :
                            <>
                                <div>
                                    <p className='text-dark mb-2 text-center'>Music NFTs Not Found</p>

                                </div>
                            </>}
                    </div> :
                    <div style={{ height: "150px", display: 'flex', alignItems: "center", justifyContent: 'center' }}> <Spinner animation="border" role="status">
                        <span className="visually-hidden">Loading...</span>
                    </Spinner></div>}
            </Modal.Body>
            {/* <Modal.Footer>
            <SubmitButton
                onClick={() => {
                    schema.length > 0 ?
                        submitHandler() :
                        createSchema(schemaName, collectionName)
                }}
                title={schema.length > 0 ?
                    "Create NFT" :
                    "Create Schema"}
                loading={loading}
            />
        </Modal.Footer> */}
        </Modal >
    </>
    )
}
