import React, { useState } from "react";
import { Modal, Form } from "react-bootstrap";
import InfoToast from "../../components/ToastMessage/InfoToast";
import { getRequiredProtectedData } from "../../redux/apicalltemplate";
import { userApis } from "../../redux/api";
import { useEffect } from "react";
import SwalSuccess from "../../components/ToastMessage/SwalSuccess";
import SwalError from "../../components/ToastMessage/SwalError";

const CreateTicket = ({ show, setShow, setCallAgain, callagain }) => {
  const [description, setDescription] = useState("");
  const [subject, setSubject] = useState("");

  const [loading, setLoading] = useState(false);
  const handleClose = () => setShow(false);

  //handle ticket---------------------
  const handleTicket = async (event) => {
    event.preventDefault();

    if (subject.length < 4) {
      InfoToast("Min length for subject is 4 characters");
    } else if (description.length < 6) {
      InfoToast("Min length for description is 6 characters");
    } else {
      let data = {};
      data.subject = subject;
      data.description = description;
      data.createdFor = "ADMIN";
      setLoading(true);
      const response = await getRequiredProtectedData(
        "POST",
        `${userApis.ADD_TICKET}`,
        data,
        {}
      );
      if (response && response.data && response.data.success) {
        SwalSuccess('Ticket added successfully');
        setTimeout(() => {
          setShow(false);
          setCallAgain(!callagain);
        }, 800);
      }
      if (response && response.data && !response.data.success) {
        SwalError(response.data.message);
      }
      if (response && response.response && response.response.data) {
        SwalError(response.response.data.message);
      }
      setLoading(false);
    }
  };

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title className="text-center">Generate Ticket</Modal.Title>
      </Modal.Header>
      <Form onSubmit={handleTicket}>
        <Modal.Body className="p-5">
          <label className="text-dark" style={{ marginLeft: "10px" }}>
            Subject*
          </label>
          <Form.Control
            className={"input-box-sm"}
            type="text"
            name="subject"
            value={subject}
            onChange={(e) => setSubject(e.target.value)}
            placeholder="Enter Subject"
          />

          <label className="text-dark" style={{ marginLeft: "10px" }}>
            Created For*
          </label>
          <Form.Control
            className={"input-box-sm"}
            type="text"
            placeholder="Created For"
            value={"ADMIN"}
            disabled
          />

          <label className="text-dark" style={{ marginLeft: "10px" }}>
            Description*
          </label>
          <textarea
            className={"input-textarea"}
            type="text"
            rows="10"
            cols="30"
            aria-label="With textarea"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            placeholder="Enter Description"
          />
        </Modal.Body>
        <Modal.Footer className="p-3">
          <button
            className="button-medium border-shadow  medium-btn"
            type="submit"
            title={"Create Ticket"}
          >
            {!loading ? (
              "Submit"
            ) : (
              <>
                <span
                  className="spinner-border spinner-border-smd"
                  role="status"
                  aria-hidden="true"
                ></span>
              </>
            )}
          </button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

export default CreateTicket;
