//for live crowdsale-----
export const livecrowdsalepayload = {
  data: {},
  params: {
    page: 1,
    limit: 8,
    pageSize: 4,
    sortBy: "",
    order: -1,
    state: "live",
    type: "songOffering",
    forWhiteListed: "",
    percentageRights: "",
    conversionVal: "zeptacoins",
    rate: "",
    minContribution: "",
    name: "",
    maxContribution: "",
    softCap: "",
    nextPage: false,
    selector:
      "state softCap minContribution maxContribution percentageRights rate zeptaCoinsRaised isuuer startDate endDate type tokenName saleId targetAmountByUser",
    selectorValue: 1,
    populateMusic: true,
    isBlocked: "false",
    // musicSelector: "royaltyStatement",
  },
};
//live crowdfunding--------------
export const livecrowdfundpayload = {
  data: {},
  params: {
    page: 1,
    limit: 8,
    pageSize: 4,
    sortBy: "",
    order: -1,
    state: "live",
    type: "newSong",
    forWhiteListed: "",
    percentageRights: "",
    conversionVal: "zeptacoins",
    rate: "",
    minContribution: "",
    name: "",
    maxContribution: "",
    softCap: "",
    nextPage: false,
    selector:
      "state softCap minContribution maxContribution percentageRights rate zeptaCoinsRaised isuuer startDate endDate type tokenName saleId targetAmountByUser",
    selectorValue: 1,
    populateMusic: true,
    isBlocked: "false",
    // musicSelector: "royaltyStatement",
  },
};

//popular crowdfunding--------------
export const popularcrowdsalepayload = {
  data: {},
  params: {
    page: 1,
    limit: 8,
    pageSize: 4,
    sortBy: "zeptaCoinsRaised",
    order: -1,
    state: "live",
    type: "",
    forWhiteListed: "",
    percentageRights: "",
    conversionVal: "zeptacoins",
    rate: "",
    name: "",
    minContribution: "",
    maxContribution: "",
    softCap: "",
    nextPage: false,
    selector:
      "state softCap minContribution maxContribution percentageRights rate zeptaCoinsRaised isuuer startDate endDate type tokenName saleId targetAmountByUser",
    selectorValue: 1,
    populateMusic: true,
    isBlocked: "false",
    // musicSelector: "royaltyStatement",
  },
};
//popular recommed--------------
export const recommendcrowdsalepayload = {
  data: {},
  params: {
    page: 1,
    limit: 8,
    pageSize: 4,
    sortBy: "zeptaCoinsRaised",
    order: -1,
    state: "live",
    type: "",
    forWhiteListed: "",
    percentageRights: "",
    conversionVal: "zeptacoins",
    rate: "",
    name: "",
    is_recommended: true,
    minContribution: "",
    maxContribution: "",
    softCap: "",
    nextPage: false,
    selector:
      "state softCap minContribution maxContribution percentageRights rate zeptaCoinsRaised isuuer startDate endDate type tokenName saleId targetAmountByUser",
    selectorValue: 1,
    populateMusic: true,
    isBlocked: "false",
    // musicSelector: "royaltyStatement",
  },
};
//SuccessfulCrowdsale--------------
export const successfulcrowdsalepayload = {
  data: {},
  params: {
    page: 1,
    limit: 8,
    pageSize: 4,
    sortBy: "",
    order: -1,
    state: "successful",
    type: "",
    forWhiteListed: "",
    percentageRights: "",
    conversionVal: "zeptacoins",
    rate: "",
    minContribution: "",
    name: "",
    maxContribution: "",
    softCap: "",
    nextPage: false,
    selector:
      "state featured_by softCap minContribution maxContribution percentageRights rate zeptaCoinsRaised isuuer startDate endDate tokenName saleId targetAmountByUser",
    selectorValue: 1,
    populateMusic: true,
    isBlocked: "false",
    // musicSelector: "royaltyStatement",
  },
};
//finalizedCrowdsale--------------
export const finalizedcrowdsalepayload = {
  data: {},
  params: {
    page: 1,
    limit: 8,
    pageSize: 4,
    sortBy: "",
    order: -1,
    state: "finalised",
    type: "songOffering",
    forWhiteListed: "",
    percentageRights: "",
    conversionVal: "zeptacoins",
    name: "",
    rate: "",
    minContribution: "",
    maxContribution: "",
    softCap: "",
    nextPage: false,
    selector:
      "state softCap minContribution maxContribution percentageRights rate zeptaCoinsRaised isuuer startDate endDate tokenName saleId targetAmountByUser",
    selectorValue: 1,
    populateMusic: true,
    isBlocked: "false",
    // musicSelector: "royaltyStatement",
  },
};

//finalized crowdfund--------------
export const finalizedcrowdfundpayload = {
  data: {},
  params: {
    page: 1,
    limit: 8,
    pageSize: 4,
    sortBy: "",
    order: -1,
    state: "finalised",
    type: "newSong",
    forWhiteListed: "",
    percentageRights: "",
    conversionVal: "zeptacoins",
    name: "",
    rate: "",
    minContribution: "",
    maxContribution: "",
    softCap: "",
    nextPage: false,
    selector:
      "state softCap minContribution maxContribution percentageRights rate zeptaCoinsRaised isuuer startDate endDate tokenName saleId targetAmountByUser",
    selectorValue: 1,
    populateMusic: true,
    isBlocked: "false",
    // musicSelector: "royaltyStatement",
  },
};

//for all-----
export const allLiveSongPayload = {
  data: {},
  params: {
    page: 1,
    limit: 8,
    pageSize: 4,
    sortBy: "",
    order: -1,
    state: "live",
    type: "",
    forWhiteListed: "",
    percentageRights: "",
    conversionVal: "zeptacoins",
    rate: "",
    minContribution: "",
    name: "",
    maxContribution: "",
    softCap: "",
    nextPage: false,
    selector:
      "state softCap minContribution maxContribution percentageRights rate zeptaCoinsRaised isuuer startDate endDate type tokenName saleId targetAmountByUser",
    selectorValue: 1,
    populateMusic: true,
    isBlocked: "false",
    // musicSelector: "royaltyStatement",
  },
};
