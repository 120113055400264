import React, { useState } from "react";
import { Button } from "react-bootstrap";
import { X, XLg } from "react-bootstrap-icons";
import { useHistory } from "react-router";
const AmlDialog = ({ amlStatus, investorStatus }) => {
  const history = useHistory();
  const [show, setShow] = useState(true);
  const handleClose = () => setShow(false);

  const inlineBlock = {
    display: "inline-block !important",
  };
  return (
    <>
      <center>
        {amlStatus == "PENDING" && investorStatus !== "pending" && show ? (
          <div style={{ backgroundColor: "#D5C79C", padding: "8px" }}>
            <div style={inlineBlock}>
              Your AML KYC is pending,
              <span
                style={{ color: "red", cursor: "pointer" }}
                onClick={() => {
                  history.push("/profile");
                  handleClose();
                }}
              >
                {" "}
                Click here{" "}
              </span>
              to submit.
              <XLg
                style={{
                  display: "inline-block !important",
                  float: "right",
                  cursor: "pointer",
                  marginTop: "4px",
                }}
                onClick={handleClose}
              />{" "}
            </div>
          </div>
        ) : null}
      </center>
    </>
  );
};

export default AmlDialog;
