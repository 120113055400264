import React from "react"
import twitterLogo from "../../images/twitter.svg"
// import whatsappLogo from "../../images/whatsapp.svg"
// import instagramLogo from "../../images/instagram.svg"
import facebookLogo from "../../images/facebook.svg"
// import googlePlusLogo from "../../images/google-plus.svg"
import linkedinLogo from "../../images/linkedin.svg"
// import pinterestLogo from "../../images/pinterest.svg"
import telegram from "../../images/telegram.svg"
import "./ShareTray.scss"

const  ShareTray=({data}) =>{

  const googleShare=()=>{
    window.open(this.href, '', 'menubar=no,toolbar=no,resizable=yes,scrollbars=yes,height=350,width=480')
    return false
  }

  const twitterShare=()=>{
  window.open(this.href, '', 'menubar=no,toolbar=no,resizable=yes,scrollbars=yes,height=300,width=600')
  return false
  }

  const redditShare=()=>{
  window.open(this.href, '', 'menubar=no,toolbar=no,resizable=yes,scrollbars=yes,height=300,width=600')
  return false
  }

//   const TelegramShare=(this)=>{
//   window.open(this.href, '', 'menubar=no,toolbar=no,resizable=yes,scrollbars=yes,height=300,width=600')
//   return false
//   }

    return(
      <div style={{display:'flex'}} id="share">
      <a  className="facebook m-2" href={`https://www.facebook.com/share.php?u=${`https://app.zeptagram.com/dashboard?id=${data._id}`}&quote=${!!data.musicId?data.musicId.shareHolderName:'Zeptagram'} is conducting a Crowdsale with target amount of $ ${data.targetAmountByUser} On Zeptagram Platform for their music ${!!data.musicId?data.musicId.longName:'Zeptagram'}. Click the link to know more...`} target="_blank">
      <img title="Share on Facebook" src={facebookLogo} className='share-icon' />
      <meta property="og:title" content="your_link_title" />
      </a>

    <a className="twitter m-2"
    // onClick={twitterShare}
    target = '_blank'
    href={`https://twitter.com/share?url=${`https://app.zeptagram.com/dashboard?id=${data._id}`}&text=${!!data.musicId?data.musicId.shareHolderName:'Zeptagram'} is conducting a Crowdsale with target amount of $ ${data.targetAmountByUser} On Zeptagram Platform for their music ${!!data.musicId?data.musicId.longName:'Zeptagram'}. Click the link to know more...`}
    >

    <img title="Share on Twitter" src={twitterLogo} className='share-icon'  />
    </a>

    <a className="linkedin m-2"
    href={`https://www.linkedin.com/sharing/share-offsite?mini=true&url=https://app.zeptagram.com/dashboard?id%3D${data._id}&title%3D${!!data.musicId?data.musicId.shareHolderName:'Zeptagram'} is conducting a Crowdsale with target amount of $ ${data.targetAmountByUser} On Zeptagram Platform for their music ${!!data.musicId?data.musicId.longName:'Zeptagram'}. Click the link to know more...`}
    target="_blank"
    >
    <img title="Share on Linkedin" src={linkedinLogo} className='share-icon'  />
  </a>

{
    // https://www.linkedin.com/shareArticle?mini=true&url=http://developer.linkedin.com&title=LinkedIn%20Developer%20Network&summary=My%20favorite%20developer%20program&source=LinkedIn
}
    <a className="pinterest m-2" href={`https://telegram.me/share/url?url=https://app.zeptagram.com/dashboard?id=${data._id}&text=${!!data.musicId?data.musicId.shareHolderName:'Zeptagram'} is conducting a Crowdsale with target amount of $ ${data.targetAmountByUser} On Zeptagram Platform for their music ${!!data.musicId?data.musicId.longName:'Zeptagram'}. Click the link to know more...`} target="_blank">
    <img title="Share on Telegram" src={telegram} className='share-icon'  />
    </a>
  </div>

    )
  
}

export default ShareTray
