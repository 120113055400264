import { useEffect, useReducer } from "react";

import axios from "axios";
import data from "bootstrap/js/src/dom/data";

const initialState = {
  loading: true,
  error: undefined,
  data: undefined,
};

function apiReducer(state, action) {
  switch (action.type) {
    case "DATA_FETCH_START":
      return { ...state, loading: true };
    case "DATA_FETCH_FAILURE":
      return { ...state, loading: false, error: action.payload };
    case "DATA_FETCH_SUCCESS":
      return { ...state, loading: false, data: action.payload };
    default:
      return state;
  }
}

export function useFetch(url, initialData = [], tokens) {
  const token = localStorage.getItem("token") || tokens;
 
  const [data, dispatch] = useReducer(apiReducer, initialState);
  useEffect(() => {
    dispatch({ type: "DATA_FETCH_START" });
    url &&
      fetch(url, {
        method: "GET",
        headers: new Headers({
          "x-auth-token": token,
          "Content-Type": "application/json",
        }),
      })
        .then(async (response) => {
          if (!response.ok) {
            const error = await response.text();
            throw {
              status: response.status,
              error: parseError(error),
            };
          }
          return response.json();
        })
        .then((json) => {
          dispatch({ type: "DATA_FETCH_SUCCESS", payload: json });
        })
        .catch((error) => {
          dispatch({ type: "DATA_FETCH_FAILURE", payload: error });
        });
  }, initialData);
  return data;
}

export async function useSubmit(url, body, type = "POST") {
  const token = localStorage.getItem("token");
  return fetch(url, {
    method: type,
    headers: new Headers({
      "x-auth-token": token,
      "Content-Type": "application/json",
    }),
    body: JSON.stringify(body),
  }).then(async (response) => {
    if (!response.ok) {
      const error = await response.text();
      throw {
        status: response.status,
        message: parseError(error),
      };
    }

    const result = await response.json();
    //console.log("result")
    //console.log(result)
    if (result["success"] !== undefined && !result["success"]) {
      //console.log("throw ex")
      throw result;
    }
    return result;
  });
}

export async function useGET(url) {
  const token = localStorage.getItem("token");
  return fetch(url, {
    method: "GET",
    headers: new Headers({
      "x-auth-token": token,
      "Content-Type": "application/json",
    }),
  }).then(async (response) => {
    if (!response.ok) {
      const error = await response.text();
      throw {
        status: response.status,
        message: parseError(error),
      };
    }

    const result = await response.json();
    //console.log("result")
    //console.log(result)
    if (result["success"] !== undefined && !result["success"]) {
      //console.log("throw ex")
      throw result;
    }
    return result;
  });
}

export function fetchUrl(url) {
  const token = localStorage.getItem("token");
  return axios.get(url, {
    headers: {
      "x-auth-token": token,
      "Content-Type": "application/json",
    },
  });
}

export async function useUpload(url, body) {
  const token = localStorage.getItem("token");
  return axios.patch(url, body, {
    headers: {
      "x-auth-token": token,
      "Content-Type": "form-data",
    },
  });
}

export async function useDelete(url) {
  const token = localStorage.getItem("token");
  return axios.delete(url, {
    headers: {
      "x-auth-token": token,
    },
  });
}

export async function useUploadKyc(url, body) {
  const token = localStorage.getItem("token");
  return axios.put(url, body, {
    headers: {
      "x-auth-token": token,
      "Content-Type": "form-data",
    },
  });
}

export async function useUploadKycImage(url, body) {
  const token = localStorage.getItem("token");
  return axios.post(url, body, {
    headers: {
      "x-auth-token": token,
      "Content-Type": "form-data",
    },
  });
}

function parseError(str) {
  try {
    const json = JSON.parse(str);
    return json.message;
  } catch (e) {
    return str || "Oops! Something went wrong";
  }
}
