import {Col, Modal, Row} from "react-bootstrap";
import  AppStoreIcon from '../../icons/app-store.png'
import  PlayStoreIcon  from '../../icons/playstore.png'

export default  function RateDialog({show,setShow}){

    return (<Modal show={show} onHide={() => {setShow()}} size='sm'>
        <Modal.Header closeButton>
            <Modal.Title>Rate us</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <Row>
                <Col sm={6} className={'d-flex justify-content-center'}>
                    <a target={'_blank'} href={'https://play.google.com/store/apps/details?id=com.zepta.gram'}>
                        <img width={'50px'} src={PlayStoreIcon}/>
                    </a>

                </Col>
                <Col sm={6} className={'d-flex justify-content-center'}>
                    <a  target={'_blank'} href={'https://apps.apple.com/in/app/zeptagram/id1481741523'}>
                        <img width={'50px'}  src={AppStoreIcon}/>
                    </a>

                </Col>
            </Row>
        </Modal.Body>

    </Modal>)
}