import { takeLatest, put, all, call } from "redux-saga/effects";
import { crowdsaleApis } from "../api";
import { crowdsaleActionTypes } from "./Types";
import
{
  SuccessToast,
  ErrorToast,
} from "../../components/ToastMessage/SuccessToast";
import { ToastContainer, toast } from "react-toastify";
import { push } from "connected-react-router";
import
{
  popularCrowdsaleSuccess,
  popularCrowdsaleFailure,
  liveCrowdsaleSuccess,
  liveCrowdsaleFailure,
  liveCrowdfundSuccess,
  liveCrowdfundFailure,
  getLivePriceSuccess,
  getLivePriceFailure,
  investCrowdsaleSuccess,
  investCrowdsaleFailure,
  successfulCrowdsaleSuccess,
  successfulCrowdsaleFailure,
  finalizedCrowdsaleSuccess,
  finalizedCrowdsaleFailure,
  finalizedCrowdfundSuccess,
  finalizedCrowdfundFailure,
  investCrowdsaleConfirmSuccess,
  investCrowdsaleConfirmFailure,
  recommendCrowdsaleSuccess,
  recommendCrowdsaleFailure,
} from "../crowdsale/Action";
import { getAxiosData } from "../apicalltemplate";

// get all crowdsale---
export function* popularCrowdsaleStart({ payload })
{
  try
  {
    const response = yield getAxiosData(
      "GET",
      crowdsaleApis.GET_ALL_CROWDSALE,
      payload.data,
      payload.params
    );
    const { data } = response;
    if (data)
    {
      yield put(popularCrowdsaleSuccess(data));
    }
  } catch (error)
  {
    yield put(popularCrowdsaleFailure(error));
  }
}

export function* popularCrowdsale()
{
  yield takeLatest(
    crowdsaleActionTypes.POPULAR_CROWDSALE_START,
    popularCrowdsaleStart
  );
}

// get recommend crowdsale---
export function* recommendCrowdsaleStart({ payload })
{
  try
  {
    const response = yield getAxiosData(
      "GET",
      crowdsaleApis.GET_ALL_CROWDSALE,
      payload.data,
      payload.params
    );
    const { data } = response;
    // console.log("saga", data, payload);
    if (data)
    {
      yield put(recommendCrowdsaleSuccess(data));
    }
  } catch (error)
  {
    yield put(recommendCrowdsaleFailure(error));
  }
}

export function* recommendCrowdsale()
{
  yield takeLatest(
    crowdsaleActionTypes.RECOMMEND_CROWDSALE_START,
    recommendCrowdsaleStart
  );
}

// get live crowdsale---
export function* liveCrowdsaleStart({ payload })
{
  try
  {
    const response = yield getAxiosData(
      "GET",
      crowdsaleApis.GET_ALL_CROWDSALE,
      payload.data,
      payload.params
    );
    const { data } = response;
    if (data)
    {
      yield put(liveCrowdsaleSuccess(data));
    }
  } catch (error)
  {
    yield put(liveCrowdsaleFailure(error));
  }
}

export function* liveCrowdsale()
{
  yield takeLatest(
    crowdsaleActionTypes.LIVE_CROWDSALE_START,
    liveCrowdsaleStart
  );
}
// get live crowdfund---
export function* liveCrowdfundStart({ payload })
{
  try
  {
    const response = yield getAxiosData(
      "GET",
      crowdsaleApis.GET_ALL_CROWDSALE,
      payload.data,
      payload.params
    );
    const { data } = response;
    if (data)
    {
      yield put(liveCrowdfundSuccess(data));
    }
  } catch (error)
  {
    yield put(liveCrowdfundFailure(error));
  }
}

export function* liveCrowdfund()
{
  yield takeLatest(
    crowdsaleActionTypes.LIVE_CROWDFUND_START,
    liveCrowdfundStart
  );
}

//successfull crowdsale
export function* successfulCrowdsaleStart({ payload })
{
  try
  {
    const response = yield getAxiosData(
      "GET",
      crowdsaleApis.GET_ALL_CROWDSALE,
      payload.data,
      payload.params
    );
    const { data } = response;
    if (data)
    {
      yield put(successfulCrowdsaleSuccess(data));
    }
  } catch (error)
  {
    yield put(successfulCrowdsaleFailure(error));
  }
}

export function* successfulCrowdsale()
{
  yield takeLatest(
    crowdsaleActionTypes.SUCCESSFUL_CROWDSALE_START,
    successfulCrowdsaleStart
  );
}

//FINALIZED crowdsale
export function* finalizedCrowdsaleStart({ payload })
{
  try
  {
    const response = yield getAxiosData(
      "GET",
      crowdsaleApis.GET_ALL_CROWDSALE,
      payload.data,
      payload.params
    );
    const { data } = response;
    if (data)
    {
      yield put(finalizedCrowdsaleSuccess(data));
    }
  } catch (error)
  {
    yield put(finalizedCrowdsaleFailure(error));
  }
}

export function* finalizedCrowdsale()
{
  yield takeLatest(
    crowdsaleActionTypes.FINALIZED_CROWDSALE_START,
    finalizedCrowdsaleStart
  );
}

//FINALIZED crowdfund
export function* finalizedCrowdfundStart({ payload })
{
  try
  {
    const response = yield getAxiosData(
      "GET",
      crowdsaleApis.GET_ALL_CROWDSALE,
      payload.data,
      payload.params
    );
    const { data } = response;
    if (data)
    {
      yield put(finalizedCrowdfundSuccess(data));
    }
  } catch (error)
  {
    yield put(finalizedCrowdfundFailure(error));
  }
}

export function* finalizedCrowdfund()
{
  yield takeLatest(
    crowdsaleActionTypes.FINALIZED_CROWDFUND_START,
    finalizedCrowdfundStart
  );
}

// get live price---
export function* getLivePriceStart({ payload })
{
  try
  {
    const response = yield getAxiosData(
      "GET",
      crowdsaleApis.GET_LIVE_PRICE,
      payload.data,
      payload.params
    );
    const { data } = response;
    if (data)
    {
      yield put(getLivePriceSuccess(data));
    }
  } catch (error)
  {
    yield put(getLivePriceFailure(error));
  }
}

export function* getLivePrice()
{
  yield takeLatest(
    crowdsaleActionTypes.GET_LIVE_PRICE_START,
    getLivePriceStart
  );
}

// invest crowdsale--
export function* investCrowdsaleStart({ payload })
{
  try
  {
    const response = yield getAxiosData(
      "POST",
      payload.type === "fiat"
        ? crowdsaleApis.INVEST_VIA_FIAT
        : payload.type === "Anchor"
          ? crowdsaleApis.INVEST_VIA_FIAT_ANCHOR
          : payload.type === "Freighter"
            ? crowdsaleApis.SOROBAN_INVEST
            : crowdsaleApis.INVEST_VIA_CRYPTO,
      payload.data,
      payload.params
    );

    const { data } = response;
    if (data && data.success)
    {
      // yield toast.success(<SuccessToast message={data.message}/>)
      if (payload.type == "fiat" || payload.type == "Anchor")
      {
        yield put(
          push(
            `/payment/${data.data}/${payload.data.crowdsaleId}/${payload.data.actualAmount}`
          )
        );
      } else
      {
        yield toast.success(<SuccessToast message={data.message} />, {
          toastId: 1,
          updateId: 1,
        });
        yield put(push(`/portfolio`));
      }

      yield put(investCrowdsaleSuccess(data));
    }

    if (data && !data.success)
    {
      yield toast.error(<ErrorToast message={data.message} />, {
        toastId: 1,
        updateId: 1,
      });
      yield put(investCrowdsaleFailure(data));
    }
  } catch (error)
  {
    if (error && error.response && error.response.data)
    {
      yield toast.error(<ErrorToast message={error.response.data.message} />, {
        toastId: 1,
        updateId: 1,
      });
    } else
    {
      yield toast.error(
        <ErrorToast message={"Please try again after sometime."} />,
        {
          toastId: 1,
          updateId: 1,
        }
      );
    }
    yield put(investCrowdsaleFailure(error));
  }
}

export function* investCrowdsale()
{
  yield takeLatest(
    crowdsaleActionTypes.INVEST_CROWDSALE_START,
    investCrowdsaleStart
  );
}

// invest confirm crowdsale--
export function* investCrowdsaleConfirmStart({ payload })
{
  try
  {
    const response = yield getAxiosData(
      "POST",
      crowdsaleApis.INVEST_CONFIRM,
      payload,
      {}
    );
    const { data } = response;
    if (data)
    {
      yield toast.success(<SuccessToast message={data.message} />, {
        toastId: 1,
        updateId: 1,
      });
      yield put(investCrowdsaleConfirmSuccess(data));
    }
  } catch (error)
  {
    if (error && error.response && error.response.data)
    {
      yield toast.error(<ErrorToast message={error.response.data.message} />, {
        toastId: 1,
        updateId: 1,
      });
    }
    yield put(investCrowdsaleConfirmFailure(error));
  }
}

export function* investCrowdsaleConfirm()
{
  yield takeLatest(
    crowdsaleActionTypes.INVEST_CROWDSALE_CONFIRM_START,
    investCrowdsaleConfirmStart
  );
}

export function* crowdsaleSaga()
{
  yield all([
    call(popularCrowdsale),
    call(recommendCrowdsale),
    call(liveCrowdsale),
    call(liveCrowdfund),
    call(getLivePrice),
    call(investCrowdsale),
    call(investCrowdsaleConfirm),
    call(successfulCrowdsale),
    call(finalizedCrowdsale),
    call(finalizedCrowdfund),
  ]);
}
