import React from "react";
import NFT1 from "../../../images/guide/nft/nft1.png";
import NFT2 from "../../../images/guide/nft/nft2.png";
import NFT3 from "../../../images/guide/nft/nft3.png";
import NFT4 from "../../../images/guide/nft/nft4.png";
import NFT5 from "../../../images/guide/nft/nft5.png";
import NFT6 from "../../../images/guide/nft/nft6.png";
import NFT7 from "../../../images/guide/nft/nft7.png";
import NFT8 from "../../../images/guide/nft/nft8.png";
import NFT9 from "../../../images/guide/nft/nft9.png";
import NFT10 from "../../../images/guide/nft/nft10.png";
import NFT11 from "../../../images/guide/nft/nft11.png";
import NFT12 from "../../../images/guide/nft/nft12.png";

export const SwapGuide = () => {
  return (
    <div className="mx-3">
      <p className="text-justify">
        Users can swap their telos ZPTC to Stellar ZPTC
      </p>
      
      <br />
      <h4 className="text-dark">Steps to swap Telos to Stellar</h4>
      <ul>
        <li>
        Login to Zeptagram
        
        </li>
        <li>
        Navigate to Wallets page.
        </li>
        <li>
        In Telos Wallet page, Click on Swap.
         
        </li>
        <li>
        Enter Receiver public Key and the quantity of Telos ZPTC to swap in swap modal.
        {/* <img className="guide-img guide-md" src={NFT2} /> */}
        </li>
        <li>
        Click on Swap button.
    
        </li>
        <p>Your Swap transaction will get initiated and you will receive an email message with the details.</p>
       
       
        
      </ul>
     
       
    </div>
  );
};
