import React, { useRef, useEffect } from "react";

export function useOutsideHook(ref, handleFunction, preventId) {
  useEffect(() => {
    /**
     * Alert if clicked on outside of element
     */
    const ele = document.querySelector(`#${preventId}`);
    function handleClickOutside(event) {
      if (
        ref.current &&
        !ref.current.contains(event.target) &&
        !ele.contains(event.target)
      ) {
        handleFunction();
      }
    }
    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref]);
}
