// import { ConnectedRouter } from "connected-react-router";
import React, { useState } from "react";
import { useEffect } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import AnchorIcon from "../../icons/anchor.png";
import { anchorLoginStart } from "../../redux/user/Action";
import crypto from "crypto-js";

import AppServices from "../../services/AppService";

const appServices = new AppServices();
const AnchorLogin = ({ ual, recaptchaRef }) => {
  const { anchorLoginLoading } = useSelector(
    (state) => state.user,
    shallowEqual
  );
  const state = useSelector((state) => state.user);
  const [loading, setLoading] = useState(anchorLoginLoading);
  const [walletConnected, setWalletConnected] = useState(false);
  const [walletName, setWalletName] = useState("");
  const [LoginStart, SetLoginStart] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    if (ual.activeUser) {
      setWalletName(ual.activeUser?.acountName);
      setWalletConnected(true);
    } else {
      setWalletConnected(false);
    }
  }, [ual.activeUser]);

  useEffect(() => {
    if (walletConnected && walletName !== "" && ual.activeUser) {
      getUserDetails();
      return;
    }
  }, [walletConnected, walletName]);

  const handleAnchorLogin = async () => {
    try {
      if (ual.activeUser !== null) {
        await appServices.logout(ual);
      }
      SetLoginStart(true);
      const data = await appServices.login(ual);
    } catch (error) {}
  };

  const getUserDetails = async () => {
    if (!LoginStart) return;
    const captchatoken = await recaptchaRef.current.executeAsync();
    //logic to send token to backend for validation
    const signature = ual.activeUser.signerProof.data.hexString;
    const walletName = ual.activeUser.accountName;
    const key = process.env.REACT_APP_SECRET_KEY;
    const data = {
      signature,
      walletName,
    };
    const hash = crypto
      .SHA256(JSON.stringify(data), key)
      .toString(crypto.enc.Hex);
    const payload = {
      type: null,
      data: {
        signature: signature,
        captcha: captchatoken,
        walletName: walletName,
        hash: hash,
      },
    };
    if (captchatoken) {
      dispatch(anchorLoginStart(payload));
    }
  };
  console.log("UAL FROM ANCHOR", ual);
  return (
    <div>
      {anchorLoginLoading ? (
        <span
          className="spinner-border spinner-border-smd"
          role="status"
          aria-hidden="true"
        ></span>
      ) : (
        <span onClick={handleAnchorLogin}>Connect Wallet</span>
      )}
    </div>
  );
};

export default AnchorLogin;
