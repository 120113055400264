import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import axios from "axios";
import { useSelector } from "react-redux";
import { userApis } from "../../redux/api";

const Exportpin = ({ showModal, hideModel }) =>
{
  const [loadingSwap, setLoadingSwap] = useState(false);
  const [showload, setShowLoad] = useState(false);
  const [otp, setOtp] = useState("");
  const [successMessageOTP, setSuccessMessageOTP] = useState(""); // Use a different variable for OTP sent message
  const [successMessageSubmit, setSuccessMessageSubmit] = useState(""); // Use a different variable for OTP submitted message

  const state = useSelector((state) => state);

  useEffect(() =>
  {
    if (loadingSwap)
    {
      setShowLoad(true);
    } else
    {
      setShowLoad(false);
    }
  }, [loadingSwap]);

  const sendOtp = async () =>
  {
    try
    {
      const apiUrl = userApis.RESEND_OTP_KEYEXPORT

      const token = localStorage.getItem("token");

      if (!token)
      {
        console.error("Token not found in localStorage");
        return;
      }

      const requestBody = {
        email: state.user.data.email,
      };

      const headers = new Headers({
        "x-auth-token": token,
        "Content-Type": "application/json",
      });

      const requestConfig = {
        method: "POST",
        headers,
        body: JSON.stringify(requestBody),
      };

      const response = await fetch(apiUrl, requestConfig);
      const responseData = await response.json();

      console.log("API Response:", responseData);

      if (responseData.success)
      {
        setSuccessMessageOTP("OTP sent successfully! Please check your email.");
      } else
      {
        console.error("Failed to send OTP. API Error:", responseData.error);
      }
    } catch (error)
    {
      console.error("An error occurred while sending OTP:", error);
    }
  };

  const submitOtp = async () =>
  {
    try
    {
      const apiUrl = userApis.EXPORT_PIN;

      const requestBody = {
        otp: otp,
      };

      const token = localStorage.getItem("token");

      console.log("REQUEST BODY", requestBody);
      const headers = new Headers({
        "x-auth-token": token,
        "Content-Type": "application/json",
      });

      const requestConfig = {
        method: "POST",
        headers,
        body: JSON.stringify(requestBody),
      };

      console.log("REQUEST CONFIG", requestConfig);
      const response = await fetch(apiUrl, requestConfig);
      const responseData = await response.json();

      console.log("API Response:", responseData);

      if (responseData.success)
      {
        setSuccessMessageSubmit(
          "OTP submitted successfully! Please check your email for details."
        );
        setOtp(""); // Clear the OTP input
      } else
      {
        console.error("Failed to submit OTP. API Error:", responseData.error);
      }
    } catch (error)
    {
      console.error("An error occurred while submitting OTP:", error);
    }
  };

  return (
    <Modal
      size="md"
      show={showModal}
      centered
      onHide={() =>
      {
        hideModel(false);
      }}
      backdrop="static"
    >
      <div className={`${showload ? "show-load" : "hide-load"}`}>
        <div class="spinner-grow my-2" role="status">
          <span class="sr-only"></span>
        </div>
      </div>

      <Modal.Header closeButton>
        <Modal.Title className="text-dark">
          <div className="heading">
            <div className="heading-text">
              <div>Export Pin </div>
            </div>
          </div>
        </Modal.Title>
      </Modal.Header>
      {successMessageSubmit ? (
        <div className="alert alert-success">{successMessageSubmit}</div>
      ) : (
        <Modal.Body className={`${!showload ? "" : "hide-load"}`}>
          <div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <input
                type="text"
                align="left"
                className={"input-box-sm input"}
                placeholder="Enter OTP"
                value={otp}
                style={{ fontSize: "16px" }}
                onChange={(e) => setOtp(e.target.value)}
                required
              ></input>
              <button
                type="button"
                className="button-medium border-shadow cur-pointer more-button box-shadow px-0 py-3 w-50"
                onClick={sendOtp}
              >
                Send OTP
              </button>
            </div>
            {successMessageOTP && (
              <div className="alert alert-success">{successMessageOTP}</div>
            )}
            <div className="d-flex align-items-center">
              <button
                type="button"
                className="button-medium border-shadow cur-pointer more-button box-shadow px-5 py-3"
                onClick={submitOtp}
              >
                Submit
              </button>
            </div>
          </div>
        </Modal.Body>
      )}
    </Modal>
  );
};

export default Exportpin;
