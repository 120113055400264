import React, { useState } from "react";
import { useFetch } from "../../api/api_client";
import { userApis } from "../../redux/api";
import { LoadingView } from "../../components/loading_view";
import { Col, Form, Image, Row } from "react-bootstrap";
import FilterIcon from "../../icons/filter.svg";
import RefreshIcon from "../../icons/refresh.svg";
import { WidthBox } from "../../components/components";
import NoRecordTable from "../../components/NoRecordFound/NoRecordTable";

export default function MusicTable({ onSelect, refresh, setRefresh }) {
  const [musicPage, setMusicPage] = useState(1);

  const [contentType, setContentType] = useState("");
  const [musicType, setMusicType] = useState("");
  const [verified, setVerified] = useState();
  const [inCrowdsale, setInCrowdsale] = useState();
  const [showFiler, setShowFilter] = useState(false);

  const musicLimit = 6;

  const musicResponse =
    useFetch(
      `${userApis.GET_MUSIC}?page=${musicPage}&limit=${musicLimit}&contentType=${contentType}&verified=${verified}&musicType=${musicType}&inCrowdsale=${inCrowdsale}&selector=contentType musicType longName sortName verification created_at royaltyStatement  startDate endDate&selectorValue=1`,
      [
        musicPage,
        musicLimit,
        musicType,
        verified,
        refresh,
        inCrowdsale,
        contentType,
      ]
    ) || {};

  // if (musicResponse['loading'])
  //
  // if (musicResponse['error']) {
  //     //console.log(musicResponse['error'])
  //     return
  // }

  // //console.log(musicResponse)

  return (
    <>
      <div style={{ marginBottom: "16px" }}>
        <div className={"d-flex justify-content-between"}>
          <span
            onClick={() => {
              setRefresh(refresh + 1);
            }}
          >
            <h4 className="text-dark mr-2">
              Music List{" "}
              <Image src={RefreshIcon} className={"setting-icon cur-pointer"} />
            </h4>
          </span>

          <Image
            src={FilterIcon}
            onClick={() => {
              setContentType("");
              setMusicType("");
              setVerified("");
              setInCrowdsale("");

              setShowFilter(!showFiler);
            }}
            className={"setting-icon"}
          />
        </div>
        <Row hidden={!showFiler}>
          <Col xs={"6"} md={3}>
            <span>Campaign</span>
            <Form.Control
              required={true}
              onChange={(e) => {
                if (e.target.value == "Select Campaign Type") {
                  setMusicType("");
                  return;
                }
                setMusicType(e.target.value);
              }}
              name={"musicType"}
              className={"input-box-sm"}
              as="select"
            >
              <option value={null}>Select Campaign Type</option>
              <option value={"new"}>Crowdfunding</option>
              <option value={"existing"}>Crowdsale</option>
            </Form.Control>
          </Col>
          <Col xs={"6"} md={3}>
            <span>Content Type</span>
            <Form.Control
              required={true}
              onChange={(e) => {
                if (e.target.value == "Select Content Type") {
                  setContentType("");
                  return;
                }
                setContentType(e.target.value);
              }}
              //      defaultValue={editData['musicType']}
              name={"contentType"}
              className={"input-box-sm"}
              as="select"
            >
              <option value={null}>Select Content Type</option>
              <option value={"album"}>Album</option>
              <option value={"song"}>Song</option>
            </Form.Control>
          </Col>
          <Col xs={"6"} md={3}>
            <span>Verified</span>
            <Form.Control
              required={true}
              onChange={(e) => {
                if (e.target.value == "Select Status") {
                  setVerified("");
                  return;
                }

                setVerified(e.target.value);
              }}
              //      defaultValue={editData['musicType']}
              name={"verified"}
              className={"input-box-sm"}
              as="select"
            >
              <option value={null}>Select Status</option>
              <option value={true}>Yes</option>
              <option value={false}>No</option>
            </Form.Control>
          </Col>
          <Col xs={"6"} md={3}>
            <span>In CrowdSale/Crowdfund</span>
            <Form.Control
              onChange={(e) => {
                if (e.target.value == "Select Status") {
                  setInCrowdsale("");
                  return;
                }
                setInCrowdsale(e.target.value);
              }}
              required={true}
              //      defaultValue={editData['musicType']}
              name={"inCrowdsale"}
              className={"input-box-sm"}
              as="select"
            >
              <option value={null}>Select Status</option>
              <option value={true}>Yes</option>
              <option value={false}>No</option>
            </Form.Control>
          </Col>
        </Row>
      </div>

      {musicResponse["loading"] ? (
        <div style={{ height: "350px" }}>
          <LoadingView />
        </div>
      ) : musicResponse["data"] &&
        musicResponse["data"].data &&
        musicResponse["data"].data.length ? (
        <>
          <div className={"table-responsive order-table box-shadow w-100"}>
            <table className={"table table-bordered table-hover"}>
              <thead>
                <tr>
                  <th scope="col" className="text-grey font-weight-bold">
                    Content Type
                  </th>
                  <th scope="col" className="text-grey font-weight-bold">
                    Listing Type
                  </th>
                  <th scope="col" className="text-grey font-weight-bold">
                    Long Name
                  </th>
                  <th scope="col" className="text-grey font-weight-bold">
                    Verification Request
                  </th>
                  <th scope="col" className="text-grey font-weight-bold">
                    Verification Status
                  </th>
                  <th scope="col" className="text-grey font-weight-bold">
                    Created On
                  </th>
                  
                </tr>
              </thead>
              <tbody>
                {musicResponse["data"] &&
                  musicResponse["data"].data &&
                  musicResponse["data"].data.map((item, i) => {
                    return (
                      <tr
                        key={i}
                        className="cur-pointer text-dark"
                        onClick={() => {
                          onSelect(item["_id"]);
                        }}
                      > 
                        <td>{item["contentType"]}</td>
                        <td>
                          {item["musicType"] === "existing"
                            ? "Crowdsale"
                            : "Crowdfunding"}
                        </td>
                        <td
                          className="data-ellipsis"
                          title={item["longName"]}
                          style={{ maxWidth: "200px" }}
                        >
                          {item["longName"]}
                        </td>
                        <td>
                          {item["verification"].request === "notSend"
                            ? "not sent "
                            : item["verification"].request === "send"
                            ? "sent"
                            : item["verification"].request}
                        </td>
                        <td
                          style={{
                            color: item["verification"].isVerified
                              ? "#00B907"
                              : "#ff6161",
                          }}
                        >
                          {item["verification"].isVerified
                            ? "Verified"
                            : "Not Verified"}
                        </td>
                        <td>{new Date(item["created_at"]).toDateString()}</td>
                       
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          </div>
          <div className={"verified-music-nav-container"}>
            <span
              className={musicPage > 1 ? "text-hover " : "page-action-disabled"}
              onClick={() => {
                if (musicPage > 1) setMusicPage(musicPage - 1);
              }}
            >
              PREV
            </span>
            <span
              className={
                musicResponse["data"].nextPage
                  ? "page-action-enabled "
                  : "page-action-disabled"
              }
              onClick={() => {
                if (musicResponse["data"].nextPage) setMusicPage(musicPage + 1);
              }}
            >
              NEXT
            </span>
          </div>
        </>
      ) : (
        <NoRecordTable />
      )}
    </>
  );
}
