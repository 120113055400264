import React, { useState } from "react";
import { Modal, Form, Row } from "react-bootstrap";
import InfoToast from "../../components/ToastMessage/InfoToast";
import SwalSuccess from "../../components/ToastMessage/SwalSuccess";
import SwalError from "../../components/ToastMessage/SwalError";
import { getRequiredProtectedData } from "../../redux/apicalltemplate";
import { userApis } from "../../redux/api";
import PinInput from "react-pin-input";
import copyIcon from "../../icons/copy.png";
import { CopyToClipboard } from "react-copy-to-clipboard/lib/Component";
import { isValidTwitterUrl, notifySuccess } from "../../utils/utils";
import { isValidPhoneNumber } from "react-phone-number-input";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";

const PrivateKey = ({ show, setShow, setUserRefresh, userRefresh }) => {
  const [pin, setPin] = useState("");
  const [otp, setOTP] = useState("");
  const [isEmail, setOnEmail] = useState(true);
  const [pvtKey, setPvtKey] = useState();
  const [otpSent, setOTPSent] = useState(false);
  const [loading, setLoading] = useState(false);
  const [qrCode, setQrCode] = useState();
  const handleClose = () => {
    if (otp) {
      setUserRefresh(!userRefresh)
    }
    setShow(false)
  };

  const [publicKey, setPublicKey] = useState("");
  const [accountName, setAccountName] = useState("");

  const userdata = useSelector((state) => state.user.data);
  const dispatch = useDispatch();
  //handle form submit------------------------
  const handleSubmit = async (event) => {
    event.preventDefault();
    if (pin.length != 4) {
      InfoToast("Pin must be 4 characters long");
      return;
    }

    if (otpSent && otp.length != 6) {
      SwalError("Please enter valid OTP");
      return;
    }

    setLoading(true);
    let data = {};
    data.pin = pin;
    data.email = userdata.email;
    data["key_on_email"] = isEmail;
    if (otpSent) {
      data.otp = otp;
    }
    const response = await getRequiredProtectedData(
      "POST",
      `${userApis.VIEW_PRIVATE_KEY}`,
      data,
      {}
    );

    if (response && response.data && response.data.success) {
      SwalSuccess(response.data.message);
      const qrData = true;
      setPublicKey(response.data.pubKey);
      setAccountName(response.data.accountName);
      if (otpSent) {
        setQrCode(qrData);
        return;
      }
      setOTPSent(true);



      // setPvtKey(response.data.data.wif);
    }
    if (response && response.data && !response.data.success) {
      SwalError(response.data.message);
    }
    if (response && response.response && response.response.data) {
      SwalError(response.response.data.message);
    }
    setLoading(false);

  };

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title className="text-dark">Export Private Key</Modal.Title>
      </Modal.Header>
      <Form onSubmit={handleSubmit}>
        <Modal.Body className="p-0">
          {accountName && (
            <>
              <div className="text-dark f-12 text-center mt-2">
                Account Name:
              </div>
              <div className="text-dark f-18 text-center ">{accountName}</div>
            </>
          )}
          {publicKey && (
            <>
              <div className="text-dark f-12 text-center mt-2">Public Key:</div>
              <div className="text-dark f-14 text-center mb-3">
                {publicKey}{" "}
              </div>
            </>
          )}

          {qrCode == undefined ? (
            <>
              {
                <p style={{ marginLeft: "16px", marginRight: "16px" }}>
                  Please ensure that the below email is correct, If not please
                  raise a{" "}
                  <a href="https://app.zeptagram.com/support">ticket here</a> or
                  email us at{" "}
                  <a href="mailto:info@zeptagram.com">info@zeptagram.com</a>
                </p>
              }

              <div className="flex-column mt-2">
                <div>
                  <label className="text-dark" style={{ marginLeft: "16px" }}>
                    Email*
                  </label>
                  <div style={{ marginLeft: "16px", marginRight: "16px" }}>
                    <input
                      className={"input-box-sm"}
                      type="text"
                      placeholder="Email"
                      name="email"
                      disabled={true}
                      value={userdata.email}
                      onChange={(e) => {
                        //setEmail(e.target.value)
                      }}
                    />
                  </div>

                  <label className="text-dark" style={{ marginLeft: "16px" }}>
                    Enter Pin*
                  </label>
                  <div style={{ marginLeft: "16px", marginRight: "16px" }}>
                    <PinInput
                      className="mx-auto"
                      length={4}
                      initialValue={pin}
                      secret
                      disabled={otpSent}
                      onChange={(value, index) => setPin(value)}
                      type="numeric"
                      inputMode="number"
                      style={{ padding: "10px", marginLeft: "auto" }}
                      inputStyle={{ borderColor: "#daca9e" }}
                      inputFocusStyle={{ borderColor: "blue" }}
                      onComplete={(value, index) => { }}
                      required
                    />
                  </div>

                  {otpSent && (
                    <label className="text-dark" style={{ marginLeft: "16px" }}>
                      Enter OTP*
                    </label>
                  )}

                  {otpSent && (
                    <div style={{ marginLeft: "16px", marginRight: "16px" }}>
                      <PinInput
                        className="mx-auto"
                        length={6}
                        secret
                        onChange={(value, index) => setOTP(value)}
                        type="numeric"
                        inputMode="number"
                        style={{ padding: "10px", marginLeft: "auto" }}
                        inputStyle={{ borderColor: "#daca9e" }}
                        inputFocusStyle={{ borderColor: "blue" }}
                        onComplete={(value, index) => { setOTP(value) }}
                        required
                      />
                    </div>
                  )}

                  {/* <div
                    style={{
                      marginLeft: "16px",
                      marginRight: "15px",
                      marginTop: "10px",
                    }}
                  >
                    <Form.Check
                      type="checkbox"
                      defaultChecked={true}
                      onChange={(ev) => {
                        setOnEmail(ev.target.checked);
                      }}
                      label={`Get Private key on Email`}
                    />
                  </div> */}

                  <p
                    style={{
                      marginLeft: "16px",
                      marginRight: "15px",
                      color: "#f00",
                      marginTop: "30px",
                    }}
                  >
                    NOTE : This is a one-time action, After 1 successful attempt
                    you will not be able to get the private key.
                  </p>
                </div>
              </div>
            </>
          ) : (
            <>
              <Row style={{ height: "auto", width: "130px", width: "100%" }}>
                <div className="text-center text-dark">
                  Private Key has been sent to your registered email. Please use
                  wallet pin to open the pdf.
                </div>
                <p
                  style={{
                    marginLeft: "16px",
                    marginRight: "15px",
                    color: "#f00",
                    marginTop: "30px",
                  }}
                >
                  NOTE : This is a one-time action, After 1 successful attempt
                  you will not be able to get the private key.
                </p>
              </Row>
            </>
          )}
        </Modal.Body>
        <Modal.Footer className="p-3">
          {!qrCode && (
            <button
              className="button-medium border-shadow medium-btn"
              type="submit"
              title={"Submit"}
            >
              {!loading ? (
                otpSent ? (
                  "Verify OTP"
                ) : (
                  "Send OTP"
                )
              ) : (
                <>
                  <span
                    className="spinner-border spinner-border-smd"
                    role="status"
                    aria-hidden="true"
                  ></span>
                </>
              )}
            </button>
          )}
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

export default PrivateKey;
