import React from "react";
  
import { Modal } from "react-bootstrap";
 
export const NoticePopup = ({show,setShow,message,handleClose,handleOk}) => {
 
  return (
    <div>
      {(
        <Modal backdrop="static" size="lg" show={show} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>General Notifications</Modal.Title>
          </Modal.Header>

          <Modal.Body className="p-2">
            <div className=" m-2 mb-5 justify-center">
          
              <div
                className="text-dark mt-2 text-justify"
                style={{ fontWeight: "500" }}
              >
                {message}
              </div>
          
            </div>
          </Modal.Body>
          <Modal.Footer className="p-3">
            <button
              onClick={handleOk}
              className="mx-auto button-medium border-shadow  small-btn"
            >
              Ok
            </button>
          </Modal.Footer>
        </Modal>
      )}
    </div>
  );
};
