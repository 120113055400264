import { Modal, Form } from "react-bootstrap";
import { SubmitButton } from "../../components/components";
import PinInput from "react-pin-input";
import { useEffect } from "react";
import { useState } from "react";
import { useSubmit } from "../../api/api_client";
import { userApis } from "../../redux/api";

import SwalError from "../../components/ToastMessage/SwalError";
import { LoadingView } from "../../components/loading_view";

const PaymentDialog = ({ show, setShow }) => {
  const [link, setLink] = useState("");
  const [loader, setLoader] = useState(false);
  useEffect(() => {
    load();
  }, []);

  const load = () => {
    setLoader(true);
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useSubmit(userApis.PAYMENT_DETAIL)
      .then((data) => {
        setLoader(false);
        setLink(data["widgetLink"]);
      })
      .catch((response) => {
        if (response && response.message) {
          SwalError(response.message);
        }
        setLoader(false);
      });
  };

  //show={pageState["pinModal"]}
  return (
    <Modal
      show={show}
      onHide={() => {
        setShow();
      }}
      size="xl"
    >
      <Modal.Header closeButton>
        <Modal.Title>Add Payment Methods</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {loader && (
          <div style={{ height: "500px" }}>
            <LoadingView />
          </div>
        )}
        {link && <iframe width="100%" height="500px" src={link} />}
      </Modal.Body>
    </Modal>
  );
};

export default PaymentDialog;
