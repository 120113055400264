import React from 'react';
import Girl from "../../images/lady.svg";


export const MusicCard = () => {
    return (
        <div className='d-flex flex-wrap align-center box-shadow py-3 border-radius-2' >
            {
                Array.from({ length: 12 }).map((el) => <div style={{ width: '230px' }} className='box-shadow border-radius-2 m-2 scale'>
                    <img className='border-radius-2' style={{ width: '100%' }} src={Girl} alt='music ' />
                    <div className='p-3' style={{position:'relative'}}>
                        <div className='text-dark f-14'>Content Type</div>
                        <div className='text-dark f-14'>Listing Type</div>
                        <div className='text-dark f-14'>Music Name</div>
                        <div className='text-dark f-12'>Total Share</div>
                        <div className='  heart'>

                        <svg width="32" height="29" viewBox="0 0 32 29"
                            fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M16 28.4948C31.583 16.2315 30.9971 11.8964 30.9971 8.49857C30.9971 5.10078 28.1852 1 23.4986 1C18.812 1 16 5.99905 16 5.99905C16 5.99905 13.188 1 8.50143 1C3.81483 1 1.00286 5.10078 1.00286 8.49857C1.00286 11.8964 0.41704 16.2315 16 28.4948Z" fill="white" stroke="black" stroke-width="0.15" />
                        </svg>
                            </div>
                       
                    </div>
                </div>)
            }

        </div>

    )
}