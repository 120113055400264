import React, { useState } from "react";
import Zeptagram from "../../images/zeptagram.svg";
import Band from "../../images/band.svg";
import Girl from "../../images/lady.svg";
import Neon from "../../images/neonbrand.svg";
import { useDispatch, useSelector } from "react-redux";
import {
  forgotPasswordStart,
  verifyforgotPasswordStart,
} from "../../redux/user/Action";
import VisibleEye from "../../components/VisibleEye/VisibleEye";
import { useHistory } from "react-router";
import GoogleCaptcha from "../../components/GoogleCaptcha/Captcha";
import { notifyError } from "../../utils/utils";

const VerifyForgetPassword = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [visible, setVisibility] = useState(false);
  const [otp, setOtp] = useState("");
  const dispatch = useDispatch();
  const recaptchaRef = React.useRef();

  const history = useHistory();
  const loading = useSelector((state) => state.user.verifypasswordloading);
  const handleSubmit = async (e) => {
    e.preventDefault();
    const captchatoken = await recaptchaRef.current.executeAsync();
    if (!captchatoken) {
      return;
    }
    let passwordregex =
      /^(?=.*\d)(?=.*[!@#$%^&?*()])(?=.*[a-z])(?=.*[A-Z]).{8,}$/;
    if (!password.match(passwordregex)) {
      notifyError(
        "Password should be minimum 8 character long, contain at least one lowercase letter, one uppercase letter, one numeric digit, and one special character. ex: Test@1234"
      );
      return;
    }
    if (otp.length !== 6) {
      notifyError("Please Enter Valid OTP");
      return;
    }
    if (!email) {
      notifyError("Please Enter Valid Email");
      return;
    }
    const data = {
      email: email,
      newPassword: password,
      otp: otp,
      captcha: captchatoken,
    };

    dispatch(verifyforgotPasswordStart(data));
  };

  const handleResendOtp = async () => {
    if (email) {
      const captchatoken = await recaptchaRef.current.executeAsync();
      if (!captchatoken) {
        return;
      }
      const data = {
        email: email,
        captcha: captchatoken,
      };
      dispatch(forgotPasswordStart(data));
    } else {
      history.push("/forgetpassword");
    }
  };

  React.useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const email = params.get("email");
    setEmail(email);
  }, []);

  return (
    <div className="row m-auto h-100 w-100">
      <div className="col-md-6 ">
        <div onClick={() => history.push("/login")}>
          <img src={Zeptagram} alt="" />
        </div>
        <div className="align-center h-84">
          <div className="d-flex flex-column justify-content-center">
            <form className="d-flex flex-column" onSubmit={handleSubmit}>
              <h4 className="text-dark">Verify Forgot Password</h4>
              <div className="text-grey w-500 mb-2">
                Please Check your linked email for OTP.
              </div>
              <div className="label-txt"> Email</div>
              <input
                type="email"
                className="input-box my-1 align-self-start"
                onChange={(e) => setEmail(e.target.value)}
                placeholder="Email"
                required
                value={email}
              />
              <div className="label-txt">New Password</div>
              <div className="eye-cont ">
                <input
                  type={`${!visible ? "password" : "text"}`}
                  value={password}
                  className="input-box my-1 "
                  onChange={(e) => setPassword(e.target.value)}
                  placeholder="New Password"
                  required
                />
                <VisibleEye visible={visible} setVisibility={setVisibility} />
              </div>
              <div className="label-txt">OTP</div>
              <input
                type="number"
                value={otp}
                className="input-box my-1 align-self-start"
                onChange={(e) => setOtp(e.target.value)}
                placeholder="OTP"
                required
              />

              <div className="d-flex align-items-center">
                <button
                  className="button-medium border-shadow  medium-btn"
                  type="submit"
                >
                  {!loading ? (
                    "Submit"
                  ) : (
                    <>
                      <span
                        className="spinner-border spinner-border-smd"
                        role="status"
                        aria-hidden="true"
                      ></span>
                    </>
                  )}
                </button>
                <button
                  className="button-medium btn-grey border-shadow m-2"
                  type="button"
                  onClick={() => history.push("/login")}
                >
                  Cancel
                </button>
              </div>
              <div className="text-dark w-500 mt-2 text-center">
                Didn't Receive OTP ?
              </div>
              <button
                type="button"
                className="mx-auto f-14  text-hover font-weight-bold cur-pointer btn-def"
                onClick={handleResendOtp}
              >
                Resend OTP
              </button>
              {/* <div className='text-grey mt-3'>Click on Submit and we will sent you an email to your registered mail.</div> */}
            </form>
          </div>
        </div>
        <GoogleCaptcha recaptchaRef={recaptchaRef} />
      </div>
      <div className="col-md-6 image-container">
        <span className="verify-image verify-image-1">
          <img src={Neon} alt="" />
        </span>
        <span className="verify-image verify-image-2">
          <img src={Band} alt="" />
        </span>
        <div className="verify-image verify-image-3">
          <img height={380} className="girl-img" src={Girl} alt="" />
        </div>
      </div>
    </div>
  );
};

export default VerifyForgetPassword;
