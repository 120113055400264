import React from "react";
import Img1 from "../../../images/guide/image39.png";
import Img2 from "../../../images/guide/image14.png";
import ReactPlayer from "react-player";
export const TradingGuide = () => {
  return (
    <div className="mx-3">
      <p className="text-justify">
        Zeptagram is also a trading platform for investors. Here prices of MTO
        go up and down. Hence being an investor you get the best benefit of buy
        and share and develop a great trading portfolio on Zeptagram. On this
        page you can see the daily up and down of live data against MTOs in
        “Trading Graph”. Hence you can pick and choose from the listing under
        ”Trading list”.
      </p>
      <b className="text-center d-block">MTO - Music Tokens</b>
      <br />
      <h4 className="text-dark"> Buy/Sell MTO</h4>
      <ul>
        <li>
          <b>MTO Name</b>: It will be automatically selected once you choose a
          particular MTO from the MTO list.
        </li>
        <li>
          <b>Type</b>: This will always be Buy in this case
        </li>
        <li>
          <b>Price (Per MTO)</b>: It is the price ($) in which you want to buy
          the MTO.
        </li>
        <li>
          <b>Quantity</b>: It is the amount of MTO you want to sell
        </li>
        <li>
          <b>Total ($)</b>: The total amount you will have to spend
        </li>
        <p>Finally you need to authenticate the transaction with your PIN</p>
      </ul>
      <p className="text-justify">
        Now you can check your complete list of MTOs in the trading list with
        live figures everyday.
      </p>
      <div className="text-center" >
      <div className="text-dark">Trading Order using Anchor</div>
      <div className="align-center"  >
      <ReactPlayer 
      controls url={'https://www.youtube.com/watch?v=vOK3_WjGrvE'}
          />
      </div>
      </div>



      <h5 className="text-dark">Buy Order</h5>
      <img className="guide-img guide-md" src={Img1} />
      <h5 className="text-dark">Sell Order</h5>
      <img className="guide-img guide-md" src={Img2} />
      <p className="text-justify">
        Fill the amount of MTOs you want to sell/buy and click on submit
      </p>
      <br />
      <p className="text-justify">
        Both Buy and Sell order value can be prefilled based on what MTO you
        have selected.You just have to enter the Quantity and in to proceed with
        the trading.
      </p>
    </div>
  );
};
