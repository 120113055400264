import React, { useState, useEffect } from "react";
import copyIcon from "../../icons/copy.png";
import moment from "moment";
import { CopyToClipboard } from "react-copy-to-clipboard/lib/Component";
import { toast } from "react-toastify";
import { SuccessToast } from "../../components/ToastMessage/SuccessToast";
import { getZeptaPrice } from "./data";
import { shortAddress } from "../../utils/utils";

export const ListView = ({
  txn,
  id,
  setRefresh,
  refresh,
  per_percent_amt,
  softCapReached,
}) => {
  const [price, setPrice] = useState(0.5);

  useEffect(async () => {
    let priceNew = 0;
    if (txn.txnData.zeptaPrice && txn.txnData.zeptaPrice !== "") {
      priceNew = Number(txn.txnData.zeptaPrice);
    } else {
      let time = txn.txnData && moment(txn.blockTime).format("DD-MM-YYYY");
      priceNew = await getZeptaPrice(time);
    }
    setPrice(priceNew);
  }, [txn]);
  useEffect(() => {
    setRefresh(!refresh);
  }, [price]);

  // const DollarToZPTC = (price, rate) => {
  //   const priceInCents = price * 100;
  //   const ZPTCPerCent = 1 / priceInCents;
  //   const rateInCent = rate * 100;

  //   return (ZPTCPerCent * rateInCent).toFixed(2);
  // };
  // const calculateTxnrights = (per_percent_amt,amount, price) => {
  //   const amt_usd = Number((amount * price).toFixed(2));
  //   const rights = Number((Number(amt_usd)/Number(per_percent_amt)).toFixed(4));
  //   return rights
  // };

  return (
    <tr>
      <td>
        <span>
          <span className={`txn-zptc-${id}`}>{txn.txnData.amount} ZPTC</span> |
          ${" "}
          <span className={`txn-usd-${id}`}>
            {" "}
            {txn.txnData.zeptaPrice ? (
              <span>
                {Number(
                  (txn.txnData.zeptaPrice * txn.txnData.amount).toFixed(2)
                )}
              </span>
            ) : (
              <span>{price && (txn.txnData.amount * Number).toFixed(2)}</span>
            )}{" "}
          </span>
        </span>
      </td>
      <td>
        {" "}
        {txn.txnData && moment(txn.blockTime).format("DD MMM YYYY hh:mm:ss A")}
      </td>

      <td>
        {" "}
        <span
          className="text-hover"
          onClick={() => {
            const baseurl =
              process.env.REACT_APP_ENV == "development"
                ? "telostest"
                : "telos";
            const url = `https://eosauthority.com/account?account=${txn.txnData.from}&network=${baseurl}`;
            window.open(url, "_blank");
            //  onSelect(item['_id'])
          }}
        >
          {txn.txnData && txn.txnData.from}
        </span>
      </td>

      <td>
        {" "}
        <span
          className="text-hover"
          onClick={() => {
            const baseurl =
              process.env.REACT_APP_ENV == "development"
                ? "telostest"
                : "telos";

            const url = `https://eosauthority.com/account?account=${txn.txnData.to}&network=${baseurl}`;
            window.open(url, "_blank");
            //  onSelect(item['_id'])
          }}
        >
          {" "}
          {txn.txnData && txn.txnData.to}
        </span>{" "}
      </td>

      {/* <td>
      {price && txn.txnData.amount && calculateTxnrights(per_percent_amt,txn.txnData.amount, price)}{" "}%
      {" "}
      {softCapReached ? (
        <span>

        </span>
      ) : (
        <span>
          (Allocation Pending)
        </span>
      )}{" "}
      </td> */}

      <td>
        {" "}
        <span>
          <span
            className="text-hover"
            onClick={() => {
              const baseurl =
                process.env.REACT_APP_ENV == "development"
                  ? "telostest"
                  : "telos";
              const url = `https://eosauthority.com/transaction/${txn.txnId}?network=${baseurl}`;
              window.open(url, "_blank");
              //  onSelect(item['_id'])
            }}
          >
            {shortAddress(txn.txnId)}
          </span>
          <span>
            <CopyToClipboard
              text={txn.txnId}
              onCopy={() => {
                toast.success(<SuccessToast message={"Copied!"} />, {
                  toastId: 1,
                  updateId: 1,
                });
              }}
            >
              <img
                src={copyIcon}
                width={"17px"}
                className={"mx-2 cur-pointer"}
              />
            </CopyToClipboard>
          </span>
        </span>
      </td>
    </tr>
  );
};
