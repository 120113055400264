import React, { useEffect, useState } from "react";
import {
  Route,
  Switch,
  Redirect,
  useHistory,
  useLocation,
} from "react-router-dom";
import ForgetPassword from "../containers/ForgetPassword/ForgetPassword";
import Home from "../containers/Home/Home";
import Login from "../containers/Login/Login";
import ResendOtp from "../containers/ResendOTP/ResendOtp";
import Signup from "../containers/Signup/Signup";
import VerifyEmail from "../containers/VerifyEmail/VerifyEmail";
import VerifyForgetPassword from "../containers/VerifyForgetPassword/VerifyForgetPassword";
import ProfilePage from "../containers/profile/profile";
import WalletPage from "../containers/wallet/wallet";
import CrowdDetails from "../containers/HomePage/CrowdDetails";
import PrivacyPage from "../containers/privacy_page";
import TradePage from "../containers/Trade/TradePage";
import Verify2fa from "../containers/TwoFAPage/TwofaPage";
import EscterPayScreen from "../components/EscterPay/EscterPay";
import Reset2fa from "../containers/Reset2faPage/Reset2fa";
import VerifyReset2fa from "../containers/Reset2faPage/VerifyReset2fa";
import FaqPage from "../containers/faq/faq_page";
import TwoFactorAuth from "../containers/TwoFactorAuth/TwoFactorAuth";
import MyCrowdsale from "../containers/MyCrowdsale/MyCrowdsale";
import MyMusic from "../containers/mymusic/my_music";
import Support from "../containers/support/Support";
import Chat from "../containers/support/Chat";
import SecurityPage from "../containers/2fa/security_page";
import TermsCondition from "../containers/TermsCondtion/Terms";
import AboutUs from "../containers/AboutUs/Aboutus";
import ThankyouAver from "../containers/ThankyouAver/ThankyouAver";
import Portfolio from "../containers/Portfolio/Portfolio";
import LinkedDevices from "../containers/LinkedDevices/LinkedDevices";
import VerifyDevice from "../containers/DeviceVerify/DeviceVerify";
import { shallowEqual, useSelector } from "react-redux";
import Header from "../components/Header/Header";
import AnchorWallet from "../containers/AnchorWallet/AnchorWallet";
import ProfileRegistration from "../containers/AnchorWallet/ProfileRegistration";
import AppServices from "../services/AppService";
import Guide from "../containers/Guide/Guide";
import MusicNFT from "../containers/NFT_Components/MusicNFT";
import GenerateKey from "../containers/GenerateKey/GenerateKey";
import Swap from "../containers/Swap/Swap";
import StellarMobileView from "../containers/StellarMobileView/StellarMobileView";
import ArtistPage from "../containers/Artist/Artist";
import Bookmarks from "../containers/Bookmark/Bookmark";
import ProfileRegistrationLobstr from "../containers/AnchorWallet/ProfileRegistrationLobstr";
import ProfileRegistrationFreighter from "../containers/AnchorWallet/ProfileRegistrationFreighter";

const appService = new AppServices();
export const Routes = (props) => {
  const { cartdata } = useSelector((state) => state.crowdsale);
  const [showHeader, setShowHeader] = useState(true);

  const location = useLocation();

  useEffect(() => {
    if (
      [
        "/login",
        "/signup",
        "/generatekey",
        "/forgetpassword",
        "/verifypassword",
        "/resendotp",
        "/reset2fa",
        "/verifyreset2fa",
        "/thank-you",
        "/verify2fa",
      ].find((el) => el == location.pathname)
    ) {
      setShowHeader(false);
    } else {
      setShowHeader(true);
    }
  }, [location.pathname]);

  return (
    <>
      {showHeader && <Header ual={props.ual} />}
      <Switch>
        <Route
          exact
          path="/stellarMobileView"
          render={() => <StellarMobileView />}
        />
        <Route
          exact
          path="/login"
          render={() =>
            !localStorage.getItem("token") ? (
              <Login {...props} />
            ) : (
              <Redirect to="/home" />
            )
          }
        />
        {/* <Route
          exact
          path="/home"
          render={() =>
            localStorage.getItem("token") ? (
              <Home {...props} />
            ) : (
              <Redirect to="/login" />
            )
          }
        /> */}
        <Route exact path="/home" render={() => <Home {...props} />} />
        <Route
          exact
          path="/profile"
          render={() =>
            localStorage.getItem("token") ? (
              <ProfilePage {...props} />
            ) : (
              <Redirect to="/login" />
            )
          }
        />
        <Route
          exact
          path="/trade"
          render={() =>
            localStorage.getItem("token") ? (
              <TradePage {...props} />
            ) : (
              <Redirect to="/login" />
            )
          }
        />
        {!(appService.isLogin() || props.ual.activeUser) ? (
          <Route
            exact
            path="/wallet"
            render={() =>
              localStorage.getItem("token") ? (
                <WalletPage {...props} />
              ) : (
                <Redirect to="/login" />
              )
            }
          />
        ) : (
          <Route
            exact
            path="/wallet"
            render={() =>
              localStorage.getItem("token") ? (
                <AnchorWallet {...props} />
              ) : (
                <Redirect to="/login" />
              )
            }
          />
        )}
        <Route
          exact
          path="/security"
          render={() =>
            localStorage.getItem("token") ? (
              <SecurityPage {...props} />
            ) : (
              <Redirect to="/login" />
            )
          }
        />
        <Route
          exact
          path="/music-nfts/:id"
          render={() =>
            localStorage.getItem("token") ? (
              <MusicNFT {...props} />
            ) : (
              <Redirect to="/login" />
            )
          }
        />
        TERMS_AND_CONDITION
        <Route path={`/privacy`} component={PrivacyPage} />
        {/*  

        <Route
          exact
          path="/artist"
          render={() =>
            localStorage.getItem("token") ? (
              <ArtistPage {...props} />
            ) : (
              <Redirect to="/login" />
            )
          }
        /> */}
        <Route path={`/faq`} component={FaqPage} />
        <Route path={`/generatekey`} component={GenerateKey} />
        <Route path={`/terms`} component={TermsCondition} />
        <Route path={`/aboutus`} component={AboutUs} />
        <Route path={`/home/:id`} render={() => <CrowdDetails {...props} />} />
        {/* <Route exact path="/signup" render={(props) => <Signup {...props} />} /> */}
        <Route
          exact
          path="/verify-email"
          render={() => <VerifyEmail {...props} />}
        />
        <Route
          exact
          path="/verify-device"
          render={() =>
            localStorage.getItem("token") ? (
              <Redirect to="/login" />
            ) : (
              <VerifyDevice {...props} />
            )
          }
        />
        <Route
          exact
          path="/verify2fa"
          render={() =>
            localStorage.getItem("token") ? (
              <Redirect to="/login" />
            ) : (
              <Verify2fa {...props} />
            )
          }
        />
        <Route
          exact
          path="/payment/:id/:crowdsaleid/:amount"
          render={() =>
            localStorage.getItem("token") ? (
              <EscterPayScreen {...props} cartdata={cartdata} />
            ) : (
              <Redirect to="/login" />
            )
          }
        />
        <Route
          exact
          path="/music"
          render={() =>
            localStorage.getItem("token") ? (
              <MyMusic {...props} />
            ) : (
              <Redirect to="/login" />
            )
          }
        />
        <Route
          exact
          path="/portfolio"
          render={() =>
            localStorage.getItem("token") ? (
              <Portfolio {...props} />
            ) : (
              <Redirect to="/login" />
            )
          }
        />
        <Route
          exact
          path="/devices"
          render={() =>
            localStorage.getItem("token") ? (
              <LinkedDevices {...props} />
            ) : (
              <Redirect to="/login" />
            )
          }
        />
        <Route
          exact
          path="/bookmark"
          render={() =>
            localStorage.getItem("token") ? (
              <Bookmarks {...props} />
            ) : (
              <Redirect to="/login" />
            )
          }
        />
        <Route exact path="/forgetpassword" component={ForgetPassword} />
        <Route exact path="/verifypassword" component={VerifyForgetPassword} />
        <Route exact path="/resendotp" component={ResendOtp} />
        <Route exact path="/reset2fa" component={Reset2fa} />
        <Route exact path="/verifyreset2fa" component={VerifyReset2fa} />
        <Route exact path="/thank-you" component={ThankyouAver} />
        {/* <Route exact path="/2fa" component={TwoFactorAuth} /> */}
        <Route
          exact
          path="/mycrowdsale"
          render={() =>
            localStorage.getItem("token") ? (
              <MyCrowdsale {...props} />
            ) : (
              <Redirect to="/login" />
            )
          }
        />
        <Route
          exact
          path="/support"
          render={() =>
            localStorage.getItem("token") ? (
              <Support {...props} />
            ) : (
              <Redirect to="/login" />
            )
          }
        />
        {/* SWAP */}
        <Route
          exact
          path="/swap"
          render={() =>
            localStorage.getItem("token") ? (
              <Swap {...props} />
            ) : (
              <Redirect to="/login" />
            )
          }
        />
        {/* SWAP */}
        <Route
          exact
          path="/chat/:id"
          render={() =>
            localStorage.getItem("token") ? (
              <Chat {...props} />
            ) : (
              <Redirect to="/login" />
            )
          }
        />
        <Route
          exact
          path="/guide"
          render={(props) =>
            localStorage.getItem("token") ? (
              <Guide {...props} />
            ) : (
              <Redirect to="/login" />
            )
          }
        />
        <Route
          exact
          path="/profileRegistration/:id"
          render={() =>
            appService.isLogin() ? (
              <ProfileRegistration {...props} />
            ) : (
              <Redirect to="/login" />
            )
          }
        ></Route>
        <Route
          exact
          path="/profileRegistrationLobstr"
          render={(props) => <ProfileRegistrationLobstr {...props} />}
        />
         <Route
          exact
          path="/profileRegistrationFreighter"
          render={(props) => <ProfileRegistrationFreighter {...props} />}
        />
        
        <Route path="/">
          <Redirect to="/home" />
        </Route>
        
       
      </Switch>
    </>
  );
};
