import React from "react";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Girl from "../../images/lady.svg";
import Neon from "../../images/neonbrand.svg";
import Jazz from "../../images/jazz.png";
import Glass from "../../images/glass.svg";
import Rock from "../../images/rock.png";
import InvestCrowdsale from "../../components/Invest/Invest";
import CrowdsaleDetails from "../../components/CrowdsaleDetails/CrowdsaleDetails";
import { useRouteMatch, Route, useHistory } from "react-router-dom";
import CrowdCardMedium from "../../components/CrowdCards/CrowdCardMedium";
import CrowdCardSmall from "../../components/CrowdCards/CrowdCardSmall";
import CrowdCardLarge from "../../components/CrowdCards/CrowdCardLarge";
import NoRecordTable from "../../components/NoRecordFound/NoRecordTable";
import AppServices from "../../services/AppService";
import AnchorInvestModal from "../AnchorWallet/AnchorInvestModal";
import AmlDialog from "../profile/amlDialog";
import { useGET } from "../../api/api_client";
import { userApis } from "../../redux/api";
import { CarouselHome } from "./Carousel";
const appServices = new AppServices();

const HomePage = ({ ual }) => {
  const [show, setShow] = useState(false);
  const [showcrowdsale, setShowCrowdsale] = useState(false);
  const [crowdsaledata, setCrowdsaleData] = useState(false);
  const history = useHistory();
  const userDetails = useSelector((state) => state.user.data);
  const [text, setText] = useState("Crowdsale");
  const {
    popularcrowdsale,
    livecrowdfund,
    livecrowdsale,
    liveprice,
    successfulcrowdsale,
    finalizedcrowdsale,
    finalizedcrowdfund,
    recommendcrowdsale,
  } = useSelector((state) => state.crowdsale);
  const genre = [Jazz, Glass, Rock, Jazz, Jazz, Glass, Rock, Jazz];
  const userdata = useSelector((state) => state.user.data);
  const bookmarks=useSelector(state=>state.user.bookmarks)

  const handleInvest = (el, type) => {
    setCrowdsaleData(el);
    if (type == "crowdfund") {
      setText("Crowdfund");
    } else {
      setText("Crowdsale");
    }
    setShow(true);
  };

  useEffect(() => {
    if (userDetails && userDetails?.walletCreated) {
      const pinChanged = userDetails?.wallet_pin_changed;

      if (pinChanged == false) {
        history.push("/profile");
      }
    }
  }, [userDetails]);

  const handleCrowdsaleDetails = (el) => {
    setCrowdsaleData(el);
    setShowCrowdsale(true);
  };

  return (
    <>
     <CarouselHome />
     <div className="container ">
     
      <div className="home-header row " style={{ minHeight: "400px" }}>
        <div className="h4 text-dark">Popular Offerings</div>
        {show && !(appServices.isLogin() || ual?.activeUser) && (
          <InvestCrowdsale
            show={show}
            setShow={setShow}
            text={text}
            liveprice={liveprice}
            crowdsaledata={crowdsaledata}
          />
        )}
       
        {show && (appServices.isLogin() || ual?.activeUser) && (
          <AnchorInvestModal
            show={show}
            setShow={setShow}
            text={text}
            liveprice={liveprice}
            crowdsaledata={crowdsaledata}
            ual={ual}
          />
        )}

        {showcrowdsale && (
          <CrowdsaleDetails
            show={showcrowdsale}
            setShow={setShowCrowdsale}
            data={crowdsaledata}
            liveprice={liveprice}
          />
        )}

        <div className="d-flex align-items-center justify-content-around flex-wrap">
          {popularcrowdsale &&
          popularcrowdsale.data &&
          popularcrowdsale.data.length ? (
            popularcrowdsale.data
              .filter((el, i) => i < 4)
              .map((el, i) => {
                return (
                  <CrowdCardMedium
                    key={i}
                    el={el}
                    liveprice={liveprice}
                    handleCrowdsaleDetails={handleCrowdsaleDetails}
                    handleInvest={handleInvest}
                    userid={userdata && userdata._id}
                    isBookmark={!!bookmarks.find(ml=>ml.crowdsaleId==el._id)}
                  />
                );
              })
          ) : (
            <div className="align-center w-100 " style={{ minHeight: "400px" }}>
              <NoRecordTable />
            </div>
          )}
        </div>
        {popularcrowdsale &&
          popularcrowdsale.data &&
          popularcrowdsale.data.length > 4 && (
            <div
              className="text-center button-medium border-shadow cur-pointer more-button ml-auto"
              onClick={() => history.push(`/home/popularcrowdsale`)}
            >
              More
            </div>
          )}
      </div>
      <div className="row" style={{ minHeight: "500px" }}>
        <div className="col-sm-7">
          <div className="h4 text-dark">Our Recommendation</div>
          <div className="d-flex flex-wrap">
            {recommendcrowdsale &&
            recommendcrowdsale.data &&
            recommendcrowdsale.data.length ? (
              recommendcrowdsale.data
                .filter((el, i) => i < 2)
                .map((el, i) => {
                  return (
                    <CrowdCardLarge
                      key={i}
                      el={el}
                      liveprice={liveprice}
                      handleCrowdsaleDetails={handleCrowdsaleDetails}
                      handleInvest={handleInvest}
                      userid={userdata && userdata._id}
                      isBookmark={!!bookmarks.find(ml=>ml.crowdsaleId==el._id)}
                    />
                  );
                })
            ) : (
              <div
                className="align-center w-100 "
                style={{ minHeight: "400px" }}
              >
                <NoRecordTable />
              </div>
            )}
          </div>
        </div>
        <div className="col-sm-5">
          <h4 className="text-dark text-center">You may also like</h4>
          <div className="d-flex justify-content-center align-items-center flex-column">
            {recommendcrowdsale &&
            recommendcrowdsale.data &&
            recommendcrowdsale.data.filter((el, i) => i >= 2 && i < 6) &&
            recommendcrowdsale.data.filter((el, i) => i >= 2 && i < 6)
              .length ? (
              recommendcrowdsale.data
                .filter((el, i) => i >= 2 && i < 6)
                .map((el, i) => {
                  return (
                    <CrowdCardSmall
                      key={i}
                      el={el}
                      liveprice={liveprice}
                      handleCrowdsaleDetails={handleCrowdsaleDetails}
                      handleInvest={handleInvest}
                    />
                  );
                })
            ) : (
              <NoRecordTable />
            )}
          </div>
        </div>
      </div>
      <div className="home-header row " style={{ minHeight: "400px" }}>
        <div className="h4 text-dark">Live Crowdsale Campaigns</div>
        <div className="d-flex align-items-center justify-content-around flex-wrap">
          {livecrowdsale && livecrowdsale.data && livecrowdsale.data.length ? (
            livecrowdsale.data
              .filter((el, i) => i < 4)
              .map((el, i) => {
                return (
                  <CrowdCardMedium
                    key={i}
                    el={el}
                    liveprice={liveprice}
                    handleCrowdsaleDetails={handleCrowdsaleDetails}
                    handleInvest={handleInvest}
                    type={"crowdsale"}
                    userid={userdata && userdata._id}
                    isBookmark={!!bookmarks.find(ml=>ml.crowdsaleId==el._id)}
                  />
                );
              })
          ) : (
            <div className="align-center w-100 " style={{ minHeight: "400px" }}>
              <NoRecordTable />
            </div>
          )}
        </div>
        {livecrowdsale && livecrowdsale.data && livecrowdsale.data.length > 4 && (
          <div
            className="text-center button-medium border-shadow cur-pointer more-button ml-auto"
            onClick={() => history.push(`/home/crowdsale`)}
          >
            More
          </div>
        )}
      </div>

      {/* successfull campaign */}
      <div className="home-header row " style={{ minHeight: "400px" }}>
        <div className="h4 text-dark">Successful Campaigns</div>
        <div className="d-flex align-items-center justify-content-around flex-wrap">
          {successfulcrowdsale &&
          successfulcrowdsale.data &&
          successfulcrowdsale.data.length ? (
            successfulcrowdsale.data
              .filter((el, i) => i < 4)
              .map((el, i) => {
                return (
                  <CrowdCardMedium
                    key={i}
                    el={el}
                    liveprice={liveprice}
                    handleCrowdsaleDetails={handleCrowdsaleDetails}
                    handleInvest={handleInvest}
                    type={"crowdfund"}
                    userid={userdata && userdata._id}
                    isBookmark={!!bookmarks.find(ml=>ml.crowdsaleId==el._id)}
                  />
                );
              })
          ) : (
            <div className="align-center w-100 " style={{ minHeight: "400px" }}>
              <NoRecordTable />
            </div>
          )}
        </div>
        {successfulcrowdsale &&
          successfulcrowdsale.data &&
          successfulcrowdsale.data.length > 4 && (
            <div
              className="text-center button-medium border-shadow cur-pointer  more-button ml-auto"
              onClick={() => history.push(`/home/successfulcrowdsale`)}
            >
              More
            </div>
          )}
      </div>
      {/* Live Crowdfunding Campaigns */}
      {livecrowdfund && livecrowdfund.data && livecrowdfund.data.length ? (
        <div className="home-header row " style={{ minHeight: "400px" }}>
          <div className="h4 text-dark">Live Crowdfunding Campaigns</div>
          <div className="d-flex align-items-center justify-content-around flex-wrap">
            {livecrowdfund &&
              livecrowdfund.data &&
              livecrowdfund.data
                .filter((el, i) => i < 4)
                .map((el, i) => {
                  return (
                    <CrowdCardMedium
                      key={i}
                      el={el}
                      liveprice={liveprice}
                      handleCrowdsaleDetails={handleCrowdsaleDetails}
                      handleInvest={handleInvest}
                      type={"crowdfund"}
                      userid={userdata && userdata._id}
                      isBookmark={!!bookmarks.find(ml=>ml.crowdsaleId==el._id)}
                    />
                  );
                })}
          </div>
          {livecrowdfund &&
            livecrowdfund.data &&
            livecrowdfund.data.length > 4 && (
              <div
                className="text-center button-medium border-shadow cur-pointer  more-button ml-auto"
                onClick={() => history.push(`/home/crowdfund`)}
              >
                More
              </div>
            )}
        </div>
      ) : (
        ""
      )}

      {/* finalized crowdsale */}
      {finalizedcrowdsale &&
      finalizedcrowdsale.data &&
      finalizedcrowdsale.data.length ? (
        <div className="home-header row " style={{ minHeight: "400px" }}>
          <div className="h4 text-dark">Upcoming Crowdsale Campaigns</div>
          <div className="d-flex align-items-center justify-content-around flex-wrap">
            {finalizedcrowdsale &&
              finalizedcrowdsale.data &&
              finalizedcrowdsale.data
                .filter((el, i) => i < 4)
                .map((el, i) => {
                  return (
                    <CrowdCardMedium
                      key={i}
                      el={el}
                      liveprice={liveprice}
                      handleCrowdsaleDetails={handleCrowdsaleDetails}
                      handleInvest={handleInvest}
                      type={"crowdsale"}
                      userid={userdata && userdata._id}
                      isBookmark={!!bookmarks.find(ml=>ml.crowdsaleId==el._id)}
                    />
                  );
                })}
          </div>
          {finalizedcrowdsale &&
            finalizedcrowdsale.data &&
            finalizedcrowdsale.data.length > 4 && (
              <div
                className="text-center button-medium border-shadow cur-pointer  more-button"
                onClick={() => history.push(`/home/upcoming`)}
              >
                More
              </div>
            )}
        </div>
      ) : null}
      {/* finalized crowdfund */}
      {finalizedcrowdfund &&
      finalizedcrowdfund.data &&
      finalizedcrowdfund.data.length ? (
        <div className="home-header row " style={{ minHeight: "400px" }}>
          <div className="h4 text-dark">Upcoming Crowdfund Campaigns</div>
          <div className="d-flex align-items-center justify-content-around flex-wrap">
            {finalizedcrowdfund &&
              finalizedcrowdfund.data &&
              finalizedcrowdfund.data
                .filter((el, i) => i < 4)
                .map((el, i) => {
                  return (
                    <CrowdCardMedium
                      key={i}
                      el={el}
                      liveprice={liveprice}
                      handleCrowdsaleDetails={handleCrowdsaleDetails}
                      handleInvest={handleInvest}
                      type={"crowdfund"}
                      userid={userdata && userdata._id}
                      isBookmark={!!bookmarks.find(ml=>ml.crowdsaleId==el._id)}
                    />
                  );
                })}
          </div>
          {finalizedcrowdfund &&
            finalizedcrowdfund.data &&
            finalizedcrowdfund.data.length > 4 && (
              <div
                className="text-center button-medium border-shadow cur-pointer  more-button"
                onClick={() => history.push(`/home/upcomingcrowdfund`)}
              >
                More
              </div>
            )}
        </div>
      ) : (
        ""
      )}
      {/* latest news and Genre */}
      {/* <div className="home-header row " style={{ minHeight: "400px" }}>
        <div className="col-md-6">
          <div className="h5 text-dark">Latest News</div>

          <div className="d-flex align-items-center justify-content-around flex-wrap">
            {Array.from({ length: 4 }).map((el, i) => {
              return (
                <div className="row w-100">
                  <div className="col-md-6">
                    <div className="d-flex p-2 box-shadow-hover">
                      <span>
                        <img className="sm-image" src={Girl} alt="Logo" />
                      </span>
                      <div className="flex-fill">
                        <div className="p-1 text-dark">Soley</div>
                        <div className="p-1">I'll drown</div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="d-flex p-2 box-shadow-hover">
                      <span>
                        <img className="sm-image" src={Neon} alt="Logo" />
                      </span>
                      <div className="flex-fill">
                        <div className="p-1 text-dark">Soley</div>
                        <div className="p-1">I'll drown</div>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
        <div className="col-md-6">
          <div className="h5 text-dark">Genres</div>
          <div className="d-flex align-items-center justify-content-around flex-wrap">
            {genre.map((el, i) => {
              return (
                <div className="d-flex p-2">
                  <span>
                    <img className="md-image" src={el} alt="Logo" />
                  </span>
                </div>
              );
            })}
          </div>
        </div>
      </div> */}
    </div>
    </>
    
  );
};

export default HomePage;
