import React, { useState } from "react";
import { Modal, Form } from "react-bootstrap";
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";
import InfoToast from "../../components/ToastMessage/InfoToast";
import { getRequiredProtectedData } from "../../redux/apicalltemplate";
import { crowdsaleApis } from "../../redux/api";
import { useEffect } from "react";
import SwalSuccess from "../../components/ToastMessage/SwalSuccess";
import SwalError from "../../components/ToastMessage/SwalError";
import moment from "moment";
import InfoBox from "../../components/InfoMessage/InfoHover";

const AddCrowdsale = ({
  show,
  setShow,
  musicId,
  editdata,
  isEdit = false,
  type = "Crowdsale",
}) => {
  const [startdate, setStartDate] = useState(new Date());
  const [enddate, setEndDate] = useState(new Date());
  const [minContribution, setMinContribution] = useState("");
  const [percentright, setPercentRights] = useState("");
  const [targetamt, setTargetAmt] = useState("");
  const [maxContribution, setMaxContribution] = useState("");
  const [deployedIn, setDeployedIn] = useState("USD");
  const [showdeploy, setShowDeploy] = useState(true);
  const [rDuration, setRDuration] = useState("");
  const [terms, setTerms] = useState(false);
  const [loading, setLoading] = useState(false);
  const handleClose = () => setShow(false);

  useEffect(() => {
    if (isEdit && editdata) {
      setMinContribution(editdata.minContributionByUser);
      setPercentRights(editdata.percentageRights);
      setRDuration(editdata.rDuration);
      setTargetAmt(editdata.targetAmountByUser);
      if (editdata.deployedIn) {
        setDeployedIn(editdata.deployedIn);
      } else {
        setShowDeploy(false);
      }

      setStartDate(new Date(editdata.startDate));
      setEndDate(new Date(editdata.endDate));
    }
  }, [editdata]);

  //handle crowdsale----------------------
  const handleOrder = async (event) => {
    event.preventDefault();

    let data = {};
    data.percentageRights = percentright;
    data.targetAmount = targetamt;
    data.minContribution = minContribution;
    if (!!maxContribution) {
      data.maxContribution = maxContribution;
    } else {
      data.maxContribution = targetamt;
    }
    data.rDuration = rDuration;

    data.startDate = moment(startdate).startOf("day");
    data.endDate = moment(enddate).endOf("day");

    if (showdeploy) {
      data.deployedIn = deployedIn;
    }

    if (targetamt < 500) {
      InfoToast("Target Amount must be greater than or equal to $500");
    } else if (targetamt < maxContribution && !!maxContribution) {
      InfoToast(
        "Target Amount must be greater than or equal to Max-contribution"
      );
    } else if (percentright <= 0) {
      InfoToast(`Percentage rights should be equal or greater than 1`);
    } else if (minContribution < 10) {
      InfoToast("Min-contribution must be greater than or equal to $10");
    } else if (minContribution > maxContribution && !!maxContribution) {
      InfoToast("Min-contribution must be less than Max-contribution");
    } else if (Number(minContribution) > Number(targetamt)) {
      InfoToast("Min-contribution must be less than or equal to Target Amount");
    } else if (targetamt > 5000000) {
      InfoToast("Target amount should be less than or equal $5000000");
    } else if (minContribution > 5000000) {
      InfoToast("Min-contribution should be less than or equal $5000000");
    } else if (percentright > 100) {
      InfoToast(`Percentage rights should be not be greater than 100`);
    } else if (rDuration < 5) {
      InfoToast(`Royalty duration must be greater than equal to 5 years`);
    } else if (rDuration > 99) {
      InfoToast(`Royalty duration must be less than equal to 99 years`);
    } else if (startdate >= enddate) {
      InfoToast("End-date must be greater than start-date");
    } else if (!terms) {
      InfoToast("Please accept the terms");
    } else if (
      moment(startdate).format("YYYY-MM-DD") <
      moment(new Date()).format("YYYY-MM-DD")
    ) {
      InfoToast("Start date must be greater than or equal to present date");
    } else {
      setLoading(true);
      const response = await getRequiredProtectedData(
        isEdit ? "PUT" : "POST",
        `${crowdsaleApis.CREATE_CROWDSALE}/${musicId}`,
        data,
        {}
      );
      if (response && response.data && response.data.success) {
        SwalSuccess(response.data.message);
        setTimeout(() => {
          setShow(false);
          window.location.reload();
        }, 800);
      }
      if (response && response.data && !response.data.success) {
        SwalError(response.data.message);
        setLoading(false);
      }
      if (response && response.response && response.response.data) {
        SwalError(response.response.data.message);
        setLoading(false);
      }
    }
  };

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title className="text-center">
          {" "}
          {isEdit ? "Edit" : "Add"} {type ? type : "Crowdsale"}
        </Modal.Title>
      </Modal.Header>
      <Form onSubmit={handleOrder}>
        <Modal.Body className="p-5">
          <label className="text-dark" style={{ marginLeft: "10px" }}>
            Target Amount($)*
            <InfoBox
              className="ml-2"
              msg={
                "The total amount that you want to raise against music rights. It should be minimum $500.00"
              }
            />
          </label>
          <Form.Control
            className={"input-box-sm"}
            type="number"
            name="targetamount"
            value={targetamt}
            onWheel={(e) => e.target.blur()}
            onChange={(e) => setTargetAmt(e.target.value)}
            placeholder="Targeted Amount"
          />
          <label className="text-dark" style={{ marginLeft: "10px" }}>
            Percentage Rights to List(%)*
            <InfoBox
              className="ml-2"
              msg={
                "This is percentage of rights that you want to offer to raise funds."
              }
            />
          </label>
          <Form.Control
            className={"input-box-sm"}
            type="number"
            min={1}
            max={100}
            value={percentright}
            onWheel={(e) => e.target.blur()}
            onChange={(e) => setPercentRights(e.target.value)}
            placeholder="Percentage Rights to List"
          />
          <label className="text-dark" style={{ marginLeft: "10px" }}>
            Min Contribution($)*
            <InfoBox
              className="ml-2"
              msg={
                "This is the minimum investment amount that you accept from the investor. Ex: If you set the minimum contribution as $10.00  then investor can’t invest less than $10.00 in your music."
              }
            />
          </label>
          <Form.Control
            className={"input-box-sm"}
            value={minContribution}
            onChange={(e) => setMinContribution(e.target.value)}
            type="number"
            required
            min={10}
            onWheel={(e) => e.target.blur()}
            placeholder="Minimum Contribution"
          />

          <label className="text-dark" style={{ marginLeft: "10px" }}>
            Royalty Duration(in years)*
            <InfoBox
              className="ml-2"
              msg={
                "The duration of years you want your investors to receive royalties from their investment. Once the Royalty term ends the ownerships rights will be returned to you. It should be minimum 5 year."
              }
            />
          </label>
          <Form.Control
            className={"input-box-sm"}
            type="number"
            placeholder="Royalty Duration"
            value={rDuration}
            onWheel={(e) => e.target.blur()}
            onChange={(e) => setRDuration(e.target.value)}
            min={5}
            max={99}
          />
          {/* {showdeploy ? (
            <>
              <label className="text-dark" style={{ marginLeft: "10px" }}>
                Deploy In*
              </label>
              <InfoBox
                msg={
                  "How to pay for buying NFT ? USD , ZPTC * Please note - USD payments also facilitate payments in ZPTC based on real-time price of ZPTC in USD."
                }
              />
              <Form.Control
                required={true}
                onChange={(e) => setDeployedIn(e.target.value)}
                className={"input-box-sm"}
                as="select"
                id="select-deployedin"
                value={deployedIn}
              >
                <option value={"ZPTC"}>ZPTC</option>
                <option value={"USD"}>USD</option>
              </Form.Control>
            </>
          ) : null} */}

          <div className="row">
            <div className="col-md-6">
              <label className="text-dark" style={{ marginLeft: "10px" }}>
                Start Date*
                <InfoBox
                  className="ml-2"
                  msg={
                    "The date  crowdsale will start and investor can start investing."
                  }
                />
              </label>
              <DatePicker
                selected={startdate}
                onChange={(date) => setStartDate(date)}
                placeholderText={"Start Date"}
                dateFormat="MMMM d, yyyy "
              />
            </div>
            <div className="col-md-6">
              <label className="text-dark" style={{ marginLeft: "10px" }}>
                End Date*
                <InfoBox
                  className="ml-2"
                  msg={
                    "The date your crowdsale will end and investor can’t invest further."
                  }
                />
              </label>
              <DatePicker
                selected={enddate}
                onChange={(date) => setEndDate(date)}
                placeholderText={"End Date"}
                dateFormat="MMMM d, yyyy "
              />
            </div>
          </div>

          <Form.Check
            type="checkbox"
            onClick={(e) => setTerms(e.target.checked)}
            label={
              <span style={{ fontSize: "13px" }}>
                The target amount includes 6% VAT and you are responsible for
                declaring it to your tax agency.
              </span>
            }
            required
          />
        </Modal.Body>
        <Modal.Footer className="p-3">
          {!loading ? (
            <button
              className="button-medium border-shadow  medium-btn"
              type="submit"
              disabled={loading}
              title={"Submit"}
            >
              Submit
            </button>
          ) : (
            <span
              className="spinner-border spinner-border-smd"
              role="status"
              aria-hidden="true"
            ></span>
          )}
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

export default AddCrowdsale;
