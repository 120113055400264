import { userActionTypes } from "./Types";

export const loginStart = (data) => ({
  type: userActionTypes.LOGIN_START,
  payload: data,
});

export const loginSuccess = (data) => ({
  type: userActionTypes.LOGIN_SUCCESS,
  payload: data,
});

export const loginFailure = (error) => ({
  type: userActionTypes.LOGIN_FAILURE,
  payload: error,
});

export const signupStart = (data) => ({
  type: userActionTypes.SIGNUP_START,
  payload: data,
});

export const signupSuccess = (data) => ({
  type: userActionTypes.SIGNUP_SUCCESS,
  payload: data,
});

export const signupFailure = (error) => ({
  type: userActionTypes.SIGNUP_FAILURE,
  payload: error,
});

export const verifyEmailStart = (data) => ({
  type: userActionTypes.VERIFY_EMAIL_START,
  payload: data,
});

export const verifyEmailSuccess = (data) => ({
  type: userActionTypes.VERIFY_EMAIL_SUCCESS,
  payload: data,
});

export const verifyEmailFailure = (error) => ({
  type: userActionTypes.VERIFY_EMAIL_FAILURE,
  payload: error,
});

export const forgotPasswordStart = (data) => ({
  type: userActionTypes.FORGOT_PASSWORD_START,
  payload: data,
});

export const forgotPasswordSuccess = (data) => ({
  type: userActionTypes.FORGOT_PASSWORD_SUCCESS,
  payload: data,
});

export const forgotPasswordFailure = (error) => ({
  type: userActionTypes.FORGOT_PASSWORD_FAILURE,
  payload: error,
});

export const verifyforgotPasswordStart = (data) => ({
  type: userActionTypes.VERIFY_FORGOT_PASSWORD_START,
  payload: data,
});

export const verifyforgotPasswordSuccess = (data) => ({
  type: userActionTypes.VERIFY_FORGOT_PASSWORD_SUCCESS,
  payload: data,
});

export const verifyforgotPasswordFailure = (error) => ({
  type: userActionTypes.VERIFY_FORGOT_PASSWORD_FAILURE,
  payload: error,
});

export const resendOtpStart = (data) => ({
  type: userActionTypes.RESEND_OTP_START,
  payload: data,
});

export const resendOtpSuccess = (data) => ({
  type: userActionTypes.RESEND_OTP_SUCCESS,
  payload: data,
});

export const resendOtpFailure = (error) => ({
  type: userActionTypes.RESEND_OTP_FAILURE,
  payload: error,
});

export const userDetailsStart = (data) => ({
  type: userActionTypes.USER_DETAIL_START,
  payload: data,
});

export const userDetailsSuccess = (data) => ({
  type: userActionTypes.USER_DETAIL_SUCCESS,
  payload: data,
});

export const userDetailsFailure = (error) => ({
  type: userActionTypes.USER_DETAIL_FAILURE,
  payload: error,
});

export const bookmarkStart = (data) => ({
  type: userActionTypes.BOOKMARK_START,
  payload: data,
});

export const bookmarkSuccess = (data) => ({
  type: userActionTypes.BOOKMARK_SUCCESS,
  payload: data,
});

export const bookmarkFailure = (error) => ({
  type: userActionTypes.BOOKMARK_FAILURE,
  payload: error,
});

export const verifyReset2faStart = (data) => ({
  type: userActionTypes.VERIFY_RESET_2FA_START,
  payload: data,
});

export const verifyReset2faSuccess = (data) => ({
  type: userActionTypes.VERIFY_RESET_2FA_SUCCESS,
  payload: data,
});

export const verifyReset2faFailure = (error) => ({
  type: userActionTypes.VERIFY_RESET_2FA_FAILURE,
  payload: error,
});

export const reset2faStart = (data) => ({
  type: userActionTypes.RESET_2FA_START,
  payload: data,
});

export const reset2faSuccess = (data) => ({
  type: userActionTypes.RESET_2FA_SUCCESS,
  payload: data,
});

export const reset2faFailure = (error) => ({
  type: userActionTypes.RESET_2FA_FAILURE,
  payload: error,
});

export const logout = (data) => ({
  type: userActionTypes.LOGOUT,
  payload: data,
});

export const logoutSuccess = () => ({
  type: userActionTypes.LOGOUT_SUCCESS,
});

export const logoutFailure = (error) => ({
  type: userActionTypes.LOGOUT_FAILURE,
  payload: error,
});

export const changePasswordStart = (data) => ({
  type: userActionTypes.PASSWORD_CHANGE,
  payload: data,
});

export const passwordChanged = (data) => ({
  type: userActionTypes.PASSWORD_CHANGE_SUCCESS,
  payload: data,
});

export const passwordChangeFailed = (error) => ({
  type: userActionTypes.PASSWORD_CHANGE_FAILURE,
  payload: error,
});

export const ticketData = (data) => ({
  type: userActionTypes.TICKET_DATA,
  payload: data,
});

export const anchorLoginStart = (data) => ({
  type: userActionTypes.ANCHOR_LOGIN_START,
  payload: data,
});

export const anchorLoginSuccess = (data) => ({
  type: userActionTypes.ANCHOR_LOGIN_SUCCESS,
  payload: data,
});

export const anchorSignupStart = (data) => ({
  type: userActionTypes.ANCHOR_SIGNUP_START,
  payload: data,
});

export const lobstrLoginStart = (data) => ({
  type: userActionTypes.LOBSTR_LOGIN_START,
  payload: data,
});

export const lobstrLoginSuccess = (data) => ({
  type: userActionTypes.LOBSTR_LOGIN_SUCCESS,
  payload: data,
});

export const lobstrSignupStart = (data) => ({
  type: userActionTypes.LOBSTR_SIGNUP_START,
  payload: data,
});

export const freighterLoginStart = (data) => ({
  type: userActionTypes.FREIGHTER_LOGIN_START,
  payload: data,
});

export const freighterLoginSuccess = (data) => ({
  type: userActionTypes.FREIGHTER_LOGIN_SUCCESS,
  payload: data,
});

export const freighterSignupStart = (data) => ({
  type: userActionTypes.FREIGHTER_SIGNUP_START,
  payload: data,
});
