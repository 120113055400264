import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import PinInput from "react-pin-input";
import VisibleEye from "../VisibleEye/VisibleEye";

const OtpInput = ({
  placeholder = "",
  name,
  onChange,
  isPassword = true,
  required,
  istrue = true,
  handleOTP,
  resendLoading,
}) => {
  const [visible, setVisible] = React.useState(false);
  const [countNum, setCountNum] = useState(60);
  const [disablebutton, setDisableButton] = useState(false);
  useEffect(() => {
    if (countNum === 0) {
      setDisableButton(false);
    }
    const timer =
      countNum > 0 && setInterval(() => setCountNum(countNum - 1), 1000);
    // localStorage.setItem('num',countNum)
    return () => clearInterval(timer);
  }, [countNum]);

  const handleSub = () => {
    handleOTP();
    setCountNum(60);
    setDisableButton(true);
  };
  return (
    <div className="eye-cont d-flex">
      {/* <input
        className={"input-box-sm"}
        type={`${!visible ? (!isPassword ? "text" : "password") : "text"}`}
        name={name}
        onChange={onChange}
        placeholder={placeholder}
        required={required ? true : false}
        autocomplete="off"
      /> */}
      <PinInput
        className="mx-auto"
        length={6}

        name={name}
        onChange={onChange}
        placeholder={placeholder}
        required={required ? true : false}
        autocomplete="off"
        // type="numeric"
        // type={`${!visible ? (!isPassword ? "text" : "password") : "text"}`}
        inputMode="number"
        style={{ padding: "10px" }}
        inputStyle={{ borderColor: "#daca9e", marginBottom: "3px" }}
        inputFocusStyle={{ borderColor: "blue" }}
        onComplete={(value, index) => { onChange(value) }}
      />
      {istrue && (
        <div>
          <button
            type='button'
            className="text-center button-medium border-shadow cur-pointer more-button f-14"
            onClick={() => handleSub()}
            disabled={disablebutton}
          >
            {!disablebutton ? (
              resendLoading ? (
                <span
                  className="spinner-border spinner-border-smd"
                  role="status"
                  aria-hidden="true"
                ></span>
              ) : (
                "Send OTP"
              )
            ) : (
              `Resend in ${countNum} sec`
            )}
          </button>
        </div>
      )}
    </div>
  );
};

export default OtpInput;
