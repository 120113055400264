import React, { Component } from "react";
import { Routes } from "./routes/Routes";
import { Anchor } from "ual-anchor";
import { UALProvider, withUAL } from "ual-reactjs-renderer";
const AppConsumer = withUAL(Routes);
const chain = {
  chainId: process.env.REACT_APP_CHAINID,
  name: "TLOS",
  rpcEndpoints: [
    {
      protocol: process.env.REACT_APP_NETWORK_PROTOCOL,
      host: process.env.REACT_APP_RPC,
      port: process.env.REACT_APP_NETWORK_PORT,
    },
  ],
};

const anchor = new Anchor([chain], {
  appName: process.env.REACT_APP_APPNAME,
});

export default class AnchorUal extends Component
{
  render()
  {
    let authenticators = [anchor];
    return (
      <UALProvider
        appName={process.env.REACT_APP_APPNAME}
        authenticators={authenticators}
        chains={[chain]}
        key={chain.chainId}
      >
        <AppConsumer />
      </UALProvider>
    );
  }
}
