import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Zeptagram from "../../images/zeptagram.svg";
import Band from "../../images/band.svg";
import Girl from "../../images/lady.svg";
import Neon from "../../images/neonbrand.svg";
import { useHistory, useParams } from "react-router";
import { anchorLoginStart, loginStart } from "../../redux/user/Action";
import GoogleCaptcha from "../../components/GoogleCaptcha/Captcha";
import LoadingOverlay from "react-loading-overlay";

import {
  getRequiredProtectedData,
  getRequiredData,
} from "../../redux/apicalltemplate";
import { userApis } from "../../redux/api";
import SwalSuccess from "../../components/ToastMessage/SwalSuccess";
import SwalError from "../../components/ToastMessage/SwalError";
import axios from "axios";
import crypto from "crypto-js";

const VerifyDevice = ({ ual }) => {
  const [otp, setOtp] = useState("");
  const [otpLoading, setOtpLoading] = useState(false);
  const history = useHistory();
  const recaptchaRef = React.useRef();
  const [loader, setLoader] = useState(false);
  const dispatch = useDispatch();
  const [googleLoading, setGoogleLoading] = useState(false);

  const {loginloading,anchorLoginLoading} = useSelector((state) => state.user);
  const handleLogin = async (e) => {
    const captchatoken = await recaptchaRef.current.executeAsync();
    if (!captchatoken) {
      return;
    }

    const payload = {
      type: null,
      data: {
        email: sessionStorage.getItem("email"),
        password: sessionStorage.getItem("pwd"),
        rememberMe: sessionStorage.getItem("rememberme"),
        captcha: captchatoken,
      },
    };
    if (
      localStorage.getItem("isGoogleLogin") ||
      localStorage.getItem("isAppleLogin")
    ) {
      let data = JSON.parse(localStorage.getItem("googleLoginData"));
      let newdata = {
        ...data,
        captcha: captchatoken,
      };
      try {
        setGoogleLoading(true);
        const responsedata = await getRequiredData(
          "POST",
          localStorage.getItem("isAppleLogin")
            ? userApis.APPLE_LOGIN
            : userApis.GOOGLE_LOGIN,
          newdata
        );

        if (responsedata && responsedata.data && !responsedata.data.success) {
          SwalError(responsedata.data.message);
        }
        setGoogleLoading(false);
        if (responsedata) {
          localStorage.setItem("token", responsedata.data.authToken);
          if (responsedata.data.success) {
            localStorage.removeItem("googleLoginData");
            history.push("/home");
          }
        }
      } catch (error) {
        setGoogleLoading(false);
        if (error && error.response && error.response.data) {
          SwalError(error.response.data.message);
        }
        if (
          error &&
          error.response &&
          error.response.data &&
          error.response.data.message == "Please enter the 2FA code."
        ) {
          history.push("/verify2fa");
          SwalError(error.response.data.message);
        }
      }
    } else {
      if (ual?.activeUser) {
        const signature =
          ual.activeUser.signerProof?.data.hexString ||
          "EA674fdDe714fd979de3EdF0F56AA9716B898e";
        const walletName = ual.activeUser.accountName;
        const key = process.env.REACT_APP_SECRET_KEY;
        const data = {
          signature,
          walletName,
        };
        const hash = crypto
          .SHA256(JSON.stringify(data), key)
          .toString(crypto.enc.Hex);
        const payload = {
          type: null,
          data: {
            signature: signature,
            captcha: captchatoken,
            walletName: walletName,
            hash: hash,
          },
        };
        if (captchatoken) {
          dispatch(anchorLoginStart(payload));
        }
        return;
      }
      dispatch(loginStart(payload));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const captchatoken = await recaptchaRef.current.executeAsync();
    if (!captchatoken) {
      return;
    }
    let reg = /^\d+$/;
    if (`${otp}`.length !== 6 || !reg.test(otp.trim())) {
      SwalError("OTP length must be 6 digits.");
      return;
    }
    const user_id = localStorage.getItem("user_id");
    const data = {
      otp: otp,
      user_id,
    };
    try {
      setLoader(true);
      const device_id = localStorage.getItem("device_id");

      const response = await axios({
        method: "PUT",
        url: `${userApis.VERIFY_DEVICE}/${device_id}`,
        data: data,
      });

      if (response && response.data) {
        SwalSuccess(response.data.message);
        handleLogin();
      }
    } catch (error) {
      if (error && error.response && error.response.data) {
        SwalError(error.response.data.message);
      }
    }

    setLoader(false);
  };

  const handleOtp = async () => {
    const captchatoken = await recaptchaRef.current.executeAsync();
    if (!captchatoken) {
      return;
    }
    try {
      setOtpLoading(true);
      const device_id = localStorage.getItem("device_id");
      const user_id = localStorage.getItem("user_id");
      const response = await axios({
        method: "PUT",
        url: `${userApis.RESEND_DEVICE_OTP}/${device_id}`,
        headers: {
          "x-auth-token": `${window.sessionStorage.getItem("token")}`,
        },
        data: {
          user_id,
        },
      });

      if (response && response.data) {
        SwalSuccess("OTP Sent to registered Email");
      }
      setOtpLoading(false);
    } catch (error) {
      if (error && error.response && error.response.data) {
        SwalError(error.response.data.message);
      }
      setOtpLoading(false);
    }
  };

  return (
    <LoadingOverlay
      className="row m-auto h-100 w-100"
      active={loginloading || googleLoading || anchorLoginLoading}
      spinner
      text="Logging in ...."
    >
      <div className="row m-auto h-100 w-100">
        <div className="col-md-6  ">
          <div onClick={() => history.push("/login")}>
            <img src={Zeptagram} alt="" />
          </div>
          <div className="align-center h-84">
            <div className="d-flex flex-column justify-content-center">
              <h4 className="text-dark">Verify your Device</h4>
              <div className="text-grey w-500">
                We will send you an OTP to verify. Once you verify your device
                you will be able to use all the Zeptagram online platform.{" "}
                <div className="mt-3 ">
                  To send OTP to registered email.
                  <button
                    disabled={otpLoading}
                    className=" btn-def text-dark cur-pointer"
                    onClick={() => handleOtp()}
                  >
                    Click here{" "}
                    {!otpLoading ? (
                      ""
                    ) : (
                      <>
                        <span
                          className="spinner-grow spinner-grow-sm"
                          role="status"
                          aria-hidden="true"
                        ></span>
                      </>
                    )}
                  </button>{" "}
                </div>
              </div>

              <form className="d-flex flex-column" onSubmit={handleSubmit}>
                <input
                  type="number"
                  minLength="6"
                  maxLength="6"
                  className="input-box my-3 align-self-start"
                  onChange={(e) => setOtp(e.target.value)}
                  placeholder="OTP"
                  required
                  value={otp}
                />
                <div className="d-flex align-items-center">
                  <button
                    className="button-medium border-shadow m-2 medium-btn"
                    type="submit"
                  >
                    {!loader ? (
                      "Verify"
                    ) : (
                      <>
                        <span
                          className="spinner-border spinner-border-smd"
                          role="status"
                          aria-hidden="true"
                        ></span>
                      </>
                    )}
                  </button>
                  <button
                    className="button-medium btn-grey border-shadow m-2"
                    type="button"
                    onClick={() => history.push("/login")}
                  >
                    Cancel
                  </button>
                </div>
              </form>

              {/* <div className="text-grey mt-3 w-500">
              Did not get an email?{" "}
              <button
                disabled={otpLoading}
                className=" btn-def text-dark cur-pointer"
                onClick={() => handleOtp()}
              >
                Click here{" "}
                {!otpLoading ? (
                  ""
                ) : (
                  <>
                    <span
                      className="spinner-grow spinner-grow-sm"
                      role="status"
                      aria-hidden="true"
                    ></span>
                  </>
                )}
              </button>{" "}
              and we will resend it to you!
            </div> */}
            </div>
          </div>
          <GoogleCaptcha recaptchaRef={recaptchaRef} />
        </div>
        <div className="col-md-6 image-container">
          <span className="verify-image verify-image-1">
            <img src={Neon} alt="" />
          </span>
          <span className="verify-image verify-image-2">
            <img src={Band} alt="" />
          </span>
          <div className="verify-image verify-image-3">
            <img height={380} className="girl-img" src={Girl} alt="" />
          </div>
        </div>
      </div>
    </LoadingOverlay>
  );
};

export default VerifyDevice;
