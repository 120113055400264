import { userActionTypes } from "./Types";

const INITIALSTATE = {
  loginloading: false,
  anchorLoginLoading: false,
  lobstrLoginLoading: false,
  freighterLoginLoading: false,
  signuploading: false,
  forgetpasswordloading: false,
  verifypasswordloading: false,
  verifyemailloading: false,
  resendotploading: false,
  loading: true,
  data: null,
  error: null,
  changePasswordLoading: false,
  passwordChanged: null,
  logout: false,
  reset2faloading: false,
  verifyreset2faloading: false,
  ticketdata: null,
  bookmarks: [],
};

export const userReducer = (state = INITIALSTATE, action) =>
{
  switch (action.type)
  {
    case userActionTypes.LOGIN_START:
      return {
        ...state,
        loginloading: true,
      };
    case userActionTypes.ANCHOR_SIGNUP_START:
    case userActionTypes.LOBSTR_SIGNUP_START:
    case userActionTypes.FREIGHTER_SIGNUP_START:
    case userActionTypes.SIGNUP_START:
      return {
        ...state,
        signuploading: true,
      };
    case userActionTypes.VERIFY_EMAIL_START:
      return {
        ...state,
        verifyemailloading: true,
      };
    case userActionTypes.FORGOT_PASSWORD_START:
      return {
        ...state,
        forgetpasswordloading: true,
      };
    case userActionTypes.VERIFY_FORGOT_PASSWORD_START:
      return {
        ...state,
        verifypasswordloading: true,
      };

    case userActionTypes.RESEND_OTP_START:
      return {
        ...state,
        resendotploading: true,
      };
    case userActionTypes.LOGOUT_SUCCESS:
    case userActionTypes.LOGOUT_FAILURE:
      localStorage.clear();
      return {
        ...state,
        logout: true,
      };

    case userActionTypes.LOGIN_FAILURE:
    case userActionTypes.SIGNUP_SUCCESS:
    case userActionTypes.SIGNUP_FAILURE:
    case userActionTypes.VERIFY_EMAIL_SUCCESS:
    case userActionTypes.VERIFY_EMAIL_FAILURE:
    case userActionTypes.FORGOT_PASSWORD_FAILURE:
    case userActionTypes.FORGOT_PASSWORD_SUCCESS:
    case userActionTypes.VERIFY_FORGOT_PASSWORD_SUCCESS:
    case userActionTypes.VERIFY_FORGOT_PASSWORD_FAILURE:
    case userActionTypes.RESEND_OTP_SUCCESS:
    case userActionTypes.RESEND_OTP_FAILURE:
    case userActionTypes.PASSWORD_CHANGE_FAILURE:
    case userActionTypes.RESET_2FA_SUCCESS:
    case userActionTypes.RESET_2FA_FAILURE:
    case userActionTypes.VERIFY_RESET_2FA_SUCCESS:
    case userActionTypes.VERIFY_RESET_2FA_FAILURE:
      return {
        ...state,
        loginloading: false,
        signuploading: false,
        forgetpasswordloading: false,
        verifypasswordloading: false,
        verifyemailloading: false,
        resendotploading: false,
        reset2faloading: false,
        verifyreset2faloading: false,
        anchorLoginLoading: false,
        lobstrLoginLoading: false,
        freighterLoginLoading: false,
      };
    case userActionTypes.LOGIN_SUCCESS:
      // localStorage.removeItem("email");
      // localStorage.removeItem("pwd");
      // localStorage.removeItem("rememberme");
      // sessionStorage.removeItem("email");
      // sessionStorage.removeItem("pwd");
      // sessionStorage.removeItem("rememberme");
      return {
        ...state,
        loginloading: false,
      };

    case userActionTypes.PASSWORD_CHANGE:
      return {
        ...state,
        loading: true,
      };

    case userActionTypes.RESET_2FA_START:
      return {
        ...state,
        reset2faloading: true,
      };
    case userActionTypes.VERIFY_RESET_2FA_START:
      return {
        ...state,
        verifyreset2faloading: true,
      };
    case userActionTypes.PASSWORD_CHANGE_SUCCESS:
      return {
        ...state,
        passwordChanged: action.payload,
      };

    case userActionTypes.USER_DETAIL_START:
      return {
        ...state,
        loading: true,
      };

    case userActionTypes.USER_DETAIL_FAILURE:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };

    case userActionTypes.USER_DETAIL_SUCCESS:
      return {
        ...state,
        data: action.payload,
        loading: false,
      };
    case userActionTypes.BOOKMARK_START:
      return {
        ...state,
        loading: true,
      };

    case userActionTypes.BOOKMARK_FAILURE:
      return {
        ...state,
        loading: false,
        bookmarks: [],
      };

    case userActionTypes.BOOKMARK_SUCCESS:
      return {
        ...state,
        bookmarks: action.payload,
        loading: false,
      };

    case userActionTypes.TICKET_DATA:
      return {
        ...state,
        ticketdata: action.payload,
      };
    case userActionTypes.ANCHOR_LOGIN_START:
      return {
        ...state,
        anchorLoginLoading: true,
      };
    case userActionTypes.ANCHOR_LOGIN_SUCCESS:
      localStorage.removeItem("email");
      localStorage.removeItem("pwd");
      localStorage.removeItem("rememberme");
      sessionStorage.removeItem("email");
      sessionStorage.removeItem("pwd");
      sessionStorage.removeItem("rememberme");
      return {
        ...state,
        anchorLoginLoading: false,
      };
    case userActionTypes.LOBSTR_LOGIN_START:
      return {
        ...state,
        lobstrLoginLoading: true,
      };
    case userActionTypes.LOBSTR_LOGIN_SUCCESS:
      localStorage.removeItem("email");
      localStorage.removeItem("pwd");
      localStorage.removeItem("rememberme");
      sessionStorage.removeItem("email");
      sessionStorage.removeItem("pwd");
      sessionStorage.removeItem("rememberme");
      return {
        ...state,
        lobstrLoginLoading: false,
      };

    case userActionTypes.FREIGHTER_LOGIN_START:
      return {
        ...state,
        freighterLoginLoading: false,
      };
    case userActionTypes.FREIGHTER_LOGIN_SUCCESS:
      localStorage.removeItem("email");
      localStorage.removeItem("pwd");
      localStorage.removeItem("rememberme");
      sessionStorage.removeItem("email");
      sessionStorage.removeItem("pwd");
      sessionStorage.removeItem("rememberme");
      return {
        ...state,
        freighterLoginLoading: false,
      };

    default:
      return { ...state };
  }
};
