import React, { useState, useEffect } from "react";
import CryptoJS from "crypto-js";
import { toast } from "react-toastify";
import { Button, Form, Modal, Spinner } from "react-bootstrap";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { lobstrLoginStart } from "../../redux/user/Action";
import crypto from "crypto-js";

import
{
  SuccessToast,
  ErrorToast,
} from "../../components/ToastMessage/SuccessToast";
import { errorMessage, stringConstants, successMessage } from "../../constants";
import StellarSdk from "stellar-sdk";
import QRCode from "qrcode.react";

var server = new StellarSdk.Server("https://horizon-testnet.stellar.org");
// const appServices = new AppServices();
const LobstrLogin = (
  { ForEncryptedKey, setZptcBalance, walletConnect, ual, recaptchaRef },
  showModal
) =>
{
  const { lobstrLoginLoading } = useSelector(
    (state) => state.user,
    shallowEqual
  );

  const dispatch = useDispatch();
  const [secretKey, setSecretKey] = useState("");
  const [Private, setPrivateKey] = useState("");
  const [loading, setLoading] = useState(false);
  const [URI, setURI] = useState();

  console.log("UAL", ual);
  // localStorage.setItem("ual", JSON.stringify(ual));
  const storedPayload = localStorage.getItem("payload");
  useEffect(() =>
  {
    if (loading)
    {
      try
      {
        if (ual.activeUser !== null)
        {
          console.log("UAL ACTIVE USER", ual.activeUser);
        }
        setLoading(true);

        walletConnect.login(
          (URI) =>
          {
            setURI(URI);
          },
          async () =>
          {
            setLoading(false);
          },
          () =>
          {
            setLoading(false);
          }
        );
      } catch (error)
      {
        console.error(error); // Handle errors properly
      }
    }
    if (storedPayload)
    {
      const payload = JSON.parse(storedPayload);
      dispatch(lobstrLoginStart(payload));
    }
  }, [loading, storedPayload]);

  const resetDetails = () =>
  {
    setPrivateKey("");
    setSecretKey("");
  };

  return (
    <div>
      <div className="popup-link" onClick={() => setLoading(true)}>
        <div style={{ paddingLeft: "16px" }}> Connect Wallet</div>
      </div>

      {loading && (
        <Modal
          size="md"
          show={showModal}
          onHide={() =>
          {
            setLoading(false);
            resetDetails();
          }}
        >
          <Modal.Header closeButton>
            <Modal.Title className="text-dark launchWalletText">
              Scan QR code with Lobstr Wallet{" "}
              {window.innerWidth <= 500 && "or click on launch wallet"}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div style={{ textAlign: "center" }}>
              {URI && (
                <>
                  <QRCode value={URI} size={206} renderAs="svg" />
                </>
              )}
            </div>
          </Modal.Body>
        </Modal>
      )}
    </div>
  );
};

export default LobstrLogin;
