import React, { useState } from "react";
import { Modal, Form } from "react-bootstrap";
import InfoToast from "../../components/ToastMessage/InfoToast";
import SwalSuccess from "../../components/ToastMessage/SwalSuccess";
import SwalError from "../../components/ToastMessage/SwalError";
import { getRequiredProtectedData } from "../../redux/apicalltemplate";
import { crowdsaleApis } from "../../redux/api";

const UploadCrowdsale = ({
  show,
  setShow,
  id,
  modaldata,
  handleReloadCrowdsale,
}) => {
  const [file, setFile] = useState(null);
  const handleClose = () => setShow(false);
  const [loading, setLoading] = useState(false);

  //upload file--------------------------------------
  const handleAgreementUpload = async (event) => {
    event.preventDefault();
    var reader = new FileReader();
    const file =
      event.target &&
      event.target.files &&
      event.target.files.length &&
      event.target.files[0];
    // let parts = file.name.split(".");

    if (file) {
      const extension = file.type;
      if (extension !== "application/pdf") {
        InfoToast("Please upload file in pdf format only.");
        return;
      }
      if (file.size > 1 * 1024 * 1024) {
        InfoToast("File size should be less than 1MB");
      } else {
        reader.readAsDataURL(file);
        let data = {};
        data.file = file;
        data.field = "image";
        setFile(data);
      }
    }
  };
  //handle form submit------------------------
  const handleSubmit = async (e) => {
    e.preventDefault();
    let uploadData = new FormData();
    uploadData.append(file.field, file.file);
    setLoading(true);
    // id = "assddss";
    const response = await getRequiredProtectedData(
      "PUT",
      `${crowdsaleApis.UPLOAD_CROWDSALE}/${id}`,
      uploadData,
      {}
    );
    if (response && response.data && response.data.success) {
      SwalSuccess(response.data.message);
      setTimeout(() => {
        setShow(false);
        handleReloadCrowdsale();
      }, 800);
    }
    if (response && response.data && !response.data.success) {
      SwalError(response.data.message);
    }
    if (response && response.response && response.response.data) {
      SwalError(response.response.data.message);
    }
    setLoading(false);
  };


 

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Upload Agreement</Modal.Title>
      </Modal.Header>
      <Form onSubmit={handleSubmit}>
        <Modal.Body className="p-5">
          <div className="text-dark mb-3" style={{ fontSize: "15px" }}>
            Please upload the signed agreement. If you do not have the
            management agreement,{" "}
            <a
              href={
                modaldata.musicId && modaldata.musicId.contentType === "song"
                  ? "https://zeptagram-test2.s3.eu-north-1.amazonaws.com/maggrement-converted.pdf"
                  : "https://zeptagram-test2.s3.eu-north-1.amazonaws.com/management_agreement_isrc.pdf"
              }
              target="_blank"
              style={{
                color: "#9b9b9b",
                fontSize: "15px",
              }}
            >
              click here
            </a>{" "}
            to download.
          </div>
          <label className="text-dark" style={{ marginLeft: "10px" }}>
            UPLOAD AGREEMENT (PDF)
          </label>
          <Form.Control
            className={"input-box-sm"}
            type="file"
            name="upload"
            accept="application/pdf"
            onChange={handleAgreementUpload}
            placeholder="Upload PDF ..."
            required
          />
        </Modal.Body>
        <Modal.Footer className="p-3">
          <button
            className="button-medium border-shadow medium-btn"
            type="submit"
            disabled={loading}
            title={"Upload Agreement"}
          >
            {!loading ? (
              "Upload Agreement"
            ) : (
              <>
                <span
                  className="spinner-border spinner-border-smd"
                  role="status"
                  aria-hidden="true"
                ></span>
              </>
            )}
          </button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

export default UploadCrowdsale;
