import Swal from "sweetalert2";

const InfoToast = (message) => {
  Swal.fire({
    titleText: message,
    type: "info",
    toast: true,
    position: "top",
    icon: "info",
    showConfirmButton: false,
    timer: 5000,
    toast: true,
    timerProgressBar: true,
    background: "white",
    didOpen: (toast) => {
      toast.addEventListener("mouseenter", Swal.stopTimer);
      toast.addEventListener("mouseleave", Swal.resumeTimer);
    },
  });
};

export default InfoToast;
