import { Col, Form, Modal, Row } from "react-bootstrap";
import BootstrapSwitchButton from "bootstrap-switch-button-react";
import { HeightBox, SubmitButton, WidthBox } from "../../components/components";
import { AiOutlineCloseCircle } from "react-icons/ai";
import React, { useEffect, useRef, useState } from "react";
import { useFetch, useSubmit, useUploadKyc } from "../../api/api_client";
import { userApis } from "../../redux/api";
import { checkLength, notifyError, notifySuccess } from "../../utils/utils";
import validbarcode from "barcode-validator";
import SwalSuccess from "../../components/ToastMessage/SwalSuccess";
import InfoBox from "../../components/InfoMessage/InfoHover";
import PublisherAgreement from "../profile/PublisherAgreement";
import { StatusKyc } from "../profile/kycview";

export default function ISWCMusicDialog({
  show,
  setShow,
  albumFlag,
  editData,
  refresh,
  setRefresh,
  kycData,
  kycLoading,
  kycRefresh, 
  setKycRefresh
}) {
  var ownerRightsRef;
  var ownerPercentageRef;
  var artistNameRef;
  var contributerNameRef;
  const [gener, setGener] = useState("");
  const [revenue, setRevenue] = useState("");
  const [musicRightTemp, setRightsTemp] = useState({
    coOwnerPercentageRights: "",
    coOwnerName: "",
  });



  const [showAgreement, setAgreementModal] = useState(false);


  const kycDetails = kycData?.data?.kycDetails;


  /// composers
  const [composerlist, setComposerList] = useState([]);
  const [particularComposer, setParticularComposer] = useState("");

  //coowners
  const [coownerlist, setCoownerList] = useState([]);
  const [particularCoowner, setParticularCoowner] = useState("");

  const [coOwnersInAlbum, setOnwers] = useState([]);
  const [artists, setArtists] = useState([]);
  const [contributers, setContributers] = useState([]);
  const [artistTemp, setArtistTemp] = useState({
    artistName: "",
    artistType: "",
  });
  const [royalityUrl, setRoyalityUrl] = useState("");
  const [contributerTemp, setContributer] = useState({
    contributorName: "",
    contributorType: "",
  });
  const [newMusicSale, setMusicSaleType] = useState(true);

  const [data, setData] = useState({
    musicType: "new",
  });

  useEffect(() => {
    let existsData = {
      musicType: "new",
    };

    if (editData !== undefined && editData["_id"] !== undefined) {
      existsData = {
        aggrement: "",
        musicType: editData["musicType"],
        coOwnersInAlbum: editData["coOwnersInAlbum"],
        longName: editData["longName"],
        sortName: editData["sortName"],
        streamingAppLink: editData["streamingAppLink"],
        description: editData["description"],
        contentType: editData["contentType"],
        numberOfTrack: editData["numberOfTrack"],
        duration: editData["contentDetails"].duration,
        totalShare: editData["totalShare"],
        musicNumber: editData["musicNumber"],
        verknummer: editData["verknummer"],
        writers: editData["writers"],
        coOwners: editData["coOwners"],
        upc: editData["upc"],
        publishedYear: editData["publishedYear"],
      };
      if (editData.royaltyStatement && editData.royaltyStatement.url) {
        setRoyalityUrl(editData.royaltyStatement.url);
      }

      if (editData.writers) {
        let composers = editData.writers.split(",");
        setComposerList(composers);
      }
      if (editData.coOwners) {
        let composers = editData.coOwners.split(",");
        setCoownerList(composers);
      }

      if (editData.genres && editData.genres.length) {
        setGener(editData.genres[0]);
      }
      if (editData["musicType"] === "new") {
        setMusicSaleType(true);
      } else {
        setMusicSaleType(false);
      }
    }

    setData(existsData);
    setRevenue(editData["revenueGenerated"]);

    if (albumFlag && editData) {
      editData["coOwnersInAlbum"] && setOnwers(editData["coOwnersInAlbum"]);
      editData["artists"] && setArtists(editData["artists"]);
      editData["contributors"] && setContributers(editData["contributors"]);
    }
  }, [editData]);

  const [loading, setLoading] = useState(false);

  const handleInputChange = (e) => {
    data[e.target.name] = e.target.value;
    setData({ ...data });
  };

  const handleRemove = (data, valArray, firstField, secondField, setArr) => {
    const newArray = valArray.filter((el, i) => {
      if (el[firstField] !== data.title || el[secondField] !== data.subTitle) {
        return el;
      }
    });
    setArr(newArray);
  };

  const uploadRoyalySheet = (file) => {
    const extension = file.type;
    if (extension !== "application/pdf") {
      notifyError("Please upload file in pdf format only.");
      document.querySelector("#royality-sheet").value = null;
      setRoyalityUrl("");
      return;
    }
    if (file.size > 1 * 1024 * 1024) {
      notifyError("File size should be less than 1MB");
      document.querySelector("#royality-sheet").value = null;
      setRoyalityUrl("");
      return;
    }
    let formData = new FormData();

    formData.append("image", file); //append the values with key, value pair

    // eslint-disable-next-line react-hooks/rules-of-hooks
    useUploadKyc(userApis.KYC_IMAGE_UPLOAD, formData)
      .then((response) => {
        SwalSuccess(response.data.message);
        setRoyalityUrl(response.data.url);
      })
      .catch((e) => {
        notifyError(e.message);
      });
  };

  const handleSubmit = (e) => {
    data["contentType"] = "song";

    if (!kycDetails?.agreement?.is_submitted) {
      notifyError("Please sign the management agreement before submitting the song.");
      return;
    }

    if (
      !data["longName"] ||
      data["longName"] === null ||
      data["longName"].trim() === ""
    ) {
      notifyError("Title is required");
      return;
    }
    if (
      data["longName"] &&
      data["longName"].trim() &&
      data["longName"].length > 50
    ) {
      notifyError("Title should be less than 50 characters");
      return;
    }
    let userregex = /^[a-zA-Z0-9, ]*$/;
    if (!checkLength(data["longName"], 2)) {
      notifyError("Min length for Title is 2 characters");
      return;
    }
    if (data["longName"] && !userregex.test(data["longName"].trim())) {
      notifyError("Please Enter a valid Title.");
      return;
    }
    // if (composerlist && !albumFlag&& !composerlist.length) {
    //   notifyError("Please Enter a valid Composer.");
    //   return;
    // }

    // if (data["writers"] && data["writers"].trim().length > 50) {
    //   notifyError("Composer should be less than 50 characters");
    //   return;
    // }

    // if (coownerlist && !albumFlag&& !coownerlist.length) {
    //   notifyError("Please Enter a valid Co-Owners.");
    //   return;
    // }
    // if (data["coOwners"] && data["coOwners"].trim().length > 50) {
    //   notifyError("Co Owners should be less than 50 characters");
    //   return;
    // }

    if (!data.publishedYear || data.publishedYear === null) {
      notifyError("Published Year is required");
      return;
    }

    if (data.publishedYear && data["publishedYear"].length !== 4) {
      notifyError("Please enter a valid year for Published Year");
      return;
    }
    let currentYear = new Date().getFullYear();
    if (
      data.publishedYear &&
      (data["publishedYear"] < 1970 || data["publishedYear"] > currentYear)
    ) {
      notifyError(
        `Published year should greater or equal to 1970 and less than or equal to ${currentYear}`
      );
      return;
    }

    if (
      data.duration === undefined ||
      !/^[0-9]{2}:[0-5][0-9]$/.test(data.duration)
    ) {
      notifyError("Duration must be in MM:SS format");
      return;
    }

    if (
      !albumFlag &&
      (data.sortName === undefined || !/^[a-z][a-z1-5]+$/.test(data.sortName))
    ) {
      notifyError("Please enter a valid short name");
      return;
    }
    if (!albumFlag && data.sortName && data.sortName.length > 12) {
      notifyError("Short name length should not be greater than 12 characters");
      return;
    }
    if (
      !data["description"] ||
      data["description"] === null ||
      data["description"].trim() === ""
    ) {
      notifyError("Description is required");
      return;
    }

    if (!checkLength(data["description"], 6)) {
      notifyError("Min length for description is 6 characters");
      return;
    }
    if (
      data["description"] &&
      data["description"].trim() &&
      data["description"].length > 1000
    ) {
      notifyError("Description should be less than 1000 characters");
      return;
    }
    if (
      data["streamingAppLink"] === undefined ||
      !/^(https?|chrome):\/\/[^\s$.?#].[^\s]*$/.test(data["streamingAppLink"])
    ) {
      notifyError("Please enter a valid Media Link");
      return;
    }

    if (checkLength(data["musicTermsData"] && !data["termsAgreed"])) {
      notifyError("Please accept the terms & Conditions");
      return;
    }
    if (!data.totalShare || data.totalShare === null) {
      notifyError("Your Ownership is required");
      return;
    }
    if (!data.totalShare || data.totalShare <= 0) {
      notifyError("Your Ownership must be greater than 0 ");
      return;
    }
    if (!data.totalShare || data.totalShare > 100) {
      notifyError("Your Ownership must be equal to or lower than 100");
      return;
    }

    if (data.musicNumber === undefined || data.musicNumber === "") {
      notifyError(`${albumFlag ? "ISRC Code" : "ISWC Code"}  must be required`);
      return;
    }
    let isrcregex = /^[a-zA-Z0-9_]*$/;
    if (!isrcregex.test(data.musicNumber)) {
      notifyError(`${albumFlag ? "ISRC Code" : "ISWC Code"}  must be valid`);
      return;
    }
    if (data.musicNumber && data.musicNumber.trim().length > 30) {
      notifyError(
        `${
          albumFlag ? "ISRC Code" : "ISWC Code"
        }  must be less than or equal to 30 characters`
      );
      return;
    }

    if (data.verknummer && data.verknummer.toString().length > 15) {
      notifyError("Song ID can contain max upto 15 digits.");
      return;
    }
    // verknummer
    if (albumFlag) {
      data["contentType"] = "album";
      if (coOwnersInAlbum && coOwnersInAlbum.length === 0) {
        notifyError("Ownership Rights must be required");
        return;
      }

      if (artists && artists.length === 0) {
        notifyError("Artist must be required");
        return;
      }

      if (contributers && contributers.length === 0) {
        notifyError("Contributor must be required");
        return;
      }

      if (data.upc === undefined || data.upc === "") {
        notifyError("UPC Number must be required");
        return;
      }

      if (!validbarcode(data.upc)) {
        notifyError("UPC Number must be valid");
        return;
      }
      if (data.verknummer === undefined || data.verknummer === "") {
        notifyError("Work Id must be required");
        return;
      }
      if (data.verknummer && data.verknummer.toString().length > 15) {
        notifyError("Song ID can contain max upto 15 characters.");
        return;
      }

      if (!checkLength(gener)) {
        notifyError("Genre must be required");
        return;
      }

      // if (gener && gener.length && gener[0].length > 30) {
      //   notifyError("Genre length should not be more than 30 characters");
      //   return;
      // }

      if (albumFlag) {
        data["genres"] = [gener];
      }

      data["artists"] = artists;
      data["contributors"] = contributers;
      data["coOwnersInAlbum"] = coOwnersInAlbum;
    }
    if (!albumFlag && composerlist) {
      data["writers"] = composerlist.join(",");
    }
    if (!albumFlag && coownerlist) {
      data["coOwners"] = coownerlist.join(",");
    }
    const payload = { ...data };
    if (!newMusicSale) {
      if (!royalityUrl) {
        notifyError("Please upload royalty document again.");
        return;
      }
      payload["aggrement"] = royalityUrl;
    }

    if (!newMusicSale) {
      if (!revenue) {
        notifyError("Please enter revenue generated");
        return;
      }
      if (revenue <= 0) {
        notifyError("Revenue Generated should be greater than 0");
        return;
      }
      payload["revenueGenerated"] = revenue;
    }

    setLoading(true);

    const api =
      editData !== undefined && editData["_id"] !== undefined
        ? userApis.EDIT_MUSIC + editData["_id"]
        : userApis.ADD_MUSIC;
    const type =
      editData !== undefined && editData["_id"] !== undefined ? "PUT" : "POST";
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useSubmit(api, payload, type)
      .then((res) => {
        notifySuccess("Success");
        setShow();
        setRefresh(refresh + 1);
        setLoading(false);
      })
      .catch((e) => {
        var message = e.message;

        setLoading(false);
        if (!message) {
          message = "Something went wrong";
        }
        notifyError(message);
      });
  };

  if (editData === undefined) {
    editData = {};
  } else {
    editData["duration"] = editData["contentDetails"].duration;
  }

  return (
    <Modal
      show={show}
      backdrop="static"
      size="md"
      onHide={() => {
        setShow();
      }}
    >
      <Modal.Header closeButton>
        <Modal.Title className="text-dark">ADD SONG</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form>
          <div className="text-dark f-12 mx-2  ">Note : Please sign the Management Agreement before proceeding with Adding song. </div>
      
          <div className="mt-3 d-flex mx-2 my-1">
            <StatusKyc style={{color:'#384E66',background:'white'}} label={"Is Management Agreement Signed ?"} title={kycDetails?.agreement?.is_submitted ? 'Yes' : 'No'} />
            {kycDetails?.agreement?.is_submitted ? '' : <button className="button-medium border-shadow  medium-btn more-button" disabled={kycLoading} type='button' onClick={() => setAgreementModal(true)}>Sign here</button>}
            {showAgreement && <PublisherAgreement show={showAgreement} setShow={setAgreementModal} setKycRefresh={setKycRefresh} kycRefresh={kycRefresh} />}

          </div>
      
          {newMusicSale ? null : (
            <div>
              <label className="input-label">
                Upload Royalty Sheet(pdf)
                <InfoBox
                  className="ml-2"
                  msg={
                    "You can upload a blank pdf if you don't have a royalty sheet."
                  }
                />
              </label>
              {royalityUrl && (
                <div className="text-hover font-weight-bold f-14 text-end">
                  <a href={`${royalityUrl}`} target="_blank">
                    Current Royalty Sheet
                  </a>
                </div>
              )}

              <input
                type={"file"}
                hidden={newMusicSale}
                className={"input-box-sm"}
                multiple={false}
                id="royality-sheet"
                accept="application/pdf"
                placeholder={"Upload Royalty Sheet"}
                onChange={(event) => {
                  const file =
                    event.target &&
                    event.target.files &&
                    event.target.files.length &&
                    event.target.files[0];
                  if (file) {
                    const reader = new FileReader();
                    reader.readAsDataURL(file);
                    reader.onloadend = function (ev) {
                      uploadRoyalySheet(file);
                      //  onChange(file, reader.result)
                    };
                  }
                }}
              />
              <div className="text-dark f-12 mx-3">Accepted Size : 1 MB</div>
            </div>
          )}

          <HeightBox hidden={newMusicSale} height={"8px"} />

          <label className="input-label">
            Campaign
            <InfoBox
              className="ml-2"
              msg={
                "A crowdsale represents sales of music rights on the platform without preconditions. A crowdfunding campaign is used when selling music rights  for a specific cause, to produce an album, to do a tour or for charity donations."
              }
            />
          </label>
          <Form.Control
            required={true}
            onChange={(event) => {
              if (event.target.value === "new") {
                setMusicSaleType(true);
              } else {
                setMusicSaleType(false);
              }
              handleInputChange(event);
            }}
            value={data["musicType"]}
            name={"musicType"}
            className={"input-box-sm"}
            as="select"
          >
            <option value={"new"}>Crowdfunding</option>
            <option value={"existing"}>Crowdsale</option>
          </Form.Control>
          <HeightBox height={"8px"} />

          <label className="input-label">
            {" "}
            {albumFlag ? "ISRC Code *" : "ISWC Code *"}
            <InfoBox
              className="ml-2"
              msg={
                albumFlag
                  ? "ISRC(International Standard Recording Code) Code should be combination of alphabets & numbers"
                  : "ISWC(International Standard Musical Work Code) Code should be combination of alphabets & numbers"
              }
            />
          </label>
          <input
            className={"input-box-sm"}
            placeholder={albumFlag ? "ISRC Code" : "ISWC Code"}
            type="text"
            maxLength={30}
            value={data["musicNumber"]}
            required={true}
            name="musicNumber"
            onChange={handleInputChange}
          />
          <HeightBox height={"8px"} />

          <label className="input-label">Title*</label>
          <input
            className={"input-box-sm"}
            name={"longName"}
            maxLength={50}
            type={"text"}
            value={data["longName"]}
            required={true}
            onChange={handleInputChange}
            placeholder={"Title"}
          />

          <HeightBox height={"8px"} hidden={albumFlag} />

          <label hidden={albumFlag} className="input-label">
            Short name {!albumFlag ? "*" : ""}
            <InfoBox
              className="ml-2"
              msg={
                "It's a Blockchain identity that should be 12-digit string combination of alphabets in small letters & number between 1 to 5 only. ex- teste1111112 "
              }
            />
          </label>
          <input
            className={"input-box-sm"}
            name={"sortName"}
            type={"text"}
            value={data["sortName"]}
            hidden={albumFlag}
            maxLength={12}
            onChange={handleInputChange}
            required={!albumFlag}
            placeholder={"Short name*"}
          />

          <HeightBox height={"8px"} />
          <label className="input-label">
            Media link *
            <InfoBox
              className="ml-2"
              msg={
                "This is your associated media link. e.g.  https://www.youtube.com/watch?v=PCwL3-hkKrg"
              }
            />
          </label>
          <input
            className={"input-box-sm"}
            name={"streamingAppLink"}
            type={"text"}
            maxLength={80}
            value={data["streamingAppLink"]}
            required={data["musicType"] === "existing"}
            onChange={handleInputChange}
            placeholder={"Media link"}
          />

          <HeightBox height={"8px"} />
          <label hidden={newMusicSale} className="input-label">
            Revenue Generated* ($)
          </label>
          <input
            className={"input-box-sm"}
            name={"revenueGenerated"}
            type={"number"}
            value={revenue}
            min={0}
            onWheel={(e) => e.target.blur()}
            hidden={newMusicSale}
            onChange={(e) => setRevenue(e.target.value)}
            placeholder={"Revenue Generated* ($)"}
          />

          <HeightBox height={"8px"} />
          <label className="input-label">
            Your Ownership(%)*
            <InfoBox
              className="ml-2"
              msg={"This is how many  percentage rights you own"}
            />
          </label>
          <input
            className={"input-box-sm"}
            name={"totalShare"}
            type={"number"}
            onWheel={(e) => e.target.blur()}
            value={data["totalShare"]}
            onChange={handleInputChange}
            placeholder={"Your Ownership(%)*"}
            min={0}
            max="100"
          />

          <HeightBox height={"8px"} />
          <label className="input-label">
            Work Key(Song Id) {albumFlag ? "*" : ""}{" "}
            <InfoBox
              className="ml-2"
              msg={
                "This is the identity/unique number of your song, and issued by PRO while registering a song."
              }
            />
          </label>
          <input
            className={"input-box-sm"}
            name={"verknummer"}
            type={"number"}
            value={data["verknummer"]}
            required={true}
            onWheel={(e) => e.target.blur()}
            min={0}
            // max="100"
            onChange={handleInputChange}
            placeholder={"Work Key(Song Id)"}
          />

          <span className={"divider"} hidden={!albumFlag} />
          <HeightBox hidden={!albumFlag} height={"8px"} />
          <div hidden={!albumFlag}>
            <div className={"chip_container"}>
              {coOwnersInAlbum &&
                coOwnersInAlbum.map((item, i) => {
                  return (
                    <Chip
                      key={i}
                      title={item["coOwnerName"]}
                      subTitle={item["coOwnerPercentageRights"]}
                      firstField="coOwnerName"
                      secondField="coOwnerPercentageRights"
                      valArray={coOwnersInAlbum}
                      setArr={setOnwers}
                      handleRemove={handleRemove}
                    />
                  );
                })}
            </div>
            <Row>
              <label className="input-label">
                Ownership Rights*
                <InfoBox
                  className="ml-2"
                  msg={
                    "Name of the person who have the Ownership of the song / album"
                  }
                />
              </label>
              <Col md={6} xs={12}>
                <input
                  className={"input-box-sm"}
                  name={"coOwnerName"}
                  type={"text"}
                  ref={(rf) => (ownerRightsRef = rf)}
                  // value={musicRightTemp['coOwnerName']}
                  onChange={(event) => {
                    musicRightTemp[event.target.name] = event.target.value;
                    setRightsTemp(musicRightTemp);
                  }}
                  maxLength="80"
                  placeholder={"Ownership(Name)"}
                />
              </Col>
              <Col md={4} xs={5}>
                <input
                  className={"input-box-sm"}
                  name={"coOwnerPercentageRights"}
                  type={"number"}
                  onWheel={(e) => e.target.blur()}
                  max={100}
                  min={0}
                  maxLength={3}
                  ref={(rf) => (ownerPercentageRef = rf)}
                  //   value={musicRightTemp['coOwnerPercentageRights']}
                  onChange={(event) => {
                    musicRightTemp[event.target.name] = event.target.value;
                    setRightsTemp(musicRightTemp);
                  }}
                  placeholder={"Percentage"}
                />
              </Col>
              <Col
                md={2}
                xs={6}
                className={
                  "d-flex flex-row align-center justify-content-center"
                }
              >
                <button
                  type={"button"}
                  className={"border-shadow button-small-long"}
                  onClick={() => {
                    if (
                      !musicRightTemp["coOwnerPercentageRights"] ||
                      !musicRightTemp["coOwnerName"] ||
                      musicRightTemp["coOwnerPercentageRights"].trim() === "" ||
                      musicRightTemp["coOwnerName"].trim() === ""
                    ) {
                      notifyError("Enter all fields in Ownership Rights");
                      return;
                    }
                    if (
                      musicRightTemp["coOwnerPercentageRights"] &&
                      musicRightTemp["coOwnerPercentageRights"] <= 0
                    ) {
                      notifyError(
                        "Ownership Percentage should be greater than 0"
                      );
                      return;
                    }
                    if (
                      musicRightTemp["coOwnerPercentageRights"] &&
                      musicRightTemp["coOwnerPercentageRights"] > 100
                    ) {
                      notifyError(
                        "Ownership Percentage should not be greater than 100"
                      );
                      return;
                    }
                    let userregex = /^[a-zA-Z0-9, ]*$/;
                    if (
                      musicRightTemp["coOwnerName"] &&
                      !musicRightTemp["coOwnerName"].trim()
                      // !userregex.test(musicRightTemp["coOwnerName"].trim())
                    ) {
                      notifyError("Please Enter a valid Owner Name.");
                      return;
                    }

                    let temp = [];
                    if (coOwnersInAlbum && coOwnersInAlbum.length) {
                      temp = [
                        ...coOwnersInAlbum,
                        {
                          coOwnerPercentageRights:
                            musicRightTemp["coOwnerPercentageRights"],
                          coOwnerName: musicRightTemp["coOwnerName"],
                        },
                      ];
                    } else {
                      temp = [
                        {
                          coOwnerPercentageRights:
                            musicRightTemp["coOwnerPercentageRights"],
                          coOwnerName: musicRightTemp["coOwnerName"],
                        },
                      ];
                    }

                    setOnwers(temp);
                    musicRightTemp["coOwnerPercentageRights"] = "";
                    musicRightTemp["coOwnerName"] = "";
                    setRightsTemp(musicRightTemp);
                    ownerRightsRef.value = "";
                    ownerPercentageRef.value = "";
                  }}
                  title={"Add"}
                >
                  {" "}
                  Add
                </button>
              </Col>
            </Row>
          </div>

          <span className={"divider"} hidden={!albumFlag} />
          <HeightBox hidden={!albumFlag} height={"8px"} />

          <div hidden={!albumFlag}>
            <div className={"chip_container"}>
              {artists &&
                artists.map((item, i) => {
                  return (
                    <Chip
                      key={i}
                      title={item["artistName"]}
                      subTitle={item["artistType"]}
                      firstField="artistName"
                      secondField="artistType"
                      valArray={artists}
                      setArr={setArtists}
                      handleRemove={handleRemove}
                    />
                  );
                })}
            </div>
            <Row>
              <label className="input-label">
                Artist*
                <InfoBox className="ml-2" msg={"Artist name"} />
              </label>
              <Col sm={6}>
                <input
                  className={"input-box-sm"}
                  name={"artistName"}
                  maxLength="80"
                  type={"text"}
                  ref={(el) => (artistNameRef = el)}
                  onChange={(e) => {
                    artistTemp[e.target.name] = e.target.value;
                    setArtistTemp(artistTemp);
                  }}
                  placeholder={"Artist (Name)"}
                />
              </Col>
              <Col sm={4}>
                <Form.Control
                  placeholder={"Artist (Type)"}
                  onChange={(e) => {
                    artistTemp[e.target.name] = e.target.value;
                    setArtistTemp(artistTemp);
                  }}
                  name={"artistType"}
                  className={"input-box-sm"}
                  as="select"
                >
                  <option value={""}>Artist Type</option>
                  <option value={"primary"}>Primary</option>
                  <option value={"featured"}>Featured</option>
                </Form.Control>
                <HeightBox height={"8px"} />
              </Col>
              <Col
                sm={2}
                className={
                  "d-flex flex-row align-center justify-content-center"
                }
              >
                <button
                  type={"button"}
                  className={"border-shadow button-small-long"}
                  title={"Add"}
                  onClick={() => {
                    if (
                      !artistTemp["artistName"] ||
                      !artistTemp["artistType"] ||
                      artistTemp["artistName"].trim() === "" ||
                      artistTemp["artistType"].trim() === ""
                    ) {
                      notifyError("Enter Artist Name and Type");
                      return;
                    }

                    let userregex = /^[a-zA-Z0-9, ]*$/;
                    if (
                      artistTemp["artistName"] &&
                      !artistTemp["artistName"].trim()
                      // !userregex.test(artistTemp["artistName"].trim())
                    ) {
                      notifyError("Please Enter a valid Artist Name.");
                      return;
                    }

                    let temp = [];
                    if (artists && artists.length) {
                      temp = [
                        ...artists,
                        {
                          artistName: artistTemp["artistName"],
                          artistType: artistTemp["artistType"],
                        },
                      ];
                    } else {
                      temp = [
                        {
                          artistName: artistTemp["artistName"],
                          artistType: artistTemp["artistType"],
                        },
                      ];
                    }

                    setArtists(temp);
                    artistTemp["artistName"] = "";
                    //   artistTemp['artistType']=''
                    setArtistTemp(artistTemp);
                    artistNameRef.value = "";
                  }}
                >
                  {" "}
                  Add
                </button>
              </Col>
            </Row>
          </div>
          <span className={"divider"} hidden={!albumFlag} />
          <HeightBox hidden={!albumFlag} height={"8px"} />

          <div hidden={!albumFlag}>
            <div className={"chip_container"}>
              {contributers &&
                contributers.map((item, i) => {
                  return (
                    <Chip
                      key={i}
                      title={item["contributorName"]}
                      subTitle={item["contributorType"]}
                      firstField="contributorName"
                      secondField="contributorType"
                      valArray={contributers}
                      setArr={setContributers}
                      handleRemove={handleRemove}
                    />
                  );
                })}
            </div>

            <Row>
              <label className="input-label">
                Contributor*
                <InfoBox className="ml-2" msg={"Contributor name"} />
              </label>
              <Col sm={6}>
                <input
                  className={"input-box-sm"}
                  name={"contributorName"}
                  maxLength="80"
                  type={"text"}
                  ref={(el) => (contributerNameRef = el)}
                  onChange={(event) => {
                    contributerTemp[event.target.name] = event.target.value;
                    setContributer(contributerTemp);
                  }}
                  placeholder={"Contributor (Name)"}
                />
              </Col>
              <Col sm={4}>
                <Form.Control
                  onChange={(event) => {
                    contributerTemp[event.target.name] = event.target.value;
                    setContributer(contributerTemp);
                  }}
                  placeholder={"Contributor (Type)"}
                  name={"contributorType"}
                  className={"input-box-sm"}
                  as="select"
                >
                  <option value={""}>Contributor Type</option>
                  <option value={"composer"}>Composer</option>
                  <option value={"lyricist"}>Lyricist</option>
                  <option value={"producer"}>Producer</option>
                  <option value={"remixer"}>Remixer</option>
                </Form.Control>
                <HeightBox height={"8px"} />
              </Col>
              <Col
                sm={2}
                className={
                  "d-flex flex-row align-center justify-content-center"
                }
              >
                <button
                  type={"button"}
                  className={"border-shadow button-small-long"}
                  title={"Add"}
                  onClick={() => {
                    if (
                      !contributerTemp["contributorName"] ||
                      !contributerTemp["contributorType"] ||
                      contributerTemp["contributorName"].trim() === "" ||
                      contributerTemp["contributorType"].trim() === ""
                    ) {
                      notifyError("Enter Contributor Name and Type");
                      return;
                    }

                    let userregex = /^[a-zA-Z0-9, ]*$/;
                    if (
                      contributerTemp["contributorName"] &&
                      !contributerTemp["contributorName"].trim()
                      // !userregex.test(contributerTemp["contributorName"].trim())
                    ) {
                      notifyError("Please Enter a valid Contributor Name.");
                      return;
                    }

                    let temp = [];
                    if (contributers && contributers.length) {
                      temp = [
                        ...contributers,
                        {
                          contributorType: contributerTemp["contributorType"],
                          contributorName: contributerTemp["contributorName"],
                        },
                      ];
                    } else {
                      temp = [
                        {
                          contributorType: contributerTemp["contributorType"],
                          contributorName: contributerTemp["contributorName"],
                        },
                      ];
                    }

                    setContributers(temp);
                    //    contributerTemp['contributorType']=''
                    contributerTemp["contributorName"] = "";
                    setContributer(contributerTemp);

                    contributerNameRef.value = "";
                  }}
                >
                  Add
                </button>
              </Col>
            </Row>
          </div>

          {/* <label hidden={albumFlag} className="input-label">
            Composer
            <InfoBox className="ml-2" msg={"Name of music composer"} />
          </label>
          <input
            className={"input-box-sm"}
            name={"writers"}
            type={"text"}
            maxLength={150}
            value={data["writers"]}
            onChange={handleInputChange}
            hidden={albumFlag}
            placeholder={"Composer"}
          /> */}

          {/* --------------- composers------------------------ */}
          {albumFlag ? (
            ""
          ) : (
            <div hidden={albumFlag}>
              <div className={"chip_container"}>
                {composerlist &&
                  composerlist.map((item, i) => {
                    return (
                      <Chip
                        key={i}
                        title={item}
                        // firstField="coOwnerName"
                        valArray={composerlist}
                        setArr={setComposerList}
                        handleRemove={(el) => {
                          const newArr = composerlist.filter(
                            (ml) => ml !== el.title
                          );
                          // console.log(newArr);
                          setComposerList(newArr);
                        }}
                      />
                    );
                  })}
              </div>
              <Row>
                <label className="input-label">
                  Composers
                  <InfoBox className="ml-2" msg={"Name of music composer"} />
                </label>
                <Col md={10} xs={12}>
                  <input
                    className={"input-box-sm"}
                    name={"composers"}
                    type={"text"}
                    value={particularComposer}
                    onChange={(event) => {
                      setParticularComposer(event.target.value);
                    }}
                    maxLength="80"
                    placeholder={"Composers(Name)"}
                  />
                </Col>
                <Col
                  md={2}
                  xs={6}
                  className={
                    "d-flex flex-row align-center justify-content-center"
                  }
                >
                  <button
                    type={"button"}
                    className={"border-shadow button-small-long"}
                    onClick={() => {
                      if (!particularComposer.trim()) {
                        notifyError("Please Enter a valid Composer.");
                        return;
                      }

                      let temp = [];
                      const tempComposer = particularComposer.trim();
                      if (composerlist && composerlist.length) {
                        temp = [...composerlist, tempComposer];
                      } else {
                        temp = [tempComposer];
                      }

                      setComposerList(temp);

                      setParticularComposer("");
                    }}
                    title={"Add"}
                  >
                    {" "}
                    Add
                  </button>
                </Col>
              </Row>
            </div>
          )}

          {/* --------------------------------------- */}

          <HeightBox height={"8px"} hidden={albumFlag} />

          {/* <label hidden={albumFlag} className="input-label">
            Co-Owners
            <InfoBox className="ml-2" msg={"Name of your music co-owners"} />
          </label>
          <input
            className={"input-box-sm"}
            name={"coOwners"}
            type={"text"}
            maxLength={150}
            value={data["coOwners"]}
            hidden={albumFlag}
            onChange={handleInputChange}
            placeholder={"Co-Owners"}
          /> */}

          {/* --------------- co owners------------------------ */}
          <div hidden={albumFlag}>
            <div className={"chip_container"}>
              {coownerlist &&
                coownerlist.map((item, i) => {
                  return (
                    <Chip
                      key={i}
                      title={item}
                      // firstField="coOwnerName"
                      valArray={coownerlist}
                      setArr={setCoownerList}
                      handleRemove={(el) => {
                        const newArr = coownerlist.filter(
                          (ml) => ml !== el.title
                        );
                        setCoownerList(newArr);
                      }}
                    />
                  );
                })}
            </div>
            <Row>
              <label className="input-label">
                Co-owners
                <InfoBox
                  className="ml-2"
                  msg={"Name of your music co-owners"}
                />
              </label>
              <Col md={10} xs={12}>
                <input
                  className={"input-box-sm"}
                  name={"coowners"}
                  type={"text"}
                  value={particularCoowner}
                  onChange={(event) => {
                    setParticularCoowner(event.target.value);
                  }}
                  maxLength="80"
                  placeholder={"Coowners(Name)"}
                />
              </Col>
              <Col
                md={2}
                xs={6}
                className={
                  "d-flex flex-row align-center justify-content-center"
                }
              >
                <button
                  type={"button"}
                  className={"border-shadow button-small-long"}
                  onClick={() => {
                    if (
                      !particularCoowner.trim()
                      // !userregex.test(musicRightTemp["coOwnerName"].trim())
                    ) {
                      notifyError("Please Enter a valid Co-owner.");
                      return;
                    }

                    let temp = [];
                    const tempCoowner = particularCoowner.trim();
                    if (coownerlist && coownerlist.length) {
                      temp = [...coownerlist, tempCoowner];
                    } else {
                      temp = [tempCoowner];
                    }

                    setCoownerList(temp);

                    setParticularCoowner("");
                  }}
                  title={"Add"}
                >
                  {" "}
                  Add
                </button>
              </Col>
            </Row>
          </div>
          {/* --------------------------------------- */}

          <HeightBox height={"8px"} />
          <label hidden={!albumFlag} className="input-label">
            UPC*
            <InfoBox
              className="ml-2"
              msg={"UPC (Universal Product Code) number of the song / album"}
            />
          </label>
          <input
            className={"input-box-sm"}
            name={"upc"}
            type={"number"}
            value={data["upc"]}
            onWheel={(e) => e.target.blur()}
            hidden={!albumFlag}
            onChange={handleInputChange}
            placeholder={"UPC"}
          />

          <HeightBox height={"8px"} hidden={!albumFlag} />
          <label hidden={!albumFlag} className="input-label">
            Genres*
          </label>
          <input
            className={"input-box-sm"}
            name={"gener"}
            type={"text"}
            maxLength={100}
            defaultValue={data["gener"]}
            hidden={!albumFlag}
            onChange={(e) => {
              setGener(e.target.value);
            }}
            value={gener}
            placeholder={"Genres"}
          />

          <HeightBox height={"8px"} hidden={!albumFlag} />
          <label className="input-label">
            Published Year*
            <InfoBox
              className="ml-2"
              msg={"Publishing year of the song / album"}
            />
          </label>
          <input
            className={"input-box-sm"}
            name={"publishedYear"}
            type={"number"}
            value={data["publishedYear"]}
            required={true}
            onWheel={(e) => e.target.blur()}
            min={1970}
            onChange={handleInputChange}
            placeholder={"Published Year(YYYY)"}
          />

          <HeightBox height={"8px"} />

          <label className="input-label">
            Track Duration (MM:SS)*
            <InfoBox
              className="ml-2"
              msg={"Duration of the song in MM:SS (minutes:seconds) format"}
            />
          </label>
          <input
            className={"input-box-sm"}
            name={"duration"}
            type={"text"}
            required={true}
            value={data["duration"]}
            onChange={handleInputChange}
            placeholder={"Track Duration"}
          />
          <HeightBox height={"8px"} />

          <label className="input-label">
            Description*
            <InfoBox className="ml-2" msg={"Description of the song"} />
          </label>
          <input
            className={"input-box-sm"}
            name={"description"}
            type={"text"}
            value={data["description"]}
            required={true}
            maxLength={1000}
            onChange={handleInputChange}
            placeholder={"Description"}
          />
        </form>
      </Modal.Body>
      <Modal.Footer>
        <SubmitButton
          onClick={handleSubmit}
          loading={loading}
          title={editData["_id"] !== undefined ? "Update" : "Add"}
        />
      </Modal.Footer>
    </Modal>
  );
}

function Chip(props) {
  return (
    <div className={"chip d-flex align-items-center "}>
      <div className="wrap-word p-1 ">
        {props.title}
        {props.subTitle ? `: ${props.subTitle}` : ""}
      </div>

      <WidthBox width={"5px"} />
      <div
        style={{ display: "inline-block", marginLeft: "3px" }}
        className="cur-pointer"
      >
        <AiOutlineCloseCircle
          onClick={() =>
            props.handleRemove(
              props,
              props.valArray,
              props.firstField,
              props.secondField,
              props.setArr
            )
          }
        />
      </div>
    </div>
  );
}
