import axios from "axios";
import { httpConstants } from "../constants";
import { stellarApis } from "./api";

export const getRequiredData = async (
  method = "GET",
  api,
  data = {},
  params = {}
) => {
  const response = await axios({
    method: method,
    url: api,
    data,
    params,
  });
  return response;
};

export const getRequiredProtectedData = async (
  method = "GET",
  api,
  data = {},
  params = {}
) => {
  try {
    const response = await axios({
      method: method,
      url: api,
      headers: {
        "x-auth-token": `${window.localStorage.getItem("token")}`,
      },
      data,
      params,
    });
    return response;
  } catch (e) {
    return e;
  }
};

export const getProtectedDatawithMessage = async (
  method = "GET",
  api,
  data = {},
  params = {}
) => {
  try {
    const response = await axios({
      method: method,
      url: api,
      headers: {
        "x-auth-token": `${window.localStorage.getItem("token")}`,
      },
      data,
      params,
    });
    return response;
  } catch (e) {
    return null;
  }
};

export const getAxiosData = (method = "GET", api, data = {}, params = {}) => {
  return axios({
    method: method,
    url: api,
    headers: {
      "x-auth-token": `${window.localStorage.getItem("token")}`,
    },
    data,
    params,
  });
};

// export function getHeaders() {
//   const config = {
//     headers: {
//       "content-type": httpConstants.CONTENT_TYPE.APPLICATION_JSON,
//       "Access-Control-Allow-Origin": "*",
//       mode: "cors",
//     },
//   };
// }

// export async function generateKeypair() {
//   let url = stellarApis.GENERATE_KEYPAIR;
//   let headers = getHeaders();
//   return axios
//     .post(url, {}, headers)
//     .then((response) => {
//       return response?.data;
//     })
//     .catch((err) => {
//       console.log(err);
//     });
// }

export const swapTransaction = async (
  method = "POST",
  api,
  data = {},
  params = {},
  token
) => {
  try {
    const response = await axios({
      method: method,
      url: api,
      headers: {
        "x-auth-token": `${token || window.localStorage.getItem("token")}`,
      },
      data,
      params,
    });
    return response;
  } catch (e) {
    return null;
  }
};

export const transactionHistory = async (
  method = "GET",
  api,
  data = {},
  params = {}
) => {
  try {
    const response = await axios({
      method: method,
      url: api,
      headers: {
        "x-auth-token": `${window.localStorage.getItem("token")}`,
      },
      data,
      params,
    });
    return response;
  } catch (e) {
    return e;
  }
};
