import React from "react";
import ReactDOM from "react-dom";
import "./index.scss";
import App from "./App";
import history from "../src/utils/history";
import { ConnectedRouter } from "connected-react-router";
import { Provider } from "react-redux";
import reportWebVitals from "./reportWebVitals";
import { store, persistor } from "./redux/store";
import ErrorBoundary from "./components/error-boundary/error-boundary";
 

ReactDOM.render(
  <ErrorBoundary>
 
    <Provider store={store}>
      <ConnectedRouter history={history}>
        <App />
      </ConnectedRouter>
    </Provider>
 
  </ErrorBoundary>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(//console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
