import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { Modal } from "react-bootstrap";
import { userApis } from "../../redux/api";
import { getRequiredProtectedData } from "../../redux/apicalltemplate";

export const Notice = () => {
  const [data, setData] = useState(null);
  const [show, setShow] = useState(true);
  const handleClose = () => setShow(false);

  useEffect(async () => {
    const response = await getRequiredProtectedData(
      "GET",
      `${userApis.NOTICE}`,
      {},
      {}
    );
    if (response && response.data) {
      setData(response.data.data);
    }
  }, []);

  const handleOk = () => {
    setShow(false);
    sessionStorage.setItem("noticeviewed", true);
  };

  return (
    <div>
      {show && data && data.enabled && !sessionStorage.getItem("noticeviewed") && (
        <Modal backdrop="static" size="lg" show={show} onHide={handleClose}>
          <Modal.Header>
            <Modal.Title>Important Information</Modal.Title>
          </Modal.Header>

          <Modal.Body className="p-2">
            <div className=" m-2 mb-5 justify-center">
              {data && data.img_url && (
                <img
                  className="notice-img"
                  src={data && data.img_url}
                  alt="notice image"
                />
              )}

              <div
                className="text-dark mt-2 text-justify"
                style={{ fontWeight: "500" }}
              >
                {data && data.notice}
              </div>
              <div className="guide-body">
                <ul
                  className=" text-dark mt-2 text-justify"
                  style={{ fontWeight: "500" }}
                >
                  {data &&
                    data.list.map((el) => {
                      return (
                        <li className="d-flex">
                          <p>{el.val} </p>
                          {el.url ? (
                            <p style={{ marginLeft: "5px" }}>
                              <a href={el.url} target="_blank">
                                {" "}
                                Click here{" "}
                              </a>
                            </p>
                          ) : (
                            ""
                          )}{" "}
                        </li>
                      );
                    })}
                </ul>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer className="p-3">
            <button
              onClick={handleOk}
              className="mx-auto button-medium border-shadow  small-btn"
            >
              Ok
            </button>
          </Modal.Footer>
        </Modal>
      )}
    </div>
  );
};
