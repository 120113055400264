import React from "react";
import ReCAPTCHA from "react-google-recaptcha";

const GoogleCaptcha = ({ recaptchaRef }) => {
  return (
    <ReCAPTCHA
      ref={recaptchaRef}
      size="invisible"
      sitekey={process.env.REACT_APP_CAPTCHA_KEY}
      style={{ zIndex: 100000 }}
      tabindex="-1"
      // onChange={(e) => console.log(e)}
      // onErrored={(e) => console.log(e)}
    />
  );
};

export default GoogleCaptcha;
