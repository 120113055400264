import { crowdsaleActionTypes } from "./Types";

export const popularCrowdsaleStart = (data) => ({
  type: crowdsaleActionTypes.POPULAR_CROWDSALE_START,
  payload: data,
});

export const popularCrowdsaleSuccess = (data) => ({
  type: crowdsaleActionTypes.POPULAR_CROWDSALE_SUCCESS,
  payload: data,
});

export const popularCrowdsaleFailure = (error) => ({
  type: crowdsaleActionTypes.POPULAR_CROWDSALE_FAILURE,
  payload: error,
});

export const recommendCrowdsaleStart = (data) => ({
  type: crowdsaleActionTypes.RECOMMEND_CROWDSALE_START,
  payload: data,
});

export const recommendCrowdsaleSuccess = (data) => ({
  type: crowdsaleActionTypes.RECOMMEND_CROWDSALE_SUCCESS,
  payload: data,
});

export const recommendCrowdsaleFailure = (error) => ({
  type: crowdsaleActionTypes.RECOMMEND_CROWDSALE_FAILURE,
  payload: error,
});

export const liveCrowdsaleStart = (data) => ({
  type: crowdsaleActionTypes.LIVE_CROWDSALE_START,
  payload: data,
});

export const liveCrowdsaleSuccess = (data) => ({
  type: crowdsaleActionTypes.LIVE_CROWDSALE_SUCCESS,
  payload: data,
});

export const liveCrowdsaleFailure = (error) => ({
  type: crowdsaleActionTypes.LIVE_CROWDSALE_FAILURE,
  payload: error,
});

export const liveCrowdfundStart = (data) => ({
  type: crowdsaleActionTypes.LIVE_CROWDFUND_START,
  payload: data,
});

export const liveCrowdfundSuccess = (data) => ({
  type: crowdsaleActionTypes.LIVE_CROWDFUND_SUCCESS,
  payload: data,
});

export const liveCrowdfundFailure = (error) => ({
  type: crowdsaleActionTypes.LIVE_CROWDFUND_FAILURE,
  payload: error,
});

export const successfulCrowdsaleStart = (data) => ({
  type: crowdsaleActionTypes.SUCCESSFUL_CROWDSALE_START,
  payload: data,
});

export const successfulCrowdsaleSuccess = (data) => ({
  type: crowdsaleActionTypes.SUCCESSFUL_CROWDSALE_SUCCESS,
  payload: data,
});

export const successfulCrowdsaleFailure = (error) => ({
  type: crowdsaleActionTypes.SUCCESSFUL_CROWDSALE_FAILURE,
  payload: error,
});

export const finalizedCrowdsaleStart = (data) => ({
  type: crowdsaleActionTypes.FINALIZED_CROWDSALE_START,
  payload: data,
});

export const finalizedCrowdsaleSuccess = (data) => ({
  type: crowdsaleActionTypes.FINALIZED_CROWDSALE_SUCCESS,
  payload: data,
});

export const finalizedCrowdsaleFailure = (error) => ({
  type: crowdsaleActionTypes.FINALIZED_CROWDSALE_FAILURE,
  payload: error,
});

export const finalizedCrowdfundStart = (data) => ({
  type: crowdsaleActionTypes.FINALIZED_CROWDFUND_START,
  payload: data,
});

export const finalizedCrowdfundSuccess = (data) => ({
  type: crowdsaleActionTypes.FINALIZED_CROWDFUND_SUCCESS,
  payload: data,
});

export const finalizedCrowdfundFailure = (error) => ({
  type: crowdsaleActionTypes.FINALIZED_CROWDFUND_FAILURE,
  payload: error,
});

export const getLivePriceStart = (data) => ({
  type: crowdsaleActionTypes.GET_LIVE_PRICE_START,
  payload: data,
});

export const getLivePriceSuccess = (data) => ({
  type: crowdsaleActionTypes.GET_LIVE_PRICE_SUCCESS,
  payload: data,
});

export const getLivePriceFailure = (error) => ({
  type: crowdsaleActionTypes.GET_LIVE_PRICE_FAILURE,
  payload: error,
});

export const investCrowdsaleStart = (data) => ({
  type: crowdsaleActionTypes.INVEST_CROWDSALE_START,
  payload: data,
});

export const investCrowdsaleSuccess = (data) => ({
  type: crowdsaleActionTypes.INVEST_CROWDSALE_SUCCESS,
  payload: data,
});

export const investCrowdsaleFailure = (error) => ({
  type: crowdsaleActionTypes.INVEST_CROWDSALE_FAILURE,
  payload: error,
});

export const investCrowdsaleConfirmStart = (data) => ({
  type: crowdsaleActionTypes.INVEST_CROWDSALE_CONFIRM_START,
  payload: data,
});

export const investCrowdsaleConfirmSuccess = (data) => ({
  type: crowdsaleActionTypes.INVEST_CROWDSALE_CONFIRM_SUCCESS,
  payload: data,
});

export const investCrowdsaleConfirmFailure = (error) => ({
  type: crowdsaleActionTypes.INVEST_CROWDSALE_CONFIRM_FAILURE,
  payload: error,
});
