import { useFetch } from "../../api/api_client";
import { userApis } from "../../redux/api";
import { LoadingView } from "../../components/loading_view";
import React from "react";
import NoRecordTable from "../../components/NoRecordFound/NoRecordTable";

export default function AboutUs() {
  const { data, error, loading } = useFetch(userApis.ABOUT_US);

  if (loading) return <LoadingView />;

  if (error) {
    return <h2>ERROR {"Opps! Something went wrong"}</h2>;
  }

  const terms = data && data["data"];
  return (
    <div className={"wrapper h-100"}>
      <div className="container h-100">
        <h3 className="text-center faq_heading">About Us</h3>
        <div className="align-center mt-3">
          {terms && terms.length ? (
            terms.map((el, i) => {
              return <div className="terms">{el.content}</div>;
            })
          ) : (
            <div className="align-center h-100">
              <NoRecordTable />
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
