import React from "react";

import "./error-boundary.scss";
import ErrorPage from "./ErrorPage";

class ErrorBoundary extends React.Component {
  constructor() {
    super();
    this.state = {
      hasError: false,
      error: { message: "", stack: "" },
      info: { componentStack: "" },
    };
  }

  static getDerivedStateFromError(error) {
    //process an error
    return { hasErrored: true };
  }

  componentDidCatch = (error, info) => {
    this.setState({ error, info });
  };
  render() {
    if (this.state.hasErrored) {
      return <ErrorPage />;
    } else {
      return this.props.children;
    }
  }
}

export default ErrorBoundary;
