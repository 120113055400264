import ReactPlayer from "react-player";
import "./Instruction.css"


const Instruction = () => {
  return (
    <div>
      <div>
        <div>
          <h3>
            <b>Instructions</b>
          </h3>
          <h5 className="mt-3">
           Steps to connect your Lobstr Wallet
          </h5>
        </div>
        <div className="mt-4 instruction">
          <h5>
            <b>Connect Wallet</b>
          </h5>
          <div >
            <ol>
              <li className="mt-1">Click on Wallet Connect button</li>
              <li className="mt-1">A qr scan will be generated. Scan this QR using your Lobstr Wallet.</li>
              <li className="mt-1">
               Check your mobile application for a Connection Request. Accept the request.
              </li>
              <li className="mt-1">
              You wallet will be connected now.
              </li>
             
            </ol>
          </div>
          <h5>
            <b>Swap</b>
          </h5>
          <div >


          <div className="text-center" >
      <div className="text-dark">Swap Stellar / ZPTC</div>
      <div className="align-center"  >
      <ReactPlayer
      controls url={'https://youtu.be/6BG6U68_B28'}
          />
      </div>
      </div>



          <ol>
            <li className="mt-1">Click on Swap</li>
            <li className="mt-1">
              Enter the stellar ZPTC and click on Swap button
            </li>
            <li className="mt-1">
            Confirm the swap request in mobile application
            </li>
            <li className="mt-1">
              You have successfully converted your Stellar ZPTC to Telos ZPTC!
            </li>
          </ol>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Instruction;
