import React, { useState } from "react";
import Zeptagram from "../../images/zeptagram.svg";
import Band from "../../images/band.svg";
import Girl from "../../images/lady.svg";
import Neon from "../../images/neonbrand.svg";
import { useDispatch, useSelector } from "react-redux";
import copyIcon from "../../icons/copy.png";

import { useHistory } from "react-router";
import { CopyToClipboard } from "react-copy-to-clipboard/lib/Component";
import { notifySuccess } from "../../utils/utils";

// const aesDecrypt = (text, password) => {
//   let algorithm = "aes-256-ctr";

//   let cypher = crypto.createDecipher(algorithm, password);
//   let decrypted = cypher.update(text, "hex", "utf8");
//   decrypted += cypher.final("utf8");

//   return decrypted;
// };

const GenerateKey = () => {
  const [secret, setSecret] = useState("");
  const [key, setKey] = useState("");
  const [hashValue, setHashValue] = useState("");

  const history = useHistory();

  const loading = useSelector((state) => state.user.forgetpasswordloading);
  const handleSubmit = async (e) => {
    e.preventDefault();

    console.log(secret, key);

    setHashValue("e");
  };
  return (
    <div className="row m-auto h-100 w-100  ">
      <div className="col-md-6  ">
        {/* <div><img className='cur-pointer' onClick={()=>history.push('/login')} src={Zeptagram} alt=''/></div> */}
        <div>
          <img
            className="cur-pointer"
            onClick={() => history.push("/login")}
            src={Zeptagram}
            alt=""
          />
        </div>
        <div className="h-84 align-center ">
          <div className="d-flex flex-column justify-content-center">
            <form className="" onSubmit={handleSubmit}>
              <h4 className="text-dark">Generate Decryption Keys</h4>

              <div className="text-grey w-500">
                We have sent you secret and encrypted key in your email.
              </div>
              <div className="mt-2">
                <label className="text-dark" style={{ marginLeft: "10px" }}>
                  Secret
                </label>
                <input
                  type="text"
                  value={secret}
                  className="input-box "
                  onChange={(e) => setSecret(e.target.value)}
                  placeholder="Secret"
                  required
                />
              </div>
              <div>
                <label className="text-dark" style={{ marginLeft: "10px" }}>
                  Encrypted Key
                </label>
                <input
                  type="text"
                  value={key}
                  className="input-box "
                  onChange={(e) => setKey(e.target.value)}
                  placeholder="Key"
                  required
                />
              </div>

              <div className="d-flex align-items-center mt-3">
                <button
                  className="button-medium border-shadow  medium-btn"
                  type="submit"
                >
                  {!loading ? (
                    "Generate"
                  ) : (
                    <>
                      <span
                        className="spinner-border spinner-border-smd"
                        role="status"
                        aria-hidden="true"
                      ></span>
                    </>
                  )}
                </button>
                {/* 
                <button
                  className="button-medium btn-grey border-shadow m-2"
                  type="button"
                  onClick={() => history.push("/login")}
                >
                  Cancel
                </button> */}
              </div>

              {/* <div className='text-grey mt-3'>Click on Submit and we will sent you an email to your registered mail.</div> */}
            </form>
            {hashValue && (
              <div className="mt-3">
                <div className="text-dark">Your Decrypted key : </div>
                <div>
                  {hashValue}{" "}
                  <CopyToClipboard
                    title="copy"
                    text={hashValue}
                    onCopy={() => {
                      notifySuccess("Copied! ");
                    }}
                  >
                    <img
                      src={copyIcon}
                      width={"17px"}
                      className={"mx-2 cur-pointer"}
                    />
                  </CopyToClipboard>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="col-md-6 image-container">
        <span className="verify-image verify-image-1">
          <img src={Neon} alt="" />
        </span>
        <span className="verify-image verify-image-2">
          <img src={Band} alt="" />
        </span>
        <div className="verify-image verify-image-3">
          <img height={380} className="girl-img" src={Girl} alt="" />
        </div>
      </div>
    </div>
  );
};

export default GenerateKey;
